<template>
    <div class="card card-default">
        <div class="card-body">
            <div class="col-md-12">
                <div class="row row-spacing">
                    <div class="col-md-4">
                        <div class="form-group row">
                            <label class="col-md-4 col-form-label">Month:</label>
                            <v-select
                                class="col-md-8"
                                :options="periods"
                                placeholder="Select Period"
                                v-model="selectedPeriod"
                                @input="getSynaqLicences"
                                :get-option-label="getLabel"
                            >
                            </v-select>
                        </div>
                    </div>
                </div>
                <hr class="mt-0"/>
                <div class="row row-spacing" v-if="selectedPeriod && (licences.length === 0)">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4">
                                <h4>Import SYNAQ Report</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <form class="mb-1">
                                    <input type="file" class="form-control" :class="{'upload-validation' : showValidation}" @change="fileSelected">
                                </form>
                                <span class="upload-validation-msg">{{ validationMessage }}</span>
                            </div>
                            <div class="col-md-8">
                                <button class="btn btn-success" @click="uploadFile()" :disabled="!isCorrectFileType">Upload File</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row row-spacing" v-if="selectedPeriod">
                    <div class="col-md-12">
                        <div class="card border-light">
                            <div class="card-body">
                                <div class="table-responsive" v-if="licences.length > 0">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Domain</th>
                                                <th>Product</th>
                                                <th>From</th>
                                                <th>Until</th>
                                                <th>Qty</th>
                                                <th>Unit Price</th>
                                                <th>Cost</th>
                                                <!-- <th v-if="selectedPeriod >= period"></th> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="licence in licences" :key="licence.synaqLicenceNumber">
                                                <td>
                                                    {{getDomainName(licence.synaqDomainNumber)}}
                                                    <!-- <select
                                                        class="form-control"
                                                        v-model="licence.synaqDomainNumber"
                                                        :disabled="period > licence.period"
                                                    >
                                                        <option
                                                            v-for="domain in domains"
                                                            :key="domain.synaqDomainNumber"
                                                            :value="domain.synaqDomainNumber"
                                                        >
                                                            {{ domain.domainName }}
                                                        </option>
                                                    </select> -->
                                                </td>
                                                <td>
                                                    <!-- <select
                                                        class="form-control"
                                                        v-model="licence.type"
                                                        :disabled="period > licence.period"
                                                    >
                                                        <option
                                                            v-for="product in options.products"
                                                            :key="product"
                                                        >
                                                            {{ product }}
                                                        </option>
                                                    </select> -->
                                                    {{ licence.type }}
                                                </td>
                                                <td>
                                                    <!-- <select
                                                        class="form-control width-50"
                                                        v-model="licence.validFrom"
                                                        :disabled="period > licence.period"
                                                    >
                                                        <option
                                                            v-for="date in options.dates"
                                                            :key="date"
                                                            :value="dateTimeFormat(date)"
                                                        >
                                                        {{ getDay(date) }}
                                                        </option>
                                                    </select> -->
                                                    {{ licence.validFrom.split(" ")[0] }}
                                                </td>
                                                <td>
                                                    <!-- <select
                                                        class="form-control width-50"
                                                        v-model="licence.validTo"
                                                        :disabled="period > licence.period"
                                                    >
                                                        <option
                                                        v-for="date in options.dates"
                                                        :key="date"
                                                        :value="dateTimeFormat(date)"
                                                        >
                                                        {{ getDay(date) }}
                                                        </option>
                                                    </select> -->
                                                    {{ licence.validTo.split(" ")[0] }}
                                                </td>
                                                <td>
                                                    <!-- <input
                                                        type="number"
                                                        class="form-control width-60"
                                                        v-model="licence.quantity"
                                                        :disabled="period > licence.period"
                                                    /> -->
                                                    {{ licence.quantity }}
                                                </td>
                                                <td>
                                                    <!-- <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">R</div>
                                                        </div>
                                                        <input
                                                            type="number"
                                                            class="form-control width-80"
                                                            v-model="licence.unitPrice"
                                                            :disabled="period > licence.period"
                                                        />
                                                    </div> -->
                                                    {{ "R " + licence.unitCostExclVat }}
                                                </td>
                                                <td>
                                                    {{ currencyFormat(amount(licence)) }}
                                                </td>
                                                <!-- <td class="text-center" v-if="selectedPeriod >= period && licence.objKey > 0">
                                                    <button class="btn btn-info" @click="saveSynaqEntry(licence)">
                                                        <i class="fa fa-floppy-o"></i>
                                                    </button>
                                                    <button class="btn btn-primary" @click="deleteSynaqEntry(licence)">
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                </td> -->
                                            </tr>
                                        </tbody>
                                        <tfoot v-if="licences.length > 0">
                                            <tr class="dark-row-footer text-right">
                                                <td colspan="9">Total Cost Excl. VAT: <strong>{{ currencyFormat(totalCostExclVat) }}</strong></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                periods: [],
                period: this.decreaseBillingPeriod(this.getCurrentPeriod()),
                selectedPeriod: null,
                licences: [],
                licence: {},
                options: {},
                domains: [],
                isNewEntry: false,
                newEntry: {},
                completeEntry: false,

                synaqFile: null,
                isCorrectFileType: false,
                validationMessage: "",
                isUploading: false,
                totalCostExclVat: 0,
            }
        },
        watch: {
            newEntry: {
            deep: true,
            handler(entry) {
                this.completeEntry =
                entry.synaqDomainNumber > 0 &&
                !entry.type.includes("None") &&
                this.getDay(entry.validFrom) > 0 &&
                this.getDay(entry.validTo) > 0 &&
                entry.quantity > 0 &&
                entry.unitPrice > 0;
            },
            },
        },
        created() {
            this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
        },
        mounted() {
            this.getSynaqBillingPeriods();
            this.getSynaqDomains();
        },
        computed: {
            firstLicence() {
                return this.licences.find(Boolean);
            },
            showValidation() {
                return !this.isCorrectFileType && this.synaqFile !== null;
            }
        },
        methods: {
            getSynaqBillingPeriods: function() {
                const hostUrl = this.$config.aimsAPI;

                this.$http.get(`${hostUrl}synaq/periods/2`).then(
                    (response) => {
                        this.periods = response.data;
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error fetching billing periods for Synaq Licences")
                    }
                );
            },
            getSynaqLicences: function(period) {
                const hostUrl = this.$config.aimsAPI;

                this.$http.get(`${hostUrl}synaq/licences/${period}`).then(
                    (response) => {
                        this.licences = response.data;
                        this.licences.forEach((licence) => {
                            return this.currencyFormat(licence.unitPrice);
                        });
                        this.getLicenceTotalCost();
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error fetching Synaq Licences", error);
                    }
                );
                // this.getSynaqOptions(period)
            },
            getSynaqDomains: function() {
                const hostUrl = this.$config.aimsAPI;

                this.$http.get(`${hostUrl}synaq/available/domains`).then(
                    (response) => {
                        this.domains = response.data;
                    },
                    (error) => {
                        console.error(error)
                        this.showError("Error fetching available domains", error)
                    },
                );
            },
            // getSynaqOptions: function(period) {
            //     const hostUrl = this.$config.aimsAPI;

            //     this.$http.get(`${hostUrl}synaq/options/${period}`).then(
            //         (response) => {
            //             this.options = response.data;
            //         },
            //         (error) => {
            //             console.error(error);
            //             this.showError("Error fetching Synaq options", error);
            //         },
            //     );
            // },
            addSynaqEntry: function() {
                const hostUrl = this.$config.aimsAPI;
                const selectedPeriod = this.selectedPeriod;

                this.$http.post(`${hostUrl}synaq/licences/add?period=${selectedPeriod}`).then(
                    (response) => {
                        this.newEntry = response.data;
                        this.licences.push(this.newEntry);
                        this.isNewEntry = true;
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error Adding New Synaq Licence Entry", error);
                    },
                );
            },
            saveSynaqEntry: function(data) {
                const hostUrl = this.$config.aimsAPI;
                const licences = this.licences;
                const licenceArray = new Array(data)

                if (licenceArray) {
                    this.$http.post(`${hostUrl}synaq/licences/save`, licences).then(
                        (response) => {
                            this.licences = response.data;
                            this.showSuccess("Successfully updated Synaq Licence Entry");
                            this.isNewEntry = false;
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error saving Synaq Licence Entry", error)
                        },
                    );
                }
            },
            deleteSynaqEntry: function(data) {
                this.$swal({
                    title: "Are you sure you want to delete this Synaq Licence entry?",
                    text: `${data.synaqDomain.domainName}: ${data.type}`,
                    type: "warning",
                    showCancelButton: true,
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.value) {
                    const hostUrl = this.$config.aimsAPI;
                    const objKey = data.objKey;

                    this.$http.delete(`${hostUrl}synaq/licences/${objKey}`).then(
                        (response) => {
                            this.showSuccess(response.data.message);
                            this.getSynaqLicences(this.selectedPeriod);
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error Deleting Synaq Licence Entry", error);
                        },
                    );
                    }
                });
            },
            getDomainName: function(synaqDomainNumber) {
                for (let i = 0; i < this.domains.length; i++) {
                    if (this.domains[i].synaqDomainNumber == synaqDomainNumber) {
                        return this.domains[i].domainName;
                    }
                }
                return "n/a"
            },  
            getLabel: function(period) {
                return this.prettifyBillingPeriod(period);
            },
            amount: function({ unitCostExclVat, quantity }) {
                return unitCostExclVat * quantity;
            },
            
            fileSelected: function(file) {      
                this.synaqFile = file.target.files[0];
                this.validateFileType(this.synaqFile);
            },
            validateFileType: function(uploadedFile) {
                if ((uploadedFile.name.substring(uploadedFile.name.length-3, uploadedFile.name.length).toLowerCase() === 'csv' || 
                uploadedFile.name.substring(uploadedFile.name.length-4, uploadedFile.name.length).toLowerCase() === 'xlsx') && this.synaqFile !== null) {
                    this.isCorrectFileType = true; 
                    this.validationMessage = '';
                } else {
                    this.isCorrectFileType = false; 
                    this.validationMessage = 'Invalid file type, only files with the following extensions are allowed: .csv'
                }
            },
            uploadFile: function() {
                this.isUploading = true;
                const headers = { headers: {'Content-Type': 'multipart/form-data'} };
                const formData = new FormData();
                formData.append('file', this.synaqFile);

                this.$http.post(`${this.$config.aimsAPI}synaq/upload/licences?period=${this.selectedPeriod}`, formData, headers).then(
                    (response) => {
                        this.showSuccess("File upload success");
                        this.getSynaqLicences(this.selectedPeriod);
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error Uploading File", error);
                    }
                ).finally(() => {
                    this.isUploading = false;
                    this.synaqFile = null;
                    $('input[type=file]').val(null);
                });      
            },
            getLicenceTotalCost: function() {
                const hostUrl = this.$config.aimsAPI;
                const currentPeriod = this.selectedPeriod;

                this.$http.get(`${hostUrl}synaq/licences/total/${currentPeriod}`).then(
                    (response) => {
                        this.totalCostExclVat = response.data;
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error fetching Total Cost VAT Excl.", error);
                    }
                )
            }
        },
    }
</script>

<style lang="scss" scoped>
    .width-50 {
        text-align: center;
        width: 50px;
    }

    .width-60 {
        text-align: center;
        width: 60px;
    }

    .width-80 {
        text-align: center;
        width: 80px !important;
    }

    .upload-validation {
        border: 1px solid #e2231a;
        color: #e2231a;
    }

    .upload-validation-msg {
        color: #e2231a;
        font-size: .9em;
    }

    .dark-row-footer {
      background-color: #3a3f51;
        color: #FFFFFF;
        position: sticky;
        bottom: 0;
        z-index: 2;
        font-size: .95em;
    }
</style>
