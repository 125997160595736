<template>
  <service-screen-layout :parts="parts">
    <template v-slot:service>
      <div class="row" v-if="saleDefinition.definition.service">
        <div class="col-md-7">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Service Name</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="saleDefinition.definition.service.serviceUsername"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Service ID</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="saleDefinition.definition.service.axxessServiceId"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Client ID</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="saleDefinition.definition.service.axxessClientId"
                />
              </div>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-md-7">
              <div class="form-group">
                <label>Description</label>
                <input
                  type="text"
                  class="form-control"
                  disabled
                  v-model="saleDefinition.definition.service.description"
                />
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group">
                <label>Product ID</label>
                <input
                  type="text"
                  class="form-control"
                  disabled
                  v-model="saleDefinition.definition.service.axxessProductId"
                />
              </div>
            </div>
          </div> -->
        </div>
        <div class="col-md-5" v-if="saleDefinition.definition.service">
          <div class="card card-default">
            <div class="card-header">
              <h4>Connectivity Package</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">Package Description:</div>
                    <div class="col-md-6">
                      <strong>{{
                        saleDefinition.definition.service.bundle.description
                      }}</strong>
                    </div>
                  </div>
                  <div class="row" v-allowed:view="['STAFF']">
                    <div class="col-md-6">Package Id:</div>
                    <div class="col-md-6">
                      <strong>{{
                        saleDefinition.definition.service.bundle
                          .axxessFtthPackageNumber
                      }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-spacing" v-allowed:view="['STAFF']">
        <div class="col-md-4">
          <button
            type="button"
            class="btn btn-success"
            @click="saveAxxessFtthService()"
          >
            Save
          </button>
        </div>
      </div>
    </template>
    <template v-slot:order>
      <div class="row">
        <div class="col-md-5">
          <div class="card card-default">
            <div class="card-header">FTTH Order</div>
            <div class="card-body" v-if="saleDefinition.definition.service">
              <div class="row">
                <div class="col-md-6">Created:</div>
                <div class="col-md-6">
                  <strong>{{
                    dateTimeFormat(saleDefinition.definition.service.order.created)
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">Order status:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.service.order.status
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">Delivered on:</div>
                <div class="col-md-6">
                  <strong>{{
                    dateTimeFormat(saleDefinition.definition.service.order.deliveredDate)
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">AIMS status:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.service.status
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">Order reference:</div>
                <div class="col-md-6">
                  <strong style="color: dodgerblue">{{
                    saleDefinition.definition.service.order.orderReference
                  }}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-spacing" v-allowed:view="['STAFF']">
        <div class="col-md-4">
          <button
            type="button"
            class="btn btn-success"
            @click="saveAxxessFtthService()"
          >
            Save
          </button>
        </div>
      </div>
    </template>
    <template v-slot:selfservice>
      <div class="row">
        <div class="col-md-5">
          <div class="card card-default">
            <div class="card-header">Portal</div>
            <div class="card-body">
              <img src="https://rcp.axxess.co.za/img/axxess_logo_updated.png" />
              <button
                class="btn btn-default"
                type="button"
                @click="viewClientOnAxxessPortal()"
              >
                View Client on Axxess Portal
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-spacing" v-allowed:view="['STAFF']">
        <div class="col-md-4">
          <button
            type="button"
            class="btn btn-success"
            @click="saveAxxessFtthService()"
          >
            Save
          </button>
        </div>
      </div>
    </template>
  </service-screen-layout>
</template>

<script>
import SaleFunctions from "@/pages/Services/SaleFunctions";
import DateInput from "@/components/GeneralComponents/InputControls/DateInput";
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";

export default {
  extends: SaleFunctions,
  components: {
    DateInput,
    ServiceScreenLayout,
  },
  computed: {
    clientPortalLink() {
      return `https://rcp.axxess.co.za/servicehome.php?clientid=${this.saleDefinition.definition.service.axxessClientId}&serviceid=${this.saleDefinition.definition.service.axxessServiceId}`;
    },
  },
  data() {
    return {
      parts: [
        {
          name: "service",
          icon: "fa fa-user-circle",
          displayName: "Service",
          permissions: ["*"],
        },
        {
          name: "order",
          icon: "fa fa-address-book",
          displayName: "Order",
          permissions: ["*"],
        },
        {
          name: "selfservice",
          icon: "fa fa-sign-in",
          displayName: "Portal",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        sale: {},
        definition: {},
        product: {},
      },
    };
  },
  mounted() {
    const saleNumber = this.$route.params.saleNumber;
    this.getSaleDefinition(saleNumber).then(
      (success) => {
        this.saleDefinition = success.data;
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching Sale Definition", error);
      }
    );
  },
  methods: {
    saveAxxessFtthService() {
      this.$http
        .put(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/update",
          this.saleDefinition
        )
        .then(
          (response) => {
            this.showSuccess("Save Success");
            this.saleDefinition = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving Axxess FTTH Service", error);
          }
        );
    },
    viewClientOnAxxessPortal() {
      window.open(this.clientPortalLink);
    },
  },
};
</script>

<style>
</style>
