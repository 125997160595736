<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-default">
        <div class="card-header">
          <h3>New Axxess LTE Service</h3>
        </div>
        <div class="card-body">
          <form-wizard @on-complete="onComplete" title="" subtitle="" shape="tab" color="#d71921" stepSize="sm">
            <tab-content title="Axxess Service Association" icon="fa fa-cube" :before-change="()=>validateStep('bundle-selection')">
              <form class="row" novalidate data-vv-scope="bundle-selection">
                <div class="col-lg-6">
                  <div class="form-group" :class="{'has-error': errors.has('bundle-selection.Sim')}">
                    <label>SIM card Serial Number *</label>
                    <v-select name="Sim" :options="availableAxxessSims" valueProp="guidServiceId" v-validate="'required'" class="mb-2" label="strSerialNumber" v-model="selectedPackage.simGuid" placeholder="Choose a SIM"></v-select>
                    <span class="has-error" v-if="errors.has('bundle-selection.Sim')">{{errors.first('bundle-selection.Sim')}}</span>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.has('bundle-selection.Package')}">
                    <label>Choose Package *</label>
                    <v-select :options="emptySaleDefinition.selectableOptions.bundle" valueProp="axxessLtePackageNumber" name="Package" v-validate="'required'" class="mb-2" label="description" v-model="selectedPackage.packageNumber" placeholder="Select Package"></v-select>
                    <span class="has-error" v-if="errors.has('bundle-selection.Package')">{{errors.first('bundle-selection.Package')}}</span>
                  </div>
                </div>
              </form>
            </tab-content>
            <tab-content title="Rica Details" icon="fa fa-clipboard" :before-change="()=>validateStep('rica-registration')">
              <form class="row" novalidate data-vv-scope="rica-registration">
                <div class="col-lg-6">
                  <div class="form-group" :class="{'has-error': errors.has('rica-registration.First Names')}">
                    <label>First Names *</label>
                    <input name="First Names" v-validate="'required'" class="form-control" v-model="filledInSaleDefinition.requiredObjs.rica.firstNames" />
                    <span class="has-error" v-if="errors.has('rica-registration.First Names')">{{errors.first('rica-registration.First Names')}}</span>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.has('rica-registration.Last Name')}">
                    <label>Last Name *</label>
                    <input name="Last Name" v-validate="'required'" class="form-control" v-model="filledInSaleDefinition.requiredObjs.rica.lastName" />
                    <span class="has-error" v-if="errors.has('rica-registration.Last Name')">{{errors.first('rica-registration.Last Name')}}</span>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.has('rica-registration.Email')}">
                    <label>Email *</label>
                    <input name="Email" v-validate="'required|email'" class="form-control" v-model="filledInSaleDefinition.requiredObjs.rica.emailAddress" />
                    <span class="has-error" v-if="errors.has('rica-registration.Email')">{{errors.first('rica-registration.Email')}}</span>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.has('rica-registration.Contact Number')}">
                    <label>Contact Number *</label>
                    <input name="Contact Number" minlength="10" maxlength="10" v-validate="'required'" type="tel" class="form-control" v-model="filledInSaleDefinition.requiredObjs.rica.contactNumber" />
                    <span class="has-error" v-if="errors.has('rica-registration.Contact Number')">{{errors.first('rica-registration.Contact Number')}}</span>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.has('rica-registration.ID / Passport Number')}">
                    <label>ID / Passport Number *</label>
                    <input name="ID / Passport Number" minlength="8" maxlength="13" v-validate="'required'" class="form-control" v-model="filledInSaleDefinition.requiredObjs.rica.idNumber" />
                    <span class="has-error" v-if="errors.has('rica-registration.ID / Passport Number')">{{errors.first('rica-registration.ID / Passport Number')}}</span>
                  </div>
                  <div class="form-group">
                    <Address @addressChanged="ricaAddressChanged" @addressValid="ricaAddressValidChanged" :addressStructure="filledInSaleDefinition.requiredObjs.ricaAddress" :addressTypes="filledInSaleDefinition.supportingEnums.ricaAddress.addressType" />
                  </div>
                </div>
              </form>
            </tab-content>
            <tab-content title="Delivery Info" icon="fa fa-truck" :before-change="()=>validateStep('delivery-info')">
              <div class="row">
                <Address class="col-xl-8" @addressChanged="addressChanged" @addressValid="deliveryAddressValidChanged" :addressStructure="filledInSaleDefinition.requiredObjs.orderAddress" :addressTypes="filledInSaleDefinition.supportingEnums.orderAddress.addressType" />
              </div>
            </tab-content>
          </form-wizard>
        </div>
      </div>
    </div>
    <WizardCheckout 
      ref="wizardCheckoutModal"
      :description="selectedPackage.package.description"
      :prices="selectedPackage.package.axxessltepackageprices"
      @checkout="finalize"
    />
  </div>
</template>

<script>
  import {FormWizard, TabContent} from "vue-form-wizard";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import Address from '@/components/GeneralComponents/Address'
  import WizardCheckout from '@/components/ObjParts/MultipleLoaded/WizardCheckout'
  
  export default {
    components: {
      FormWizard,
      TabContent,
      WizardCheckout,
      Address
    },
    props: {
      emptySaleDefinition: {
        type: Object,
        required: true
      },
      product: {
        type: Object,
        required: true
      }
    },
    watch: {
      emptySaleDefinition: function(to, from) {
        this.filledInSaleDefinition = JSON.parse(JSON.stringify(to));
      }
    },
    data() {
      return {
        filledInSaleDefinition: {
          selectableOptions: {
            bundle: []
          },
          supportingEnums: {
            service: {},
            bundle: {},
            order: {
            },
            orderAddress: {
              addressType: []
            },
            ricaAddress: {
              addressType: []
            }
          },
          requiredObjs: {
            service: {},
            order: {},
            orderAddress: {},
            rica: {},
            ricaAddress: {}
          }
        },
        availableAxxessSims: [],        
        selectedPackage: {
          package: {
            description: '',
            axxessltepackageprices: [],
          },
          packageNumber: null,
          simGuid: null,
          simSerial: null
        }
      };
    },
    mounted: function() {
      this.filledInSaleDefinition = JSON.parse(JSON.stringify(this.emptySaleDefinition));
      
      this.$http.get(this.$config.aimsAPI + 'axxess/lte/telkom/availablesims')
          .then(response => {
              this.availableAxxessSims = response.body;
          },
          error => {
              this.showError("Error fetching Axxess SIMS", error);
              console.error(error);
          });
    },
    methods: {
      validateStep: function(stepName) {
        return this.$validator.validateAll(stepName).then(result => {
          if (stepName == 'rica-registration') {
            if (this.ricaAddressValid == false) {
              result = false;
            }
          }

          if (stepName == 'delivery-info') {
            if (this.deliveryAddressValid == false) {
              result = false;
            }
          }
  
          if (result) {
            return true;
          } else {
            return false;
          }
        });
      },
      ricaAddressChanged(raddress) {
        this.filledInSaleDefinition.requiredObjs.ricaAddress = raddress;
      },
      addressChanged(address) {
        this.filledInSaleDefinition.requiredObjs.orderAddress = address;
      },
      ricaAddressValidChanged(isValid) {
        this.ricaAddressValid = isValid;
      },
      deliveryAddressValidChanged(isValid) {
        this.deliveryAddressValid = isValid;
      },
      getPackage(p){
          return this.$http.get(this.$config.aimsAPI + 'axxess/lte/package/' + p);
      },
      getSerialFromGuid(guid) {
        let result = this.availableAxxessSims.filter(p => p.guidServiceId === guid).strSerialNumber;
        return result;
      },
      async onComplete() {
        await this.getPackage(this.selectedPackage.packageNumber).then(response => {
              this.selectedPackage.package = response.data;
        })
        this.filledInSaleDefinition.requiredObjs.service.axxessLtePackageNumber = this.selectedPackage.packageNumber;
        this.filledInSaleDefinition.requiredObjs.service.axxessSimServiceId = this.selectedPackage.simGuid;
        this.filledInSaleDefinition.requiredObjs.service.axxessSimSerialNumber = this.getSerialFromGuid(this.selectedPackage.simGuid);

        this.$refs.wizardCheckoutModal.open();
      },
      finalize(){
        this.$emit('createSale', this.filledInSaleDefinition);
      }
    }
  };
</script>

<style>
  
</style>
