var render = function render(){var _vm=this,_c=_vm._self._c;return _c('service-screen-layout',{attrs:{"parts":_vm.parts},scopedSlots:_vm._u([{key:"details",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"card card-default"},[_c('div',{staticClass:"card-header"},[_c('h4',[_vm._v("MPLS VPN Details")])]),_c('metro-connect-mpls-details',{attrs:{"metroConnectionMpls":_vm.saleDefinition.definition.metroConnectionMpls},on:{"saveSale":_vm.saveSale}},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.saleDefinition.sale.label),expression:"saleDefinition.sale.label"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.saleDefinition.sale.label)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.saleDefinition.sale, "label", $event.target.value)}}})])])],1)])])]},proxy:true},{key:"ipaddresses",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"card card-default"},[_c('div',{staticClass:"card-header"},[_c('h4',[_vm._v("IPv4 Address")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10"},[_c('table',{staticClass:"table table-bordered"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Address")]),_c('th',[_vm._v("Mask")])])]),_c('tbody',[_c('tr',[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                            _vm.saleDefinition.definition.metroConnectionMpls
                              .ipAddress
                          ),expression:"\n                            saleDefinition.definition.metroConnectionMpls\n                              .ipAddress\n                          "}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(
                            _vm.saleDefinition.definition.metroConnectionMpls
                              .ipAddress
                          )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.saleDefinition.definition.metroConnectionMpls
                              , "ipAddress", $event.target.value)}}})]),_c('td',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(
                            _vm.saleDefinition.definition.metroConnectionMpls
                              .shortMask
                          ),expression:"\n                            saleDefinition.definition.metroConnectionMpls\n                              .shortMask\n                          "}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.saleDefinition.definition.metroConnectionMpls
                              , "shortMask", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.netmasksV4),function(mask){return _c('option',{key:mask,domProps:{"value":mask}},[_vm._v(" /"+_vm._s(mask)+" ")])}),0)])])])])])]),_c('div',{directives:[{name:"allowed",rawName:"v-allowed:view",value:(['STAFF']),expression:"['STAFF']",arg:"view"}],staticClass:"row row-spacing"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-success pull-right",attrs:{"type":"button"},on:{"click":function($event){return _vm.saveSale()}}},[_vm._v(" Save ")])])])])])])])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }