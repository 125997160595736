<template>
  <div>
    <ul class="nav nav-pills nav-fill" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link active"
          id="networkRangePackage-tab"
          data-toggle="tab"
          href="#networkRangePackage"
          role="tab"
          aria-controls="networkRangePackage"
          aria-selected="true"
          ><i class="fa fa-plus-square"></i> Network Range Packages</a
        >
      </li>
    </ul>
    <div class="tab-content">
      <div
        class="tab-pane active"
        id="networkRangePackage"
        role="tabpanel"
        aria-labelledby="networkRangePackage-tab"
      >
        <NetworkRangePackagePrices
          :productNumber="productNumber"
          :packages="networkRangePackages"
          @getNetworkRangePackages="getNetworkRangePackages"
          :userEditRight="userEditRight"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NetworkRangePackagePrices from "@/components/Admin/NetworkRangePackages/NetworkRangePackagePrices";
export default {
  props: {
    productNumber: {
      type: Number,
      required: true,
    },
    userEditRight: {
      type: Boolean,
      required: true
    },
  },
  components: {
    NetworkRangePackagePrices,
  },
  data() {
    return {
      networkRangePackages: [],
      emptyPackage: {}
    };
  },
  mounted() {
    this.getNetworkRangePackages();
  },
  methods: {
    getNetworkRangePackages() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "pricing/networkrangepackages/product/" +
            this.productNumber
        )
        .then(
          (response) => {
            this.networkRangePackages = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error fetching Network Range Packages", error);
          }
        );
    },
  },
};
</script>

<style scoped>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #f05050 !important;
}

.nav-pills .nav-link {
  background-color: #f3f2ee;
}

a {
  color: rgba(0, 0, 0, 0.6);
}
</style>
