<template>
    <modal ref="verifyModal" :title="modalTitle" :saveNeeded="false" dismissText="Close" :size="isIPv6 ? 'modal-xl' : 'modal-lg'" >
        <div class="col-md-12 terminal-theme px-0">
            <div class="row">
                <div class="col-md-12 my-3" v-for="(data, index) in verifyData" :key="index">
                    <div class="row">
                        <label class="col-md-12">
                            <div class="nsHead">dns{{ index === 0 ? 1 : index + 1 }}</div>.adept.za.net
                        </label>
                    </div>
                    <div class="d-flex align-items-center justify-content-center mx-5 my-2">
                        <div class="col-auto">
                            <div class="d-flex align-items-center">
                                {{ data.host }} 
                                <i class="fa-solid fa-circle-info mx-1" v-tooltip="`${dnsRecord.forwardAddress}`" style="cursor: pointer;"></i>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div>{{ data.ttl }}</div>
                        </div>
                        <div class="col-auto">
                            <div>IN</div>
                        </div>
                        <div class="col-auto">
                            <div>{{ data.type }}</div>
                        </div>
                        <div class="col-auto">
                            <div>{{ data.data }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";

export default {
    components: { 
        Modal 
    },
    props: {
        verifyData: {
            type: Array,
            required: true
        },
        modalTitle: {
            type: String,
            required: true
        },
        dnsRecord: {
            type: Object,
            required: true
        },
        isIPv6: {
            type: Boolean,
            required: true
        },
    },
    methods: {
        open: function() {
            this.$refs.verifyModal.open();
        },
    }
}
</script>

<style lang="scss" scoped>

.terminal-theme {
    font-family: monospace;
    letter-spacing: -1px;
    word-spacing: 6px;
    text-align: center;
}

.nsHead {
  color: royalblue;
  display: inline;
}
</style>