<template>
  <div>
    <!-- Home PBX Tabs -->
    <ServiceScreenLayout :parts="partsPBX"  v-if="saleDefinition.definition.pbx">
      <template v-slot:sip_trunk>
        <div class="card-header" style="padding-left: 0px">
          <h4>PBX</h4>
        </div>
        <div class="col-md-7" style="padding-left: 0px">
          <label>Description</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-model="saleDefinition.definition.pbx.description"
            />
          </div>
        </div>
        <hr/>
        <VoipDetails 
          :sipTrunk="saleDefinition.definition.sipTrunk" 
          :did="saleDefinition.definition.didNumber"
          @save-sale="saveSaleDefinition"
        />
      </template>
      <template v-slot:extensions>
        <VoipExtensions 
          :extensionList="saleDefinition.definition.pbx.extensions"
          @update-extensions="saveSaleExtensions"
          @add-new-extension="addNewExtension"
          @add-extensions="addExtensionRange"
          @delete-extension="deleteExtension"
        />
      </template>
    </ServiceScreenLayout>

    <!-- Home Voice Tabs -->
    <ServiceScreenLayout :parts="parts" v-else>
      <template v-slot:sip_trunk>
        <VoipDetails 
          :sipTrunk="saleDefinition.definition.sipTrunk" 
          :did="saleDefinition.definition.didNumber"
          @save-sale="saveSaleDefinition"
        />
      </template>
    </ServiceScreenLayout>
  </div>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";

import VoipDetails from "@/components/ObjParts/SingleConstructed/VoipDetails";
import VoipExtensions from "@/components/ObjParts/MultipleCaptured/VoipExtensions";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    VoipDetails,
    VoipExtensions,
  },
  watch: {
    saleDefinition: {
      deep: true,
      handler(newSaleDef, oldSaleDef) {
        if (oldSaleDef.definition.sipTrunk.sipTrunkNumber != newSaleDef.definition.sipTrunk.sipTrunkNumber) {
          this.$parent.$refs.serviceTitle.reloadSale();
        }
      },
    },
  },
  data() {
    return {
      saleNumber: null,
      partsPBX: [
        {
          name: "sip_trunk",
          icon: "fa fa-user-circle",
          displayName: "Service",
          permissions: ["*"],
        },
        {
          name: "extensions",
          icon: "fa fa-plus-square",
          displayName: "Extensions",
          permissions: ["*"],
        },
      ],
      parts: [
        {
          name: "sip_trunk",
          icon: "fa fa-user-circle",
          displayName: "Home VOIP Details",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        definition:{
          sipTrunk: {},
          didNumber: {},
        },
      },
    };
  },
  watch: {},
  async mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.getSaleDefinition(this.saleNumber).then(
      (success) => {
        this.saleDefinition = success.data;
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching Sale Definition", error);
      }
    );
  },
  methods: {
    loadSaleDefinition() {
      this.getSaleDefinition(this.saleNumber).then(
        (success) => {
          this.saleDefinition = success.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
      return Promise.resolve('Success');
    },
    saveSaleDefinition: function() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
                this.loadSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Voice Service", error);
              }
            );
        }
      });
    },
    saveSaleExtensions: function(data) {
      this.saleDefinition.definition.pbx.extensions = data;
      this.saveSaleDefinition();
    },
    addNewExtension: function(data) {
      var extensionObj = this.saleDefinition.supportingObjs.extensions;
      extensionObj.number = data;
      extensionObj.vodiaCloudPbxNumber = this.saleDefinition.definition.pbx.vodiaCloudPbxNumber;
      this.saleDefinition.definition.pbx.extensions.push(extensionObj);
      this.saveSaleDefinition();
    },
    addExtensionRange: function(data) {
      return this.$swal({
                title: "New Extensions",
                text: "You are about to add " + data.length + " extensions",
                type: 'info',
                confirmButtonText: 'Ok',
                showCancelButton: true,
            }).then((result) => {
                if (result.dismiss) {
                    return false;
                }
                if (result.value) {
                  for (let i = 0; i < data.length; i++) {
                    var extensionObj = Object.assign({}, this.saleDefinition.supportingObjs.extensions);
                    extensionObj.number = data[i];
                    extensionObj.vodiaCloudPbxNumber = this.saleDefinition.definition.pbx.vodiaCloudPbxNumber;
                    this.saleDefinition.definition.pbx.extensions.push(extensionObj);
                  }
                  this.saveSaleDefinition();
                }
              });
    },
    updateExtensions: function(data) {
      this.saleDefinition.definition.pbx.extensions = data;
      this.saveSaleDefinition();
    },
    deleteExtension: function(data) {
      if (this.saleDefinition.definition.pbx.extensions.length > 1) {
        for (let i = 0; i < this.saleDefinition.definition.pbx.extensions.length; i++) {
          if (this.saleDefinition.definition.pbx.extensions[i] == data) {
            this.deleteObjPart(this.saleDefinition.definition.pbx.extensions[i])
              .then(
                (response) => {
                  this.showSuccess("Delete Success");
                  this.loadSaleDefinition()
                    .then((response) => {
                      this.saleDefinition.definition.pbx.extensions = this.saleDefinition.definition.pbx.extensions.filter(ext => (ext != data));
                    }, (error) => {
                      console.error(error);
                      this.showError("Error Deleting Extension", error);
                    });
                },
                (error) => {
                  console.error(error);
                  this.showError("Error Deleting Extension", error);
              });
          }
        }
      } else {
        return this.$swal({
          title: "Error",
          text: "You can't delete the last extension",
          type: 'warning',
          confirmButtonText: 'Ok',
        });
      }

    },
  },
};
</script>