<template>
    <div class="card card-default">
        <div class="card-body">
            <div class="row row-spacing">
                <div class="col-md-6">
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Search Domain Contact" v-model="domainContactState.searchTerm">
                        <div class="input-group-append">
                            <span class="input-group-text"><i class="fa fa-search"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <button class="btn btn-success float-right" @click="openAddDomainContactModal()">Add Domain Contact</button>
                </div>
            </div>
            <hr />
            <div class="row row-spacing">
                <div class="col-md-12">
                    <div class="table-responsive table-bordered">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>First name</th>
                                    <th>Last name</th>
                                    <th>Email Address</th>
                                    <th>Telephone Number</th>
                                    <th>Cellphone Number</th>
                                    <th>Company Name</th>
                                    <th>Client Number</th>
                                    <th>Shared Contact</th>
                                </tr>
                            </thead>
                            <tbody v-if="filteredDomainContacts.length > 0">
                                <tr v-for="domainContact in filteredDomainContacts" :key="domainContact.domainRegistrationContactNumber">
                                    <td>{{ domainContact.firstNames }}</td>
                                    <td>{{ domainContact.lastName }}</td>
                                    <td>{{ domainContact.emailAddress }}</td>
                                    <td>{{ domainContact.contactPhoneNumber }}</td>
                                    <td>{{ domainContact.contactCellNumber }}</td>
                                    <td>{{ domainContact.companyName }}</td>
                                    <td>{{ domainContact.clientNumber }}</td>
                                    <td>{{ domainContact.shared }}</td>
                                    <td>
                                        <div class="text-center">
                                            <button 
                                                class="btn btn-info"
                                                v-tooltip="'Edit Domain Registration Contact'"
                                                @click="openEditDomainContactModal(domainContact)"
                                            >
                                                <i class="fa fa-edit"></i>
                                            </button>
                                            <button 
                                                class="btn btn-primary"
                                                v-tooltip="'Delete Domain Registration Contact'"
                                                @click="deleteDomainRegistrationContact(domainContact)"
                                            >
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="7">No Domain Registrations Contacts created</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <AddDomainRegistrationContactModal 
                ref="openAddDomainContactModal"
                :allClients="allClients"
                :modalTitle="modalTitle"
                @addDomainContacts="addDomainContacts"
            />
            <ModifyDomainRegistrationContactModal 
                ref="openEditDomainContactModal"
                :allClients="allClients"
                :modalTitle="modalTitle"
                :selectedDomainContact="selectedDomainContact"
                @editDomainContacts="editDomainContacts"
            />
        </div>
    </div>
</template>

<script>
import { DomainContactState } from '@/stores/pages/Admin/DomainContacts.ts';
import AddDomainRegistrationContactModal from '@/components/Admin/Modals/AddDomainRegistrationContactModal';
import ModifyDomainRegistrationContactModal from '@/components/Admin/Modals/ModifyDomainRegistrationContactModal';

export default {
    components: {
        AddDomainRegistrationContactModal,
        ModifyDomainRegistrationContactModal
    },
    data() {
        const domainContactState = DomainContactState();
        return {
            domainContactState,
            modalTitle: "",
            contactObject: {},
            domainContacts: [],
            allClients: [],
            selectedDomainContact: {
                domaincontactaddress: {}
            },
        }
    },
    mounted() {
        this.setPageTitle();
        this.getDomainContacts();
        this.getAllAvailableClients();
    },
    computed: {
        filteredDomainContacts() {
            this.domainContactsFiltered = this.domainContacts;

            if (this.domainContactState.searchTerm) {
                this.domainContactsFiltered = this.domainContactsFiltered.filter((domainContact) => 
                    domainContact.firstNames.toLowerCase().includes(this.domainContactState.searchTerm.toLowerCase()) ||
                    domainContact.lastName.toLowerCase().includes(this.domainContactState.searchTerm.toLowerCase()) ||
                    domainContact.emailAddress.toLowerCase().includes(this.domainContactState.searchTerm.toLowerCase()) ||
                    domainContact.contactCellNumber.toString().includes(this.domainContactState.searchTerm) ||
                    domainContact.companyName.toLowerCase().includes(this.domainContactState.searchTerm.toLowerCase()) ||
                    domainContact.clientNumber.toString().includes(this.domainContactState.searchTerm) ||
                    domainContact.shared.toString().includes(this.domainContactState.searchTerm)
                );
            };

            return this.domainContactsFiltered;
        },
    },
    methods: {
        setPageTitle: function() {
            this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
        },
        openAddDomainContactModal: function() {
            this.modalTitle = 'Add Domain Registration Contact';
            this.$refs.openAddDomainContactModal.open();
        },
        openEditDomainContactModal: function(data) {
            const hostUrl = this.$config.aimsAPI;
            const selectedDomainContactNumber = data.domainContactNumber;
                this.$http.get(`${hostUrl}domains/contacts/address/${selectedDomainContactNumber}`).then(
                (response) => {
                    this.selectedDomainContact = response.data;
                },
                (error) => {
                    console.error(error);
                    this.showError("Error fetching all Clients", error);
                }
            );        
            this.modalTitle = 'Edit Domain Registration Contact';
            
            this.$refs.openEditDomainContactModal.open();
        },
        getAllAvailableClients: function() {
            const hostUrl = this.$config.aimsAPI;

            this.$http.get(`${hostUrl}clients/all`).then(
                (response) => {
                    this.allClients = response.data;
                },
                (error) => {
                    console.error(error);
                    this.showError("Error fetching all Clients", error);
                }
            );
        },
        getDomainContacts: function() {
            const hostUrl = this.$config.aimsAPI;

            this.$http.get(`${hostUrl}domains/contacts/all`).then(
                (response) => {
                    this.domainContacts = response.data;                    
                },
                (error) => {
                    console.error(error);
                    this.showError("Error fetching Domain Registration Contacts", error);
                }
            );
        },
        async addDomainContacts(data) {                       
            const hostUrl = this.$config.aimsAPI;
            const domainContact = data;
            const contactAddress = data.domainContactAddress;
            this.contactObject = data;

            await this.$http.post(`${hostUrl}domains/contacts`, domainContact).then(
                (response) => {
                    this.domainContacts.push(response.data);
                    contactAddress.domainContactNumber = response.data.domainContactNumber;
                },
                (error) => {
                    console.error(error);
                    this.showError("Error creating Domain Registration Contact", error);
                },
            );
            await this.$http.post(`${hostUrl}domains/contacts/address`, contactAddress).then(
                (response) => {
                    this.showSuccess("Successfully added Domain Registration Contact");
                },
                (error) => {
                    console.error(error);
                    this.showError("Error creating Domain Registration Contact Address", error);
                }
            );

            this.$emit('reloadDomainContacts');
        },
        async editDomainContacts(data) {            
            const hostUrl = this.$config.aimsAPI;
            const domainContact = data;
            const domainContactAddress = data.domaincontactaddress;

            await this.$http.put(`${hostUrl}domains/contacts`, domainContact).then(
                (response) => {
                    this.getDomainContacts();
                }, 
                (error) => {
                    console.error(error);
                    this.showError("Error saving Domain Registration Contact", error);
                }
            );
            await this.$http.put(`${hostUrl}domains/contacts/address`, domainContactAddress).then(
                (response) => {
                    this.getDomainContacts();
                    this.showSuccess("Successfully updated Domain Registration Contact");
                }, 
                (error) => {
                    console.error(error);
                    this.showError("Error saving Domain Registration Contact Address", error);
                }
            );
        },
        deleteDomainRegistrationContact: function(data) {
            this.$swal({
                title: "Are you sure you want to delete this Domain Contact",
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Delete"
            }).then((result) => {
                if (result.value) {
                    const hostUrl = this.$config.aimsAPI;
                    const domainContactNumber = data.domainContactNumber;

                    this.$http.delete(`${hostUrl}domains/contacts/address/${domainContactNumber}`, data).then(
                        (response) => {
                            this.showSuccess(`Successfully deleted Domain Contact`);
                            this.getDomainContacts();
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error deleting Domain Contact, Contact has Role assigned", error);
                        },
                    );
                }
            });
            
        }
    },
}
</script>
