<template>
  <div class="row">
    <div class="col-md-8">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template v-slot:backupPackage>
            <form novalidate data-vv-scope="backupPackage">
              <div class="row">
                <div class="col-xl-8">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Package</label>
                      <v-select
                        name="description"
                        placeholder="Select Package"
                        :options="filledInSaleDefinition.selectableOptions.backupPackage"
                        valueProp="description"
                        label="description"
                        @input="packageSelected"
                      >
                      </v-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Device Name</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="filledInSaleDefinition.requiredObjs.solarwindsBackup.deviceName"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
          <template v-slot:summary>
            <form novalidate data-vv-scope="summary">
              <div class="row">
                <div class="col-xl-8">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Package</label>
                      <input
                        type="text"
                        class="form-control"
                        readonly
                        :value="selectedPackage.description"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Backup Storage</label>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          readonly
                          :value="sizeBytesToGb(selectedPackage.includedStorage)"
                        />
                        <div class="input-group-append">
                          <div class="input-group-text">GB</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Device Name</label>
                      <input
                        type="text"
                        class="form-control"
                        readonly
                        v-model="filledInSaleDefinition.requiredObjs.solarwindsBackup.deviceName"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Cost</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">R</div>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          readonly
                          :value="cost"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>First Invoice</label>
                      <input
                        type="text"
                        class="form-control"
                        readonly
                        :value="increaseBillingPeriod(getCurrentPeriod())"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
    <WizardCheckout 
      ref="wizardCheckoutModal"
      :description="selectedPackage.description"
      :prices="selectedPackage.selectedPrice"
      @checkout="finalize"
    />
  </div>
</template>

<script>
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
import WizardCheckout from '@/components/ObjParts/MultipleLoaded/WizardCheckout'

export default {
  components: {
    ServiceWizardLayout,
    WizardCheckout,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  computed: {
    cost: function() {
      const cost = this.selectedPackage.selectedPrice.find(
        (period) => this.getCurrentPeriod() >= period.validFrom || period.validUntil == 0
      );
      if (cost) {
        return cost.priceExclVat;
      }
      return 0;
    },
  },
  data() {
    return {
      parts: [
        {
          name: "backupPackage",
          displayName: "Package",
        },
        {
          name: "summary",
          displayName: "Summary",
        },
      ],
      filledInSaleDefinition: {
        selectableOptions: {
          backupPackage: [],
        },
        supportingEnums: {
          lineAddress: {
            addressType: [],
          },
        },
        requiredObjs: {
          solarwindsBackup: {},
        },
      },
      filteredPackages: [
        {
          managementType: "",
        },
      ],
      selectedPackage: {
        description: "",
        selectedPrice: [],
      },
      finalPrice: {}
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
  },
  methods: {
    packageSelected: function(description) {
      let filteredPackageList = [];
      const backupPackages = this.filledInSaleDefinition.selectableOptions.backupPackage;
      backupPackages.forEach((solarWPackage) => {
          if (solarWPackage.description === description) {
            filteredPackageList.push(solarWPackage);
          }
        }
      );

      this.filteredPackages = this.distinctList(
        filteredPackageList,
        "solarwindsBackupPackageNumber"
      );

      this.filteredPackages[0].selectedPrice = [];
      this.selectedPackage = this.deepCopyObject(this.filteredPackages[0]);
      this.getSolarwindsBackupPackagePrice(
        this.filteredPackages[0].solarwindsBackupPackageNumber
      ).then(
        (success) => {
          this.selectedPackage.selectedPrice = success.data;
        },
        (error) => {
          this.showError("Error fetching Price for package", error);
          console.error(error);
        }
      );
    },
    getSolarwindsBackupPackagePrice: function(solarwindsBackupPackageNumber) {
      return this.$http.get(this.$config.aimsAPI + "pricing/solarwindbackuppackages/" +solarwindsBackupPackageNumber);      
    },
    validateStep: function(stepName) {
      return this.$validator.validateAll(stepName).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
    onComplete: function() {
      this.filledInSaleDefinition.requiredObjs.solarwindsBackup.solarwindsBackupPackageNumber =
      this.selectedPackage.solarwindsBackupPackageNumber;
      this.finalPrice = this.selectedPackage.selectedPrice[this.selectedPackage.selectedPrice.length-1];
      
      this.$refs.wizardCheckoutModal.open();
    },
    finalize(){
      this.$emit('createSale', this.filledInSaleDefinition);
    },
  },
};
</script>