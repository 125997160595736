<template>
  <div>
    <div class="row row-spacing">
      <div class="col-md-2" v-if="heading">
        <h3>{{ heading }}</h3>
      </div>
      <div class="col-md-10" v-allowed:view="['STAFF']">
        <div class="row">
          <div class="col-md-4">
            <div class="input-group">
              <select class="form-control" v-model="topupSelected">
                <option
                  v-for="topup in availableTopups"
                  :key="topup.objKey"
                  :value="topup.objKey"
                >
                  {{ topup.description }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-2">
            <button class="btn btn-success" type="button" @click="addTopup()">Add Topup</button>
          </div>

        </div>
      </div>
    </div>
    <div class="row row-spacing">
      <div class="col-md-12">
        <div class="table-bordered table-responsive">
          <table class="table">
            <thead>
              <tr class="dark-row">
                <th>Description</th>
                <th>Created</th>
                <th v-if="showStatus">Status</th>
                <th v-if="!hideDates">Valid From</th>
                <th v-if="!hideDates">Valid To</th>
                <th v-if="!hideUsage">% Used</th>
                <th v-if="!hideUsage">Data Used</th>
                <th v-if="!hideUsage">Data Remaining</th>
              </tr>
            </thead>
            <tbody v-if="allocatedTopups.length > 0">
              <tr
                v-for="(topup, index) in allocatedTopups"
                :key="index"
                :class="{ 'new-table-row': topup.isNew }"
              >
                <td>{{ topup.topup.description }}</td>
                <td>{{ topup.created }}</td>
                <td v-if="showStatus">
                  {{ topup.status }}
                  <span class="badge bg-green" v-if="topup.isNew">New</span>
                </td>
                <td v-if="!hideDates">
                  {{ dateTimeFormat(topup.validFrom) }}
                </td>
                <td v-if="!hideDates">
                  {{ dateTimeFormat(topup.validUntil) }}
                </td>
                <td v-if="!hideUsage">
                  {{
                    getPercentageUsed(
                      topup.bytesConsumed,
                      topup.bytesRemaining
                    )
                  }}
                </td>
                <td v-if="!hideUsage">
                  {{ sizeBytesToGb(topup.bytesConsumed) }} GB
                </td>
                <td v-if="!hideUsage">
                  <span class="badge bg-success"
                    >{{ sizeBytesToGb(topup.bytesRemaining) }} GB</span
                  >
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="8">No topups added</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: false,
    },
    availableTopups: {
      type: Array,
      required: true,
    },
    allocatedTopups: {
      type: Array,
      required: true,
    },
    showStatus: {
      type: Boolean,
      required: true,
    },
    hideUsage: {
      type: Boolean,
      required: false,
    },
    hideDates: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      topupSelected: null,
    };
  },
  methods: {
    addTopup: function () {
      if (this.topupSelected) {
        const topup = this.availableTopups.find(
          (topup) => topup.objKey === this.topupSelected
        );
        let priceInclVAT = "";

        this.$http
          .post(this.$config.aimsAPI + "pricing/topupprice", topup)
          .then((response) => {
            const result = response.data;
            priceInclVAT =
              "Incl VAT " +
              this.timesVAT(parseFloat(result.priceExclVat));
            this.$swal({
              title: "Are you sure you want to add " + topup.description + "? ",
              text: priceInclVAT,
              type: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, add it!",
            }).then((result) => {
              if (result.value) {
                this.$emit("topupAdded", topup);
              }
            });
          });
      }
    },
    getPercentageUsed: function (used, remaining) {
      if (used == 0) {
        return "0";
      }
      return ((used / (used + remaining)) * 100).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-row {

  th {
    background-color: #3a3f51;
    color: #FFFFFF;

    position: sticky;
    top: 0;
    z-index: 2;
  }
}
</style>
