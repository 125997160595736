var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4",staticStyle:{"overflow":"scroll","height":"500px"}},[_c('div',[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Service Type(s)")]),_c('multiselect',{attrs:{"placeholder":"Select Service Type(s)","group-label":"label","group-values":"values","group-select":true,"options":_vm.classificationTypes,"multiple":true},on:{"input":function($event){return _vm.typesChanged()}},model:{value:(_vm.selectedClassificationTypes),callback:function ($$v) {_vm.selectedClassificationTypes=$$v},expression:"selectedClassificationTypes"}})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Vendor(s)")]),_c('multiselect',{attrs:{"placeholder":"Select Vendor(s)","group-label":"label","group-values":"values","group-select":true,"disabled":!_vm.selectedClassificationTypes ||
              _vm.selectedClassificationTypes.length == 0,"options":_vm.classificationVendors,"multiple":true},on:{"input":function($event){return _vm.vendorsChanged()}},model:{value:(_vm.selectedClassificationVendors),callback:function ($$v) {_vm.selectedClassificationVendors=$$v},expression:"selectedClassificationVendors"}})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Segment(s)")]),_c('multiselect',{attrs:{"placeholder":"Select Segment(s)","group-label":"label","group-values":"values","group-select":true,"disabled":!_vm.selectedClassificationVendors ||
              _vm.selectedClassificationVendors.length == 0,"options":_vm.classificationSegments,"multiple":true},on:{"input":function($event){return _vm.segmentsChanged()}},model:{value:(_vm.selectedClassificationSegments),callback:function ($$v) {_vm.selectedClassificationSegments=$$v},expression:"selectedClassificationSegments"}})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Sub Segment(s)")]),_c('multiselect',{attrs:{"placeholder":"Select SubSegment(s)","group-label":"label","group-values":"values","group-select":true,"disabled":!_vm.selectedClassificationSegments ||
              _vm.selectedClassificationSegments.length == 0,"options":_vm.classificationSubSegments,"multiple":true},on:{"input":function($event){return _vm.subSegmentsChanged()}},model:{value:(_vm.selectedClassificationSubSegments),callback:function ($$v) {_vm.selectedClassificationSubSegments=$$v},expression:"selectedClassificationSubSegments"}})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Product(s)")]),_c('multiselect',{attrs:{"placeholder":"Select Products","group-label":"label","group-values":"values","group-select":true,"disabled":!_vm.selectedClassificationSubSegments ||
              _vm.selectedClassificationSubSegments.length == 0,"options":_vm.products,"multiple":true},on:{"input":function($event){return _vm.productsChanged()}},model:{value:(_vm.selectedProducts),callback:function ($$v) {_vm.selectedProducts=$$v},expression:"selectedProducts"}})],1),(_vm.showRegionInput)?_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Region(s)")]),(_vm.regions.length > 0 && !_vm.addingRegion)?_c('div',{staticClass:"form-control mb-2 pl-2",staticStyle:{"border":"1px solid #dde6e9"}},_vm._l((_vm.regions),function(region,index){return _c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Remove region'),expression:"'Remove region'"}],key:index,staticClass:"badge bg-red text-white",staticStyle:{"cursor":"pointer","margin":"0.166em 0.5em 0.166em 0"},attrs:{"type":"button"},on:{"click":function($event){return _vm.removeRegion(index)}}},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(region))]),_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])}),0):_vm._e(),(_vm.regions.length === 0 || _vm.addingRegion)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedRegions),expression:"selectedRegions"}],staticClass:"form-control",staticStyle:{"height":"40px"},attrs:{"type":"text","disabled":_vm.selectedClassificationSubSegments.length == 0 ||
              _vm.selectedProducts.length == 0 ||
              _vm.selectedCircuitNumbers !== ''},domProps:{"value":(_vm.selectedRegions)},on:{"input":function($event){if($event.target.composing)return;_vm.selectedRegions=$event.target.value}}}):_vm._e(),(_vm.addingRegion || _vm.regions.length === 0)?_c('button',{staticClass:"btn btn-success mt-2 ml-0",class:{
              disabled:
                _vm.selectedClassificationSubSegments.length == 0 ||
                _vm.selectedProducts.length == 0 ||
                _vm.selectedCircuitNumbers !== '',
            },attrs:{"type":"button","disabled":_vm.selectedClassificationSubSegments.length == 0 ||
              _vm.selectedProducts.length == 0 ||
              _vm.selectedCircuitNumbers !== ''},on:{"click":function($event){return _vm.addRegion()}}},[_vm._v(" Add Region ")]):_vm._e(),(!_vm.addingRegion && _vm.regions.length > 0)?_c('button',{staticClass:"btn btn-info mt-2",attrs:{"type":"button"},on:{"click":function($event){_vm.addingRegion = true}}},[_vm._v(" New Region ")]):_vm._e(),(_vm.addingRegion && _vm.regions.length !== 0)?_c('button',{staticClass:"btn btn-primary mt-2",attrs:{"type":"button"},on:{"click":function($event){_vm.addingRegion = false}}},[_vm._v(" Cancel ")]):_vm._e()]):_vm._e(),(_vm.showRegionInput)?_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Circuit Numbers(s)")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedCircuitNumbers),expression:"selectedCircuitNumbers"}],staticClass:"form-control",attrs:{"id":"circuitTextArea","rows":"3","disabled":_vm.selectedClassificationSubSegments.length == 0 ||
              _vm.selectedProducts.length == 0 ||
              _vm.regions.length > 0},domProps:{"value":(_vm.selectedCircuitNumbers)},on:{"input":[function($event){if($event.target.composing)return;_vm.selectedCircuitNumbers=$event.target.value},function($event){return _vm.circuitNumbersChanged()}]}})]):_vm._e()])]),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"card card-default"},[_c('div',{staticClass:"card-header pb-0 pt-4"},[(_vm.clientsAffected.length > 0)?_c('div',[_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.clientText))])]):_c('div',[_c('h3',{staticClass:"text-center"},[_vm._v("No clients found")])])]),_c('div',{staticClass:"card-body pt-0"},[_c('hr'),(_vm.clientsAffected.length > 0)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-success",attrs:{"type":"button","disabled":_vm.clientsAffected.length == 0},on:{"click":function($event){return _vm.generateExportFile()}}},[_vm._v(" Export ")])])]),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchTerm),expression:"searchTerm"}],staticClass:"form-control",attrs:{"type":"search","placeholder":"Search by Client Number or Client Name..."},domProps:{"value":(_vm.searchTerm)},on:{"input":function($event){if($event.target.composing)return;_vm.searchTerm=$event.target.value}}}),_vm._m(0)])])])]):_vm._e(),(_vm.filteredClients.length > 0)?_c('div',{staticClass:"table-bordered table-responsive"},[_c('table',{staticClass:"table"},[_vm._m(1),_c('tbody',_vm._l((_vm.filteredClients),function(client){return _c('tr',{key:client.clientNumber},[_c('td',[_vm._v(_vm._s(client.clientNumber))]),(client.type == 'Corporate')?_c('td',[_vm._v(" "+_vm._s(client.companyname)+" ")]):_c('td',[_vm._v(" "+_vm._s(client.firstnames)+" "+_vm._s(client.lastname)+" ")])])}),0)])]):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text",attrs:{"id":"basic-addon2"}},[_c('i',{staticClass:"fa fa-search"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Client Number")]),_c('th',[_vm._v("Client Name")])])])
}]

export { render, staticRenderFns }