<template>
    <div class="row" style="justify-content: center;">
        <div class="" style="justify-content: center;">
            <button type="button" class="btn-circle btn-arrow" :disabled="this.currentPage == 0 || disableControls" @click="$emit('firstPage')"><i class="fa-solid fa-angles-left"></i></button>
            <button type="button" class="btn-circle btn-arrow" :disabled="this.currentPage == 0 || disableControls" @click="$emit('previousPage', 1)"><i class="fa-solid fa-angle-left"></i></button>

            <button type="button" class="btn-circle" :disabled="disableControls" v-show="this.currentPage == this.totalPages && this.totalPages != 0 && this.currentPage-3 >= 1" @click="$emit('previousPage', 4)">{{this.currentPage-3}}</button>
            <button type="button" class="btn-circle" :disabled="disableControls" v-show="this.currentPage > this.totalPages-2 && this.totalPages != 0 && this.currentPage-2 >= 1" @click="$emit('previousPage', 3)">{{this.currentPage-2}}</button>
            <button type="button" class="btn-circle" :disabled="disableControls" v-show="this.currentPage > 1 && this.currentPage-1 >= 1" @click="$emit('previousPage', 2)">{{this.currentPage-1}}</button>
            <button type="button" class="btn-circle" :disabled="disableControls" v-show="this.currentPage != 0" @click="$emit('previousPage', 1)">{{this.currentPage}}</button>

            <button type="button" class="btn-circle btn-current" :disabled="disableControls">{{this.currentPage+1}}</button>

            <button type="button" class="btn-circle" :disabled="disableControls" v-show="this.currentPage != this.totalPages && this.currentPage+1 <= this.totalPages" @click="$emit('nextPage', 1)">{{this.currentPage+2}}</button>
            <button type="button" class="btn-circle" :disabled="disableControls" v-show="this.currentPage < this.totalPages-1 && this.currentPage+2 <= this.totalPages" @click="$emit('nextPage', 2)">{{this.currentPage+3}}</button>
            <button type="button" class="btn-circle" :disabled="disableControls" v-show="this.currentPage < 2 && this.totalPages != 0 && this.currentPage+3 <= this.totalPages" @click="$emit('nextPage', 3)">{{this.currentPage+4}}</button>
            <button type="button" class="btn-circle" :disabled="disableControls" v-show="this.currentPage == 0 && this.totalPages != 0 && this.currentPage+4 <= this.totalPages" @click="$emit('nextPage', 4)">{{this.currentPage+5}}</button>

            <button type="button" class="btn-circle btn-arrow" :disabled="this.currentPage == this.totalPages || disableControls" @click="$emit('nextPage', 1)"><i class="fa-solid fa-angle-right"></i></button>
            <button type="button" class="btn-circle btn-arrow" :disabled="this.currentPage == this.totalPages || disableControls" @click="$emit('lastPage')"><i class="fa-solid fa-angles-right"></i></button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        currentPage: {
            type: Number,
            required: true
        },
        totalPages: {
            type: Number,
            required: true
        },
        disableControls: {
            type: Boolean,
            required: false
        },
    }
}
</script>

<style scoped>

    .btn-arrow {
        font-size: 14px !important;
    }
    .btn-circle {
        position: static;
        margin: 5px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        background-color: transparent;
        color: #444;
    }

    .btn-circle:hover{
        background-color: #dfdfdf;
    }

    .btn-circle:active {
        background-color: #bfbfbf;
    }

    .btn-circle:disabled {
        background-color: transparent;
        color: #b0b0b6;
    }

    .btn-current {
        margin: 5px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        background-color: #E2231A;
        color: white;
    }

    .btn-current:hover {
        background-color: #E2231A;
        color: white;
    }

    .btn-current:disabled {
        background-color: #ef7972;
        color: white;
    }
</style>