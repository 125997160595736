<template>
  <div class="row">
    <div class="col-xl-8 col-lg-12">
      <div class="card card-default">
        <form-wizard
          title=""
          subtitle=""
          @on-complete="onComplete"
          stepSize="sm"
        >
          <tab-content
            title="Point to Point Address"
            :before-change="() => validateStep('pointToPointIpv4Network')"
          >
          </tab-content>
        </form-wizard>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  components: {
    FormWizard,
    TabContent,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filledInSaleDefinition: {
        requiredObjs: {
          pointToPointIpv4Network: {},
        },
      },
    };
  },
  mounted: function () {
    this.filledInSaleDefinition = this.emptySaleDefinition;
    this.filledInSaleDefinition.requiredObjs.pointToPointIpv4Network.family =
      "IPV4";
  },
  methods: {
    validateStep: function (stepName) {
      return this.$validator.validateAll(stepName).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
    onComplete: function () {
      this.$emit("createSale", this.filledInSaleDefinition);
    },
  },
};
</script>

<style>
</style>
