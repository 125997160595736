<template>
  <div>
    <ServiceScreenLayout :parts="parts">
      <template v-slot:summary>
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-5">
                <div class="row">
                    <label class="col-md-4 col-form-label" style="padding-left:25px">Billing Period:</label>
                  <div class="col-md-7" v-if="saleDefinition.definition.minutes.summary">
                    <BillingFilter
                      :baseArray="saleDefinition.definition.minutes.summary"
                      @selectedOption="changeSummary"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row row-spacing">
              <div class="col-md-12">
                <table
                    class="table table-bordered"
                    v-if="saleDefinition.definition.minutes.summary && saleDefinition.definition.minutes.summary.length > 0"
                >
                    <thead>
                        <tr>
                            <th>Called Network</th>
                            <th>Duration (Min.)</th>
                            <th>Price (excl. VAT)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="sum in summaryList"
                            :key="sum.objKey"
                        >
                            <td>{{ sum.calledNetwork }}</td>
                            <td>{{ formattedDuration(sum.duration) }}</td>
                            <td>{{ currencyFormat(sum.priceExVat) }}</td>
                        </tr>
                    </tbody>
                </table>
                <div v-else class="alert alert-info">
                    No Summary to Display.
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:cdr>
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-5">
                <div class="row">
                    <label class="col-md-4 col-form-label" style="padding-left:25px">Billing Period:</label>
                  <div class="col-md-7">
                    <BillingFilter
                      :baseArray="saleDefinition.definition.minutes.cdrs"
                      @selectedOption="changeFilter"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row row-spacing">
              <div class="col-md-12">
                <table
                    class="table table-bordered"
                    v-if="saleDefinition.definition.minutes.cdrs.length > 0"
                >
                    <thead>
                        <tr>
                            <th>Called Number</th>
                            <th>Duration (Min.)</th>
                            <th>Price (excl. VAT)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="cdr in filteredList"
                            :key="cdr.objKey"
                        >
                            <td>{{ cdr.calledNumber }}</td>
                            <td>{{ formattedDuration(cdr.duration) }}</td>
                            <td>{{ currencyFormat(cdr.priceExVat) }}</td>
                        </tr>
                    </tbody>
                </table>
                <div v-else class="alert alert-info">
                    No CDRs to Display.
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ServiceScreenLayout>
  </div>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";
import BillingFilter from "@/components/ObjParts/MultipleLoaded/BillingFilter";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    BillingFilter,
  },
  watch: {
    saleDefinition: {
      deep: true,
      handler(newSaleDef, oldSaleDef) {
        if (oldSaleDef.definition.sipTrunk.sipTrunkNumber != newSaleDef.definition.sipTrunk.sipTrunkNumber) {
          this.$parent.$refs.serviceTitle.reloadSale();
        }
      },
    },
  },
  data() {
    return {
      saleNumber: null,
      parts: [
        {
          name: "summary",
          icon: "fa fa-book",
          displayName: "Summary",
          permissions: ["*"],
        },
        {
          name: "cdr",
          icon: "fa fa-volume-control-phone",
          displayName: "Call Detail Records",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        definition:{
            minutes: {
                cdrs: [],
            },
        },
      },
      filteredList: [],
      summaryList: [],
    };
  },
  watch: {},
  async mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.getSaleDefinition(this.saleNumber).then(
      (success) => {
        this.saleDefinition = success.data;
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching Sale Definition", error);
      }
    );
  },
  computed: {
  },
  methods: {
    formattedDuration(data) {
      var minutes = parseInt(data) / 60;
      var seconds = parseInt(data) % 60;

      return Math.trunc(minutes) + ":" + seconds;
      
    }, 
    loadSaleDefinition() {
      this.getSaleDefinition(this.saleNumber).then(
        (success) => {
          this.saleDefinition = success.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
      return Promise.resolve('Success');
    },
    saveSaleDefinition: function() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
                this.loadSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Voice Service", error);
              }
            );
        }
      });
    },
    changeFilter(data) {
      this.filteredList = data;
    },
    changeSummary(data) {
      this.summaryList = data;
    },
    getLabel(period) {
      return this.prettifyBillingPeriod(period);
    },
  },
};
</script>