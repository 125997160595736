<template>
  <div>
    <h3>Pipedrive service is currently offline.</h3>
  </div>
</template>

<script>
  export default {
    mounted() {
      this.$root.$emit("SET_PAGE_TITLE", "Person");
    }
  }
</script>

<style lang="scss" scoped>

</style>