<template>
    <div><!-- This component is used strictly to handle redirects --></div>
</template>
<script>
    export default {
        data() {
            return {
                productInformation: [],
                routeName: '',
                clientNumber: '',
                saleNumber: '',
                redirectRoute: {},
                redirectRoutePath: '',
                sale: {},
                loggedInUser: {},
            }
        },
        mounted() {
            this.redirectRoute = this.$route;
            this.redirectRoutePath = this.$route.path;
            this.clientNumber =  this.$route.params.clientNumber;
            this.saleNumber = this.$route.params.saleNumber;
        },
        created() {
            this.initAuth();
        },
        watch: {
            redirectRoute() {
                const hostUrl = this.$config.aimsAPI;
                this.$http.get(`${hostUrl}users/user/loggedInUser`).then(
                    (response) => {
                        this.loggedInUser = response.data;
                        if (this.loggedInUser) {
                                this.$http.get(`${hostUrl}products/frontend-product-information`).then(
                                    (response) => {
                                        this.productInformation = response.data;
                                    }, 
                                    (error) => {
                                        console.error(error);
                                        this.showError("Error fetching product information", error);
                                    }
                            );
                            this.$http.get(`${hostUrl}sales/${this.saleNumber}`).then(
                                (response) => {
                                    this.sale = response.data;
                                    let url = this.redirectRoutePath;
                                    let pathArr = url.split("/");
                                    const productRoute = this.getProductDefinition(this.sale.product.productDefinition);
                                    if (pathArr.includes(this.saleNumber && this.clientNumber)) {
                                        this.$router.push({
                                            name: productRoute.routeName,
                                            params: {
                                                clientNumber: this.clientNumber,
                                                saleNumber: this.saleNumber
                                            }
                                        });

                                    } 
                                }
                            );
                        }
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error Fetching User", error);
                    }
                );                     
            }
        },
    }
</script>
