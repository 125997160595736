<template>
  <div>
    <ul class="nav nav-pills nav-fill" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link active"
          id="webhosting-tab"
          data-toggle="tab"
          href="#webhostingPackage"
          role="tab"
          aria-controls="webhostingPackage"
          aria-selected="true"
          ><i class="fa fa-dropbox"></i> Webhosting Packages</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link" 
          id="zimbraPackage-tab"
          data-toggle="tab"
          href="#zimbraPackage"
          role="tab"
          aria-controls="zimbraxPackage"
          aria-selected="true"
          ><i class="fa fa-plus-square"></i> Zimbra Packages</a
        >
      </li>
    </ul>
    <div class="tab-content">
      <div
        class="tab-pane active"
        id="webhostingPackage"
        role="tabpanel"
        aria-labelledby="webhostingPackage-tab"
      >
        <WebhostingPackagePrices
          :productNumber="productNumber"
          :userEditRight="userEditRight"          
        />
      </div>
      <div
        class="tab-pane"
        id="zimbraPackage"
        role="tabpanel"
        aria-labelledby="zimbraPackage-tab"
      >
        <ZimbraPackagePrices
          :productNumber="productNumber"
          :userEditRight="userEditRight"          
        />
      </div>
    </div>
  </div>
</template>

<script>
import WebhostingPackagePrices from "@/components/Admin/Webhosting/WebhostingPackagePrices";
import ZimbraPackagePrices from "@/components/Admin/Webhosting/ZimbraPackagePrices";
export default {
  props: {
    productNumber: {
      type: Number,
      required: true,
    },
    userEditRight: {
      type: Boolean,
      required: true
    },
  },
  components: {
    WebhostingPackagePrices,
    ZimbraPackagePrices,
  },
  computed: {
 
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  
  },
};
</script>

<style scoped>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #f05050 !important;
}

.nav-pills .nav-link {
  background-color: #f3f2ee;
}

a {
  color: rgba(0, 0, 0, 0.6);
}
</style>
