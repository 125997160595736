<template>
    <modal 
        :title="'Add FTP User'"
        ref="addWebsiteAdminModal"
        @save="createWebsiteAdmin(newWebsiteAdmin)"
    >
        <div class="row">
            <div class="form-group col-md-12">
                <label class="control-label">Username:</label>
                <div class="input-group">
                    <input
                    type="text"
                    class="form-control"
                    v-model="newWebsiteAdmin.username"
                    aria-describedby="sitepart"
                    />
                    <span class="input-group-text" id="sitepart">@{{ site }}</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-md-12">
                <label class="control-label">Password:</label>
                <PasswordInput
                    :password="newWebsiteAdmin.password"
                    :userCanEdit="true"
                    @changePassword="changePassword"
                />
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label class="control-label">Directory:</label>
                <input type="text" class="form-control" v-model="newWebsiteAdmin.directory"/>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/GeneralComponents/Modal';
import PasswordInput from "@/components/GeneralComponents/InputControls/PasswordInput";

export default {
    components: {
        Modal,
        PasswordInput
    },
    props: {
        newWebsiteAdmin: {
            type: Object,
            required: true
        },
        site: {
            type: String,
            required: true
        }
    },
    methods: {
        open: function() {
            this.$refs.addWebsiteAdminModal.open();
        },
        changePassword: function(data) {
            if (data) {
                this.newWebsiteAdmin.password = data;
            }
        },
        createWebsiteAdmin: function(data) {            
            this.$emit("createWebsiteAdmin", data)
        }
    }
}
</script>

<style lang="scss" scoped>

</style>