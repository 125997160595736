<template>
    <modal :title="modalTitle" ref="addDomain" :size="'modal-md'" @save="saveDomain()">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label>Domain Name</label>
                            <input class="form-control" v-model="domain.name"/>
                            <small>This will only add a record in Service Panel.  You need to add the domain using cPanel.</small>
                        </div>
                    </div>
                </div>
              </div>
            </div>
    </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";

export default {
    components: {
        Modal
    },
    props: {
        modalTitle: {
            type: String,
            required: true
        },
        domain: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        open: function() {             
            this.$refs.addDomain.open();
        },
        saveDomain: function() {
            this.$refs.addDomain.isLoading();
            this.$emit('saveDomain', this.domain);
            this.$refs.addDomain.close();
        }
    },
}
</script>
