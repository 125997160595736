<template>
  <service-screen-layout :parts="parts">
    <template v-slot:details>
      <div class="row row-spacing">
        <div class="col-md-8">
          <div
            class="table-responsive table-bordered"
            v-if="
              saleDefinition.definition.repaymentPlan.instalments.length > 0
            "
          >
            <table class="table">
              <thead>
                <tr>
                  <th>Billing Cycle</th>
                  <th>Amount Invoiced</th>
                  <!-- <th>Total Paid</th> -->
                  <th>Amount Outstanding</th>
                  <th>Payments Remaining</th>
                </tr>
              </thead>
              <tbody class="text-nowrap">
                <tr
                  v-for="(instalment, index) in saleDefinition.definition
                    .repaymentPlan.instalments"
                  :key="index"
                >
                  <td>
                    {{ prettifyBillingPeriod(instalment.billingPeriod) }}
                  </td>
                  <td>R {{ timesVATNoCurrency(instalment.amount) }}</td>
                  <!-- <td>{{ currencyFormat(totalPaid(index)) }}</td> -->
                  <td>R {{ timesVATNoCurrency(instalment.remainingBalance) }}</td>
                  <td class="text-center">
                    {{ instalment.remainingInstalments }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="alert alert-info">
            No Repayment Instalments created.
          </div>
        </div>
      </div>
    </template>
    <template v-slot:summary>
      <div class="row">
        <div class="col-md-8">
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Device</label>
              <input
                type="text"
                readonly
                class="form-control"
                :value="
                  saleDefinition.definition.repaymentPlan.repaymentPlanPackage
                    .description
                "
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Repayment Terms</label>
              <input
                type="text"
                readonly
                class="form-control"
                v-model="
                  saleDefinition.definition.repaymentPlan.repaymentPlanPackage
                    .terms
                "
              />
            </div>
            <div class="form-group col-md-6">
              <label>Remaining Payments</label>
              <input
                type="text"
                readonly
                class="form-control"
                :value="latestInstalment.remainingInstalments"
              />
            </div>
          </div>
          <div class="form-row" v-if="remainingInstalments">
            <div class="form-group col-md-6">
              <label>Next Invoice Amount (Inc. VAT)</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">R</span>
                </div>
                <input
                  type="text"
                  readonly
                  class="form-control"
                  :value="timesVATNoCurrency(saleDefinition.definition.repaymentPlan.monthlyAmount)"
                />
              </div>
            </div>
            <div class="form-group col-md-6">
              <label>Next Invoice Date</label>
              <input
                type="text"
                readonly
                class="form-control"
                :value="prettifyBillingPeriod(period)"
              />
            </div>
          </div>
          <div class="form-row" v-if="remainingInstalments">
            <div class="form-group col-md-6">
              <label>Outstanding Amount (Inc. VAT)</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">R</span>
                </div>
                <input
                  type="text"
                  readonly
                  class="form-control"
                  :value="timesVATNoCurrency(latestInstalment.remainingBalance)"
                />
              </div>
            </div>
            <div
              class="col-md-6 align-self-center pt-2"
              v-allowed:view="['STAFF']"
            >
              <button
                class="btn btn-info"
                type="button"
                @click="settleAmount()"
              >
                Settle Amount
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";

import SaleFunctions from "@/pages/Services/SaleFunctions";
export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
  },
  computed: {
    latestInstalment() {
      const repaymentPlan = this.saleDefinition.definition.repaymentPlan;
      if (repaymentPlan.instalments.length > 0) {
        return repaymentPlan.instalments.reduce((prev, current) => {
          return prev.remainingInstalments < current.remainingInstalments
            ? prev
            : current;
        });
      }

      return {
        remainingInstalments:
          repaymentPlan.repaymentPlanPackage.terms.includes("12") ? 12 : 6,
        remainingBalance: repaymentPlan.deviceCost,
      };
    },
    remainingInstalments() {
      return this.latestInstalment.remainingInstalments ? true : false;
    },
    period() {
      return this.increaseBillingPeriod(
        this.latestInstalment.billingPeriod || this.getCurrentPeriod()
      );
    },
  },
  data() {
    return {
      parts: [
        {
          name: "summary",
          icon: "fa fa-book",
          displayName: "Summary",
          permissions: ["*"],
        },
        {
          name: "details",
          icon: "fa fa-info-circle",
          displayName: "Details",
          permissions: ["*"],
        },
      ],
      // documentLines: [],
      saleDefinition: {
        definition: {
          repaymentPlan: {
            instalments: [],
            repaymentPlanPackage: {
              terms: "",
            },
          },
        },
        product: {},
      },
    };
  },
  mounted() {
    this.loadSale();
  },
  methods: {
    loadSale() {
      const saleNumber = this.$route.params.saleNumber;
      this.getSaleDefinition(saleNumber).then(
        (response) => {
          this.saleDefinition = response.data;
        },
        (error) => {
          this.showError("Error fetching Sale Definition", error);
          console.error(error);
        }
      );

      // this.$http
      //   .get(this.$config.aimsAPI + "sales/" + saleNumber + "/documentlines")
      //   .then(
      //     (response) => {
      //       this.documentLines = response.data;
      //     },
      //     (error) => {
      //       this.showError("Error fetching document lines for sale", error);
      //       console.error(error);
      //     }
      //   );
    },
    // totalPaid(index) {
    //   let paid = 0;
    //   let i = this.documentLines.length - 1;
    //   while (--index >= -1) {
    //     if (this.documentLines[i]) {
    //       paid += this.documentLines[i].transactionAmount;
    //     }
    //     --i;
    //   }
    //   return paid;
    // },
    settleAmount() {
      this.$swal({
        title: "Settle full remaining amount?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Settle",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post(
              this.$config.aimsAPI + "instalments/settle/repaymentplan",
              this.saleDefinition.definition.repaymentPlanNumber 
            )
            .then(
              () => {
                this.loadSale();
                this.showSuccess(response.data.message);
              },
              (error) => {
                this.showError("Error Settling Remaining Amount", error);
                console.error(error);
              }
            );
        }
      });
    }
  },
};
</script>
