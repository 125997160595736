<template>
  <div>
      <SystemAlerts />
  </div>
</template>

<script>
import SystemAlerts from "@/components/Dashboard/SystemAlerts";

export default {
    components: {
        SystemAlerts,
    },
    created() {
        this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
    },
};
</script>

<style scoped>
</style>