<template>
  <div>
    <div class="card card-default">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3" style="border-right:1px solid grey">
            <label>ISP Allocations</label>
            <ul>
              <li v-for="ispallocation in ispallocations" :key="ispallocation.ipv4networkNumber" @click="fetchNetworkBranch(ispallocation)" style="cursor:pointer">
                <b>{{ispallocation.address}}/{{ispallocation.mask}}</b>
              </li>
            </ul>
            <label>Network Groups</label>
            <ul>
              <li v-for="networkgroup in networkgroups" :key="networkgroup.networkGroupNumber">
                <b>{{networkgroup.name}}</b>
                {{networkgroup.description}}
              </li>
            </ul>
          </div>
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-12">
                  <span v-for="expandedNetwork in networksExpanded" @click="navigateTo(expandedNetwork)" :key="expandedNetwork.text">{{expandedNetwork.text}}</span>
              </div>
              <div class="col-md-12">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Address</th>
                      <th>Name</th>
                      <th>Used</th>
                      <th>Size</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="node in tree.nodes" :key="node.data.objKey">
                      <td>{{node.data.address}} / {{node.data.mask}}</td>
                      <td>{{node.data.name}}</td>
                      <td>
                        <div class="progress progress-xs mb-3">
                          <div
                            class="progress-bar progress-bar-striped"
                            :class="getProgressClass(60)"
                            role="progressbar"
                            style="width: 60%;"
                          >
                            <span class="sr-only">60% Complete</span>
                          </div>
                        </div>
                      </td>
                      <td>Size</td>
                      <td>{{node.data.status}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ispallocations: [],
      networkgroups: [],
      tree: [],
      networksExpanded: []
    };
  },
  created() {
    this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
  },
  mounted() {
    this.$http.get(this.$config.aimsAPI + "network/allocations/isp").then(
      response => {
        this.ispallocations = response.data;
      },
      error => {
        console.error(error);
        this.showError("Error Fetching ISP Allocations", error);
      }
    );

    this.$http.get(this.$config.aimsAPI + "network/groups").then(
      response => {
        this.networkgroups = response.data;
      },
      error => {
        console.error(error);
        this.showError("Error Fetching Network Groups", error);
      }
    );
  },
  methods: {
    fetchNetworkBranch(ispAllocation) {
      const address = ispAllocation.address
      const mask = ispAllocation.mask

      this.$http
        .get(
          this.$config.aimsAPI +
            "network/allocations/tree?address=" +
            address +
            "&mask=" +
            mask
        )
        .then(
          response => {
            this.tree = response.data;
          },
          error => {
            console.error(error);
            this.showError("Error Fetching Allocation Tree", error);
          }
        );
    },
    getProgressClass(percentage) {
      if (percentage < 20) {
        return "bg-green";
      }

      if (percentage < 80) {
        return "bg-warning";
      }

      return "bg-danger";
    }
  }
};
</script>

<style>
</style>