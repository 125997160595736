<template>
  <div class="row">
    <div class="col-xl- col-lg-12">
      <div class="card card-default">
        <form-wizard
          title=""
          subtitle=""
          @on-complete="onComplete"
          stepSize="sm"
        >
          <tab-content
            title="Package"
            :before-change="() => validateStep('package')"
          >
            <form novalidate data-vv-scope="package">
              <div class="row">
                <div class="col-xl-6">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('package.ipv4address'),
                    }"
                  >
                    <label class="control-label">IPv4 Address *</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="ipv4address"
                      v-model="
                        filledInSaleDefinition.requiredObjs.metroConnectionMpls
                          .ipAddress
                      "
                    />
                    <span
                      class="has-error"
                      v-if="errors.has('package.ipv4address')"
                      >{{ errors.first("package.ipv4address") }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('package.netmask'),
                    }"
                  >
                    <label class="control-label">IPv4 Netmask *</label>
                    <select
                      class="form-control"
                      v-validate="'required'"
                      name="netmask"
                      v-model="
                        filledInSaleDefinition.requiredObjs.metroConnectionMpls
                          .shortMask
                      "
                    >
                      <option
                        :key="mask"
                        v-for="mask in netmasksV4"
                        :value="mask"
                      >
                        /{{ mask }}
                      </option>
                    </select>
                    <span
                      class="has-error"
                      v-if="errors.has('package.netmask')"
                      >{{ errors.first("package.netmask") }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('package.vlan'),
                    }"
                  >
                    <label class="control-label">VLAN *</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="vlan"
                      v-model="
                        filledInSaleDefinition.requiredObjs.metroConnectionMpls
                          .virtualLan
                      "
                    />
                    <span class="has-error" v-if="errors.has('package.vlan')">{{
                      errors.first("package.vlan")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('package.price'),
                    }"
                  >
                    <label class="control-label">Price ex VAT *</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="price"
                      v-model="
                        filledInSaleDefinition.requiredObjs.metroConnectionMpls
                          .serviceCostExVat
                      "
                    />
                    <span
                      class="has-error"
                      v-if="errors.has('package.price')"
                      >{{ errors.first("package.price") }}</span
                    >
                  </div>
                </div>
              </div>
            </form>
          </tab-content>
          <tab-content
            title="Summary"
            :before-change="() => validateStep('summary')"
          >
            <form novalidate data-vv-scope="summary">
              <div class="row">
                <div class="col-xl-6">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('summary.ipv4address'),
                    }"
                  >
                    <label class="control-label">IPv4 Address *</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      readonly
                      name="ipv4address"
                      v-model="
                        filledInSaleDefinition.requiredObjs.metroConnectionMpls
                          .ipAddress
                      "
                    />
                    <span
                      class="has-error"
                      v-if="errors.has('summary.ipv4address')"
                      >{{ errors.first("summary.ipv4address") }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('summary.netmask'),
                    }"
                  >
                    <label class="control-label">IPv4 Netmask *</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      readonly
                      name="netmask"
                      v-model="
                        filledInSaleDefinition.requiredObjs.metroConnectionMpls
                          .shortMask
                      "
                    />
                    <span
                      class="has-error"
                      v-if="errors.has('summary.netmask')"
                      >{{ errors.first("summary.netmask") }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('summary.vlan'),
                    }"
                  >
                    <label class="control-label">VLAN *</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      readonly
                      name="vlan"
                      v-model="
                        filledInSaleDefinition.requiredObjs.metroConnectionMpls
                          .virtualLan
                      "
                    />
                    <span class="has-error" v-if="errors.has('summary.vlan')">{{
                      errors.first("summary.vlan")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('summary.price'),
                    }"
                  >
                    <label class="control-label">Price ex VAT *</label>
                    <input
                      type="text"
                      class="form-control"
                      readonly
                      v-validate="'required'"
                      name="price"
                      v-model="
                        filledInSaleDefinition.requiredObjs.metroConnectionMpls
                          .serviceCostExVat
                      "
                    />
                    <span
                      class="has-error"
                      v-if="errors.has('summary.price')"
                      >{{ errors.first("summary.price") }}</span
                    >
                  </div>
                </div>
              </div>
            </form>
          </tab-content>
        </form-wizard>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  components: {
    FormWizard,
    TabContent,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filledInSaleDefinition: {
        requiredObjs: {
          metroConnectionMpls: {},
        },
      },
      netmasksV4: [32, 30, 29, 28, 27, 26, 25],
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
  },
  methods: {
    onComplete() {
      this.$swal({
        title:
          "Add " +
          this.filledInSaleDefinition.requiredObjs.metroConnectionMpls
            .virtualLan,
        text:
          "Are you sure you want to add " +
          this.filledInSaleDefinition.requiredObjs.metroConnectionMpls
            .virtualLan +
          "?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Add Package",
      }).then((result) => {
        if (result.value) {
          this.$emit("createSale", this.filledInSaleDefinition);
        }
      });
    },
    validateStep: function (stepName) {
      return this.$validator.validateAll(stepName).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>