<template>
  <div class="row">
    <div class="col-md-9">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template slot="certificate">
            <form novalidate data-vv-scope="certificate">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('certificate.hostNumber'),
                    }"
                  >
                    <label>Site</label>
                    <v-select 
                      name="hostNumber"
                      placeholder="Select a Site"
                      :options="hosts" 
                      label="name" 
                      valueProp="hostNumber"
                      v-validate="'required'"
                      v-model="filledInSaleDefinition.requiredObjs.cert.hostNumber" 
                    ></v-select>
                    <span class="has-error" v-if="errors.has('certificate.hostNumber')">{{
                      errors.first("certificate.hostNumber")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-6">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('certificate.generator'),
                    }"
                  >
                    <label>Generated By</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="generator"
                      v-model="filledInSaleDefinition.requiredObjs.cert.generator"
                    />
                    <span class="has-error" v-if="errors.has('certificate.generator')">{{
                      errors.first("certificate.generator")
                    }}</span>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-6">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('certificate.issuer'),
                    }"
                  >
                    <label>Issuer</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="issuer"
                      v-model="filledInSaleDefinition.requiredObjs.cert.issuer"
                    />
                    <span class="has-error" v-if="errors.has('certificate.issuer')">{{
                      errors.first("certificate.issuer")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('certificate.renewURL'),
                    }"
                  >
                    <label>Renewal URL</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="renewURL"
                      v-model="filledInSaleDefinition.requiredObjs.cert.renewURL"
                    />
                    <span class="has-error" v-if="errors.has('certificate.renewURL')">{{
                      errors.first("certificate.renewURL")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-6">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('certificate.ticketReference'),
                    }"
                  >
                    <label>Ticket Reference</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="ticketReference"
                      v-model="filledInSaleDefinition.requiredObjs.cert.ticketReference"
                    />
                    <span class="has-error" v-if="errors.has('certificate.ticketReference')">{{
                      errors.first("certificate.ticketReference")
                    }}</span>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-6">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('certificate.expiryDate'),
                    }"
                  >
                    <label>Expiry Date</label>
                    <div>
                      <DateInput
                        type="date"
                        v-model="filledInSaleDefinition.requiredObjs.cert.expiryDate"
                        name="expiryDate"
                        v-validate="'required'"
                      />
                    </div>
                    <span class="has-error" v-if="errors.has('certificate.expiryDate')">{{
                      errors.first("certificate.expiryDate")
                    }}</span>
                  </div>
                </div>
              </div>
            </form>
          </template>

          <template slot="keys">
            <form novalidate data-vv-scope="keys">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('keys.certificate'),
                    }"
                  >
                    <label>Certificate</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="certificate"
                      v-model="filledInSaleDefinition.requiredObjs.cert.certificatePEM"
                    />
                    <span class="has-error" v-if="errors.has('keys.certificate')">{{
                      errors.first("keys.certificate")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('keys.private'),
                    }"
                  >
                    <label>Private Key</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="private"
                      v-model="filledInSaleDefinition.requiredObjs.cert.privateKeyPEM"
                    />
                    <span class="has-error" v-if="errors.has('keys.private')">{{
                      errors.first("keys.private")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('keys.csr'),
                    }"
                  >
                    <label>CSR</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="csr"
                      v-model="filledInSaleDefinition.requiredObjs.cert.csrPEM"
                    />
                    <span class="has-error" v-if="errors.has('keys.csr')">{{
                      errors.first("keys.csr")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('keys.caBundle'),
                    }"
                  >
                    <label>CA Bundle</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="caBundle"
                      v-model="filledInSaleDefinition.requiredObjs.cert.caBundle"
                    />
                    <span class="has-error" v-if="errors.has('keys.caBundle')">{{
                      errors.first("keys.caBundle")
                    }}</span>
                  </div>
                </div>
              </div>
            </form>
          </template>

        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
import DateInput from "@/components/GeneralComponents/InputControls/DateInput";

export default {
  components: {
    FormWizard,
    TabContent,
    ServiceWizardLayout,
    DateInput,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "certificate",
          displayName: "Info",
          icon: "fa fa-cloud",
        },
        {
          name: "keys",
          displayName: "Keys",
          icon: "fa fa-key",
        },
      ],
      filledInSaleDefinition: {
        requiredObjs: {
          cert: {},
        },
        selectableOptions: {

        }
      },
      hosts: []
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;

    this.$http.get(this.$config.aimsAPIvLegacy + this.$route.params.clientNumber + "/hosts")
    .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].domain) {
            this.hosts.push({ name: response.data[i].name + "." + response.data[i].domain.name,
                            hostNumber: response.data[i].hostNumber});
          } else {
            this.hosts.push({ name: response.data[i].name,
                              hostNumber: response.data[i].hostNumber});
          }
        }
      },(error) => {
        console.error(error);
        this.showError("Error Saving Sale Definition", error);
      }
    );
  },
  methods: {
    onComplete() {
      this.$emit("createSale", this.filledInSaleDefinition);
    },
    validateStep(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
</style>