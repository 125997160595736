<template>
    <div>
        <div class="row">
            <div class="col-md-2" v-if="productVersion === 'NEW'">
                <button
                      class="btn btn-success w-100"
                      type="button"
                      @click="$emit('createProduct')"
                    >
                      New Product
                    </button>
            </div>
            <div class="col-md-10">
                <div class="input-group">
                    <input 
                        type="text" 
                        class="form-control" 
                        placeholder="Search Product Number, Description or Product Definition..." 
                        aria-label="Search" 
                        aria-describedby="search-input"
                        v-model="productListState.searchInput"
                    >
                    <div class="input-group-append">
                        <span class="input-group-text" id="search-input">
                            <div class="fa fa-search"></div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-md-2">
                <v-select
                    :options="types"
                    v-model="productListState.selectedClassifications.classificationType"
                    label="name"
                    clearable
                    placeholder="Select Type"
                >
                </v-select>
            </div>
            <div class="col-md-2">
                <v-select
                    :options="getClassificationNames(suppliers)"
                    v-model="productListState.selectedClassifications.supplier"
                    label="name"
                    clearable
                    placeholder="Select Supplier"
                >
                </v-select>
            </div>
            <div class="col-md-2">
                <v-select
                    :options="getClassificationNames(vendors)"
                    v-model="productListState.selectedClassifications.vendor"
                    label="name"
                    clearable
                    placeholder="Select Vendor"
                >
                </v-select>
            </div>
            <div class="col-md-2">
                <v-select
                    :options="getClassificationNames(segments)"
                    v-model="productListState.selectedClassifications.segment"
                    label="name"
                    clearable
                    placeholder="Select Segment"
                    @input="$emit('getSubsegments', productListState.selectedClassifications.segment)"
                >
                </v-select>
            </div>
            <div class="col-md-2">
                <v-select
                    :options="getClassificationNames(subSegments)"
                    v-model="productListState.selectedClassifications.subsegment"
                    label="name"
                    clearable
                    placeholder="Select Subsegment"
                >
                </v-select>
            </div>
            <div class="col-md-2">
                <v-select
                    :options="['true', 'false']"
                    v-model="productListState.selectedClassifications.forSale"
                    label="name"
                    clearable
                    placeholder="Select For Sale"
                >
                </v-select>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-md-12">
                <div class="table-responsive table-bordered table-hover">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Description</th>
                                <th>Product Definition</th>
                                <th>Type</th>
                                <th>Supplier</th>
                                <th>Vendor</th>
                                <th>Segment</th>
                                <th>Subsegment</th>
                                <th>For Sale</th>
                            </tr>
                        </thead>
                        <tbody v-if="filteredProducts.length > 0">
                            <tr 
                                v-for="product in filteredProducts" 
                                :key="product.productNumber" 
                                @click="$emit('productClicked', product)"
                            >
                                <td>{{ product.productNumber }}</td>
                                <td>{{ product.description }}</td>
                                <td>
                                    <span class="text-bold">{{ product.productDefinition }}</span class="text-bold">
                                </td>
                                <td>
                                    <span class="badge badge-success">{{ product.classificationType }}</span>
                                </td>
                                <td>
                                    <span class="badge badge-primary">{{ product.supplier.name }}</span>
                                </td>
                                <td>
                                    <span class="badge badge-secondary">{{ product.vendor.name }}</span>
                                </td>
                                <td>
                                    <span class="badge badge-info">{{ product.segment.name }}</span>
                                </td>
                                <td>
                                    <span class="badge badge-info">{{ product.subsegment.name }}</span>
                                </td>
                                <td>
                                    <span class="text-bold">{{ product.forSale }}</span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else-if="isFetchingData">
                            <tr>
                                <td colspan="9">
                                    <DataLoader :loadingText="'Fetching Products...'"/>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="9">No products to display</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataLoader from '@/components/GeneralComponents/DataLoader.vue';

import { ProductListState } from '@/stores/pages/Admin/ProductList.ts';

export default {
    components: {
        DataLoader
    },
    props: {
        products: {
            type: Array,
            required: true,
        },
        productVersion: {
            type: String,
            required: true,
        },
        types: {
            type: Array,
            required: true,
        },
        suppliers: {
            type: Array,
            required: true,
        },
        vendors: {
            type: Array,
            required: true,
        },
        segments: {
            type: Array,
            required: true,
        },
        subSegments: {
            type: Array,
            required: true,
        },
        isFetchingData: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        const productListState = ProductListState();
        return {
            productListState,
        }
    },
    computed: {
        filteredProducts() { 
            let filteredData = this.products;            

            if (this.productListState.searchInput) {
                filteredData = this.products.filter((p) => 
                    p.productNumber.toString().includes(this.productListState.searchInput) ||
                    p.description.toLowerCase().includes(this.productListState.searchInput.toLowerCase()) ||
                    p.productDefinition.toLowerCase().includes(this.productListState.searchInput.toLowerCase())
                );
            };     
            
            if (this.productListState.selectedClassifications.classificationType) {
                filteredData = this.products.filter((p) => 
                    p.classificationType.toLowerCase() === this.productListState.selectedClassifications.classificationType.toLowerCase()
                );
            };

            if (this.productListState.selectedClassifications.supplier) {
                filteredData = this.products.filter((p) => 
                    p.supplier.name.toLowerCase() === this.productListState.selectedClassifications.supplier.toLowerCase()
                );
            };


            if (this.productListState.selectedClassifications.vendor) {
                filteredData = this.products.filter((p) => 
                    p.vendor.name.toLowerCase() === this.productListState.selectedClassifications.vendor.toLowerCase()
                );
            };

            if (this.productListState.selectedClassifications.segment) {
                filteredData = this.products.filter((p) => 
                    p.segment.name.toLowerCase() === this.productListState.selectedClassifications.segment.toLowerCase()
                );
            };

            if (this.productListState.selectedClassifications.subsegment) {
                filteredData = this.products.filter((p) => 
                    p.subsegment.name.toLowerCase() === this.productListState.selectedClassifications.subsegment.toLowerCase()
                );
            };

            if (this.productListState.selectedClassifications.forSale) {
                filteredData = this.products.filter((p) => 
                    p.forSale.toString().toLowerCase() === this.productListState.selectedClassifications.forSale.toLowerCase()
                );
            }; 
                        
            return filteredData;
        },
    },
    methods: {
        getClassificationNames: function(arr) {
            let names = [];

            arr.forEach((c) => {
                names.push(c.name);
            });

            return names;
        }
    }
}
</script>