<template>
    <div>
      <div class="row">
        <div class="col-md-12">
          <button v-if="userEditRight" type="button" class="btn btn-info" @click="addNewPackage()">
            Create New DNS View
          </button>
        </div>
      </div>
      <div class="row row-spacing">
        <div class="col-md-12">
          <div
            class="table-responsive table-bordered"
            v-if="dnsViews.length > 0"
          >
            <table class="table">
              <thead>
                <tr class="text-nowrap">
                  <th>Name</th>
                  <th>Description</th>
                  <th>Client Number</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="dnsView in dnsViews"
                  :key="dnsView.dnsViewNumber"
                >
                  <td>{{ dnsView.name }}</td>
                  <td>{{ dnsView.description }}</td>
                  <td>{{ dnsView.clientNumber }}</td>
                  <td class="text-center">
                    <button
                      v-if="userEditRight"
                      v-tooltip="'Edit Package'"
                      type="button"
                      @click="editPackage(dnsView)"
                      class="btn btn-sm btn-info m-1"
                    >
                      <em class="fa fa-edit fa-fw"></em>
                    </button>
                    <button
                      v-if="userEditRight"
                      v-tooltip="'Delete Package'"
                      type="button"
                      @click="deletePackage(dnsView)"
                      class="btn btn-sm btn-primary"
                    >
                      <em class="fa fa-trash fa-fw"></em>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="alert alert-info">
            No Router Rental Packages Available
          </div>
        </div>
      </div>
      <ModifyDnsViewModal
        ref="modifyPackageModal"
        :pricePackage="pricePackage"
        @packageEdited="loadDnsViews()"
      />
    </div>
  </template>
  
  <script>
  import PriceFunctions from "@/components/Admin/PriceFunctions";
  import ModifyDnsViewModal from "@/components/Admin/Modals/ModifyDnsViewModal";
  import AddPriceModal from "@/components/Admin/Modals/AddPriceModal";
  
  export default {
    extends: PriceFunctions,
    components: {
        ModifyDnsViewModal,
    },
    props: {
      productNumber: {
        type: Number,
        required: true,
      },
      userEditRight: {
        type: Boolean,
        required: true
      },
    },
    data() {
      return {
        dnsViews: [],
        period: this.getCurrentPeriod(),
        rowPrice: [],
        newPricePackage: {},
        pricePackage: {},
        newPrice: {},
      };
    },
    mounted() {
      this.loadDnsViews();
    },
    methods: {
      loadDnsViews() {
        this.$http
          .get(
            this.$config.aimsAPI +
              "pricing/dnsviews"
          )
          .then(
            (response) => {
              this.newPricePackage = response.data;
              this.dnsViews = response.data.dnsviews;
            },
            (error) => {
              console.error(error);
              this.showError("Error Fetching DNS Views", error);
            }
          );
      },
      addNewPackage() {
        this.pricePackage = this.deepCopyObject(this.newPricePackage);
        this.$refs.modifyPackageModal.open();
      },
      editPackage(dnsView) {
        this.pricePackage = this.deepCopyObject(dnsView);
        this.$refs.modifyPackageModal.open();
      },
      savePackage(dnsView) {
        this.saveData(dnsView).then(
          () => {
            this.showSuccess("Successfully Saved DNS View");
            this.loadDnsViews();
          },
          (error) => {
            this.showError("Error Saving DNS View", error);
          }
        );
      },
      deletePackage(dnsView) {
        this.$swal({
          title: "Are you sure you want to delete this DNS View?",
          type: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            this.$http.delete( this.$config.aimsAPI + "pricing/dnsviews/" + dnsView.dnsViewNumber)
              .then(
                (response) => {
                  this.showSuccess(response.data.message);
                  this.loadDnsViews();
                },
                (error) => {
                  this.showError("Error Deleting DNS View", error);
                }
              );
          }
        });
      },
    },
  };
  </script>
  