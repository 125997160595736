<template>
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-4 pl-0">
                <v-select
                    :options="billingMonths"
                    v-model="selectedBillingMonth"
                    :get-option-label="getLabel"
                    @input="filterUsage(selectedBillingMonth)"
                    placeholder="Select billing month"
                >
                </v-select>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Bundle usage (GB)</th>
                            <th>Over usage (GB)</th>
                            <th>Total usage (GB)</th>
                        </tr>
                    </thead>
                    <tbody v-if="apnUsage.length > 0">
                        <tr v-for="usage in apnUsage" :key="usage.apnBulkUsageNumber">
                            <td>{{ sizeBytesToGb(usage.bundleUsage) }}</td>
                            <td>{{ sizeBytesToGb(usage.overUsage) }}</td>
                            <td>{{ sizeBytesToGb(usage.totalUsage) }}</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="3">No usage</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            billingMonths: [],
            selectedBillingMonth: 0,
            apnUsage: []
        }
    },
    mounted() {
        this.getBillingMonths();
    },
    methods: {
        getBillingMonths: function() {
            const hostUrl = this.$config.aimsAPI;

            this.$http.get(`${hostUrl}aws/billingmonths`).then(
                (response) => {
                    this.billingMonths = response.data;                      
                },
                (error) => {
                    console.error(error);
                    this.showError('Error fetching AWS billing months', error);
                }
            );
        },
        filterUsage: function(period) {
            const hostUrl = this.$config.aimsAPI;  

            this.$http.get(`${hostUrl}ionline/usage?period=${period}`).then(
                (response) => {                  
                    this.apnUsage = response.data;                    
                },
                (error) => {
                    console.error(error);
                    this.showError('Error fetching Apn bulk usage', error);
                }
            );  
        },
        getLabel: function(period) {
            return this.prettifyBillingPeriod(period);
        },
    }
}
</script>

<style lang="scss" scoped>

</style>