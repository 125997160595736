<template>
  <modal :title="this.modalTitle" ref="modifyRecord" @save="saveDnsRecord()">
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label">Address</label>
        <input
          type="text"
          class="form-control"
          v-model="dnsRecord.address"
          disabled
        />
      </div>
      <div class="form-group col-md-10">
        <label class="control-label">IP Address</label>
        <input
          type="text"
          class="form-control"
          v-model="dnsRecord.forwardAddress"
          disabled
        />
      </div>
      <div class="form-group col-md-10">
        <label class="control-label">Name</label>
        <input type="text" class="form-control" v-model="dnsRecord.name" />
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
export default {
  props: {
    dnsRecord: {
      type: Object,
      required: true,
    },
    modalTitle: {
      type: String,
      required: true,
    },
    ipvType: {
      type: String,
      required: true,
    },
  },
  components: {
    Modal,
  },
  data() {
    return {};
  },
  methods: {
    open: function () {
      this.$refs.modifyRecord.open();
    },
    saveDnsRecord: function() {
      this.$refs.modifyRecord.isLoading();
      const ipvType = this.ipvType.toLowerCase();
      const hostUrl = this.$config.aimsAPI;
      const recordObject = this.dnsRecord;
      this.$http
        .put(`${hostUrl}reverse/zones/${ipvType}/records`, recordObject)
        .then(
          (response) => {
            this.$refs.modifyRecord.close();
            this.showSuccess("Successfully edited Record");
            this.$emit("recordsLoaded");
          },
          (error) => {
            this.showError("Error Saving Record", error);
            console.error(error);
          }
        );
    },
  },
};
</script>
