<template>
  <div class="card card-default">
    <div class="card-header">
      <h3>Cabinet Power Billing</h3>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-10 row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Cabinet:</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  disabled
                  v-model="saleDefinition.definition.cabinetPowerBilling.cabinetRental.cabinetLabel"
                />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Included kWh:</label>
              <div class="input-group">
                <input
                  type="number"
                  class="form-control"
                  v-model="saleDefinition.definition.cabinetPowerBilling.includedKwh"
                />
              </div>
            </div>
          </div>
          <div class="form-row col-md-2 mt-3" v-allowed:view="['STAFF']">
            <div class="form-group row-spacing col-auto">
              <button type="button" @click="saveSaleDefinition()" class="btn btn-success">Save</button>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="saleDefinition.definition.cabinetPowerBilling.cabinetPowerEntries">
          <div class="table-responsive table-bordered" v-if="saleDefinition.definition.cabinetPowerBilling.cabinetPowerEntries.length > 0">
            <table class="table">
              <thead>
                <tr>
                  <th>Month</th>
                  <th v-allowed:view="['STAFF']">Cost</th>
                  <th>kWh Used</th>
                  <th>Total (Excl. VAT)</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="powerEntry in saleDefinition.definition
                    .cabinetPowerBilling.cabinetPowerEntries"
                  :key="powerEntry.cabinetPowerEntryNumber"
                >
                  <td>{{ prettifyBillingPeriod(powerEntry.billingMonth) }}</td>
                  <td v-allowed:view="['STAFF']">{{ currencyFormat(powerEntry.costExclVat)}}</td>
                  <td>{{ decimalFormat(powerEntry.kwhConsumed) }}</td>
                  <td>{{ currencyFormat(powerEntry.priceExclVat) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="alert alert-info">No Cabinet Power Entries</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SaleFunctions from "@/pages/Services/SaleFunctions";
export default {
  extends: SaleFunctions,
  data() {
    return {
      saleNumber: 0,
      saleDefinition: {
        definition: {
          cabinetPowerBilling: {
            cabinetRental: {},
          },
          cabinetPowerEntries: [],
        },
      },
    };
  },
  mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.getDefinition();
  },
  methods: {
    getDefinition: function() {
      this.getSaleDefinition(this.saleNumber).then(
        (success) => {
          this.saleDefinition = success.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
    },  
    saveSaleDefinition: function() {
      this.$http.put(this.$config.aimsAPI + "sales/definition/" + this.saleNumber + "/update", 
        this.saleDefinition).then(
          (response) => {
            this.showSuccess("Save Success");
            this.saleDefinition = response.data;
            this.getDefinition();
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving Sale Definition", error);
          }
        );
    }
    }
  };
</script>

<style>
</style>