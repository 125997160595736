<script>
export default {
  methods: {
    getSetupFee(data, period) {
      if (data) {
        if (data.length > 0) {
          let price = data.filter((price) => period >= price.validFrom);
          price.forEach((newPrice) => {
            if (newPrice.validUntil != 0) {
              price = price.filter((price) => period <= price.validUntil);
            } else {
              price = newPrice;
            }
          });
          if (Array.isArray(price)) {
            if (price.length > 0) {
              return this.currencyFormat(
                parseFloat(price[0].setupfee)
              );
            } else {
              return "R " + 0;
            }
          } else {
            return this.currencyFormat(
              parseFloat(price.setupfee)
            );
          }
        } else {
          return "R " + 0;
        }
      } else {
        return 0;
      }
    },
    getSetupFeeExclVat(data, period) {
      if (data) {
        if (data.length > 0) {
          let price = data.filter((price) => period >= price.validFrom);
          price.forEach((newPrice) => {
            if (newPrice.validUntil != 0) {
              price = price.filter((price) => period <= price.validUntil);
            } else {
              price = newPrice;
            }
          });
          if (Array.isArray(price)) {
            if (price.length > 0) {
              return this.currencyFormat(
                parseFloat(price[0].setupFeeExclVat)
              );
            } else {
              return "R " + 0;
            }
          } else {
            return this.currencyFormat(
              parseFloat(price.setupFeeExclVat)
            );
          }
        } else {
          return "R " + 0;
        }
      } else {
        return 0;
      }
    },
    getPrice(data, period, vat) {
      if (data) {
        if (data.length > 0) {
          let price = data.filter((price) => period >= price.validFrom);
          price.forEach((newPrice) => {
            if (newPrice.validUntil != 0) {
              price = price.filter((price) => period <= price.validUntil);
            } else {
              price = newPrice;
            }
          });
          if (Array.isArray(price)) {
            if (price.length > 0) {
              if (vat) {
                return this.timesVAT(
                  parseFloat(price[0].priceExclVat)
                );
              } else {
                return this.currencyFormat(
                  parseFloat(price[0].priceExclVat)
                );
              }
            } else {
              return "R " + 0;
            }
          } else {
            if (vat) {
              return this.timesVAT(
                parseFloat(price.priceExclVat)
              );
            } else {
              return this.currencyFormat(
                parseFloat(price.priceExclVat)
              );
            }
          }
        } else {
          return "R " + 0;
        }
      } else {
        return 0;
      }
    },
    getCurrencyPrice(data, period, currency) {
      if (data) {
        if (data.length > 0) {
          let price = data.filter((price) => period >= price.validFrom);
          price.forEach((newPrice) => {
            if (newPrice.validUntil != 0) {
              price = price.filter((price) => period <= price.validUntil);
            } else {
              price = newPrice;
            }
          });
          if (Array.isArray(price)) {
            if (price.length > 0) {
              return (
                currency +
                " " +
                parseFloat(price[0].priceExclVat).toFixed(2)
              );
            }
          } else {
            return (
              currency +
              " " +
              parseFloat(price.priceExclVat).toFixed(2)
            );
          }
        } else {
          return currency + " " + 0;
        }
      } else {
        return 0;
      }
    },
    saveData(pricePackage) {
      return this.$http.post(
        this.$config.aimsAPI + "pricing/pricepackages/save",
        pricePackage
      );
    },
    saveCurrencyData(pricePackage) {
      return this.$http.post(
        this.$config.aimsAPI + "pricing/currencypricepackages/save",
        pricePackage
      );
    },
  },
};
</script>