<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row row-spacing">
        <div
          class="col-md-4"
          v-for="widget in dashboardLayout.widgets"
          :key="widget.title"
        >
          <div class="card border-0">
            <div class="row row-flush">
              <div
                :class="getWidgetColour(widget)"
                class="
                  col-4
                  text-center
                  d-flex
                  align-items-center
                  justify-content-center
                  rounded-left
                "
              >
                <em><i :class="getWidgetIcon(widget)"></i></em>
              </div>
              <div class="col-8">
                <div class="card-body text-center">
                  <h4 class="mt-0">{{ widget.value }}</h4>
                  <p class="mb-0 text-muted">{{ widget.title }}</p>
                  <small class="">{{ widget.subtitle }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-spacing">
        <div class="col-md-4">
          <WidgetContacts />
        </div>
        <div class="col-md-4">
          <WidgetEscalations />
        </div>
        <div class="col-md-4">
          <WidgetSegments />
        </div>
        <div class="col-md-6">
          <WidgetDocuments />
        </div>
        <div class="col-md-6">
          <WidgetWorkorders />
        </div>
        <div :class="clients.length > 0 ? 'col-md-4' : 'col-md-6'">
          <WidgetTickets />
        </div>
        <div class="col-md-4" v-if="clients.length > 0">
          <WidgetResellerClients :clients="clients" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientFunctions from "@/pages/ClientFunctions";
import WidgetSegments from "@/components/Dashboard/WidgetSegments";
import WidgetPipedrive from "@/components/Dashboard/WidgetPipedrive";
import WidgetEscalations from "@/components/Dashboard/WidgetEscalations";
import WidgetContacts from "@/components/Dashboard/WidgetContacts";
import WidgetTickets from "@/components/Dashboard/WidgetTickets";
import WidgetWorkorders from "@/components/Dashboard/WidgetWorkorders";
import WidgetResellerClients from "@/components/Dashboard/WidgetResellerClients";
import WidgetDocuments from "@/components/Dashboard/WidgetDocuments";

export default {
  extends: ClientFunctions,
  components: {
    WidgetSegments,
    WidgetPipedrive,
    WidgetEscalations,
    WidgetContacts,
    WidgetTickets,
    WidgetWorkorders,
    WidgetResellerClients,
    WidgetDocuments,
  },
  data() {
    return {
      selectedClient: {},
      dashboardLayout: {},
      clients: [],
    };
  },
  mounted() {
    const clientNumber = this.$route.params.clientNumber;
    this.$http
      .get(this.$config.aimsAPI + "dashboards/getclient/" + clientNumber)
      .then(
        (response) => {
          this.selectedClient = response.data;
          const clientName = this.getClientName(this.selectedClient);
          this.$root.$emit(
            "SET_PAGE_TITLE",
            clientName + " [" + this.selectedClient.type + "]"
          );
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Client for Dashboard", error);
        }
      );

    //get dashboard for generic widgets
    this.$http
      .get(this.$config.aimsAPI + "dashboards/client/" + clientNumber)
      .then(
        (response) => {
          this.dashboardLayout = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Dashboard Layout", error);
        }
      );

    //get reseller clients
    this.$http
      .get(this.$config.aimsAPI + "dashboards/reseller/clients/" + clientNumber)
      .then(
        (response) => {
          this.clients = response.data;
        },
        (error) => {
          this.showError("Error fetching Reseller Clients ", error);
          console.error(error);
        }
      );
  },
  methods: {
    // getDescription(client) {
    //     return client.firstNames + " " + client.lastName;
    // },
    getWidgetIcon(widget) {
      return `fa fa-${widget.icon} fa-2x`;
    },
    getWidgetColour(widget) {
      switch (widget.colour) {
        case "green":
          return "bg-green";
        case "blue":
          return "bg-info";
        case "orange":
          return "bg-warning";
        case "gold":
          return "bg-gold";
        case "grey":
          return "bg-primary";
        case "dark-blue":
          return "bg-primary-dark";
        default:
          break;
      }
    }
  },
};
</script>

<style scoped>
.hover:hover {
  background: rgba(0, 0, 0, 0.15);
  color: red !important;
}
</style>