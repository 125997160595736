<template>
    <modal :title="liquidAccount.liquidCustomerNumber ? 'Edit Liquid Account' : 'Add New Liquid Account'"  ref="accountModal" @save="saveAccount()">
        <div class="row">
            <div class="form-group col-md-10">
                <label for="" class="control-label">Liquid Customer Account ID</label>
                <input 
                    type="text"
                    class="form-control"
                    placeholder="Enter Liquid Customer Account ID..."
                    v-model="liquidAccount.customerAccountId"
                    :disabled="liquidAccount.liquidCustomerNumber!=0"
                />
            </div>
            <div class="form-group col-md-10">
                <label for="" class="control-label">Liquid Customer Name</label>
                <input 
                    type="text"
                    class="form-control"
                    placeholder="Enter Liquid Customer Name..."
                    v-model="liquidAccount.customerName"
                />
            </div>
            <div class="form-group col-md-10">
                <div class="row">
                    <div class="col-md-6">
                        <label for="" class="control-label">Admin Username</label>
                        <input type="text" class="form-control" placeholder="Admin credentials..." v-model="liquidAccount.adminUsername"/>
                    </div>
                    <div class="col-md-6">
                        <label for="" class="control-label">Admin Password</label>
                        <input type="password" class="form-control" placeholder="Admin credentials..." v-model="liquidAccount.adminPassword"/>
                    </div>
                </div>
            </div>
            <div class="form-group col-md-10">
                <label for="" class="control-label">2FA Secret Key</label>
                <input type="password" class="form-control" placeholder="2 FA Secret Key" v-model="liquidAccount.secretKey"/>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
export default {
    props: {
        liquidAccount: {
            type: Object,
            required: true,
        },
    },
    components: {
        Modal,
    },
    data() {
        return {
        }
    },
    methods: {
        open() {
          this.$refs.accountModal.open();
        },
        saveAccount: function() {
            let call = "create";
            (this.liquidAccount.liquidCustomerNumber!=0) ? call = "update" : call = "create";
            this.$refs.accountModal.isLoading();
            this.$http.post(this.$config.aimsAPI + "clients/integrations/liquid/" + call,  this.liquidAccount).then(
                (response) => {
                    this.$refs.accountModal.close();
                    if (this.liquidAccount.liquidCustomerNumber!=0) {
                        this.showSuccess("Successfully Edited Liquid Account");
                    } else {
                        this.showSuccess("Successfully Created Liquid Account");
                    }
                    this.$emit("loadLiquidCustomers");
                },
                (error) => {
                    this.showError("Error Saving Liquid Account", error);
                    console.error(error);
                }
            ).finally(() => {
              this.$refs.accountModal.isLoading();
            });
        }
    }
}
</script>

<style lang="scss" scoped>

</style>