<template>
    <div class="card">
      <div class="card-body">
        <div class="row">
            <div class="col-md-12" v-if="mpls && mpls.fttbMplsNumber >= 0">
                <div class="row" >
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Point to Point Ipv4</label>
                                    <input class="form-control" v-model="mpls.p2pIpv4Range"/>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Routed Ipv4</label>
                                    <input class="form-control" v-model="mpls.routedIpv4Range"/>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Point to Point Ipv6</label>
                                    <input class="form-control" v-model="mpls.p2pIpv6Range"/>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Routed Ipv6</label>
                                    <input class="form-control" v-model="mpls.routedIpv6Range"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                            <div v-if="mpls.fttbMplsNumber != 0 && mpls.mplsConnectivityPackage">
                                <FttbConnectivityPackage :fttbConnectivityPackage="mpls.mplsConnectivityPackage"/>
                            </div>
                    </div>
                </div>
                <div class="row"> 
                    <div class="col-md-2">
                        <button class="btn btn-info" type="button" @click="saveNewMpls()">Save</button>
                    </div>
                    <div class="col-md-4">
                        <button class="btn btn-primary pull-right" type="button" @click="removeMpls"><em class="fa fa-trash fa-fw"></em> Remove</button>
                    </div>
                </div>
            </div>
            <div v-else class="col-md-12">
                <div class="row row-spacing">
                    <div class="col-md-6">
                        <button class="btn btn-info" type="button" @click="addMpls()">Add MPLS</button>
                    </div>
                </div>
                <div class="row row-spacing">
                    <div class="col-md-6 row-spacing">
                        <label>No MPLS added to service</label>            
                    </div>    
                </div>
            </div>
        </div>
    </div>    

    <modal
        title="Add New Mpls"
        ref="addNewMpls"
        @save="saveNewMpls()"
        :disable-save="inputValueCheck == null"
    >
        <div class="row">
            <div class="form-group col-md-12">
                <label>Select Connectivity Package</label>
                <select class="form-control" v-model="newMpls.connectivityPackageNumber">
                    <option :value="fttbConnectivityPackage.connectivityPackageNumber" 
                        v-for="fttbConnectivityPackage in mplsConnectivityPackages" 
                        :key="fttbConnectivityPackage.connectivityPackageNumber">
                        {{fttbConnectivityPackage.description}}
                    </option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label>Point to Point Ipv4</label>
                <input class="form-control" v-model="newMpls.p2pIpv4Range"/>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label>Routed Ipv4</label>
                <input class="form-control" v-model="newMpls.routedIpv4Range"/>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label>Point to Point Ipv6</label>
                <input class="form-control" v-model="newMpls.p2pIpv6Range"/>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label>Routed Ipv6</label>
                <input class="form-control" v-model="newMpls.routedIpv6Range"/>
            </div>
        </div>
    </modal>      
  </div>
</template>

<script>
import FttbConnectivityPackage from '@/components/ObjParts/Selected/FttbConnectivityPackage'
import Modal from "@/components/GeneralComponents/Modal";
export default {
    components:{
        FttbConnectivityPackage,
        Modal
    },
    props: {
        mpls: {
            type: Object,
            required: true
        },
        mplsConnectivityPackages: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            newMpls: {
                p2pIpv4Range: "",
                routedIpv4Range: "",
                p2pIpv6Range: "",
                routedIpv6Range: "",
                connectivityPackageNumber: 0,
            },
            selectedMplsPackageNumber: ""
        }
    },
    computed: {
        inputValueCheck() {
            if (((this.newMpls.p2pIpv4Range && this.newMpls.routedIpv4Range) || 
                (this.newMpls.p2pIpv6Range && this.newMpls.routedIpv6Range)) 
                && this.newMpls.connectivityPackageNumber) {
                return true;
            }
        }
    },
    methods: {
        addMpls(){
            this.$refs.addNewMpls.open();
        },
        saveNewMpls() {
            this.$emit('saveMpls', {
                fttbMplsNumber: 0,
                name: 'FTTB MPLS',
                class: 'za.co.adept.aims.fibre.business.components.mpls.FttbMpls',
                connectivityPackageNumber: this.newMpls.connectivityPackageNumber,
                p2pIpv4Range: this.newMpls.p2pIpv4Range,
                routedIpv4Range: this.newMpls.routedIpv4Range,
                p2pIpv6Range: this.newMpls.p2pIpv6Range,
                routedIpv6Range: this.newMpls.routedIpv6Range,
                userReadOnly: false
            })
            this.$refs.addNewMpls.close();
        },
        removeMpls(){
            this.$swal({
                title: `Are you sure you want to remove MPLS?`,
                type: 'warning',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.value) {
                    this.$emit('removeMpls')
                }
            });
        }
    }
}
</script>

<style>
.pull-right{
    float: right;
}
</style>