<template>
  <div class="row">
    <div class="col-md-8">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template slot="domain">
            <form novalidate data-vv-scope="domain">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('domain.name'),
                    }"
                  >
                    <label>Domain Name</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="name"
                      placeholder="Domain Name"
                      v-model="filledInSaleDefinition.requiredObjs.domain.name"
                    />
                    <span class="has-error" v-if="errors.has('domain.name')">{{
                      errors.first("domain.name")
                    }}</span>
                  </div>
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('domain.plan'),
                    }"
                  >
                    <label>Plan</label>
                    <v-select
                      :options="
                        filledInSaleDefinition.supportingEnums.domain.plan
                      "
                      label="description"
                      valueProp="name"
                      name="plan"
                      v-validate="'required'"
                      placeholder="Select Plan"
                      v-model="filledInSaleDefinition.requiredObjs.domain.plan"
                    ></v-select>
                    <span class="has-error" v-if="errors.has('domain.plan')">{{
                      errors.first("domain.plan")
                    }}</span>
                  </div>
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('domain.renewal'),
                    }"
                  >
                    <label class="col-form-label">Renewal Date</label>
                    <div class="input-group">
                      <DateInput
                        type="date"
                        v-model="filledInSaleDefinition.requiredObjs.domain.renewalDate"
                        name="renewal"
                        v-validate="'required'"
                        style="width: 100%"
                      />
                      <div class="input-group-append">
                          <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                      </div>
                    </div>
                    <span
                      class="has-error"
                      v-if="errors.has('domain.renewal')"
                      >{{ errors.first("domain.renewal") }}</span
                    >
                  </div>
                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
import DateInput from "@/components/GeneralComponents/InputControls/DateInput";
export default {
  components: {
    ServiceWizardLayout,
    DateInput,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "domain",
          displayName: "Google Workspace Domain",
          icon: "fa fa-info",
        },
      ],
      filledInSaleDefinition: {
        requiredObjs: {
          domain: {},
        },
      },
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
  },
  methods: {
    onComplete() {
      this.$emit("createSale", this.filledInSaleDefinition);
    },
    validateStep(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
</style>
