<template>
    <div class="col-md-12">
        <div class="row" v-allowed:view="['STAFF']">
            <button class="btn btn-info" @click="addRealm">Add Realm</button>
        </div>
        <div class="row row-spacing">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Provider</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="realms.length > 0">
                        <tr v-for="realm in realms" :key="realm.apnRealmNumber">
                            <td>{{ realm.provider }}</td>
                            <td class="text-center">
                                <button class="btn btn-primary" @click="deleteRealm(realm)"  v-allowed:view="['STAFF']">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="2">No Realms</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <IonlineRealmModal 
            ref="realmModal"
            :modalTitle="modalTitle"
            :realm="realm"
            :providers="providers"
            @saveRealm="saveRealm"
        />
    </div>
</template>

<script>
import IonlineRealmModal from '@/components/Admin/Modals/IonlineRealmModal.vue';

export default {
    components: {
        IonlineRealmModal
    },
    props: {
        apn: {
            type: Object,
            required: true
        },
        realms: {
            type: Array,
            required: true
        },
        realmObj: {
            type: Object,
            required: true
        },
        providers: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            modalTitle: "",
            realm: {},
        }
    },
    methods: {
        addRealm: function() { 
            this.modalTitle = "Add Realm";
            this.realm = this.deepCopyObject(this.realmObj);
            this.realm.apnNumber = this.apn.apnNumber;
            this.$refs.realmModal.open(); 
        },
        deleteRealm: function(realm) {
            const hostUrl = this.$config.aimsAPI;
            const saleNumber = this.apn.saleNumber;

            this.$swal({
                title: "Are you sure you want to delete this realm?",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
            }).then((result) => {  
                if (result.value) {
                    this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/delete`, realm).then(
                        (response) => {
                            this.showSuccess("Realm successfully deleted");
                            this.$emit("reloadSale");
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error deleting realm", error);
                    });
                }                  
            });
        },
        saveRealm: function(realm) {
            const hostUrl = this.$config.aimsAPI;
            const saleNumber = this.apn.saleNumber;

            if (realm.apnRealmNumber === 0) {
                this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/create`, realm).then(
                    (response) => {
                        this.showSuccess("Successfully added realm");
                        this.$emit('reloadSale');
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error adding new realm", error);
                    }
                ).finally(() => {
                    this.$refs.realmModal.$refs.modifyRealmModal.close();
                });
            }   
        }
    }
}
</script>

<style lang="scss" scoped>

</style>