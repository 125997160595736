<template>
  <div class="card border-danger row-spacing" v-if="alerts.length > 0">
    <div
      class="card-header bg-danger"
      style="cursor: pointer"
      data-tool="card-collapse"
    >
      <a class="float-right" data-tool="card-collapse">
        <em class="fa fa-minus"></em>
      </a>
      <div class="card-title">System Alerts</div>
    </div>
    <div class="card-wrapper collapse show">
      <div class="card-body">
        <div class="row" v-if="sources.length > 1">
          <div class="col-md-3 col-md-offset-9">
            <v-select
              :options="sources"
              placeholder="Filter By Source"
              v-model="selectedSource"
              class="mb-2"
              clearable
            ></v-select>
          </div>
        </div>
        <div class="row row-spacing">
          <div class="col-md-12">
            <div class="table-responsive table-bordered">
              <table class="table table-bordered">
                <thead>
                  <tr class="text-nowrap">
                    <th>Source</th>
                    <th>Problem</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="alert in filteredAlerts" :key="alert.alertNumber">
                    <td>{{ alert.source }}</td>
                    <td>{{ alert.description }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    sources() {
      return [...new Set(this.alerts.map((alert) => alert.source))];
    },
    filteredAlerts() {
      return this.selectedSource
        ? this.alerts.filter((alert) => alert.source == this.selectedSource)
        : this.alerts;
    },
  },
  data() {
    return {
      alerts: [],
      selectedSource: null,
    };
  },
  mounted() {
    this.$http.get(this.$config.aimsAPI + "alerts").then(
      (response) => {
        this.alerts = response.data;
      },
      (error) => {
        this.showError("Error fetching alerts", error);
        console.error(error);
      }
    );
  },
  methods: {},
};
</script>