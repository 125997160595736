<template>
  <ServiceScreenLayout :parts="parts" v-if="saleLoaded === true">
    <template v-slot:configuration>
      <CPanelConfiguration
        :cPanelConfiguration="saleDefinition.definition.configuration"
        :cPanelPackageOptions="saleDefinition.selectableOptions.cPanelPackage"
        :cPanelServerOptions="saleDefinition.selectableOptions.cPanelServer"
        @updateConfiguration="updateConfiguration"
      />
    </template>
    <template v-slot:cpanel-domains>
        <CPanelDomains
          :saleDefinition="saleDefinition"
          @saveSale="saveSale"
          @reloadSale="getSaleDefinition"
        />
    </template>
  </ServiceScreenLayout>
</template>

<script>
import ServiceScreenLayout from '@/layouts/ServiceScreenLayout';
import CPanelConfiguration from '@/components/ObjParts/SingleCaptured/CPanelConfiguration';
import CPanelDomains from '@/components/ObjParts/MultipleCaptured/CPanelDomains';

export default {  
    components: {
      ServiceScreenLayout,
      CPanelConfiguration,
      CPanelDomains
    },
    data() {
      return {
        saleNumber: null,
        clientNumber: null,
        saleLoaded: false,
        saleDefinition: {
          definition: {
            configuration: {}
          },
          selectableOptions: {
            cPanelPackage: [],
            cPanelServer: []
          }
        },
        parts: [
          {
            name: "configuration",
            icon: "fa fa-cogs",
            displayName: "Configuration",
            permissions: ["*"],
          },
          {
            name: "cpanel-domains",
            icon: "fa fa-registered",
            displayName: "Domains",
            permissions: ["*"],
          }
        ],
        componentKey: 0,
      }
    },
    mounted() {
      this.getSaleNumber();
      this.getClientNumber();
      this.getSaleDefinition();      
    },
    methods: {
      getSaleNumber: function() {
        this.saleNumber =  this.$route.params.saleNumber;
      },
      getClientNumber: function() {
        this.clientNumber = this.$route.params.clientNumber;
      },
      getSaleDefinition: function() {
        const hostUrl = this.$config.aimsAPI;
        const saleNumber = this.saleNumber;

        this.$http.get(`${hostUrl}sales/definition/sale/${saleNumber}`).then(
          (response) => {
            this.saleDefinition = response.data;             
            this.saleLoaded = true;
          },
          (error) => {
            console.error(error);
            this.showError("Error fetching Sale Definition", error);
          }
        )
      },
      updateConfiguration: function(configuration) {
          if (configuration.cPanelPackage.cPanelPackageNumber !== this.saleDefinition.definition.configuration.cPanelPackageNumber) {
            this.saleDefinition.definition.configuration.cPanelPackageNumber = configuration.cPanelPackage.cPanelPackageNumber;
          }

          if (configuration.cPanelServer.cPanelServerNumber !== this.saleDefinition.definition.configuration.cPanelServerNumber) {
            this.saleDefinition.definition.configuration.cPanelServerNumber = configuration.cPanelServer.cPanelServerNumber;
          }
          
          this.saveSale();
      },
      saveSale: function() {
          const hostUrl = this.$config.aimsAPI;
          const saleNumber = this.saleNumber;

          this.$validator.validateAll().then((result) => {
            if (result) {
              this.$http.put(`${hostUrl}sales/definition/${saleNumber}/update`, this.saleDefinition).then(
                (response) => {
                  this.showSuccess("Save Success");
                  this.getSaleDefinition();
                },
                (error) => {
                  console.error(error);
                  this.showError("Error Saving Sale Definition", error);
                  this.getSaleDefinition();
                }
              );
            } 
          });
      },
    },
}
</script>
