

<template>

<div class="card border-primary card-default">
    <div class="card-header">
        <h4>{{domainPackage.description}}</h4>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-md-12">
              <div class='row'>                
                 <div class="col-md-6">
                   Renewal Period:
                 </div>
                 <div class="col-md-6">
                   <strong>{{domainPackage.term}} months</strong>  
                 </div>
              </div>   
              <div class='row'>                
                 <div class="col-md-6">
                   Expired:
                 </div>
                 <div class="col-md-6">
                   <strong>{{domainPackage.expired}}</strong>  
                 </div>
              </div>   
              <div class='row'>                
                 <div class="col-md-6">
                   Integration Type:
                 </div>
                 <div class="col-md-6">
                   <strong>{{domainPackage.integrationType}}</strong>  
                 </div>
              </div>   
              <div class='row' v-allowed:view="['STAFF']">                
                 <div class="col-md-6">
                   Package Id:
                 </div>
                 <div class="col-md-6">
                   <strong>{{domainPackage.domainRegistrationPackageNumber}}</strong>  
                 </div>
              </div>   
            </div>
        </div>
    </div>
</div>

</template>

<script>
export default {
  components: {},
  props: {
    domainPackage: {
        type: Object,
        required: true
    }
  },
  data: function() {
    return {};
  },
  mounted: function() {
  },
  methods: {}
};
</script>
