import { defineStore } from "pinia"

export const ProductListState = defineStore("ProductListState", {
    state: () => ({
        showLegacy: false,

        searchInput: "",
        selectedClassifications: {},
    }),
    actions: {
        setShowLegacy(data) {
            this.showLegacy = data;
        },
        setSelectedClassifications(data) {
            this.selectedClassifications = data;
        }
    }
});