<template>
  <div>
    <div class="row mb-2">
      <button v-if="userEditRight" type="button" @click="createNewPackage()" class="btn btn-info">Add new Price</button>
    </div>
    <div class="row">
      <div class="table-responsive table-bordered" v-if="packages.length > 0">
        <table class="table col-md-8">
        <thead>
          <tr class="text-nowrap">
            <th>Description</th>
            <th>Current Price (excl. VAT)</th>
            <th>Expired</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="cPanelPackage in packages" :key="cPanelPackage.cPanelPackageNumber">
            <td>
              <input disabled type="text" v-model="cPanelPackage.description" class="form-control">
            </td>
            <td>
              {{getPrice(cPanelPackage.cpanelpackageprices, period)}}
              <span v-if="userEditRight" style="cursor: pointer" v-tooltip="'Edit Row Price'" @click="openPriceModal(cPanelPackage)"
                class="pull-right">
                <em class="fa fa-edit fa-fw text-green"></em>
              </span>
            </td>
            <td class="text-center">
              <span class="badge" :class="cPanelPackage.expired ? 'badge-warning' : 'badge-green'">
                {{ cPanelPackage.expired }}
              </span>
            </td>
            <td class="text-center">
              <button v-if="userEditRight" v-tooltip="'Edit Package'" @click="editPackage(cPanelPackage)" type="button" class="btn btn-sm btn-info m-1">
                <em class="fa fa-edit fa-fw"></em>
              </button>
              <button v-if="userEditRight" v-tooltip="'Delete Row'" type="button" @click="deletePackage(cPanelPackage)"
                class="btn btn-sm btn-primary">
                <em class="fa fa-trash fa-fw"></em>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      <div v-else class="alert alert-info col-md-12">No price packages to display</div>
    </div>
   <AddPriceModal ref="addPriceModal" :row-price="rowPrice" :new-price="newPrice"
      @addPrice="addPrice" @pricesAdded="getPackages()" />
    <GenericModifyPackage
            ref="modifyCPanelPackageModal"
            :pricePackage="cPackage"
            @packageAdded="cPanelPackageAdded"
            @packageEdited="getPackages()"
    />    
  </div>
</template>

<script>
  import AddPriceModal from '@/components/Admin/Modals/AddPriceModal'
  import GenericModifyPackage from "@/components/Admin/Modals/GenericModifyPackage";
  import PriceFunctions from './PriceFunctions'

  export default {
    extends: PriceFunctions,
    components: {
      AddPriceModal,
      GenericModifyPackage
    },
    props: {
      productNumber: {
        type: Number,
        required: true
      },
      userEditRight: {
        type: Boolean,
        required: true
      },
    },
    data() {
      return {
        period: this.getCurrentPeriod(),
        newPrice: {},
        cPackage: {},
        rowPrice: [],
        packages: [],
      }
    },
    mounted() {
      this.getPackages();
    },
    methods: {
      getPackages() {
        this.$http.get(this.$config.aimsAPI + 'pricing/cpanelpackages/' + this.productNumber)
          .then(response => {
              this.packages = response.data;
              this.setNewPrice();
            },
            error => {
              this.showError('Error fetching cPanel Packages', error)
              console.error(error);
            });
      },
      openPriceModal(cpp) {
        this.rowPrice = this.deepCopyObject(cpp.cpanelpackageprices || []);
        this.newPrice.cPanelPackageNumber = cpp.cPanelPackageNumber;
        this.$refs.addPriceModal.open();
      },
      addPrice(newPrice) {
        this.rowPrice.forEach(price => {
          if (price.validUntil == 0) {
            if (newPrice.validFrom % 100 > 1) {
              price.validUntil = newPrice.validFrom - 1;
            } else {
              price.validUntil = (newPrice.validFrom - 100) + 11;
            }
          }
        });
        this.rowPrice.push(newPrice);
        this.setNewPrice();
        this.newPrice.cPanelPackageNumber = newPrice.cPanelPackageNumber;
      },
      createNewPackage() {
        this.$http.get(this.$config.aimsAPI + "pricing/new/package/CPanelPackage")
        .then(
          (response) => {
            this.cPackage = response.data;
            this.cPackage.productNumber = this.productNumber;
            this.$refs.modifyCPanelPackageModal.open();
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Support Template", error);
          }
        );
      },
      setNewPrice() {
        this.$http.get(this.$config.aimsAPI + "pricing/new/price/CPanelPackagePrice")
          .then((response) => {
              this.newPrice = response.data;
            },
            (error) => {
              console.error(error);
              this.showError("Error Fetching Price Template", error);
            }
          );
      },
      cPanelPackageAdded(cp) {
        this.packages.push(cp);
        this.openPriceModal(cp);
      },
      editPackage(cppackage) {
        this.cPackage = this.deepCopyObject(cppackage);
        this.$refs.modifyCPanelPackageModal.open();
      },
      deletePackage(deletePackage) {
        this.$swal({
          title: 'Are you sure you want to delete this package?',
          text: deletePackage.description,
          type: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then(result => {
          if (result.value) {
            this.$http.delete(this.$config.aimsAPI + 'pricing/cpanelpackages/' + deletePackage.cPanelPackageNumber)
              .then(response => {
                  this.showSuccess('Successfully deleted cPanel Package');
                  this.getPackages();
                },
                error => {
                  this.showError('Error deleting cPanel Package', error)
                  console.error(error);
                });
          }
        })
      }
    }
  }

</script>
