<template>
  <div class="row">
    <div class="col-md-8">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template v-slot:management>
            <form novalidate data-vv-scope="management">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{ 'has-error': errors.has('management.description') }"
                  >
                    <label class="control-label">Description *</label>
                    <input
                      class="form-control"
                      name="Name"
                      v-validate="'required'"
                      v-model="filledInSaleDefinition.requiredObjs.management.description"
                    />
                    <span
                      class="has-error"
                      v-if="errors.has('management.description')"
                      >{{ errors.first("management.description") }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{ 'has-error': errors.has('management.deviceManagementPackageNumber') }"
                  >
                    <label class="control-label">Package *</label>
                    <div class="input-group">
                      <v-select
                        name="Package"
                        placeholder="Select Package"
                        v-validate="'required'"
                        label="description"
                        valueProp="deviceManagementPackageNumber"
                        v-model="
                          filledInSaleDefinition.requiredObjs.management
                            .deviceManagementPackageNumber
                        "
                        :options="
                          filledInSaleDefinition.selectableOptions
                            .devicePackage
                        "
                      ></v-select>
                    </div>
                    <span
                      class="has-error"
                      v-if="errors.has('management.deviceManagementPackageNumber')"
                      > No Package Selected </span
                    >
                  </div>
                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
export default {
  components: {
    ServiceWizardLayout,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "management",
          displayName: "Device Management",
        },
      ],
      filledInSaleDefinition: {
        requiredObjs: {
          management: {
            deviceManagementPackageNumber: 0,
            description: ""
          }
        },
        selectableOptions: {

        },
        supportingEnums: {

        },
      },
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
  },
  methods: {
    onComplete() {
      this.$emit("createSale", this.filledInSaleDefinition);
    },
    validateStep(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>
