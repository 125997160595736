<template>
  <div>
    <div class="row row-spacing">
      <div class="col-md-12 mb-2">
        <button v-if="userEditRight" type="button" class="btn btn-info" @click="addNewPackage()">
          Add New Package
        </button>
      </div>
    </div>
    <div class="table-responsive table-bordered" v-if="packages.length > 0">
      <table class="table">
        <thead>
          <tr class="text-nowrap">
            <th>Description</th>
            <th>Label</th>
            <th>Mask</th>
            <th>Price (excl. VAT)</th>
            <th>Expired</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="networkRangePackage in packages"
            :key="networkRangePackage.networkRangePackageNumber"
          >
            <td>
              <input
                disabled
                type="text"
                class="form-control"
                v-model="networkRangePackage.description"
              />
            </td>
            <td>
              <input
                :disabled="!userEditRight"
                type="text"
                class="form-control"
                v-model="networkRangePackage.label"
              />
            </td>
            <td>{{ networkRangePackage.shortMask }}</td>
            <td>
              {{
                getPrice(networkRangePackage.networkrangepackageprices, period)
              }}
              <span
                v-if="userEditRight"
                style="cursor: pointer"
                @click="openPriceModal(networkRangePackage)"
                v-tooltip="'Edit Row Price'"
                class="pull-right"
              >
                <em class="fa fa-edit fa-fw text-green"></em>
              </span> 
            </td>
            <td>
              <select
                :disabled="!userEditRight"
                v-model="networkRangePackage.expired"
                class="form-control custom-select"
              >
                <option>false</option>
                <option>true</option>
              </select>
            </td>
            <td class="text-center">
              <button
                v-if="userEditRight"
                v-tooltip="'Save Row'"
                type="button"
                @click="savePackage(networkRangePackage)"
                class="btn btn-sm btn-info m-1"
              >
                <em class="fa fa-save fa-fw"></em>
              </button>
              <button
                v-if="userEditRight"
                v-tooltip="'Delete Row'"
                type="button"
                @click="deletePackage(networkRangePackage)"
                class="btn btn-sm btn-primary"
              >
                <em class="fa fa-trash fa-fw"></em>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="alert alert-info">
      No Network Range Packages to display
    </div>
    <AddNewNetworkRangePackageModal
      ref="newPackageModal"
      :new-price-package="newPricePackage"
      @packageAdded="packageAdded"
    />
    <AddPriceModal
      ref="addPriceModal"
      :row-price="rowPrice"
      :new-price="newPrice"
      @addPrice="addPrice"
      @pricesAdded="$emit('getNetworkRangePackages')"
    />
  </div>
</template>

<script>
import AddNewNetworkRangePackageModal from "@/components/Admin/Modals/AddNewNetworkRangePackageModal";
import AddPriceModal from "@/components/Admin/Modals/AddPriceModal";
import PriceFunctions from "@/components/Admin/PriceFunctions";
export default {
  extends: PriceFunctions,
  props: {
    userEditRight: {
      type: Boolean,
      required: true
    },
    packages: {
      type: Array,
      required: true,
    },
    productNumber: {
      type: Number,
      required: true,
    },
  },
  components: {
    AddNewNetworkRangePackageModal,
    AddPriceModal,
  },
  data() {
    return {
      newPricePackage: {},
      rowPrice: [],
      newPrice: {},
      period: this.getCurrentPeriod(),
    };
  },
  methods: {
    addNewPackage() {
      this.newPricePackage = this.deepCopyObject({
        productNumber: this.productNumber,
        class: "za.co.adept.aims.connectivity.NetworkRangePackage",
      });
      this.$refs.newPackageModal.open();
    },
    packageAdded(networkRangePackage) {
      networkRangePackage.networkrangepackageprices = [];
      this.packages.push(networkRangePackage);
      this.openPriceModal(networkRangePackage);
    },
    openPriceModal(networkRangePackage) {
      this.rowPrice = this.deepCopyObject(
        networkRangePackage.networkrangepackageprices
      );
      this.newPrice = this.deepCopyObject({
        networkRangePackageNumber:
          networkRangePackage.networkRangePackageNumber,
        class: networkRangePackage.class + "Price",
        validFrom: 0,
        validUntil: 0,
      });
      this.$refs.addPriceModal.open();
    },
    addPrice(newPrice) {
      this.rowPrice.forEach((price) => {
        if (price.validUntil == 0) {
          if (newPrice.validFrom % 100 > 1) {
            price.validUntil = newPrice.validFrom - 1;
          } else {
            price.validUntil = newPrice.validFrom - 100 + 11;
          }
        }
      });
      this.rowPrice.push(newPrice);
      this.newPrice = this.deepCopyObject({
        networkRangePackageNumber: newPrice.networkRangePackageNumber,
        class: newPrice.class,
        validFrom: 0,
        validUntil: 0,
      });
    },
    savePackage(networkRangePackage) {
      this.saveData(networkRangePackage).then(
        () => {
          this.showSuccess("Successfully Saved Package");
          this.$emit("getNetworkRangePackages");
        },
        (error) => {
          this.showError("Error saving Package", error);
        }
      );
    },
    deletePackage(networkRangePackage) {
      this.$swal({
        title: "Are you sure you want to delete this price package?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$http
            .delete(
              this.$config.aimsAPI +
                "pricing/pricepackages/" +
                networkRangePackage.objKey +
                "?objClassName=" +
                networkRangePackage.class
            )
            .then(
              (response) => {
                this.showSuccess(response.data.message);
                this.$emit("getNetworkRangePackages");
              },
              (error) => {
                this.showError("Error Deleting Price Package", error);
              }
            );
        }
      });
    },
  },
};
</script>

<style>
</style>
