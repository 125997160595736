<template>
  <BasicPackagePrices :productNumber="productNumber" :userEditRight="userEditRight" />
</template>
<script>
import BasicPackagePrices from "@/components/Admin/BasicPackagePrices";

export default {
  props: {
    productNumber: {
      type: Number,
      required: true,
    },
    userEditRight: {
      type: Boolean,
      required: true
    },
  },
  components: {
    BasicPackagePrices,
  },
};
</script>

<style scoped>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #f05050 !important;
}
.nav-pills .nav-link {
  background-color: #f3f2ee;
}
a {
  color: rgba(0, 0, 0, 0.6);
}
</style>