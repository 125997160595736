import Vue from 'vue';
import VueRouter from 'vue-router';

// COMPONENT IMPORTS
import Login from '@/pages/Login';
import Otp from '@/pages/Otp';
import Callback from '@/pages/Callback.vue';
import ForgotPassword from '@/pages/ForgotPassword';
import ChangePassword from '@/pages/ChangePassword';
import AppLayout from '@/layouts/AppLayout';

import appRoutes from '@/structures/appRoutes';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/otp',
    name: 'otp',
    component: Otp
  },
  {
    path: '/oauth2',
    name: 'oauth2',
    component: Callback
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    component: ForgotPassword    
  },
  {
    path: '/changepassword/:token',
    name: 'changepassword',
    component: ChangePassword    
  },
  {
    path: '/main/',
    component: AppLayout,
    children: appRoutes
  },
  {
    path: '*', redirect: '/login'
  }
];

const router = new VueRouter({
  mode: 'history', 
  routes
});

router.beforeEach((to, from, next) => {
  if (to.path === '/oauth2') {
    next();
  } else {
    next();
  }
});

export default router;