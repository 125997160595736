<template>
  <ServiceScreenLayout :parts="parts">
    <template v-slot:did>
      <div class="card card-default">
        <div class="card-header">
            <h4>DID Number</h4>
        </div>
        <div class="row" style="margin-left: 0px">
            <div class="col-md-2">
              <div class="form-group">
                <label class="control-label">Dialing Prefix</label>
                <input class="form-control" type="text" disabled v-model="prefixDescription"/>                                            
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label">Number</label>
                <input class="form-control" type="text" v-model="saleDefinition.sale.didNumber.number"/>                            
              </div>
            </div>
        </div>  
        <div class="col-md-1">
              <div class="form-group">
                <button type="button" class="btn btn-info" @click="saveSaleDefinition()">Save</button>
              </div>
        </div>

      </div>  
    </template>
  </ServiceScreenLayout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout
  },
  watch: {
  },
  data() {
    return {
      saleNumber: null,
      prefixDescription: "",
      parts: [
        {
          name: "did",
          icon: "fa fa-phone-square",
          displayName: "International DID number",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        sale: {
          didNumber: {}
         }
      }
    };
  },
  async mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.fetchSaleDefinition(this.saleNumber).then(
      (success) => {
        this.saleDefinition = success.data;
        this.getPrefix(); 
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching Sale Definition", error);
      }
    );
  },
  methods: {
    loadSaleDefinition() {
      this.fetchSaleDefinition(this.saleNumber).then(
        (success) => {
          this.saleDefinition = success.data;
          this.getPrefix();
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
      return Promise.resolve('Success');
    },
    getPrefix() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "products/"+this.saleDefinition.sale.productNumber +
            "/getVariable/prefixDescription"
        )
        .then(
          (response) => {
            this.prefixDescription = response.data;
          },
          (error) => {
            this.showError("Error fetching Dialing Prefix", error);
            console.error(error);
          }
        );
    },
    saveSaleDefinition: function() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPIv2 +
                "sales/definition/" +
                this.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
                this.loadSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Bulk DID Service", error);
                this.loadSaleDefinition();
              }
            );
        }
      });
    },
    updateDids: function(data) {
      this.saleDefinition.sale.didNumbers = data;
      this.saveSaleDefinition();
    }
  },
};
</script>