<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template slot="rental">
            <form novalidate data-vv-scope="rental">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group" :class="{ 'has-error': errors.has('rental.datacenter'), }">
                    <label>Data Center</label>
                    <v-select
                      :options="filledInSaleDefinition.selectableOptions.dataCentre"
                      name="DataCenter"
                      v-validate="'required'"
                      placeholder="Select Data Centre"
                      v-model="filledInSaleDefinition.requiredObjs.cabinetRental.dataCentreNumber"
                      label="description"
                      valueProp="dataCentreNumber"
                    >
                    </v-select>
                    <span class="has-error" v-if="errors.has('rental.DataCenter')">{{ errors.first("rental.DataCenter") }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group" :class="{ 'has-error': errors.has('rental.label'), }">
                    <label>Cabinet Label</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="label"
                      placeholder="Cabinet Label"
                      v-model="filledInSaleDefinition.requiredObjs.cabinetRental.cabinetLabel"
                    />
                    <span class="has-error" v-if="errors.has('rental.label')">{{ errors.first("rental.label") }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group" :class="{ 'has-error': errors.has('rental.serviceID'), }">
                    <label>Service ID</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="serviceID"
                      placeholder="Service ID"
                      v-model="filledInSaleDefinition.requiredObjs.cabinetRental.serviceID"
                    />
                    <span class="has-error" v-if="errors.has('rental.serviceID')">{{ errors.first("rental.serviceID") }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group" :class="{ 'has-error': errors.has('rental.powerServiceID'), }">
                    <label>Power Service ID</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="powerServiceID"
                      placeholder="Power Service ID"
                      v-model="filledInSaleDefinition.requiredObjs.cabinetRental.powerServiceID"
                    />
                    <span class="has-error" v-if="errors.has('rental.powerServiceID')">{{ errors.first("rental.powerServiceID") }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group" :class="{ 'has-error': errors.has('rental.rackSize'), }">
                    <label>Rack Size</label>
                    <v-select
                      :options="filledInSaleDefinition.supportingEnums.cabinetRental.rackSize"
                      name="rackSize"
                      v-validate="'required'"
                      placeholder="Select Rack Size"
                      v-model="filledInSaleDefinition.requiredObjs.cabinetRental.rackSize"
                      label="description"
                      valueProp="name"
                    >
                    </v-select>
                    <span class="has-error" v-if="errors.has('rental.rackSize')">{{ errors.first("rental.rackSize") }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group" :class="{ 'has-error': errors.has('rental.powerLicence'), }">
                    <label>Power Licence</label>
                    <v-select
                      :options="filledInSaleDefinition.supportingEnums.cabinetRental.powerLicence"
                      name="powerLicence"
                      v-validate="'required'"
                      placeholder="Select Power Licence"
                      v-model="filledInSaleDefinition.requiredObjs.cabinetRental.powerLicence"
                      label="description"
                      valueProp="name"
                    >
                    </v-select>
                    <span class="has-error" v-if="errors.has('rental.powerLicence')">{{ errors.first("rental.powerLicence") }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group" :class="{ 'has-error': errors.has('rental.nrc'), }">
                    <label>Non Recurring Cost (Excl. VAT)</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">R</span>
                      </div>
                      <input
                        type="number"
                        class="form-control"
                        v-validate="'required'"
                        name="nrc"
                        placeholder="Non Recurring Cost (Excl. VAT)"
                        v-model="filledInSaleDefinition.requiredObjs.cabinetRental.nrcExclVat"
                      />
                    </div>
                    <span class="has-error" v-if="errors.has('rental.nrc')">{{ errors.first("rental.nrc") }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group" :class="{ 'has-error': errors.has('rental.mrc'), }">
                    <label>Monthly Recurring Cost (Excl. VAT)</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">R</span>
                      </div>
                      <input
                        type="number"
                        class="form-control"
                        v-validate="'required'"
                        name="mrc"
                        placeholder="Monthly Recurring Cost (Excl. VAT)"
                        v-model="filledInSaleDefinition.requiredObjs.cabinetRental.mrcExclVat"                      
                      />
                    </div>
                    <span class="has-error" v-if="errors.has('rental.mrc')">{{ errors.first("rental.mrc") }}</span>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
export default {
  components: {
    ServiceWizardLayout,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "rental",
          displayName: "Cabinet Rental",
          icon: "fa fa-info",
        },
      ],
      filledInSaleDefinition: {
        requiredObjs: {
          cabinetRental: {},
        },
        selectableOptions: {
          dataCentre: []
        },
        supportingEnums: {
          cabinetRental: {
            powerLicence: [],
            rackSize: []
          }
        },
      },
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition; 
  },
  methods: {
    onComplete() {
      this.$swal({
        title:this.filledInSaleDefinition.product.description,
        text: "R " + this.filledInSaleDefinition.requiredObjs.cabinetRental.mrcExclVat,
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Create Service",
      }).then((result) => {
        if (result.value) {
          this.$emit("createSale", this.filledInSaleDefinition);
        } else {
        }
      });
    },
    validateStep(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>

    .header th {
       height: 2px;
       line-height: 2px;
    }

</style>
