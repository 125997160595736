<template>
  <div class="card-body pt-0">
    <div class="row">
      <div class="col-lg-12">
        <div class="row justify-content-between" style="font-size:20px">
          <div class="form-group col-md-auto">
            <button type="button" class="btn btn-default" :disabled="previousButtonDisabled"
              @click="previousMonthBilling()"><i class="fa fa-arrow-left"></i> Previous Month</button>
          </div>
          <div class="form-group col-md-auto">
            <h3>{{this.periodSelected}}</h3>
          </div>
          <div class="form-group col-md-auto">
            <button type="button" class="btn btn-default" @click="nextMonthBilling()">Next Month <i
                class="fa fa-arrow-right"></i></button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card" :class="'border-primary'">
              <div class="card-header">
                <h4 class="card-title">Generated Document Lines</h4>
              </div>
              <div class="card-body">
                <div class="form-group">
                  <button class="btn btn-success" type="button" @click="resaveSale()">Resave Sale</button>
                </div>
                <div class="table-responsive table-bordered" v-if="documentLines.length > 0">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Amount</th>
                        <th>Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="documentLine in documentLines" :key="documentLine.objKey">
                        <td>{{documentLine.description}}</td>
                        <td class="text-nowrap"><strong>{{currencyFormat(documentLine.transactionAmount)}}</strong></td>
                        <td>{{documentLine.type}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <strong v-else>No Document Lines</strong>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-spacing">
          <div class="col-md-12">
            <BillingTree billingType="ONCE_OFF" :period="periodSelected" v-if="onceOffBilling"
              :billingTree="onceOffBilling" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <BillingTree billingType="RECURRING" :period="periodSelected" v-if="recurringBilling"
              :billingTree="recurringBilling" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BillingTree from '@/components/GeneralComponents/BillingTree'

  export default {
    components: {
      BillingTree
    },
    computed: {
      previousButtonDisabled() {
        if (this.periodSelected <= this.getCurrentPeriod()) {
          return true;
        } else {
          return false;
        }
      }
    },
    data() {
      return {
        saleNumber: null,
        periodSelected: null,
        onceOffBilling: null,
        recurringBilling: null,
        saleStartPeriod: null,
        documentLines: []
      }
    },
    created: function () {
      this.periodSelected = parseInt(this.getCurrentPeriod());
    },
    mounted: function () {

      var saleNumber = this.$route.params.saleNumber;

      if (typeof saleNumber == 'string') {
        saleNumber = parseInt(saleNumber);
      }

      this.saleNumber = saleNumber;

      this.$http.get(this.$config.aimsAPI + "sales/" + this.saleNumber + "/startdate").then(
        response => {
          this.saleStartPeriod = response.data;
        },
        error => {
          console.error(error);
          this.showError("Error Reading Sale", error);
        });

      this.fetchBilling();
    },
    methods: {
      fetchBilling: function () {

        this.$http.get(this.$config.aimsAPI + "sales/definition/sale/" + this.saleNumber + "/billing?period=" + this
          .periodSelected + "&type=ONCE_OFF").then(
          response => {
            this.onceOffBilling = response.data;
          },
          error => {
            console.error(error);
            this.showError("Error Fetching Billing", error);
          });

        this.$http.get(this.$config.aimsAPI + "sales/definition/sale/" + this.saleNumber + "/billing?period=" + this
          .periodSelected + "&type=RECURRING").then(
          response => {
            this.recurringBilling = response.data;
          },
          error => {
            console.error(error);
            this.showError("Error Fetching Billing", error);
          });

        this.$http.get(this.$config.aimsAPI + "sales/" + this.saleNumber + "/documentlines?billingPeriod=" + this
          .periodSelected).then(
          response => {
            this.documentLines = response.data;
          },
          error => {
            console.error(error);
            this.showError("Error Fetching Document Lines", error);
          });
      },
      resaveSale() {
        this.$http.put(this.$config.aimsAPI + "sales/definition/sale/" + this.saleNumber + "/resave").then(
          response => {
            this.fetchBilling();
          },
          error => {
            console.error(error);
            this.showError("Error Resaving Sale", error);
          });
      },
      previousMonthBilling() {
        if (this.periodSelected <= this.getCurrentPeriod()) {
          this.$router.push({
            name: 'salebillinghistory'
          });
        } else {
          if (('' + this.periodSelected).substring(4, 6) == '01') {
            this.periodSelected = this.periodSelected - 100 + 11; //dec last year
          } else {
            this.periodSelected--;
          }
          this.fetchBilling();
        }
      },
      nextMonthBilling() {
        if (('' + this.periodSelected).substring(4, 6) == '12') {
          this.periodSelected = this.periodSelected + 100 - 11; //jan next year
        } else {
          this.periodSelected++;
        }
        this.fetchBilling();
      }
    }
  }

</script>

<style>

</style>
