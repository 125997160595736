<template>
  <modal :title="modalTitle" ref="splaModal" :size="'modal-lg'" @save="saveSpla(splaLicence)">
    <div class="row row-spacing justify-content-center">
      <div class="col-md-12">
          <div class="col-md-12">
            <h4>Service Provider Licence Agreement</h4>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>SPLA Package</label>
                  <v-select
                  :options="splaPackages"
                  valueProp="splaPackageNumber"
                  label="description"
                  v-model="
                    splaLicence.splaPackageNumber
                  "
                ></v-select>
              </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Quantity</label>
                  <input type="text" class="form-control" v-model="splaLicence.quantity" />
              </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
import { Circle } from "vue-loading-spinner";
export default {
  components: {
    Modal,
    loading: Circle,
  },
  props: {
    modalTitle: {
      type: String,
      required: true,
    },
    splaLicence: {
      type: Object,
      required: true,
    },
    splaPackages: {
      type: Array,
      required: true,
    },
    saveText: {
      type: String,
      default: "Save",
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
  },
  methods: {
    open: function () {
      this.$refs.splaModal.open();
    },
    saveSpla: function (spla) {
      this.$emit("saveSplaLicence", spla);
      this.$refs.splaModal.close();
    },
  },
};
</script>