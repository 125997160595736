<template>
    <div class="row">
        <div class="col-md-6 mt-4">
            <div class="form-group row">
                <label class="col-md-4">Added RAM:</label>
                <div class="col-md-8">
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="addedRam">
                        <div class="input-group-append">
                            <span class="input-group-text">GB</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-4">Added CPUs:</label>
                <div class="col-md-8">
                    <input type="text" class="form-control" v-model="virtualServerAddons.addOnCPUs">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-4">Added Disk space:</label>
                <div class="col-md-8">
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="addedDiskSpace">
                        <div class="input-group-append">
                            <span class="input-group-text">GB</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row" v-allowed:view="['STAFF']">
                <div class="col-sm-12">
                    <button class="btn btn-success" type="button" @click="save">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            virtualServerAddons: {
                type: Object,
                required: true
            },
        },
        computed: {
            addedRam: {

                get: function() {
                    return this.sizeBytesToGbWhole(this.virtualServerAddons.addOnRam);
                },

                set: function (newValue) {                    
                    this.virtualServerAddons.addOnRam = this.sizeGbToBytesWhole(parseInt(newValue));
                }
            },
            addedDiskSpace: {

                get: function() {
                    return this.sizeBytesToGbWhole(this.virtualServerAddons.addOnStorage);
                },

                set: function (newValue) {
                    this.virtualServerAddons.addOnStorage = this.sizeGbToBytesWhole(parseInt(newValue));
                }
            },
        },
        methods: {
            save: function() {                                
                this.$emit("saveSale");
            }
        }
    }
</script>
