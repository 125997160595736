<template>
    <ServiceScreenLayout :parts="parts" v-if="saleLoaded === true">
        <template v-slot:firewall-rules>
            <LegacyFirewallRules 
                :firewallRules="saleDefinition.sale.firewall"
                :saleNumber="saleNumber"
                :clientHostedNetworks="clientHostedNetworks"
                :accessArray="accessArray"
                :protocolArray="protocolArray"
                @saveFirewallRule="saveFirewallRule"
                @deleteSelectedFirewallRule="deleteSelectedFirewallRule"
            />
        </template>
    </ServiceScreenLayout>
</template>

<script>
import ServiceScreenLayout from '@/layouts/ServiceScreenLayout';
import LegacyFirewallRules from '@/components/ObjParts/MultipleCaptured/Legacy/LegacyFirewallRules';

export default {
    components: {
        ServiceScreenLayout,
        LegacyFirewallRules
    },
    data() {
        return {
            saleNumber: null,
            clientNumber: null,
            saleLoaded: false,
            saleDefinition: {
                sale: {
                    firewall: [],
                }
            },
            clientHostedNetworks: [],
            accessArray: [],
            protocolArray: [],
            parts: [
                {
                    name: "firewall-rules",
                    icon: "fa fa-shield",
                    displayName: "Firewall Rules",
                    permissions: ["*"]
                }
            ]
        }
    },
    async mounted() {
        await this.getSaleNumber();
        await this.getClientNumber();
        await this.getSaleDefinition();
        await this.getClientHostedNetworks();
    },
    methods: {
        getSaleNumber: function() {
            this.saleNumber = this.$route.params.saleNumber;
        },
        getClientNumber: function() {
            this.clientNumber = this.$route.params.clientNumber
        },
        getSaleDefinition: function() {
            const hostUrl = this.$config.aimsAPIv2;
            const saleNumber = this.saleNumber;

            this.$http.get(`${hostUrl}sales/definition/sale/${saleNumber}`).then(
                (response) => {
                    this.saleDefinition = response.data;
                    this.accessArray = this.saleDefinition.meta.firewall.objMeta.enums.access;
                    this.protocolArray = this.saleDefinition.meta.firewall.objMeta.enums.protocol;
                    this.saleLoaded = true;
                },
                (error) => {
                    console.error(error);
                    this.showError("Error fetching Sale Definition", error);
                }
            );
        },
        getClientHostedNetworks: function() {
            const legacyHostUrl = this.$config.aimsAPIvLegacy;
            const clientNumber = this.clientNumber;

            this.$http.get(`${legacyHostUrl}networks/hosted/client/${clientNumber}`).then(
                (response) => {
                    this.clientHostedNetworks = response.data; 
                },
                (error) => {
                    console.error(error);
                    this.showError("Error fetching Client Hosted Networks", error);
                }
            )
        },
        saveFirewallRule: function(data) {
            const hostUrl = this.$config.aimsAPI;
            const hostUrlv2 = this.$config.aimsAPIv2;
            const saleNumber = this.saleNumber;

            if (data.objKey) {
                this.$http.put(`${hostUrl}sales/definition/${saleNumber}/obj/update`, data).then(
                    (response) => {
                            this.showSuccess("Save Success");
                            this.getSaleDefinition();
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error Saving Sale Definition", error);
                    },
                );
            }
            else {
                this.$http.put(`${hostUrlv2}sales/definition/${saleNumber}/update`, this.saleDefinition).then(
                    (response) => {
                            this.showSuccess("Save Success");
                            this.getSaleDefinition();
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error Saving Sale Definition", error);
                    },
                );
            }
        },
        deleteSelectedFirewallRule: function(firewallRule) {
            this.$swal({
                title: "Are you sure you want to delete this Firewall rule",
                text: `${firewallRule.toString}`,
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Delete"
            }).then((result) => {
                if (result.value) {
                    const hostUrl = this.$config.aimsAPI;
                    const saleNumber = this.saleNumber;

                    this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/delete`, firewallRule).then(
                        (response) => {
                            this.showSuccess(`Successfully deleted ${firewallRule.toString}`);
                             this.getSaleDefinition();
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error deleting IP Network Address", error);
                        },
                    );
                }
            });
        }
    },
}
</script>
