<template>
    <modal :title="modalTitle" ref="modifyRealmModal" @save="saveRealm">
        <div class="row">
            <div class="col-md-12">
                <label>Provider</label>
                <v-select
                    :options="providers"
                    v-model="realm.provider"
                    label="description"
                    placeholder="Select a provider"
                    valueProp="name"
                >
                </v-select>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/GeneralComponents/Modal.vue';

export default {
    components: {
        Modal
    },
    props: {
        modalTitle: {
            type: String,
            required: true
        },
        providers: {
            type: Array,
            required: true
        },
        realm: {
            type: Object,
            required: true
        }
    },
    methods: {
        open: function() {             
            this.$refs.modifyRealmModal.open();
        },
        saveRealm: function() {
            this.$emit("saveRealm", this.realm);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>