<template>
  <div>
    <h5>Run history for last {{ days }} days</h5>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Task #</th>
          <th>Process Date</th>
          <th>Status</th>
          <th>Progress</th>
          <th>Parameters</th>
          <th>Run By</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="task in taskHistory"
          :key="task.taskNumber"
          :class="{ running: isTaskCompleted(task) == false }"
        >
          <td>{{ task.taskNumber }}</td>
          <td>{{ dateFormat(task.processDate) }}</td>
          <td>
            {{ task.status }}
            <loading
              v-if="isTaskCompleted(task) == false"
              style="height: 20px"
            />
          </td>
          <td>
            <span
              :class="
                task.progressDone === 100 || task.status == 'COMPLETED'
                  ? 'badge bg-green'
                  : 'badge bg-warning'
              "
              >{{
                task.status == "COMPLETED" && task.progressDone == 0
                  ? 100
                  : task.progressDone
              }}%</span
            >
            <small>{{ task.progressMessage }}</small>
          </td>
          <td>
            <small
              style="margin-right: 5px"
              v-for="taskParam in task.taskparameters"
              :key="taskParam.taskParameterNumber"
              >{{ taskParam.name }} : {{ taskParam.text }}</small
            >
          </td>
          <td>{{ task.ownerUsername }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { Circle } from "vue-loading-spinner";

export default {
  components: {
    loading: Circle,
  },
  props: {
    taskName: {
      type: String,
      required: true,
    },
    days: {
      type: Number,
      required: true,
    },
    includeInputParams: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    isRunningTasks() {
      return (
        this.taskHistory.filter((t) => this.isTaskCompleted(t) == false)
          .length > 0
      );
    },
  },
  data() {
    return {
      taskHistory: [],
    };
  },
  methods: {
    isTaskCompleted(task) {
      return task.status == "COMPLETED" || task.status == "FAILED";
    },
    getTaskHistory() {
      //check for running task
      this.$http
        .get(
          this.$config.aimsAPI +
            "tasks/list?days=" +
            this.days +
            "&taskName=" +
            this.taskName +
            "&includeInputParams=" +
            this.includeInputParams
        )
        .then(
          (response) => {
            this.taskHistory = response.data;
            clearInterval(this.checkInterval);
            if (this.isRunningTasks) {
              this.checkInterval = setInterval(() => {
                this.getTaskHistory();
              }, 10000);
              this.$emit("tasksRunningChanged", true);
            } else {
              this.$emit("tasksRunningChanged", false);
            }
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Task History", error);
          }
        );
    },
  },
  mounted() {
    this.getTaskHistory();
  },
  beforeUnmount() {
    clearInterval(this.checkInterval);
  },
};
</script>

<style>
.running {
  color: green;
  font-weight: bold;
}
</style>