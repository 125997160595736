var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_vm._m(0),_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-md-4 pl-0"},[_c('v-select',{attrs:{"options":_vm.billingMonths,"get-option-label":_vm.getLabel},on:{"input":function($event){return _vm.$emit('filterEntries', _vm.selectedBillingMonth)}},model:{value:(_vm.selectedBillingMonth),callback:function ($$v) {_vm.selectedBillingMonth=$$v},expression:"selectedBillingMonth"}})],1),_c('div',{staticClass:"col-md-4 pr-0"},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchTerm),expression:"searchTerm"}],staticClass:"form-control",attrs:{"type":"search","placeholder":"Search by Product Name..."},domProps:{"value":(_vm.searchTerm)},on:{"input":function($event){if($event.target.composing)return;_vm.searchTerm=$event.target.value}}}),_vm._m(1)])])]),_c('div',{staticClass:"row row-spacing"},[_c('table',{staticClass:"table table-bordered"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Product Name")]),_c('th',[_vm._v("Billing Month")]),_c('th',[_vm._v("Charges Excl VAT USD")]),_c('th',[_vm._v("Credits Excl VAT USD")]),_c('th',[_vm._v("Nett Charges Excl VAT USD")]),_c('th',[_vm._v("Price Excl VAT")]),(_vm.selectedBillingMonth)?_c('th',{staticClass:"text-center"},[_c('TableToFile',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Download Excel'),expression:"'Download Excel'"}],staticStyle:{"position":"inherit !important"},attrs:{"small":true,"fileName":`AWS Invoice Entries - ${_vm.selectedBillingMonth}`,"fieldProp":{
                                    'Product Name' : 'productName', 
                                    'Billing Month' : 'billingMonth', 
                                    'Charges Excl VAT USD' : 'chargesExclVatUsd', 
                                    'Credits Excl VAT USD' : 'creditsExclVatUsd',
                                    'Nett Charges Excl VAT USD' : 'nettChargesExclVatUsd',
                                    'Price Excl VAT' : 'priceExclVat'
                            },"dataProp":_vm.filteredEntries}})],1):_vm._e()])]),(_vm.filteredEntries.length > 0)?_c('tbody',_vm._l((_vm.filteredEntries),function(entry){return _c('tr',{key:entry.awsInvoiceEntryNumber},[_c('td',[_vm._v(_vm._s(entry.productName))]),_c('td',[_vm._v(_vm._s(entry.billingMonth))]),_c('td',[_vm._v(_vm._s(_vm.currencyFormatUSD(entry.chargesExclVatUsd)))]),_c('td',[_vm._v(_vm._s(_vm.currencyFormatUSD(entry.creditsExclVatUsd)))]),_c('td',[_vm._v(_vm._s(_vm.currencyFormatUSD(entry.nettChargesExclVatUsd)))]),_c('td',[_vm._v(_vm._s(_vm.currencyFormat(entry.priceExclVat)))]),(_vm.selectedBillingMonth)?_c('td'):_vm._e()])}),0):_c('tbody',[_vm._m(2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('h4',[_vm._v("Invoice Entries")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text",attrs:{"id":"product-search"}},[_c('i',{staticClass:"fa fa-search"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"6"}},[_vm._v("No invoice entries")])])
}]

export { render, staticRenderFns }