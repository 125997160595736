<template>
  <modal :title="modalTitle" ref="contractModal" :size="'modal-md'" @save="saveContract(contract)">
      
    <div class="row row-spacing">
      <div class="col-md-12">
          
            <div class="row">
              <div class="col-md-6">
                <label>Start Date:</label>
                <div class="input-group">
                  <DateInput
                    id="cstart"
                    type="date"
                    v-model="contract.startDate"
                    style="width: 100%"
                  /> 
                  <div class="input-group-append">
                      <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                  </div>
                </div>
              </div>
            
              <div class="col-md-6">
                <label>End Date:</label>
                <div class="input-group">
                  <DateInput
                    id="cend"
                    type="date"
                    v-model="contract.endDate"
                    style="width: 100%"
                  /> 
                  <div class="input-group-append">
                      <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                  </div>
                </div>
              </div>
            </div>
          
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
import DateInput from "@/components/GeneralComponents/InputControls/DateInput";
import { Circle } from "vue-loading-spinner";
export default {
  components: {
    Modal,
    loading: Circle,
    DateInput
  },
  props: {
    modalTitle: {
      type: String,
      required: true,
    },
    contract: {
      type: Object,
      required: true,
    },
    saveText: {
      type: String,
      default: "Add",
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
  },
  methods: {
    open: function () {
      this.$refs.contractModal.open();
    },
    saveContract: function (contract) {
      this.$emit("saveContract", contract);
      this.$refs.contractModal.close();
    },
  },
};
</script>