<template>
  <div class="row" v-if="!isClient">
    <div class="col-md-12">
      <label>Generate Onboarding Document</label>
      <div class="row">
        <div class="form-group col-md-6">
          <div class="input-group mb-3">
            <select class="form-control" 
              v-model="templateSelected" :disabled="!config.version || config.version == 0"
            >
              <option disabled value="-1">Select Onboarding Document Template</option>
              <option v-for="onboardingTemplate in onboardingTemplatesTemplates"
                :key="onboardingTemplate.templateNumber" :value="onboardingTemplate.templateNumber">
                {{onboardingTemplate.description}}</option>
            </select>
            <div class="input-group-append">
              <button class="btn btn-default" :disabled="!canSave || templateSelected == -1" type="button"
                @click="generateOnboarding()">Generate</button>
              <i class="fa fa-info-circle"
                style="color:orange; margin-top:10px; margin-left: 5px; font-size:16px; cursor:pointer" v-if="!canSave"
                :title="cantSaveReason"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      saleNumber: {
        type: Number,
        required: true
      },
      canSave: {
        type: Boolean,
        required: true
      },
      cantSaveReason: {
        type: String,
        required: true
      },
      isClient: {
          type: Boolean,
          default: false
      },
      routerConfig: {
        type: Object,
        required: false,
      },
      config: {
        type: Object,
        required: false,
      }
    },
    data() {
      return {
        onboardingTemplatesTemplates: [],
        templateSelected: -1,
      }
    },
    created() {
      if (this.routerConfig && Object.keys(this.routerConfig).length > 0) {
        this.config = this.routerConfig;
        
        
      } else {
        this.$http.get(this.$config.aimsAPI + "documents/emptyConfig").then(
          (response) => {
            this.config = response.data;
            
            
          },
          (error) => {
            this.showError("Could not fetch empty Router Config", error);
            console.error(error);
          }
        );
      }
    },
    mounted() {
      this.$http.get(this.$config.aimsAPI + "sales/definition/" + this.saleNumber +
          "/documentation/templates?docType=ONBOARDING_DOCUMENT")
        .then(
          response => {
            this.onboardingTemplatesTemplates = response.data;
          },
          error => {
            console.error(error);
            this.showError("Error Fetching Onboarding Templates", error);
          }
        );
    },
    methods: {
      generateOnboarding() {
        this.$http.get(this.$config.aimsAPI + "sales/definition/" + this.saleNumber + "/documentation/templates/" + this
            .templateSelected + "/download?docType=ONBOARDING_DOCUMENT", {
              responseType: 'arraybuffer'
            })
          .then(function (response) {
            const contentType = response.headers.get('Content-Type');
            let blob = new Blob([response.data], {
              type: contentType
            });
            const fileName = response.headers.get('Content-Disposition').split("filename = ")[1];
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.dispatchEvent(new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            }));
          }, function (error) {
            console.error(error);
            this.showError('Error Downloading Onboarding Document', error.data);
          });
      }
    }
  }

</script>

<style>

</style>
