<template>
  <modal
    :title="
      dnsView.dnsViewNumber
        ? 'Edit DNS View'
        : 'Add New DNS View'
    "
    ref="modifyPackageModal"
    @save="savePackage()"
  >
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> Client </label>
        <v-select
          placeholder="Select a Client"
          v-model="dnsView.clientNumber"
          :options="allClients"
          valueProp="clientNumber"
          label="toString"
          :searchable="true"
        >
        </v-select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> Name </label>
        <input
          type="text"
          class="form-control"
          v-model="dnsView.name"
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> Description </label>
        <input
          type="text"
          class="form-control"
          v-model="dnsView.description"
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> Default Origin </label>
        <input
          type="text"
          class="form-control"
          v-model="dnsView.defaultOrigin"
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> Command Subject </label>
        <input
          type="text"
          class="form-control"
          v-model="dnsView.commandSubject"
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> DNS Servers </label>
        <textarea
          rows="2"
          class="form-control"
          v-model="dnsView.nsServerHostNames"
        ></textarea>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
export default {
  props: {
    dnsView: {
      type: Object,
      required: true,
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      allClients: [],
    }
  },
  methods: {
    open() {
      this.$refs.modifyPackageModal.open();
      this.getAllClients();
    },
    getAllClients() {
      this.$http.get(this.$config.aimsAPI + "clients/all").then(
        (response) => {
          this.allClients = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Clients", error);
        }
      );
    },
    savePackage() {
      let call = "create";
      this.dnsView.dnsViewNumber ? call = "update" : call = "create";

      this.$refs.modifyPackageModal.isLoading();
      this.$http
        .post(
          this.$config.aimsAPI + "pricing/dnsviews/" + call,
          this.dnsView
        )
        .then(
          (response) => {
            this.$refs.modifyPackageModal.close();
            if (this.dnsView.dnsViewNumber) {
              this.showSuccess("Successfully Edited DNS View");
            } else {
              this.showSuccess("Successfully Created DNS View");
            }
              this.$emit("packageEdited");
          },
          (error) => {
            this.showError("Error Saving DNS View", error);
            console.error(error);
          }
        )
        .finally(() => {
          this.$refs.modifyPackageModal.isLoading();
        });
    },
  },
};
</script>

<style>
</style>
