<template>
  <div class="card card-default">
    <div class="card-header">
      <h4 class="mb-0">{{heading}}</h4>
    </div>
    <div class="card-body pt-1">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Type</th>
            <th>Address</th>
            <th>Netmask</th>
            <th v-if="editable"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(address,index) in validAddresses" :key="index" :class="{'new-table-row': address.isNew}">
            <td>{{address.family}}</td>
            <td>{{address.address}}</td>
            <td v-if="netmasksV6">
              <div class="input-group">
                <select class="form-control" v-model="address.shortMask">
                  <option :key="mask" v-for="mask in netmasksV6" :value="mask">/{{mask}}</option>
                </select>
                <div class="input-group-append">
                  <button class="btn btn-success" @click="$emit('save-sale')" type="button"><i
                      class="fa fa-check"></i></button>
                </div>
              </div>
            </td>
            <td v-else>{{address.shortMask}}</td>
            <td v-if="editable" style="text-align:center" v-allowed:view="['STAFF']">
              <button type="button" class="btn btn-danger" @click="removeAddress(address)">Remove</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    computed: {
      validAddresses() {
        return this.addresses.filter(address => address);
      }
    },
    props: {
      heading: {
        type: String,
        required: true
      },
      addresses: {
        type: Array,
        required: true
      },
      editable: {
        type: Boolean,
        required: false
      },
      netmasksV6: {
        type: Array,
        required: false
      }
    },
    methods: {
      removeAddress(address) {
        this.$emit('removeAddress', address);
      }
    }
  }

</script>
