<template>
  <ServiceScreenLayout :parts="parts">
    <template v-slot:service>
      <div class="row">
        <div class="col-md-7">
          <div class="card-header" >
              <h4>Remote Domain</h4>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Domain Name</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="saleDefinition.definition.domain.name"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Origin</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="saleDefinition.definition.domain.origin"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Serial</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="saleDefinition.definition.domain.serial"
                      disabled
                    />
                  </div>
              </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Minimum TTL</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="saleDefinition.definition.domain.minimumTtl"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-row row-spacing justify-content-between" v-allowed:view="['STAFF']">
              <div class="form-group col-auto">
                <button type="button" @click="saveSaleDefinition()" class="btn btn-success">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:hosts>
      <div class="row">
        <div class="col-md-12">
          <div class="card-body">
            <table class="table table-bordered row-spacing">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Destination</th>
                  <th>TTL</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(host, index) in hosts" :key="index" :class="{'new-table-row': host.isNew}">
                  <td>{{host.name}}</td>
                  <td>{{host.type}}</td>
                  <td style="word-break: break-word">{{host.destination}}</td>
                  <td>{{host.ttl}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </ServiceScreenLayout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
  },
  data() {
    return {
      saleNumber: null,
      parts: [
        {
          name: "service",
          icon: "fa fa-user-circle",
          displayName: "Service",
          permissions: ["*"],
        },
        {
          name: "hosts",
          icon: "fa fa-server",
          displayName: "Hosts",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        definition: {
          domain: {}
        }
      },
      hosts: [],
    };
  },
  async mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition() {
      this.getSaleDefinition(this.saleNumber).then(
        (success) => {
          this.saleDefinition = success.data;

          this.hosts = this.saleDefinition.definition.domain.mxHosts;
          this.hosts = this.hosts.concat(this.saleDefinition.definition.domain.nsHosts);
          this.hosts = this.hosts.concat(this.saleDefinition.definition.domain.hosts);
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
      return Promise.resolve('Success');
    },
    saveSaleDefinition: function() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http.put(this.$config.aimsAPI + "sales/definition/" + this.saleNumber + "/update", this.saleDefinition)
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.loadSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Sale Definition", error);
              }
            );
        }
      });
    },

    containsHost(host, list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i] === host) {
          return true;
        }
      }
    
      return false;
    }

  },
};
</script>