<template>
    <div class="mt-3">
        <div class="row row-spacing">
            <div class="col-md-6">
                <label><strong>Service Types</strong></label>
                <multiselect
                    :options="serviceTypes"
                    placeholder="Select Service Type(s)"
                    :group-select="true"
                    :multiple="true"
                    group-label="label"
                    group-values="values"
                    v-model="serviceTypeSelected"
                    @select="serviceTypeChanged()"
                >
                </multiselect>
            </div>
            <div class="col-md-6">
                <label>
                    <strong>Available Services</strong> 
                </label>
                <multiselect
                    :options="availableServices"
                    placeholder="Select available services"
                    :group-select="true"
                    :multiple="true"
                    group-label="label"
                    group-values="values"
                    v-model="selectedService"
                    @input="serviceChanged()"
                    :disabled="!hasSelectedTypes"
                >
                </multiselect>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-md-6">
                <div class="form-group">
                    <label>
                        <strong>Location</strong>
                    </label>
                    <div class="input-group">
                        <gmap-autocomplete id="gmap-autocomplete" style="width: 100%;" @place_changed="placeChanged">
                            <template v-slot:default="slotProps">
                                <input 
                                    type="text" 
                                    class="form-control"
                                    style="border-radius: 0.25rem 0 0 0.25rem;"
                                    ref="input" 
                                    :options="placesOptions"
                                    v-on:listeners="slotProps.listeners"
                                    v-on:attrs="slotProps.attrs"
                                >
                            </template>
                        </gmap-autocomplete>
                        <div class="input-group-append">
                            <span class="input-group-text"><i class="fa-solid fa-location-dot"></i></span>
                        </div>
                    </div>
                </div>
                <button class="btn dark-bg location-btn mx-0" type="button" @click="getCurrentLocation()">
                    <div class="d-flex align-items-center justify-content-center">
                        <span class="mr-2">Use current location</span>
                        <i class="fa-solid fa-location-dot" v-if="!fetchingCurrentLocation"></i>
                        <Circle2 v-else/>
                    </div>
                </button>
            </div>
        </div>
        <hr>
        <div class="row row-spacing">
            <div class="col-md-9">
                <div class="card p-1">
                    <gmap-map ref="gmap" 
                        :center="mapControls.center" 
                        :zoom="mapControls.zoom" 
                        :options="mapOptions" 
                        @bounds_changed="mapBoundsChanged" 
                        class="map-style"
                    >
                        <gmap-marker 
                            :position="mapControls.center" 
                            :clickable="true" 
                            :draggable="true" 
                        >
                        </gmap-marker>
                        <gmap-polygon 
                            :key="'polygon' + index" 
                            :paths="polygon.combinedCoords" 
                            :editable="false" 
                            v-for="(polygon,index) in mapPolygons" 
                            :options="polygon.renderOptions" 
                        >
                        </gmap-polygon>
                        <gmap-polyline 
                            :key="'line' + index" 
                            :path="polyline.outerCoords" 
                            :editable="false"
                             v-for="(polyline,index) in mapPolylines" 
                             :options="polyline.renderOptions" 
                        >
                        </gmap-polyline>
                        <gmap-circle 
                            :key="'circle' + index" 
                            :center="circle.outerCoords" 
                            :editable="false" 
                            :radius="circle.radius" 
                            v-for="(circle,index) in mapCircles" 
                            :options="circle.renderOptions"
                        >
                        </gmap-circle>
                    </gmap-map>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-header dark-bg">
                        <strong>Available Services</strong>
                    </div>
                    <div class="card-body">
                        <div v-if="this.availableServicesSelected.length > 0 && selectedService?.length > 0 && !noFibreAvailable && !fetchingCoverage">
                            <div class="row my-2" v-for="service in this.availableServicesSelected" :key="service.serviceNumber">
                                <div class="col-md-2 legend-block" :style="{'background-color':service.secondaryColourCode, 'border': '3px solid ' + service.primaryColourCode, 'margin': '0 .8em'}"></div>
                                <div class="col-md-9">
                                    <div class="row">
                                        <span><strong>{{service.name}}</strong></span>
                                    </div>
                                    <div class="row">
                                        <small>{{service.lastUpdated}}</small>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        </div>
                        <div v-else>
                            <div class="row my-2">
                                <div class="col-md-12 text-center">
                                    <span class="dark-bg px-5 py-1 rounded" v-if="!fetchingCoverage">
                                        <strong>{{ legendText }}</strong>
                                    </span>
                                    <div v-if="showCoverageLoader">
                                        <DataLoader 
                                            :loadingText="'Checking coverage...'"
                                            :textStyle="'font-size: 1em'"
                                        />
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataLoader from '@/components/GeneralComponents/DataLoader';
import { Circle2 } from "vue-loading-spinner";

    export default {
        components: {
            DataLoader,
            Circle2
        },
        props: {
            allServices: {
                type: Array,
                required: true
            }
        },
        data() {
            return {               
                coverage: [],
                placeSelected: {
                    country: {
                        label: null,
                        data: {},
                    },
                },
                placesOptions: {
                    componentRestrictions: {
                        country: "za"
                    },
                    fields: ["address_components", "geometry", "icon", "name"],
                    strictBounds: false,
                },
                mapControls: {
                    zoom: 20,
                    maxZoom: 12,
                    center: {
                        lat: -33.9655332,
                        lng: 18.8365501
                    },
                },
                mapOptions: {
                    mapType: 'roadmap',
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                },
                mapViewPort: {},
                mapPolygons: [],
                mapPolylines: [],
                mapCircles: [],
                coverageFetchTimeout: null,
                googleMapsGeocoder: null,
                fetchingCurrentLocation: false,
                serviceTypeSelected: null,
                serviceTypes: [
                    {
                        label: "Select All",
                        values: [],
                    },
                ],
                selectedService: null,
                availableServices: [
                    {
                        label: "Select All",
                        values: [],
                    },
                ],
                hasSelectedTypes: false,
                availableServicesSelected: [],
                viewPortData: [],
                noFibreAvailable: false,
                locationSelected: false,
                fetchingCoverage: false,
            }
        },
        mounted() {
            this.getServiceTypes(this.allServices);             
        },
        watch: {
            serviceTypeSelected(val) {    
                const hasSelectedServiceTypes = val?.length > 0;               
                if (hasSelectedServiceTypes) return this.hasSelectedTypes = true;

                this.availableServices[0].values = [];
                this.selectedService = null;
                return this.hasSelectedTypes = false;
            },
            selectedService(val) {
                const hasSelectedService = val?.length === 0;
                if (hasSelectedService) return this.availableServicesSelected = [];
                  
                val?.forEach(s => {                        
                    const indexOfObject = this.availableServicesSelected.findIndex(service => { return service.name !== s; });
                    return this.availableServicesSelected.splice(indexOfObject, 1);                        
                });
            },
        },
        computed: {
            legendText() {
                const noFibreAvailable = this.selectedService?.length > 0 && this.noFibreAvailable && this.locationSelected && !this.fetchingCoverage;
                const noLocationEntered = this.selectedService?.length > 0 && !this.locationSelected;
                const noServiceSelected = this.selectedService?.length === 0 || this.selectedService?.length === undefined;

                if (!this.hasSelectedTypes) return 'Select a service type';
                if (noServiceSelected) return 'Select a service';
                if (noLocationEntered) return 'Enter a location';
                if (noFibreAvailable) return 'No fibre yet';
            },
            showCoverageLoader() {
                const showLoader = this.selectedService?.length > 0 && this.locationSelected && this.fetchingCoverage;
                return showLoader;
            }
        },
        methods: {
            getServiceTypes: function(allServices) {
                allServices.filter((service) => {
                    const indexOfType = this.serviceTypes[0].values.findIndex(type => type === service.type);
                    if (indexOfType === -1) return this.serviceTypes[0].values.push(service.type);     
                })
            },
            serviceTypeChanged: function() {   
                let services = [];
                services = this.allServices.filter((service) => {
                    this.serviceTypeSelected?.includes(service.type);
                    const indexOfType = this.availableServices[0].values.findIndex(type => type === service.type);

                    if (indexOfType === -1) return this.availableServices[0].values.push(service.name);
                });
            },
            serviceChanged: function() {               
                this.selectedServices = this.availableServices.filter(service => service.checked === true).map(service => {
                    return service.serviceNumber
                });

                this.fetchCoverage();
            },
            getCurrentLocation: function() {
                try {
                    this.fetchingCurrentLocation = true;
                    navigator.geolocation.getCurrentPosition(this.currentLocationChanged);
                } catch (error) {
                    console.error("Geolocation is not supported by this browser.");
                }
            },
            currentLocationChanged: function(position) {                                
                this.fetchingCurrentLocation = false;
                const locationPosition = {name: `${position.coords.latitude},${position.coords.longitude}`};
                this.placeChanged(locationPosition);
            },
            selectAllServicesChanged: function(){
                this.availableServices.map(service => {
                    service.checked = this.allServicesSelected
                });

                this.serviceChanged();
            },
            placeChanged: function(placeData) { 
                this.locationSelected = Object.keys(placeData).length > 0; 
                
                if (placeData && placeData.geometry) return this.mapControls.center = placeData.geometry.location;

                if (!this.googleMapsGeocoder) this.googleMapsGeocoder = new google.maps.Geocoder;

                const latlngStr = placeData.name.split(',', 2);
                const latlng = {
                    lat: parseFloat(latlngStr[0]),
                    lng: parseFloat(latlngStr[1])
                };

                this.mapControls.center = new google.maps.LatLng(latlngStr[0], latlngStr[1]);

                this.googleMapsGeocoder.geocode({
                    'location': latlng
                }, function(results, status) {
                    if (status === 'OK' && results[0]) $('#gmap-autocomplete').val(results[0].formatted_address);
                });
            },
            mapBoundsChanged: function(bounds) {   
                if (!bounds) return;

                const north_east_corner_lat = bounds.getNorthEast().lat();
                const north_east_corner_lng = bounds.getNorthEast().lng();
                const south_west_corner_lat = bounds.getSouthWest().lat();
                const south_west_corner_lng = bounds.getSouthWest().lng();  
                
                const viewPort = {
                    northEast: {
                        lat: north_east_corner_lat,
                        lng: north_east_corner_lng
                    },
                    southWest: {
                        lat: south_west_corner_lat,
                        lng: south_west_corner_lng
                    },
                };

                this.mapViewPort = viewPort;                                

                this.fetchCoverage();
            },
            fetchCoverage: function() {                
                const hostUrl = this.$config.aimsAPI;
                const serviceNumber = this.getSelectedService();  
                const noServicesSelected =  !this.selectedService || this.selectedService?.length === 0;

                this.fetchingCoverage = true;  
                           
                if (noServicesSelected) {
                    this.mapPolygons = [];
                    this.mapPolylines = [];
                    this.circles = [];
                    this.fetchingCoverage = false;
                    return;
                }

                clearTimeout(this.coverageFetchTimeout);

                this.coverageFetchTimeout = setTimeout(() => {
                    this.$http.post(`${hostUrl}feasibility/coverage/viewport?services=${serviceNumber}`, this.mapViewPort).then(
                        (response) => {
                            if (response.data !== "") this.setupCoverage(response.data);
                            this.noFibreAvailable = response.data.length === 0;   
                            this.getAvailableServices(serviceNumber);
                            this.fetchingCoverage = false;                                  
                        }, 
                        (error) => {
                            console.error(error);
                            this.showError('Error Fetching Coverage Data', error);
                            this.fetchingCoverage = false;
                    });
                }, 300);
            },
            getSelectedService: function() {
                let serviceSelected = [];

                this.selectedService?.forEach(element => {
                    this.allServices.filter((service) => {
                        service.name === element ? serviceSelected.push(service.serviceNumber) : serviceSelected;
                    });
                });

                return serviceSelected;
            },
            setupCoverage: function(viewPortData) {
                let polygons = [];
                let polylines = [];
                let circles = [];
                
                viewPortData.map(coverage => {
                    let coverageServicePrimaryColour = '#FF0000';
                    let coverageServiceSecondaryColour = '#FF0000';
                    let coverageServiceRadius = 500;
                    let coverageServiceIndex = this.allServices.findIndex(service => service.serviceNumber === coverage.serviceNumber);  
                    
                    if (coverage.type === 'POLYGON') {
                        coverage.combinedCoords = JSON.parse(coverage.innerCoords);
                        coverage.combinedCoords.push(JSON.parse(coverage.outerCoords));
                    } else {
                        coverage.outerCoords = JSON.parse(coverage.outerCoords);
                        coverage.innerCoords = JSON.parse(coverage.innerCoords);
                    }   

                    if (coverageServiceIndex != -1) {
                        coverageServicePrimaryColour = this.allServices[coverageServiceIndex].primaryColourCode;
                        coverageServiceSecondaryColour = this.allServices[coverageServiceIndex].secondaryColourCode;
                        coverageServiceRadius = this.allServices[coverageServiceIndex].radius;
                    }

                    if (coverage.type === 'POLYGON') {
                        coverage.renderOptions = {
                            strokeColor: coverageServicePrimaryColour,
                            strokeOpacity: 1,
                            strokeWeight: 2,
                            fillColor: coverageServiceSecondaryColour,
                            fillOpacity: 0.37
                        };
                        
                        polygons.push(coverage);
                    }

                    if (coverage.type === 'POLYLINE') {
                        coverage.renderOptions = {
                            strokeColor: coverageServicePrimaryColour,
                            strokeOpacity: 1,
                            strokeWeight: 2,
                        };

                        polylines.push(coverage);
                    }

                    if (coverage.type === 'CIRCLE') {

                        coverage.renderOptions = {
                            radius: coverageServiceRadius,
                            strokeColor: coverageServicePrimaryColour,
                            strokeOpacity: 1,
                            stroplaceSearchkeWeight: 2,
                        };

                        circles.push(coverage);
                    }
                });

                this.mapPolygons = polygons;
                this.mapPolylines = polylines;
                this.mapCircles = circles;  

            },
            getAvailableServices: function(serviceNumber) {
                serviceNumber.forEach((service) => {
                    this.allServices.map((services) => {
                        if (services.serviceNumber === service) {
                            const indexOfType = this.availableServicesSelected.findIndex(availableService => availableService.serviceNumber === service);

                            if (indexOfType === -1) this.availableServicesSelected.push(services);       
                        }
                    })
                });  
            }
        }
    }
</script>

<style lang="scss" scoped>
.map-style {
    width: 100%; 
    height: 550px
}

.dark-bg {
    background-color: #3a3f51;
    color: #ffffff;
}

.location-btn {
    width: 200px;
    .spinner.spinner--circle-2 {
        border-color:   #3a3f51 #ffffff #ffffff !important;
        width: 15px !important;
        height: 15px !important;
        border-width: 2px !important;
    }
}
</style>