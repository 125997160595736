<template>
  <div class="row row-spacing">
    <div class="col-md-12">
      <div class="card table-responsive table-bordered" v-if="correspondence.length">
        <table class="table">
          <thead>
            <tr>
              <th class="clickable" @click="sortBy('description')">Description
                <i class="fa fa-sort-asc float-right" v-if="sortKey == 'description' && reverse"></i>
                <i class="fa fa-sort-desc float-right" v-if="sortKey == 'description' && !reverse"></i>
              </th>
              <th class="clickable" @click="sortBy('type')">Type
                <i class="fa fa-sort-asc float-right" v-if="sortKey == 'type' && reverse"></i>
                <i class="fa fa-sort-desc float-right" v-if="sortKey == 'type' && !reverse"></i>
              </th>
              <th class="clickable" @click="sortBy('fileName')">File
                <i class="fa fa-sort-asc float-right" v-if="sortKey == 'fileName' && reverse"></i>
                <i class="fa fa-sort-desc float-right" v-if="sortKey == 'fileName' && !reverse"></i>
              </th>
              <th class="clickable" @click="sortBy('created')">Date
                <i class="fa fa-sort-asc float-right" v-if="sortKey == 'created' && reverse"></i>
                <i class="fa fa-sort-desc float-right" v-if="sortKey == 'created' && !reverse"></i>
              </th>
              <th v-if="context == 'client'">Services</th>
              <th style="width:7em"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="paper in sortedCorrespondence" :key="paper.paperNumber" :class="{'new-table-row': paper.isNew}">
              <td>{{paper.description}}</td>
              <td>{{paperType(paper, paperTypes)}}</td>
              <td><a href="javascript:void(0)" @click="downloadPaper(paper)">{{paper.fileName}}</a></td>
              <td>{{paper.created.split(" ")[0]}}</td>
              <td v-if="context == 'client'">
                <span v-for="(saleNumber, index) in saleNumbers(paper)" :key="index" @click="viewSale(saleNumber)">
                  <span v-if="index">, </span><a href="javascript:void(0)">{{ saleNumber }}</a>
                </span>
              </td>
              <td class="text-center">
                <button v-if="context == 'client'" v-tooltip="'Email Document'" type="button" 
                  @click="emailDialog(paper)"
                  v-allowed:view="['ADMIN', 'ACCOUNTS_STAFF']"
                  class="btn btn-sm btn-green">
                  <em class="fa fa-envelope fa-fw"></em>
                </button>
                <button class="btn btn-sm btn-info m-1" type="button"
                  :disabled="paperNotEditable(paper, notEditableTypes)" v-tooltip="'Edit Document Details'"
                  @click="openEditModal(paper)">
                  <em class="fa fa-edit fa-fw"></em>
                </button>
                <button v-if="context == 'client'" v-tooltip="'Delete Document'" type="button"
                  :disabled="paperNotEditable(paper, notEditableTypes)" @click="deletePaper(paper.paperNumber)"
                  v-allowed:view="['ADMIN', 'ACCOUNTS_STAFF']"
                  class="btn btn-sm btn-primary">
                  <em class="fa fa-trash fa-fw"></em>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        No Documentation Found
      </div>
    </div>
    <EmailModal ref="emailDialogModal" @send="sendEmail" :clientNumber="clientNumber"></EmailModal>
    <modal title="Edit Document Details" ref="editDocumentModal" @save="updateDocument()">
      <div class="form-group">
        <label>Type</label>
        <v-select :options="paperTypes" label="description" v-model="document.type" valueProp="name"></v-select>
      </div>
      <div class="form-group">
        <label>Description</label>
        <input type="text" class="form-control" v-model="document.description">
      </div>
      <div class="form-group">
        <label>File Name</label>
        <p>
          <strong>{{document.fileName}}</strong>
        </p>
      </div>
      <div v-if="selectedSales.length > 0" class="form-group">
        <label class="d-block">Selected Sales</label>
        <span v-for="saleNumber in selectedSales" :key="saleNumber">
          <span class="badge badge-warning" style="margin: 0.05rem">{{sale(saleNumber, clientSales)}}</span>
        </span>
      </div>
      <div class="form-group" v-if="context == 'client' && sales.length > 0">
        <label class="d-block">Sales</label>
        <popper trigger="clickToToggle" :options="{ placement: 'top', modifiers: {offset: {offset: '0,10px'}}}">
          <div class="popper">
            <div class="card mb-0">
              <div class="card-header">
                <div class="row row-spacing">
                  <div class="col-md-5">
                    <input type="text" class="form-control" v-model="searchText" placeholder="Search Sale">
                  </div>
                  <div class="col-md-4">
                    <v-select :options="options" v-model="show"></v-select>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="selectId" v-model="selectAll">
                    <label class="form-check-label" for="selectId">{{selectAll ? 'Deselect' : 'Select'}}
                      All</label>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="table-wrapper-scroll-y my-custom-scrollbar">

                  <table class="table table-bordered table-hover mb-0">
                    <thead>
                      <tr>
                        <th scope="col">SaleNumber</th>
                        <th scope="col">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr :class="selectedSales.includes(sale.saleNumber) ? 'new-table-row' : ''"
                        v-for="sale in filteredSales" :key="sale.saleNumber" @click="selectSale(sale)">
                        <td scope="row">{{sale.saleNumber}}</td>
                        <td>{{sale.description}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <span class="text-danger">*Click on table row to select/deselect sale</span>
          </div>

          <button class="btn btn-secondary" slot="reference">
            Click to view list of sales
          </button>
        </popper>
      </div>
    </modal>
  </div>
</template>

<script>
  import Modal from '@/components/GeneralComponents/Modal'
  import EmailModal from "@/components/GeneralComponents/EmailModal";
  import Popper from 'vue-popperjs';
  import 'vue-popperjs/dist/vue-popper.css';
  
  export default {
    components: {
      Modal,
      EmailModal,
      'popper': Popper
    },
    computed: {
      sortedCorrespondence() {
        if (this.reverse) {
          return _.orderBy(this.correspondence, this.sortKey, 'asc');
        } else {
          return _.orderBy(this.correspondence, this.sortKey, 'desc');
        }
      },
      saleNumbers: () => ({
        saledocumentations
      }) => {
        if (saledocumentations) {
          return saledocumentations.map(documentationLinks => documentationLinks.saleNumber);
        }
      },
      paperNotEditable: () => ({
        type
      }, notEditableTypes) => {
        return notEditableTypes.findIndex(m => m === type) != -1;
      },
      paperType: () => ({
        type
      }, paperTypes) => {
        const t = paperTypes.find(t => t.name === type);
        if (t) {
          return t.description;
        } else {
          return "ERROR (Report to Dev)";
        }
      },
      filteredSales() {
        if (this.show == 'Selected Sales') {
          return this.sales.filter(sale => this.selectedSales.includes(sale.saleNumber));
        }

        if (this.show == 'Unselected Sales') {
          return this.sales.filter(sale => !this.selectedSales.includes(sale.saleNumber));
        }

        return this.sales.filter(sale => {
          return sale.description.toLowerCase().includes(this.searchText.toLowerCase()) ||
            sale.saleNumber.toString().includes(this.searchText)
        });
      },
      sale: () => (saleNumber, sales) => {
        const sale = sales.find(sale => sale.saleNumber == saleNumber);
        let description;
        if (sale.toString.length > 30) {
          description = sale.toString.substring(0, 30) + '...';
        } else {
          description = sale.toString
        }
        return '#' + sale.saleNumber + ' - ' + description;
      }
    },
    watch: {
      clientSales(sales) {
        this.sales = sales;
      },
      selectAll(selectAll) {
        if (selectAll) {
          this.selectedSales = this.sales.map(sale => sale.saleNumber);
        } else {
          this.selectedSales = []
        }
      },
      selectedSales(sales) {
        if (sales.length == this.sales.length) {
          this.selectAll = true;
        } else if (sales.length == 0) {
          this.selectAll = false;
        }
      }
    },
    props: {
      correspondence: {
        type: Array,
        required: true
      },
      clientNumber: {
        type: String,
        required: true,
      },
      paperTypes: {
        type: Array,
        required: true
      },
      context: {
        type: String,
        required: true
      },
      clientSales: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        document: {},
        selectedPaper: {},
        notEditableTypes: ['INVOICE'],
        selectedSales: [],
        sales: [],
        options: ['All Sales', 'Selected Sales', 'Unselected Sales'],
        show: 'All Sales',
        selectAll: false,
        searchText: '',

        sortKey: 'created',
        reverse: false,
      }
    },
    methods: {
      viewSale(saleNumber) {
        const sale = this.clientSales.find(sale => sale.saleNumber == saleNumber);
        const productRoute = this.getProductDefinition(sale.product.productDefinition);
        if (productRoute == -1) {
          this.$swal({
            title: "No Page for Product",
            text: "Contact Dev",
            type: "info"
          });
        } else {
          this.$router.push({
            name: productRoute.routeName,
            params: {
              saleNumber: sale.saleNumber
            }
          });
        }
      },
      selectSale({
        saleNumber
      }) {
        const i = this.selectedSales.indexOf(saleNumber);
        if (i == -1) {
          this.selectedSales.push(saleNumber);
        } else {
          this.selectedSales.splice(i, 1);
        }
      },
      downloadPaper(paper) {
        this.$http.get(this.$config.aimsAPI + "clients/correspondence/" + paper.paperNumber + "/download", {
            responseType: 'arraybuffer'
          })
          .then(function (response) {
            const contentType = response.headers.get('Content-Type');
            let blob = new Blob([response.data], {
              type: contentType
            });
            const fileName = response.headers.get('Content-Disposition').split("filename = ")[1];
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.dispatchEvent(new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            }));
          }, function (error) {
            console.error(error);
            this.showError('Error Downloading Correspondence', error.data);
          });
      },
      deletePaper(paperNumber) {
        this.$swal({
          title: 'Are you sure you want to delete this document?',
          text: 'Document #' + paperNumber,
          type: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then(result => {
          if (result.value) {
            this.$http.delete(this.$config.aimsAPI + 'clients/correspondence/' + paperNumber)
              .then(response => {
                  this.showSuccess('Successfully deleted Document');
                  this.$emit('fetchCorrespondence');
                },
                error => {
                  this.showError('Error deleting Document', error)
                  console.error(error);
                });
          }
        })
      },
      openEditModal(paper) {
        this.selectedSales = this.saleNumbers(paper) || [];
        this.document = this.deepCopyObject(paper);
        this.$refs.editDocumentModal.open();
      },
      updateDocument() {
        this.$http.put(this.$config.aimsAPI + 'clients/correspondence/update', this.document)
          .then(response => {
              this.updateSaleDocumentation();
            },
            error => {
              this.showError("Error updating correspondence", error);
              console.error(error);
            });
      },
      updateSaleDocumentation() {
        this.$refs.editDocumentModal.isLoading();
        this.$http.post(this.$config.aimsAPI + 'clients/correspondence/' + this.document.paperNumber + '/sales/',
          this.selectedSales).then(response => {
            this.$refs.editDocumentModal.close();
            this.showSuccess(response.data.message);
            this.$emit('fetchCorrespondence');
          },
          error => {
            this.showError("Error updating Sale Documentation", error);
            console.error(error);
          }).finally(() => {
          this.$refs.editDocumentModal.isLoading();
        });
      },
      sortBy(sortKey) {
        this.reverse = (this.sortKey == sortKey) ? ! this.reverse : false;
        this.sortKey = sortKey;
      },
      emailDialog(paper) {
        this.selectedPaper = paper;
        this.$refs.emailDialogModal.open();    
      },
      sendEmail(data) {
        this.$http.put(this.$config.aimsAPI + 'clients/correspondence/' + this.selectedPaper.paperNumber + '/email', data)
        .then(response => {
            this.showSuccess(response.data.message);
          }, error => {
            this.showError("Error updating Sale Documentation", error);
            console.error(error);
          }).finally(() => {
          this.$refs.emailDialogModal.close();
        });
      }
    }
  }

</script>

<style scoped>
.clickable:hover {
 cursor: pointer;
}

.table>thead>tr>th {
  background-color: #3a3f51;
  color: #FFFFFF; 
  padding: 1em;

  top: 0;
  z-index: 2;
  position: sticky;
} 
</style>
