<template>
  <div class="row">
    <div class="col-xl-8 col-lg-12">
      <div class="card card-default">
        <form-wizard
          title=""
          subtitle=""
          @on-complete="onComplete"
          stepSize="sm"
        >
          <tab-content
            title="Account Options"
            :before-change="() => validateStep('account-selection')"
          >
            <form novalidate data-vv-scope="account-selection">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('account-selection.Speed'),
                    }"
                  >
                    <label class="control-label">Package *</label>
                    <div class="input-group">
                      <v-select
                        @input="getPackage"
                        ref="speed"
                        name="Speed"
                        v-validate="'verify_selected'"
                        placeholder="Select Package"
                        :get-option-label="getLabel"
                        :options="filteredPackages"
                      ></v-select>
                    </div>
                    <span
                      class="has-error"
                      v-if="errors.has('account-selection.Speed')"
                      >{{ errors.first("account-selection.Speed") }}</span
                    >
                  </div>
                </div>
              </div>
            </form>
          </tab-content>
          <tab-content
            title="Line Address Info"
            icon="fa fa-clipboard"
            :before-change="() => validateStep('line-info')"
          >
            <div
              class="form-group"
              :class="{ 'has-error': errors.has('line-info.circuitNumber') }"
            >
              <label>Circuit Number *</label>
              <input
                placeholder="Circuit Number"
                name="circuitNumber"
                v-validate="'required'"
                class="form-control"
                v-model="filledInSaleDefinition.requiredObjs.line.circuitNumber"
              />
              <span
                class="has-error"
                v-if="errors.has('line-info.circuitNumber')"
                >{{ errors.first("line-info.circuitNumber") }}</span
              >
            </div>

            <div class="row">
              <Address
                class="col-xl-12"
                @addressChanged="lineAddressChanged"
                @addressValid="lineAddressValidChanged"
                :addressStructure="
                  filledInSaleDefinition.requiredObjs.lineAddress
                "
                :addressTypes="
                  filledInSaleDefinition.supportingEnums.lineAddress.addressType
                "
              />
            </div>
          </tab-content>
        </form-wizard>
      </div>
    </div>
    <WizardCheckout 
      ref="wizardCheckoutModal"
      :description="packageSelected.selectedDescription"
      :prices="packageSelected.selectedPrice"
      @checkout="finalize"
    />
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Address from "@/components/GeneralComponents/Address";
import WizardCheckout from '@/components/ObjParts/MultipleLoaded/WizardCheckout'

export default {
  components: {
    FormWizard,
    TabContent,
    WizardCheckout,
    Address,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filledInSaleDefinition: {
        selectableOptions: {
          connectivityPackage: [],
        },
        supportingEnums: {
          lineAddress: {
            addressType: [],
          },
        },
        requiredObjs: {
          lineAddress: {
            addressType: [],
          },
          line: {}
        },
      },
      packageSelected: {
        selectedDescription: null,
        selectedSpeed: null,
        selectedPrice: null,
      },
      filteredPackages: [],
    };
  },
  created() {
    this.$validator.extend(
      "verify_selected",
      (value) => {
        return {
          valid: this.packageSelected && this.packageSelected.selectedSpeed,
          data: {
            required: true,
          },
        };
      },
      {
        computesRequired: true,
      }
    );
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;    
    const distinctSpeedList = this.distinctList(
      this.filledInSaleDefinition.selectableOptions.connectivityPackage,
      "connectivityPackageNumber"
    );
    this.filteredPackages = this.orderList(distinctSpeedList, "downloadSpeed");
  },
  methods: {
    getLabel(option) {
      return `${option.description} -  ${option.contractTerm}`;
    },
    getConnectivityPackagePrice(connectivityPackageNumber) {
      return this.$http.get(
        this.$config.aimsAPI +
          "pricing/connectivitypackages/" +
          connectivityPackageNumber
      );
    },
    async getPackage(packageSelected) {
      if (packageSelected.connectivityPackageNumber) {
        this.packageSelected.selectedDescription = packageSelected.description;
        this.packageSelected.selectedSpeed =
          packageSelected.connectivityPackageNumber;
        this.getConnectivityPackagePrice(
          packageSelected.connectivityPackageNumber
        ).then(
          (success) => {
            this.packageSelected.selectedPrice = success.data;
          },
          (error) => {
            this.showError("Error fetching Price for package", error);
            console.error(error);
          }
        );
      }
    },
    validateStep: function (stepName) {
      return this.$validator.validateAll(stepName).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
    onComplete: function () {
      this.filledInSaleDefinition.requiredObjs.uncappedService.connectivityPackageNumber = this.packageSelected.selectedSpeed;
      this.$refs.wizardCheckoutModal.open();
    },
    finalize(){
      this.$emit('createSale', this.filledInSaleDefinition);
    },
    lineAddressChanged(address) {
      this.filledInSaleDefinition.requiredObjs.lineAddress = address;
    },
    lineAddressValidChanged(isValid) {
      this.lineAddressValid = isValid;
    },
  },
};
</script>