<template>
  <ServiceScreenLayout :parts="parts">
    <template v-slot:service>
      <div class="row">
        <div class="col-md-7">
          <div class="card-header" >
              <h4>{{saleDefinition.product.toString}}</h4>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Description</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="saleDefinition.definition.service.description"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Details</label>
                  <div class="input-group">
                    <textarea 
                      type="text"
                      class="form-control"
                      rows="4" 
                      cols="50"
                      v-model="saleDefinition.definition.service.text"
                    >
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-row row-spacing justify-content-between" v-allowed:view="['STAFF']">
              <div class="form-group col-auto">
                <button type="button" @click="saveSaleDefinition()" class="btn btn-success">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:network>
      <div class="row">
        <div class="col-md-7">
          <div class="card-header" >
              <h4>{{saleDefinition.product.toString}}</h4>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <label>VLAN Name</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="saleDefinition.definition.network.name"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>VLAN ID</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="saleDefinition.definition.network.vlan"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Contention Ratio</label>
                  <div class="input-group">
                    <v-select
                      disabled
                      :value="contention" 
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Bandwidth</label>
                  <div class="input-group">
                    <v-select 
                      name="hostNumber"
                      :options="rateLimits" 
                      label="description" 
                      valueProp="rateLimit"
                      v-model="selectedRateLimit" 
                    ></v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-row row-spacing justify-content-between" v-allowed:view="['STAFF']">
              <div class="form-group col-auto">
                <button type="button" @click="saveSaleDefinition()" class="btn btn-success">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:address>
      <div class="form-group">
        <div class="row align-items-center">
          <div class="col-md-4">
            <button
              class="btn btn-info"
              @click="addRouteModal()"
              type="button"
              v-allowed:view="['STAFF']"
            >
              Add IPv4 Range
            </button>
          </div>
        </div>
        <table class="table table-bordered row-spacing">
          <thead>
            <tr>
              <th>Address</th>
              <th>Mask</th>
              <th v-allowed:view="['STAFF']"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="network in saleDefinition.definition.network.networkAddress"
              :key="network.hostedNetworkAddressNumber"
            >
              <td>{{ network.address }}</td>
              <td>{{ network.shortMask }}</td>
              <td class="text-center" v-allowed:view="['STAFF']">
                <button
                  type="button"
                  v-tooltip="'Delete Routed Network'"
                  @click="removeAddress(network)"
                  class="btn btn-sm btn-primary"
                >
                  <em class="fa fa-trash fa-fw"></em>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <modal
        title="Add Network"
        ref="addNewNetwork"
        @save="saveNetworks(newNetwork)"
      >
        <div class="row">
          <div class="form-group col-md-10">
            <label class="control-label mt-2">Address:</label>
            <div class="input-group">
              <input class="form-control" v-model="newNetwork.address" />
            </div>
            <label class="control-label mt-2">Short Mask:</label>
            
            <div class="input-group">
              <select class="form-control" v-model="newNetwork.shortMask">
                <option :key="mask" v-for="mask in netmasksV4" :value="mask">
                  /{{ mask }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </modal>
    </template>
  </ServiceScreenLayout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";
import RoutedNetworks from "@/components/ObjParts/MultipleCaptured/RoutedNetworks";
import Modal from "@/components/GeneralComponents/Modal";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    RoutedNetworks,
    Modal,
  },
  data() {
    return {
      saleNumber: null,
      parts: [
        {
          name: "service",
          icon: "fa fa-user-circle",
          displayName: "Service",
          permissions: ["*"],
        },
        {
          name: "network",
          icon: "fa fa-plug",
          displayName: "Network",
          permissions: ["*"],
        },
        {
          name: "address",
          icon: "fa fa-list",
          displayName: "Addresses",
          permissions: ["*"],
        }
      ],
      saleDefinition: {
        definition: {
            service: {},
            network: {}
        },
        product: {

        },
        supportingObjs: {
          networkAddress: {},
        }
      },
      newNetwork: [],
      rateLimits: [],
      selectedRateLimit: '',
      contention: '',
      netmasksV4: [32, 30, 29, 28, 27, 26, 25],
    };
  },
  async mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition() {
      this.getSaleDefinition(this.saleNumber).then(
        (success) => {
          this.saleDefinition = success.data;
          this.newNetwork = this.saleDefinition.supportingObjs.networkAddress;
          this.newNetwork.hostedNetworkNumber = this.saleDefinition.definition.network.hostedNetworkNumber;
          this.$refs.addNewNetwork.close();
                    
          this.$http.get(this.$config.aimsAPIvLegacy + "fibre/ratelimits/" + this.saleDefinition.product.productNumber)
            .then(
              (response) => {
                this.rateLimits = response.data;
                for (let i = 0; i < this.rateLimits.length; i++) {
                  if (this.rateLimits[i].rateLimit == this.saleDefinition.definition.network.rateLimit) {
                    this.selectedRateLimit = this.rateLimits[i].description;
                  }
                }

                this.$http.get(this.$config.aimsAPIvLegacy + "fibre/contention/" + this.saleDefinition.definition.network.shapedPoolNumber)
                .then(
                  (response) => {
                    this.contention = response.bodyText;
                  },
                  (error) => {
                    console.error(error);
                  }
                );
              },
              (error) => {
                console.error(error);
              }
            );
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
      return Promise.resolve('Success');
    },
    saveSaleDefinition: function() {
      for (let i = 0; i < this.rateLimits.length; i++) {
        if (this.selectedRateLimit == this.rateLimits[i].rateLimit) {
          this.saleDefinition.definition.network.rateLimit = this.rateLimits[i].rateLimit;
        }
      }

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http.put(this.$config.aimsAPI + "sales/definition/" + this.saleNumber + "/update", this.saleDefinition)
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.loadSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Sale Definition", error);
                this.loadSaleDefinition();
              }
            );
        }
      });
    },
    addRouteModal() {
      this.modalTitle = "Add Network";
      this.$refs.addNewNetwork.open();
    },
    saveNetworks(network) {
      this.$refs.addNewNetwork.isLoading();
      network.type = "IPV4";
      this.saleDefinition.definition.network.networkAddress.push(network);
      this.saveSaleDefinition();
    },
    removeAddress(address) {
      this.$swal({
        title: "Are you sure you want to remove this address?",
        text: address.address,
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Remove",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/obj/delete",
              address
            )
            .then(
              () => {
                this.showSuccess("Delete Success");
                this.loadSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Sale Definition", error);
              }
            );
        }
      });
    },
  },
};
</script>