<template>
    <modal
      title="Deposit"
      ref="depositModal"
      size="modal-xs"
      save-text="Save Deposit"
      @save="saveDeposit(workOrderDeposit)"
      dismiss-text="Close"
    >
      <div class="row">
        <div
          class="form-group col-md-6"
          :class="{ 'has-error': errors.has('Deposit Type') }"
        >
          <label class="col-md-12 col-form-label">Deposit Required for:</label>
          <div class="col-md-12">
            <v-select
              :options="depositType"
              v-model="workOrderDeposit.depositType"
              label="description"
              valueProp="description"
            ></v-select>
          </div>
        </div>
        <div
          class="form-group col-md-6"
          :class="{ 'has-error': errors.has('Deposit Percentage') }"
        >
          <label class="col-md-12 col-form-label">Percentage required:</label>
          <div class="col-md-12">
            <v-select
              :options="depositPercentage"
              v-model="workOrderDeposit.depositPercentage"
              label="description"
              valueProp="description"
            ></v-select>
          </div>
        </div>
      </div>
    </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";

export default {
    components: {
        Modal
    },
    data() {
      return {
        depositType: [],
        depositPercentage: [],
        workOrderDeposit: {},
        workOrderNumber: null,
      }
    },
    async created() {      
      const hostUrl = this.$config.aimsAPI;
      this.workOrderNumber = this.$route.params.workOrderNumber;

      await this.$http.get(`${hostUrl}workorders/labourentry/enums`).then(
        (response) => {
          const data = response.data;

          const {depositType, depositPercentage} = data;
          this.depositType = depositType;
          this.depositPercentage = depositPercentage;
        },
        (error) => {
          this.showError("Error Fetching Labour Entry Enums", error);
          console.error(error);
        }
      );

      await this.$http.get(`${hostUrl}workorders/${this.workOrderNumber}/deposit`).then(
          (response) => {
            this.workOrderDeposit = response.data;
          },
          (error) => {
            this.showError("Error fetching Deposit", error);
            console.error(error);
          }
        );
    },
    methods: {
        open() {
            this.$refs.depositModal.open();
        },
        saveDeposit(workOrderDeposit) {
      const hostUrl = this.$config.aimsAPI;
      

      this.$http.put(`${hostUrl}workorders/${this.workOrderNumber}/deposit/save`, workOrderDeposit).then(
          (response) => {
            this.showSuccess(response.data.message);
            this.$emit('getWorkOrder' , false);
            this.$refs.depositModal.close();
          },
          (error) => {
            this.showError("Error updating Deposit", error);
            console.error(error);
          }
        );
    }
    }
}
</script>
