<template>
  <div>
    <div class="card card-default">
      <div class="card-header">
        <h3>Run a task</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <input
              class="form-control"
              v-model="searchText"
              placeholder="Search"
            />
          </div>
        </div>
        <div class="row row-spacing">
          <div class="col-md-12">
            <div class="table-responsive table-bordered">
              <table class="table">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="taskDefinition in filterAvailableTasks"
                    :key="taskDefinition.taskDefinitionNumber"
                  >
                    <td>{{ taskDefinition.description }}</td>
                    <td>
                      <small>{{ taskDefinition.name }}</small>
                    </td>
                    <td class="text-center">
                      <button
                        class="btn btn-primary m-1"
                        type="button"
                        @click="openRunTaskNowModal(taskDefinition)"
                      >
                        <i class="fa fa-bolt"></i> Run Now
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      :title="'Run ' + selectedTaskDefinition.name + ' task now'"
      ref="runNewTaskModal"
      size="modal-lg"
      save-text="Run Task"
      @save="runTaskNow()"
    >
      <h4>Required</h4>
      <table
        class="table table-bordered"
        v-if="selectedTaskDefinition.requiredInputParams.length > 0"
      >
        <tbody>
          <tr
            v-for="inputParam in selectedTaskDefinition.requiredInputParams"
            :key="inputParam.taskParameterDefinitionNumber"
          >
            <td>
              <strong>{{ inputParam.description }}</strong>
            </td>
            <td>
              <input
                type="checkbox"
                v-model="inputParam.value"
                v-if="inputParam.type == 'BOOLEAN'"
              />
              <input
                type="text"
                v-model="inputParam.value"
                v-else-if="inputParam.type == 'STRING'"
                class="form-control"
              />
              <input
                type="number"
                v-model="inputParam.value"
                v-else-if="inputParam.type == 'INT'"
                class="form-control"
              />
              <input
                type="number"
                v-model="inputParam.value"
                v-else-if="inputParam.type == 'DOUBLE'"
                class="form-control"
              />
              <input
                type="file"
                @change="fileSelected($event, inputParam)"
                v-else-if="inputParam.type == 'CONTENT'"
                class="form-control"
              />
              <DateInput
                v-model="inputParam.value"
                v-else-if="inputParam.type == 'DATE'"
              />
              <strong style="color: red" v-else
                >Param type {{ inputParam.type }} not supported</strong
              >
              <strong style="color: red" v-if="inputParam.hasError"
                >{{ inputParam.name }} is required</strong
              >
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>No Required Parameters</p>
      <hr />
      <h5>Optional</h5>
      <table
        class="table table-bordered"
        v-if="selectedTaskDefinition.optionalInputParams.length > 0"
      >
        <tbody>
          <tr
            v-for="inputParam in selectedTaskDefinition.optionalInputParams"
            :key="inputParam.taskParameterDefinitionNumber"
          >
            <td>
              <p>{{ inputParam.description }}</p>
            </td>
            <td>
              <input
                type="checkbox"
                v-model="inputParam.value"
                v-if="inputParam.type == 'BOOLEAN'"
              />
              <input
                type="text"
                v-model="inputParam.value"
                v-else-if="inputParam.type == 'STRING'"
                class="form-control"
              />
              <input
                type="number"
                v-model="inputParam.value"
                v-else-if="inputParam.type == 'INT'"
                class="form-control"
              />
              <input
                type="number"
                v-model="inputParam.value"
                v-else-if="inputParam.type == 'DOUBLE'"
                class="form-control"
              />
              <input
                type="file"
                @change="fileSelected($event, inputParam)"
                v-else-if="inputParam.type == 'CONTENT'"
                class="form-control"
              />
              <DateInput
                v-model="inputParam.value"
                v-else-if="inputParam.type == 'DATE'"
              />
              <strong style="color: red" v-else
                >Param type {{ inputParam.type }} not supported</strong
              >
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>No Optional Parameters</p>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
import DateInput from "@/components/GeneralComponents/InputControls/DateInput";
export default {
  components: {
    Modal,
    DateInput,
  },
  computed: {
    filterAvailableTasks() {
      if (this.searchText && this.searchText.length > 0) {
        return this.taskDefinitions.filter(
          (t) =>
            t.name.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1 ||
            t.description
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) != -1
        );
      } else {
        return this.taskDefinitions;
      }
    },
  },
  data() {
    return {
      taskDefinitions: [],
      searchText: null,
      selectedTaskDefinition: {
        requiredInputParams: [],
        optionalInputParams: [],
        notifyEmail: {},
      },
    };
  },
  mounted() {
    this.$root.$emit("SET_PAGE_TITLE", "Run New Task");

    this.$http.get(this.$config.aimsAPI + "tasks/definitions").then(
      (response) => {
        this.taskDefinitions = response.data;
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching Tasks", error);
      }
    );
  },
  methods: {
    openRunTaskNowModal(taskDefinition) {
      this.$set(taskDefinition, "requiredInputParams", []);
      this.$set(taskDefinition, "optionalInputParams", []);
      this.selectedTaskDefinition = taskDefinition;
      this.$http
        .get(
          this.$config.aimsAPI +
            "tasks/definitions/" +
            taskDefinition.name +
            "/params/input"
        )
        .then(
          (response) => {
            this.selectedTaskDefinition.requiredInputParams =
              response.data.filter((r) => r.required == true);
            this.selectedTaskDefinition.optionalInputParams =
              response.data.filter((r) => r.required == false);
            this.$refs.runNewTaskModal.open();
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Task Parameters", error);
          }
        );
    },
    fileSelected(event, inputParam) {
      const fileSelected = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(fileSelected);
      reader.onload = function () {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        inputParam.value = encoded;
        inputParam.meta = fileSelected.name;
      };
      reader.onerror = function (error) {
        console.error("Error selecting file: ", error);
        this.showError("Error selecting file");
      };
    },
    runTaskNow() {
      this.selectedTaskDefinition.requiredInputParams.forEach((rp) => {
        if (!rp.value || rp.value == "" || rp.value.length == 0) {
          this.$set(rp, "hasError", true);
        }
      });

      const paramValues = [];
      this.selectedTaskDefinition.requiredInputParams.forEach((rp) => {
        paramValues.push({
          name: rp.name,
          type: rp.type,
          value: rp.value,
          meta: rp.meta,
        });
      });

      this.selectedTaskDefinition.optionalInputParams.forEach((rp) => {
        paramValues.push({
          name: rp.name,
          type: rp.type,
          value: rp.value,
          meta: rp.meta,
        });
      });

      if (
        this.selectedTaskDefinition.requiredInputParams.filter(
          (rp) => rp.hasError == true
        ).length === 0
      ) {
        this.$refs.runNewTaskModal.isLoading();
        
        this.$http
          .post(
            this.$config.aimsAPI +
              "tasks/definitions/run?taskName=" +
              this.selectedTaskDefinition.name +
              "&runInForeground=" +
              this.$config.runInForeground,
            paramValues
          )
          .then(
            () => {
              this.$refs.runNewTaskModal.close();
              this.showSuccess("Task queued successfully");
              this.$router.push({
                name: "tasklist",
              });
            },
            (error) => {
              console.error(error);
              this.showError("Error Running Task", error);
            }
          );
      }
    }
  },
};
</script>
