<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-default">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3 col-lg-2 mb-3">
                <h4 class="modal-title">
                  Show tasks for:
                </h4>
                <v-select :options="timePeriods" @input="loadTasks()" v-model="timePeriod" label="name"
                  valueProp="days"></v-select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <SimpleTable :headings="tableConfig.headings" :dataKeys="tableConfig.dataKeys"
                  :searchFields="['taskNumber','name','status','ownerUsername']" :data="taskList" :clickable="true"
                  @rowClicked="taskClicked" />
              </div>
              <modal :title="selectedTask.taskNumber + ' - ' + selectedTask.name" ref="viewTaskModal" size="modal-xl"
                v-if="selectedTask" :delete-needed="true" @delete="deleteTask()" save-text="Email" @save="emailDialog()"
                dismiss-text="Close" @dismiss="loadTasks()">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-8">
                        <label>Description</label>
                        <input class="form-control" type="text" v-model="selectedTask.description" disabled />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Created</label>
                        <input class="form-control" type="text" v-model="selectedTask.created" disabled />
                      </div>
                      <div class="col-md-4">
                        <label>Status</label>
                        <input class="form-control" type="text" v-model="selectedTask.status" disabled />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-8">
                        <label>Owner</label>
                        <input class="form-control" type="text" v-model="selectedTask.ownerUsername" disabled />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Type</th>
                              <th>Kind</th>
                              <th>Required</th>
                              <th>Content Type</th>
                              <th>Description</th>
                              <th>Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="param in selectedTask.taskparameters" :key="param.objKey">
                              <td><small>{{param.name}}</small></td>
                              <td><small>{{param.type}}</small></td>
                              <td><small>{{param.kind}}</small></td>
                              <td><small>{{param.required}}</small></td>
                              <td><small>{{param.contentType}}</small></td>
                              <td><small>{{param.description}}</small></td>
                              <td v-if="param.contentType.length > 0">
                                <a href="javascript:void(0)" @click="downloadFile(param)">{{param.text}}</a>
                              </td>
                              <td v-else>
                                <small>{{param.text}}</small>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                    </div>
                  </div>
              </modal>
              <modal :title="'Task ' + this.selectedTask.name + ' - Email Task'" ref="emailDialogModal"
                save-text="Send Email" @save="sendEmail()" dismissText="Close">
                <div class="row">
                  <div class="col-md-12">
                    <input type="text" class="form-control" v-model="additionalContacts"
                      placeholder="Comma separate email addresses">
                  </div>
                </div>
              </modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SimpleTable from '@/components/GeneralComponents/SimpleTable'
  import Modal from '@/components/GeneralComponents/Modal'

  export default {
    components: {
      SimpleTable,
      Modal
    },
    data() {
      return {
        tableConfig: {
          headings: ['#', 'Name', 'Description', 'Status', 'Owner username', 'Created'],
          dataKeys: [{
              name: 'taskNumber'
            },
            {
              name: 'name'
            },
            {
              name: 'description'
            },
            {
              name: 'status',
              cssclass: this.getTaskClass(status)
            }, //cssclass: 'text-bold'},
            {
              name: 'ownerUsername'
            },
            {
              name: 'created'
            },
          ]
        },
        taskList: [],
        selectedTask: {},
        selectedContacts: [],
        additionalContacts: '',
        timePeriod: 7,
        timePeriods: [{
            name: 'Day',
            days: 1
          },
          {
            name: 'Week',
            days: 7
          },
          {
            name: 'Month',
            days: 30
          },
          {
            name: '3 months',
            days: 90
          }
        ]
      }
    },
    async mounted() {
      await this.loadTasks();
      this.$root.$emit('SET_PAGE_TITLE', this.$route.meta.title);
    },
    methods: {
      loadTasks() {
        this.$http.get(this.$config.aimsAPI + "tasks/list?days=" + this.timePeriod).then(response => {
          this.taskList = response.data;
        }, error => {
          console.error(error);
          this.showError('Error Fetching Tasks', error);
        });
      },
      getTaskClass(status) {
        switch (status) {
          case 'COMPLETED':
            return 'badge badge-info';
          case 'RUNNING':
            return 'badge badge-warning';
          case 'FAILED':
            return 'badge badge-success';
          default:
            return 'px-3';
        }
      },
      taskClicked(clickedTask) {
        this.selectedTask = {};
        this.$http.get(this.$config.aimsAPI + "tasks/" + clickedTask.objKey).then(response => {
          this.selectedTask = response.data;
        }, error => {
          console.error(error);
          this.showError('Error Fetching Task information', error);
        });

       this.$refs.viewTaskModal.open();
      },
      deleteTask() {
        this.$swal({
          title: 'Are you sure you want to delete this task?',
          text: this.selectedTask.name,
          type: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then(result => {
          if (result.value) {
            this.$refs.viewTaskModal.isLoading();
            this.$http.delete(this.$config.aimsAPI + 'tasks/' + this.selectedTask.objKey)
              .then(() => {
                  this.showSuccess('Successfully deleted Task');

                  this.loadTasks();
                  this.$refs.viewTaskModal.close();
                },
                error => {
                  this.showError('Error deleting Task', error);
                  console.error(error);
                }).finally(() => {
                  this.$refs.viewTaskModal.isLoading();
                });
          }
        })
      },
      emailDialog() {
        this.selectedContacts = [];
        this.additionalContacts = '';
        this.$refs.viewTaskModal.close();
        this.$refs.emailDialogModal.open();
      },
      sendEmail() {
        var addArray = this.additionalContacts.split(',');
        addArray.forEach(e => {
          this.selectedContacts.push(e);
        });

        if (this.selectedContacts.length > 0) {
          this.$refs.emailDialogModal.isLoading();
          this.$http.put(this.$config.aimsAPI + 'tasks/' + this.selectedTask.taskNumber + '/email', this
              .selectedContacts)
            .then(() => {
              this.showSuccess('Email Sent');
              this.$refs.emailDialogModal.close();
            }, error => {
              console.error(error);
              this.showError("Error Emailing Task", error);
            }).finally(() => {
              this.$refs.emailDialogModal.isLoading();
            });
        }
        this.additionalContacts = '';
        this.selectedContacts = [];
      },
      downloadFile(taskParam) {        
        this.$http.get(this.$config.aimsAPI + "tasks/params/" + taskParam.taskParameterNumber + "/download", {
            responseType: 'arraybuffer'
          })
          .then(function (response) {
            const contentType = response.headers.get('Content-Type');
            let blob = new Blob([response.data], {
              type: contentType
            });
            const fileName = response.headers.get('Content-Disposition').split("filename = ")[1];
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.dispatchEvent(new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            }));
          }, function (error) {
            console.error(error);
            this.showError('Error Downloading File', error.data);
          });
      }
    },
    created() {
      document.title = this.$route.meta.title
    }
  }

</script>

<style>
.text-wrapp {
  display: block !important;
  width: 10% !important;
}
</style>
