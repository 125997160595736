<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <h3>Change Package</h3>
                <div class="form-group row">
                    <label class="col-md-2 col-form-label">Packages: </label>
                    <div class="col-md-4">
                        <v-select
                            name="packages"
                            v-model="solarwindsBackup.backupPackage.solarwindsBackupPackageNumber"
                            label="description"
                            :options="packages"
                            valueProp="solarwindsBackupPackageNumber"
                        >
                        </v-select>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <button type="button" class="btn btn-success" @click="changePackage()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            solarwindsBackup: {
                type: Object,
                required: true,
            },
            packages: {
                type: Array,
                required: true,
            }
        },
        methods: {
            changePackage: function() {                               
                this.$emit('saveSale');
            }
        },
    }
</script>
