var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"card card-default"},[_c('service-wizard-layout',{attrs:{"title":"","subtitle":"","parts":_vm.parts,"onComplete":_vm.onComplete,"validateStep":_vm.validateStep}},[_c('template',{slot:"internet"},[_c('form',{attrs:{"novalidate":"","data-vv-scope":"internet"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('internet.description'),
                  }},[_c('label',[_vm._v("Description:")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.filledInSaleDefinition.requiredObjs.internet.description),expression:"filledInSaleDefinition.requiredObjs.internet.description"}],staticClass:"form-control",attrs:{"type":"text","name":"description","placeholder":"Description"},domProps:{"value":(_vm.filledInSaleDefinition.requiredObjs.internet.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filledInSaleDefinition.requiredObjs.internet, "description", $event.target.value)}}}),(_vm.errors.has('internet.description'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("internet.description")))]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"form-group",class:{
                  'has-error': _vm.errors.has('internet.bandwidth'),
                  }},[_c('label',[_vm._v("Bandwidth (Mbps):")]),_c('v-select',{attrs:{"name":"bandwidth","label":"label","options":_vm.bandwidthOptions},model:{value:(_vm.bandwidthSelected),callback:function ($$v) {_vm.bandwidthSelected=$$v},expression:"bandwidthSelected"}}),(_vm.errors.has('internet.bandwidth'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("internet.bandwidth")))]):_vm._e()],1)])])])]),_c('template',{slot:"network"},[_c('form',{attrs:{"novalidate":"","data-vv-scope":"network"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('network.id'),
                  }},[_c('label',[_vm._v("VLAN ID:")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.filledInSaleDefinition.requiredObjs.network.vlanId),expression:"filledInSaleDefinition.requiredObjs.network.vlanId"}],staticClass:"form-control",attrs:{"type":"text","name":"id","placeholder":"ID"},domProps:{"value":(_vm.filledInSaleDefinition.requiredObjs.network.vlanId)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filledInSaleDefinition.requiredObjs.network, "vlanId", $event.target.value)}}}),(_vm.errors.has('network.id'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("network.id")))]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('network.name'),
                  }},[_c('label',[_vm._v("VLAN Name:")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.filledInSaleDefinition.requiredObjs.network.vlanName),expression:"filledInSaleDefinition.requiredObjs.network.vlanName"}],staticClass:"form-control",attrs:{"type":"text","name":"name","placeholder":"Name"},domProps:{"value":(_vm.filledInSaleDefinition.requiredObjs.network.vlanName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filledInSaleDefinition.requiredObjs.network, "vlanName", $event.target.value)}}}),(_vm.errors.has('network.name'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("network.name")))]):_vm._e()])])])])])],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }