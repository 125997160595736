<template>
  <div v-dom-portal>
    <div class="modal" data-backdrop="static" data-keyboard="false" ref="communicationModal">
      <div class="modal-dialog" :class="size ? size : ''">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              {{title}}
              <!-- <p v-if="subtitle"><small>{{subtitle}}</small></p> -->
            </h4>
            <button type="button" class="close" @click="close()">&times;</button>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Circle } from "vue-loading-spinner";
  export default {
    components: {
      'loading': Circle
    },
    props: {
      title: {
        type: String,
        default: ""
      },
      subtitle: {
        type: String,
        required: false
      },
      size: {
        type: String,
        default: 'modal-md'
      },
    },
    data() {
      return {
        loading: false
      }
    },
    methods: {
      open() {
        this.loading = false;
        $(this.$refs.communicationModal).modal('show');
      },
      isLoading() {
        this.loading = !this.loading;
      },
      close() {
        $(this.$refs.communicationModal).modal('hide');
      }
    }
  }

</script>
