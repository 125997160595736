<template>
    <modal
        ref="saleTagModal"
        :title="modalTitle"
        :saveNeeded="shouldEditSaleTags === true"
        :deleteNeeded="shouldEditSaleTags === true"
        @save="saveSaleTag(selectedTag)"
        @delete="deleteSaleTag(selectedTag)"
    >
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Name</label>
                        <input type="text" class="form-control" v-model="saleTag.name" disabled :style="`background: ${saleTag.colour}; color: #ffffff; font-weight: bold`">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Value</label>
                        <input type="text" class="form-control" v-model="selectedTag.value">
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";

export default {
    components: {
        Modal,
    },
    props: {
        modalTitle: {
            type: String,
            required: true
        },
        selectedTag: {
            type: Object,
            required: true
        },
        saleTag: {
            type: Object,
            required: true
        },
        allClientSaleTags: {
            type: Array,
            required: true
        }
    },
    computed: {
        shouldEditSaleTags() {
            return this.userContainsPermission(["ADMIN", "CLIENT_ADMIN", "MANAGER", "SALES_META", "SALES_META_STAFF"]);
        }
    },
    methods: {
        open: function() {
          this.$refs.saleTagModal.open();
        },
        saveSaleTag: function(selectedTag) {
            const hostUrl = this.$config.aimsAPI;

            this.$http.post(`${hostUrl}saletags/save/value`, selectedTag).then(
                (response) => {
                    this.showSuccess("Tag Saved");
                    this.$emit("reloadTags");
                    this.$refs.saleTagModal.close(); 
                },
                (error) => {
                    console.error(error);
                    this.showError("Error Saving Tag", error);
                }
            )
        },
        deleteSaleTag: function(selectedTag) {
            this.$swal({
                title: "Are you sure you want to delete this Tag?",
                text: `${selectedTag.value}`,
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    this.$refs.saleTagModal.isLoading();
                    const hostUrl = this.$config.aimsAPI;

                    this.$http.delete(`${hostUrl}saletags/delete/value/${selectedTag.saleTagValueNumber}`).then(
                        (response) => {
                             this.showSuccess("Successfully deleted Tag");
                             this.$emit("reloadSaleTags");
                        },
                        (error) => {
                            this.showError("Error deleting Tag", error);
                            console.error(error);
                        }
                    ).finally(() => {
                        this.$refs.saleTagModal.isLoading();
                        this.$emit("reloadTags");
                        this.$refs.saleTagModal.close();
                    })
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>

</style>