<template>
    <div>
        <div class="personal-details-wrapper my-3" v-if="shouldCapturePersonalDetails">
            <h4>Personal Details</h4>
            <label v-allowed:view="['CLIENT']"><em>Should any of the below information found to be incorrect, please contact <a href="mailto:support@adept.co.za" target="_blank">support@adept.co.za</a> to have it corrected.</em></label>
            <hr />
            <div class="form-wrapper">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label>Title:</label>
                        <v-select name="title" :options="titleOptions" placeholder="Select Client Title" :disabled="!isAuthorizedUser && !isStaffUser" v-model="clientDetail.title">
                        </v-select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label>Firstname:</label>
                        <input type="text" class="form-control" name="firstname" :disabled="!isAuthorizedUser && !isStaffUser" v-model="clientDetail.firstnames">
                    </div>
                    <div class="form-group col-md-6">
                        <label>Lastname:</label>
                        <input type="text" class="form-control" name="lastname" :disabled="!isAuthorizedUser && !isStaffUser" v-model="clientDetail.lastname">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6" :class="{ 'has-error': errors.has('idnumber') }">
                        <label>ID Number:</label>
                        <input 
                            type="text" 
                            class="form-control" 
                            :disabled="!isAuthorizedUser" 
                            v-model="clientDetail.idnumber"
                            data-vv-as="ID Number"
                            name="idnumber"
                            :class="{ 'has-error': errors.has('idnumber') }"
                            v-validate="'digits:13'"
                        >
                        <small class="has-error mt-1" v-if="errors.has('idnumber')">{{ errors.first("idnumber") }}</small>
                    </div>
                    <div class="form-group col-md-6">
                        <label>Passport Number:</label>
                        <input type="text" class="form-control" :disabled="!isAuthorizedUser && !isStaffUser" v-model="clientDetail.passport">
                    </div>
                </div>
            </div>
        </div>
        <div class="company-details-wrapper my-3" v-if="shouldCaptureCompanyDetails">
            <h4>Company Details</h4>
            <label v-allowed:view="['CLIENT']"><em>Should any of the below information found to be incorrect, please contact <a href="mailto:support@adept.co.za" target="_blank">support@adept.co.za</a> to have it corrected.</em></label>
            <hr />
            <div class="form-wrapper">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label>Company:</label>
                        <input type="text" class="form-control" :disabled="!isAuthorizedUser && !isStaffUser" v-model="clientDetail.companyname">
                    </div>
                    <div class="form-group col-md-6"  :class="{ 'has-error': errors.has('crn') }">
                        <label>Company Registration Number:</label>
                        <input 
                            type="text" 
                            class="form-control"  
                            :disabled="!isAuthorizedUser && !isStaffUser"
                            v-model="clientDetail.companyRegistrationNumber"
                            data-vv-as="Company Registration Number"
                            name="crn"
                            :class="{ 'has-error': errors.has('crn') }"
                            v-validate="'min:4'"
                        >
                        <small class="has-error mt-1" v-if="errors.has('crn')">{{ errors.first("crn") }}</small>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label>Registered Company Name:</label>
                        <input 
                            type="text" 
                            class="form-control" 
                            :disabled="!isAuthorizedUser && !isStaffUser"
                            v-model="clientDetail.registeredCompanyName"
                            name="rcn"
                        >
                    </div>
                    <div class="form-group col-md-6" :class="{ 'has-error': errors.has('vatnumber') }">
                        <label>VAT Number:</label>
                        <input 
                            type="text" 
                            class="form-control" 
                            :disabled="!isAuthorizedUser && !isStaffUser"
                            v-model="clientDetail.vatNumber"
                            data-vv-as="VAT Number"
                            name="vatnumber"
                            :class="{ 'has-error': errors.has('vatnumber') }"
                            v-validate="{ regex: '^([4]\\d{9})$' }"
                        >
                        <small class="has-error mt-1" v-if="errors.has('vatnumber')">{{ errors.first("vatnumber") }}</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="save-button-wrapper" v-allowed:view="['ADMIN', 'ACCOUNTS_STAFF', 'ADMIN_STAFF', 'STAFF']">
            <button class="btn btn-info m-0" @click="updateClientDetails(clientDetail)">Save</button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            userType: {
                type: String,
                required: true,
            },
            titleOptions: {
                type: Array,
                required: true,
            },
            isAuthorizedUser: {
                type: Boolean,
                required: true,
            },
            isStaffUser: {
                type: Boolean,
                required: true,
            },
            clientDetail: {
                type: Object,
                required: true,
            },
        },
        mounted() {
            
        },
        computed: {
            shouldCapturePersonalDetails() {
                return this.userType == 'Private' || this.userType == 'Client of reseller';
            },
            shouldCaptureCompanyDetails() {
                return this.userType == 'Corporate' || this.userType == 'Client of reseller' || this.userType == 'Reseller';
            }
        },
        methods: {
            updateClientDetails: function(clientDetail) {
                this.$validator.validateAll().then((valid) => {
                    if (valid) {
                        this.$emit('updateClientDetails', clientDetail)
                    }
                });     
            }
        },
    }
</script>

<style scoped>

</style>