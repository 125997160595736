<template>
    <div>
        <div class="row row-spacing">
            <div class="col-md-6">
                <div class="form-group">
                    <label>Billing Period</label>
                    <BillingFilter
                        :baseArray="saleDefinition.sale.synaq.licences"
                        @selectedOption="changeFilter"
                    />
                </div>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-md-12">
                <table
                    class="table table-bordered"
                    v-if="saleDefinition.sale.synaq.licences.length > 0"
                >
                    <thead>
                    <tr>
                        <th>Type</th>
                        <th>From</th>
                        <th>Until</th>
                        <th>Unit Price (Excl. VAT)</th>
                        <th>Quantity</th>
                        <th>Price (Excl. VAT)</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="licence in filteredList"
                        :key="licence.synaqeDomainLicenceNumber"
                    >
                        <td>{{ licence.type }}</td>
                        <td>{{ dateFormat(licence.validFrom) }}</td>
                        <td>{{ dateFormat(licence.validTo) }}</td>
                        <td>{{ currencyFormat(licence.unitPriceExclVat) }}</td>
                        <td>{{ licence.quantity }}</td>
                        <td>{{ currencyFormat(licence.priceExclVat) }}</td>
                    </tr>
                    </tbody>
                </table>
                <div v-else class="alert alert-info">
                    No Licences loaded for
                    {{ saleDefinition.sale.synaq.name }}
                </div>
                </div>
        </div>
    </div>
</template>

<script>
import BillingFilter from "./BillingFilter";

    export default {
        components: {
            BillingFilter,
        },
        props: {
            saleDefinition: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                filteredList: [],
            }
        },
        methods: {
            changeFilter: function(data) {
                this.filteredList = data;
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>