<template>
    <div class="row mt-3">
        <div class="col-md-7">      
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label>Description</label>
                    <input type="text" class="form-control" :disabled="!isAdmin" v-model="saleDefinition.definition.service.description"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label>Axxess Username</label>
                    <input type="text" class="form-control" :disabled="!isAdmin" v-model="saleDefinition.definition.service.axxessUsername"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label>Axxess Sim Serial Number</label>
                    <input type="text" class="form-control" :disabled="!isAdmin" v-model="saleDefinition.definition.service.axxessSimSerialNumber"/>
                </div>
                <div class="form-group col-md-6">
                    <label>Axxess Cell Number</label>
                    <input type="text" class="form-control" :disabled="!isAdmin" v-model="saleDefinition.definition.service.axxessSimCellNumber"/>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="bg-warning badge" v-if=" Object.keys(pendingDeleteAccountInstruction).length > 0 || Object.keys(pendingSuspendAccountInstruction).length > 0">
                        <i class="fa fa-info-circle"></i> <span class="bold">Instructions Pending.</span>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row row-spacing">
                        <div class="col-md-12">
                            <div class="form-row justify-content-between row-spacing">
                                <div class="form-group col-md-auto">
                                    <button type="button" @click="saveSale(saleDefinition)" class="btn btn-success" :disabled="!isAdmin" v-if="!clientReadOnly">
                                        Save
                                    </button>
                                </div>
                                <div class="form-group col-md-auto">
                                    <div class="form-row">
                                        <div class="col-auto">
                                            <button
                                                v-allowed:view="['ADMIN', 'ACCOUNTS_ADMIN', 'ACCOUNTS_STAFF']"
                                                type="button"
                                                :disabled="Object.keys(pendingDeleteAccountInstruction).length > 0"
                                                v-if="Object.keys(pendingSuspendAccountInstruction).length == 0"
                                                @click="$emit('sendSuspendAccountInstruction')"
                                                class="btn btn-warning"
                                            >
                                                Suspend
                                            </button>
                                            <button
                                                v-allowed:view="['ADMIN','ACCOUNTS_ADMIN', 'ACCOUNTS_STAFF']"
                                                type="button"
                                                :disabled="Object.keys(pendingDeleteAccountInstruction).length > 0"
                                                v-else
                                                @click="$emit('sendRestoreAccountInstruction')"
                                                class="btn btn-green"
                                            >
                                                Restore
                                            </button>
                                        </div>
                                        <div class="form-group col-auto">
                                            <button
                                                v-allowed:view="['ADMIN', 'ACCOUNTS_ADMIN', 'ACCOUNTS_STAFF']"
                                                type="button"
                                                v-if="Object.keys(pendingDeleteAccountInstruction).length > 0"
                                                @click="$emit('sendRestoreAccountInstruction')"
                                                class="btn btn-primary"
                                            >
                                                Cancel Expire Instruction
                                            </button>
                                            <button
                                                v-else
                                                v-allowed:view="['ADMIN','ACCOUNTS_ADMIN', 'ACCOUNTS_STAFF']"
                                                type="button"
                                                @click="$emit('sendDeleteAccountInstruction')"
                                                class="btn btn-primary"
                                            >
                                                Expire Service
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="card card-default">
                <div class="card-header">
                    <h4>Connectivity Status</h4>
                </div>
                <div class="card-body">
                    <div class="form-group mb-md-0 row">
                        <label class="col-md-4 mb-md-0">Status:</label>
                        <div class="col-md-8">
                            <strong>{{ saleDefinition.definition.service.status }}</strong>
                        </div>
                    </div>
                    <div class="form-group mb-md-0 row">
                        <label class="col-md-4 mb-md-0">Last updated:</label>
                        <div class="col-md-8">
                            <strong>{{ dateTimeFormat(saleDefinition.definition.service.modified) }}</strong>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card card-default">
                <div class="card-header">
                    <h4>Connectivity Package</h4>
                </div>
                <div class="card-body">
                    <div class="form-group mb-md-0 row">
                        <label class="col-md-6 mb-md-0">Package Description:</label>
                        <div class="col-md-6">
                            <strong>{{ saleDefinition.definition.service.bundle.description }}</strong>
                        </div>
                    </div>
                    <div class="form-group mb-md-0 row">
                        <label class="col-md-6 mb-md-0">Package Id:</label>
                        <div class="col-md-6">
                            <strong>{{ saleDefinition.definition.service.bundle.axxessLtePackageNumber }}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            saleDefinition: {
                type: Object,
                required: true
            },
            pendingDeleteAccountInstruction: {
                type: Object,
                required: true
            },
            pendingSuspendAccountInstruction: {
                type: Object,
                required: true
            },
            isSaving: {
                type: Boolean,
                required: true
            },
            isAdmin: {
                type: Boolean,
                required: true
            },
            clientReadOnly: {
                type: Boolean,
                required: true
            },
        },
        methods: {
         saveSale: function(saleDefinition) {             
           this.$emit("saveAxxessLteService", saleDefinition);
         }
        },
    }
</script>
