<template>
    <modal
        :title="'Email Contacts'"
        ref="emailModal"
        size="modal-xl"
        save-text="Send Email"
        @save="sendEmail()"
        dismiss-text="Close"
        >
        <div class="row">
            <div class="col-md-12">
            <table class="table table-bordered table-hover">
                <thead>
                <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="person in clientContacts"
                    :key="person.personNumber"
                    @click="contactClicked(person.emailAddress)"
                >
                    <td>{{ person.firstNames }}</td>
                    <td>{{ person.lastName }}</td>
                    <td>{{ person.emailAddress }}</td>
                    <td>
                    <input
                        type="checkbox"
                        :checked="isSelected(person.emailAddress)"
                    />
                    </td>
                </tr>
                </tbody>
            </table>

            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
            <input
                type="text"
                class="form-control"
                v-model="additionalContacts"
                placeholder="Comma separate additional email addresses"
            />
            </div>
        </div>
    </modal>
</template>
  
<script>
import Modal from "@/components/GeneralComponents/Modal";
  export default {
    name: "EmailModal",
    components: {
        Modal,
    },
    props: {
      clientNumber: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        clientContacts: [],
        selectedContacts: [],
        additionalContacts: "",
      }
    },
    methods: {
        open() {
            this.$http.get(this.$config.aimsAPI + "documents/contacts/" + this.clientNumber)
                .then((response) => {
                    this.clientContacts = response.data;
                    this.selectedContacts = [];
                    this.$refs.emailModal.open();
                },(error) => {
                    console.error(error);
                    this.showError("Error Fetching Client Contacts", error);
                }
            );     
        },
        close() {
            this.$refs.emailModal.close();
        },
        sendEmail() {
            this.$refs.emailModal.isLoading();
            var addArray = this.additionalContacts.split(",");
            addArray.forEach((e) => {
                this.contactClicked(e);
            });
            
            this.$emit("send", this.selectedContacts);
            this.additionalContacts = "";
            this.selectedContacts = [];
        },
        contactClicked(emailAddress) {
            if (emailAddress.length > 0) {
                const indx = this.selectedContacts.indexOf(emailAddress);
                if (indx >= 0) {
                    this.selectedContacts.splice(indx, 1);
                } else {
                    this.selectedContacts.push(emailAddress);
                }
            }
        },
        isSelected(emailAddress) {
            const indx = this.selectedContacts.findIndex((p) => p === emailAddress);
            if (indx >= 0) {
                return true;
            } else {
                return false;
            }
        }
    },
  };
</script>

<style>

</style>
