<template>
    <service-screen-layout :parts="parts">
        <template v-slot:certificate>
            <SslCertificate 
                :saleDefinition="saleDefinition"
                @saveSale="saveSale"
            />
        </template>
        <template v-slot:certificateIssue>
            <SslCertificateIssue 
                :saleDefinition="saleDefinition"
                @saveSale="saveSale"
                @reloadSale="getSaleDefinition"
            />
        </template>
    </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";

import SslCertificate from '@/components/ObjParts/SingleCaptured/SslCertificate'
import SslCertificateIssue from '@/components/ObjParts/MultipleCaptured/SslCertificateIssue'

export default {
    components: {
        ServiceScreenLayout,
        SslCertificate,
        SslCertificateIssue
    },
    data() {
        return {
            parts: [
                {
                    name: "certificate",
                    icon: "fa-solid fa-file-shield",
                    displayName: "Certificate",
                    permissions: ["*"],
                },
                {
                    name: "certificateIssue",
                    icon: "fa-regular fa-rectangle-list",
                    displayName: "Certificate Issue",
                    permissions: ["*"],
                }
            ],
            saleNumber: null,
            saleDefinition: {
                sale: {
                    certificate: {
                        certificateissue: [],
                    }
                },
                meta: {
                    certificate: {
                        children: {
                            certificateissue: {
                                objMeta: {
                                    enums: {
                                        product: [],
                                        issuer: [],
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    mounted() {
        this.saleNumber = this.$route.params.saleNumber;

        this.getSaleDefinition();
    },
    methods: {
        getSaleDefinition: function() {
            const hostUrl = this.$config.aimsAPIv2;
            const saleNumber = this.saleNumber;

            this.$http.get(`${hostUrl}sales/definition/sale/${saleNumber}`).then(
                (response) => {
                    this.saleDefinition = response.data;
                },
                (error) => {
                    console.error(error);
                    this.showError("Error fetching Sale Definition", error);
                }
            )
        },
        saveSale: function(saleDefinition) {
            const hostUrl = this.$config.aimsAPIv2;
            const saleNumber = this.saleNumber;

            this.$http.put(`${hostUrl}sales/definition/${saleNumber}/update`, saleDefinition).then(
                (response) => {
                    this.showSuccess("Save Success");
                    this.getSaleDefinition();
                },
                (error) => {
                    console.error(error);
                    this.showError("Error saving Sale Definition", error);
                }
            )
        }
    },
}
</script>