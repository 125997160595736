<template>
    <div class="table-responsive table-bordered">
        <table class="table text-nowrap">
            <thead>
                <tr>
                    <th>Start Time</th>
                    <th>Stop Time</th>
                    <th>IPv4</th>
                    <th>IPv6</th>
                    <th>{{cpeAddressName}}</th>
                    <th>Duration</th>
                    <th>Termination Cause</th>
                    <th>Download</th>
                    <th>Upload</th>
                    <th>Last Updated</th>
                    <th>NAS IP</th>
                    <th>NAS Port</th>
                </tr>
            </thead>
            <tbody v-if="sessions.length > 0">
                <tr v-for="session in sessions" :key="session.objKey">
                    <td class="text-center">{{session.startTime}}</td>
                    <td class="text-center">
                        <span v-if="session.stopTime != '1970-01-01 02:00:00'">{{session.stopTime}}</span>
                        <span class="badge bg-green" v-else>Active</span>
                    </td>
                    <td>{{session.ipAddressV4}}</td>
                    <td>{{session.ipAddressV6}}</td>
                    <td>
                        <p v-if="cpeAddressField == 'cpeAddress'" @mouseenter="lookupCpe(session)" style="cursor:pointer">
                            {{session[cpeAddressField]}}
                            <strong>{{cpeMacAddresses[session[cpeAddressField]]}}</strong>
                            <small v-if="session.fetchingMac">Fetching Mac Details...</small>
                        </p>
                        <p v-else>
                            {{session[cpeAddressField]}}
                        </p>
                    </td>
                    <td>
                        <span :class="getTime(session.duration)">
                            {{ time(session.duration) }}
                        </span>
                    </td> 
                    <td>
                        <span v-if="session.terminationCause">{{session.terminationCause}}</span>
                        <span v-else class="badge bg-danger">None</span>
                    </td>
                    <td class="text-center">{{size(session.outBytes)}}</td>
                    <td class="text-center">{{size(session.inBytes)}}</td>
                    <td class="text-center">{{session.lastUpdate}}</td>
                    <td>{{session.nasIpAddress}}</td>
                    <td>{{session.nasPort}}</td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="12">No sessions available</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    props: {
        sessions:{
            type: Array,
            required: true,
            default: () => [],
        },
        cpeAddressName: {
            type: String,
            required: true
        },
        cpeAddressField: {
            type: String,
            required: true
        }
    },
    data(){
        return {
            cpeMacAddresses: {

            }
        }
    },
    methods: {
        size: function(bytes) {
            if(bytes.toString().length > 9) {
                return this.sizeBytesToGb(bytes) + ' GB'
            } else if(bytes.toString().length > 6) {
                return this.sizeBytesToMb(bytes) + ' MB'
            } else {
                return this.sizeBytesToKb(bytes) + ' KB'
            }
        },
        lookupCpe(session){
            if(!this.cpeMacAddresses[session[this.cpeAddressField]]){
                if(this.cpeAddressField == 'cpeAddress' && session[this.cpeAddressField] && session[this.cpeAddressField] != '' && !session.macDetails && !session.fetchingMac){
                    this.$set(session, 'fetchingMac', true);
                    this.$http.get(
                        this.$config.aimsAPI + 'system/tools/macaddress/' + session[this.cpeAddressField]
                        + '/' + session.username
                    ).then(response => {
                        session.fetchingMac = false;
                        this.$set(this.cpeMacAddresses, session[this.cpeAddressField], response.data);
                    }, error => {
                        session.fetchingMac = false;
                        this.$set(this.cpeMacAddresses, session[this.cpeAddressField], 'Failed to fetch MAC details');
                        console.error(error);
                    });
                }
            }

            
        },
        time(ms) {
            var time = moment.duration(ms, "seconds").humanize();
            return time
        }
    }
}
</script>

<style>

</style>
