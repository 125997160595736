<template>
  <div class="card card-default">
    <div class="card-body">
      <div class="row">
        <div class="col-md-5">
          <div class="form-group row">
            <label class="col-md-5 col-form-label">Billing Period for Capture:</label>
            <div class="col-md-7">
              <v-select
                :options="periods"
                placeholder="Select Billing Period"
                v-model="selectedPeriod"
                :get-option-label="getLabel"
                @input="getItemsInPeriod"
              ></v-select>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="selectedPeriod">
        <div class="col-md-12">
          <div class="card border-light">
            <div class="card-body">
                <table class="table table-responsive" v-if="itemsInPeriod.length > 0">
                  <thead>
                    <tr class="dark-row">
                      <th>Customer Name</th>
                      <th>Item</th>
                      <th>SKU</th>
                      <th style="width:10%">Quantity</th>
                      <th style="width:15%">Unit Cost (excl. VAT)</th>
                      <th>Total</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody v-if="itemsInPeriod.length > 0">
                    <tr v-for="item in itemsInPeriod" :key="item.mimecastItemNumber">                    
                      <td>
                        <select class="form-control" v-model="item.mimecastServiceNumber" :disabled="selectedPeriod < period" title="Contact an Admin to change this on the Service Page">
                          <option
                            v-for="service in availableServices"
                            :key="service.mimecastServiceNumber"
                            :value="service.mimecastServiceNumber"
                          >
                            {{ service.description }} 
                          </option>
                        </select>
                      </td>
                      <td>
                        <input type="text" class="form-control" v-model="item.item" :disabled="selectedPeriod < period" />                        
                      </td>
                      <td>
                        <input type="text" class="form-control" v-model="item.sku" :disabled="selectedPeriod < period" />
                      </td>
                      <td>
                        <input type="number" class="form-control" v-model="item.quantity" :disabled="selectedPeriod < period" />
                      </td>
                      <td>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">R</span>
                          </div>
                          <input
                            type="number"
                            class="form-control"
                            style="width:30%"
                            v-model="item.unitCostExVat"
                            :disabled="selectedPeriod < period"
                          />
                        </div>
                      </td>
                      <td>
                        {{ totalPriceExVat(item) }}
                      </td>
                      <td v-if="selectedPeriod >= period && item.objKey > 0">
                        <button
                          v-tooltip="'Delete Entry'"
                          @click="deleteEntry(item)"
                          type="button"
                          class="btn btn-sm btn-primary">
                          <em class="fa fa-trash fa-fw"></em>
                        </button>
                      </td>
                    </tr> 
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td class="text-center" colspan="6">No items to display</td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="alert alert-info">
                  No Items found
                </div>
              <div class="row row-spacing" v-if="selectedPeriod >= period">
                <div class="col-md-12">
                  <button type="button" class="btn btn-info mr-2"
                    @click="addEntry()" v-if="(itemsInPeriod.length == 0) || (!itemsInPeriod.some((p) => p.objKey == 0))">
                    Add Entry
                  </button>
                  <button
                    type="button" @click="saveEntries()" class="btn btn-primary" v-if="(itemsInPeriod.length > 0) && completeEntry">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    newEntry: {
      deep: true,
      handler(entry) {
        this.completeEntry =
          entry.mimecastServiceNumber && entry.quantity && entry.unitCostExVat;
      },
    },
  },
  data() {
    return {
      period: this.getCurrentPeriod(),
      selectedPeriod: null,
      periods: [],
      itemsInPeriod: [],
      availableServices: [],
      selectedService: null,
      newEntry: {},
      completeEntry: true,
    };
  },
  created() {
      this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
  },
  mounted() {
    this.getMimecastAvailabelServices();
    this.getMimecastAvailablePeriods();
  },
  methods: {
    getMimecastAvailabelServices: function() {
      const hostUrl = this.$config.aimsAPI; 
  
      this.$http.get(`${hostUrl}mimecastservice/availableservices`).then(
        (response) => {
          this.availableServices = response.data;  
        },
        (error) => {
          this.showError("Error fetching Mimecast Services", error);
          console.error(error);
        }
      );
    },
    getMimecastAvailablePeriods: function() {
      let periods = [];
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}mimecastservice/availableperiods`).then(
        (response) => {
            this.periods = periods.concat(response.data);
          },
          (error) => {
            this.showError("Error fetching periods for Data Capture", error);
            console.error(error);
          }
        );
    },
    getItemsInPeriod: function(period) {
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}mimecastservice/mimecastitems/${period}`).then(
        (response) => {
            this.itemsInPeriod = response.data;            
          },
          (error) => {
            this.showError("Error fetching Billing Items", error);
            console.error(error);
          }
        );
    },
    addEntry: function() {
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}mimecastservice/empty/mimecastitem/${this.selectedPeriod}`).then(
          (response) => {
            this.newEntry = response.data;
            this.itemsInPeriod.push(this.newEntry);
          },
          (error) => {
            this.showError("Error fetching empty Billing Item", error);
            console.error(error);
          }
        );
    },
    saveEntries: function() {
      const hostUrl = this.$config.aimsAPI;

      this.$http.post(`${hostUrl}mimecastservice/mimecastitems/save`, this.itemsInPeriod).then(
          (response) => {
            this.itemsInPeriod = response.data;
            this.showSuccess("Successfully updated Billing Items.");
            this.getItemsInPeriod(this.selectedPeriod);
          },
          (error) => {
            this.showError("Error updating Billing Items", error);
            console.error(error);
          }
        );
    },
    deleteEntry: function(mimecastItem) {
      this.$swal({
        title: "Are you sure you want to delete Billing Item?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          const hostUrl = this.$config.aimsAPI;

          this.$http.delete(`${hostUrl}mimecastservice/mimecastitem/${mimecastItem.objKey}`).then(
              (response) => {
                this.showSuccess(response.data.message);
                this.getItemsInPeriod(this.selectedPeriod);
              },
              (error) => {
                this.showError("Error Deleting Billing Item", error);
                console.error(error);
              }
            );
        }
      });
    },
    totalPriceExVat: function({ unitCostExVat, quantity }) {
      return this.currencyFormat(unitCostExVat * quantity);
    },
    getLabel: function(period) {
      return this.prettifyBillingPeriod(period);
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-row {

  th {
    background-color: #3a3f51;
    color: #FFFFFF;
    position: sticky;
    top: 0;
    z-index: 2;
    font-size: .95em;
  }
}

.table-responsive {
  max-height: calc(90vh - 250px) !important;
}
</style>