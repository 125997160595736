<template>
    <div class="row">
        <div class="col-md-12 mt-4">
            <div class="table-responsive table-bordered">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Period</th>
                            <th>Usage</th>
                        </tr>
                    </thead>
                    <tbody v-if="usage.length > 0">
                        <tr v-for="u in usage" :key="u.virtualServerUsageNumber">
                            <td>{{u.period}}</td>
                            <td>{{sizeBytesToGb(u.usage)}} GB</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="2">No Usage found</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            usage: {
                type: Array,
                required: true
            },
        },
    }
</script>