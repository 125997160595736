<template>
  <modal
    :title="
      topupPrice.connectivityTopupNumber
        ? 'Edit Topup Package'
        : 'Add New Topup Package'
    "
    ref="modifyPriceModal"
    size="modal-lg"
    @save="savePricePackage()"
  >
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> Description </label>
        <input
          type="text"
          class="form-control"
          v-model="topupPrice.description"
          disabled
          placeholder="Description will be created when saved."
        />
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> Label </label>
        <input
          type="text"
          class="form-control"
          v-model="topupPrice.label"
          placeholder="Additional text shown with the Description"
        />
      </div>
    </div>   

    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> Size </label>
        <div class="input-group">
          <input type="text" v-model="topupPrice.size" class="form-control" />
          <div class="input-group-append">
            <span class="input-group-text">GB</span>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
export default {
  props: {
    topupPrice: {
      type: Object,
      required: true,
    },
  },
  components: {
    Modal,
  },
  methods: {
    open() {
      this.$refs.modifyPriceModal.open();
    },
    getLabel({ name, location }) {
      return name + " - " + location;
    },
    savePricePackage() {
      this.$refs.modifyPriceModal.isLoading();
      this.$http
        .post(
          this.$config.aimsAPI + "pricing/pricepackages/save",
          this.topupPrice
        )
        .then(
          (response) => {
            this.$refs.modifyPriceModal.close();
            if (this.topupPrice.connectivityTopupNumber) {
              this.showSuccess("Successfully Edited Package");
              this.$emit("packageEdited");
            } else {
              this.showSuccess("Successfully Added Package");
              this.$emit("packageAdded", response.data);
            }
          },
          (error) => {
            this.showError("Error Saving Pricing", error);
            console.error(error);
          }
        )
        .finally(() => {
          this.$refs.modifyPriceModal.isLoading();
        });
    },
  },
};
</script>
