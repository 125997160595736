<template>
  <div class="row">
    <div class="col-xl-8 col-lg-12">
      <div class="card card-default">
        <form-wizard
          title=""
          subtitle=""
          @on-complete="onComplete"
          stepSize="md"
        >
          <tab-content 
            title="Domain Registration"
            :before-change="() => validateStep('domain-registration-name')" 
          >
              <div class="row">
                <div class="col-xl-10">
                  <div class="form-group">
                    <label class="control-label">Name</label>
                    <input
                      placeholder="Host name and Domain name (e.g. acme.co.za)"
                      name="DomainName"
                      type="text"
                      class="form-control"
                      v-model="fullDomainName"/>
                  </div> 
                </div>
                <!-- <div class="col-xl-6">
                    <label class="control-label">Full Domain Name</label>
                    <input type="text" class="form-control" disabled v-model="this.displayName"/>
                </div> -->
              </div>
          </tab-content>
          <tab-content
            title="Package and Pricing"
            :before-change="() => validateStep('domain-registration-package')"
          >
            <form novalidate data-vv-scope="domain-registration">
              <div class="row">
                <div class="col-xl-8">
                    <label class="control-label">Domain Name</label>
                    <input type="text" class="form-control" disabled v-model="this.fullDomainName"/>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                      <label class="control-label">Top Level Domain</label>
                      <v-select
                        disabled
                        name="Tld"
                        placeholder="Select Top Level Domain"
                        label="tld"
                        v-model="selectedOptions.tld"
                        :options="distinctTlds"
                      ></v-select>
                    </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">  
                  <label class="control-label">Select Package</label>
                    <v-select
                      name="DomainPackage"
                      placeholder="Select Package"
                      label="description"
                      valueProp="domainRegistrationPackageNumber"
                      v-model="filledInSaleDefinition.requiredObjs.registration.domainRegistrationPackageNumber"
                      :options="availablePackages"
                      @input="onPackageChanged()"
                    ></v-select>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <label class="control-label"
                      >Renewable period (months)</label
                    >
                    <input v-model="selectedOptions.renew" class="form-control" disabled/>
                  </div>
                </div>
              </div>

            </form>
          </tab-content>
          
          <tab-content
            title="Registration Contact Info"
            :before-change="() => validateStep('domain-registrant-contact')"
          >
            <form novalidate data-vv-scope="domain-registrant-contact">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group">
                    <label class="control-label">Admin</label>
                    <v-select
                      name="domainContactsAdmin"
                      :options="clientDomainContacts"
                      placeholder="Assign a Domain Contact to this Role"
                      v-model="filledInSaleDefinition.requiredObjs.adminRole.domainContactNumber"
                      :get-option-label="getLabel"
                      valueProp="domainContactNumber"
                      clearable
                      :searchable="true"
                    >
                    </v-select>
                  </div>
                </div> 
               
                       <button type="button" class="btn btn-link" ref="adminReUse" @click="duplicateAdmin" :disabled="!this.filledInSaleDefinition.requiredObjs.adminRole.domainContactNumber">
                        Assign Admin to all roles</button>
                
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group">
                    <label class="control-label">Registrant</label>
                    <v-select
                      name="domainContactsReg"
                      :options="clientDomainContacts"
                      placeholder="Assign a Registrant Contact to this Role"
                      v-model="filledInSaleDefinition.requiredObjs.registrantRole.domainContactNumber"
                      :get-option-label="getLabel"
                      valueProp="domainContactNumber"
                      clearable
                      :searchable="true"
                    >
                    </v-select>
                  </div>
                </div>  
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group">
                    <label class="control-label">Billing</label>
                    <v-select
                      name="domainContactsBilling"
                      :options="clientDomainContacts"
                      placeholder="Assign a Billing Contact to this Role"
                      v-model="filledInSaleDefinition.requiredObjs.billingRole.domainContactNumber"
                      :get-option-label="getLabel"
                      valueProp="domainContactNumber"
                      clearable
                      :searchable="true"
                    >
                    </v-select>
                  </div>
                </div>  
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group">
                    <label class="control-label">Technical</label>
                    <v-select
                      name="domainContactsTech"
                      :options="clientDomainContacts"
                      placeholder="Assign a Technical Contact to this Role"
                      v-model="filledInSaleDefinition.requiredObjs.technicalRole.domainContactNumber"
                      :get-option-label="getLabel"
                      valueProp="domainContactNumber"
                      clearable
                      :searchable="true"
                    >
                    </v-select>
                  </div>
                </div>  
              </div>
            </form>    
          </tab-content>       
        </form-wizard>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  computed: {},
  components: {
    FormWizard,
    TabContent
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filledInSaleDefinition: {
        requiredObjs: {
          registration: {},
          adminRole: {
            role: "ADMIN",
            domainContactNumber: null,
          },
          billingRole: {
            role: "BILLING",
            domainContactNumber: null,
          },
          registrantRole: {
            role: "REGISTRANT",
            domainContactNumber: null,
          },
          technicalRole: {
            role: "TECH",
            domainContactNumber: null,
          } 
        }
      },
      clientDomainContacts: [],

      distinctTlds: [],
      distinctTerms: [],
      availablePackages: [],
      selectedOptions: {
        selectedDescription: null,
        selectedPrice: null,
        tld: null,
        renew: null,
      },
      isValidDomain: true,
      domainInput: "",
      fullDomainName:""
    };
  },
  watch: {

  },
  mounted: function () {
    this.filledInSaleDefinition = this.emptySaleDefinition;
    this.filledInSaleDefinition.selectableOptions.domainRegistrationPackage.map(
      (p) => {
          if (!this.distinctTlds.includes(p.topLevelDomain)) {
            this.distinctTlds.push(p.topLevelDomain);
          }
      });
    this.getClientDomainContacts();
  },
  methods: {
    onComplete() {
      this.$swal({
        title: "Register " + this.fullDomainName,
        text: "Are you sure you want to register " + this.fullDomainName + "?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Register Domain",
      }).then((result) => {
        if (result.value) {
          const saleDefCopy = this.deepCopyObject(this.filledInSaleDefinition);

          //fully qualified domain name
          saleDefCopy.requiredObjs.registration.name = this.fullDomainName;
          if (saleDefCopy.requiredObjs.hosting) {
            saleDefCopy.requiredObjs.hosting.name =
              saleDefCopy.requiredObjs.registration.name;
          }
        
          this.$emit("createSale", saleDefCopy);

        }
      });
    },
    getClientDomainContacts: function() {
      const hostUrl = this.$config.aimsAPI;
      const clientNumber = this.$route.params.clientNumber;

      this.$http.get(`${hostUrl}domains/contacts/${clientNumber}`).then(
          (response) => {
              this.clientDomainContacts = response.data;
          },
          (error) => {
              console.error(error);
              this.showError("Error fetching Domain Registration Contacts", error);
          }
      );
    },
    validateStep(stepName) {
      if (stepName == "domain-registration-name") {
        //will we maybe a domain availability check here in the future?
        //don't stop the users progress for now
        this.determineTLD();
        return true;
      } else if (stepName == "domain-registration-package") {
        if (this.filledInSaleDefinition.requiredObjs.registration.domainRegistrationPackageNumber=== "") {
          return false;  
        }
        return true;
      } else if (stepName == "domain-registrant-contact") {
        return this.$validator.validateAll(stepName).then((result) => {
          if (result) {
            if ((this.filledInSaleDefinition.requiredObjs.adminRole.domainContactNumber === "")
             || (this.filledInSaleDefinition.requiredObjs.registrantRole.domainContactNumber === "")
             || (this.filledInSaleDefinition.requiredObjs.billingRole.domainContactNumber === "")
             || (this.filledInSaleDefinition.requiredObjs.technicalRole.domainContactNumber === "")
             || (this.filledInSaleDefinition.requiredObjs.adminRole.domainContactNumber === 0)
             || (this.filledInSaleDefinition.requiredObjs.registrantRole.domainContactNumber === 0)
             || (this.filledInSaleDefinition.requiredObjs.billingRole.domainContactNumber === 0)
             || (this.filledInSaleDefinition.requiredObjs.technicalRole.domainContactNumber === 0)
             ) {
               return false;
            }
            return true;
          } else {
            return false;
          }
        });
      } else {
        console.error("Validation for step " + stepName + " not implemented");
        return false;
      }
    },
    determineTLD() {
      this.availablePackages = [];
      this.filledInSaleDefinition.requiredObjs.registration.domainRegistrationPackageNumber="";
      this.filledInSaleDefinition.requiredObjs.adminRole.domainContactNumber = "";
      this.filledInSaleDefinition.requiredObjs.billingRole.domainContactNumber = "";
      this.filledInSaleDefinition.requiredObjs.registrantRole.domainContactNumber = "";
      this.filledInSaleDefinition.requiredObjs.technicalRole.domainContactNumber = "";
      this.filledInSaleDefinition.requiredObjs.registration.name = "";

      //make clever determination here to find tld  
      this.$http.put(this.$config.aimsAPI + "domains/determine/tld/" + this.fullDomainName, this.distinctTlds).then(
          (response) => {
            this.selectedOptions.tld = response.data;
            this.filledInSaleDefinition.selectableOptions.domainRegistrationPackage.map(
              (p) => {
                if (p.topLevelDomain == this.selectedOptions.tld) {
                  this.availablePackages.push(p);
                }
              });
            if (this.availablePackages.length > 0) {
              this.filledInSaleDefinition.requiredObjs.registration.domainRegistrationPackageNumber = this.availablePackages[0];
            } 
          },
          (error) => {
              console.error(error);
              this.showError("Could not determine Top Level Domain.", error);
          }
      );
    },
    onPackageChanged() {
      this.filledInSaleDefinition.selectableOptions.domainRegistrationPackage.map(
      (p) => {
        if (p.domainRegistrationPackageNumber == this.filledInSaleDefinition.requiredObjs.registration.domainRegistrationPackageNumber) {
          this.selectedOptions.renew = p.term;
        }
      });
    },
    requestTransfer() {
      //not supported yet.
    },
    duplicateAdmin() {
        this.filledInSaleDefinition.requiredObjs.registrantRole.domainContactNumber = this.filledInSaleDefinition.requiredObjs.adminRole.domainContactNumber;
        this.filledInSaleDefinition.requiredObjs.billingRole.domainContactNumber = this.filledInSaleDefinition.requiredObjs.adminRole.domainContactNumber;
        this.filledInSaleDefinition.requiredObjs.technicalRole.domainContactNumber = this.filledInSaleDefinition.requiredObjs.adminRole.domainContactNumber;
    },
    getLabel(contact) {
      return contact.firstNames + " " + contact.lastName + " [" + contact.clientNumber + "]";
    },

  },
};
</script>