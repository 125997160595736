<template>
  <div>
    <div>
      <div class="row row-spacing mt-4">
        <div class="form-group col-auto">
          <button class="btn btn-info" @click="settleModal('CASH_SETTLEMENT')">
            Cash Payment
          </button>
          <button class="btn btn-info" @click="settleModal('REFUND')">
            Refund
          </button>
          <div class="dropdown d-inline-block">
            <button
              class="btn btn-info dropdown-toggle"
              type="button"
              id="dropdownPayment"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Write Off
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="#" @click="settleModal('WRITEOFF')"
                >Write Off</a
              >
              <a
                class="dropdown-item"
                href="#"
                @click="settleModal('WRITEOFF_WITH_VAT')"
                >Write Off with VAT</a
              >
              <a
                class="dropdown-item"
                href="#"
                @click="settleModal('INTEREST_DISCOUNT')"
                >Interest Discount</a
              >
            </div>
          </div>
        </div>
        <div class="form-group col-auto">
          <button class="btn btn-primary" @click="settleAccount()">
            Settle Account
          </button>
        </div>
        <div class="form-group col-md">
          <button class="btn btn-primary pull-right" @click="downloadCsv()">
            Download Excel
          </button>
        </div>
      </div>
      <hr>
      <div class="form-row">
        <div class="form-group col-md-6">
          <div class="card table-responsive table-bordered">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <td colspan="4"><strong>Debit transactions</strong></td>
                  <td class="no-wrap">
                    <span :class="getDebitClass()"
                      ><strong class="badge bg-green">{{ currencyFormat(totalDebit) }}</strong></span
                    >
                  </td>
                  <td></td>
                </tr>
                <tr class="small">
                  <th>Date</th>
                  <th>Type</th>
                  <th>Reference</th>
                  <th>Description</th>
                  <th>Amount</th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="small" v-if="debitTransactions.length > 0">
                <tr
                  v-for="debitTransaction in debitTransactions"
                  :key="debitTransaction.transactionNumber"
                >
                  <td class="wd-sm">
                    {{ dateFormat(debitTransaction.created) }}
                  </td>
                  <td>{{ debitTransaction.type }}</td>
                  <td>{{ debitTransaction.extRefrence }}</td>
                  <td>{{ debitTransaction.description }}</td>
                  <td class="text-nowrap">
                    {{ currencyFormat(debitTransaction.transactionAmount) }}
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      @change="tallyDebitTotals()"
                      v-model="debitTransaction.selected"
                    />
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="6">No Debit transactions to display</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card table-responsive table-bordered">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <td colspan="4"><strong>Credit transactions</strong></td>
                  <td class="text-nowrap">
                    <span :class="getCreditClass()"
                      ><strong class="badge bg-green">{{ currencyFormat(totalCredit) }}</strong></span
                    >
                  </td>
                  <td></td>
                </tr>
                <tr class="small">
                  <th>Date</th>
                  <th>Type</th>
                  <th>Reference</th>
                  <th>Description</th>
                  <th>Amount</th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="small" v-if="creditTransactions.length > 0">
                <tr
                  v-for="creditTransaction in creditTransactions"
                  :key="creditTransaction.transactionNumber"
                >
                  <td>{{ dateFormat(creditTransaction.created) }}</td>
                  <td>{{ creditTransaction.type }}</td>
                  <td>{{ creditTransaction.extRefrence }}</td>
                  <td>{{ creditTransaction.description }}</td>
                  <td class="text-nowrap">
                    {{ currencyFormat(creditTransaction.transactionAmount) }}
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      @change="tallyCreditTotals()"
                      v-model="creditTransaction.selected"
                    />
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="6">No Credit transactions to display</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <modal :title="settleAction.type" ref="settleModal" @save="addAction()">
      <div class="form-group">
        <label>Description</label>
        <input
          type="text"
          v-model="settleAction.description"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label>Reference</label>
        <input
          type="text"
          v-model="settleAction.extRefrence"
          class="form-control"
        />
      </div>
      <div
        class="form-group"
        v-if="this.settleAction.type == 'WRITEOFF_WITH_VAT'"
      >
        <label>Vat Amount</label>
        <input
          type="text"
          v-model="settleAction.vatAmount"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label>Transaction Amount</label>
        <input
          type="text"
          v-model="settleAction.transactionAmount"
          class="form-control"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      types: [],
      debitTransactions: [],
      creditTransactions: [],
      totalDebit: 0,
      totalCredit: 0,
      settleAction: {
        type: "",
      },
    };
  },
  async mounted() {
    await this.$http.get(this.$config.aimsAPI + "transaction/types").then(
      (response) => {
        this.types = response.data;
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching Transaction Types", error);
      }
    );

    await this.reloadOpenTransactions();
  },
  methods: {
    reloadOpenTransactions() {
      const clientNumber = this.$route.params.clientNumber;

      this.$http
        .get(
          this.$config.aimsAPI +
            "transaction/" +
            clientNumber +
            "/opentransactions"
        )
        .then(
          (response) => {
            this.creditTransactions = response.data.creditTransactions;
            this.debitTransactions = response.data.debitTransactions;
            this.debitTransactions.forEach((dt) => {
              this.$set(dt, "selected", false);
            });
          },
          (error) => {
            this.showError("Error Fetching Client's Transactions", error);
            console.error(error);
          }
        );
    },
    settleModal(type) {
      this.settleAction = {};
      this.settleAction.type = type;
      this.$refs.settleModal.open();
    },
    settleAccount() {
      this.$swal({
        title: "Settle " + this.getSettleType(),
        text: this.getSettleAmount(),
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          const settledDebits = [];
          this.debitTransactions.forEach((dt) => {
            if (dt.selected) {
              settledDebits.push(dt);
            }
          });
          const settledCredits = [];
          this.creditTransactions.forEach((ct) => {
            if (ct.selected) {
              settledCredits.push(ct);
            }
          });
          const settlementPojo = {
            clientNumber: this.$route.params.clientNumber,
            debits: settledDebits,
            credits: settledCredits,
          };
          if (this.getSettleType() == "With Discount") {
            this.$http
              .post(
                this.$config.aimsAPI + "transaction/settle/discount",
                settlementPojo
              )
              .then(
                (response) => {
                  this.reloadOpenTransactions();
                  this.showSuccess("Success");
                },
                (error) => {
                  console.error(error);
                  this.showError("Error Settle Account", error);
                }
              );
          } else if (this.getSettleType() == "With Overpayment") {
            this.$http
              .post(
                this.$config.aimsAPI + "transaction/settle/payment",
                settlementPojo
              )
              .then(
                (response) => {
                  this.reloadOpenTransactions();
                  this.showSuccess("Success");
                },
                (error) => {
                  console.error(error);
                  this.showError("Error Settle Account", error);
                }
              );
          } else if (this.getSettleType() == "Matched Transactions") {
            this.$http
              .post(
                this.$config.aimsAPI + "transaction/settle/account",
                settlementPojo
              )
              .then(
                (response) => {
                  this.reloadOpenTransactions();
                  this.showSuccess("Success");
                },
                (error) => {
                  console.error(error);
                  this.showError("Error Settle Account", error);
                }
              );
          }
        }
      });
    },
    addAction() {
      this.$swal({
        title: "Are you sure you want to add ?",
        text: this.settleAction.type,
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$refs.settleModal.isLoading();
          const transaction = this.deepCopyObject(this.settleAction);
          transaction.class = "za.co.adept.aims.classes.billing.Transaction";
          transaction.clientNumber = this.$route.params.clientNumber;
          this.$http
            .post(this.$config.aimsAPI + "transaction/", transaction)
            .then(
              (response) => {
                this.reloadOpenTransactions();
                this.showSuccess("Add Success");
                this.$refs.settleModal.close();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Transaction", error);
              }
            )
            .finally(() => {
              this.$refs.settleModal.isLoading();
            });
        }
      });
    },
    getDebitClass() {
      if (this.totalDebit != this.totalCredit) {
        if (this.totalDebit > this.totalCredit) {
          return "yellowlight";
        } else {
          return "redlight";
        }
      } else {
        return "greenlight";
      }
    },
    getCreditClass() {
      if (this.totalDebit != this.totalCredit) {
        if (this.totalCredit > this.totalDebit) {
          return "yellowlight";
        } else {
          return "redlight";
        }
      } else {
        return "greenlight";
      }
    },
    getSettleType() {
      if (this.totalDebit != this.totalCredit) {
        if (this.totalCredit < this.totalDebit) {
          return "With Discount";
        } else {
          return "With Overpayment";
        }
      } else {
        return "Matched Transactions";
      }
    },
    getSettleAmount() {
      if (this.totalDebit != this.totalCredit) {
        if (this.totalCredit > this.totalDebit) {
          return this.currencyFormat(
            this.totalCredit - this.totalDebit
          );
        } else {
          return this.currencyFormat(
            this.totalDebit - this.totalCredit
          );
        }
      } else {
        return (
          "Total: " + this.currencyFormat(this.totalDebit)
        );
      }
    },
    tallyCreditTotals() {
      this.totalCredit = 0;
      this.creditTransactions.forEach((ct) => {
        if (ct.selected) {
          this.totalCredit += ct.transactionAmount;
        }
      });
    },
    tallyDebitTotals() {
      this.totalDebit = 0;
      this.debitTransactions.forEach((dt) => {
        if (dt.selected) {
          this.totalDebit += dt.transactionAmount;
        }
      });
    },
    downloadCsv() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "transaction/download/" +
            this.$route.params.clientNumber,
          {
            responseType: "arraybuffer",
          }
        )
        .then(
          function (response) {
            const contentType = response.headers.get("Content-Type");
            let blob = new Blob([response.data], {
              type: contentType,
            });
            const fileName = response.headers
              .get("Content-Disposition")
              .split("filename = ")[1];
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.dispatchEvent(
              new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
              })
            );
          },
          function (error) {
            console.error(error);
            this.showError("Error Downloading Transactions", error.data);
          }
        );
    },
  },
};
</script>

<style scoped>
.redlight {
  color: red;
}

.yellowlight {
  color: orange;
}

.greenlight {
  color: green;
}

td:hover {
  cursor: default;
}

.table>thead>tr>td {
  background-color: #3a3f51;
  color: #FFFFFF;
  padding: 1em;

  top: 1;
  z-index: 2;
  position: sticky;
}

.table>thead>tr>th {
  background-color: #3a3f51;
  color: #FFFFFF;
  padding: 1em;

  top: 4.2em;
  z-index: 2;
  position: sticky;
}

.pull-right {
  float: right;
}
</style>
