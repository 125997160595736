<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group row">
          <label class="col-md-2 col-form-label">Device Name: </label>
          <div class="col-md-4">
            <input
                  type="text"
                  class="form-control"
                  v-model="solarwindsBackup.deviceName"
              />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-6">
            <label class="col-form-label">Package Description: </label>
            <div class="">
              <input
                type="text"
                class="form-control"
                v-model="solarwindsBackup.backupPackage.description"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6">
            <label class="col-form-label">Package Licence Type: </label>
            <div class="">
              <input
                type="text"
                class="form-control"
                v-model="solarwindsBackup.backupPackage.licenceType"
                readonly
              />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-6">
            <label class="col-form-label">Package Storage: </label>
            <div class="">
              <div class="input-group">
                <input
                  type="text"
                  v-model="storage"
                  readonly
                  class="form-control"
                />
                <div class="input-group-append">
                  <span class="input-group-text">GB</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label class="col-form-label">Management Type: </label>
            <div class="">
              <input
                type="text"
                class="form-control"
                readonly
                v-model="solarwindsBackup.backupPackage.managementType"
              />
            </div>
          </div>
        </div>
        <div class="form-group" v-allowed:view="['STAFF']">
          <div class="">
            <button
              class="btn btn-success"
              @click="$emit('saveSale')"
              type="button"
            >
              Save
            </button>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    solarwindsBackup: {
      type: Object,
      required: true,
    },
  },
  computed: {
    storage() {
      return this.sizeBytesToGb(
        this.solarwindsBackup.backupPackage.includedStorage
      );
    },
  },
};
</script>