<template>
  <div class="card card-default">
    <div class="card-body">
      <div class="row">
        <div class="col-md-8">
          <div class="form-group">
            <button
              class="input-group-text btn btn-primary text-white"
              type="button"
              @click="openTemplateModal(newTemplate)"
            >
              <i class="fa fa-plus"></i>
                New Template
            </button>
          </div>
        </div>
        <div class=" col-md-12">
          <table class="table table-bordered" v-if="availableTemplates.length > 0">
            <thead>
              <tr>
                <th>Description</th>
                <th>Template</th>
                <th style="width: 10%;">Type</th>
                <th>Group</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="template in availableTemplates" :key="template.templateNumber">
                <td>{{template.description}}</td>
                <td>
                  <div class="red" v-if="!template.fileName || template.fileName == '' || template.fileName == 'To be uploaded'">
                    {{template.fileName}}
                  </div>
                  <a v-else href="#" @click="downloadTemplate(template.templateNumber); return false;">
                    {{template.fileName}}
                  </a>
                </td>
                <td class="text-center"><span class="badge" :class="template.contentType == 'PDF' ? 'bg-danger' : 'bg-info'">{{template.contentType}}</span></td>
                <td>{{template.group}}</td>
                <td class="text-center">
                  <button
                    class="btn btn-info btn-sm"
                    type="button"
                    v-tooltip="'Edit Template'"
                    @click="openTemplateModal(template)"
                  >
                    <em class="fa fa-edit fa-fw"></em>
                  </button>
                  <button
                    class="btn btn-green btn-sm"
                    type="button"
                    v-tooltip="'Upload Template'"
                    @click="openFileModal(template.templateNumber)"
                  >
                    <em class="fa fa-file-import fa-fw"></em>
                  </button>
                  <button
                    class="btn btn-primary btn-sm"
                    type="button"
                    v-tooltip="'Delete Template'"
                    @click="deleteTemplate(template)"
                  >
                    <em class="fa fa-trash fa-fw"></em>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <modal :title="templateDetails.templateNumber > 0 ? 'Edit Document Template' : 'New Document Template'" ref="templateModal" @save="editTemplate()">
      <div class="row">
        <div class="form-group col-md-10">
          <label class="control-label">
              Description
          </label>
          <input type="text" class="form-control" v-model="templateDetails.description">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-10">
          <label class="control-label">
              Type
          </label>
          <select class="form-control" v-model="templateDetails.type">
            <option>Configuration</option>
            <option>Onboarding</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-10">
          <label class="control-label">
            Group Name
          </label>
          <select class="form-control" v-model="templateDetails.group">
            <option
              :value="group"
              v-for="group in templateGroups"
              :key="group"
            >
              {{ group }}
            </option>
          </select>
        </div>
      </div>
    </modal>

    <modal title="Upload File" ref="uploadModal" @save="uploadTemplate()">
      <div class="row">
        <div class="form-group col-md-12">
          <label class="control-label">
            File
          </label>
          <div class="input-group">
            <input type="text" class="form-control" v-model="this.fileSelected.name" disabled/>
            <div class="input-group-append">
              <input type="file" ref="selectedFile" @change="fileChanged($event)" style="display: none;"/>
              <button 
                type="button" 
                class="btn btn-info" 
                @click="selectFile()"
              >
                Upload File
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/GeneralComponents/Modal';

export default {
  components: {
    Modal
  },
  computed: {
    templateGroups() {
      const groups = this.availableTemplates.map((t) => {
        return t.group;
      });
      return groups.filter((v, i, a) => a.indexOf(v) === i);
    },
  },
  data() {
    return {
      availableTemplates: [],
      templateDetails: {},
      newTemplate: {
        class: "za.co.adept.aims.docs.Template",
        templateNumber: 0,
      },
      fileSelected: {
        name: ''
      },
      templateNumberSelected: 0,
    };
  },
  mounted() {
    this.getAvailableTemplates();
    this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
  },
  methods: {
    selectFile() {
      this.$refs.selectedFile.click();
    },
    fileChanged: function (event) {
      this.fileSelected = event.target.files[0];
    },
    resetFile: function () {
      this.fileSelected = { name: '' };
      $("input[type=file]").val(null);
    },
    getAvailableTemplates() {
      this.$http.get(this.$config.aimsAPI + "documents/templates/").then(
        (response) => {
          this.availableTemplates = response.data;
          this.templateDetails = {};
          this.setTemplateDetails();
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Templates", error);
        }
      );
    },
    setTemplateDetails() {
      this.$http.get(this.$config.aimsAPI + "documents/emptyTemplate").then(
        (response) => {
          this.newTemplate = { 
            class: response.data,
            templateNumber: 0
          };
          this.templateNumberSelected = 0;
        },
        (error) => {
          this.showError("Could not fetch empty template.", error);
          console.error(error);
        }
      );
    },
    openTemplateModal(template) {
      this.resetFile();
      if (template.templateNumber > 0) {
        this.templateDetails = template;
      } else {
        this.templateDetails = this.newTemplate
      }
      this.$refs.templateModal.open();
    },
    openFileModal(templateNumber) {
      this.resetFile();
      this.templateNumberSelected = templateNumber;
      this.$refs.uploadModal.open();
    },
    editTemplate() {
      this.$refs.templateModal.isLoading();

      switch (this.templateDetails.type) {
        case "Configuration":
          this.templateDetails.contentType = "TEXT";
          break;
        case "Onboarding":
          this.templateDetails.contentType = "PDF";
          break;
        default:
          this.showWarning(
            "Could not set Content Type for template type" +
              this.templateDetails.type
          );
          return;
      }

      if (!this.templateDetails.fileName || this.templateDetails.fileName == "")
        this.templateDetails.fileName = "To be uploaded";

      this.$http
        .post(
          this.$config.aimsAPI + "documents/templates/",
          this.templateDetails
        )
        .then(
          (response) => {
            this.getAvailableTemplates();
            this.resetFile();
            this.$refs.templateModal.close();
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving Template", error);
          }
        );
    },
    deleteTemplate(template) {
      this.$swal({
        title: "Are you sure you want to delete this template?",
        text: template.description + " (" + template.type + ")",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.value) {
          this.$http.delete(this.$config.aimsAPI + "documents/templates/" + template.templateNumber + "/delete").then(
            (response) => {
              this.getAvailableTemplates();
              this.showSuccess("Template Deleted Successfully");
            },
            (error) => {
              this.showError("Could not fetch empty template.", error);
              console.error(error);
            }
          );
        }
      });
    },
    downloadTemplate(templateNumber) {
      this.$http
        .get(
          this.$config.aimsAPI +
            "documents/templates/" +
            templateNumber +
            "/download",
          {
            responseType: "arraybuffer",
          }
        )
        .then(
          function (response) {
            const contentType = response.headers.get("Content-Type");
            let blob = new Blob([response.data], {
              type: contentType,
            });
            const fileName = response.headers
              .get("Content-Disposition")
              .split("filename = ")[1];
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.dispatchEvent(
              new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
              })
            );
          },
          function (error) {
            console.error(error);
            this.showError("Error Downloading Template", error.data);
          }
        );
    },
    uploadTemplate() {
      this.$refs.uploadModal.isLoading();
      const formData = new FormData();

      formData.append("file", this.fileSelected);

      this.$http
        .post(
          this.$config.aimsAPI +
            "documents/templates/" +
            this.templateNumberSelected +
            "/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(
          function (response) {
            this.getAvailableTemplates();
            this.resetFile();
            this.$refs.uploadModal.close();
            this.showSuccess("File Uploaded Successfully");
          },
          function (error) {
            console.error(error);
            this.showError("Error Uploading Template", error);
          }
        );
    },
  },
};
</script>

<style scoped>
select option[disabled]:first-child { display: none; }

i {
  padding-right: 5px;
}

.iconOnly {
  padding-right: 0;
}

.pull-right {
  float: right;
}

.red {
  color: red;
}
</style>
