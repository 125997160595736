<template>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-8">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label>Domain</label>
                            <input type="text" class="form-control" disabled v-model="saleDefinition.sale.synaq.domainName">
                        </div>
                        <div class="col-md-3" v-allowed:view="['STAFF']">
                            <label>Margin</label>
                            <div class="input-group">
                                <input type="number" class="form-control" :disabled="!this.userContainsPermission(['PRODUCT_MANAGER', 'MANAGER', 'ADMIN'])" v-model="saleDefinition.sale.synaq.margin">
                                <div class="input-group-append">
                                    <div class="input-group-text">%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-allowed:view="['PRODUCT_MANAGER', 'MANAGER', 'ADMIN']">
                        <div class="col-md-12 mt-1">
                            <button class="btn btn-success" @click="$emit('saveSale')">Save</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4" 
                    v-if="saleDefinition.details && 
                    saleDefinition.details.portal && 
                    saleDefinition.details.portal.url.length > 0 && 
                    saleDefinition.details.portal.name.length > 0">
                    <PortalButton :portalDetails="saleDefinition.details.portal"/>
                </div>
            </div>
        </div>
</template>

<script>
import PortalButton from "@/components/ObjParts/SingleLoaded/PortalButton";

    export default {
        components : {
            PortalButton
        },
        props: {
            saleDefinition: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>