<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card card-default">
                <service-wizard-layout
                    title=""
                    subtitle=""
                    :parts="parts"
                    :onComplete="onComplete"
                    :validateStep="validateStep"
                >
                    <template slot="website">
                        <form novalidate data-vv-scope="website">
                            <div class="row">
                                    <div class="col-xl-8 col-lg-12">
                                        <div class="form-group">
                                            <label>Name</label>
                                            <input 
                                                type="text" 
                                                class="form-control"  
                                                v-model="filledInSaleDefinition.requiredObjs.website.websiteName"
                                            >
                                        </div>
                                    </div>
                                </div>
                            <div class="row">
                                <div class="col-xl-8 col-lg-12">
                                    <div class="form-group">
                                        <label>Package</label>
                                        <v-select
                                            name="webHostingPackageNumber"
                                            placeholder="Select Package"
                                            :value="packageLabel"
                                            :options="packageOptions"
                                            @input="changePackage"
                                        >
                                        </v-select>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </template>
                    <!-- <template slot="extras">
                        <form novalidate data-vv-scope="extras">
                        

                        </form>
                    </template> -->
                </service-wizard-layout>
            </div>
        </div>
    </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard';
import ServiceWizardLayout from '@/layouts/ServiceWizardLayout';
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
    components: {
        FormWizard, 
        TabContent,
        ServiceWizardLayout
    },
    props: {
        emptySaleDefinition: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            parts: [
                {
                    name: "website",
                    displayName: "Website",
                    icon: "fa-solid fa-globe",
                },
                // {
                //     name: "extras",
                //     displayName: "Additional",
                //     icon: "fa-solid fa-globe",
                // },
            ],
            filledInSaleDefinition: {
                requiredObjs: {
                    website: {
                    },
                }
            },
            packageOptions: [],
            packageLabel: '',
            // emptyWebsiteAdmin: {
            //     class: "za.co.adept.aims.webhosting.WebsiteAdmin",
            //     directory: "/",
            //     password: "",
            //     username: "",
            //     websiteNumber:0
            // }
        }
    },
    mounted() {
        this.filledInSaleDefinition = this.emptySaleDefinition;
        for (let i = 0; i < this.filledInSaleDefinition.selectableOptions.webHostingPackage.length; i++) {
            if (this.filledInSaleDefinition.selectableOptions.webHostingPackage.length > 0 && i == 0) {
                this.packageLabel = this.filledInSaleDefinition.selectableOptions.webHostingPackage[i].description;
                this.filledInSaleDefinition.requiredObjs.website.webHostingPackageNumber = this.filledInSaleDefinition.selectableOptions.webHostingPackage[i].webHostingPackageNumber;
            }
            this.packageOptions[i] = this.filledInSaleDefinition.selectableOptions.webHostingPackage[i].description
        }
        
    },
    methods: {
        onComplete() {
            this.filledInSaleDefinition.requiredObjs.website.websiteAdmins = [];
            // this.emptyWebsiteAdmin.username = "admin@"+this.filledInSaleDefinition.requiredObjs.website.websiteName;
            // this.emptyWebsiteAdmin.websiteNumber = this.filledInSaleDefinition.requiredObjs.website.websiteNumber;
            this.filledInSaleDefinition.requiredObjs.website.websiteAdmins.push(this.emptyWebsiteAdmin);
            this.$emit("createSale", this.filledInSaleDefinition);
        },
        changePackage(value) {
            for (let i = 0; i < this.filledInSaleDefinition.selectableOptions.webHostingPackage.length; i++) {
                if (value == this.filledInSaleDefinition.selectableOptions.webHostingPackage[i].description) {
                    this.filledInSaleDefinition.requiredObjs.website.webHostingPackageNumber = this.filledInSaleDefinition.selectableOptions.webHostingPackage[i].webHostingPackageNumber;
                }
                
            }
        },
        validateStep(scope) {
            return this.$validator.validateAll(scope).then((result) => {
                if (result) {
                    return true;
                } else {
                    return false;
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>

</style>