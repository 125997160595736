<template>
    <div class="row">
        <div class="col-xl-8 col-lg-12">
            <div class="card card-default">
                <form-wizard
                    title=""
                    subtitle=""
                    @on-complete="onComplete"
                    stepSize="sm"
                >
                    <tab-content
                        title="Package"
                        :before-change="() => validateStep('layer3service')"
                    >
                        <form novalidate data-vv-scope="layer3service">
                            <div class="row">
                                <div class="col-xl-8 col-lg-12">
                                    <div
                                        class="form-group"
                                        :class="{ 'has-error': errors.has('layer3service.ID') }"
                                    >
                                        <label class="control-label">Service ID *</label>
                                        <input
                                            class="form-control"
                                            v-model="filledInSaleDefinition.requiredObjs.layer3service.serviceId"
                                            name="ID"
                                            v-validate="'required'"
                                        />
                                        <span
                                            class="has-error"
                                            v-if="errors.has('layer3service.ID')"
                                            >{{ errors.first("layer3service.ID") }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-8 col-lg-12">
                                    <div
                                        class="form-group"
                                        :class="{ 'has-error': errors.has('layer3service.ipv4Range') }"
                                    >
                                        <label class="control-label">IPV4 Range</label>
                                        <input
                                            class="form-control"
                                            v-model="filledInSaleDefinition.requiredObjs.layer3service.ipv4Range"
                                            name="ipv4Range"
                                        />
                                        <span
                                            class="has-error"
                                            v-if="errors.has('layer3service.ipv4Range')"
                                            >{{ errors.first("layer3service.ipv4Range") }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-8 col-lg-12">
                                    <div
                                        class="form-group"
                                        :class="{ 'has-error': errors.has('layer3service.ipv6Range') }"
                                    >
                                        <label class="control-label">IPV6 Range</label>
                                        <input
                                            class="form-control"
                                            v-model="filledInSaleDefinition.requiredObjs.layer3service.ipv6Range"
                                            name="ipv6Range"
                                        />
                                        <span
                                            class="has-error"
                                            v-if="errors.has('layer3service.ipv6Range')"
                                            >{{ errors.first("layer3service.ipv6Range") }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-8 col-lg-12">
                                    <div
                                        class="form-group"
                                        v-if="filledInSaleDefinition.requiredObjs.layer3service"
                                        :class="{ 'has-error': errors.has('layer3service.Package') }"
                                    >
                                        <label class="control-label">Package *</label>
                                        <div class="input-group">
                                            <v-select
                                                name="Package"
                                                placeholder="Select Package"
                                                v-validate="'required'"
                                                label="description"
                                                valueProp="layer3PackageNumber"
                                                v-model="filledInSaleDefinition.requiredObjs.layer3service.layer3PackageNumber"
                                                :options="filledInSaleDefinition.selectableOptions.servicePackage"
                                            ></v-select>
                                        </div>
                                        <span
                                            class="has-error"
                                            v-if="errors.has('layer3service.Package')"
                                            >{{ errors.first("layer3service.Package") }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </tab-content>
                    <tab-content title="Address" icon="fa fa-truck" :before-change="()=>validateStep('delivery-info')">
                        <div class="row">
                            <Address class="col-xl-12" @addressChanged="addressChanged" :addressStructure="filledInSaleDefinition.requiredObjs.serviceAddress" :addressTypes="filledInSaleDefinition.supportingEnums.serviceAddress.addressType" />
                        </div>
                    </tab-content>
                </form-wizard>
            </div>
        </div>
    </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Address from '@/components/GeneralComponents/Address'

export default {
    components: {
        FormWizard,
        TabContent,
        Address,
    },
    props: {
        emptySaleDefinition: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            filledInSaleDefinition: {
                requiredObjs: {
                    layer3service: {
                        serviceId: '',
                        ipv4Range: '',
                        ipv6Range: '',
                    },
                    serviceAddress: {

                    }
                },
                selectableOptions: {
                    servicePackage: [],
                },
                supportingEnums: {
                    serviceAddress: {
                        addressType: []
                    },
                },
            },
        };
    },
    mounted: function () {
        this.filledInSaleDefinition = this.emptySaleDefinition;
    },
    methods: {
        validateStep: function (stepName) {
            return this.$validator.validateAll(stepName).then((result) => {
                if (result) {
                    return true;
                } else {
                    return false;
                }
            });
        },
        onComplete: function () {
            this.$emit("createSale", this.filledInSaleDefinition);
        },
        addressChanged(address) {
            this.filledInSaleDefinition.requiredObjs.serviceAddress = address;
        },
    },
};
</script>

<style>
</style>