
<template>
    <div>
        <div class="card card-default">
            <div class="card-body"> 
                <label class="control-label">Hostname</label>
                <div class="input-group">
                    <input type="text" v-model="dynDnsDetails.name" class="form-control" :disabled="typeof dynDnsDetails.dynDNSNumber !== 'undefined' && dynDnsDetails.dynDNSNumber != 0"/>
                    <div class="input-group-append">
                        <span class="input-group-text">@{{domain.name}}</span>
                    </div>
                </div>
            </div>
            <div class="card-footer" v-allowed:view="['STAFF']">
                <button v-if="!dynDnsDetails.dynDNSNumber || dynDnsDetails.dynDNSNumber == 0" type="button" class="btn btn-success" @click="dynDnsSaved()">Save</button>
                <button v-else type="button" class="btn btn-danger" @click="dynDnsRemoved(dynDnsDetails)">Remove</button>
            </div>
        </div>    
    </div>
</template>

<script>
export default {
  props: {
        dynDns: {
            type: Object,
            required: true
        },
        domain: {
            type: Object,
            required: false
        }
    },
    watch: {    
         dynDns: function(to, from){
             this.dynDnsDetails = to;
         }  
    },
    data(){ 
        return {
            dynDnsDetails: {
                name: '',
                dynDNSNumber: 0
            }
        }
    },
    methods: {
        dynDnsSaved(){
            if(!this.dynDns.dynDNSNumber || this.dynDns.dynDNSNumber == 0){
                //set up empty structure
                this.dynDnsDetails.class = 'adept.aims.classes.service.DynDNS';
                this.dynDnsDetails.userReadOnly = false;
                this.$emit('dynDnsCreated', this.dynDnsDetails); 
            }
        },
        dynDnsRemoved(){
            this.$emit('dynDnsRemoved', this.dynDnsDetails); 
        }
    },
    mounted: function(){
        
    }
};
</script>
