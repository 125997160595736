<template>
  <service-screen-layout :parts="parts">
    <template v-slot:otp>
      <div class="row row-spacing">
          
          <div class="col-md-5">
              <div class="form-group">
                  <label>Customer Id</label>
                  <input type="text" class="form-control" v-model="liquidCustomer.customerAccountId" disabled/>
              </div>
          </div>
          <div class="col-md-7">
            <div class="form-group">
                    <label>Customer Name</label>
                    <input type="text" class="form-control" v-model="liquidCustomer.customerName" disabled/>
            </div>
          </div>
        
          <div class="col-md-5">
              <Otp 
                  ref="otpComponent"
                  :secretKey="saleDefinition.sale.subscription.customer.secretKey"
                />
          </div>
          
          <div class="col-md-2">
            <div class="form-group">
                <label>Admin Username</label>
                <div class="input-group">
                  <input id="adminUser" type="text" class="form-control" v-model="liquidCustomer.adminUsername" disabled/>
                  <div class="input-group-append">
                      <span @click="copyText(liquidCustomer.adminUsername)"
                        class="input-group-text btn btn-info rounded text-white border-left-0">
                        <i class="fa fa-clipboard"></i>
                      </span>
                  </div>
                </div>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group">
                  <label>Admin Password</label>
                  <div class="input-group">
                    <input id="adminPass" type="password" class="form-control" v-model="liquidCustomer.adminPassword" disabled/>
                    <div class="input-group-append">
                        <span @click="copyText(liquidCustomer.adminPassword)"
                          class="input-group-text btn btn-info rounded text-white border-left-0">
                          <i class="fa fa-clipboard"></i>
                        </span>
                    </div>
                  </div>
            </div> 
          </div>

          <div 
              class="col-md-3" 
              v-if="saleDefinition.details && 
              saleDefinition.details.portal && 
              saleDefinition.details.portal.url.length > 0 && 
              saleDefinition.details.portal.name.length > 0"
            >
                <PortalButton :portalDetails="saleDefinition.details.portal"/>
          </div>
      </div>  
        
    </template>
    <template v-slot:subscription>
      <div class="row row-spacing">
        <div class="col-md-8">
          <LiquidSubscriptionInformation
            :liquidSubscriptionInformation="saleDefinition.sale.subscription"
          />
        </div>
      </div>
    </template>
    <template v-slot:detail>
      <SubscriptionDetail
        :liquidSubscriptionDetail="saleDefinition.sale.subscription.subscriptiondetail"
      />
    </template>
  </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import LiquidSubscriptionInformation from "@/components/ObjParts/SingleCaptured/LiquidSubscriptionInformation";
import SubscriptionDetail from "@/components/ObjParts/MultipleCaptured/SubscriptionDetail";
import PortalButton from "@/components/ObjParts/SingleLoaded/PortalButton";
import SaleFunctions from "@/pages/Services/SaleFunctions";
import Otp from "@/components/GeneralComponents/Otp";


export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    LiquidSubscriptionInformation,
    SubscriptionDetail,
    PortalButton,
    Otp
  },
  data() {
    return {
      saleNumber: null,
      props: ["active"],
      parts: [
        {
          name: "subscription",
          icon: "fa fa-info-circle",
          displayName: "Subscription Information",
          permissions: ["*"],
        },
        {
          name: "detail",
          icon: "fa fa-file-text-o",
          displayName: "Subscription Detail",
          permissions: ["*"],
        },
        {
          name: "otp",
          icon: "fa fa-file-text-o",
          displayName: "Microsoft Office Portal",
          permissions: ["ADMIN","MANAGER","TECHNICAL_STAFF"],
        },
      ],
      saleDefinition: {
        sale: {
          subscription: {
            subscriptiondetail: [],
            customer: {
              secretKey:""
            }
          }
        }
      },
      liquidCustomer: {}
    };
  },
  mounted() {
    this.getSaleDefinition();
  },
  methods: {
    getSaleDefinition: function() {
      this.saleNumber = this.$route.params.saleNumber;
      this.fetchSaleDefinition(this.saleNumber).then(
        (success) => {
          this.saleDefinition = success.data;
          this.liquidCustomer = this.saleDefinition.sale.subscription.customer;          
        },
        (error) => {
            console.error(error);
            this.showError("Error loading service information", error);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>