<template>
  <div>
      <ConnectivityPackage v-allowed:view="['STAFF']" :bundle="fttbConnectivityPackage" />
  </div>
</template>

<script>

import ConnectivityPackage from '@/components/ObjParts/SingleConstructed/ConnectivityPackage';

export default {
    components: {
        ConnectivityPackage
    },
    props: {
        fttbConnectivityPackage: {
            type: Object,
            required: true
        }
    }
}
</script>

<style>

</style>