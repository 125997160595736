import { render, staticRenderFns } from "./ClientHelpdeskTickets.vue?vue&type=template&id=63e66f41&scoped=true"
import script from "./ClientHelpdeskTickets.vue?vue&type=script&lang=js"
export * from "./ClientHelpdeskTickets.vue?vue&type=script&lang=js"
import style0 from "./ClientHelpdeskTickets.vue?vue&type=style&index=0&id=63e66f41&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63e66f41",
  null
  
)

export default component.exports