
<template>
  <router-view></router-view>
</template>

<script>
export default {
  watch: {
    $route(to, from) {
      if (to.name == "dashboard") this.directToDashboard();
    },
  },
  mounted: function () {
    this.directToDashboard();
    this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
    this.$root.$emit("SET_PAGE_TITLE_BADGES", []);
  },
  methods: {
    directToDashboard: function () {
      if (this.userContainsPermission(["STAFF"])) {
        this.$router.replace({ name: "staffdashboard" });
      } else if (this.userContainsPermission(["RESELLER"])) {
        this.$router.replace({ name: "resellerdashboard" });
      } else if (this.userContainsPermission(["CLIENT"])) {
        this.$router.replace({ name: "clientdashboard" });
      } else {
        this.showWarning(
          "Dashboard Route",
          "Could not find Dashboard for this user"
        );
      }
    },
  },
};
</script>
