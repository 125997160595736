<template>
  <div class="card card-default">
    <div class="card-header">
      <h3 class="card-title">
        {{client.firstnames}} {{client.lastname}} [{{client.clientNumber}}]
      </h3>
      <div class="row">
        <div class="col-md-2">
          <button type="button" @click="reverseSettlement()" class="btn btn-info mt-3">
            Reverse Settlement
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="form-row">
        <div class="form-group col-md-6">
          <div class="table-responsive table-bordered">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Reference</th>
                  <th>Description</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="debitTransaction in debitTransactions" :key="debitTransaction.transactionNumber">
                  <td class="text-nowrap">{{ dateFormat(debitTransaction.created) }}</td>
                  <td>{{debitTransaction.type}}</td>
                  <td>{{debitTransaction.extRefrence}}</td>
                  <td>{{debitTransaction.description}}</td>
                  <td class="text-nowrap">{{currencyFormat(debitTransaction.transactionAmount)}}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="4">Total amount</td>
                  <td class="text-nowrap">{{currencyFormat(totalAmount)}}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div class="form-group col-md-6">
          <div class="table-responsive table-bordered">
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Reference</th>
                  <th>Description</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="creditTransaction in creditTransactions" :key="creditTransaction.transactionNumber">
                  <td class="text-nowrap">{{dateFormat(creditTransaction.created)}}</td>
                  <td>{{creditTransaction.type}}</td>
                  <td>{{creditTransaction.extRefrence}}</td>
                  <td>{{creditTransaction.description}}</td>
                  <td class="text-nowrap">{{currencyFormat(creditTransaction.transactionAmount)}}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="4">Total amount</td>
                  <td class="text-nowrap">{{currencyFormat(totalAmount)}}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ClientFunctions from '@/pages/ClientFunctions'

  export default {
    extends: ClientFunctions,
    data() {
      return {
        client: {},
        creditTransactions: [],
        debitTransactions: [],
        totalAmount: 0
      }
    },
    async mounted() {
      const clientNumber = this.$route.params.clientNumber;
      const settlementNumber = this.$route.params.settlementNumber;

      await this.getClient(clientNumber).then(response => {
          this.client = response.data;
        },
        error => {
          this.showError('Error Fetching Client for Reversals', error);
          console.error(error);
        })

      await this.$http.get(this.$config.aimsAPI + 'clients/' + clientNumber + '/settlements/' + settlementNumber)
        .then(response => {
            this.creditTransactions = response.data.creditTransactions;
            this.debitTransactions = response.data.debitTransactions;

            this.creditTransactions.forEach(ct => {
              this.totalAmount += ct.transactionAmount;
            })
          },
          error => {
            this.showError('Error Fetching Settlement Batch', error);
            console.error(error);
          })
    },
    methods: {
      reverseSettlement() {
        const clientNumber = this.$route.params.clientNumber;
        const settlementNumber = this.$route.params.settlementNumber;
        this.$swal({
          title: 'Are you sure you want to reverse settlement batch: ' + this.$route.params.settlementNumber,
          type: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonText: 'Reverse Settlement'
        }).then(result => {
          if (result.value) {
            this.$http.put(this.$config.aimsAPI + 'clients/' + clientNumber + '/settlements/' + settlementNumber +
                '/reverse')
              .then(response => {
                  this.showSuccess('Settlement reversed successfully');
                  this.$router.go(-1);
                },
                error => {
                  this.showError('Error Reversing Settlement Batch', error);
                  console.error(error);
                })
          }
        })
      }
    }
  }

</script>

<style scoped>
  .wd-sm {
    width: 113px;
  }

</style>
