<template>
    <div class="col-md-12">
        <div class="row">
            <h4>Invoice Entries</h4>
        </div>
        <div class="row justify-content-between">
            <div class="col-md-4 pl-0">
                <v-select
                    :options="billingMonths"
                    v-model="selectedBillingMonth"
                    :get-option-label="getLabel"
                    @input="$emit('filterEntries', selectedBillingMonth)"
                >
                </v-select>
            </div>
            <div class="col-md-4 pr-0">
                <div class="input-group">  
                    <input type="search" class="form-control" placeholder="Search by Product Name..." v-model="searchTerm">
                    <div class="input-group-append">
                        <span class="input-group-text" id="product-search"><i class="fa fa-search"></i></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-spacing">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Product Name</th>
                        <th>Billing Month</th>
                        <th>Charges Excl VAT USD</th>
                        <th>Credits Excl VAT USD</th>
                        <th>Nett Charges Excl VAT USD</th>
                        <th>Price Excl VAT</th>
                        <th class="text-center" v-if="selectedBillingMonth">
                            <TableToFile 
                                v-tooltip="'Download Excel'"
                                :small="true"
                                :fileName="`AWS Invoice Entries - ${selectedBillingMonth}`"
                                :fieldProp="{
                                        'Product Name' : 'productName', 
                                        'Billing Month' : 'billingMonth', 
                                        'Charges Excl VAT USD' : 'chargesExclVatUsd', 
                                        'Credits Excl VAT USD' : 'creditsExclVatUsd',
                                        'Nett Charges Excl VAT USD' : 'nettChargesExclVatUsd',
                                        'Price Excl VAT' : 'priceExclVat'
                                }"
                                :dataProp="filteredEntries"
                                style="position: inherit !important;"
                            />
                        </th>
                    </tr>
                </thead>
                <tbody v-if="filteredEntries.length > 0">
                    <tr v-for="entry in filteredEntries" :key="entry.awsInvoiceEntryNumber">
                        <td>{{ entry.productName }}</td>
                        <td>{{ entry.billingMonth }}</td>
                        <td>{{ currencyFormatUSD(entry.chargesExclVatUsd) }}</td>
                        <td>{{ currencyFormatUSD(entry.creditsExclVatUsd) }}</td>
                        <td>{{ currencyFormatUSD(entry.nettChargesExclVatUsd) }}</td>
                        <td>{{ currencyFormat(entry.priceExclVat) }}</td>
                        <td v-if="selectedBillingMonth"></td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="6">No invoice entries</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import TableToFile from "@/components/GeneralComponents/TableToFile";

    export default {
        components: {
            TableToFile
        },
        props: {
            awsInvoiceEntries: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                billingMonths: [],
                selectedBillingMonth: 0,
                searchTerm: '',
                entries: []
            }
        },
        mounted() {
            this.getAwsBillingMonths();
        },
        computed: {
            filteredEntries() {
                this.entries = this.awsInvoiceEntries;

                if (this.searchTerm) {
                    this.entries = this.entries.filter(e => e.productName.toLowerCase().includes(this.searchTerm.toLowerCase()));
                }

                return this.entries;
            }
        },
        methods: {
            getAwsBillingMonths: function() {
                const hostUrl = this.$config.aimsAPI;

                this.$http.get(`${hostUrl}aws/billingmonths`).then(
                    (response) => {
                        this.billingMonths = response.data;                      
                    },
                    (error) => {
                        console.error(error);
                        this.showError('Error fetching AWS billing months', error);
                    }
                );
            },
            getLabel: function(period) {
                return this.prettifyBillingPeriod(period);
            },
        }
    }
</script>
