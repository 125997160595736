<template>
  <div class="row">
    <div class="col-md-9">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template slot="internet">
            <form novalidate data-vv-scope="internet">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('internet.description'),
                    }"
                  >
                    <label>Description:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="description"
                      placeholder="Description"
                      v-model="filledInSaleDefinition.requiredObjs.internet.description"
                    />
                    <span class="has-error" v-if="errors.has('internet.description')">{{
                      errors.first("internet.description")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                    'has-error': errors.has('internet.bandwidth'),
                    }"
                  >
                    <label>Bandwidth (Mbps):</label>
                    <v-select
                      name="bandwidth"
                      label="label"
                      v-model="bandwidthSelected"
                      :options="bandwidthOptions"
                    >
                    </v-select>
                    <span class="has-error" v-if="errors.has('internet.bandwidth')">{{
                    errors.first("internet.bandwidth")
                    }}</span>
                  </div>
                </div>
              </div>
            </form>
          </template>
          <template slot="network">
            <form novalidate data-vv-scope="network">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('network.id'),
                    }"
                  >
                    <label>VLAN ID:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="id"
                      placeholder="ID"
                      v-model="filledInSaleDefinition.requiredObjs.network.vlanId"
                    />
                    <span class="has-error" v-if="errors.has('network.id')">{{
                      errors.first("network.id")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('network.name'),
                    }"
                  >
                    <label>VLAN Name:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="name"
                      placeholder="Name"
                      v-model="filledInSaleDefinition.requiredObjs.network.vlanName"
                    />
                    <span class="has-error" v-if="errors.has('network.name')">{{
                      errors.first("network.name")
                    }}</span>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
import DateInput from "@/components/GeneralComponents/InputControls/DateInput";

export default {
  components: {
    FormWizard,
    TabContent,
    ServiceWizardLayout,
    DateInput,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "internet",
          displayName: "Internet Details",
          icon: "fa fa-cubes",
        },
        {
          name: "network",
          displayName: "Network Details",
          icon: "fa fa-info",
        },
      ],
      filledInSaleDefinition: {
        requiredObjs: {
          internet: {},
          network: {},
        },
        selectableOptions: {

        }
      },
      bandwidthSelected: {},
      bandwidthOptions: [],
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;

    for (let i = 0; i < this.filledInSaleDefinition.selectableOptions.internetPackage.length; i++) {
      this.bandwidthOptions.push({
        label: this.filledInSaleDefinition.selectableOptions.internetPackage[i].description,
        value: this.filledInSaleDefinition.selectableOptions.internetPackage[i].dataCentreInternetPackageNumber
      });
    }

    if (this.bandwidthOptions.length > 0) {
      this.bandwidthSelected = this.bandwidthOptions[0];
    }
  },
  methods: {
    onComplete() {
      this.$emit("createSale", this.filledInSaleDefinition);
    },
    validateStep(scope) {
      this.filledInSaleDefinition.requiredObjs.internet.dataCentreInternetPackageNumber = this.bandwidthSelected.value;

      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
</style>