<template>
  <modal
    :title="entryTypeDescription"
    ref="addEntryModal"
    @save="save"
    :size="'modal-lg'"
  >
    <div class="row">
      <div
        class="form-group col-md-6"
        :class="{ 'has-error': errors.has('Entry Description') }"
      >
        <label>Description</label>
        <input
          type="text"
          name="Entry Description"
          v-validate="'required'"
          class="form-control"
          placeholder="Enter description"
          v-model="entry.description"
        />
        <span class="has-error" v-if="errors.has('Entry Description')">
          {{ errors.first("Entry Description") }}
        </span>
      </div>
      <div
        class="form-group col-md-6"
        :class="{ 'has-error': errors.has('Ticket Reference') }"
      >
        <label>Ticket Reference</label>
        <input
          type="text"
          class="form-control"
          name="Ticket Reference"
          v-model="entry.ticketReference"
        />
      </div>
    </div>
    <div class="row" v-if="workorder">
      <div class="form-group col-md-6">
        <label>Staff User</label>
        <v-select
          placeholder="Select Staff User"
          :options="staffUserOptions"
          v-model="entry.username"
          label="username"
          valueProp="email"
        ></v-select>
      </div>
      <div class="form-group col-md-6">
        <div>
          <label>Effective Date</label>
        </div>
        <DateInput v-model="entry.effectiveDate" class="w-100" />
      </div>
    </div>
    <div
      class="row"
      v-if="
        entryType.includes('Hardware') ||
        entryType.includes('Software') ||
        entryType.includes('Service')
      "
    >
      <div
        class="form-group col-md-2"
        :class="{ 'has-error': errors.has('Units') }"
      >
        <label>Units</label>
        <input
          type="text"
          class="form-control"
          v-validate="'required|min_value:1|numeric'"
          name="Units"
          placeholder="Enter units"
          v-model="entry.units"
        />
        <span class="has-error" v-if="errors.has('Units')">
          {{ errors.first("Units") }}
        </span>
      </div>
      <div v-if="isVatExcl" class="col-md-10">
        <div class="row">
          <div class="form-group col-md-6">
            <label>Cost per unit(ex VAT)</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">R</span>
              </div>
              <input
                type="text"
                class="form-control"
                ref="exVatCostPerUnit"
                name="Cost per unit"
                placeholder="Enter cost per unit"
                v-model="entry.exVatCostPerUnit"
              />
            </div>
          </div>
          <div
            class="form-group col-md-6"
            :class="{ 'has-error': errors.has('Cost per unit') }"
          >
            <label>Cost per unit(incl VAT)</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">R</span>
              </div>
              <input
                type="text"
                class="form-control"
                v-validate="'required|min_value:0.0'"
                name="Cost per unit"
                placeholder="Enter cost per unit"
                @focus="
                  entry.costPerUnit = cost;
                  isVatExcl = false;
                "
                v-model="cost"
              />
            </div>
            <span class="has-error" v-if="errors.has('Cost per unit')">{{
              errors.first("Cost per unit")
            }}</span>
          </div>
        </div>
      </div>
      <div v-else class="col-md-10">
        <div class="row">
          <div class="form-group col-md-6">
            <label>Cost per unit(ex VAT)</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">R</span>
              </div>
              <input
                type="text"
                class="form-control"
                name="Cost per unit"
                placeholder="Enter cost per unit"
                @focus="
                  entry.exVatCostPerUnit = exVatCost;
                  isVatExcl = true;
                "
                v-model="exVatCost"
              />
            </div>
          </div>
          <div
            class="form-group col-md-6"
            :class="{ 'has-error': errors.has('Cost per unit') }"
          >
            <label>Cost per unit(incl VAT)</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">R</span>
              </div>
              <input
                type="text"
                class="form-control"
                v-validate="'required|min_value:0.0'"
                name="Cost per unit"
                placeholder="Enter cost per unit"
                v-model="entry.costPerUnit"
              />
            </div>
            <span class="has-error" v-if="errors.has('Cost per unit')">{{
              errors.first("Cost per unit")
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="entryType.includes('Labour') || entryType.includes('Bulk')">
      <div class="row">
        <div class="form-group col-md-6">
          <label>Labour Type</label>
          <v-select
            placeholder="Select Labour Type"
            label="description"
            valueProp="name"
            v-model="entry.labourType"
            :options="labourEntryEnums.labourType"
          >
          </v-select>
        </div>
        <div class="form-group col-md-6">
          <label>Labour Type</label>
          <v-select
            placeholder="Select Labour Rate"
            label="description"
            valueProp="name"
            v-model="entry.labourRate"
            :options="labourEntryEnums.labourRate"
          >
          </v-select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-4" v-if="entryType.includes('Bulk')">
          <label>Rate Amount</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">R</span>
            </div>
            <input
              type="text"
              class="form-control"
              placeholder="Enter rate amount"
              v-model="entry.rateAmount"
            />
          </div>
        </div>
        <div
          class="form-group col-md-4"
          :class="{ 'col-md-6': !entryType.includes('Bulk') }"
        >
          <label>Time Rate</label>
          <v-select
            placeholder="Select Time Rate"
            label="description"
            valueProp="name"
            v-model="entry.rateTime"
            :options="labourEntryEnums.rateTime"
          >
          </v-select>
        </div>
        <div
          class="form-group col-md-4"
          :class="{ 'col-md-6': !entryType.includes('Bulk') }"
        >
          <label>Time</label>
          <v-select
            placeholder="Select Time"
            label="label"
            valueProp="value"
            v-model="entry.time"
            :options="labourEntryEnums.timeList"
          >
          </v-select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">
          <label>Billable</label>&nbsp;&nbsp;
          <toggle-button
            :width="70"
            class="text m-0"
            :color="{ checked: '#7DCE94', unchecked: '#82C7EB' }"
            :labels="{ checked: 'Yes', unchecked: 'No' }"
            v-model="entry.billable"
            :sync="true"
          />
        </div>
        <div class="form-group col-md-10">
          <label>Sale</label>
          <multiselect
            v-model="selectedSale"
            @input="$emit('setLinkedSale', selectedSale)"
            :custom-label="customLabel"
            placeholder="Select a Sale"
            :options="clientSales"
            :searchable="true"
            :multiple="false"
          >
          </multiselect>
        </div>
      </div>
    </div>
    <div class="row" v-if="entryType.includes('Travel')">
      <div class="col-md-2">
        <label>Billable</label>&nbsp;&nbsp;
        <toggle-button
          :width="70"
          class="text m-0"
          :color="{ checked: '#7DCE94', unchecked: '#82C7EB' }"
          :labels="{ checked: 'Yes', unchecked: 'No' }"
          v-model="entry.billable"
          :sync="true"
        />
      </div>
      <div
        class="form-group col-md-10"
        :class="{ 'has-error': errors.has('Travel') }"
      >
        <label>Kilometers</label>
        <input
          type="text"
          class="form-control"
          v-validate="'required|min_value:0.1'"
          name="Travel"
          placeholder="Enter kilometers"
          v-model="entry.kilometers"
        />
        <span class="has-error" v-if="errors.has('Travel')">{{
          errors.first("Travel")
        }}</span>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
import DateInput from "@/components/GeneralComponents/InputControls/DateInput.vue";

export default {
  components: {
    Modal,
    DateInput,
  },
  props: {
    entryTypeDescription: {
      type: String,
      required: true,
    },
    entryType: {
      type: String,
      required: true,
    },
    entry: {
      type: Object,
      required: true,
    },
    cost: {
      type: Number,
      required: true,
    },
    exVatCost: {
      type: Number,
      required: true,
    },
    bulkCost: {
      type: Number,
      required: true,
    },
    bulkCostExVat: {
      type: Number,
      required: true,
    },
    selectedSale: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    clientSales: {
      type: Array,
      required: true,
    },
    workorder: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      staffUserOptions: [],
      isVatExcl: true
    };
  },
  created() {
    const hostUrl = this.$config.aimsAPI;
    this.$http.get(`${hostUrl}workorders/labourentry/enums`).then(
      (response) => {
        this.labourEntryEnums = response.data;

        for (let i = 0; i < this.labourEntryEnums.staff.length; i++) {
          this.staffUserOptions.push({
            username:
              this.labourEntryEnums.staff[i].firstNames +
              " " +
              this.labourEntryEnums.staff[i].lastName +
              " (" +
              this.labourEntryEnums.staff[i].labourRate +
              ")",
            email: this.labourEntryEnums.staff[i].emailAddress,
          });
        }
      },
      (error) => {
        this.showError("Error Fetching Labour Entry Enums", error);
        console.error(error);
      }
    );
  },
  methods: {
    open() {
      this.$refs.addEntryModal.open();
    },
    save() {
      this.$emit('saveEntry', {"entry": this.entry, "isVatExcl": this.isVatExcl});
    },
    customLabel({ saleNumber, toString }) {
      return "Sale# " + saleNumber + " - " + toString;
    },
  },
};
</script>
