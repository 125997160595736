<template>
  <div class="col-md-12">
    <div class="row row-spacing justify-content-between">
        <h3>IP Network Addresses</h3>
      <div class="mb-2" v-allowed:view="['STAFF']">
        <button type="button" class="btn btn-info pull-right" @click="openIpAddressModal()">
          Add IP Network Address
        </button>
      </div>
    </div>
    <div class="row row-spacing">
      <div
        class="table-responsive table-bordered"
      >
        <table class="table">
          <thead>
            <tr>
              <th>Network Address</th>
              <th>Short Mask</th>
              <th v-allowed:view="['STAFF']"></th>
            </tr>
          </thead>
          <tbody
             v-if="networkAddresses.length > 0"
          >
            <tr v-for="networkAddress in networkAddresses"
            :key="networkAddress.hostedNetworkAddressNumber">
              <td>{{ networkAddress.address }}</td>
              <td>{{ networkAddress.shortMask }}</td>
              <td v-allowed:view="['STAFF']">
                <div class="text-center">
                  <button 
                    class="btn btn-primary"
                    @click="deleteIpNetworkAddress(networkAddress)"
                    v-tooltip="'Delete IP Network Address'"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="3">No IP Network Address has been assigned</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <AddIpNetworkAddresses 
        ref="addIpAddressModal" 
        :userEnterAddress="isInternalNetworks"
        @addIpNetworkAddress="addIpNetworkAddress"
    />
  </div>
</template>

<script>
import AddIpNetworkAddresses from "@/components/Admin/Modals/Legacy/AddIpNetworkAddresses";

export default {
  props: {
    networkAddresses: {
      type: Array,
      required: true,
    },
    saleNumber: {
      type: String,
      required: true,
    },
    isInternalNetworks: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    AddIpNetworkAddresses,
  },
  methods: {
    openIpAddressModal: function () {
      this.$refs.addIpAddressModal.open();
    },
    addIpNetworkAddress: function(data) {
      this.networkAddresses.push(data);
      this.$emit('saveSale');
    },
    deleteIpNetworkAddress: function(networkAddess) {
      this.$emit('deleteNetworkAddress', networkAddess);
    }
  },
};
</script>