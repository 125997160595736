<template>
  <div class="card-body">
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item">
        <a
          href="#detail"
          class="nav-link active"
          role="tab"
          data-toggle="tab"
          id="detail-tab"
          aria-controls="detail"
          aria-selected="true"
        >
          <i class="fa fa-info-circle"></i>&nbsp;&nbsp;N-Central Customer
        </a>
      </li>
      <li class="nav-item">
        <a
          href="#devices"
          class="nav-link"
          role="tab"
          data-toggle="tab"
          id="devices-tab"
          aria-controls="devices"
          aria-selected="true"
        >
          <i class="fa fa-tablet"></i>&nbsp;&nbsp;Devices
        </a>
      </li>
    </ul>
    <form class="tab-content">
      <div
        class="tab-pane fade show active"
        id="detail"
        role="tabpanel"
        aria-labelledby="detail-tab"
      >
        <div class="form-group row mt-3">
          <label class="col-md-3 col-form-label">Customer Description:</label>
          <div class="col-md-4">
            <input
              type="text"
              class="form-control"
              readonly
              disabled
              v-model="
                saleDefinition.definition.nCentralCustomer.customerDescription
              "
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 col-form-label">Customer Access Code:</label>
          <div class="col-md-4">
            <input
              type="text"
              class="form-control"
              readonly
              disabled
              v-model="
                saleDefinition.definition.nCentralCustomer.customerAccessCode
              "
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 col-form-label">Billing from:</label>
          <div class="col-md-4">
            <v-select
              :options="periods"
              placeholder="Select Billing From"
              v-model="saleDefinition.definition.nCentralCustomer.billFrom"
              :get-option-label="getLabel"
            ></v-select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 col-form-label">Billing type:</label>
          <div class="col-md-4">
            <v-select
              :options="billingTypes"
              placeholder="Select Billing Type"
              v-model="saleDefinition.definition.nCentralCustomer.billingType"
            ></v-select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <button class="btn btn-success" @click="saveSale()" type="button">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane"
        id="devices"
        role="tabpanel"
        aria-labelledby="devices-tab"
      >
        <div class="input-group pull-left mt-3 mb-3">
          <button type="button" @click="editDevice()" class="btn btn-success text-white border-0" :disabled="selectedRows.length == 0 || isSaving">Edit Selected Devices</button>
        </div>
        <div class="table-responsive table-bordered device-table">
          <table class="table text-nowrap">
            <thead>
              <tr class="dark-row">
                <th style="text-align:center">
                  <input type="checkbox" :checked="saleDefinition.definition.nCentralCustomer.manageddevices.length == selectedRows.length" @change="addRemoveToGlobal">
                </th>
                <th>Device Name</th>
                <th>Site</th>
                <th>Created</th>
                <th>Device Class</th>
                <th>Supporting Service</th>
                <th></th>
              </tr>
            </thead>
            <tbody
              v-if="
                saleDefinition.definition.nCentralCustomer.manageddevices
                  .length > 0
              "
            >
              <tr
                v-for="(device, index) in saleDefinition.definition
                  .nCentralCustomer.manageddevices"
                :key="index"
                :class="{ 'selectedRow' : isRowSelected(device.objKey) }"
              >
                <td style="width: 2%; text-align:center"><input type="checkbox" :id="device.objKey" :checked="isRowSelected(device.objKey)" @change="addRemoveToSelected"></td>
                <td>
                  <a
                    :href="$config.urlToNCentral + device.nCentralDeviceNumber"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ device.nCentralDeviceName }}
                  </a>
                </td>
                <td>{{ device.nCentralCustomerName }}</td>
                <td>{{ dateFormat(device.created) }}</td>
                <td>{{ device.deviceClass }}</td>
                <td>{{ device.supportPackage.description }}</td>
                <td style="text-align:center"><button type="button" @click="editDevice(device)" class="btn btn-success text-white border-0" :disabled="isSaving || selectedRows.length > 0"><i class="fa fa-edit"></i></button></td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="6">No Managed Devices</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
    <modal :title="selectedRows.length == 0 ? 'Edit Device' : 'Edit Multiple Devices'" ref="editDeviceModal" @save="saveSale()">
      <form class="form-row">
        <div class="form-group col-md-12" v-if="selectedRows.length == 0">
          <label>Package</label>
          <v-select
            name="Package"
            label="description"
            valueProp="supportPackageNumber"
            :options="packageOptions"
            v-model="selectedDevice.supportPackageNumber"
          >
          </v-select>
        </div>
        <div class="form-group col-md-12" v-else>
          <label>Package</label>
          <v-select
            name="Package"
            placeholder="Select a Package"
            label="description"
            valueProp="supportPackageNumber"
            :options="packageOptions"
            v-model="selectedPackage"
          >
          </v-select>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import SaleFunctions from "@/pages/Services/SaleFunctions";
import Modal from '@/components/GeneralComponents/Modal'

export default {
  extends: SaleFunctions,
  components: {
    Modal
  },
  watch: {
    saleDefinition: {
      deep: true,
      handler(newSaleDef, oldSaleDef) {
        if (oldSaleDef.sale.description != newSaleDef.sale.description) {
          this.$parent.$refs.serviceTitle.reloadSale();
        }
      },
    },
  },
  data() {
    return {
      saleDefinition: {
        definition: {
          nCentralCustomer: {
            manageddevices: [],
          },
        },
        sale: {},
      },
      periods: [],
      billingTypes: [],
      isSaving: false,
      
      selectedDevice: [],
      selectedPackage: null,
      selectedRows: [],
      selectedGlobal: [],
      packageOptions: [],
    };
  },
  mounted() {
    this.loadSale();
    this.loadBilling();
  },
  methods: {
    loadSale() {
      const saleNumber = this.$route.params.saleNumber;
      $("#saleLoadingSpinner").show();
      this.toggleSaving();
      this.getSaleDefinition(saleNumber)
        .then(
          (response) => {
            this.selectedRows = [];
            this.saleDefinition = response.data;
            
            this.packageOptions = this.saleDefinition.selectableOptions.supportPackage;
          },
          (error) => {
            this.showError("Error fetching Sale Definition", error);
            console.error(error);
          }
        )
        .finally(() => {
          $("#saleLoadingSpinner").hide();
          this.toggleSaving();
        });
    },
    loadBilling() {
      this.$http
        .get(this.$config.aimsAPI + "system/billingperiods?max=" + 3)
        .then(
          (response) => {
            this.periods = response.data;
          },
          (error) => {
            this.showError("Error fetching billing periods", error);
            console.error(error);
          }
        );

      this.$http.get(this.$config.aimsAPI + "system/billingtypes").then(
        (response) => {
          this.billingTypes = response.data;
        },
        (error) => {
          this.showError("Error fetching Billing Types", error);
          console.error(error);
        }
      );
    },
    toggleSaving() {
      this.isSaving = !this.isSaving;
    },
    getLabel(period) {
      return this.prettifyBillingPeriod(period);
    },
    saveSale() {
      this.toggleSaving();
      this.$refs.editDeviceModal.isLoading();

      if (this.selectedRows.length > 0) {
        for (let i = 0; i < this.selectedRows.length; i++) {
          for (let j = 0; j < this.saleDefinition.definition.nCentralCustomer.manageddevices.length; j++) {
            if(this.selectedRows[i] == this.saleDefinition.definition.nCentralCustomer.manageddevices[j].objKey) {
              this.saleDefinition.definition.nCentralCustomer.manageddevices[j].supportPackageNumber = this.selectedPackage;
            }
          }
        }
      }

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http.put(this.$config.aimsAPI + "sales/definition/" + this.saleDefinition.sale.saleNumber + "/update", this.saleDefinition)
            .then((response) => {
                this.$refs.editDeviceModal.close();
                this.toggleSaving();
                this.showSuccess("Save Success");
                this.loadSale();
              },
              (error) => {
                this.toggleSaving();
                console.error(error);
                this.showError("Error Saving NCentral Sale", error);
                this.loadSale();
              }
            ).finally(() => {
              this.$refs.editDeviceModal.isLoading();
              this.selectedPackage = null;
            });
        }
      });
    },
    editDevice(device) {
      if (this.selectedRows.length == 0) {
        this.selectedDevice = device;
      }
      
      this.$refs.editDeviceModal.open();
    },
    isRowSelected(data){
      var isSelected = false;

      for (let i = 0; i < this.selectedRows.length; i++) {
        if (this.selectedRows[i] == data) {
          isSelected = true;
        }
      }

      return isSelected;
    },
    addRemoveToSelected(e) {
      if (e.target.checked) {
        this.selectedRows.push(e.target.id);
      } else {
        this.selectedRows = this.selectedRows.filter((i) => i != e.target.id);
      }
    },
    addRemoveToGlobal(e) {
      if (e.target.checked) {
        this.selectedRows = [];
        for (let i = 0; i < this.saleDefinition.definition.nCentralCustomer.manageddevices.length; i++) {
          this.selectedRows.push(this.saleDefinition.definition.nCentralCustomer.manageddevices[i].objKey);
        }
      } else {
        this.selectedRows = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-row {
  th {
    background-color: #3a3f51;
    color: #FFFFFF;

    position: sticky;
    top: 0;
    z-index: 2;
  }
}
.input-group > .v-select > .v-select-toggle {
  padding: 0.249rem 0.75rem !important;
}

.selectedRow {
  background-color: rgb(225, 225, 225);
}
</style>
