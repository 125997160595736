<template>
  <modal :title="modalTitle" ref="fttbAddon" :size="'modal-xl'" @save="saveFttbAddon(fttbAddon)">
    <div class="row row-spacing justify-content-center">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <h4>Addon Info</h4>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Add-on Name</label>
                  <input type="text" class="form-control" v-model="fttbAddon.name" />
              </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                    <label>VLAN Name</label>
                    <input type="text" class="form-control" v-model="fttbAddon.vlanName" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                    <label>VLAN ID</label>
                    <input type="text" class="form-control" v-model="fttbAddon.vlanId" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-6">
            <h4>Routed Networks</h4>
            <div class="table-responsive table-bordered" v-if="fttbAddon.routedIpv4Network.fttbAddonNumber || fttbAddon.routedIpv6Network.fttbAddonNumber">
                  <table class="table mb-0 text-nowrap">
                    <thead>
                      <tr>
                        <th>Address</th>
                        <th>Short Mask</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ fttbAddon.routedIpv4Network.address }}</td>
                        <td>{{ fttbAddon.routedIpv4Network.shortMask }}</td>
                      </tr>
                      <tr>
                        <td>{{ fttbAddon.routedIpv6Network.address }}</td>
                        <td>{{ fttbAddon.routedIpv6Network.shortMask }}</td>
                      </tr>
                    </tbody>
                  </table>
              </div>
              <div v-else>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="networks">Routed IPv4 Network</label>
                    <v-select
                      name="network"
                      v-model="routedIpv4Network.shortMask"
                      :options="ipv4NetworkOptions"
                      clearable
                      ref="ipv4Network"
                    >
                    </v-select>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Routed IPv6 Network</label>
                    <v-select
                      name="network"
                      v-model="routedIpv6Network.shortMask"
                      :options="ipv6NetworkOptions"
                      clearable
                      ref="ipv6Network"
                    >
                    </v-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col md-12">
                    <button class="btn btn-success" 
                      @click="addRoutedNetworks(routedIpv4Network, routedIpv6Network)" 
                      :disabled="routedIpv4Network.shortMask  === 0 || routedIpv6Network.shortMask === 0"
                    >
                      Add Routed Networks
                    </button>
                  </div>
                </div>
              </div>
          </div>
          <div class="col-md-6">
            <h4>Package</h4>
            <div v-if="fttbAddon.addonConnectivityPackage">
              <FttbConnectivityPackage
                :fttbConnectivityPackage="fttbAddon.addonConnectivityPackage"
            />
            </div>
        </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import FttbConnectivityPackage from "@/components/ObjParts/Selected/FttbConnectivityPackage";
import Modal from "@/components/GeneralComponents/Modal";
import { Circle } from "vue-loading-spinner";
export default {
  components: {
    Modal,
    FttbConnectivityPackage,
    loading: Circle,
  },
  props: {
    modalTitle: {
      type: String,
      required: true,
    },
    fttbAddon: {
      type: Object,
      required: true,
    },
    routedIpv4Network: {
      type: Object,
      required: true,
    },
    routedIpv6Network: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      ipv4NetworkOptions: ['32', '30', '29', '28'],
      ipv6NetworkOptions: ['64'],
    };
  },
  methods: {
    open: function () {
      this.$refs.fttbAddon.open();
    },
    close: function() {
      this.$refs.fttbAddon.close();
    },
    saveFttbAddon: function (fttbAddon) {
      this.$emit("saveFttbAddon", fttbAddon);
      this.close();
    },
    addRoutedNetworks: async function(routedIpv4Network, routedIpv6Network) {
      routedIpv4Network.fttbAddonNumber = this.fttbAddon.fttbAddonNumber;
      routedIpv6Network.fttbAddonNumber = this.fttbAddon.fttbAddonNumber;
      this.$emit("addFttbAddonRoutedIpv4Network", routedIpv4Network);
      this.$emit("addFttbAddonRoutedIpv6Network", routedIpv6Network);
    },
  },
};
</script>