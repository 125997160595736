

<template>

<div>
    <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-8">
                <h3 class="has-error">Under construction, this graph has placeholder data</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
                <UsageBarGraph
                :usageData="sampleData"
                :title="'Usage Data'"
                :height="200"/>
            </div>
          </div>
        </div>
    </div>
</div>

</template>

<script>
import UsageBarGraph from "@/components/Graphs/UsageBarGraph";

export default {
  components: {
    UsageBarGraph
  },
  data: function() {
    return {
      clients: [],
      sampleData: [
        {
          label: "2018-06-01",
          downloaded: 26.123,
          uploaded: 12.34
        },
        {
          label: "2018-06-02",
          downloaded: 12.345,
          uploaded: 0.509
        }
      ]
    };
  },
  mounted: function() {
  },
  methods: {}
};
</script>
