<template>
    <div class="row">
        <div class="col-md-6 mt-4">
            <div class="form-group row">
                <label class="col-md-3">Name:</label>
                <div class="col-md-9">
                    <input type="text" class="form-control" v-model="virtualServer.name">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3">vLAN Name:</label>
                <div class="col-md-9">
                    <input type="text" class="form-control" v-model="virtualServer.vlanName">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3">vLAN Number:</label>
                <div class="col-md-9">
                    <input type="text" class="form-control" v-model="virtualServer.vlanNumber">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3">UUID:</label>
                <div class="col-md-9">
                    <input type="text" class="form-control" v-model="virtualServer.uuid">
                </div>
            </div>
            <div class="form-group row" v-allowed:view="['STAFF']">
                <div class="col-sm-12">
                    <button class="btn btn-success" type="button" @click="save">Save</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            virtualServer: {
                type: Object,
                required: true
            }
        },
        methods: {
            save: function() {
                this.$emit("saveSale");
            }
        }
    }
</script>
