<template>
    <div class="card mb-3 border-warning mt-2" v-if="outstandingDocuments.length > 0">
        <div class="card-header text-white bg-warning"><i class="fa fa-info-circle info-ping"></i>  Outstanding Documentation</div>
        <div class="card-body">
            <h5 class="card-title">Please provide required documentation</h5>
            <div v-for="outstandingDocument in outstandingDocuments" :key="outstandingDocument.type">
                <div class="form-row align-items-center">
                    <div class="col-md-4">
                        <strong>{{outstandingDocument.description}}</strong>   
                    </div>
                    <div class="form-group col-md-6">
                        <div class="input-group">
                            <input type="file" class="form-control" @change="event => fileSelected(event, outstandingDocument.type)"/>
                            <div class="input-group-append">
                                <button class="btn btn-outline-success" :disabled="!selectedFiles[outstandingDocument.type]" type="button" @click="uploadDocument(outstandingDocument.type)">Upload</button>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        outstandingDocuments:{
            required: true,
            type: Array
        },
        clientNumber:{
            required: true,
            type: String
        }
    },
    data(){
        return {
            selectedFiles: {}
        }
    },
    methods: {
        uploadDocument(paperType){

            const formData = new FormData();

            formData.append('file', this.selectedFiles[paperType]);

            this.$http.post(this.$config.aimsAPI + 'clients/' + this.clientNumber + '/correspondence/upload?type=' + paperType, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(function(success) {
                this.showSuccess(paperType + ' Upload Success');
                this.$emit('documentUploaded', paperType);
            }, function(error) {
                console.error(error);
                this.showError('Error Uploading File', error);
            });
        },
        fileSelected(event, outstandingDocument){
            this.$set(this.selectedFiles, outstandingDocument, event.target.files[0]);
        }
    }
}
</script>