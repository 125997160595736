<template>
    <div>
        <div class="department-escalations-wrapper my-3">
            <h4>Department Escalations</h4>
            <hr />
            <div class="form-wrapper">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label>Billing Liaison</label>
                        <v-select
                            name="billingLiaison"
                            v-model="clientDetail.accountAgentNumber"
                            :options="staffMembers"
                            :get-option-label="getLabel"
                            valueProp="staffUserNumber"
                            placeholder="Select a Billing Liaison"
                            :clearable="true"
                            :disabled="!isAuthorizedUser" 
                        >
                        </v-select>
                    </div>
                    <div class="form-group col-md-6">
                        <label>Sale Executive: (Pipedrive Owner)</label>
                        <v-select
                            name="salesAgent"
                            v-model="clientDetail.salesAgentNumber"
                            :options="staffMembers"
                            :get-option-label="getLabel"
                            valueProp="staffUserNumber"
                            placeholder="Select a Sales Executive"
                            :clearable="true"
                            :disabled="!isAuthorizedUser" 
                        >
                        </v-select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label>Service Delivery Manager:</label>
                        <v-select
                            name="serviceDeliveryManager"
                            v-model="clientDetail.serviceAgentNumber"
                            :options="staffMembers"
                            :get-option-label="getLabel"
                            valueProp="staffUserNumber"
                            placeholder="Select a Service Delivery Manager"
                            :clearable="true"
                            :disabled="!isAuthorizedUser" 
                        >
                        </v-select>
                    </div>
                    <div class="form-group col-md-6">
                        <label>Technical Support Agent:</label>
                        <!-- <select v-model="clientDetail.techAgentNumber">
                            <option v-for="option in staffMembers" v-bind:value="option.value">
                            {{ option.name }}
                            </option>
                        </select> -->
                        <v-select
                            name="techSupportAgent"
                            v-model="clientDetail.techAgentNumber"
                            :options="staffMembers"
                            :get-option-label="getLabel"
                            valueProp="staffUserNumber"
                            placeholder="Select a Tech Support Agent"
                            :clearable="true"
                            :disabled="!isAuthorizedUser" 
                        >
                        </v-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="save-button-wrapper" v-allowed:view="['ADMIN', 'ACCOUNTS_STAFF', 'ADMIN_STAFF','MANAGER']">
            <button class="btn btn-info m-0" @click="updateClientDetails(clientDetail)">Save</button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {            
            clientDetail: {
                type: Object,
                required: true,
            },
            staffMembers: {
                type: Array,
                required: true,
            },
            isAuthorizedUser: {
                type: Boolean,
                required: true,
            },
        },
        methods: {
            updateClientDetails: function(clientDetail) {
                this.$emit('updateClientDetails', clientDetail)
            },
            getLabel: function({ firstNames, lastName }) {
                return `${firstNames} ${lastName}`;
            },
        },
    }
</script>

<style scoped>

</style>