import { defineStore } from "pinia";

export const useServicesStore = defineStore("servicesStore", {
    state: () => ({
        searchTerm: "",
        selectedSegment: {
            name: ""
        },
        selectedProduct: {
            description: ""
        },
    }),
})