<template>
    <div>
        <div class="row row-spacing">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Domain Name:</label>
                            <input type="text" class="form-control" v-model="saleDefinition.sale.cyberRiskAware.domainName">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Management Username:</label>
                            <input type="text" class="form-control" v-model="saleDefinition.sale.cyberRiskAware.managementUsername">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Management Password:</label>
                            <PasswordInput :password="saleDefinition.sale.cyberRiskAware.managementPassword" :userCanEdit="true" @changePassword="changePassword"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card card-default">
                    <div class="card-header">
                        <h4>CyberRisk Aware Package</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="mr-2">Description:</label>
                                    <span><strong>{{ saleDefinition.sale.cyberRiskAware.cyberRiskAwarePackage.description }}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-md-12">
                <button class="btn btn-success" type="button" @click="saveSale(saleDefinition)">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
import PasswordInput from '@/components/GeneralComponents/InputControls/PasswordInput';

export default {
        components: {
            PasswordInput
        },
        props: {
            saleDefinition: {
                type: Object,
                required: true
            }
        },
        methods: {
            changePassword: function(data) {
                if (data) {
                    this.saleDefinition.sale.cyberRiskAware.managementPassword = data;
                }
            },
            saveSale: function(saleDefinition) {             
                this.$emit("saveSale", saleDefinition);
            }
        },
    }
</script>
