<template>
  <service-screen-layout :parts="parts">
        <template v-slot:vlantab>
          <div class="row col-md-12">
            
              <div class="col-md-2">
                <div class="form-group">
                  <label>VLAN ID</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      v-model="saleDefinition.definition.network.vlanId"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>VLAN Name</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="saleDefinition.definition.network.vlanName"
                    />
                  </div>
                </div>
              </div>
          </div>
          <div class="row">
            <div class="row-spacing col-md-12">
                  <h4> Assigned IP Network Addresses</h4>
                  <FttbRoutedNetworks
                    :ipv4Networks="saleDefinition.definition.network.ipv4networks"
                    :ipv6Networks="saleDefinition.definition.network.ipv6networks"
                    @routeAdded="addRoute"
                    @routeRemoved="removeRoute"
                  />
                </div>
          </div>          
          <div class="form-row row-spacing justify-content-between" v-allowed:view="['STAFF']">
                <div class="form-group row-spacing col-auto">
                  <button type="button" @click="saveSaleDefinition()" class="btn btn-success">Save</button>
                </div>
          </div>
        </template>
    </service-screen-layout>
</template>

<script>
import SaleFunctions from "@/pages/Services/SaleFunctions";
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import FttbRoutedNetworks from "@/components/ObjParts/MultipleCaptured/FttbRoutedNetworks"

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    FttbRoutedNetworks,
  },
  data() {
    return {
      parts: [
                {
                    name: "vlantab",
                    icon: "fa fa-cloud",
                    displayName: "Network Details",
                    permissions: ["*"],
                }
              ],
      saleDefinition: {
        definition: {
          network: {
              ipv4networks: [],
              ipv6networks: []
            },
        }
      }
    }
  },
  async mounted() {
    this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition() {
      const saleNumber = this.$route.params.saleNumber;
      this.getSaleDefinition(saleNumber).then(
        (success) => {
          this.saleDefinition = success.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
    },
    saveSaleDefinition: function() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
                this.loadSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Sale Definition", error);
              }
            );
        }
      });
    },
    addRoute(data) {
      let newRoute = null
      
      if (data.family.toLowerCase() == "ipv4") {
        newRoute = this.saleDefinition.supportingObjs.ipv4networks
      } else if (data.family.toLowerCase() == "ipv6") {
        newRoute = this.saleDefinition.supportingObjs.ipv6networks
      }

      if (newRoute) {
        newRoute.metric = data.metric
        newRoute.address = data.address
        newRoute.shortMask = data.shortMask
        newRoute.vlanNumber = this.saleDefinition.definition.network.vlanNumber
        
        if (data.family.toLowerCase() == "ipv4") {
          this.saleDefinition.definition.network.ipv4networks.push(newRoute);
          this.saveSaleDefinition();
        } else if (data.family.toLowerCase() == "ipv6") {
          this.saleDefinition.definition.network.ipv6networks.push(newRoute);
          this.saveSaleDefinition();
        }
      } else {
        this.$swal({
          title: 'Could not find route family',
          type: 'warning',
        })
      }
    },
    removeRoute(data) {
      if (data.family.toLowerCase() == "ipv4") {
        for (let i = 0; i < this.saleDefinition.definition.network.ipv4networks.length; i++) {
          if (this.saleDefinition.definition.network.ipv4networks[i] == data) {
            this.deleteObjPart(this.saleDefinition.definition.network.ipv4networks[i])
              .then(
                (response) => {
                  this.showSuccess("Network Removed Succesfully");
                  this.loadSaleDefinition()
                },
                (error) => {
                  console.error(error);
                  this.showError("Error Removing Network", error);
              });
          }
        }
      } else if (data.family.toLowerCase() == "ipv6") {
        for (let i = 0; i < this.saleDefinition.definition.network.ipv6networks.length; i++) {
          if (this.saleDefinition.definition.network.ipv6networks[i] == data) {
            this.deleteObjPart(this.saleDefinition.definition.network.ipv6networks[i])
              .then(
                (response) => {
                  this.showSuccess("Network Removed Succesfully");
                  this.loadSaleDefinition()
                },
                (error) => {
                  console.error(error);
                  this.showError("Error Removing Network", error);
              });
          }
        }
      }
    },

  },
};
</script>