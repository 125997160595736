<template>
    <div>
        <div class="row row-spacing">
            <div class="col-md-12">
                <div class="card card-default">
                    <div v-if="heading" class="card-header row-spacing">
                        <h4>{{heading}}</h4>
                    </div>
                    <div class="card-body card-scroll-60">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Created</th>
                                    <td>Status</td>
                                    <td>Valid From</td>
                                    <td>Valid To</td>
                                    <td>% Used</td>
                                    <td>Data Used</td>
                                    <td>Data Remaining</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(bundle,index) in allocatedBundles" :key="index" :class="{'new-table-row': bundle.isNew}">
                                    <td>{{bundle.description}}</td>
                                    <td>{{bundle.created}}</td>
                                    <td>{{bundle.status}}</td>
                                    <td>{{dateTimeFormat(bundle.validFrom)}}</td>
                                    <td>{{dateTimeFormat(bundle.validUntil)}}</td>
                                    <td>{{getPercentageUsed(bundle.bytesConsumed,bundle.bytesRemaining)}}</td>
                                    <td>{{sizeBytesToGb(bundle.bytesConsumed)}} GB</td>
                                    <td><span class="badge bg-success">{{sizeBytesToGb(bundle.bytesRemaining)}} GB</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            heading: {
              type: String,
              required: false
            },
            availableBundles: {
                type: Array,
                required: true
            },
            allocatedBundles: {
                type: Array,
                required: true
            },
            bundleKeyName: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                
            }
        },
        methods: {
            getBundleDescription: function(allocatedBundle) {
                const indx = this.availableBundles.findIndex(r => r.objKey === allocatedBundle[this.bundleKeyName]);
                if (this.availableBundles[indx] == undefined) {
                    return "Monthly Bundle";
                } else {
                    return this.availableBundles[indx].description + " " + this.availableBundles[indx].size + "GB";
                }
            },
            getPercentageUsed: function(used, remaining) {
                if (used == 0) {
                    return "0";
                }
                return (used/(used + remaining) * 100).toFixed(2);
            }
        }
    }
</script>

<style>
    
</style>
