<template>
  <div class="card card-default">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>VoIP Supplier:</label>
              <div class="row">
                <div class="col-md-6">
                  <v-select
                    :options="supplierLabels"
                    placeholder="Select Supplier"
                    v-model="supplierOption"
                  ></v-select>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="supplierOption">
        <div class="col-md-12">
          <div class="form-group">
            <label>Voice Minutes Billing CSV File:</label>
            <div class="row">
              <div class="col-md-6" >
                <input type="file" class="form-control" @change="fileSelected" />
              </div>
              <div class="col-md-6" >
                <div class="row">
                  <i v-if="showOk" class="text-light customIco fa fa-check" style="background-color: #2E9E83"/>
                  <i v-if="showWrong" class="text-light customIco fa fa-times" style="background-color: #E2231A"/>
                  <div>
                    <label v-if="showWrong" class="customTxt" style="color: #E2231A; font-weight: bold">{{wrongMessage}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="showOk">
        <div class="col-md-12">
          <button class="btn btn-primary" @click="uploadFile()">Upload File</button>
        </div>
      </div>
      <div class="row-spacing table-responsive table-bordered">
        <table class="table text-nowrap">
          <thead>
            <tr>
              <th>ID</th>
              <th>File Name</th>
              <th>Uploaded</th>
            </tr>
          </thead>
          <tbody v-if="vmFiles.length > 0">
            <tr v-for="file in vmFiles" :key="file.objKey">
              <td>{{file.objKey}}</td>
              <td>{{file.fileName}}</td>
              <td>{{file.created.split(" ")[0]}}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5">No Voice Minutes Billing Files Uploaded</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import Modal from "@/components/GeneralComponents/Modal";

  export default {
    components: {
        Modal,
    },
    data() {
      return {
        minutesBillingFile: null,
        fileName: '',
        supplierOption: '',
        supplierFileType: '',
        supplierLabels: [],
        supplierObjects: [],
        showOk: false,
        showWrong: false,
        wrongMessage: '',
        vmFiles: [],
      }
    },
    created() {
      this.$root.$emit('SET_PAGE_TITLE', this.$route.meta.title);
    },
    mounted() {
      this.$http.get(this.$config.aimsAPI + 'voiceminutes/upload/cdr/suppliers').then(
        response => {
          for (let i = 0; i < response.data.length; i++) {
            this.supplierLabels.push(response.data[i].description);
            this.supplierObjects.push(response.data[i]);
          }
        },
        error => {
          console.error(error);
          this.showError("Error Loading Suppliers", error);
        });

      this.getFiles();
    },
    computed: {
      
    },  
    methods: {
        fileSelected(event) {
            this.minutesBillingFile = event.target.files[0];
            this.fileName = this.minutesBillingFile.name;

            for (let i = 0; i < this.supplierObjects.length; i++) {
              if (this.supplierOption == this.supplierObjects[i].description) {
                this.supplierFileType = this.supplierObjects[i].contentType
              }
            }
            
            if (this.minutesBillingFile.name.substring(this.minutesBillingFile.name.length-3, this.minutesBillingFile.name.length).toLowerCase() == "csv" || this.minutesBillingFile.type == this.supplierFileType) {
              this.showOk = true;
              this.showWrong = false;
            } else {
              this.showOk = false;
              this.showWrong = true;
              this.wrongMessage = "Wrong File Type"
            }
        },
        uploadFile() {
            var supplierID;
            this.showOk = false;

            if (!this.minutesBillingFile || this.minutesBillingFile.length == 0) {
              this.showWarning('Please select a file');
              return;
            }

            for (let i = 0; i < this.supplierObjects.length; i++) {
              if (this.supplierObjects[i].description == this.supplierOption) {
                supplierID = this.supplierObjects[i].name
              }
            }

            const formData = new FormData();
            formData.append('file', this.minutesBillingFile);

            this.$http.post(this.$config.aimsAPI + 'voiceminutes/upload/cdr/' + supplierID, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(
              response => {
                if (response.data.recordsRead == 0) {
                  return this.$swal({
                    title: "Error: Couldn't read CSV",
                    text: "Check file format and make sure the correct supplier was selected.",
                    type: 'warning',
                    confirmButtonText: 'Ok',
                  });
                } else {
                  this.getFiles();
                  return this.$swal({
                    title: "Successfully uploaded " + response.data.fileName,
                    text: "File successfully uploaded",
                    type: 'success',
                    confirmButtonText: 'Ok',
                  });
                }
              },
              error => {
                console.error(error);
                this.showError("Error Uploading File", error);
              });

            this.resetFile();
        },
        getFiles() {
          this.$http.get(this.$config.aimsAPI + 'voiceminutes/files').then(
            response => {
              this.vmFiles = response.body;
            },
            error => {
              console.error(error);
              this.showError("Error Loading Files", error);
            });
        },
        resetFile() {
            this.minutesBillingFile = null;
            $('input[type=file]').val(null);
        },
    },
  }

</script>

<style>
    .customBtn {
        height: 25px;
        width: 25px;
        text-align: center;
        padding: 6px 0;
        font-size: 12px;
        line-height: 0.885;
        border-radius: 50%;
        margin-top: 3px;
    }
    .customIco {
        height: 25px;
        width: 25px;
        text-align: center;
        padding-top: 5.5px;
        margin-top: 5px;
        border-radius: 50%;
    }
    .customTxt {
        text-align: center;
        padding: 8.5px;
        line-height: 0.885;
        margin-top: 3px;
    }
</style>
