<template>
  <div class="card card-default">
    <div class="card-header">
      <h4>{{ heading }}</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-9" v-if="validAddresses.length > 0">
          <caption v-if="caption" class="text-nowrap">
            {{
              caption
            }}
          </caption>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Address</th>
                <th>Netmask</th>
                <th v-allowed:view="['STAFF']"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(address, index) in validAddresses"
                :key="index"
                :class="{ 'new-table-row': address.isNew }"
              >
                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="address.address"
                  />
                </td>
                <td>
                  <select class="form-control" v-model="address.shortMask">
                    <option :key="mask" v-for="mask in netmasks" :value="mask">
                      /{{ mask }}
                    </option>
                  </select>
                </td>
                <td style="text-align: center" v-allowed:view="['STAFF']">
                  <button
                    type="button"
                    class="btn btn-danger"
                    @click="$emit('removeAddress', address)"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="col-md-12">
          <div class="alert alert-info">No IPv4 Routed Networks Added</div>
        </div>
      </div>
      <div class="row-spacing row" v-allowed:view="['STAFF']">
        <div class="col-md-12">
          <div class="form-group pull-right">
            <button
              class="btn btn-secondary"
              @click="
                routedNetworks.unshift(deepCopyObject(emptyRoutedNetwork))
              "
              type="button"
            >
              Add Another
            </button>
            <button
              class="btn btn-success"
              @click="$emit('saveRoutedNetworks', routedNetworks)"
              type="button"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    validAddresses() {
      return this.routedNetworks.filter((address) => address);
    },
  },
  props: {
    heading: {
      type: String,
      required: true,
    },
    routedNetworks: {
      type: Array,
      required: true,
    },
    netmasks: {
      type: Array,
      required: false,
    },
    emptyRoutedNetwork: {
      type: Object,
      required: true,
    },
    caption: {
      type: String,
      required: false,
    },
  },
};
</script>
