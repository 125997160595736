<template>
  <service-screen-layout :parts="parts">
    <template v-slot:account>
      <div class="row row-spacing">
        <div class="col-md-7">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label"
                      >Email Address:
                    </label>
                    <div class="col-md-7">
                      <input
                        type="text"
                        class="form-control"
                        readonly
                        v-model="emailAddress"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label"
                      >Current Password:
                    </label>
                    <div class="col-md-7">
                      <PasswordInput
                        :password="saleDefinition.sale.mailbox.password"
                        :userCanEdit="true"
                        @changePassword="changePassword"
                        validation="zimbra"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label">Package: </label>
                    <div class="col-md-7">
                      <v-select
                        :options="saleDefinition.meta.mailbox.children.pkg.partMeta.options"
                        label="description"
                        v-model="saleDefinition.sale.mailbox.pkg"
                      ></v-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label"
                      >Current Usage:
                    </label>
                    <div class="col-md-7">
                      <div class="input-group">
                        <div class="form-control bg-usage">
                          {{ sizeBytesToMb(saleDefinition.sale.mailbox.usage) }}
                        </div>
                        <div class="input-group-append">
                          <span class="input-group-text">MB</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-9">
                  <div class="form-group">
                    <button
                      class="btn btn-success"
                      @click="saveSale"
                      type="button"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div 
          class="col-md-5" 
          v-if="saleDefinition.details && 
          saleDefinition.details.portal && 
          saleDefinition.details.portal.url.length > 0 && 
          saleDefinition.details.portal.name.length > 0"
        >
          <PortalButton :portalDetails="saleDefinition.details.portal"/>
        </div>
      </div>
    </template>
  </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";
import PasswordInput from "@/components/GeneralComponents/InputControls/PasswordInput";
import PortalButton from "@/components/ObjParts/SingleLoaded/PortalButton";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    PasswordInput,
    PortalButton,
  },
  watch: {
    saleDefinition: {
      deep: true,
      handler(newSaleDef, oldSaleDef) {
        if (oldSaleDef.sale.description != newSaleDef.sale.description && this.$parent.$refs.serviceTitle) {
          this.$parent.$refs.serviceTitle.reloadSale();
        }
      },
    },
  },
  computed: {
    emailAddress() {
      return (
        this.saleDefinition.sale.mailbox.username +
        "@" +
        this.saleDefinition.sale.mailbox.domain.domainName
      );
    },
  },
  data() {
    return {
      parts: [
        {
          name: "account",
          icon: "fa fa-info-circle",
          displayName: "Account Details",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        sale: {
          mailbox: {
            domain: {},
          },
        },
        meta: {
          mailbox: {
            children: {
              pkg: {
                partMeta: {
                  options: []
                }
              }
            }
          }
        }
      },
    };
  },
  mounted() {
    this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition() {
      const saleNumber = this.$route.params.saleNumber;
      this.fetchSaleDefinition(saleNumber).then(
        (response) => {
          this.saleDefinition = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Could not load Sale Definition", error);
        }
      );
    },
    changePassword: function(data) {
        if (data) {
          this.saleDefinition.sale.mailbox.password = data;
        }
    },
    saveSale() {
      this.$http
        .put(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/update",
          this.saleDefinition
        )
        .then(
          () => {
            this.showSuccess("Save Success");
            this.loadSaleDefinition();
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving SaleDefinition", error);
          }
        );
    },
    sizeBytesToMb(value) {
      if (value && value > 0) {
        return (value / 1024 / 1024).toFixed(2)
      } else {
        return 0;
      }
    }
  },
};
</script>

<style scoped>
  .bg-usage {
    background-color: #edf1f2;
  }
</style>
