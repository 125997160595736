<template>
    <div class="col-md-12">
        <div class="row row-spacing">
            <h4>Website Aliases</h4>
        </div>
        <div class="row row-spacing">
            <button class="btn btn-info" @click="addWebsiteAlias" v-allowed:view="['STAFF']">Add Alias</button>
        </div>
        <div class="row row-spacing">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Alias</th>
                        <th v-allowed:view="['STAFF']"></th>
                    </tr>
                </thead>
                <tbody v-if="website.websiteAliases.length > 0">
                    <tr v-for="alias in website.websiteAliases" :key="alias.websiteAliasNumber">
                        <td>{{ alias.name }}</td>
                        <td class="text-center" v-allowed:view="['STAFF']">
                            <button class="btn btn-primary" @click="deleteWebsiteAlias(alias)"><i class="fa fa-trash"></i></button>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="2">No Website Aliases to display</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <AddWebHostingWebsiteAliasModal 
            ref="addWebsiteAliasModal"
            :newWebsiteAlias="newWebsiteAlias"
            @createWebsiteAlias="createWebsiteAlias"
        />
    </div>
</template>

<script>
import AddWebHostingWebsiteAliasModal from '@/components/Admin/Modals/AddWebHostingWebsiteAliasModal';

export default {
    props: {
        website: {
            type: Object,
            required: true
        },
        newWebsiteAlias: {
            type: Object,
            required: true
        },
    },
    components: {
        AddWebHostingWebsiteAliasModal,
    },
    methods: {
        addWebsiteAlias: function() {
            this.$refs.addWebsiteAliasModal.open();
        },
        createWebsiteAlias: function(data) {
            const hostUrl = this.$config.aimsAPI;
            const saleNumber = this.$route.params.saleNumber;
            
            this.$refs.addWebsiteAliasModal.$refs.addWebsiteAliasModal.isLoading();

            data.websiteNumber = this.website.websiteNumber;
            
            this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/create`, data).then(
                (response) => {
                    this.showSuccess("Successfully created website alias");
                },
                (error) => {
                    this.showError("Error adding website alias", error);
                    console.error(error);
                }
            ).finally(() => {
                this.$emit("loadSaleDefinition");
                this.$refs.addWebsiteAliasModal.$refs.addWebsiteAliasModal.isLoading();
                this.$refs.addWebsiteAliasModal.$refs.addWebsiteAliasModal.close();
            });  
        },
        deleteWebsiteAlias: function(data) {
            this.$swal({
                title: "Are you sure you want to delete this alias?",
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    const hostUrl = this.$config.aimsAPI;
                    const saleNumber = this.$route.params.saleNumber;

                    this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/delete/`, data).then(
                        (response) => {
                            this.showSuccess(response.data.message);
                            this.$emit("loadSaleDefinition");
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error deleting website alias", error);
                        }
                    );
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>

</style>