<template>
  <div class="row">
    <div class="col-xl-8 col-lg-12">
      <div class="card card-default">
        <form-wizard
          title=""
          subtitle=""
          @on-complete="onComplete"
          stepSize="sm"
        >
          <tab-content
            title="Line"
            icon="fa fa-plug"
            :before-change="() => validateStep('line')"
          >
            <form novalidate data-vv-scope="line">
              <div class="row">
                <div class="form-group col-md-6">
                  <label>Circuit Number *</label>
                  <div
                    class="input-group"
                    :class="{
                      'has-error': errors.has('line.circuitNumber'),
                    }"
                  >
                    <input
                      v-validate="{required: true, regex: /^[A-Z0-9]+(-[A-Z0-9]+)*$/}"
                      type="text"
                      name="circuitNumber"
                      class="form-control"
                      v-model="filledInSaleDefinition.requiredObjs.line.circuitNumber"
                    />
                  </div>
                  <span
                    class="has-error"
                    v-if="errors.has('line.circuitNumber')"
                    >Circuit numbers may only contain capital letters, numbers and hyphens seperating them</span
                  >
                </div>
              </div>
            </form>
          </tab-content>
          
          <tab-content
            title="Line Address Info"
            icon="fa fa-clipboard"
            :before-change="() => validateStep('line-info')"
          >
            <div class="row">
              <Address
                class="col-xl-12"
                @addressChanged="lineAddressChanged"
                @addressValid="lineAddressValidChanged"
                :addressStructure="
                  filledInSaleDefinition.requiredObjs.lineAddress
                "
                :addressTypes="
                  filledInSaleDefinition.supportingEnums.lineAddress.addressType
                "
              />
            </div>
          </tab-content>

          <tab-content
            title="Account Options"
            :before-change="() => validateStep('account-selection')"
          >
            <form novalidate data-vv-scope="account-selection">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('account-selection.CapSize'),
                    }"
                  >
                    <label class="control-label">Cap Size *</label>
                    <div class="input-group">
                      <v-select
                        name="CapSize"
                        placeholder="Select Cap Size"
                        label="capSize"
                        v-model="packageSelected.selectedCapSize"
                        @input="onCapSizeChanged()"
                        :options="distinctCapSizes"
                      ></v-select>
                      <div class="input-group-append">
                        <span class="input-group-text">GB</span>
                      </div>
                    </div>
                    <span
                      class="has-error"
                      v-if="errors.has('account-selection.CapSize')"
                      >{{ errors.first("account-selection.CapSize") }}</span
                    >
                  </div>
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('account-selection.Speed'),
                    }"
                  >
                    <label class="control-label">Package *</label>
                    <div class="input-group">
                      <v-select
                        @input="getPackage"
                        v-validate="'verify_selected'"
                        ref="speed"
                        name="Speed"
                        placeholder="Select Package"
                        :get-option-label="getLabel"
                        :options="filterPackagesByCapSize"
                      ></v-select>
                      <div class="input-group-append">
                        <span class="input-group-text">Mbps</span>
                      </div>
                    </div>
                    <span
                      class="has-error"
                      v-if="errors.has('account-selection.Speed')"
                      >{{ errors.first("account-selection.Speed") }}</span
                    >
                  </div>
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('account-selection.Network'),
                    }"
                  >
                    <label class="control-label">Network *</label>
                    <div class="input-group">
                      <v-select
                        v-validate="'required'"
                        ref="networkgroup"
                        class="mb-1"
                        name="Network"
                        label="description"
                        valueProp="networkGroupNumber"
                        placeholder="Select Network"
                        v-model="
                          filledInSaleDefinition.requiredObjs.pppoe
                            .networkGroupNumber
                        "
                        :options="
                          filledInSaleDefinition.selectableOptions.networkGroup
                        "
                      ></v-select>
                    </div>
                    <span
                      class="has-error"
                      v-if="errors.has('account-selection.Network')"
                      >{{ errors.first("account-selection.Network") }}</span
                    >
                  </div>
                </div>
              </div>
            </form>
          </tab-content>
        </form-wizard>
      </div>
    </div>
    <WizardCheckout 
      ref="wizardCheckoutModal"
      :description="packageSelected.description"
      :prices="packageSelected.selectedPrice"
      @checkout="finalize"
    />
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Address from "@/components/GeneralComponents/Address";
import WizardCheckout from '@/components/ObjParts/MultipleLoaded/WizardCheckout'

export default {
  components: {
    FormWizard,
    TabContent,
    WizardCheckout,
    Address,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filledInSaleDefinition: {
        selectableOptions: {
          connectivityPackage: [],
          networkGroup: [],
        },
        supportingEnums: {
          lineAddress: {
            addressType: [],
          },
          line: {},
        },
        requiredObjs: {
          pppoe: {},
          lineAddress: {
            addressType: [],
          },
        },
      },
      packageSelected: {
        description: "",
        selectedPrice: []
      },
      distinctCapSizes: [],
      filterPackagesByCapSize: [],
      shapedPools: [],
    };
  },
  created() {
    this.$validator.extend(
      "verify_selected",
      (value) => {
        return {
          valid:
            value ||
            (this.packageSelected && this.packageSelected.connectivityPackageNumber),
          data: {
            required: true,
          },
        };
      },
      {
        computesRequired: true,
      }
    );
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
    this.getFilteredPackageList();
    const productNumber = this.filledInSaleDefinition.product.productNumber;
    this.$http
      .get(this.$config.aimsAPI + "shapedpools?productNumber=" + productNumber)
      .then(
        (response) => {
          this.shapedPools = response.body;
        },
        (error) => {
          this.showError("Error fetching Shaped Pools", error);
          console.error(error);
        }
      );
  },
  methods: {
    getFilteredPackageList() {
      this.distinctCapSizes = [];
      this.filledInSaleDefinition.selectableOptions.connectivityPackage.forEach(
        (p) => {
          if (!this.distinctCapSizes.includes(p.size)) {
            this.distinctCapSizes.push(p.size);
          }
        }
      );
    },
    onCapSizeChanged() {
      const filteredPackageList = [];
      this.$refs.speed.clearSelection();
      this.filledInSaleDefinition.selectableOptions.connectivityPackage.forEach(
        (p) => {
          if (p.size === this.packageSelected.selectedCapSize) {
            filteredPackageList.push(p);
          }
        }
      );

      const distinctSpeedList = this.distinctList(
        filteredPackageList,
        "connectivityPackageNumber"
      );
      this.filterPackagesByCapSize = this.orderList(
        distinctSpeedList,
        "downloadSpeed"
      );
    },
    getLabel(option) {
      return (
        option.description +
        " - [" +
        option.contractTerm +
        "] - " +
        this.speed_bitsToMbits(option.downloadSpeed) +
        "/" +
        this.speed_bitsToMbits(option.uploadSpeed) +
        " - [" + this.shapedPools.find(sp => sp.shapedPoolNumber === option.shapedPoolNumber).name + "]"
      );
    },
    getPrice(p) {
      return this.$http.get(
        this.$config.aimsAPI +
          "pricing/connectivitypackages/product/" +
          p +
          "?onlyCurrentPricing=true"
      );
    },
    async getPackage(packageSelected) {
      if (packageSelected && Object.keys(packageSelected).length > 0) {
        this.packageSelected = packageSelected;
        this.getPrice(packageSelected.productNumber).then((success) => {
          let prices = success.data.connectivitypackages;
          prices.forEach((price) => {
            if (
              price.connectivityPackageNumber == packageSelected.connectivityPackageNumber
            ) {
              this.packageSelected.selectedPrice =
                price.connectivitypackageprices;
            }
          });
        });
      }
    },
    validateStep(stepName) {
      return this.$validator.validateAll(stepName).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
    onComplete() {
      this.filledInSaleDefinition.requiredObjs.cappedService.connectivityPackageNumber = this.packageSelected.connectivityPackageNumber;
  
      this.$refs.wizardCheckoutModal.open();
    },
    finalize(){
      this.$emit('createSale', this.filledInSaleDefinition);
    },
    lineAddressChanged(address) {
      this.filledInSaleDefinition.requiredObjs.lineAddress = address;
    },
    lineAddressValidChanged(isValid) {
      this.lineAddressValid = isValid;
    },
  },
};
</script>