<template>
    <div class="col-md-12">
        <div class="card border-light">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>Total Usage</th>
                                <th>Over Usage</th>
                                <th>Bundle Usage</th>
                            </tr>
                        </thead>
                        <tbody v-if="apnBulkUsage.length > 0">
                            <tr v-for="usage in apnBulkUsage" :key="usage.apnBulkUsageNumber">
                                <td>{{ sizeBytesToGb(usage.totalUsage)  }} GB</td>
                                <td>{{ sizeBytesToGb(usage.overUsage) }} GB</td>
                                <td>{{ sizeBytesToGb(usage.bundleUsage) }} GB</td>
                            </tr>
                        </tbody>
                        <tbody v-else-if="isUploading">
                            <tr>
                                <td colspan="9">
                                    <DataLoader 
                                        :loadingText="loadingText"
                                    />
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="4">No usage uploaded</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataLoader from '@/components/GeneralComponents/DataLoader.vue';

export default {
    components: {
        DataLoader
    },
    props: {
        apnBulkUsage: {
            type: Array,
            required: true
        },
        isUploading: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            loadingText: 'Uploading Entries...',
        }
    },
}
</script>

<style lang="scss" scoped>

</style>