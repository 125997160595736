<template>
  <modal :title="billingBreakdown.description" ref="billingBreakdownModal" size="modal-xl" dismissText="Close"
    :save-needed="false">
    <table class="billing-breakdown">
      <thead>
        <tr>
          <th>Description</th>
          <th>Excl.</th>
          <th>Incl. VAT @ {{billingBreakdown.vatPercentage}}%</th>
        </tr>
      </thead>
      <tbody>
        <tr style="border-bottom:1px solid lightgray; border-top:1px solid lightgray">
          <td>
            <div>
              <p>{{billingBreakdown.description}}</p>
            </div>
          </td>
          <td>
            <div>
              <p>{{currencyFormat(billingBreakdown.exclVat)}}</p>
            </div>
          </td>
          <td>
            <div>
              <p>{{currencyFormat(billingBreakdown.inclVat)}}</p>
            </div>
          </td>
        </tr>
        <tr v-for="(level1, index) in billingBreakdown.breakdown" :key="index+'A'" :class="level1.type == 'DISCOUNT' ? 'text-warning' : ''">
          <td>
            <div>
              <p>{{level1.description}}</p>
            </div>
          </td>
          <td>
            <div>
              <p>{{currencyFormat(level1.exclVat)}}</p>
            </div>
          </td>
          <td>
            <div>
              <p>{{currencyFormat(level1.inclVat)}}</p>
            </div>
          </td>
        </tr>
        <tr v-for="(level1, index) in billingBreakdown.breakdown" :key="index+'B'">
          <td>
            <div v-for="(level2, index) in level1.breakdown" :key="index+'C'" class="billing-item">
              <p>{{level2.description}}</p>
              <div v-for="(level3, index) in level2.breakdown" :key="index+'D'" class="billing-item">
                <p>{{level3.description}}</p>
              </div>
            </div>
          </td>
          <td>
            <div v-for="(level2,index) in level1.breakdown" :key="index+'E'" class="billing-item">
              <p>{{currencyFormat(level2.exclVat)}}</p>
              <div v-for="(level3,index) in level2.breakdown" :key="index+'F'" class="billing-item">
                <p>{{currencyFormat(level3.exclVat)}}</p>
              </div>
            </div>
          </td>
          <td>
            <div v-for="(level2,index) in level1.breakdown" :key="index+'G'" class="billing-item">
              <p>{{currencyFormat(level2.inclVat)}}</p>
              <div v-for="(level3,index) in level2.breakdown" :key="index+'H'" class="billing-item">
                <p>{{currencyFormat(level3.inclVat)}}</p>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </modal>
</template>

<script>
  import Modal from '@/components/GeneralComponents/Modal'
  export default {
    components: {
      Modal
    },
    data() {
      return {
        billingBreakdown: {}
      }
    },
    methods: {
      open(documentLineNumber) {
        this.$http.get(this.$config.aimsAPI + "documents/documentline/" + documentLineNumber + "/billingbreakdown")
          .then(
            response => {
              this.billingBreakdown = response.data;
              this.$refs.billingBreakdownModal.open();
            },
            error => {
              console.error(error);
              this.showWarning("No additional billing information available.");
            });

      },
      close() {
        this.$refs.billingBreakdownModal.close();
      }
    }
  }

</script>

<style>
  .billing-item {
    padding-left: 30px
  }

  .billing-breakdown {
    width: 100%
  }

</style>
