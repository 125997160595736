<template>
  <div class="card-body">
    <div class="row">
      <div class="col-md-6">
        <div class="card card-default">
          <div class="card-header">
            <h4 class="mb-0">Sale Information</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-md-4">Product:</label>
                  <div class="col-md-8">
                    <strong>{{
                      saleDefinition.product.description +
                      " (" +
                      saleDefinition.product.productNumber +
                      ")"
                    }}</strong>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4">Sale number:</label>
                  <div class="col-md-8">
                    <strong>{{ saleDefinition.sale.saleNumber }}</strong>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4">Status:</label>
                  <div class="col-md-8">
                    <strong><label id="dtlStatus">{{ saleDefStatus }}</label></strong>
                  </div>
                </div>
                <div class="form-group row" id="divArchive">
                  <label class="col-md-4">Archive:</label>
                  <div class="col-md-8">
                    <strong><label id="dtlArchive">{{ saleDefinition.sale.archive }}</label></strong>
                  </div>
                </div>
                <div class="form-group row align-items-center">
                  <label class="col-md-4 mb-md-0">Sale start date:</label>
                  <div class="col-md-8">
                    <strong><label id="dtlArchive">{{ saleDefinition.sale.startDate.split(" ")[0] }}</label></strong>
                  </div>
                </div>
                <div class="form-group row align-items-center" v-if="saleDefStatus == 'Inactive'">
                  <label class="col-md-4 mb-md-0">Sale end date:</label>
                  <div class="col-md-8">
                    <strong><label id="dtlArchive">{{ saleDefinition.sale.endDate.split(" ")[0] }}</label></strong>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4">Cost override:</label>
                  <div class="col-md-8">
                    <strong>{{ saleDefinition.sale.overrideCost }}</strong>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4">Overridden Cost:</label>
                  <div class="col-md-8">
                    <strong>{{
                      currencyFormat(saleDefinition.sale.overriddenCost)
                    }}</strong>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4">Sale created:</label>
                  <div class="col-md-8">
                    <strong>{{
                      dateTimeFormat(saleDefinition.sale.created)
                    }}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer row-spacing">
            <button
              v-allowed:view="['STAFF', 'RESELLER']"
              type="button"
              @click="saleSave()"
              class="btn btn-success"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-6" v-allowed:view="['STAFF', 'RESELLER']">
        <Discounts
          :discountAllowed="saleDefinition.definition.discountAllowed"
          :discounts="saleDefinition.definition.discounts"
          @discountSave="discountSave"
          @discountDelete="discountDelete"
          @discountCancel="loadSale()"
        />

        <div class="card card-default">
          <div class="card-header">
            <h4>Billing Information</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Billing type:</label>
                  <div class="col-md-6">
                    <select
                      disabled
                      name="type"
                      class="form-control"
                      v-model="saleDefinition.sale.type"
                    >
                      <option
                        v-for="(type, tindex) in saleDefinition.supportingEnums
                          .Sale.type"
                        :key="tindex"
                        :value="type.description"
                      >
                        {{ type.description }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Invoice to:</label>
                  <div class="col-md-6">


                <v-select
                  name="paidBy"
                  :options="saleDefinition.supportingEnums.Sale.paidBy"
                  valueProp="name"
                  label="description"
                  placeholder="Select"
                  v-model="saleDefinition.sale.paidBy"
                ></v-select>
                    
                    <!-- <select v-allowed:edit="['STAFF']" name="paidBy" class="form-control"
                      v-model="saleDefinition.sale.paidBy">
                      <option
                        v-for="(paidBy, pindex) in saleDefinition.supportingEnums.Sale.paidBy"
                        :key="pindex"
                        :value="paidBy.description">
                              {{ paidBy.description }}
                        </option>
                    </select>  -->
                   </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4 col-form-label"
                    >Commission type:</label
                  >
                  <div class="col-md-6">
                    <select
                      disabled
                      name="comissionType"
                      class="form-control"
                      v-model="saleDefinition.sale.comissionType"
                    >
                      <option
                        v-for="(comissionType, cindex) in saleDefinition
                          .supportingEnums.Sale.comissionType"
                        :key="cindex"
                        :value="comissionType.description"
                      >
                        {{ comissionType.description }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateInput from "@/components/GeneralComponents/InputControls/DateInput";
import Discounts from "@/components/ObjParts/MultipleCaptured/Discounts";
import SaleFunctions from "@/pages/Services/SaleFunctions";

export default {
  extends: SaleFunctions,
  components: {
    DateInput,
    Discounts,
  },
  data() {
    return {
      saleDefinition: {
        sale: {
          startDate: "",
          endDate: ""
        },
        definition: {
          discountAllowed: false,
          discounts: [],
        },
        supportingObjs: {
          discounts: {},
        },
        supportingEnums: {
          Sale: {
            audited: [],
            paidBy: [],
            comissionType: [],
            type: [],
          },
        },
        isCached: true,
      },
      showArchiveField: false
    };
  },
  async mounted() {
        await this.loadSale();    
  },
  computed: {
    saleDefStatus() {
      if (this.saleDefinition.supportingInfo && this.saleDefinition.supportingInfo.deactivateOn) {
        return this.saleDefinition.sale.status + " (Deactivating on: " + this.saleDefinition.supportingInfo.deactivateOn.substring(0,10) + ")";
      } else {
        return this.saleDefinition.sale.status;
      }
    },
    saleDefDeactivationDate() {
      return "(Deactivating on: " + this.saleDefinition.supportingInfo.deactivateOn.substring(0,10) + ")";
    }
  },
  methods: {
    loadSale() {
      if (this.$route.name === "archivedservice") {
        return this.isCached = false;
      } 
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.$route.params.saleNumber;
      this.$http.get(`${hostUrl}sales/definition/sale/${saleNumber}?cache=${this.isCached}`).then(
        (success) => {
          this.saleDefinition = success.data;
          this.reloadStatus();
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
    },
    saleSave() {
      this.saleDefinition.sale.startDate = this.dateTimeFormat(
        this.saleDefinition.sale.startDate
      );

      this.saleDefinition.sale.endDate = this.dateTimeFormat(
        this.saleDefinition.sale.endDate
      );
      if (
        this.saleDefinition.sale.endDate === null ||
        this.saleDefinition.sale.endDate === undefined
      ) {
        this.saleDefinition.sale.endDate = "1970-01-01 02:00:00";
      }

      this.$http
        .put(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/update",
          this.saleDefinition
        )
        .then(
          (response) => {
            this.showSuccess("Save Success");
            this.saleDefinition = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving Sale Info.", error);
          }
        );
    },
    setSaleEndDate() {
      this.saleDefinition.sale.endDate = null;
    },
    discountSave(modifiedDiscounts) {
      this.$http
        .put(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/objlist/update/", 
          modifiedDiscounts
        )
        .then(
          (success) => {
            this.showSuccess("Updated Discounts.");
            this.loadSale();
          },
          (error) => {
            this.showError("Error Saving Discount", error);
            console.error(error);
          }
        );
    },
    discountDelete(discount) {
      const index = this.saleDefinition.definition.discounts.findIndex(
        (d) => d.objKey === discount.objKey
      );
      this.saleDefinition.definition.discounts.splice(index, 1);
      this.$http
        .post(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/obj/delete/",
          discount
        )
        .then(
          (success) => {
            this.showSuccess("Discount successfully removed");
            this.loadSale();
          },
          (error) => {
            console.error(error);
            this.showError("Error Removing Discount", error);
          }
        );
    },
    reloadStatus() {
      if (document.getElementById("dtlStatus")) {
        document.getElementById("dtlStatus").textContent = this.saleDefStatus;

        if (document.getElementById("dtlArchive") && this.saleDefinition.supportingInfo.archiveOn) {
          document.getElementById("divArchive").hidden = false;
          document.getElementById("dtlArchive").textContent = this.saleDefinition.supportingInfo.archiveOn.substring(0,10);

        } else {
          document.getElementById("divArchive").hidden = true;

        }
      }
    },
  },
};
</script>
