<template>
  <modal :title="modalTitle" ref="licenceModal" :size="'modal-lg'" @save="saveLicenceBundle(licence)">
    <div class="row">
          <div class="col-md-4">
                <label>Contract Start Date:</label>
                <DateInput
                    id="lstart"
                    type="date"
                    v-model="contract.startDate"
                    style="width: 100%"
                    disabled
                  />  
          </div>
          <div class="col-md-4">  
               <label>Contract End Date:</label>
                  <DateInput
                    id="lend"
                    type="date"
                    v-model="contract.endDate"
                    style="width: 100%"
                    disabled
                  /> 
          </div>
    </div>
   

    <form>
    <div class="row">
      <div class="col-md-12 mt-4">
        <h4>Add Licence to Contract</h4>
        <div class="row">
          <div class="col-md-4">
                <label>Licence Start Date:</label>
                <div class="input-group">
                  <DateInput
                    id="licStart"
                    type="date"
                    v-model="licence.startDate"
                    style="width: 100%"
                  /> 
                  <div class="input-group-append">
                      <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                  </div>
                </div>
          </div>
          <div class="form-group col-md-4" :class="{'has-error': errors.has('quantity')}">
                  <label>Quantity</label>
                  <input type="number" class="form-control" :class="{'has-error': errors.has('quantity')}" name="quantity" v-validate="'integer'" v-model="licence.quantity" />
          </div>
          <div class="form-group col-md-4" :class="{'has-error': errors.has('licenceKey')}">
            <label>Unit Cost (excl. VAT)</label>
            <div class="input-group">   
                <div class="input-group-prepend">
                  <span class="input-group-text">{{ getCurrency() }}</span>
                </div>
                <input
                  type="number"
                  v-model="licence.unitCost"
                  name="unitCost" v-validate="'min_value:0.01'"
                  class="form-control" :class="{'has-error': errors.has('unitCost')}"
                />
            </div>
          </div>
        </div>  
        <div class="row">
          <div class="form-group col-md-6" :class="{'has-error': errors.has('licenceKey')}"> 
            <label class="col-form-label">Licence Key:</label>
            <input type="text" class="form-control" :class="{'has-error': errors.has('licenceKey')}" name="licenceKey" v-validate="'required'" v-model="licence.licenceKey" > 
            <span class="has-error" v-if="errors.has('licenceKey')">
                      {{ errors.first("licenceKey") }}
            </span>
          </div> 
          <div class="form-group col-md-6" :class="{'has-error': errors.has('description')}">
            <label class="col-form-label">Description:</label>
            <input type="text" class="form-control" :class="{'has-error': errors.has('description')}" name="description" v-validate="'required'" v-model="licence.description">
            <span class="has-error" v-if="errors.has('description')">
                      {{ errors.first("description") }}
            </span>
          </div> 
        </div> 
      
      </div>
    </div>
    </form>
        
  </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
import { Circle } from "vue-loading-spinner";
import DateInput from "@/components/GeneralComponents/InputControls/DateInput";

export default {
  components: {
    Modal,
    DateInput,
    loading: Circle,

  },
  props: {
    modalTitle: {
      type: String,
      required: true,
    },
    contract: {
      type: Object,
      required: true,
    },
    licence: {
      type: Object,
      required: true,
    },
    saveText: {
      type: String,
      default: "Add",
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
  },
  methods: {
    open: function () {
      this.$refs.licenceModal.open();
    },
    getCurrency() {
      if (this.currency) {
        return this.currency;
      } else return "R";
    },
    saveLicenceBundle: function (data) {
      return this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("saveLicenceBundle", data);
          this.$refs.licenceModal.close();
        }
      });
    }
  },
};
</script>