<template>
  <div class="mt-4">
    <div class="row row-spacing">
      <div class="col-md-6">
        <div class="form-row align-items-center">
            <label class="col-md-6">Data Centre:</label>
            <div class="col-md-6">
              <input type="text" class="form-control" v-model="crossconnect.datacentre.description" disabled/>
            </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-row align-items-center">
            <label class="col-md-6">Description:</label>
            <div class="col-md-6">
              <input type="text" class="form-control"  v-model="crossconnect.description"/>
            </div>
        </div>
      </div>
    </div>
    <div class="row row-spacing">
      <div class="col-md-6">
        <div class="form-row align-items-center">
            <label class="col-md-6">Service Order Number:</label>
            <div class="col-md-6">
              <input type="text" class="form-control" v-model="crossconnect.soNumber"/>
            </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-row align-items-center">
            <label class="col-md-6">Expiration Date:</label>
            <div class="col-md-6">
              <div class="input-group">
                <DateInput type="date" v-model="crossconnect.termExpirationDate" style="width: 100%"/>
                <div class="input-group-append">
                    <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    <div class="row row-spacing">
      <div class="col-md-6">
        <div class="form-row align-items-center">
            <label class="col-md-6">Service ID:</label>
            <div class="col-md-6">
              <input type="text" class="form-control" v-model="crossconnect.serviceID"/>
            </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-row align-items-center">
            <label class="col-md-6">Type:</label>
            <div class="col-md-6">
              <input type="text" class="form-control" v-model="crossconnect.type" disabled/>
            </div>
        </div>
      </div>
    </div>
    <div class="row row-spacing">
      <div class="col-md-6">
        <div class="form-row align-items-center">
            <label class="col-md-6">Non Recurring Price Excl. VAT:</label>
            <div class="col-md-6 input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">R</span>
              </div>
              <input type="text" class="form-control" v-model="crossconnect.nrcExclVat"/>
            </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-row align-items-center">
            <label class="col-md-6">Monthly Recurring Price Excl. VAT:</label>
            <div class="col-md-6 input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">R</span>
              </div>
              <input type="text" class="form-control" v-model="crossconnect.mrcExclVat"/>
            </div>
        </div>
      </div>
    </div>
    <div class="row row-spacing">
      <div class="col-md-6">
        <div class="form-row align-items-center">
            <label class="col-md-6">Remote end:</label>
            <div class="col-md-6">
              <input type="text" class="form-control" v-model="crossconnect.remoteEnd"/>
            </div>
        </div>
      </div>
    </div>
    <div class="row row-spacing">
      <div class="col-md-12">
        <button class="btn btn-success" @click="$emit('saveSale')" type="button">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import DateInput from '@/components/GeneralComponents/InputControls/DateInput'   

export default {
  props: {
    crossconnect: {
      type: Object,
      required: true
    }
  },
  components: {
    DateInput
  },
  data() {
    return {
      
    };
  },
};
</script>
