<template>
    <div>
        <div class="card card-default">
            <div class="card-header">
                <h4>Rica Registration</h4>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        First names:
                    </div>
                    <div class="col-md-6">
                        <strong>{{ricaDetails.firstNames}}</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        Surname:
                    </div>
                    <div class="col-md-6">
                        <strong>{{ricaDetails.lastName}}</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        Contact number:
                    </div>
                    <div class="col-md-6">
                        <strong>{{ricaDetails.contactNumber}}</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        Email address:
                    </div>
                    <div class="col-md-6">
                        <strong>{{ricaDetails.emailAddress}}</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        ID Type:
                    </div>
                    <div class="col-md-6">
                        <strong>{{ricaDetails.idType}}</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        ID number:
                    </div>
                    <div class="col-md-6">
                        <strong>{{ricaDetails.idNumber}}</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        Physical address:
                    </div>
                    <div class="col-md-6">
                        <strong>{{ricaDetails.ricaAddressLine}}</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <strong>Rica Address</strong>
                    </div>
                    <div class="col-md-6">
    
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        Address type:
                    </div>
                    <div class="col-md-6">
                        <strong>{{ricaDetails.ricaAddress.addressType}}</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        Sub premise:
                    </div>
                    <div class="col-md-6">
                        <strong>{{ricaDetails.ricaAddress.subpremise}}</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        Street number:
                    </div>
                    <div class="col-md-6">
                        <strong>{{ricaDetails.ricaAddress.streetNumber}}</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        Street name:
                    </div>
                    <div class="col-md-6">
                        <strong>{{ricaDetails.ricaAddress.streetName}}</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        Suburb:
                    </div>
                    <div class="col-md-6">
                        <strong>{{ricaDetails.ricaAddress.suburb}}</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        City:
                    </div>
                    <div class="col-md-6">
                        <strong>{{ricaDetails.ricaAddress.city}}</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        Province:
                    </div>
                    <div class="col-md-6">
                        <strong>{{ricaDetails.ricaAddress.province}}</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        Country:
                    </div>
                    <div class="col-md-6">
                        <strong>{{ricaDetails.ricaAddress.country}}</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        Postal Code:
                    </div>
                    <div class="col-md-6">
                        <strong>{{ricaDetails.ricaAddress.postalCode}}</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            ricaDetails: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style>
    
</style>
