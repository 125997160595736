<template>
  <service-screen-layout :parts="parts">
    <template v-slot:details>
      <LiquidLayer2LineDetails
        :liquidLayer2Line="saleDefinition.definition.liquidLayer2Line"
        @saveSale="saveSale"
      />
    </template>
  </service-screen-layout>
</template>

<script>
import SaleFunctions from "@/pages/Services/SaleFunctions";
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import LiquidLayer2LineDetails from "@/components/ObjParts/SingleCaptured/LiquidLayer2LineDetails";
export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    LiquidLayer2LineDetails,
  },
  data() {
    return {
      parts: [
        {
          name: "details",
          icon: "fa fa-info-circle",
          displayName: "Details",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        definition: {
          liquidLayer2Line: {},
        },
      },
    };
  },
  mounted() {
    this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition() {
      const saleNumber = this.$route.params.saleNumber;
      this.getSaleDefinition(saleNumber).then(
        (success) => {
          this.saleDefinition = success.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
    },
    saveSale() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Sale Definition", error);
              }
            );
        }
      });
    },
  },
};
</script>