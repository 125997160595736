<template>
    <div class="mt-4">
        <div class="row row-spacing" v-allowed:view="['STAFF']">
           <div class="col-md-12">
                <button type="button" class="btn btn-success" @click="addCrossConnect()">Add Cross Connect</button>
           </div> 
        </div>
        <div class="row row-spacing">
            <div class="col-md-12">
                <div class="table-responsive table-bordered">
                    <table class="table">
                        <thead>
                            <tr class="dark-row">
                                <th>Service ID</th>
                                <th>Description</th>
                                <th>SO Number</th>
                                <th>Remote End</th>
                                <th>Type</th>
                                <th>MRC Excl VAT</th>
                                <th>Expiration Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody v-if="crossConnects.length > 0">
                            <tr v-for="connect in crossConnects" :key="connect.crossConnectNumber">
                                <td>{{ connect.serviceID }}</td>
                                <td>{{ connect.description }}</td>
                                <td>{{ connect.soNumber }}</td>
                                <td>{{ connect.remoteEnd }}</td>
                                <td>{{ connect.type }}</td>
                                <td>{{ currencyFormat(connect.mrcExclVat) }}</td>
                                <td>{{ dateFormat(connect.termExpirationDate) }}</td>
                                <td class="text-center" v-allowed:view="['TECHNICAL_STAFF']">
                                    <button type="button" class="btn btn-info" @click="editCrossConnect(connect)"><i class="fa fa-edit"></i></button>
                                    <button type="button" class="btn btn-primary" @click="deleteCrossConnect(connect)"><i class="fa fa-trash"></i></button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="8">No Cross Connects added</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <ModifyCrossConnectModal 
            ref="crossConnectModal"
            :title="title"
            :crossConnect="crossConnect"
            :crossConnectTypes="crossConnectTypes"
            @createCrossConnect="createCrossConnect"
            @updateCrossConnect="updateCrossConnect"
        />
    </div>
</template>

<script>
import ModifyCrossConnectModal from '@/components/Admin/Modals/ModifyCrossConnectModal';

    export default {
        components: {
            ModifyCrossConnectModal
        },
        props: {
            crossConnects: {
                type: Array,
                required: true,
            },
            newCrossConnect: {
                type: Object,
                required: true,
            },
            cabinetRental: {
                type: Object,
                required: true,
            },
            crossConnectTypes: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                title: "",
                crossConnect: {},
            }
        },
        methods: {
            addCrossConnect: function() {
                this.title = "Add Cross Connect";
                this.crossConnect = Object.assign({}, this.newCrossConnect);
                this.crossConnect.saleNumber = this.cabinetRental.saleNumber;
                this.crossConnect.serviceID = this.cabinetRental.serviceID;
                this.crossConnect.dataCentreNumber = this.cabinetRental.dataCentreNumber;
                this.$refs.crossConnectModal.open();
            },
            editCrossConnect: function(connect) {
                this.title = "Edit Cross Connect";
                this.crossConnect = Object.assign({}, connect);
                this.$refs.crossConnectModal.open();
            },
            createCrossConnect: function(connect) {
                const hostUrl = this.$config.aimsAPIv2;
                const saleNumber = connect.saleNumber;

                this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/create?commit=true`, connect).then(
                    (response) => {
                        this.$refs.crossConnectModal.$refs.crossConnectModal.isLoading();
                        this.showSuccess("Cross Connect added");
                        this.$emit("getSaleDefinition");
                        this.$refs.crossConnectModal.$refs.crossConnectModal.close();
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error adding Cross Connect", error);
                        this.$refs.crossConnectModal.$refs.crossConnectModal.isLoading();
                    }
                )
            },
            updateCrossConnect: function(connect) {                
                const hostUrl = this.$config.aimsAPI;
                const saleNumber = connect.saleNumber;

                this.$http.put(`${hostUrl}sales/definition/${saleNumber}/obj/update`, connect).then(
                    (response) => {
                        this.$refs.crossConnectModal.$refs.crossConnectModal.isLoading();
                        this.showSuccess("Save success");
                        this.$emit("getSaleDefinition");
                        this.$refs.crossConnectModal.$refs.crossConnectModal.close();
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error updating Cross Connect", error);
                        this.$refs.crossConnectModal.$refs.crossConnectModal.isLoading();
                    }
                );
            },
            deleteCrossConnect: function(connect) {
                this.$swal({
                    title: `Are you sure you want to delete this Cross Connect: ${connect.description}?`,
                    type: "warning",
                    showCancelButton: true,
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.value) {
                        const hostUrl = this.$config.aimsAPI;
                        const saleNumber = connect.saleNumber;
                        
                        this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/delete`, connect).then(
                            (response) => {
                                this.showSuccess("Cross Connect deleted");
                                this.$emit("getSaleDefinition");
                            },
                            (error) => {
                                console.error(error);
                                this.showError("Error deleting Cross Connect", error);
                        });
                    }
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
.dark-row {
    width: 900px !important;
    background-color: #3a3f51;

    th {
        background-color: #3a3f51;
        color: #FFFFFF !important;

        position: sticky;
        top: 0;
        z-index: 2;
        font-size: .95em;
        min-width: calc(100px + 1em);
    }

}
</style>