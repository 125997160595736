<template>
    <div>
        <form novalidate data-vv-scope="workorder">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group row">
              <label class="col-md-4 col-form-label">{{
                this.workOrder.workOrderType
              }}</label>
              <div class="col-md-7">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    readonly
                    v-model="workOrder.workOrderNumber"
                  />
                  <div class="input-group-append">
                    <button
                      type="button"
                      class="btn btn-info border-0"
                      @click="
                        copyText('Work Order ' + workOrder.workOrderNumber)
                      "
                    >
                      <i class="fa fa-clipboard"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group row">
              <label class="col-md-4 col-form-label">Description</label>
              <div class="col-md-7">
                <input
                  type="text"
                  :class="editMode ? 'form-control' : 'form-control-plaintext'"
                  :readonly="!editMode"
                  v-model="workOrder.description"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-form-label">Client Reference</label>
              <div class="col-md-7">
                <input
                  type="text"
                  :class="editMode ? 'form-control' : 'form-control-plaintext'"
                  :readonly="!editMode"
                  v-model="workOrder.customerReference"
                />
              </div>
            </div>
            <div class="form-group row" v-if="isStaff">
              <label class="col-md-4 col-form-label">PO Number</label>
              <div class="col-md-7">
                <input
                  type="text"
                  :class="editMode ? 'form-control' : 'form-control-plaintext'"
                  :readonly="!editMode"
                  v-model="workOrder.purchaseOrderNumber"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-form-label">Ticket References</label>
              <div class="col-md-7">
                <input
                  v-if="combinedTicketRefs"
                  type="text"
                  style="cursor: pointer"
                  v-tooltip="ticketsString"
                  class="form-control-plaintext btn-link"
                  readonly
                  :value="'Multiple tickets found'"
                />
                <input
                  v-else
                  type="text"
                  class="form-control-plaintext"
                  readonly
                  :value="ticketsString"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <label class="col-md-4 col-form-label">Status</label>
              <div class="col-md-7">
                <input
                  type="text"
                  :class="editMode ? 'form-control' : 'form-control-plaintext'"
                  disabled
                  v-model="workOrder.status"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-form-label">Next Action</label>
              <div class="col-md-7">
                <input
                  type="text"
                  class="form-control-plaintext"
                  v-if="!editMode"
                  disabled
                  v-model="workOrder.nextAction"
                />
                <v-select
                  v-else
                  :options="nextActions"
                  v-model="workOrder.nextAction"
                ></v-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-form-label">Next Action Date</label>
              <div class="col-md-7">
                <input
                  type="text"
                  class="form-control-plaintext"
                  v-if="!editMode"
                  disabled
                  :value="dateFormat(workOrder.nextActionDate)"
                />
                <div class="input-group" v-else>
                  <DateInput 
                      type="date" 
                      v-model="workOrder.nextActionDate"
                      style="width: 100%"
                  />
                  <div class="input-group-append">
                      <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row align-items-center" v-if="isStaff">
              <label class="col-md-4 col-form-label">Document Number</label>
              <div class="col-md-7">
                <button
                  v-if="workOrder.documentNumber"
                  @click="
                    $router.push({
                      name: 'documentedit',
                      params: { documentNumber: workOrder.documentNumber },
                    })
                  "
                  type="button"
                  class="btn btn-link pl-0"
                >
                  {{ workOrder.documentNumber }}
                </button>
                <input
                  v-else
                  type="text"
                  class="form-control-plaintext"
                  readonly
                  value="Not invoiced"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
</template>

<script>
import DateInput from "@/components/GeneralComponents/InputControls/DateInput.vue";

export default {
    components: {
      DateInput
    },
    props: {
        workOrder: {
            type: Object,
            required: true
        },
        editMode: {
            type: Boolean,
            required: true
        },
        ticketsString: {
            type: String,
            required: true
        },
        open: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            entryOptions: [
                {
                    name: "Hardware",
                    type: "hardware"
                },
                {
                    name: "Software/Licence",
                    type: "software"
                },
                {
                    name: "Labour",
                    type: "labour"
                },
                {
                    name: "Bulk Labour",
                    type: "bulk_labour"
                },
                {
                    name: "Travel",
                    type: "travel"
                },
                {
                    name: "Outsourced Service",
                    type: "outsourced_service"
                },
            ],
            nextActions: ["COMPLETE", "CANCEL", "INVOICE"]
        }
    },
    computed: {
        isStaff() {
            return this.userContainsPermission(["STAFF"]);
        },
        combinedTicketRefs() {
            if (this.ticketsString.length > 20) {
                return true;
            }

            return false;
        },
    },
    methods: {
        addEntry(option) {                    
            this.$emit('addEntry', option)
        }
    } 
}
</script>
