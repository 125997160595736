<template>
  <div class="row">
    <div class="col-md-8">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template slot="service">
            <form novalidate data-vv-scope="service">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('service.description'),
                    }"
                  >
                    <label>Customer Name</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="description"
                      placeholder="Customer Name"
                      v-model="
                        filledInSaleDefinition.requiredObjs.mimecastmail.description
                      "
                    />
                    <span
                      class="has-error"
                      v-if="errors.has('service.description')"
                      >{{ errors.first("service.description") }}</span
                    >
                  </div>
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('service.domain'),
                    }"
                  >
                    <label>Domain Name</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="domain"
                      placeholder="Domain Name"
                      v-model="
                        filledInSaleDefinition.requiredObjs.mimecastmail.domain
                      "
                    />
                    <span
                      class="has-error"
                      v-if="errors.has('service.domain')"
                      >{{ errors.first("service.domain") }}</span
                    >
                  </div>
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('service.startDate'),
                    }"
                  >
                    <label class="col-form-label">Start Date</label>
                    <div class="input-group">
                      <DateInput
                        type="date"
                        v-model="filledInSaleDefinition.requiredObjs.mimecastmail.startDate"
                        name="startDate"
                        data-vv-as="Start Date"
                        v-validate="'required'"
                        style="width: 100%"
                      />
                      <div class="input-group-append">
                          <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                      </div>
                    </div>
                    <span
                      class="has-error"
                      v-if="errors.has('service.startDate')"
                      >{{ errors.first("service.startDate") }}</span
                    >
                  </div>
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('service.endDate'),
                    }"
                  >
                    <label class="col-form-label">End Date</label>
                    <div class="input-group">
                      <DateInput
                        type="date"
                        v-model="filledInSaleDefinition.requiredObjs.mimecastmail.endDate"
                        name="endDate"
                        data-vv-as="End Date"
                        v-validate="'required'"
                        style="width: 100%"
                      />
                      <div class="input-group-append">
                          <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                      </div>
                    </div>
                    <span
                      class="has-error"
                      v-if="errors.has('service.endDate')"
                      >{{ errors.first("service.endDate") }}</span
                    >
                  </div>
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('service.markup'),
                    }"
                  >
                    <label>Markup</label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        v-validate="'required'"
                        name="markup"
                        placeholder="Markup"
                        v-model="
                          filledInSaleDefinition.requiredObjs.mimecastmail
                            .markup
                        "
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">%</span>
                      </div>
                    </div>
                    <span
                      class="has-error"
                      v-if="errors.has('service.markup')"
                      >{{ errors.first("service.markup") }}</span
                    >
                  </div>
                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
import DateInput from "@/components/GeneralComponents/InputControls/DateInput";
export default {
  components: {
    ServiceWizardLayout,
    DateInput,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "service",
          displayName: "Mimecast Service",
          icon: "fa fa-info",
        },
      ],
      filledInSaleDefinition: {
        requiredObjs: {
          mimecastmail: {},
        },
      },
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
    
  },
  methods: {
    onComplete() {
      this.$swal({
        title:
          "Add " + this.filledInSaleDefinition.requiredObjs.mimecastmail.domain,
        text:
          "Are you sure you want to add " +
          this.filledInSaleDefinition.requiredObjs.mimecastmail.domain +
          "?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Create Service",
      }).then((result) => {
        if (result.value) {
          this.$emit("createSale", this.filledInSaleDefinition);
        }
      });
    },
    validateStep(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
</style>
