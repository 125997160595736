<template>
  <div class="row">
    <div class="col-xl-9 col-lg-12">
      <div class="card card-default">
        <form-wizard
          title=""
          subtitle=""
          @on-complete="onComplete"
          stepSize="sm"
        >
          <!-- internet access -->
          <!-- line -->
          <!-- line order --> 
          <!-- line address -->
          <tab-content
            title="Line"
            icon="fa fa-plug"
            :before-change="() => validateStep('line')"
          >
            <form novalidate data-vv-scope="line">
              <div class="row">
                <div class="form-group col-md-8">
                  <label>Circuit Number *</label>
                  <div
                    class="input-group"
                    :class="{
                      'has-error': errors.has('line.circuitNumber'),
                    }"
                  >
                    <input
                      v-validate="{required: true, regex: /^[A-Z0-9]+(-[A-Z0-9]+)*$/}"
                      type="text"
                      name="circuitNumber"
                      class="form-control"
                      v-model="
                        filledInSaleDefinition.requiredObjs.line
                          .circuitNumber
                      "
                    />
                  </div>
                  <span
                    class="has-error"
                    v-if="errors.has('line.circuitNumber')"
                    >Circuit numbers may only contain capital letters, numbers and hyphens seperating them</span
                  >
                </div>
              </div>
              <div class="row" v-if="filledInSaleDefinition.requiredObjs.lineOrder">
                <div class="form-group col-md-8">
                  <label>Ticket Number</label>
                  <div
                    class="input-group"
                    :class="{
                      'has-error': errors.has('line.circuitNumber'),
                    }"
                  >
                    <input
                      type="text"
                      name="circuitNumber"
                      class="form-control"
                      v-model="filledInSaleDefinition.requiredObjs.lineOrder.orderReference"
                    />
                  </div>
                  <span
                    class="has-error"
                    v-if="errors.has('line.circuitNumber')"
                    >Circuit numbers may only contain capital letters, numbers and hyphens seperating them</span
                  >
                </div>
              </div>
            </form>
          </tab-content>
          <tab-content
            title="Line Address"
            icon="fa fa-globe"
            :before-change="() => validateStep('address')"
          >
            <div class="row">
              <Address
                class="col-xl-12"
                @addressChanged="lineAddressChanged"
                :addressStructure="filledInSaleDefinition.requiredObjs.lineAddress"
                :addressTypes="filledInSaleDefinition.supportingEnums.lineAddress.addressType"
              />
            </div>
          </tab-content>
          <tab-content
            title="Internet Access"
            icon="fa fa-link"
            :before-change="() => validateStep('internet-access')"
          >
            <form novalidate data-vv-scope="internet-access">
              <div class="row">
                <div
                  class="form-group col-md-8"
                  :class="{
                    'has-error': errors.has('internet-access.Network'),
                  }"
                >
                  <label>Network</label>
                  <select
                    v-validate="'required'"
                    name="Network"
                    class="form-control"
                    v-model="
                      filledInSaleDefinition.requiredObjs.internetAccess
                        .networkGroupNumber
                    "
                  >
                    <option
                      :value="networkGroup.networkGroupNumber"
                      v-for="networkGroup in filledInSaleDefinition
                        .selectableOptions.internetNetworkGroup"
                      :key="networkGroup.networkGroupNumber"
                    >
                      {{ networkGroup.description }}
                    </option>
                  </select>
                  <span
                    class="has-error"
                    v-if="errors.has('internet-access.Network')"
                    >{{ errors.first("internet-access.Network") }}</span
                  >
                </div>
              </div>
              <div class="row">
                <div
                  class="form-group col-md-8"
                  :class="{
                    'has-error': errors.has('internet-access.Package'),
                  }"
                >
                  <label>Package</label>
                  <select
                    v-validate="'required'"
                    name="Package"
                    class="form-control"
                    v-model="filledInSaleDefinition.requiredObjs.internetAccess.connectivityPackageNumber"
                    @change="setSelectedPackage(filledInSaleDefinition.requiredObjs.internetAccess.connectivityPackageNumber)"
                  >
                      <option
                        v-for="connectivityPackage in filledInSaleDefinition.selectableOptions.internetConnectivityPackage"
                        :key="connectivityPackage.connectivityPackageNumber"
                        :value="connectivityPackage.connectivityPackageNumber"
                      >
                        {{ connectivityPackage.description }}
                      </option>
                  </select>
                  <span
                    class="has-error"
                    v-if="errors.has('internet-access.Package')"
                    >{{ errors.first("internet-access.Package") }}</span
                  >
                </div>
              </div>
            </form>
          </tab-content>
        </form-wizard>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Address from "@/components/GeneralComponents/Address";
export default {
  components: {
    FormWizard,
    TabContent,
    Address,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filledInSaleDefinition: {
        requiredObjs: {
          internetAccess: {},
          lineAddress: {},
          line: {},
          lineOrder: {}
        },
        supportingEnums: {
          lineAddress: {
            addressType: [],
          },
        },
        selectableOptions: {
          internetNetworkGroup: [],
          internetConnectivityPackage: [],
        },
      },
      shapedPools: [],
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
    
    this.filledInSaleDefinition.requiredObjs.internetAccess.name = "internet";
    const productNumber = this.filledInSaleDefinition.product.productNumber;  

    this.$http.get(this.$config.aimsAPI + "shapedpools?productNumber=" + productNumber)
      .then(
        (response) => {
          this.shapedPools = response.data;
        },
        (error) => {
          this.showError("Error fetching Shaped Pools", error);
          console.error(error);
        }
      );
  },
  methods: {
    packagesForShapedPool({ shapedPoolNumber }) {
      return this.filledInSaleDefinition.selectableOptions.internetConnectivityPackage.filter(
        (c) => c.shapedPoolNumber === shapedPoolNumber
      );
    },
    validateStep(stepName) {
      return this.$validator.validateAll(stepName).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
    setSelectedPackage(connectivityPackageNumber) {
      if (connectivityPackageNumber !== undefined) {
        let selectedPackage = this.filledInSaleDefinition.selectableOptions.internetConnectivityPackage.filter(
          (c) => c.connectivityPackageNumber === connectivityPackageNumber
        );
      
        this.filledInSaleDefinition.requiredObjs.line.orderedDownloadSpeed = selectedPackage[0].downloadSpeed;
        this.filledInSaleDefinition.requiredObjs.line.orderedUploadSpeed = selectedPackage[0].uploadSpeed;
      }      
    },
    onComplete() {
      this.$emit("createSale", this.filledInSaleDefinition);
    },
    lineAddressChanged(address) {
      this.filledInSaleDefinition.requiredObjs.lineAddress = address;
    },
    speedBitsToMbits(value) {
      if (value && value > 0) {
        return value / 1000000;
      } else {
        return 0;
      }
    },
  },
};
</script>