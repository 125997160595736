var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row row-spacing"},[_c('div',{staticClass:"col-md-12"},[(_vm.searchResults.length > 0)?_c('div',{staticClass:"table-responsive table-bordered"},[_c('table',{staticClass:"table table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.searchResults),function(searchResult,index){return _c('tr',{key:index,class:{
              'search-result-error': Object.keys(
                searchResult.metaData
              ).includes('Error'),
            },on:{"click":function($event){return _vm.viewResult(searchResult)}}},[_c('td',{staticClass:"text-nowrap"},[_c('i',{class:_vm.getTypeIcon(searchResult.type)}),_c('span',[_vm._v(" "+_vm._s(searchResult.label))])]),_c('td',[_vm._v(_vm._s(searchResult.result))]),_c('td',_vm._l((Object.keys(searchResult.metaData).filter(
                  (metaKey) => !metaKey.includes('_')
                )),function(metaKey){return _c('div',{key:metaKey},[_c('small',[_c('b',[_vm._v(_vm._s(metaKey)+":")]),_vm._v(" "+_vm._s(searchResult.metaData[metaKey]))])])}),0),_c('td',[(_vm.availableTypes(searchResult.type))?_c('div',{staticClass:"dropdown"},[_c('a',{staticClass:"btn btn-info dropdown-toggle",attrs:{"href":"#","data-toggle":"dropdown","role":"button","id":"dropdownMenuLink","aria-haspopup":"true","aria-expanded":"false"},on:{"click":_vm.toggleDropdown}},[_vm._v(" Options ")]),_c('div',{staticClass:"dropdown-menu"},_vm._l((_vm.options[searchResult.type.toLowerCase()]),function(option){return _c('a',{key:option.name,staticClass:"dropdown-item text-dark",on:{"click":function($event){return _vm.goTo(option, searchResult)}}},[_vm._v(_vm._s(option.name))])}),0)]):_vm._e(),(searchResult.type == 'Sale')?_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.goTo('clientdetail', searchResult)}}},[_vm._v(_vm._s(searchResult.metaData.Client))]):_vm._e()])])}),0)])]):_c('div',[_vm._v("No Results...")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"text-nowrap"},[_c('th',[_vm._v("Type")]),_c('th',[_vm._v("Matched")]),_c('th',[_vm._v("Quick Info")]),_c('th')])])
}]

export { render, staticRenderFns }