<template>
  <service-screen-layout :parts="parts">
    <template v-slot:domain>
      <div class="row row-spacing">
        <div class="col-md-7">
          <div class="form-group row">
            <label class="col-md-4 col-form-label">Domain:</label>
            <div class="col-md-7">
              <input
                type="text"
                class="form-control"
                v-model="saleDefinition.sale.domain.name"
                readonly
                placeholder="Name"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label">Plan:</label>
            <div class="col-md-7">
              <input
                type="text"
                class="form-control"
                v-model="saleDefinition.sale.domain.plan"
                readonly
                placeholder="ID"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label">Renewal Date:</label>
            <div class="col-md-7">
              <div class="input-group">
                <DateInput 
                    type="date" 
                    v-model="renewalDate" 
                    :disabled="!isStaffUser"
                    style="width: 100%"
                />
                <div class="input-group-append">
                    <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-allowed:view="['STAFF']">
            <div class="col-md-12">
              <div class="form-group">
                <button
                  class="btn btn-success"
                  @click="saveSale()"
                  type="button"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <div 
          class="col-md-5" 
          v-if="saleDefinition.details && 
          saleDefinition.details.portal && 
          saleDefinition.details.portal.url.length > 0 && 
          saleDefinition.details.portal.name.length > 0"
        >
          <PortalButton :portalDetails="saleDefinition.details.portal"/>
        </div>
      </div>
    </template>
    <template v-slot:licences>
      <div class="row mt-4">
        <div class="col-md-5">
          <div class="row">
            <label class="col-md-4 col-form-label" style="padding-left:25px">Billing Period:</label>
            <div class="col-md-7">
              <BillingFilter
                :baseArray="saleDefinition.sale.domain.licences"
                 @selectedOption="changeFilter"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row row-spacing">
        <div class="col-md-12">
          <table
            class="table table-bordered"
            v-if="saleDefinition.sale.domain.licences.length > 0"
          >
            <thead>
              <tr>
                <th>Type</th>
                <th>From Date</th>
                <th>To Date</th>
                <th>Exchange Rate</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="licence in filteredList"
                :key="licence.googleWorkspaceDomainLicenceNumber"
              >
                <td>{{ licence.type }}</td>
                <td>{{ dateFormat(licence.validFrom) }}</td>
                <td>{{ dateFormat(licence.validTo) }}</td>
                <td>{{ currencyFormat(licence.exchangeRate) }}</td>
                <td>{{ licence.quantity }}</td>
                <td>{{ currencyFormat(licence.unitPrice) }}</td>
                <td>{{ currencyFormat(licence.amount) }}</td>
              </tr>
            </tbody>
          </table>
          <div v-else class="alert alert-info">
            No Licences loaded for
            {{ saleDefinition.sale.domain.name }}
          </div>
        </div>
      </div>
    </template>
  </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";
import DateInput from "@/components/GeneralComponents/InputControls/DateInput.vue";
import BillingFilter from "@/components/ObjParts/MultipleLoaded/BillingFilter";
import PortalButton from "@/components/ObjParts/SingleLoaded/PortalButton";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    DateInput,
    BillingFilter,
    PortalButton
  },
  watch: {
    saleDefinition: {
      deep: true,
      handler(newSaleDef, oldSaleDef) {
        if (oldSaleDef.sale.description != newSaleDef.sale.description && this.$parent.$refs.serviceTitle) {
          this.$parent.$refs.serviceTitle.reloadSale();
        }
      },
    },
  },
  computed: {
    renewalDate: {
      get() {
        return this.saleDefinition.sale.domain.renewalDate;
      },
      set(date) {
        this.saleDefinition.sale.domain.renewalDate =
          this.dateTimeFormat(date);
      },
    },
    isStaffUser() {
      return this.userContainsPermission(['STAFF']);
    }
  },
  data() {
    return {
      parts: [
        {
          name: "domain",
          icon: "fa fa-cloud",
          displayName: "Domain",
          permissions: ["*"],
        },
        {
          name: "licences",
          icon: "fa fa-id-card",
          displayName: "Licences",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        sale: {
          domain: {
            licences: [],
          },
        },
      },
      filteredList: [],
    };
  },
  mounted() {
    this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition() {
      const saleNumber = this.$route.params.saleNumber;
      this.fetchSaleDefinition(saleNumber).then(
        (response) => {
          this.saleDefinition = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Could not load Sale Definition", error);
        }
      );
    },
    saveSale() {
      this.$http
        .put(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/update",
          this.saleDefinition
        )
        .then(
          () => {
            this.showSuccess("Save Success");
            this.loadSaleDefinition();
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving SaleDefinition", error);
          }
        );
    },
    changeFilter(data) {
      this.filteredList = data;
    },
  },
};
</script>
