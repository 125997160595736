<template>
    <div>
        <div class="card card-default">
            <div class="card-body">
                <div class="row row-spacing">
                    <div class="col-md-12">
                        <TaskHistory :days="15" :taskName="taskName" @tasksRunningChanged="tasksRunningChanged" ref="taskHistory"/>
                    </div>
                </div>
                <hr>
                <div v-if="!isRunningTasks">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Which types of clients would you like to send statements to?</label>
                            <select class="form-control" v-model="clientPaymentType">
                                <option value='__NONE__'>ALL</option>
                                <option value='DEBIT'>DEBIT</option>
                                <option value='INVOICE'>INVOICE</option>
                            </select>
                        </div>
                    </div>
                    <div class="row row-spacing">
                        <div class="col-md-12">
                            <button class="btn btn-primary" @click="sendStatements()">Send Statements</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import TaskHistory from './TaskHistory'

export default {
    components: {
        TaskHistory
    },
    data(){
        return {
            taskName: 'send-statements',
            clientPaymentType: null,
            isRunningTasks: false
        }
    },
    methods: {
        sendStatements(){
            if(!this.clientPaymentType || this.clientPaymentType.length == 0){
                this.showWarning('Please select client payment type');
                return;
            }
            this.$swal({
                title: 'Are you sure you want to send statements to clients?',
                type: 'warning',
                showCancelButton: true,
                cancelButtonColor: '',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.value) {

                    const params = [];
                    if (this.clientPaymentType != '__NONE__') {
                        params.push({
                                name: 'clientPaymentType',
                                value: this.clientPaymentType,
                                type: 'STRING'});
                    }
                    this.$http.post(this.$config.aimsAPI + 'tasks/definitions/run?taskName=' + this.taskName, params).then(
                        () => {
                                this.showSuccess('Send Statements Task Submitted');
                                this.$refs.taskHistory.getTaskHistory();
                            },
                        error => {
                            console.error(error);
                            this.showError("Error Running Send Statements", error);
                        });
                }
            });
        },
        tasksRunningChanged(isTasksRunning){
            this.isRunningTasks = isTasksRunning;
        }
    },
    mounted(){
        this.$root.$emit('SET_PAGE_TITLE', 'Send Statements');
    }
}
</script>