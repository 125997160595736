<template>
  <service-screen-layout :parts="parts">
    <template v-slot:admin>
      <div class="row row-spacing">
        <div class="col-md-7">
          <div class="form-group row">
            <label class="col-md-2 col-form-label">Tenant:</label>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control"
                v-model="saleDefinition.sale.backup.tenant"
                readonly
                placeholder="Tenant"
              />
            </div>
          </div>
        </div>
        <div 
          class="col-md-5" 
          v-if="saleDefinition.details && 
          saleDefinition.details.portal && 
          saleDefinition.details.portal.url.length > 0 && 
          saleDefinition.details.portal.name.length > 0"
        >
          <PortalButton :portalDetails="saleDefinition.details.portal"/>
        </div>
      </div>
    </template>
    <template v-slot:stats>
      <div class="row row-spacing">
        <div class="card-body">
          <div class="col-md-12">
            <div class="table-responsive table-bordered" v-if="saleDefinition.sale.backup.backupUpdate.length > 0">
              <table class="table">
                <thead>
                  <tr>
                    <th>Period</th>
                    <th>Date</th>

                    <th>Protected Users</th>
                    <!-- <th>Unprotected Users</th> -->
                    <th>SharePoint Users</th>
                    <th>Exchange/OneDrive Users</th>
                    
                    <th>Storage</th>
                    <th>Storage Used</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(update, index) in saleDefinition.sale.backup.backupUpdate" :key="index" :class="{'new-table-row': update.isNew}">
                    <td>{{update.period}}</td>
                    <td>{{dateFormat(update.date)}}</td>

                    <td>{{update.protectedUsers}}</td>
                    <!-- <td>{{update.unprotectedUsers}}</td> -->
                    <td>{{update.sharePointUsers}}</td>
                    <td>{{update.outlookUsers}}</td>

                    <td>{{sizeBytesToTb(update.storageSelected)}} TB</td>
                    <td>{{sizeBytesToTb(update.storageUsed)}} TB</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="alert alert-info" v-else>
              No Backup Detail Records Available
            </div>
          </div>
        </div>
      </div>
    </template>
  </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";
import PortalButton from "@/components/ObjParts/SingleLoaded/PortalButton";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    PortalButton
  },
  watch: {
    saleDefinition: {
      deep: true,
      handler(newSaleDef, oldSaleDef) {
        if (oldSaleDef.sale.description != newSaleDef.sale.description && this.$parent.$refs.serviceTitle) {
          this.$parent.$refs.serviceTitle.reloadSale();
        }
      },
    },
  },
  computed: {
    isStaffUser() {
      return this.userContainsPermission(['STAFF']);
    }
  },
  data() {
    return {
      parts: [
        {
          name: "admin",
          icon: "fa fa-circle-user",
          displayName: "Admin",
          permissions: ["*"],
        },
        {
          name: "stats",
          icon: "fa fa-info-circle",
          displayName: "Details",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        sale: {
          backup: {
            backupUpdate: [],
          },
        },
      },
    };
  },
  mounted() {
    this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition() {
      const saleNumber = this.$route.params.saleNumber;
      this.fetchSaleDefinition(saleNumber).then(
        (response) => {
          this.saleDefinition = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Could not load Sale Definition", error);
        }
      );
    },
    saveSale() {
      this.$http
        .put(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/update",
          this.saleDefinition
        )
        .then(
          () => {
            this.showSuccess("Save Success");
            this.loadSaleDefinition();
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving SaleDefinition", error);
          }
        );
    },
    dateFormat(date) {
      return date.split(" ")[0];
    },
  },
};
</script>
