<template>
  <div class="row">
    <div class="col-md-7">
      <div class="form-group row">
        <label class="col-md-4 col-form-label">Circuit Number: </label>
        <div class="col-md-7">
          <input
            type="text"
            class="form-control"
            v-model="diginetLine.circuitNumber"
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 col-form-label">Line Speed: </label>
        <div class="col-md-7">
          <v-select
            :options="[64000, 128000]"
            :get-option-label="getLabel"
            v-model="linespeed"
          ></v-select>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 col-form-label">A-Side: </label>
        <div class="col-md-7">
          <input type="text" class="form-control" v-model="diginetLine.sideA" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 col-form-label">B-side: </label>
        <div class="col-md-7">
          <input type="text" class="form-control" v-model="diginetLine.sideB" />
        </div>
      </div>
      <div class="row" v-allowed:view="['STAFF']">
        <div class="col-md-12">
          <div class="form-group">
            <button
              class="btn btn-success"
              @click="$emit('saveSale')"
              type="button"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    linespeed: {
      get() {
        return this.diginetLine.downloadSpeed;
      },
      set(speed) {
        this.diginetLine.downloadSpeed = this.diginetLine.uploadSpeed = speed;
      },
    },
  },
  props: {
    diginetLine: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getLabel(speed) {
      return this.speed_bitsToKbits(speed) + " kB/s";
    },
  },
};
</script>