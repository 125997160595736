<template>
    <div>
        <loading :active="true" :can-cancel="false"></loading>
    </div>
</template>

<script>
import Loading from "vue-loading-overlay";
export default {
    components: {
        Loading,
    },
    data() {
        return {
            loginCredentials: {
                userName: "",
                password: "",
            },
        }
    },
    created: function() {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        if (code) {
            this.$http.post(this.$config.aimsAPIv2 + "login/oauth2", {code, state}).then(
                (response) => {
                    this.$localStorage.set("aims-token", response.body.token);
                    this.$localStorage.set("uli", btoa(this.loginCredentials.userName));
                    
                    if (this.$localStorage.get("OG_URL")) {
                        this.$router.replace(this.$localStorage.get("OG_URL"));
                    } else {
                        this.$router.replace({
                        name: "dashboard",
                        });
                    }
                },
                (error) => {
                    console.error('Error loggin in with OAuth 2.0:', error);
                    
                    const provider = this.$localStorage.get("prov");
                    this.$localStorage.set("prov-err", `Error occurred attempting to log in with ${provider}`);
                    this.$localStorage.remove("prov"); 

                    this.$router.replace({
                        name: "login",
                    });
                }
            );
        }
    },
    methods: {
    
    },
}
</script>

<style scoped>
    .input-bg {
        background: #ffffff;
    }
</style>
