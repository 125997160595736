<template>
    <Modal
        ref="userManagementModal"
        :title="modalTitle"
        :size="'modal-xl'"
        :saveNeeded="false"
        :dismissNeeded="false"
        :footerNeeded="false"
        @close="close"        
    >
        <div class="row">
            <div class="col-md-12">
                <form-wizard
                    title=""
                    subtitle=""
                    stepSize="md"
                    color="#3a3f51"
                    errorColor="none"
                    ref="userModal"
                    @on-complete="saveUserDetails(selectedUser.user)" 
                >
                    <tab-content 
                        title="User Details"
                        icon="fa fa-user"
                        :before-change="()=>validateStep('user-details')"
                    >
                    <div class="row">
                        <div class="col-md-12" v-allowed:view="['ADMIN']">
                            <div class="form-group mb-0 float-right">
                                <button class="btn btn-success" @click="resetOTP(selectedUser.user.staffUserNumber)">Reset OTP</button>
                            </div>
                        </div>
                    </div>
                    <form data-vv-scope="user-details">
                    <hr />
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Email</label>
                                <input type="text" class="form-control" v-model="selectedUser.user.emailAddress" disabled>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Password</label>
                                <MaskedPasswordInput 
                                    :password="selectedUser.user.password"
                                    :userCanEdit="true"
                                    :showPasswordToggle="true"
                                    @changePassword="changePassword"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="isStaffUserType">
                        <div class="col-md-6">
                            <div class="form-group" :class="{'has-error': errors.has('user-details.Firstnames')}">
                                <label>Firstnames</label>
                                <input type="text" class="form-control" v-model="selectedUser.user.firstNames" name="Firstnames" v-validate="'required'">
                                <span class="has-error" v-if="errors.has('user-details.Firstnames')">{{ errors.first('user-details.Firstnames') }}</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group" :class="{'has-error': errors.has('user-details.Lastname')}">
                                <label>Lastname</label>
                                <input type="text" class="form-control" v-model="selectedUser.user.lastName" name="Lastname" v-validate="'required'">
                                <span class="has-error" v-if="errors.has('user-details.Lastname')">{{ errors.first('user-details.Lastname') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="isStaffUserType">
                        <div class="col-md-6">
                            <div class="form-group" :class="{'has-error': errors.has('user-details.Labour Rate')}">
                                <label>Labour Rate</label>
                                <v-select
                                    ref="labourRateOptions"
                                    class="mb-2"
                                    :options="labourRates"
                                    placeholder="Select Labour rate"
                                    v-model="selectedUser.user.labourRate"
                                    name="Labour Rate" 
                                    v-validate="'required'"
                                    clearable
                                >
                                </v-select>
                                <span class="has-error" v-if="errors.has('user-details.Labour Rate')">{{ errors.first('user-details.Labour Rate') }}</span>
                            </div>
                        </div>
                    </div>
                    <hr />
                    </form>
                    </tab-content>
                    <tab-content 
                        title="Access Rights Management"
                        icon="fa fa-key"
                    >
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <h4>User Access Rights</h4>
                                    <div class="form-group">
                                        <label>Assign Rights</label>
                                        <div class="row" v-if="showRightMessage === true">
                                            <div class="col-md-12">
                                                <p class="badge bg-danger" style="font-size: .95em; padding: 8px 16px"><i class="fa fa-warning mr-2"></i><strong>{{ rightMessage }}</strong></p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-10" :class="{ 'col-md-12' :  rightObj === ''}">
                                                <v-select
                                                    ref="rightsOptions"
                                                    :options="availableUserRights"
                                                    v-model="rightObj"
                                                    label="description"
                                                    v-if="availableUserRights.length > 0"
                                                >
                                                </v-select>
                                            </div>
                                            <div class="col-md-2" v-if="availableUserRights.length > 0 && rightObj != ''">
                                                <button class="btn btn-success float-right" @click="assignRightToUser(rightObj)">Add Right</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group" style="height: 275px; overflow-y: scroll; overflow-x: hidden">
                                        <ul class="list-group" v-if="selectedUser.rights.length > 0">
                                            <li class="list-group-item align-items-center" v-for="(right, name) in selectedUser.rights" :key="name">
                                                <div class="row">
                                                    <div class="d-flex align-items-center justify-content-between" style="width: 93%">
                                                        <div :class="showAdditionalRights(right)">
                                                            <span><i class="fa fa-user-plus mr-2"></i>{{ right.description }}</span>
                                                        </div>
                                                        <div class="d-flex justify-content-around col-md-4 border rounded" v-if="right.options.length > 0" style="padding: .34em 0">
                                                            <div class="d-flex justify-content-between" v-for="option in right.options" :key="option.name">
                                                                <input type="checkbox" class="form-control mr-2" v-model="option.enabled" v-on="option.enabled === false ? { click: () => assignRightToUser(option) } : { click: () => unassignUserRight(option) }" style="width: 15px">
                                                                <strong>
                                                                    {{ option.description }}
                                                                </strong>
                                                            </div>
                                                        </div> 
                                                        <button class="btn btn-primary float-right" @click="unassignUserRight(right)"><i class="fa fa-trash"></i></button> 
                                                    </div>                                         
                                                </div>
                                            </li>
                                        </ul>
                                        <ul class="list-group" v-else-if="availableUserRights.length > 0 && selectedUser.rights.length <= 0">
                                            <li class="list-group-item">
                                                <span><i class="fa fa-user-plus mr-2"></i>Assign rights to user</span>
                                            </li>
                                        </ul>
                                        <ul class="list-group" v-else>
                                            <li class="list-group-item">
                                                <span><i class="fa fa-user-times mr-2"></i>No rights available</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab-content>
                </form-wizard>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
import MaskedPasswordInput from "@/components/GeneralComponents/InputControls/MaskedPasswordInput";
import {FormWizard, TabContent} from 'vue-form-wizard'
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
    components: {
        Modal,
        MaskedPasswordInput,
        FormWizard,
        TabContent
    },
    props: {
        modalTitle: {
            type: String,
            required: true,
        },
        selectedUserType: {
            type: String,
            required: true,
        },
        selectedUser: {
            type: Object,
            required: true,
        },
        labourRates: {
            type: Array,
            required: true,
        },
        availableUserRights: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            rightObj: '',
            showOptionalRights: false,
            rightMessage: 'Assign right in dropdown in order to finish',
            showRightMessage: false,
        }
    },
    computed: {
        isStaffUserType() {
            return this.selectedUserType.toLowerCase() === 'staff';
        }
    },
    methods: {
        open: function() {
            this.$refs.userManagementModal.open();            
        },
        showAdditionalRights(right) {            
            return right.options.length > 0 ? 'col-md-8' : 'col-md-12';
        },
        changePassword: function(data) {
            if (data) {
                this.selectedUser.user.password = data;
            }
        },
        validateStep: function(stepName) {
            return this.$validator.validateAll(stepName).then(result => {  
                if (result) {
                    return true;
                } else {
                    return false;
                }
            });
        },
        saveUserDetails: function(selectedUser) { 
            if (Object.keys(this.rightObj).length !== 0) {
                this.showRightMessage = true
            } else {
                this.$emit('saveUser', selectedUser);
                this.closeModal();
            }
        },
        assignRightToUser: function(rightObj) {
            this.showRightMessage = false
            this.$emit('assignRightToUser', rightObj);
            this.$refs.rightsOptions._data.selectedValue = null;   
            this.rightObj = "";      
        },
        unassignUserRight: function(rightObj) {
            this.$emit('removeAssignedUserRight', rightObj); 
        },
        resetOTP(data) {
            this.$swal({
                title: "Are you sure you want to reset this user's OTP?",
                type: 'warning',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.$http.post(this.$config.aimsAPIv2 + 'users/staff/' + data + '/otp/reset')
                    .then(response => {
                        this.showSuccess('Reset Successful');
                        this.closeModal();
                    },
                    error => {
                        this.showError('Error trying to reset OTP', error)
                        console.error(error);
                    });
                }
            })
        },
        close() {   
            this.errors.clear()
        },
        closeModal: function() {
            if (this.selectedUserType.toLowerCase() === 'staff') {
                this.$refs.labourRateOptions._data.selectedValue = null;
            }

            this.showOptionalRights = false;
            this.optionsIcon = 'fa fa-angle-down';
            this.$refs.userManagementModal.close();
            this.$refs.userModal.reset();
        }
    },
}
</script>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #37bc9b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}

.cursor {
    cursor: pointer;
}
</style>
