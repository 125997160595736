<template>
  <modal 
    :title="
      this.pricePackage.lteBundleNumber || this.pricePackage.lteTopupNumber
        ? 'Edit Price Package'
        : 'Add New Price Package'
    "
    ref="newPriceModal" 
    size="modal-lg" 
    @save="savePrice()" 
    :disable-save="!pricePackage.packageId"
  >
    <div class="row">
      <div class="form-group col-md-7">
        <v-select name="lteProduct" :options="availableLteProducts" 
          label="description" valueProp="classId" v-validate="'required'" class="mb-2" v-model="pricePackage.packageId"
          placeholder="Choose LTE Product"></v-select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label">
          Description
        </label>
        <input disabled type="text" class="form-control"
          v-model="pricePackage.description">
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label">
          Label
        </label>
        <input type="text" class="form-control" :disabled="!pricePackage.packageId"
          v-model="pricePackage.label">
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10"  v-if="pricePackage.lteBundleNumber || pricePackage.lteTopupNumber">
        <label class="control-label"> Expired </label>
        <v-select :options="['true', 'false']" v-model="expired"></v-select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label">
          Size
        </label>
        <div class="input-group">
          <input type="text" v-model="pricePackage.size" :disabled="!pricePackage.packageId" class="form-control">
          <div class="input-group-append">
            <span class="input-group-text">GB</span>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import Modal from '@/components/GeneralComponents/Modal'
  export default {
    components: {
      Modal
    },
    computed: {
      expired: {
        get() {
          return this.pricePackage.expired.toString();
        },
        set(expired) {
          this.pricePackage.expired = expired;
        },
      },
    },
    props: {
      pricePackage: {
        type: Object,
        required: true
      },
      availableLteProducts: {
        type: Array,
        required: true
      },
      supplier: {
        type: String,
        required: true
      }
    },
    methods: {
      savePrice() {
        if (this.pricePackage.lteBundleNumber || this.pricePackage.lteTopupNumber) {
          this.$emit('update-price', this.pricePackage);
        } else {
          this.$emit('save-price', this.pricePackage);
        }
          this.$refs.newPriceModal.close();
      },
      open() {
        this.$refs.newPriceModal.open();
      }
    }
  }

</script>
