<template>
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive table-bordered">
          <table class="table">
            <thead>
              <tr>
                <th>Data Usage</th>
                <th>Period</th>
                <th>Maximum Data Usage</th>
                <th>Total Backup Size</th>
                <th>Backup Over Usage Size</th>
              </tr>
            </thead>
            <tbody v-if="usages.length > 0">
              <tr v-for="usage in usages" :key="usage.solarwindsDataUsageNumber">
                <td>{{ sizeBytesToGb(usage.dataUsage) }} [GB]</td>
                <td>{{ usage.period }}</td>
                <td>{{ sizeBytesToGb(usage.maximumDataUsage) }} [GB]</td>
                <td>{{ sizeBytesToGb(backupPackage.includedStorage) }} [GB]</td>
                <td>{{ backupOverusageSize(usage) }} [GB]</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    usages: {
      type: Array,
      required: true,
    },
    backupPackage: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      period: parseInt(this.getCurrentPeriod()),
    };
  },
  mounted() {
    
  },
  methods: {
    backupOverusageSize: function(usage) {      
      if (this.usages.length > 0) {
        return this.sizeBytesToGb(usage.maximumDataUsage - this.backupPackage.includedStorage);
      }        
    }
  },
};
</script>