<template>
<div class="card card-default">
    <div class="card-header" v-if="showResults">
        <h6>Results for: "{{searchTerm}}"</h6>
        <div class="row row-spacing justify-content-between">
            <div class="col-md-10">
                <div style="cursor: pointer" v-for="(searchItem, index) in supportedSearchList" :key="index" class="badge m-1" @click="filterItem = searchItem" :class="filterItem == searchItem ? 'badge-warning' : 'badge-info'">
                    {{item(searchItem)}}
                </div>
            </div>
            <div class="col-md-2" v-if="item(filterItem) == 'Client'">
                <v-select :options="options" :get-option-label="getLabel" v-model="type"></v-select>
            </div>
        </div>
        <hr>
        <SearchResults :search-results="filteredResults" @search-item-clicked="searchItemClicked" />
    </div>
    <loading v-else active></loading>
</div>
</template>

<script>
import SearchResults from '@/components/Search/SearchResults'
import Loading from "vue-loading-overlay";
export default {
    components: {
        SearchResults,
        Loading
    },
    computed: {
        item: () => (i) => {
            return i.substring(i.lastIndexOf(".") + 1, i.length);
        },
        filteredResults() {
            if (this.filterItem == 'Any') {
                return this.searchResults;
            }

            const results = this.searchResults.filter(result => this.item(this.filterItem) == result.label);

            if (this.type == 'All') {                                
                return results;
            } else if (this.type == 'Active' && this.item(this.filterItem) == 'Client') {
                const activeResults = results.filter((result) => {
                    return result.metaData.Status === this.type;
                });
                return activeResults;
            } else if (this.type == 'Inactive' && this.item(this.filterItem) == 'Client') {
                const inActiveResults = results.filter((result) => {
                    return result.metaData.Status === this.type;
                });
                return inActiveResults;
            }
            return results.filter(r => this.label == r.metaData.Status);
        },
    },
    data() {
        return {
            filterItem: 'Any',
            showResults: false,
            supportedSearchList: [],
            searchResults: [],
            options: ['All', 'Active', 'Inactive'],
            type: 'All',
            searchTerm: '',
            productDefinitionList: '',
        }
    },
    watch: {
        $route({
            query
        }) {
            if (query.searchTerm) {
                this.searchTerm = query.searchTerm;
                this.doSearch(query.searchTerm);
            }
        }
    },
    created() {
        this.$root.$emit('SET_PAGE_TITLE', this.$route.meta.title);
        document.title = this.$route.meta.title;
    },
    mounted() {
        if (this.$route.query.saleNumber) {
            this.$http.get(this.$config.aimsAPI + 'sales/' + this.$route.query.saleNumber)
                .then(response => {
                        this.viewSale(response.data.saleNumber, response.data.clientNumber, response.data.product
                            .productDefinition, true);
                    },
                    error => {
                        this.showError("Error fetching sale", error);
                        console.error(error);
                    })
        } else if (this.$route.query.documentNumber) {
            this.$http.get(this.$config.aimsAPI + 'documents/' + this.$route.query.documentNumber + '/documentlines').then(response => {
                this.$router.push({
                    name: 'documentedit',
                    params: {
                        documentNumber: response.data.documentNumber,
                        clientNumber: response.data.clientNumber
                    }
                });
            })
        } else if (this.$route.query.searchTerm) {
            this.searchTerm = this.$route.query.searchTerm;
            this.doSearch(this.$route.query.searchTerm);
        } else {
            this.showResults = true;
        }
    },
    methods: {
        getLabel(label) {
            return 'Show ' + label;
        },
        searchItemClicked(result) {
            this.$root.productDefinitions.forEach(productItem => {
                return this.productDefinitionList += productItem.product;
            });
            let isIncludedInProductDefinition = this.productDefinitionList.includes(result.metaData._productDefinition);
            if (result.metaData._isSold) {
                if (result.metaData._productDefinitionType === 'OLD' && isIncludedInProductDefinition) {
                    this.viewSale(result.metaData._saleNumber, result.metaData._clientNumber, result.metaData._productDefinition, false)
                } else if (result.metaData._productDefinitionType === 'OLD' && isIncludedInProductDefinition != true) {
                    this.viewSaleWithOldProduct(result.metaData._saleNumber); 
                } else {
                    this.viewSale(result.metaData._saleNumber, result.metaData._clientNumber, result.metaData._productDefinition, false)
                }
                return;
            }
            switch (result.type) {
                case "Client":
                case "Reseller":  
                    //remove this after dashboard reveal
                    if (this.$config.enableDashboard) {
                        this.$router.push({
                            name: 'searchclient',
                            params: {
                                clientNumber: result.key
                            }
                        });
                    } else {
                        this.$router.push({
                            name: 'clientdetail',
                            params: {
                                clientNumber: result.key
                            }
                        });
                    }
                    break;
                case "Sale":
                    if (result.metaData._productDefinitionType === 'OLD') {
                        this.viewSaleWithOldProduct(result.key);
                    } else {
                        this.viewSale(result.key, result.metaData._clientNumber, result.metaData._productDefinition, false)
                    }
                    break;
                case "Person":                    
                    this.$router.push({ name: 'clientusers', params: { 'clientNumber': result.metaData._linkedClientNumbers, personNumber: result.key }});
                    break;
                case "StaffUser":
                case "ResellerUser":
                case "ClientUser":
                    this.$router.push({
                        name: 'usermanagement',
                        params: {
                            userNumber: result.key,
                            keyName: result.keyName,
                            userType: result.metaData._userType.replace('User', '')
                        }
                    });
                    break;
                case "Document":
                    this.$router.push({
                        name: 'documentedit',
                        params: {
                            documentNumber: result.key,
                            clientNumber: result.metaData._clientNumber
                        }
                    });
                    break;
                case "WorkOrder":
                    this.$router.push({
                        name: 'workorderdetails',
                        params: {
                            clientNumber: result.metaData._clientNumber,
                            workOrderNumber: result.key
                        }
                    });
                    break;
                case "Product":
                    this.$router.push({
                        name: "productdetail",
                        params: {
                        productNumber: result.key,
                        },
                    });
                    break;
                case "PublicIpv4ReverseZone":
                case "PublicIpv4ReverseRecord":
                    this.$router.push(`reversednsrecordipv4?ipv=IPv4&id=${result.metaData._zone}`)
                    break;
                case "PublicIpv6ReverseZone":
                case "PublicIpv6ReverseRecord":
                    this.$router.push(`reversednsrecordipv6?ipv=IPv6&id=${result.metaData._zone}`)
                    break;
                case "LiquidCustomer":
                    this.$router.push({
                            name: 'searchclient',
                            params: {
                                clientNumber: result.metaData._clientNumber
                            }
                    });
                    break;
                default:
                    this.showWarning('Could not open view for ' + result.type)
            }
        },
        getFilters(results) {
            this.supportedSearchList = [];
            var uniqueTypes = [];
            for (let i = 0; i < results.length; i++) {
                if (uniqueTypes.indexOf(results[i].label) === -1 && results[i].label !== '') {
                    uniqueTypes.push(results[i].label);
                }
            }

            this.supportedSearchList = uniqueTypes;            
            this.supportedSearchList.unshift('Any');
        },

        doSearch(searchTerm) {
            this.showResults = false;
            this.filterItem = "Any";
            let query = Object.assign({}, this.$route.query);
            delete query.searchTerm;
            this.$router.replace({
                query
            });
            if (this.filterItem == 'Any') {
                this.$http.get(this.$config.aimsAPI + "search/any?term=" + searchTerm)
                    .then(response => {
                        this.getFilters(response.data);
                        this.searchResults = response.data;
                    },
                    error => {
                        console.error(error);
                        this.showError("Error During Search", error);
                    }
                ).finally(() => {
                    this.showResults = true;
                });
            } else {
                this.$http.get(this.$config.aimsAPI + "search/specific?objClass=" + this.filterItem + "&term=" + searchTerm)
                    .then(response => {
                        this.getFilters(response.data);
                        this.searchResults = response.data;                        
                    },
                    error => {
                        console.error(error);
                        this.showError("Error During Search", error);
                    }
                ).finally(() => {
                    this.showResults = true;
                });
            }
        },
        viewSaleWithOldProduct(saleNumber) {
            this.$swal({
                title: 'Redirect',
                text: 'Open sale in AIMS Staff portal ?',
                type: 'info',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.value) {
                    this.openNewWindow('SALE', saleNumber);
                }
            });
        },
        viewSale(saleNumber, clientNumber, productDefinition, mustReplaceRoute) {
            const productRoute = this.getProductDefinition(productDefinition);
            if (productRoute == -1) {
                this.showWarning('Could not find view for Sale Product ' + productDefinition)
            } else {
                if (mustReplaceRoute) {
                    this.$router.replace({
                        name: productRoute.routeName,
                        params: {
                            clientNumber: clientNumber,
                            saleNumber: saleNumber
                        }
                    });
                } else {
                    this.$router.push({
                        name: productRoute.routeName,
                        params: {
                            clientNumber: clientNumber,
                            saleNumber: saleNumber
                        }
                    });
                }
            }
        },
    }
}
</script>

<style>

</style>
