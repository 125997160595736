<template>
  <div class="row row-spacing">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>VLAN Name</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                v-model="networkDetails.name"
              />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>VLAN Number</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                v-model="networkDetails.vlan"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Contention Ratio</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                v-model="dynamicContentionRatio"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="col-md-6" v-if="networkDetails.rateLimit != 0">
          <div class="form-group">
            <label>Bandwidth</label>
            <div class="input-group">
              <div class="form-control" style="background-color: #edf1f2">{{ speedBitsToMbits(dynamicBandwidth) }} Mbps</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-spacing" v-allowed:view="['STAFF']">
        <div class="col-md-2">
            <div class="form-group">
                <button type="button" @click="saveNetworkDetails()" class="btn btn-success">Save</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    networkDetails: {
      type: Object,
      required: true,
    },
    contentionRatio: { 
        type: String,
        required: true,
    }
  },
  computed: {
    dynamicContentionRatio() {
        return this.contentionRatio ? this.contentionRatio: 'No Contention Ration available';
    },
    dynamicBandwidth() {
        return this.networkDetails.rateLimit  ? this.networkDetails.rateLimit: 'No Rate limit available';
    }
  },
  methods: {
    saveNetworkDetails: function() {
        this.$emit('saveSale') 
    },
    speedBitsToMbits(value) {
      if (value && value > 0) {
        return value / 1000000;
      } else {
        return 0;
      }
    },
  },
};
</script>
