<template>
  <ServiceScreenLayout :parts="parts">
        <template v-slot:mplsL2tpLink>
            <MplsL2tpLink v-model="saleDefinition.sale.mplsL2tpLink" :vpns="saleDefinition.meta.mplsL2tpLink.children.vpn.partMeta.options" @loadSaleDefinition="loadSaleDefinition"/>
        </template>
        <template v-slot:vpnAccount>
            <RadiusSessions :sessions="saleDefinition.sale.mplsL2tpLink.l2tpAccount.l2tpSessions" cpeAddressName="Caller ID" cpeAddressField="callerId" />
        </template>
        <template v-slot:internetAccount>
            <div v-if="saleDefinition.sale.internetAccount.realm">
                <OpenserveAccount v-model="saleDefinition.sale.internetAccount"/>
                <RadiusSessions :sessions="saleDefinition.sale.internetAccount.internetSessions" cpeAddressName="Caller ID" cpeAddressField="callerId" />
            </div>
            <div v-else class="col-md-12">
                <div class="row">
                    <div class="col-md-6">
                        <label>No Internet Account</label>            
                    </div>    
                </div>
                <div class="row row-spacing">
                    <div class="col-md-6">
                        <button class="btn btn-info" type="button" @click="addInternetAccount()">Create Internet Account</button>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:line>
            <ConnectivityLine 
                v-if="saleDefinition.sale.line" 
                v-allowed:view="['STAFF','CLIENT','RESELLER', 'SYSTEM']" 
                :line="saleDefinition.sale.line" 
                @saveSale="saveSale"

            />
            <div v-else>
                No line account
            </div>
        </template>
        <template v-slot:setup>
            <div v-if="saleDefinition.sale.routerConfig">
                <RouterConfig v-allowed:view="['STAFF']" :routerConfig="saleDefinition.sale.routerConfig"
                    :saleNumber="saleDefinition.sale.saleNumber" @routerConfigSaved="routerConfigSavedHandler" @reloadSale="loadSaleDefinition"/>
                <ServiceOnboarding v-allowed:view="['STAFF']" :saleNumber="saleDefinition.sale.saleNumber"
                    :canSave="Object.keys(saleDefinition.sale.routerConfig).length > 0 && saleDefinition.sale.routerConfig.objKey != 0"
                    :cantSaveReason="'Router Config Needs to be captured'" />
            </div>
            <div v-else>
                No router config
            </div>
        </template>
  </ServiceScreenLayout>
</template>

<script>
import ServiceScreenLayout from '@/layouts/ServiceScreenLayout'
import SaleFunctions from '@/pages/Services/SaleFunctions'
import MplsL2tpLink from '@/components/ObjParts/SingleCaptured/MplsL2tpLink'
import ConnectivityLine from '@/components/ObjParts/SingleCaptured/ConnectivityLine'
import OpenserveAccount from '@/components/ObjParts/SingleCaptured/OpenserveAccount'
import RadiusSessions from '@/components/ObjParts/MultipleLoaded/RadiusSessions'

export default {
    extends: SaleFunctions,
    components:{
        ServiceScreenLayout,
        MplsL2tpLink,
        OpenserveAccount,
        RadiusSessions,
        ConnectivityLine
    },
    data(){
        return {
            parts: [
                {
                    name: 'mplsL2tpLink',
                    icon: 'fa fa-link',
                    displayName: 'MPLS L2TP',
                    permissions: ['*']
                },
                {
                    name: 'vpnAccount',
                    icon: 'fa fa-user-circle',
                    displayName: 'VPN Account Sessions',
                    permissions: ['*']
                },
                {
                    name: 'internetAccount',
                    icon: 'fa fa-globe',
                    displayName: 'Internet Account',
                    permissions: ['*']
                },
                {
                    name: 'line',
                    icon: 'fa fa-link',
                    displayName: 'Line',
                    permissions: ['*']
                },
                {
                    name: 'setup',
                    icon: 'fa fa-cogs',
                    displayName: 'Setup',
                    permissions: ['*']
                }
            ],
            saleDefinition: {
                meta: {
                    mplsL2tpLink: {
                        children: {
                            vpn: {
                                partMeta: {
                                    options: []
                                }
                            }
                        }
                    }
                },
                sale: {
                    mplsL2tpLink: {
                        l2tpAccount: {
                            l2tpSessions: [],
                            l2tprealm: {},
                            p2pIpv4Network: {},
                            routedIpv4Network: {}
                        }
                    },
                    account: {
                        sessions: [],
                        realm: {}
                    },
                    internetAccount: {
                        internetSessions: [],
                        realm: {}
                    }
                }
            },
            createInternetAccount: {
                profile:'INTERNET',
                class: 'za.co.adept.aims.protocol.l2tp.L2tpAccount',
                saleNumber: ""
            },
            saleNumber: null,
        }
    },
    mounted(){
        this.saleNumber = this.$route.params.saleNumber;
        this.loadSaleDefinition();
    },
    methods: {
        loadSaleDefinition() {
            this.fetchSaleDefinition(this.saleNumber).then(success => {
                this.saleDefinition = success.data;                              
            }, error => {
                console.error(error);
                this.showError("Error Fetching Sale Definition", error);
            });
        },
        saveSale() {
            this.$validator.validateAll().then(result => {
                if (result) {
                    this.$http.put(this.$config.aimsAPI + "sales/definition/" + this.saleNumber +
                        "/update", this.saleDefinition)
                    .then(
                        response => {
                            this.showSuccess("Save Success");
                            this.loadSaleDefinition();
                        },
                        error => {
                            console.error(error);
                            this.showError("Error Saving Openserve Account", error);
                        }
                    );
                }
            });
        },
        routerConfigSavedHandler(routerConfig) {
            this.saleDefinition.sale.routerConfig = routerConfig;
            this.saveSale();
        },
        addInternetAccount(){
            this.createInternetAccount.saleNumber = this.saleDefinition.sale.saleNumber;
            return this.$http.post(
                this.$config.aimsAPI + "sales/definition/" + this.saleDefinition.sale.saleNumber + "/obj/create",
                                this.createInternetAccount).then(
                    (response) => {
                        this.showSuccess("Internet Account Added Successfully");
                        this.loadSaleDefinition();
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error Adding Internet Account", error);
                    }
                );
                            
        }
    }
}
</script>

<style>

</style>