<template>
  <div class="row">
    <div class="col-xl-8 col-lg-12">
      <div class="card card-default">
        <form-wizard
          title=""
          subtitle=""
          @on-complete="onComplete"
          stepSize="sm"
        >
          <tab-content
            title="Configuration"
            :before-change="() => validateStep('configuration')"
          >
            <form novalidate data-vv-scope="configuration">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('configuration.username'),
                    }"
                  >
                    <label class="control-label">User Name *</label>
                    <input
                      type="text"
                      name="username"
                      v-validate="'required'"
                      class="form-control"
                      v-model="
                        filledInSaleDefinition.requiredObjs.configuration
                          .username
                      "
                    />
                    <span
                      class="has-error"
                      v-if="errors.has('configuration.username')"
                      >{{ errors.first("configuration.username") }}</span
                    >
                  </div>
                </div>
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{ 'has-error': errors.has('configuration.server') }"
                  >
                    <label class="control-label">Server *</label>
                    <v-select
                      name="server"
                      placeholder="Select Server"
                      v-validate="'required'"
                      label="serverHostname"
                      valueProp="cPanelServerNumber"
                      v-model="
                        filledInSaleDefinition.requiredObjs.configuration
                          .cPanelServerNumber
                      "
                      :options="
                        filledInSaleDefinition.selectableOptions.cPanelServer
                      "
                    ></v-select>
                    <span
                      class="has-error"
                      v-if="errors.has('configuration.server')"
                      >{{ errors.first("configuration.server") }}</span
                    >
                  </div>
                </div>
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('configuration.package'),
                    }"
                  >
                    <label class="control-label">Package *</label>
                    <v-select
                      name="package"
                      placeholder="Select Package"
                      v-validate="'required'"
                      label="description"
                      valueProp="cPanelPackageNumber"
                      v-model="
                        filledInSaleDefinition.requiredObjs.configuration
                          .cPanelPackageNumber
                      "
                      :options="
                        filledInSaleDefinition.selectableOptions.cPanelPackage
                      "
                    ></v-select>
                    <span
                      class="has-error"
                      v-if="errors.has('configuration.package')"
                      >{{ errors.first("configuration.package") }}</span
                    >
                  </div>
                </div>
              </div>
            </form>
          </tab-content>
        </form-wizard>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  computed: {},
  components: {
    FormWizard,
    TabContent,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filledInSaleDefinition: {
        selectableOptions: {},
        requiredObjs: {
          configuration: {},
        },
      },
    };
  },
  mounted: function () {
    this.filledInSaleDefinition = this.emptySaleDefinition;
  },
  methods: {
    validateStep: function (stepName) {
      return this.$validator.validateAll(stepName).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
    onComplete() {
      this.$emit("createSale", this.filledInSaleDefinition);
    },
  },
};
</script>