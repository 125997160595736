<template>
  <div>
    <div class="card card-default">
      <div class="card-body">
        <ul class="nav nav-pills nav-fill" role="tablist">
          <li class="nav-item" @click="groupSelected = 'Clients'">
            <a
              class="nav-link active"
              id="clients-tab"
              data-toggle="tab"
              href="#clients"
              role="tab"
              aria-controls="clients"
              aria-selected="true"
              ><i class="fa fa-building"></i> Clients</a
            >
          </li>
          <li class="nav-item" @click="groupSelected = 'Sales'">
            <a
              class="nav-link"
              id="sales-tab"
              data-toggle="tab"
              href="#sales"
              role="tab"
              aria-controls="sales"
              aria-selected="true"
              ><i class="fa fa-money"></i> Sales</a
            >
          </li>
          <li class="nav-item" @click="groupSelected = 'Work Orders'">
            <a
              class="nav-link"
              id="work-orders-tab"
              data-toggle="tab"
              href="#work-orders"
              role="tab"
              aria-controls="work-orders"
              aria-selected="true"
              ><i class="fa fa-briefcase"></i> Work Orders</a
            >
          </li>
          <li class="nav-item" @click="groupSelected = 'Products'">
            <a
              class="nav-link"
              id="product-specific-tab"
              data-toggle="tab"
              href="#product-specific"
              role="tab"
              aria-controls="product-specific"
              aria-selected="true"
              ><i class="fa fa-archive"></i> Product Specific</a
            >
          </li>
          <li class="nav-item" @click="groupSelected = 'Miscellaneous'">
            <a
              class="nav-link"
              id="miscellaneous-tab"
              data-toggle="tab"
              href="#miscellaneous"
              role="tab"
              aria-controls="miscellaneous"
              aria-selected="true"
              ><i class="fa fa-archive"></i> Miscellaneous</a
            >
          </li>
        </ul>
        <div class="row row-spacing">
          <div class="col-md-6">
            <input
              class="form-control"
              v-model="searchAdhocReportText"
              placeholder="Search"
            />
          </div>
        </div>
        <div class="row row-spacing">
          <div class="col-md-12">
            <div class="table-responsive table-bordered">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Report Name</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="report in filteredAdhocReports"
                    :key="report.className"
                  >
                    <td>
                      <a
                        class="btn-link"
                        href="javascript:void(0)"
                        @click="openGenerateReportModal(report)"
                        >{{ report.name }}</a
                      >
                    </td>
                    <td>
                      <small>{{ report.description }}</small>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-default">
      <div class="card-header">
        <h3>Generated Reports</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <input
              class="form-control"
              v-model="searchCannedReportText"
              placeholder="Search"
            />
          </div>
        </div>
        <div class="row row-spacing">
          <div class="col-md-12">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th>Report Name</th>
                  <th>Description</th>
                  <th>Created</th>
                  <th>Download Count</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="report in filteredCannedReports"
                  :key="report.cannedReportNumber"
                >
                  <td>
                    <a
                      href="javascript:void(0)"
                      @click="downloadCannedReport(report)"
                      >Download</a
                    >
                  </td>
                  <td>{{ report.name }}</td>
                  <td>
                    <small>{{ report.description }}</small>
                  </td>
                  <td>
                    {{ dateTimeFormat(report.created) }}
                  </td>
                  <td>{{ report.downloadCount }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <modal
      :title="adhocReportSelected.report.name"
      ref="generateReportModal"
      save-text="Done"
      @save="generateReport()"
    >
      <div
        class="form-group"
        v-for="reportParam in adhocReportSelected.params"
        :key="reportParam.name"
      >
        <label :class="{ 'has-error': errors.has(reportParam.label) }">{{
          reportParam.label
        }}</label>
          <DateInput
            v-if="reportParam.type == 'DATE'"
            value-type="DD/MM/YYYY"
            v-model="reportParam.value"
          />
          <input
            v-else-if="reportParam.type == 'STRING'"
            v-model="reportParam.value"
            v-validate="reportParam.required ? 'required' : ''"
            :name="reportParam.name"
            v-mask="{ casing: 'upper' }"
            class="form-control"
          />
          <input
            v-else-if="reportParam.type == 'INTEGER'"
            v-model="reportParam.value"
            v-validate="reportParam.required ? 'required' : ''"
            :name="reportParam.name"
            v-mask="{ casing: 'upper' }"
            class="form-control"
          />
          <toggle-button
              v-else-if="reportParam.type == 'BOOLEAN'"
              v-model="reportParam.value"
              :name="reportParam.name"
              :width="100"
              :height="30"
              class="text"
              :color="{ checked: '#7DCE94', unchecked: '#82C7EB' }"
              :labels="{
                checked: 'True',
                unchecked: 'False',
              }"
          />
          <div v-else-if="
            reportParam.type == 'OBJSELECT'
            || reportParam.type == 'ENUM'
            || reportParam.type == 'BILLINGPERIOD'
            || reportParam.type == 'STRINGSELECT'">
            <multiselect
              v-if="reportParam.options"
              v-model="reportParam.value"
              :options="reportParam.options"
              :name="reportParam.name"
              v-validate="'required'"
              label="description"
              placeholder="Select"
            ></multiselect>
            <small v-else>Loading selectable items...</small>
          </div>

          <strong style="color: red" v-else
            >Param type '{{ reportParam.type }}' not supported - BUG</strong
          >
          <span class="has-error" v-if="errors.has(reportParam.name)">{{
            errors.first(reportParam.name)
          }}</span>
      </div>
    </modal>
  </div>
</template>

<script>
import DateInput from "@/components/GeneralComponents/InputControls/DateInput.vue";
import Modal from "@/components/GeneralComponents/Modal";
export default {
  components: {
    DateInput,
    Modal,
  },
  computed: {
    filteredAdhocReports() {
      let filteredAdhoc = this.adhocReports;
      if (this.groupSelected) {
        filteredAdhoc = this.adhocReports.filter(
          (r) => r.group === this.groupSelected
        );
      }
      if (this.searchAdhocReportText && this.searchAdhocReportText.length > 0) {
        filteredAdhoc = this.adhocReports.filter(
          (r) =>
            r.name
              .toLowerCase()
              .indexOf(this.searchAdhocReportText.toLowerCase()) != -1
        );
      }
      return filteredAdhoc;
    },
    filteredCannedReports() {
      if (
        this.searchCannedReportText &&
        this.searchCannedReportText.length > 0
      ) {
        return this.cannedReports.filter(
          (r) =>
            r.name
              .toLowerCase()
              .indexOf(this.searchCannedReportText.toLowerCase()) != -1
        );
      } else {
        return this.cannedReports;
      }
    },
  },
  data() {
    return {
      adhocReports: [],
      cannedReports: [],
      adhocReportSelected: {
        report: {},
        params: []
      },
      groupSelected: "Clients",
      searchCannedReportText: null,
      searchAdhocReportText: null,
    };
  },
  created() {
    this.$http.get(this.$config.aimsAPI + "reporting/adhoc").then(
      (response) => {
        this.adhocReports = response.data;
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching Adhoc Reports", error);
      }
    );
    this.$http.get(this.$config.aimsAPI + "reporting/canned").then(
      (response) => {
        this.cannedReports = response.data;
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching Canned Reports", error);
      }
    );
  },
  mounted() {
    this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
  },
  methods: {
    openGenerateReportModal(report) {
      this.adhocReportSelected.report = report;
      this.fetchParams(this.adhocReportSelected.report.class);
      
      this.$refs.generateReportModal.open();
    },
    fetchParams(reportName) {
      this.$http.get(this.$config.aimsAPI + "reporting/adhoc/parameters/" + reportName)
        .then(
          (response) => {
            this.adhocReportSelected.params = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Parameters", error);
          }
        );
    },
    generateReport() {
      this.adhocReportSelected.params.forEach((param) => {
        
        if (
          param.type != "OBJSELECT" &&
          param.type != "ENUM" &&
          param.type != "BILLINGPERIOD") {
          if (param.value && param.value.value == "") {
            param.value = {};
          }
        }
      });
      
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$refs.generateReportModal.isLoading();
          let params = {};

          for (let i = 0; i < this.adhocReportSelected.params.length; i++) {
            if (this.adhocReportSelected.params[i].options) {
              params[this.adhocReportSelected.params[i].name] =  this.adhocReportSelected.params[i].value.value;
            } else if (this.adhocReportSelected.params[i].type == 'DATE') {
              let date = new Date(this.adhocReportSelected.params[i].value);
              params[this.adhocReportSelected.params[i].name]  = date;
            } else {
              params[this.adhocReportSelected.params[i].name] = this.adhocReportSelected.params[i].value;
            }
          }

          this.$http
            .post(
              this.$config.aimsAPI + "reporting/adhoc/download?reportClassName=" + this.adhocReportSelected.report.class,
              params, { responseType: "arraybuffer", }
            )
            .then(
              function (response) {
                this.$refs.generateReportModal.close();
                const contentType = response.headers.get("Content-Type");
                let blob = new Blob([response.data], {
                  type: contentType,
                });
                const fileName = response.headers
                  .get("Content-Disposition")
                  .split("filename = ")[1];
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                link.dispatchEvent(
                  new MouseEvent("click", {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                  })
                );
              },
              function (error) {
                this.showError("Report", error);
              }
            )
            .finally(() => {
              this.$refs.generateReportModal.isLoading();
            });
        }
      });
    },
    downloadCannedReport(report) {
      this.$http
        .get(
          this.$config.aimsAPI +
            "reporting/canned/" +
            report.storedCannedReportNumber +
            "/download",
          {
            responseType: "arraybuffer",
          }
        )
        .then(
          function (response) {
            report.downloadCount++;
            const contentType = response.headers.get("Content-Type");
            let blob = new Blob([response.data], {
              type: contentType,
            });
            const fileName = response.headers
              .get("Content-Disposition")
              .split("filename = ")[1];
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.dispatchEvent(
              new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
              })
            );
          },
          function (error) {
            console.error(error);
            this.showError("Error Downloading Report", error.data);
          }
        );
    },
  },
};
</script>

<style scoped>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #f05050 !important;
}
.nav-pills .nav-link {
  background-color: #f3f2ee;
}
.nav-link {
  color: rgba(0, 0, 0, 0.6);
}
</style>
