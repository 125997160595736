<template>
  <div class="row">
    <div class="col-md-8">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template v-slot:backup>
            <form novalidate data-vv-scope="backup">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div 
                    class="form-group" 
                    :class="{'has-error': errors.has('backup.officeTenant')}"
                  >
                    <label>Tenant</label>
                    <input 
                        name="officeTenant" 
                        v-validate="'required'" 
                        class="form-control" 
                        v-model="filledInSaleDefinition.requiredObjs.backup.tenant"
                    />
                    <span class="has-error" v-if="errors.has('backup.officeTenant')">
                        {{errors.first('backup.officeTenant')}}</span>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
export default {
  components: {
    ServiceWizardLayout,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "backup",
          displayName: "Office 365 Backup",
        },
      ],
      filledInSaleDefinition: {
        requiredObjs: {
            
        },
        selectableOptions: {

        },
        supportingEnums: {

        },
      },
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
  },
  methods: {
    onComplete() {
      this.$emit("createSale", this.filledInSaleDefinition);
    },
    validateStep(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>
