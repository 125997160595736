<template>
    <div class="col-md-12">
        <div class="row row-spacing">
            <h4>FTP Credentials</h4>
        </div>
        <div class="row row-spacing">
            <button class="btn btn-info" @click="addWebsiteAdmin" :disabled="website.websiteAdmins.username">Add FTP User</button>
            <!-- <button class="btn btn-info" @click="refreshWebsiteAdmin">Refresh</button> -->
        </div>
        <div class="row row-spacing">
            <div class="table-responsive table-bordered">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Directory</th>
                            <th v-allowed:view="['STAFF']"></th>
                        </tr>
                    </thead>
                    <tbody v-if="website.websiteAdmins.length > 0"> 
                        <tr v-for="websiteadmin in website.websiteAdmins" :key="websiteadmin.websiteAdminNumber"> 
                            <td>{{ websiteadmin.username }}</td>
                            <td>{{ websiteadmin.directory }}</td>
                            <td class="text-center" v-allowed:view="['STAFF']">
                                <button class="btn btn-info" @click="editWebsiteAdmin(websiteadmin)"><i class="fa fa-edit"></i></button>
                                <button class="btn btn-primary" @click="deleteWebsiteAdmin(websiteadmin)"><i class="fa fa-trash"></i></button>
                            </td>
                        </tr>
                   </tbody>
                   <tbody v-else>
                    <tr>
                        <td colspan="2">No Ftp users to display</td>
                    </tr>
                </tbody>
                </table>

            </div>
        </div>
        <AddWebHostingWebsiteAdminModal 
            ref="addWebsiteAdminModal"
            :newWebsiteAdmin="newWebsiteAdmin"
            :site="website.websiteName"
            @createWebsiteAdmin="createWebsiteAdmin"
        />
        <ModifyWebHostingWebsiteAdminModalVue 
            ref="editWebsiteAdminModal"
            :selectedAdmin="selectedAdmin"
            @saveAdmin="saveWebsiteAdmin"
        />
    </div>
</template>

<script>
import AddWebHostingWebsiteAdminModal from '@/components/Admin/Modals/AddWebHostingWebsiteAdminModal';
import ModifyWebHostingWebsiteAdminModalVue from '@/components/Admin/Modals/ModifyWebHostingWebsiteAdminModal';

export default {
    props: {
        website: {
            type: Object,
            required: true,
        },
        newWebsiteAdmin: {
            type: Object,
            required: true
        },
    },
    components: {
        AddWebHostingWebsiteAdminModal,
        ModifyWebHostingWebsiteAdminModalVue
    },
    data() {
        return {
            selectedAdmin: {},
        }
    },
    methods: {
        addWebsiteAdmin: function() {
            this.$refs.addWebsiteAdminModal.open();
        },
        createWebsiteAdmin: function(data) {
            const hostUrl = this.$config.aimsAPI;
            const saleNumber = this.$route.params.saleNumber;
            this.$refs.addWebsiteAdminModal.$refs.addWebsiteAdminModal.isLoading();

            data.websiteNumber = this.website.websiteNumber
            this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/create`, data).then(
                (response) => {
                    this.showSuccess("Successfully created FTP user");
                    this.$emit("loadSaleDefinition");
                },
                (error) => {
                    this.showError("Error adding FTP user", error);
                    console.error(error);
                }
            ).finally(() => {
                this.$emit("loadSaleDefinition");
                this.$refs.addWebsiteAdminModal.$refs.addWebsiteAdminModal.isLoading();
                this.$refs.addWebsiteAdminModal.$refs.addWebsiteAdminModal.close();
            });  
        },
        editWebsiteAdmin: function(data) {
            this.selectedAdmin = this.deepCopyObject(data);
            this.$refs.editWebsiteAdminModal.open();
        },
        saveWebsiteAdmin: function(data) {
            const hostUrl = this.$config.aimsAPI;
            const saleNumber = this.$route.params.saleNumber;

            this.$refs.editWebsiteAdminModal.$refs.editWebsiteAdminModal.isLoading();

            this.$http.put(`${hostUrl}sales/definition/${saleNumber}/obj/update`, data).then(
                (response) => {
                    this.showSuccess("Successfully updated FTP user");
                },
                (error) => {
                    this.showError("Error updating FTP user", error);
                    console.error(error);
                }
            ).finally(() => {
                this.$emit("loadSaleDefinition");
                this.$refs.editWebsiteAdminModal.$refs.editWebsiteAdminModal.isLoading();
                this.$refs.editWebsiteAdminModal.$refs.editWebsiteAdminModal.close();
            });  
        },
        deleteWebsiteAdmin: function(data) {
            this.$swal({
                title: "Are you sure you want to delete this user?",
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    const hostUrl = this.$config.aimsAPI;
                    const saleNumber = this.$route.params.saleNumber;

                    this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/delete/`, data).then(
                        (response) => {
                            this.showSuccess(response.data.message);
                            this.$emit("loadSaleDefinition");
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error deleting FTP user", error);
                        }
                    );
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>

</style>