<template>
  <div>
    <slot>
      <div class="row row-spacing mb-2">
        <div class="col-md-auto">
          <button
            v-if="userEditRight"
            type="button"
            @click="
              pricePackage = deepCopyObject(emptyPackage);
              $refs.modifyPackageModal.open();
            "
            class="btn btn-info"
          >
            Add New Package
          </button>
        </div>
      </div>
    </slot>
    <div
      class="table-responsive table-bordered"
      v-if="connectivityPackages.length > 0"
    >
      <table class="table" ref="table">
        <thead>
          <tr class="text-nowrap">
            <th>Description</th>
            <th>Label</th>
            <th>Bandwidth</th>
            <th>NRC (excl. VAT)</th>
            <th>MRC (excl. VAT)</th>
            <th>MRC (incl. VAT)</th>
            <!-- <th>Term</th> -->
            <th>Expired</th>
            <th>#</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            :class="{ 'bg-primary': connectivityPackage.expired }"
            v-for="connectivityPackage in connectivityPackages"
            :key="connectivityPackage.dataCentreInternetPackageNumber"
          >
            <td>
              {{ connectivityPackage.description }}
            </td>
            <td>
              {{ connectivityPackage.label }}
            </td>
            <!-- <td>
              <span class="pull-right"
                >{{ speedBitsToMbits(connectivityPackage.downloadSpeed) }} /
                {{ speedBitsToMbits(connectivityPackage.uploadSpeed) }}
                Mbit/s</span
              >
            </td> -->
            <td class="wd-md">{{ speed_bitsToMbits(connectivityPackage.bandwidth) }} Mbit/s</td>
            <td class="text-nowrap">
              {{
                getSetupFee(
                  connectivityPackage.datacentreinternetpackageprices,
                  period
                )
              }}
            </td>
            <td>
              {{
                getPrice(connectivityPackage.datacentreinternetpackageprices, period)
              }}
              <span
                v-if="userEditRight"
                style="cursor: pointer"
                @click="openPriceModal(connectivityPackage)"
                v-tooltip="'Edit Row Price'"
                class="pull-right"
              >
                <em class="fa fa-edit fa-fw text-green"></em>
              </span>
            </td>
            <td class="text-nowrap">
              {{
                getPrice(
                  connectivityPackage.datacentreinternetpackageprices,
                  period,
                  true
                )
              }}
            </td>
            <!-- <td class="text-nowrap">
              {{ connectivityPackage.contractTerm }}
            </td> -->
            <td>
              <span
                class="badge"
                :class="
                  connectivityPackage.expired ? 'badge-warning' : 'badge-green'
                "
                >{{ connectivityPackage.expired }}
              </span>
            </td>
            <td>{{ connectivityPackage.dataCentreInternetPackageNumber }}</td>
            <td class="text-center">
              <button
                v-if="userEditRight"
                v-tooltip="'Edit Package'"
                @click="editPackage(connectivityPackage)"
                type="button"
                class="btn btn-sm btn-info m-1"
              >
                <em class="fa fa-edit fa-fw"></em>
              </button>
              <button
                v-if="userEditRight"
                v-tooltip="'Delete Package'"
                @click="deletePackage(connectivityPackage)"
                type="button"
                class="btn btn-sm btn-primary"
              >
                <em class="fa fa-trash fa-fw"></em>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="alert alert-info">No Prices Available to Display</div>
    <ModifyDataCentrePackageModal
      ref="modifyPackageModal"
      :pricePackage="pricePackage"
      :contractTerms="contractTerms"
      @packageAdded="packageAdded"
      @packageEdited="$emit('get-connectivity-packages')"
    />
    <AddPriceModal
      ref="addPriceModal"
      :row-price="rowPrice"
      :new-price="newPrice"
      @addPrice="addPrice"
      @pricesAdded="$emit('get-connectivity-packages')"
    />
  </div>
</template>

<script>
import PriceFunctions from "@/components/Admin/PriceFunctions";
import ModifyDataCentrePackageModal from "@/components/Admin/Modals/ModifyDataCentrePackageModal";
import AddPriceModal from "@/components/Admin/Modals/AddPriceModal";
export default {
  extends: PriceFunctions,
  props: {
    connectivityPackages: {
      type: Array,
      required: true,
    },
    productNumber: {
      type: Number,
      required: true,
    },
    emptyPackage: {
      type: Object,
      required: true,
    },
    userEditRight: {
      type: Boolean,
      required: true
    },

  },
  components: {
    ModifyDataCentrePackageModal,
    AddPriceModal,
  },
  data() {
    return {
      period: this.getCurrentPeriod(),
      rowPrice: [],
      contractTerms: [],
      pricePackage: {},
      newPrice: {},
    };
  },
  mounted() {
    // this.$http.get(this.$config.aimsAPI + "shapedpools").then(
    //   (response) => {
    //     this.shapedPools = response.data;
    //   },
    //   (error) => {
    //     this.showError("Error fetching Shaped Pools", error);
    //     console.error(error);
    //   }
    // );
    // this.$http.get(this.$config.aimsAPI + "pricing/contractterms").then(
    //   (response) => {
    //     this.contractTerms = response.data;
    //   },
    //   (error) => {
    //     this.showError("Error fetching Contract Terms", error);
    //     console.error(error);
    //   }
    // );
  },
  methods: {
    getLabel({ name, location }) {
      return name + " - " + location;
    },
    addNewPackage(serviceType) {
      this.pricePackage = this.deepCopyObject(this.emptyPackage);
      this.pricePackage.type = serviceType;
      this.$refs.modifyPackageModal.open();
    },
    editPackage(connectivityPackage) {
      this.pricePackage = this.deepCopyObject(connectivityPackage);
      this.$refs.modifyPackageModal.open();
    },
    packageAdded(connectivityPackage) {
      connectivityPackage.datacentreinternetpackageprices = [];
      // connectivityPackage.shapedpool = this.shapedPools.find(
      //   (sp) => sp.shapedPoolNumber == connectivityPackage.shapedPoolNumber
      // );
      this.connectivityPackages.push(connectivityPackage);
      this.openPriceModal(connectivityPackage);
    },
    setNewPrice() {
      this.newPrice = this.deepCopyObject(
        this.emptyPackage.datacentreinternetpackageprice
      );
    },
    openPriceModal(connectivityPackage) {
      this.setNewPrice();
      this.rowPrice = this.deepCopyObject(
        connectivityPackage.datacentreinternetpackageprices
      );
      this.newPrice.dataCentreInternetPackageNumber =
        connectivityPackage.dataCentreInternetPackageNumber;
      this.$refs.addPriceModal.open();
    },
    addPrice(newPrice) {
      this.rowPrice.forEach((price) => {
        if (price.validUntil == 0) {
          if (newPrice.validFrom % 100 > 1) {
            price.validUntil = newPrice.validFrom - 1;
          } else {
            price.validUntil = newPrice.validFrom - 100 + 11;
          }
        }
      });
      this.rowPrice.push(newPrice);
      this.setNewPrice();
      this.newPrice.dataCentreInternetPackageNumber =
        newPrice.dataCentreInternetPackageNumber;
    },
    deletePackage(connectivityPackage) {
      this.$swal({
        title: "Are you sure you want to delete this price package?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$http
            .delete(
              this.$config.aimsAPI +
                "pricing/pricepackages/" +
                connectivityPackage.objKey +
                "?objClassName=" +
                connectivityPackage.class
            )
            .then(
              (response) => {
                this.showSuccess(response.data.message);
                this.$emit("get-connectivity-packages");
              },
              (error) => {
                this.showError("Error Deleting Price Package", error);
              }
            );
        }
      });
    }
  },
};
</script>
