<template>
  <div>
    <div class="card card-default">
      <div class="card-body">
        <div class="row">
          <div class="form-group col-md-6">
            <input type="text" placeholder="Enter Domain or IP Address" v-model="domain" @keydown.enter="doWhois()" class="form-control">
          </div>
          <div class="col-md-6">
            <button class="btn btn-info" @click="doWhois()">
              <span><i class="fa fa-stack-overflow"></i></span>
              Query
            </button>
          </div>
        </div>
        <pre v-if="whois">
          {{ whois }}
        </pre>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        domain: '',
        whois: null
      }
    },
    methods: {
      doWhois() {
        this.$http.get(this.$config.aimsAPI + 'system/tools/whois?domain=' + this.domain)
          .then(response => {
            this.whois = response.data;
          }, error => {
            console.error(error);
            this.showError("Error getting whois lookup", error)            
          })
      }
    },
    created() {
      this.$root.$emit('SET_PAGE_TITLE', this.$route.meta.title);
    }
  }

</script>