<template>
    <modal ref="addRoutedIPv4AddressModal" :title="'Add Routed IPv4 Address'" @save="addRoutedAddress">
        <div class="row">
            <div class="col-md-12">
                <v-select :options="maskOptions" :get-option-label="getLabel" placeholder="Select Mask" v-model="shortMask">
                </v-select>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/GeneralComponents/Modal.vue'

export default {
    components: {
        Modal
    },
    data() {
        return {
            maskOptions: [28, 29, 30],
            shortMask: 0
        }
    },
    methods: {
        open: function() {
            this.$refs.addRoutedIPv4AddressModal.open();
        },
        addRoutedAddress: function() {
            this.$emit('addRoutedIPv4Address', this.shortMask);
        },
        getLabel: function(mask) {
            return `/${mask}`;
        },
    }
}
</script>
