<template>
    <modal 
        :title="'Edit Website Admin'"
        ref="editWebsiteAdminModal"
        @save="saveAdmin(selectedAdmin)"
    >
        <div class="row">
            <div class="form-group col-md-12">
                <label class="control-label">Username:</label>
                <input type="text" class="form-control" v-model="selectedAdmin.username"/>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label class="control-label">Password:</label>
                <PasswordInput
                    :password="selectedAdmin.password"
                    :userCanEdit="true"
                    @changePassword="changePassword"
                />
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label class="control-label">Directory:</label>
                <input type="text" class="form-control" v-model="selectedAdmin.directory"/>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/GeneralComponents/Modal';
import PasswordInput from "@/components/GeneralComponents/InputControls/PasswordInput";

export default {
    components: {
        Modal,
        PasswordInput
    },
    props: {
        selectedAdmin: {
            type: Object,
            required: true
        }
    },
    methods: {
        open: function() {
            this.$refs.editWebsiteAdminModal.open();
        },
        changePassword: function(data) {
            if (data) {
                this.selectedAdmin.password = data;
            }
        },
        saveAdmin: function(data) {
            this.$emit("saveAdmin", data);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>