<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card card-default">
                <service-wizard-layout
                    title=""
                    subtitle=""
                    :parts="parts"
                    :onComplete="onComplete"
                    :validateStep="validateStep"
                >
                <template v-slot:sladetails>
                    <form novalidate data-vv-scope="sladetails">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group" :class="{'has-error': errors.has('sladetails.description')}">
                                    <label>Description</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="description"
                                        v-model="filledInSaleDefinition.requiredObjs.serviceLevelAgreement.description"
                                        v-validate="'required'" 
                                    />
                                    <span class="has-error" v-if="errors.has('sladetails.description')">
                                        {{errors.first('sladetails.description')}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group" :class="{'has-error': errors.has('sladetails.price')}">
                                    <label>Price (excl. VAT)</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="price"
                                        v-model="filledInSaleDefinition.requiredObjs.serviceLevelAgreement.priceExclVat"
                                        v-validate="'required'" 
                                    />
                                    <span class="has-error" v-if="errors.has('sladetails.price')">
                                        {{errors.first('sladetails.price')}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group" :class="{'has-error': errors.has('sladetails.sale')}">
                                    <label>Service Supported</label>
                                    <v-select
                                        :options="sales"
                                        placeholder="Select a sale"
                                        name="sale"
                                        :get-option-label="getLabel"
                                        v-model="filledInSaleDefinition.requiredObjs.serviceLevelAgreement.supportedSaleNumber"
                                        valueProp="saleNumber"
                                        searchable
                                        clearable
                                        v-validate="'required'" 
                                    >
                                    </v-select>
                                    <span class="has-error" v-if="errors.has('sladetails.sale')">
                                        {{errors.first('sladetails.sale')}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </form>
                </template>
                </service-wizard-layout>
            </div>
        </div>
    </div>
</template>

<script>
import ServiceWizardLayout from '@/layouts/ServiceWizardLayout';

export default {
    components: {
        ServiceWizardLayout
    },
    props: {
        emptySaleDefinition: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            clientNumber: null,
            sales: [],
            parts: [
                {
                    name: "sladetails",
                    displayName: "Support SLA Details",
                    icon: "fa-solid fa-file-contract"
                }
            ],
            filledInSaleDefinition: {
                requiredObjs: {
                    serviceLevelAgreement: {}
                }
            }
        }
    },
    mounted() {        
        this.clientNumber = this.$route.params.clientNumber;
        this.filledInSaleDefinition = this.emptySaleDefinition;                
        this.getClientSales();
    },
    methods: {
        getClientSales() {
            const hostUrl = this.$config.aimsAPI;

            this.$http.get(`${hostUrl}sales/client/${this.clientNumber}`).then(
                (response) => {
                    this.sales = response.data;                    
                },
                (error) => {
                    this.showError("Error fetching client sales", error);
                    console.error(error);
                }
            )
        },
        onComplete() {
            this.filledInSaleDefinition.requiredObjs.serviceLevelAgreement.supportedSaleNumber = parseInt(this.filledInSaleDefinition.requiredObjs.serviceLevelAgreement.supportedSaleNumber);
            this.$emit("createSale", this.filledInSaleDefinition);
        },
        validateStep(scope) {
            return this.$validator.validateAll(scope).then((result) => {
                if (result) {
                    return true;
                } else {
                    return false;
                }
            });
        },
        getLabel(data) {
            return `${data.description} - ${data.product.description}`;
        },
    }
}
</script>
