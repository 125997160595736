import { defineStore } from "pinia"

export const StatementState = defineStore("StatementState", {
    state: () => ({
        
        startDate: '',
        endDate: '',
    }),
    actions: {
        initStartDate() {
            if (this.startDate == '') {
                var date = new Date();
                var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
                this.startDate = dateString.substring(0,7) + "-01";
            }
        }
    }
});