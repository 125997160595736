<template>
    <div class="col-md-8">
        <div class="row">
            <h4>Tenant Details</h4>
        </div>
        <div class="form-group row">
            <label>Account Number:</label>
            <input type="text" class="form-control" placeholder="AWS Account Number" v-model="saleDefinition.sale.awsTenant.awsAccountNumber"/>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            saleDefinition: {
                type: Object,
                required: true
            }
        }
    }
</script>