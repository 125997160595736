<template>
    <span v-tooltip="poptext" class="customBtn fa-solid fa-fw fa-triangle-exclamation"></span>
</template>

<script>
export default {
  props: {
    poptext: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
    .customBtn {
        position: absolute;
        left: -1em;
        height: 25px;
        width: 25px;
        text-align: center;
        padding: 5px 0;
        font-size: 18px;
        line-height: 0.885;
        margin-top: 3px;
        color: #ff8800;
        float: right;
    }

    .customBtn:hover {
        cursor: default;
    }
</style>
