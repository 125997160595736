<template>
  <modal title="Add New Package" ref="newPriceModal" @save="savePricePackage()">
    <div class="row">
      <div class="form-group col-md-12">
        <label class="control-label">
          Description
        </label>
        <input type="text" class="form-control" v-model="newPricePackage.description"
              disabled placeholder="Description will be created when saved.">
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-12">
        <label class="control-label"> Label </label>
        <input
          type="text"
          class="form-control"
          v-model="newPricePackage.label"
          placeholder="Additional text shown with the Description"
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-12">
        <label class="control-label">
          Shortmask
        </label>
        <v-select :options="masks" :get-option-label="getLabel" placeholder="Select Mask" v-model="newPricePackage.shortMask"></v-select>
      </div>
    </div>
  </modal>
</template>

<script>
  import Modal from '@/components/GeneralComponents/Modal'
  export default {
    props: {
      newPricePackage: {
        type: Object,
        required: true
      }
    },
    components: {
      Modal
    },
    data() {
      return {
        masks: [32, 30, 29, 28, 27, 26, 25]
      }
    },
    methods: {
      open() {
        this.$refs.newPriceModal.open();
      },
      getLabel(mask) {
        return '/' + mask;
      },
      savePricePackage() {
        this.$refs.newPriceModal.isLoading();
        this.$http.post(this.$config.aimsAPI + 'pricing/pricepackages/save', this.newPricePackage)
          .then(response => {
              this.$refs.newPriceModal.close();
              this.showSuccess('Successfully Added Package');
              this.$emit("packageAdded", response.data);
          }, error => {
              this.showError("Error Saving Pricing", error);
              console.error(error);
          }).finally(() => {
            this.$refs.newPriceModal.isLoading();
          });
      }
    }
  }

</script>

<style>

</style>
