<template>
  <ServiceScreenLayout :parts="parts">
    <template v-slot:mplsL2tpLink>
      <MplsL2tpLink
        v-model="saleDefinition.sale.mplsL2tpLink"
        :vpns="saleDefinition.meta.mplsL2tpLink.children.vpn.partMeta.options"
      />
    </template>
  </ServiceScreenLayout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";
import MplsL2tpLink from "@/components/ObjParts/SingleCaptured/MplsL2tpLink";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    MplsL2tpLink,
  },
  data() {
    return {
      parts: [
        {
          name: "mplsL2tpLink",
          icon: "fa fa-link",
          displayName: "MPLS L2TP",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        meta: {
          mplsL2tpLink: {
            children: {
              vpn: {
                partMeta: {
                  options: [],
                },
              },
            },
          },
        },
        sale: {
          mplsL2tpLink: {
            l2tpAccount: {
              l2tprealm: {},
              p2pIpv4Network: {},
              routedIpv4Network: {},
            },
          },
        },
      },
    };
  },
  mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition() {
      this.fetchSaleDefinition(this.saleNumber).then(
        (success) => {
          this.saleDefinition = success.data;
          
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
    },
  },
};
</script>

<style>
</style>