<template>
  <div class="card-body">
    <div class="row">
      <div class="col-md-11">
        <div class="form-group row">
          <label class="col-md-4 col-form-label">Label: </label>
          <div class="col-md-7">
            <slot name="label"></slot>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-4 col-form-label">VLAN: </label>
          <div class="col-md-7">
            <input
              type="text"
              class="form-control"
              v-model="metroConnectionMpls.virtualLan"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-4 col-form-label">Price ex VAT: </label>
          <div class="col-md-7">
            <input
              type="text"
              class="form-control"
              v-model="metroConnectionMpls.serviceCostExVat"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-allowed:view="['STAFF']">
      <div class="col-md-12">
        <div class="form-group">
          <button
            class="btn btn-success pull-right"
            @click="$emit('saveSale')"
            type="button"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    metroConnectionMpls: {
      type: Object,
      required: true,
    },
  },
};
</script>