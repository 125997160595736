<template>
    <modal
        :title="this.pricePackage.apnSimsPackageNumber ? 'Edit SIMs Package' : 'Add New SIM Package'"
        ref="modifySimsPriceModal"
        @save="saveSimsPricePackage"
    >
        <div class="row">
            <div class="form-group col-md-12">
                <label>Description </label>
                <input type="text" class="form-control" disabled v-model="pricePackage.description"/>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label>Label </label>
                <input type="text" class="form-control" v-model="pricePackage.label"/>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label>Maximum Number of SIMs</label>
                <input type="text" class="form-control" v-model="pricePackage.maxNumberOfSims"/>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-10" v-if="pricePackage.apnSimsPackageNumber">
                <label class="control-label"> Expired </label>
                <v-select :options="['true', 'false']" v-model="expired"></v-select>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/GeneralComponents/Modal';

export default {
    components: {
        Modal
    },
    props: {
        pricePackage: {
            type: Object,
            required: true,
        },
    },
    computed: {
        expired: {
            get() {
                return this.pricePackage.expired.toString();
            },
            set(expired) {
                this.pricePackage.expired = expired;
            },
        },
    },
    methods: {
        open: function() {
            this.$refs.modifySimsPriceModal.open();
        },
        saveSimsPricePackage: function() {     
            this.$emit("saveSimsPricePackage", this.pricePackage);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>