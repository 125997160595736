<template>
    <modal title="Add IP Network Address" ref="newIpAddressModal" @save="saveAddedNetworkIpAddress()">
        <div class="row">
            <div class="form-group col-md-12">
                <label class="control-label">
                    Type
                </label>
                <v-select
                    name="network"
                    :placeholder="'Select address type'"
                    v-model="newNetworkAddress.type"
                    :options="options"
                    clearable
                    ref="addressType"
                    >
                </v-select>
            </div>
        </div>
        <div class="row" v-if="userEnterAddress">
            <div class="form-group col-md-12">
                <label class="control-label">
                    IP Address
                </label>
                <input
                    class="form-control"
                    v-model="newNetworkAddress.address"
                    name="address"
                    :placeholder="'Insert IP Address'"
                    ref="ipAddress"
                />
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label class="control-label">
                    Short Mask
                </label>
                <div v-if="newNetworkAddress.type == 'IPV4'">
                    <v-select
                    :placeholder="`Select Short Mask`"
                    v-model="newNetworkAddress.shortMask"
                    :options="shortMaskIpv4"
                    clearable
                    ref="shortMask"
                    >
                </v-select>
                </div>
                <div v-else>
                    <v-select
                    :placeholder="`Select Short Mask`"
                    v-model="newNetworkAddress.shortMask"
                    :options="shortMaskIpv6"
                    clearable
                    ref="shortMask"
                    >
                </v-select>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/GeneralComponents/Modal'

export default {
    props: {
        userEnterAddress: {
            type: Boolean,
            required: false,
        },
    },
    components: {
      Modal
    },
    data() {
        return {
            newNetworkAddress: {
                shortMask: '',
                type: '',
            },
            options: ['IPV4', 'IPV6'],
            shortMaskIpv4: [25, 26, 27, 28, 29, 30, 32],
            shortMaskIpv6: [64],
        }
    },
    computed: {

    },
    methods: {
        open: function() {
            this.$refs.newIpAddressModal.open();
            this.newNetworkAddress = {
                class: "za.co.adept.aims.classes.service.HostedNetworkAddress",
            }
        },
        saveAddedNetworkIpAddress: function() {
            this.$refs.newIpAddressModal.isLoading();
            this.$emit('addIpNetworkAddress', this.newNetworkAddress);
            this.$refs.newIpAddressModal.close();
            setTimeout(this.clearInputs(), 5000);
        },
        clearInputs: function() {
            this.$refs.addressType._data.selectedValue = null;
            this.$refs.shortMask._data.selectedValue = null;
            this.$refs.ipAddress._data.selectedValue = null;
        }
    },
}
</script>
