<template>
  <div class="provider-logo text-center" :style="logoContainerStyle">
    <img
      :src="providerLogo"
      class="img-fluid"
      v-for="providerLogo in getProviderLogos()"
      :key="providerLogo"
      :style="logoImgStyle"
    />
  </div>
</template>

<script>
export default {
  props: {
    providerName: {
      type: String,
      required: true,
    },
    logoContainerStyle: {
      type: String,
      required: false,
    },
    logoImgStyle: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      logoBasePath: "/img/services/",
      providerLogos: {
        Rain: "lte/rain.png",
        CellC: "lte/cellc.png",
        Axxess: "lte/axxess.png",
        MTN: "lte/mtn.png",
        Telkom: "lte/telkom.png",
        InternetSolutions: "lte/ignite.png",
        Openserve: "adsl/openserve.png",
        Frogfoot: "ftth/frogfoot.png",
        Linteg: "ftth/linteg.png",
        Octotel: "ftth/octotel.png",
        SADV: "ftth/sadv.png",
        LinkAfrica: "ftth/linkafrica.png",
        Microsoft: "cloudservices/microsoft.png",
        LiquidTelecom: "fttb/liquid.png",
        DarkFibreAfrica: "fttb/dfa.png",
        Vumatel: "ftth/vumatel.png",
        VOConnect: "businesswireless/voconnect.png",
        AfricaDataCentres: "providers/africadatacentres.png",
        Teraco: "providers/teraco.png",
        CX3: "voiceservices/3CX-LOGO.png",
        ICTGlobe: "ftth/ICT-GLOBE-LOGO.png",
        Wanatel: "voiceservices/WANATEL-LOGO.png",
        Vodia: "voiceservices/VODIA-LOGO.png",
        Backspace: "backspace/BACKSPACE-LOGO.png",
        Zimbra: "cloudservices/ZIMBRA-LOGO.png",
        Digicloud: "cloudservices/DIGICLOUD-LOGO.jpg",
        Google: "cloudservices/GOOGLE-LOGO.png",
        Mimecast: "mimecast/MIMECAST-LOGO.png",
        SolarWinds: "solarwinds/solarwinds-logo.png",
        Sectigo: "cloudservices/SECTIGO-LOGO.png",
        Hexonet: "cloudservices/HEXONET-LOGO.png",
        Default: "default.png",
      },
    };
  },
  methods: {
    getProviderLogos() {
      let providerName = this.providerName.split(" ").join("");
      
      if (providerName === "3CX") { 
        providerName = "CX3";
      }

      let logos = [];
      if (providerName && providerName != "") {
        if (providerName.indexOf("/") != -1) {
          //multiple
          const providerNames = providerName.split("/");
          providerNames.forEach((n) => {
            if (this.providerLogos[n]) {
              logos.push(this.logoBasePath + this.providerLogos[n]);
            } else {
              logos.push(this.logoBasePath + this.providerLogos.Default);
            }
          });
        } else {
          //single
          if (this.providerLogos[providerName]) {
            logos.push(this.logoBasePath + this.providerLogos[providerName]);
          } else {
            logos.push(this.logoBasePath + this.providerLogos.Default);
          }
        }
      }
      return logos;
    },
  },
};
</script>

<style>
.provider-logo {
  max-width: 120px;
}
.provider-logo > img {
  width: 60%;
}
</style>
