<template>
    <modal title="Edit Firewall Rule" ref="editFirewallRuleModal" size="modal-lg" @save="saveFirewallRule(firewall)">
        <div class="row">
            <div class="form-group col-md-6">
                <label class="control-label">Network</label>
                <v-select
                    ref="hostedNetwork"
                    name="Client Hosted Networks"
                    :options="clientHostedNetworks"
                    v-model="firewall.hostedNetworkNumber"
                    :get-option-label="getLabel"
                    :placeholder="'Select Hosted Network'"
                    valueProp="hostedNetworkNumber"
                    clearable
                >
                </v-select>
            </div>
            <div class="form-group col-md-6">
                <label class="control-label">Access</label>
                <v-select
                    ref="accessType"
                    name="Access"
                    :options="accessArray"
                    v-model="firewall.access"
                    :placeholder="'Select Access Type'"
                    :get-option-label="getLabel"
                    valueProp="name"
                    clearable
                >
                </v-select>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6">
                <label class="control-label">Protocol</label>
                <v-select
                    ref="protocolType"
                    name="Protocol"
                    :options="protocolArray"
                    v-model="firewall.protocol"
                    placeholder="Select Protocol Type"
                    :get-option-label="getLabel"
                    valueProp="name"
                    clearable
                >
                </v-select>
            </div>
            <div class="form-group col-md-6">
                <label class="control-label">Src Address</label>
                <input 
                    ref="srcAddress"
                    type="text" 
                    class="form-control" 
                    v-model="firewall.srcAddress" 
                    placeholder="Enter Src Address..."
                >
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6">
                <label class="control-label">Src Mask</label>
                <v-select
                    ref="srcNetmask"
                    name="Src Mask"
                    :options="masks"
                    v-model="firewall.srcNetmask"
                    placeholder="Select Src Mask"
                    :get-option-label="getLabel"
                    valueProp="subnetMask"
                    clearable
                >
                </v-select>
            </div>
            <div class="form-group col-md-6">
                <label class="control-label">Dst Address</label>
                <input 
                    ref="dstAddress"
                    type="text" 
                    class="form-control" 
                    v-model="firewall.dstAddress" 
                    placeholder="Enter Dst Address..."
                >
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6">
                <label class="control-label">Dst Mask</label>
                <v-select
                    ref="dstNetmask"
                    name="Dst Mask"
                    :options="masks"
                    v-model="firewall.dstNetmask"
                    placeholder="Select Dst Mask"
                    :get-option-label="getLabel"
                    valueProp="subnetMask"
                    clearable
                >
                </v-select>
            </div>

            <div class="form-group col-md-6">
                <label class="control-label">Port/Group Name</label>
                <input 
                    ref="port"
                    type="text" 
                    class="form-control" 
                    v-model="firewall.port" 
                    placeholder="Enter Port/Group Name..."
                >
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/GeneralComponents/Modal'

export default {
    props: {
         clientHostedNetworks: {
            type: Array,
            required: true
        },
        accessArray: {
            type: Array,
            required: true
        },
        protocolArray: {
            type: Array,
            required: true
        },
        firewall: {
            type: Object,
            required: true
        }
    },
    components: {
        Modal
    },
    data() {
        return {
            masks: [
                {
                    subnetMask: "255.255.255.255",
                    name: "/32"
                },
                {
                    subnetMask: "255.255.255.252",
                    name: "/30"
                },
                {
                    subnetMask: "255.255.255.248",
                    name: "/29"
                },
                {
                    subnetMask: "255.255.255.240",
                    name: "/28"
                },
                {
                    subnetMask: "255.255.255.224",
                    name: "/27"
                },
                {
                    subnetMask: "255.255.255.192",
                    name: "/26"
                },
                {
                    subnetMask: "255.255.255.128",
                    name: "/25"
                },
                {
                    subnetMask: "255.255.255.0",
                    name: "/24"
                },
                {
                    subnetMask: "0.0.0.0",
                    name: "All"
                },
            ],
        }
    },
    methods: {
        open: function() {
            this.$refs.editFirewallRuleModal.open();
        },
        getLabel: function({ name }) {
            return name;
        },
        saveFirewallRule: function(firewall) {
            this.$refs.editFirewallRuleModal.isLoading();
            this.$emit('editFirewallRule', firewall)
            this.$refs.editFirewallRuleModal.close();
        },
    },
}
</script>
