<template>
  <div class="col-md-12">
    <div class="row row-spacing" v-allowed:view="['ADMIN', 'ACCOUNTS_STAFF']">
      <div class="d-flex justify-content-start align-items-center w-100">
        <button class="btn btn-green" @click="reActivateClient()" v-if="clientIsDeactivated">Activate Client</button>
        <button class="btn btn-warning" @click="deActivateClient()" v-if="clientIsActive">Deactivate Client</button>
        <button class="btn btn-primary" @click="deleteClient(clientDetail.clientNumber)" v-if="clientIsDeactivated">Delete Client</button>
      </div>
    </div>
    <div class="row" v-allowed:view="['ADMIN', 'ACCOUNTS_STAFF']">
      <hr class="w-100"/>
    </div>
    <div class="row" :class="{ 'row-spacing' :  !isAuthorizedUser }">
      <div class="nav-tabs-wrapper w-100">
        <ul class="nav nav-tabs responsive-tabs" role="tablist">
          <li class="nav-item" v-for="tabItem in navTabItems" :key="tabItem.id" v-allowed:view="tabItem.access">
            <a 
              :href="tabItem.link" 
              data-toggle="tab"
              role="tab"
              :aria-controls="tabItem.name"
              aria-selected="true"
              :id="tabItem.id"
              :class="tabItem.class"
            >
              <i :class="tabItem.icon"></i>
              <span class="ml-2">{{ tabItem.heading }}</span>
            </a>
          </li>
        </ul>
        <form class="tab-content" @submit.prevent="">
          <div class="error-wrapper">
            <div class="text-danger" v-for="(error, index) in errors" :key="index">
              {{ error.msg }}
            </div>
          </div>
            <div 
              class="tab-pane show active pb-3" 
              id="basic-info"
              role="tabpanel"
              aria-labelledby="basic-info-tab"
            >
              <BasicInformation 
                :userType="userType"
                :titleOptions="titleOptions"
                :isAuthorizedUser="isAuthorizedUser"
                :isStaffUser="isStaffUser"
                :clientDetail="clientDetail"
                @updateClientDetails="updateClientDetails"
              />
            </div>
            <div 
              class="tab-pane pb-3"
              id="contact-info"
              role="tabpanel"
              aria-labelledby="contact-info-tab"
            >
              <ContactInformation 
                :userType="userType"
                :isAuthorizedUser="isAuthorizedUser"
                :isStaffUser="isStaffUser"
                :clientDetail="clientDetail"
                @updateClientDetails="updateClientDetails"
                @reloadDetails="getClientDetails"
              />
            </div>
            <div  
              class="tab-pane pb-3"
              id="billing-settings"
              role="tabpanel"
              aria-labelledby="billing-settings-tab"
            >
              <BillingSettings 
                :userType="userType"
                :isAuthorizedUser="isAuthorizedUser"
                :clientDetail="clientDetail"
                :clientObjEnums="clientObjEnums"
                :resellers="resellers"
                @updateClientDetails="updateClientDetails"
              />
            </div>
            <div 
              class="tab-pane pb-3"
              id="escalations"
              role="tabpanel"
              aria-labelledby="escalations-tab"
            >
              <Escalations 
                :clientDetail="clientDetail"
                :staffMembers="staffMembers"
                :isAuthorizedUser="isAuthorizedUser"
                @updateClientDetails="updateClientDetails"
              />
            </div>
            <div  
              class="tab-pane pb-3"
              id="external-integrations"
              role="tabpanel"
              aria-labelledby="external-integrations-tab"
            >
              <LiquidIntegration />
            </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Validator } from "vee-validate";

import BasicInformation from '@/components/Clients/ClientProfileTabs/BasicInformation'
import ContactInformation from '@/components/Clients/ClientProfileTabs/ContactInformation'
import BillingSettings from '@/components/Clients/ClientProfileTabs/BillingSettings'
import Escalations from '@/components/Clients/ClientProfileTabs/Escalations'
import LiquidIntegration from '@/components/Clients/ExternalIntegrations/LiquidIntegration'

  export default {
    components: {
      BasicInformation,
      ContactInformation,
      BillingSettings,
      Escalations,
      LiquidIntegration,
    },
    data() {
      return {
        navTabItems: [
          {
            id: 'basic-info-tab',
            name: 'basic-info',
            link: '#basic-info',
            class: 'nav-link active',
            icon: 'fa fa-info-circle',
            heading: 'Basic Information',
            access: ['STAFF', 'CLIENT']
          },
          {
            id: 'contact-info-tab',
            name: 'contact-info',
            link: '#contact-info',
            class: 'nav-link',
            icon: 'fa fa-address-book',
            heading: 'Contact Information',
            access: ['STAFF', 'CLIENT']
          },
          {
            id: 'billing-settings-tab',
            name: 'billing-settings',
            link: '#billing-settings',
            class: 'nav-link',
            icon: 'fa fa-cog',
            heading: 'Billing Settings',
            access: ['STAFF']
          },
          {
            id: 'escalations-tab',
            name: 'escalations',
            link: '#escalations',
            class: 'nav-link',
            icon: 'fa fa-level-up',
            heading: 'Escalations',
            access: ['STAFF']
          },
          {
            id: 'external-integrations-tab',
            name: 'external-integrations',
            link: '#external-integrations',
            class: 'nav-link',
            icon: 'fa fa-sign-in',
            heading: 'External Integrations',
            access: ['ADMIN', 'MANAGER', 'TECHNICAL_STAFF']
          },
        ],
        clientNumber: null,
        staffMembers: [],
        clientObjEnums: {},
        clientSegments: [],
        titleOptions: ["Mr", "Ps", "Mrs", "Miss", "Adv", "Dr", "Prof", "Rev"],
        clientDetail: {
          status: ""
        },
        userType: '',
        resellers: [],
      }
    },
    created() {
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}staff`).then(
        (response) => {
          this.staffMembers = response.data;            
        },
          (error) => {
          console.error(error);
          this.showError("Error fetching Staff data", error);
        }
      );

      this.$validator.extend("verify_selected", () => {
          return {
            valid:
              this.clientDetail &&
              ((this.clientDetail.accounttype != '' && this.clientDetail.accounttype != 'None(Select one)') ||
                this.clientDetail.resellerNumber > 0),
            data: {
              required: true,
            },
          };
        },
        {
          computesRequired: true,
        }
      );
    },
    mounted() {
      if (this.userContainsPermission(["CLIENT"])) {
        this.$root.$emit("SET_CLIENT_PAGE_TITLE", 'Account Details'); 
      }

      this.clientNumber = this.$route.params.clientNumber;
      this.getClientDetails();
      this.getClientEnums();
      this.checkIfResellerDataShouldBeLoaded();
      Validator.localize("en", {
                custom: {
                vatnumber: {
                    regex:
                    "Invalid VAT Number, RSA VAT numbers are 10 digits long and starts with a digit 4",
                },
                crn: {
                    regex:
                    "Invalid Company Registration Number, RSA Company Registration numbers are in the format of YYYY/NNNNNN/NN",
                },
                },
            });
    },
    computed: {
      isAuthorizedUser() {
        return this.userContainsPermission(['ADMIN', 'ACCOUNTS_STAFF', 'ADMIN_STAFF','MANAGER']);
      },
      isStaffUser() {
        return this.userContainsPermission(['STAFF']);
      },
      clientIsActive() {
        return this.clientDetail.status == 'ACTIVE' ||  this.clientDetail.status == 'Active';
      },
      clientIsDeactivated() {
        return this.clientDetail.status == 'INACTIVE' ||  this.clientDetail.status == 'Inactive';
      }
    },
    methods: {
      checkIfResellerDataShouldBeLoaded: function() {
        if (this.userContainsPermission(["STAFF", "RESELLER"])) {
          this.getResellers();
        }
      },
      getClientEnums: function() {
        const hostUrl = this.$config.aimsAPI;

        this.$http.get(`${hostUrl}clients/enums`).then(
          (response) => {
            this.clientObjEnums = response.data;                      
          },
          (error) => {
            console.error(error);
            this.showError("Error fetching Client Enums data", error);
          }
        );
      },
      getClientSegments: function() {
        const hostUrl = this.$config.aimsAPI;

        this.$http.get(`${hostUrl}clients/segments`).then(
          (response) => {
            this.clientSegments = response.data;          
          },
          (error) => {
            console.error(error);
            this.showError("Error fetching Client Segments data", error);
          }
        );
      },
      getClientDetails: function() {
        const hostUrl = this.$config.aimsAPI;
        const clientNumber = this.$route.params.clientNumber;

        this.$http.get(`${hostUrl}clients/${clientNumber}`).then(
          (response) => {
            this.clientDetail = response.data;           
            this.userType = this.clientDetail.type;      
          },
          (error) => {
            console.error(error);
            this.showError("Error fetching Client details", error);
          }
        )
      },
      getResellers: function() {
        const hostUrl = this.$config.aimsAPI;

        this.$http.get(`${hostUrl}clients/resellers`).then(
          (response) => {
            this.resellers = response.data;                      
          },
          (error) => {
            console.error(error);
            this.showError("Error fetching Resellers", error);
          }
        )
      },
      updateClientDetails: function(clientDetail) {        
        const hostUrl = this.$config.aimsAPI;
        this.$http.put(`${hostUrl}clients/update`, clientDetail).then(
              (success) => {
                this.clientDetail = success.data;
                this.showSuccess("Updated client successfully");
              },
              (error) => {
                this.showError("Error updating client", error);
                console.error(error);
              }
            );
      },
      reActivateClient: function() {
        const hostUrl = this.$config.aimsAPI;
        const clientDetails = this.clientDetail; 

        this.$swal({
          title: "Activate Client?",
          text: "Are you sure you want to Activate this Client?",
          type: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            this.clientDetail.status = "ACTIVE";
            this.$http.put(`${hostUrl}clients/update`, clientDetails).then(
              (success) => {
                this.clientDetail = success.data;
                this.getClientDetails(this.clientNumber);
                this.setPageTitleForStaff();
                this.showSuccess("Client Re-activated successfully");
              },
              (error) => {
                this.showError("Error Activating client", error);
                console.error(error);
              }
            );
          }
        });
      },
      deActivateClient: function() {
        const hostUrl = this.$config.aimsAPI;
        const clientDetails = this.clientDetail; 

        this.$swal({
          title: "Deactivate Client",
          text: "Are you sure you want to Deactivate this Client?",
          type: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            this.clientDetail.status = "INACTIVE";
            this.$http.put(`${hostUrl}clients/update`, clientDetails).then(
              (success) => {
                this.clientDetail = success.data;
                this.getClientDetails(this.clientNumber);
                this.setPageTitleForStaff();
                this.showSuccess("Client Deactivated successfully");
              },
              (error) => {
                this.showError("Error Deactivating client", error);
                console.error(error);
              }
            );
          }
        });
      },
      deleteClient(clientNumber) {
        const hostUrl = this.$config.aimsAPI;

        this.$swal({
          title: "Delete Client",
          text: "Are you sure you want to Delete this Client?",
          type: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
              this.$http.delete(`${hostUrl}clients/${clientNumber}`).then(
                (response) => {
                    this.showSuccess('Successfully deleted Client');
                    this.$router.replace({ name: "dashboard" });
                },
                (error) => {
                    this.showError('Error deleting Client', error)
                    console.error(error);
                });
          }
        });
      },
    },
  }
</script>

<style scoped>

</style>