<template>
    <div>
        <div class="row">
            <div class="col-sm-8 col-md-6">
                <div class="card card-default">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-6">
                                <h3>Total Usage Summary</h3>
                            </div>
                            <div class="col-md-6">
                                <h3 class="float-right">{{decimalFormat(totalUsedPercentage)}} %</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <small class="pull-right">Last updated: {{usageSummary.lastUpdated}}</small>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4">
                                <label>Total Main used:</label>
                            </div>
                            <div class="col-md-4">
                                {{ sizeBytesToGb(usageSummary.mainPackageUsed) }} GB
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Main remaining:</label>
                            </div>
                            <div class="col-md-4">
                                {{ sizeBytesToGb(usageSummary.mainPackageRemaining) }} GB
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Off Peak used:</label>
                            </div>
                            <div class="col-md-4">
                                {{ sizeBytesToGb(usageSummary.offpeakPackageUsed) }} GB
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Off Peak remaining:</label>
                            </div>
                            <div class="col-md-4">
                                {{ sizeBytesToGb(usageSummary.offpeakPackageRemaining) }} GB
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Topup used:</label>
                            </div>
                            <div class="col-md-4">
                                {{ sizeBytesToGb(usageSummary.topupUsed) }} GB
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Topup remaining:</label>
                            </div>
                            <div class="col-md-4">
                                {{ sizeBytesToGb(usageSummary.topupRemaining) }} GB
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            totalUsedPercentage() {
                const totalUsed = this.usageSummary.mainPackageUsed + this.usageSummary.topupUsed;
                const totalData = this.usageSummary.mainPackageUsed + this.usageSummary.topupUsed +
                    this.usageSummary.mainPackageRemaining + this.usageSummary.topupRemaining;
                return 100 * (totalUsed / totalData);
            }
        },
        props: {
            usageSummary: {
                type: Object,
                required: true
            }
        },
        methods: {
            decimalFormat(value) {
                if (value) {
                    return parseFloat(value).toFixed(2);
                } else {
                    return 0.00;
                }
            }
        }
    }
</script>

<style>
    
</style>
