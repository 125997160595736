<template>
  <div class="row">
    <div class="col-md-8">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template v-slot:vpn>
            <form novalidate data-vv-scope="vpn">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div 
                    class="form-group" 
                    :class="{'has-error': errors.has('vpn.vrfName')}"
                  >
                    <label>VRF Name</label>
                    <input 
                        name="vrfName" 
                        v-validate="'required'" 
                        class="form-control" 
                        v-model="filledInSaleDefinition.requiredObjs.vpn.vrfName" 
                    />
                    <span class="has-error" v-if="errors.has('vpn.vrfName')">
                        {{errors.first('vpn.vrfName')}}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div 
                    class="form-group" 
                    :class="{'has-error': errors.has('vpn.loopbackName')}"
                  >
                    <label>Loopback Name</label>
                    <input 
                        name="loopbackName" 
                        v-validate="'required'" 
                        class="form-control" 
                        v-model="filledInSaleDefinition.requiredObjs.vpn.loopbackName" 
                    />
                    <span class="has-error" v-if="errors.has('vpn.loopbackName')">
                        {{errors.first('vpn.loopbackName')}}</span>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
export default {
  components: {
    ServiceWizardLayout,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "vpn",
          displayName: "VPN Setup Details",
        },
      ],
      filledInSaleDefinition: {
        requiredObjs: {
          vpn: {},
        },
        selectableOptions: {

        },
        supportingEnums: {

        },
      },
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
  },
  methods: {
    onComplete() {
      this.$emit("createSale", this.filledInSaleDefinition);
    },
    validateStep(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>
