<template>
  <div>
      <div class="form-row">
        <div class="form-group col-lg-12 col-xl-6">
          <label>Username:</label>
          <div class="input-group">
            <input v-model="fullUsername" type="text" class="form-control" disabled="" />
            <div class="input-group-append">
              <span @click="copyText(fullUsername)"
                class="input-group-text btn btn-info rounded text-white border-left-0">
                <i class="fa fa-clipboard"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="form-group col-lg-12 col-xl-6" v-allowed:view="['STAFF']">
          <label>Password:</label>
          <PasswordInput :password="value.password" :userCanEdit="true" @changePassword="changePassword"/>
        </div>
      </div>
  </div>
</template>

<script>
import PasswordInput from '@/components/GeneralComponents/InputControls/PasswordInput'
export default {
    props: {
        value: {
            type: Object,
            required: true
        }
    },
    components: {
        PasswordInput
    },
    computed: {
        fullUsername(){
            return `${this.value.username}@${this.value.realm.name}` 
        }
    },
    methods: {
      changePassword: function(data) {
        if (data) {
          this.value.password = data;
        }
      },
    },
}
</script>

<style>

</style>