<template>
  <ServiceScreenLayout :parts="parts">
    <template v-slot:service>
      <div class="row">
        <div class="col-md-7">
          <div class="card-header" >
              <h4>{{saleDefinition.sale.product.toString}}</h4>
          </div>
          <div class="col-md-8">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Username</label>
                        <div class="input-group">
                            <input
                            type="text"
                            class="form-control"
                            v-model="saleDefinition.sale.authsmtp.username"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Password</label>
                        <div>
                            <PasswordInput
                                :password="saleDefinition.sale.authsmtp.password"
                                :userCanEdit="true"
                                @changePassword="changePassword"
                            />
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-row row-spacing justify-content-between" v-allowed:view="['STAFF']">
              <div class="form-group col-auto">
                <button type="button" @click="saveSaleDefinition()" class="btn btn-success">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:senders>
      <div class="row row-spacing">
        <div class="col-md-9">
          <div class="card-header" >
              <h4>Allowed Senders</h4>
          </div>
          <div class="row">
            <div class="col-md-10">
              <button type="button" class="btn btn-info" @click="openSenderModal()">Add Sender</button>
              <div class="row-spacing"> 
                <table
                  class="table table-bordered"
                  v-if="saleDefinition.sale.senders.length > 0"
                >
                  <thead>
                    <tr>
                      <th>Sender Address</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="sender in saleDefinition.sale.senders"
                      :key="sender.authSMTPSenderNumber"
                    >
                      <td>{{ sender.senderAddress }}</td>
                      <td style="width: 10em; text-align:center">
                        <button type="button" class="btn btn-info" @click="openSenderModal(sender)"><i class="fa fa-edit"></i></button>
                        <button type="button" class="btn btn-red" @click="deleteSender(sender)"><i class="fa fa-trash" style="color: white;"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="alert alert-info">
                  No Allowed Senders to display
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <modal
        :title="newSender.objKey ? 'Edit Address' : 'Add Address'"
        ref="senderModal"
        size="modal-md"
        @save="saveSender()"
        @dismiss="dismissSenderModal()"
      >
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Address:</label>
              <input
                class="form-control"
                type="text"
                v-model="newSender.senderAddress"
              />
            </div>
          </div>
        </div>
      </modal>

    </template>
  </ServiceScreenLayout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";
import Modal from "@/components/GeneralComponents/Modal";
import PasswordInput from "@/components/GeneralComponents/InputControls/PasswordInput";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    Modal,
    PasswordInput
  },
  data() {
    return {
      saleNumber: null,
      parts: [
        {
          name: "service",
          icon: "fa fa-user-circle",
          displayName: "Service",
          permissions: ["*"],
        },
        {
          name: "senders",
          icon: "fa-regular fa-paper-plane",
          displayName: "Senders",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        sale: {
            product: {},

            authsmtp: {},
            senders: []
        }
      },
      newSender: {},

    };
  },
  async mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition() {
      this.fetchSaleDefinition(this.saleNumber).then(
        (success) => {
          this.saleDefinition = success.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
      return Promise.resolve('Success');
    },
    saveSaleDefinition: function() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http.put(this.$config.aimsAPI + "sales/definition/" + this.saleNumber + "/update", this.saleDefinition)
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.loadSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Sale Definition", error);
                this.loadSaleDefinition();
              }
            );
        }
      });
    },
    changePassword: function(data) {
        if (data) {
          this.saleDefinition.sale.authsmtp.password = data;
        }
    },

    openSenderModal(data) {
      if (data) {
        this.newSender = data;
      } else {
        this.newSender = this.saleDefinition.meta.senders.objMeta.new;
        this.newSender.saleNumber = this.saleDefinition.sale.saleNumber;
        this.newSender.websiteNumber = this.saleDefinition.sale.authsmtp.authSMTPNumber;
      }
      this.$refs.senderModal.open();
    },
    dismissSenderModal() {
      this.newSender = {};
      this.$refs.senderModal.close();
    },
    saveSender() {
      this.$refs.senderModal.isLoading();
      this.saleDefinition.sale.senders.push(this.newSender);
      this.$refs.senderModal.close();
      this.saveSaleDefinition();
    },
    deleteSender(data) {
      this.deleteObjPart(data)
        .then((response) => {
          this.showSuccess("Delete Success");
          this.loadSaleDefinition()
        },(error) => {
          console.error(error);
          this.showError("Error Deleting Sender", error);
        });
    },
  },
};
</script>