<template>
  <div>
    <div class="row row-spacing mt-4">
      <div class="col-md-12">
        <div class="card table-responsive table-bordered">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Created</th>
                <th>Type</th>
                <th>Batch Number</th>
              </tr>
            </thead>
            <tbody v-if="settlements.length > 0">
              <tr v-for="settlement in settlements" :key="settlement.settlementnumber" @click="$router.push({name: 'clientreversals', 
                      params:{settlementNumber: settlement.settlementNumber}})">
                <td>{{settlement.created}}</td>
                <td>{{settlement.type}}</td>
                <td>{{settlement.batchNumber}}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="3">No settlement batches</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ClientFunctions from '@/pages/ClientFunctions'

  export default {
    extends: ClientFunctions,
    data() {
      return {
        client: {},
        settlements: []
      }
    },
    async mounted() {
      const clientNumber = this.$route.params.clientNumber;
      await this.getClient(clientNumber).then(response => {
          this.client = response.data;
        },
        error => {
          this.showError('Error Fetching Client for Settlements', error);
          console.error(error);
        })

      await this.$http.get(this.$config.aimsAPI + 'clients/' + clientNumber + '/settlements')
        .then(response => {
            response.data.forEach(settlement => {
              if (settlement.batchNumber === settlement.settlementNumber) {
                this.settlements.push(settlement)
              }
            })
          },
          error => {
            this.showError('Error Fetching Client Settlement Batches', error);
            console.error(error);
          })
    }
  }

</script>

<style scoped>
.table>thead>tr>th {
  background-color: #3a3f51;
  color: #FFFFFF; 
  padding: 1em;

  top: 0;
  z-index: 2;
  position: sticky;
} 
</style>
