<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-row">
        <div class="form-group col-md-12">
          <label>Connectivity Package:</label>
          <input v-model="connectivitypackage.description" type="text" class="form-control" disabled="" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-12 col-xl-6">
          <label>Username:</label>
          <div class="input-group">
            <input id="userText" v-model="account.fullUsername" type="text" class="form-control" disabled="" />
            <div class="input-group-append">
              <span @click="copyText(account.fullUsername)"
                class="input-group-text btn btn-info rounded text-white border-left-0">
                <i class="fa fa-clipboard"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="form-group col-lg-12 col-xl-6" v-allowed:view="['STAFF']">
          <label>Password:</label>
          <PasswordInput :password="account.password" :userCanEdit="true" @changePassword="changePassword"/>
        </div>
      </div>
      <div class="form-row justify-content-between" v-allowed:view="['STAFF']">
        <div class="form-group col-auto">
          <button type="button" @click="$emit('save-sale')" class="btn btn-success">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PasswordInput from '@/components/GeneralComponents/InputControls/PasswordInput';
  
  export default {
    props: {
      connectivitypackage: {
        type: Object,
        required: true
      },
      account: {
        type: Object,
        required: true
      },
      sale: {
        type: Object,
        required: true
      }
    },
    components: {
      PasswordInput,
    },
    methods: {
      changePassword: function(data) {
        if (data) {
          this.account.password = data;
        }
      }
    },
  }

</script>

<style>

</style>
