<template>
  <div class="row">
    <div class="col-md-12">
      <div
        class="card"
        :class="billingType == 'ONCE_OFF' ? 'border-primary' : 'border-info'"
      >
        <div
          class="card-header"
          :class="billingType == 'ONCE_OFF' ? 'bg-default' : 'bg-default'"
        >
          <div class="row">
            <div class="col-md-8">
              <h3>{{ billingTree.description }}</h3>
            </div>
          </div>
        </div>
        <div
          class="card-body"
          v-if="billingTree && Object.keys(billingTree).length > 0"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <button
                  class="btn btn-primary"
                  @click="viewBreakdown(billingTree)"
                >
                  View Breakdown
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive table-bordered">
                <table class="table">
                  <thead>
                    <tr class="text-nowrap">
                      <th>Description</th>
                      <th>Excl.</th>
                      <th>
                        Incl. VAT @ {{ billingTree.price.vatPercentage }}%
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td class="text-nowrap">
                        {{ currencyFormat(billingTree.price.exclVat) }}
                      </td>
                      <td>{{ currencyFormat(billingTree.price.inclVat) }}</td>
                    </tr>
                    <tr class="text-warning">
                      <td>Discount</td>
                      <td class="text-nowrap">
                        {{ currencyFormat(billingTree.discount.exclVat) }}
                      </td>
                      <td>
                        {{ currencyFormat(billingTree.discount.inclVat) }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td class="text-nowrap">
                        <strong>{{
                          currencyFormat(billingTree.total.exclVat)
                        }}</strong>
                      </td>
                      <td>
                        <strong>{{
                          currencyFormat(billingTree.total.inclVat)
                        }}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body" v-else>
          <p v-if="billingType == 'ONCE_OFF'">No Once off billing for Period</p>
          <p v-else>No Recurring billing for Period</p>
        </div>
      </div>
    </div>
    <modal
      :title="billingBreakdown.description"
      ref="billingBreakdownModal"
      size="modal-xl"
      dismiss-text="Close"
      :save-needed="false"
    >
      <table class="billing-breakdown">
        <thead>
          <tr>
            <th>Description</th>
            <th>Excl.</th>
            <th>Incl. VAT @ {{ billingBreakdown.price.vatPercentage }}%</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(level1, index) in billingBreakdown.priceBreakdown"
            :key="index"
          >
            <td>
              <div>
                <p>{{ level1.description }}</p>
              </div>
            </td>
            <td>
              <div>
                <p>{{ currencyFormat(level1.price.exclVat) }}</p>
              </div>
            </td>
            <td>
              <div>
                <p>{{ currencyFormat(level1.price.inclVat) }}</p>
              </div>
            </td>
          </tr>
          <tr
            v-for="level1 in billingBreakdown.priceBreakdown"
            :key="level1.description"
          >
            <td>
              <div
                v-for="level2 in level1.priceBreakdown"
                :key="level2.description"
                class="billing-item"
              >
                <p>{{ level2.description }}</p>
                <div
                  v-for="level3 in level2.priceBreakdown"
                  :key="level3.index"
                  class="billing-item"
                >
                  <p>{{ level3.description }}</p>
                </div>
              </div>
            </td>
            <td>
              <div
                v-for="level2 in level1.priceBreakdown"
                :key="level2.description"
                class="billing-item"
              >
                <p>{{ currencyFormat(level2.price.exclVat) }}</p>
                <div
                  v-for="level3 in level2.priceBreakdown"
                  :key="level3.index"
                  class="billing-item"
                >
                  <p>{{ currencyFormat(level3.price.exclVat) }}</p>
                </div>
              </div>
            </td>
            <td>
              <div
                v-for="level2 in level1.priceBreakdown"
                :key="level2.description"
                class="billing-item"
              >
                <p>{{ currencyFormat(level2.price.inclVat) }}</p>
                <div
                  v-for="level3 in level2.priceBreakdown"
                  :key="level3.index"
                  class="billing-item"
                >
                  <p>{{ currencyFormat(level3.price.inclVat) }}</p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
export default {
  components: {
    Modal,
  },
  props: {
    billingType: {
      type: String,
      required: true,
    },
    billingTree: {
      type: Object,
      required: false,
    },
    period: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      billingBreakdown: {
        price: {},
      },
      test: "",
    };
  },
  methods: {
    viewBreakdown(billingTree) {
      this.billingBreakdown = billingTree;
      this.$refs.billingBreakdownModal.open();
    },
    hoverBillingItem: function (billingItem) {
      if (billingItem.hover) {
        this.$set(billingItem, "hover", false);
      } else {
        this.$set(billingItem, "hover", true);
      }
    },
  },
};
</script>

<style>
.billing-item {
  padding-left: 30px;
}

.billing-breakdown {
  width: 100%;
}
</style>
