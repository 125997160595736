<template>
  <modal
    title="Update Prices"
    ref="priceModal"
    :size="'modal-xl'"
    :disable-save="rowPrice ? rowPrice.length == 0 : false"
    @save="savePrice()"
  >
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Extra extension MRC (excl. VAT)</th>
          <th>MRC (excl. VAT)</th>
          <th>Valid From</th>
          <th>Valid Until</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="price in rowPrice" :key="price.objkey">
          <td class="wd-md">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{ getCurrency() }}</span>
              </div>
              <input
                type="text"
                v-model="price.pricePerAdditionalExtensionExVat"
                class="form-control"
              />
            </div>
          </td>
          <td class="wd-md">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{ getCurrency() }}</span>
              </div>
              <input
                type="text"
                v-model="price.priceExclVat"
                class="form-control"
              />
            </div>
          </td>
          <td>{{ prettifyBillingPeriod(price.validFrom) }}</td>
          <td>{{ prettifyBillingPeriod(price.validUntil) }}</td>
        </tr>
        <tr>
          <td class="wd-md">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{ getCurrency() }}</span>
              </div>
              <input
                type="text"
                v-model="newPrice.pricePerAdditionalExtensionExVat"
                class="form-control"
              />
            </div>
          </td>
          <td class="wd-md">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{ getCurrency() }}</span>
              </div>
              <input
                type="text"
                v-model="newPrice.priceExclVat"
                class="form-control"
              />
            </div>
          </td>
          <td>
            <v-select
              :options="periods"
              :get-option-label="getLabel"
              v-model="newPrice.validFrom"
              placeholder="Select Period"
            ></v-select>
          </td>
          <td>
            <button 
              :disabled="newPrice.validFrom == 0"
              type="button"
              @click="$emit('addPrice', newPrice)"
              class="btn btn-info"
            >
              Add
            </button>
            <button class="btn btn-link" type="button" @click="resetPricing()">
              Reset Unused Pricing
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
export default {
  props: {
    rowPrice: {
      type: Array,
      required: true,
    },
    newPrice: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: false,
    },
  },
  components: {
    Modal,
  },
  watch: {
    rowPrice(price) {
      if (price.length > 0) {
        const period = price[price.length - 1].validFrom;
        if (period >= this.getCurrentPeriod()) {
          this.getPeriods(period);
        } else {
          this.getPeriods();
        }
      } else {
        this.getPeriods();
      }
    },
  },
  data() {
    return {
      periods: [],
    };
  },
  methods: {
    open() {
      this.$refs.priceModal.open();
    },
    getPeriods(period) {
      let subURL = "system/billingperiods?max=12";
      if (period) {
        subURL += "&period=" + period;
      }
      this.$http.get(this.$config.aimsAPI + subURL).then(
        (response) => {
          this.periods = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching billing periods", error);
        }
      );
    },
    getLabel(period) {
      return this.prettifyBillingPeriod(period);
    },
    savePrice() {
      this.$refs.priceModal.isLoading();
      this.$http
        .post(
          this.$config.aimsAPI + "pricing/pricepackages/prices/save",
          this.rowPrice
        )
        .then(
          () => {
            this.showSuccess("Successfully Saved Prices");
            this.$emit("pricesAdded");
            this.$refs.priceModal.close();
          },
          (error) => {
            this.showError("Error Saving Pricing", error);
            console.error(error);
          }
        )
        .finally(() => {
          this.$refs.priceModal.isLoading();
        });
    },
    resetPricing() {
      this.$swal({
        title: "Reset Pricing?",
        text: "This will delete all FUTURE dated packages?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$refs.priceModal.isLoading();
          this.$http
            .post(
              this.$config.aimsAPI + "pricing/pricepackages/prices/reset",
              this.rowPrice
            )
            .then(
              () => {
                this.showSuccess("Successfully Reset Prices");
                this.$emit("pricesAdded");
                this.$refs.priceModal.close();
              },
              (error) => {
                this.showError("Error Resetting Pricing", error);
                console.error(error);
              }
            )
            .finally(() => {
              this.$refs.priceModal.isLoading();
            });
        }
      });
    },
    getCurrency() {
      if (this.currency) {
        return this.currency;
      } else return "R";
    },
  },
};
</script>

<style>
</style>
