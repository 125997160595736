<template>
    <div class="mt-3">
        <div class="row">
            <div class="col-md-6">
                <div class="card card-default">
                    <div class="card-header"><h4>Service Regrade</h4></div>
                    <div class="card-body">
                        <div class="row" v-allowed:view="['STAFF']">
                            <div class="col-md-12">
                                <label>Select a package</label>
                                <v-select
                                    :options="regradeOptions"
                                    label="strName"
                                    placeholder="Available Regrade Options"
                                    v-model="selectedPackage"
                                    clearable
                                ></v-select>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-12" v-allowed:view="['STAFF']">
                                <button type="button" :disabled="regradeOptions.length == 0 || Object.values(selectedPackage).length === 0" @click="packageRegrade(selectedPackage)" class="btn btn-warning">
                                    Regrade
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card card-default">
                    <div class="card-header"><h4>SIM Swap</h4></div>
                    <div class="card-body">
                        <div class="row" v-allowed:view="['STAFF']">
                            <div class="col-md-6">
                                <label>Select a sim</label>
                                <v-select
                                    :options="simswapAvailableSims"
                                    label="strSerialNumber"
                                    placeholder="Available Sims"
                                    v-model="simswapSelectedSim"
                                    clearable
                                >
                                </v-select>
                            </div>
                            <div class="col-md-6">
                                <label>Select a reason</label>
                                <v-select
                                    :options="simswapReasons"
                                    label="strReason"
                                    placeholder="Reason for SIM Swap request"
                                    v-model="simswapSelectedReason"
                                    clearable
                                >
                                </v-select>
                            </div>
                        </div>
                        <div class="row mt-4" v-allowed:view="['STAFF']">
                            <div class="col-md-12">
                                <button type="button" :disabled="simswapAvailableSims.length == 0 || simswapReasons.length == 0 || Object.values(simswapSelectedSim).length === 0 || Object.values(simswapSelectedReason).length === 0" @click="simSwap(simswapSelectedSim, simswapSelectedReason)" class="btn btn-warning">
                                    SIM Swap
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="card card-default" style="height: 92%;">
                    <div class="card-header"><h4>LTE Order</h4></div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">Created:</div>
                            <div class="col-md-6">
                                <strong>{{ dateTimeFormat(saleDefinition.definition.service.order.created) }}</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">Order status:</div>
                            <div class="col-md-6">
                                <strong>{{ saleDefinition.definition.service.order.status }}</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">Delivered on:</div>
                            <div class="col-md-6">
                                <strong>{{ dateTimeFormat(saleDefinition.definition.service.order.deliveredDate) }}</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">AIMS status:</div>
                            <div class="col-md-6">
                                <strong>{{ saleDefinition.definition.service.status }}</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">Order reference:</div>
                            <div class="col-md-6">
                                <strong style="color: dodgerblue">{{ saleDefinition.definition.service.order.orderReference }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card card-default">
                    <div class="card-header"><h4>Delivery Address</h4></div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">Address type:</div>
                            <div class="col-md-6">
                                <strong>{{ saleDefinition.definition.service.order.orderAddress.addressType }}</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">Sub premise:</div>
                            <div class="col-md-6">
                                <strong>{{ saleDefinition.definition.service.order.orderAddress.subpremise }}</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">Street number:</div>
                            <div class="col-md-6">
                                <strong>{{ saleDefinition.definition.service.order.orderAddress.streetNumber }}</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">Street name:</div>
                            <div class="col-md-6">
                                <strong>{{ saleDefinition.definition.service.order.orderAddress.streetName }}</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">Suburb:</div>
                            <div class="col-md-6">
                                <strong>{{ saleDefinition.definition.service.order.orderAddress.suburb }}</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">City:</div>
                            <div class="col-md-6">
                                <strong>{{ saleDefinition.definition.service.order.orderAddress.city }}</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">Province:</div>
                            <div class="col-md-6">
                                <strong>{{ saleDefinition.definition.service.order.orderAddress.province }}</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">Country:</div>
                            <div class="col-md-6">
                                <strong>{{ saleDefinition.definition.service.order.orderAddress.country }}</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">Postal Code:</div>
                            <div class="col-md-6">
                                <strong>{{ saleDefinition.definition.service.order.orderAddress.postalCode }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>
      <div class="row" v-allowed:view="['STAFF']">
        <div class="col-md-12">
            <RicaRegistration
              v-allowed:view="['STAFF', 'RESELLER']"
              :ricaDetails="saleDefinition.definition.service.rica"/>
        </div>
      </div>
    </div>
</template>

<script>
import RicaRegistration from "@/components/ObjParts/SingleCaptured/RicaRegistration";

export default {
    components: {
        RicaRegistration
    },
    props: {
        saleDefinition: {
            type: Object,
            required: true
        },
        simswapReasons: {
            type: Array,
            required: true
        },
        simswapAvailableSims: {
            type: Array,
            required: true
        },
        regradeOptions: {
            type: Array,
            required: true
        },
    },
    computed: {
        
    },
    data() {
        return {
            selectedPackage: {
                guidProductId: "",
                strName: "",
                ltePackageNumber: 0
            },
            simswapSelectedReason: {
                intReasonId: "1",
                strReason: ""
            },
            simswapSelectedSim: {
                strSerialNumber: ""
            },
        }
    },
    methods: {
        packageRegrade: function(packageSelected) {
            this.$emit('changePackageInstruction', packageSelected);
        },
        simSwap: function(sim, reason) {
            this.$emit('simswapInstruction', sim, reason);
        },
    }
}
</script>

<style lang="scss" scoped>

</style>