<template>
    <modal :title="modalTitle" ref="modifySimChangeModal" @save="saveChange">
        <div class="row">
            <div class="col-md-12">
                <label>Date</label>
                <DateInput 
                    type="date" 
                    format="YYYY-MM-DD"
                    :placeholder="'Select starting date range'"
                    style="width: 100%"
                    v-model="simChange.date"
                />
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-md-12">
                <label>SIM change</label>
                <input type="text" class="form-control" v-model="simChange.change">
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/GeneralComponents/Modal.vue';
import DateInput from "@/components/GeneralComponents/InputControls/DateInput";

export default {
    components: {
        Modal,
        DateInput
    },
    props: {
        modalTitle: {
            type: String,
            required: true
        },
        simChange: {
            type: Object,
            required: true
        }
    },
    methods: {
        open: function() {             
            this.$refs.modifySimChangeModal.open();
        },
        saveChange: function() {
            this.$emit("saveSimChange", this.simChange);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>