<template>
<div>
  <SplaPackagePrices
            :productNumber="productNumber"
            :packages="splaPackages"
            @get-spla-packages="getSplaPackages"
            :userEditRight="userEditRight"          
            />
</div>
</template>

<script>
import SplaPackagePrices from "@/components/Admin/VirtualServerPackages/SplaPackagePrices";

export default {
    props: {
        productNumber: {
            type: Number,
            required: true
        },
        userEditRight: {
            type: Boolean,
            required: true
        }
    },
    components: {
        SplaPackagePrices
    },
    computed: {
       
    },
    data() {
        return {
          splaPackages: [],
          emptyPackage: {}
        }
    },
    mounted() {
        this.getSplaPackages();
    },
    methods: {
        getSplaPackages() {
            this.$http.get(this.$config.aimsAPI + 'pricing/splapackages/product/' + this.productNumber).then(response => {
                this.splaPackages = this.deepCopyObject(response.data.splapackages);
            }, error => {
                console.error(error);
                this.showError('Error Fetching SPLA Packages', error);
            });
        }
 
    },
}
</script>
<style scoped>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #f05050 !important;
}

.nav-pills .nav-link {
  background-color: #f3f2ee;
}

a {
  color: rgba(0, 0, 0, 0.6);
}
</style>