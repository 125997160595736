<template>
    <div>
        <ClientsListTable 
            :clientsList="clientsList"
            :loadingState="loadingState"
            :loadingText="loadingText"
            :showAllClients="true"
        />
    </div>
</template>

<script>
import ClientsListTable from '@/components/Clients/ClientsListTable'

    export default {
        components: {
            ClientsListTable
        },
        data() {
            return {
                clientsList: [],
                loadingState: true,
                loadingText: 'Loading Clients...',
            }
        },
        created() {
            this.$root.$emit('SET_PAGE_TITLE', this.$route.meta.title);
            this.$root.$emit('SET_PAGE_TITLE_BADGES', []);
        },
        mounted() {
            this.getActiveClients();
        },
        methods: {
            getActiveClients: function() {
                const hostUrl = this.$config.aimsAPI;

                this.$http.get(`${hostUrl}clients/all`).then(
                    (response) => {
                        this.clientsList = response.data;
                        this.loadingState = false;
                    },
                    (error) => {
                        this.showError('Error fetching active clients list', error);
                        console.error(error);
                    }
                );
            }
        }
    }
</script>