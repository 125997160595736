<template>
  <div>
    <ClientsListTable 
        :clientsList="clientsList"
        :loadingState="loadingState"
        :loadingText="loadingText"
        :loggedInUserName="loggedInUserName"
    />
  </div>
</template>

<script>
import ClientsListTable from '@/components/Clients/ClientsListTable'

export default {
  components: {
    ClientsListTable,
  },
  props: {
    clientsList: {
        type: Array,
        required: true
    },
    loadingState: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      loggedInUser: {},
      loadingText: 'Loading Clients...'
    };
  },
  created() {
    this.initAuth();
    this.versionCheck();
  },
  computed: {
    loggedInUserName() {
      return `${this.loggedInUser.firstNames} ${this.loggedInUser.lastName}`;
    }
  },
  mounted() {
    this.getLoggedInUser();
  },
  methods: {
    getLoggedInUser: function() {
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}users/user/loggedInUser`).then(
        (response) => {
          this.loggedInUser = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching User", error);
        }
      );
    },
  },
};
</script>
