<template>
    <modal :title="title" ref="crossConnectModal" @save="save()" :size="'modal-lg'">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group" :class="{ 'has-error': errors.has('Description') }">
                    <label>Description</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        v-model="crossConnect.description"
                        name="Description"
                        :class="{ 'has-error': errors.has('Description') }"
                        v-validate="'required'"
                    >
                    <small class="has-error mt-1" v-if="errors.has('Description')">{{ errors.first("Description") }}</small>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group" :class="{ 'has-error': errors.has('Service Order Number') }">
                    <label>Service Order Number</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        v-model="crossConnect.soNumber"
                        name="Service Order Number"
                        :class="{ 'has-error': errors.has('Service Order Number') }"
                        v-validate="'required'"
                    >
                    <small class="has-error mt-1" v-if="errors.has('Service Order Number')">{{ errors.first("Service Order Number") }}</small>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group" :class="{ 'has-error': errors.has('Type') }">
                    <label>Type</label>
                    <v-select
                        :options="crossConnectTypes"
                        label="description"
                        valueProp="name"
                        v-model="crossConnect.type"
                        name="Type"
                        :class="{ 'has-error': errors.has('Type') }"
                        v-validate="'required'"
                        clearable
                    >
                    </v-select>
                    <small class="has-error mt-1" v-if="errors.has('Type')">{{ errors.first("Type") }}</small>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group" :class="{ 'has-error': errors.has('Remote End') }">
                    <label>Remote End</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        v-model="crossConnect.remoteEnd"
                        name="Remote End"
                        :class="{ 'has-error': errors.has('Remote End') }"
                        v-validate="'required'"
                    />
                    <small class="has-error mt-1" v-if="errors.has('Remote End')">{{ errors.first("Remote End") }}</small>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group" :class="{ 'has-error': errors.has('Non Recurring Cost (Excl. VAT)') }">
                    <label>Non Recurring Cost (Excl. VAT)</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">R</span>
                        </div>
                        <input 
                            type="text" 
                            class="form-control" 
                            v-model="crossConnect.nrcExclVat"
                            name="Non Recurring Cost (Excl. VAT)"
                            :class="{ 'has-error': errors.has('Non Recurring Cost (Excl. VAT)') }"
                            v-validate="'required'"
                        />
                    </div>
                    <small class="has-error mt-1" v-if="errors.has('Non Recurring Cost (Excl. VAT)')">{{ errors.first("Non Recurring Cost (Excl. VAT)") }}</small>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group" :class="{ 'has-error': errors.has('Monthly Recurring Cost (Excl. VAT)') }">
                    <label>Monthly Recurring Cost (Excl. VAT)</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">R</span>
                        </div>
                        <input 
                            type="text" 
                            class="form-control" 
                            v-model="crossConnect.mrcExclVat"
                            name="Monthly Recurring Cost (Excl. VAT)"
                            :class="{ 'has-error': errors.has('Monthly Recurring Cost (Excl. VAT)') }"
                            v-validate="'required'"
                        />
                    </div>
                    <small class="has-error mt-1" v-if="errors.has('Monthly Recurring Cost (Excl. VAT)')">{{ errors.first("Monthly Recurring Cost (Excl. VAT)") }}</small>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group" :class="{ 'has-error': errors.has('Term Expiration Date') }">
                    <label>Term Expiration Date</label>
                    <div class="input-group">
                        <DateInput 
                            type="date" 
                            v-model="crossConnect.termExpirationDate"
                            format="YYYY-MM-DD"
                            :placeholder="'Select date'"
                            style="width: 100%"
                            ref="date"
                            name="Term Expiration Date"
                            :class="{ 'has-error': errors.has('Term Expiration Date') }"
                            v-validate="'required'"
                        />
                        <div class="input-group-append">
                            <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                        </div>
                    </div>
                    <small class="has-error mt-1" v-if="errors.has('Term Expiration Date')">{{ errors.first("Term Expiration Date") }}</small>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/GeneralComponents/Modal';
import DateInput from '@/components/GeneralComponents/InputControls/DateInput.vue';

    export default {
        components: {
            Modal,
            DateInput
        },
        props: {
            title: {
                type: String,
                required: true,
            },
            crossConnect: {
                type: Object,
                required: true,
            },
            crossConnectTypes: {
                type: Array,
                required: true,
            },
        },
        methods: {
            open: function() {
                this.$refs.crossConnectModal.open();
            },
            save: function() {
                this.$refs.crossConnectModal.isLoading();
                this.$validator.validateAll().then((valid) => {
                    if (valid) {
                        if (this.crossConnect.crossConnectNumber !== 0) return this.$emit("updateCrossConnect", this.crossConnect);
                        return this.$emit("createCrossConnect", this.crossConnect);
                    } else {
                        this.$refs.crossConnectModal.isLoading();
                    }
                });  
            }
        }
    }
</script>
