<template>
    <!-- <div class="col-md-12">
        <div class="row"> -->
            <!-- <div class="col-md-6"> -->
                <div> 
                    <label>Web Hosting Package</label>
                    <input type="text" class="form-control" v-model="webHostingPackage.description" disabled>
                </div>
            <!-- </div> -->
        <!-- </div>
    </div> -->
</template>

<script>
export default {
    props: {
        webHostingPackage: {
            type: Object,
            required: true
        },
    }
}
</script>

<style lang="scss" scoped>

</style>