<template>
    <div class="row">
      <div class="col-md-8">
        <div class="card card-default">
          <service-wizard-layout
            title=""
            subtitle=""
            :parts="parts"
            :onComplete="onComplete"
            :validateStep="validateStep"
          >
            <template slot="awsTenant">
              <form novalidate data-vv-scope="awsTenant">
                <div class="row">
                  <div class="col-xl-8 col-lg-12">
                    <div
                      class="form-group"
                      :class="{
                        'has-error': errors.has('awsTenant.awsAccountNumber'),
                      }"
                    >
                      <label>AWS Account Number</label>
                      <input
                        type="text"
                        class="form-control"
                        v-validate="'required'"
                        name="name"
                        placeholder="AWS Account Number"
                        v-model="filledInSaleDefinition.requiredObjs.awsTenant.awsAccountNumber"
                      />
                      <span class="has-error" v-if="errors.has('awsTenant.awsAccountNumber')">{{
                        errors.first("awsTenant.awsAccountNumber")
                      }}</span>
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </service-wizard-layout>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
  import DateInput from "@/components/GeneralComponents/InputControls/DateInput";
  export default {
    components: {
      ServiceWizardLayout,
      DateInput,
    },
    props: {
      emptySaleDefinition: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        parts: [
          {
            name: "awsTenant",
            displayName: "AWS Tenant",
            icon: "fa fa-info",
          },
        ],
        filledInSaleDefinition: {
          requiredObjs: {
            awsTenant: {
                awsAccountNumber: "",
            }
          },
        },
      };
    },
    mounted() {
      this.filledInSaleDefinition = this.emptySaleDefinition;      
    },
    methods: {
      onComplete() {
        this.$emit("createSale", this.filledInSaleDefinition);
      },
      validateStep(scope) {
        return this.$validator.validateAll(scope).then((result) => {
          if (result) {
            return true;
          } else {
            return false;
          }
        });
      },
    },
  };
  </script>
  
  <style>
  </style>
  