<template>
    <div>
       <div class="row row-spacing mb-2">
        <div class="col-md-12">
            <button class="btn btn-info" @click="createNewSimsPackage()">
                Add New SIM Package
            </button>
        </div>
       </div>
       <div class="row row-spacing mb-2">
        <div class="col-md-12">
            <div class="table-responsive table-bordered" v-if="simsPackages.length > 0">
                <table class="table">
                    <thead>
                        <tr class="text-nowrap">
                            <th>Description</th>
                            <th>Label</th>
                            <th>Max No. of SIMs</th>
                            <th>NRC per SIM (excl. VAT)</th>
                            <th>MRC per SIM (excl. VAT)</th>
                            <th>Expired</th>
                            <th v-if="userEditRight"></th>
                        </tr>
                    </thead>
                    <tbody
                        v-for="simsPackage in simsPackages"
                        :key="simsPackage.apnSimsPackageNumber"
                        :class="{ 'bg-primary': simsPackage.expired }"
                    >
                        <tr>
                            <td class="text-nowrap text-center">{{ simsPackage.description }}</td>
                            <td class="text-nowrap text-center">{{ simsPackage.label }}</td>
                            <td class="text-nowrap text-center">{{ simsPackage.maxNumberOfSims }}</td>
                            <td>
                                {{ getPrice(simsPackage.prices, period) }}
                                <span v-if="userEditRight" @click="openSimsPriceModal(simsPackage)" v-tooltip="'Edit Row Price'" class="pull-right price-edit">
                                    <em class="fa fa-edit fa-fw text-green"></em>
                                </span>
                            </td>
                            <td>
                                {{ getApnPerSimPrice(simsPackage.prices, period) }}
                            </td>
                            <td>
                                <span class="badge" :class="simsPackage.expired ? 'badge-warning' : 'badge-green'">
                                    {{ simsPackage.expired }}
                                </span>
                            </td>
                            <td class="text-center" v-if="userEditRight">
                                <button v-tooltip="'Edit SIMs Package'" @click="editSimsPackage(simsPackage)" type="button" class="btn btn-sm btn-info m-1">
                                    <em class="fa fa-edit fa-fw"></em>
                                </button>
                                <button v-tooltip="'Delete Package'" type="button" @click="deleteSimsPackage(simsPackage)" class="btn btn-sm btn-primary">
                                    <em class="fa fa-trash fa-fw"></em>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else class="alert alert-info">No SIMs Packages Available</div>
        </div>
       </div>
       <IonlinePriceSimsModal
            ref="addSimsPriceModal"
            :row-price="rowPrice"
            :new-price="newPrice"
            @addSimsPrice="addSimsPrice"
            @pricesAdded="getSimsPackages"
       />
       <ModifyIonlineSimsPackageModal
            ref="modifySimsPackageModal"
            :pricePackage="pricePackage"
            @saveSimsPricePackage="saveSimsPricePackage"
       />
    </div>
</template>

<script>
import PriceFunctions from "@/components/Admin/PriceFunctions";
import IonlinePriceSimsModal from '@/components/Admin/Modals/IonlinePriceSimsModal';
import ModifyIonlineSimsPackageModal from '@/components/Admin/Modals/ModifyIonlineSimsPackageModal'

export default {
    extends: PriceFunctions,
    components: {
        IonlinePriceSimsModal,
        ModifyIonlineSimsPackageModal
    },
    props: {
        productNumber: {
            type: Number,
            required: true,
        },
        userEditRight: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            simsPackages: [],
            pricePackage: {},
            period: this.getCurrentPeriod(),
            rowPrice: [],
            newPrice: {},
        }
    },
    mounted() {
        this.getSimsPackages();
    },
    methods: {
        getSimsPackages: function() {
            const hostUrl = this.$config.aimsAPI;

            this.$http.get(`${hostUrl}pricing/packages/product/${this.productNumber}/ApnSimsPackage`).then(
                (response) => {
                    this.simsPackages = response.data;
                },
                (error) => {
                    console.error(error);
                    this.showError("Error fetching SIMs Packages", error);
                }
            );
        },
        createNewSimsPackage: function() {
            const hostUrl = this.$config.aimsAPI;
            this.$http.get(`${hostUrl}pricing/new/package/ApnSimsPackage`).then(
                (response) => {
                    this.pricePackage = response.data;
                    this.pricePackage.productNumber = this.productNumber;
                    this.$refs.modifySimsPackageModal.open();
                },
                (error) => {
                    console.error(error);
                    this.showError("Error Fetching SIMs Package Template", error);
                }
            );
        },
        saveSimsPricePackage: function(pricePackage) {
            const hostUrl = this.$config.aimsAPI;
            if (pricePackage.apnSimsPackageNumber) {
                this.$http.put(`${hostUrl}pricing/packages/update`, pricePackage).then(
                    (response) => {
                        this.$refs.modifySimsPackageModal.$refs.modifySimsPriceModal.close();
                        this.showSuccess("Successfully Edited SIMs Package");
                    },
                    (error) => {
                        this.showError("Error Saving SIMs Pricing", error);
                        console.error(error);
                    }
                ).finally(() => {
                    this.$refs.modifySimsPackageModal.$refs.modifySimsPriceModal.isLoading();
                });
            } else {
                this.$http.post(`${hostUrl}pricing/packages/create`, pricePackage).then(
                    (response) => {
                        this.$refs.modifySimsPackageModal.$refs.modifySimsPriceModal.close();
                        this.showSuccess("Successfully Added SIMs Package");
                        this.openSimsPriceModal(response.data);
                    },
                    (error) => {
                        this.showError("Error Saving Pricing", error);
                        console.error(error);
                    }
                ).finally(() => {
                    this.$refs.modifySimsPackageModal.$refs.modifySimsPriceModal.isLoading();
                });
            }
        },
        getApnPerSimPrice: function(data, period, vat) {
            if (data) {
                if (data.length > 0) {
                let price = data.filter((price) => period >= price.validFrom);
                price.forEach((newPrice) => {
                    if (newPrice.validUntil != 0) {
                        price = price.filter((price) => period <= price.validUntil);
                    } else {
                        price = newPrice;
                    }
                });
                if (Array.isArray(price)) {
                    if (price.length > 0) {
                        if (vat) {
                            return this.timesVAT(parseFloat(price[0].perSimPriceExclVat));
                        } else {
                            return this.currencyFormat(parseFloat(price[0].perSimPriceExclVat));
                        }
                    } else {
                        return "R " + 0;
                    }
                } else {
                    if (vat) {
                        return this.timesVAT(parseFloat(price.perSimPriceExclVat));
                    } else {
                        return this.currencyFormat(parseFloat(price.perSimPriceExclVat));
                    }
                }
                } else {
                    return "R " + 0;
                }
            } else {
                return 0;
            }
        },
        openSimsPriceModal: function(simsPackage) {
            this.rowPrice = this.deepCopyObject(simsPackage.prices || []);
            this.newPrice.apnSimsPackageNumber = simsPackage.apnSimsPackageNumber;

            this.$refs.addSimsPriceModal.open();
        },
        editSimsPackage: function(simsPackage) {
            this.pricePackage = this.deepCopyObject(simsPackage);
            this.$refs.modifySimsPackageModal.open();
        },
        deleteSimsPackage: function(simsPackage) {
            const hostUrl = this.$config.aimsAPI;

            this.$swal({
                title: "Are you sure you want to delete this SIMs Package?",
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    this.$http.delete(`${hostUrl}pricing/pricepackages/${simsPackage.objKey}?objClassName=${simsPackage.class}`).then(
                        (response) => {
                            this.showSuccess(response.data.message);
                            this.getSimsPackages();
                        },
                        (error) => {
                            this.showError("Error Deleting SIMs Price Package", error);
                        }
                    );
                }
            });
        },
        addSimsPrice: function(newPrice) {
            this.rowPrice.forEach((price) => {
                if (price.validUntil == 0) {
                    price.validUntil = this.decreaseBillingPeriod(newPrice.validFrom);
                }
            });
            newPrice.class = "za.co.adept.aims.protocol.gsm.ApnSimsPackagePrice";
            this.rowPrice.push(newPrice);
            this.setNewPrice();
            this.newPrice.apnSimsPackageNumber = newPrice.apnSimsPackageNumber;
        },
        setNewPrice: function() {
            const hostUrl = this.$config.aimsAPI;

            this.$http.get(`${hostUrl}pricing/new/price/ApnSimsPackagePrice`).then(
                (response) => {
                    this.newPrice = response.data;
                },
                (error) => {
                    console.error(error);
                    this.showError("Error Fetching SIMs Price Template", error);
                }
            );
        },
    }
}
</script>

<style scoped>
.filter-container {
  position: relative;
  z-index: 999;
}

.filter-container .filter-wrapper {
  position: absolute;
  z-index: 999;
  margin-top: .5em;
  /* background-color: #f5f7fa; */
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 2px;
  width: 350px;
  right: 90;
  top: -42;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.filter-container .filter-wrapper .filter-body {
  max-height: 200px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 10px;
  text-align: left;
}

th.table-font-size {
  font-size: .95rem;
}
th.table-header-width-1 {
  width: 10px;
}
th.table-header-width-2 {
  width: 20px;
}
th.table-header-width-3 {
  width: 50px;
}
th.table-header-width-4 {
  width: 60px;
}
th.table-header-width-5 {
  width: 95px;
}
th.table-header-width-6 {
  width: 200px;
}

.font-size {
  font-size: .9rem;
}
</style>
