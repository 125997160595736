<template>
    <div class="card-body">
        <ul class="nav nav-tabs nav-fill responsive-tabs" role="tablist">
            <li class="nav-item" v-for="part in parts" v-allowed:view="part.permissions" :key="part.name" @click="activeTab = part.name">
                <a 
                    class="nav-link row" 
                    :class="{'active' : activeTab === part.name}" 
                    :id="part.name + '-tab'" 
                    data-toggle="tab"
                    :href="'#'+part.name" 
                    role="tab" 
                    :aria-controls="part.name" 
                    aria-selected="true"
                >
                    <span class="part-name">{{ part.displayName }}</span>
                </a>
            </li>
        </ul>
        <div class="tab-content">
            <div 
                v-for="part in parts" 
                :key="part.name" 
                class="tab-pane" 
                :class="{'active show' : activeTab === part.name}"
                :id="part.name" 
                role="tabpanel" 
                :aria-labelledby="part.name + '-tab'"
            >
                <slot :name="part.name">
                    {{ part.name }} has not been implemented
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
      parts: {
        required: true,
        type: Array
      }
    },
    data() {
      return {
        activeTab: ''
      }
    },
    mounted() {
      $('.responsive-tabs').responsiveTabs({
        accordionOn: ['xs', 'sm']
      });
      this.activeTab = this.parts[0].name;
    }
}
</script>

<style lang="scss" scoped>
    .card-body {
        padding: 0;

        .nav-link {
            background-color: #ffffff;

            &.active {
                background-color: #3a3f51;

                    &:hover {
                        background-color: #3a3f51;
                    }
                    .part-name {
                        color: #ffffff;
                    }
            }
        };
    }
</style>