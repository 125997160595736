<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-default">
        <div class="card-header">
          <h3>New LTE Service</h3>
        </div>
        <div class="card-body">
          <form-wizard @on-complete="onComplete" title="" subtitle="" shape="tab" color="#d71921" stepSize="sm">
            <tab-content title="Bundle Selection" icon="fa fa-cube" :before-change="()=>validateStep('bundle-selection')">
              <form class="row" novalidate data-vv-scope="bundle-selection">
                <div class="col-lg-6">
                  
                  <div class="form-group">
                    <label>Package *</label>
                    <v-select :options="emptySaleDefinition.selectableOptions.bundle" name="Package" class="mb-2" label="description"  v-model="selectedPackage.package" placeholder="Select Package"></v-select>
                  </div>

                  <!-- <div class="form-group" :class="{'has-error': errors.has('bundle-selection.Router')}">
                    <label>Order type *</label>
                    <v-select :options="emptySaleDefinition.supportingEnums.order.deviceType" name="deviceType" v-validate="'required'" class="mb-2" label="description" valueProp='name' v-model="selectedPackage.router.deviceType" placeholder="Select Type"></v-select>
                    <span class="has-error" v-if="errors.has('bundle-selection.Router')">{{errors.first('bundle-selection.Router')}}</span>
                  </div> -->

                  <div class="form-group" :class="{'has-error': errors.has('bundle-selection.lteDevice')}">
                    <label>SIM or Router option *</label>
                    <v-select :options="availableRouters" name="LteDevice" class="mb-2" label="lteDevice" v-validate="'required'" v-model="selectedPackage.router.lteDevice" placeholder="Select Router"></v-select>
                  </div>

                </div>
              </form>
            </tab-content>
            <!--  -->
            <tab-content title="Check Feasibility" icon="fa fa-globe" :before-change="()=>validateStep('feasibility')">
              <form class="row" novalidate data-vv-scope="feasibility">
                <div class="col-lg-6">
                  <div class="col-lg-12">
                    <div class="form-group" :class="{'has-error': errors.has('feasibility.Latitude')}">
                        <label>Latitude *</label>
                        <input name="Latitude" v-validate="'required'" class="form-control"  
                            v-model="selectedPackage.latitude" @change="coordinatesChanged()"/>
                        <span class="has-error" v-if="errors.has('feasibility.Latitude')">{{errors.first('feasibility.Latitude')}}</span>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group" :class="{'has-error': errors.has('feasibility.Longitude')}">
                        <label>Longitude *</label>
                        <input name="Longitude" v-validate="'required'" class="form-control" 
                            v-model="selectedPackage.longitude" @change="coordinatesChanged()" />
                        <span class="has-error" v-if="errors.has('feasibility.Longitude')">{{errors.first('feasibility.Longitude')}}</span>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="card flex-row align-items-center p-2" :class="{'has-error': errors.has('feasibility.Feasibility')}">
                        <div class="col-md-4">
                          <label>MTN LTE Feasibility *</label>
                        </div>  
                        <div class="col-md-4">
                          <toggle-button disabled name="Feasibility" :width="80" class="text" :color="{checked: '#7DCE94', unchecked: '#82C7EB'}"
                            :labels="{checked: 'Passed', unchecked: 'Failed'}" v-validate="'required:true'" v-model="selectedPackage.isFeasible" :sync="true" />
                        </div>  
                        <div class="col-md-4">
                          <button class="btn btn-primary" type="button" @click="checkFeasibility()" :disabled="checkingFeasibility">
                              Check Feasibility    
                          </button>
                        </div>  
                    </div>
                    
                    <div class="form-group" v-allowed:view="['ADMIN']" v-if="!selectedPackage.isFeasible">
                        <label>Admin Override Feasibility:</label>
                        <input class="ml-2" type="checkbox" ref="overrideFeasibility" v-model="selectedPackage.isFeasible">
                    </div>  
                    
                  </div>
                </div>
              </form>
            </tab-content>

            <tab-content title="Rica Details" icon="fa fa-clipboard" :before-change="()=>validateStep('rica-registration')">
              <form class="row" novalidate data-vv-scope="rica-registration">
                <div class="col-lg-6">
                  <div class="form-group" :class="{'has-error': errors.has('rica-registration.First Names')}">
                    <label>First Names *</label>
                    <input name="First Names" v-validate="'required'" class="form-control" v-model="filledInSaleDefinition.requiredObjs.rica.firstNames" />
                    <span class="has-error" v-if="errors.has('rica-registration.First Names')">{{errors.first('rica-registration.First Names')}}</span>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.has('rica-registration.Last Name')}">
                    <label>Last Name *</label>
                    <input name="Last Name" v-validate="'required'" class="form-control" v-model="filledInSaleDefinition.requiredObjs.rica.lastName" />
                    <span class="has-error" v-if="errors.has('rica-registration.Last Name')">{{errors.first('rica-registration.Last Name')}}</span>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.has('rica-registration.Email')}">
                    <label>Email *</label>
                    <input name="Email" v-validate="'required|email'" class="form-control" v-model="filledInSaleDefinition.requiredObjs.rica.emailAddress" />
                    <span class="has-error" v-if="errors.has('rica-registration.Email')">{{errors.first('rica-registration.Email')}}</span>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.has('rica-registration.Contact Number')}">
                    <label>Cellphone Number (must be a valid cellphone number) *</label>
                    <input name="Contact Number" minlength="10" maxlength="10" v-validate="'required'" type="tel" class="form-control" v-model="filledInSaleDefinition.requiredObjs.rica.contactNumber" />
                    <span class="has-error" v-if="errors.has('rica-registration.Contact Number')">{{errors.first('rica-registration.Contact Number')}}</span>
                  </div>
                  <div class="form-group">
                    <label>ID Type</label>
                    <select name="ID Type" class="form-control" v-model="filledInSaleDefinition.requiredObjs.rica.idType">
                      <option
                        :value="idType.description"
                        v-for="idType in filledInSaleDefinition.supportingEnums.rica.idType"
                        :key="idType.description"
                      >
                          {{ idType.description }}
                      </option>
                    </select>
                  </div> 
                  <div class="form-group" :class="{'has-error': errors.has('rica-registration.ID / Passport Number')}">
                    <label>ID / Passport Number *</label>
                    <input name="ID / Passport Number" minlength="8" maxlength="13" v-validate="'required'" class="form-control" v-model="filledInSaleDefinition.requiredObjs.rica.idNumber" />
                    <span class="has-error" v-if="errors.has('rica-registration.ID / Passport Number')">{{errors.first('rica-registration.ID / Passport Number')}}</span>
                  </div>

                  <div class="form-group">
                    <Address @addressChanged="ricaAddressChanged" @addressValid="ricaAddressValidChanged" :addressStructure="filledInSaleDefinition.requiredObjs.ricaAddress" :addressTypes="filledInSaleDefinition.supportingEnums.ricaAddress.addressType" />
                  </div>
                </div>
              </form>
            </tab-content>

            <tab-content title="Delivery Info" icon="fa fa-truck" :before-change="()=>validateStep('delivery-info')">
              <form class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <!-- <input type="checkbox" id="checkbox" @click="copyFromRica()">
                    <label>Same as Rica adrress</label>                                     -->
                    <Address @addressChanged="addressChanged" @addressValid="deliveryAddressValidChanged" :addressStructure="filledInSaleDefinition.requiredObjs.orderAddress" :addressTypes="filledInSaleDefinition.supportingEnums.orderAddress.addressType" />
                  </div>
                </div>
              </form>  
            </tab-content>
          </form-wizard>
        </div>
      </div>
    </div>
    <WizardCheckout 
      ref="wizardCheckoutModal"
      :description="selectedPackage.package.description"
      :prices="selectedPackage.package.price"
      @checkout="finalize"
    />
  </div>
</template>

<script>
  import {
    FormWizard,
    TabContent
  } from "vue-form-wizard";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import Address from '@/components/GeneralComponents/Address'
  import WizardCheckout from '@/components/ObjParts/MultipleLoaded/WizardCheckout'
  
  export default {
    components: {
      FormWizard,
      TabContent,
      WizardCheckout,
      Address
    },
    props: {
      emptySaleDefinition: {
        type: Object,
        required: true
      }
    },
    // watch: {
    //   emptySaleDefinition: function(to, from) {
    //     this.filledInSaleDefinition = JSON.parse(JSON.stringify(to));
    //   }
    // },
    data() {
      return {
        checkingFeasibility: false,
        filledInSaleDefinition: {
          selectableOptions: {
            bundle: []
          },
          supportingEnums: {
            account: {},
            bundle: {},
            order: {
              deviceType: []
            },
            orderAddress: {
              addressType: []
            },
            rica: {
              idType: {}
            },
            ricaAddress: {
              addressType: []
            }
          },
          requiredObjs: {
            account: {},
            order: {},
            orderAddress: {},
            rica: {},
            ricaAddress: {}
          }
        },
        selectedPackage: {
          package: {
            description: "",
            price: []
          },
          router: {},
          latitude: "",
          longitude: "",
          isFeasible: false
        },
        availableRouters: []
      };
    },
    mounted: function() {
      this.filledInSaleDefinition = JSON.parse(JSON.stringify(this.emptySaleDefinition));
      this.getRouterOptions();
    },
    methods: {
      getRouterOptions() {
        this.$http.get(this.$config.aimsAPI + "mweb/mtn/availablerouters")
          .then((response) => {
              this.availableRouters = response.data;
            },
            (error) => {
              this.showError("Error fetching Available Routers", error);
              console.error(error);
            }
          );
      },
      validateStep: function(stepName) {
        this.getPrice();
        return this.$validator.validateAll(stepName).then(result => {
          if (stepName == 'rica-registration') {

            if (this.filledInSaleDefinition.requiredObjs.rica.idType != 'Passport') {
              let idValid = this.checkIdNumber();
              if (idValid == false) {
                return false;
              }
            }
            if (this.ricaAddressValid == false) {
              result = false;
            }
          }

          if (stepName == 'delivery-info') {
            if (this.deliveryAddressValid == false) {
              result = false;
            }
          }
          if (result) {
            return true;
          } else {
            return false;
          }
        });
      },
      ricaAddressChanged(raddress) {
        this.filledInSaleDefinition.requiredObjs.ricaAddress = raddress;
      },
      addressChanged(address) {
        this.filledInSaleDefinition.requiredObjs.orderAddress = address;
      },
      coordinatesChanged(){
        this.selectedPackage.isFeasible = false;    
      },
      ricaAddressValidChanged(isValid) {
        this.ricaAddressValid = isValid;
      },
      deliveryAddressValidChanged(isValid) {
        this.deliveryAddressValid = isValid;
      },
      // copyFromRica() {
      //   this.addressChanged(this.filledInSaleDefintion.requiredObjs.ricaAddress);
      //   this.deliveryAddressValidChanged(this.validateStep('delivery-info'));
      // },
      getPrice(){
        if (this.selectedPackage.package.productNumber) {
          this.$http.get(this.$config.aimsAPI + 'pricing/ltebundles/' + this.selectedPackage.package.productNumber).then(response => {
            response.data.forEach(price => {
              if(this.selectedPackage.package.lteBundleNumber == price.lteBundleNumber) {
                this.selectedPackage.package.description = price.description;
                this.selectedPackage.package.price = price.ltebundleprices;
              }
            })
          });
        }
      },
      checkIdNumber() {
            this.$http.get(this.$config.aimsAPI + "mweb/checkidnumber" 
                                              + "/" + this.filledInSaleDefinition.requiredObjs.rica.idNumber)
            .then(
              response => {
                  if (response.data === "true") {
                     return true;
                  } else {
                    this.showWarning("Invalid RSA ID Number");
                    return false;
                  }
              },
              error => {
                console.error(error);
                this.showError("Error Checking Rica Validity", error);
                return false
              }
            );
      },
      async checkFeasibility() {
        this.checkingFeasibility = true;

            await this.$http.get(this.$config.aimsAPI + "mweb/mtn/checkcoverage" 
                                              + "/" + this.selectedPackage.latitude
                                              + "/" + this.selectedPackage.longitude
                                              + "/" + "MTN")
            .then(
              response => {
                  if (response.data === "true") {
                     this.selectedPackage.isFeasible = true;
                  }
                  
              },
              error => {
                console.error(error);
                this.showError("Error Checking Feasibility", error);
                this.selectedPackage.isFeasible = false;
              }
            );
            
        this.checkingFeasibility = false;              
      },
      onComplete() {
        this.filledInSaleDefinition.requiredObjs.account.lteBundleNumber = this.selectedPackage.package.lteBundleNumber;
        this.filledInSaleDefinition.requiredObjs.order.lteDevice = this.selectedPackage.router.lteDevice;

        this.filledInSaleDefinition.requiredObjs.geolocation.latitude = this.selectedPackage.latitude;
        this.filledInSaleDefinition.requiredObjs.geolocation.longitude = this.selectedPackage.longitude;
        
        this.$refs.wizardCheckoutModal.open();
      },
      finalize(){
        this.$emit('createSale', this.filledInSaleDefinition);
      },
    }
  };
</script>

<style>
  
</style>
