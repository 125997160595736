<template>
    <modal 
        :title="'Edit PHP Directives'"
        ref="addDirectivesModal"
        :size="'modal-xl'"
        @save="saveDirectives(newWebsitePhp)"
        @close="close" @dismiss="dismiss"
    >
        <div class="row">
            <div class="form-group col-md-12">
                <textarea class="form-control"
                    v-model="phpDirectives" cols="15" rows="15">
                </textarea>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/GeneralComponents/Modal';

export default {
    components: {
        Modal
    },
    props: {
        phpDirectives: {
            type: Object,
            required: true 
        },
        newWebsitePhp: {
            type: Object,
            required: true
        },
    },
    methods: {
        open() {
            this.$refs.addDirectivesModal.open();
        },
        saveDirectives: function(data) {
            data.phpDirectives = this.phpDirectives;
            this.$emit("saveDirectives", data);
            this.$refs.addDirectivesModal.close();
        }
    }
}
</script>

<style lang="scss" scoped>

</style>