<template>
    <div class="pt-3">
        <div class="row">
          <div class="col-md-6">
            <div class="table-bordered">
               
              <table class="table"> 
                  <tr>
                      <th rowspan='1' colspan="2">Available</th>
                  </tr>
                  <tr>
                      <td rowspan='1' colspan="1"><strong>Total</strong></td>
                      <td rowspan='1' colspan="1">{{ sizeBytesToGb(totalAvailable) }} GB</td>
                  </tr>
                  <tr v-for="usage in usageBreakdown.usages">
                      <td class="cell-bg" rowspan='1' colspan="1"><strong>{{usage.name}}</strong></td>
                      <td class="cell-bg" rowspan='1' colspan="1">{{ sizeBytesToGb(usage.remaining) }} GB</td>
                  </tr>
              </table>
            
            </div>
          </div>
          <div class="col-md-6">
            <div class="table-bordered">
              <table class="table"> 
                  <tr>
                      <th rowspan='1' colspan="2">Used</th>
                  </tr>
                  <tr>
                      <td rowspan='1' colspan="1"><strong>Total</strong></td>
                      <td rowspan='1' colspan="1">{{ sizeBytesToGb(totalUsed) }} GB</td>
                  </tr>
                  <tr v-for="usage in usageBreakdown.usages">
                      <td class="cell-bg" rowspan='1' colspan="1"><strong>{{usage.name}}</strong></td>
                      <td class="cell-bg" rowspan='1' colspan="1">{{ sizeBytesToGb(usage.used) }} GB</td>
                  </tr>
                 
              </table>
            </div>
            <div class="row row-spacing">
                <div class="col-md-6">
                    <h4>Total Usage Breakdown</h4>
                </div>
                <div class="col-md-6">
                    <h4 class="float-right">{{decimalFormat(totalUsedPercentage)}} %</h4>
                </div>
                <div class="col-md-12">
                    <small class="pull-right">Last Updated: {{usageBreakdown.updated}}</small>
                </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
    export default {
        computed: {
            totalUsedPercentage() {
                let totalUsed = 0;
                this.usageBreakdown.usages.forEach( usage => {
                        if ( (usage.name === "Peak") || (usage.name === "Offpeak") 
                          || (usage.name === "Topup") || (usage.name === "Topup offpeak")
                          || (usage.name === "20 Mbps") || (usage.name === "10 Mbps")
                          || (usage.name === "4 Mbps") || (usage.name === "Rollover")
                            ) {
                          totalUsed = totalUsed + usage.used;
                        }
                });
                let totalData = 0;
                this.usageBreakdown.usages.forEach( usage => {
                        if ( (usage.name === "Peak") || (usage.name === "Offpeak") 
                          || (usage.name === "Topup") || (usage.name === "Topup offpeak")
                          || (usage.name === "20 Mbps") || (usage.name === "10 Mbps")
                          || (usage.name === "4 Mbps") || (usage.name === "Rollover")
                            ) {
                                totalData = totalData + usage.total;
                        }
                });                
                return 100 * (totalUsed / totalData);

            },
            totalAvailable() {
                let totalAvailable = 0;
                this.usageBreakdown.usages.forEach( usage => {
                        if ( (usage.name === "Peak") || (usage.name === "Offpeak") 
                          || (usage.name === "Topup") || (usage.name === "Topup offpeak")
                          || (usage.name === "20 Mbps") || (usage.name === "10 Mbps")
                          || (usage.name === "4 Mbps") || (usage.name === "Rollover")
                            ) {
                          totalAvailable = totalAvailable + usage.remaining;
                        }
                });
                
                return totalAvailable;
            },
            totalUsed() {
                let totalUsed = 0;
                this.usageBreakdown.usages.forEach( usage => {
                        if ( (usage.name === "Peak") || (usage.name === "Offpeak") 
                        || (usage.name === "Topup") || (usage.name === "Topup offpeak")
                          || (usage.name === "20 Mbps") || (usage.name === "10 Mbps")
                          || (usage.name === "4 Mbps") | (usage.name === "Rollover")
                            ) {
                          totalUsed = totalUsed + usage.used;
                        }
                });
                return totalUsed;
            }
        },
        props: {
            usageBreakdown: {
                type: Object,
                required: true
            }
        },
        methods: {
            decimalFormat(value) {
                if (value) {
                    return parseFloat(value).toFixed(2);
                } else {
                    return 0.00;
                }
            }
        }
    }
</script>

<style scoped>
th {
    background-color: #58B0C2;
    color: #FFFFFF;
}

.cell-bg {
    background-color: #f9f9f9;
}
</style>
