<template>
    <div class="card card-default">
    <div class="card-header">
        <h4>Extension Numbers</h4>
    </div>
    <div class="row" style="margin-left: 0px" v-allowed:view="['STAFF']">
        <div class="col-md-4">
            <div class="form-group">
            <input
                type="text"
                class="form-control"
                v-model="newExtension"
            />
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <button type="button" class="btn btn-info" v-show="!isAddable" @click="commitNewExtensions()">Add Extension</button>
            </div>
        </div>
    </div>
    <div class="card-body">
        <table class="table table-bordered" style="width: 60%">
        <thead>
            <tr>
                <th>Extension</th>
                <th>Number</th>
                <th v-allowed:view="['STAFF']"></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(extension, index) in extensionList" :key="index" :class="{'new-table-row': extension.isNew}">
                <td style="text-align:center; width: 15%">{{index + 1}}</td>
                <td style="width: 60%">{{extension.number}}</td>
                <td style="text-align:center; width: 25%" v-allowed:view="['STAFF']">
                    <button :disabled='editable' type="button" class="btn btn-info" @click="editExtension(index)"><i class="fa fa-edit"></i></button>
                    <button v-show='!isDeletable' type="button" class="btn btn-red" @click="deleteExtension(extension)"><i class="fa fa-trash" style="color: white;"></i></button>
                </td>
            </tr>
        </tbody>
        </table>
    </div>
    </div>  
</template>

<script>

  export default {
    props: {
        extensionList: {
            type: Array,
            required: true
        },
        pbx: {
            type: Object,
            required: false,
        }
    },
    computed: {
        isDeletable() {
            if (this.extensionList.length > 1) {
                return false;
            }
            return true;
        },
        isAddable() {
            if (this.pbx && this.pbx.maxExtensions) {
                if (this.extensionList.length < this.pbx.maxExtensions) {
                    return false;
                }
                return true;
            } else {
                if (this.pbx) {
                    return false;
                } else {
                    if (this.extensionList.length < 5) {
                        return false;
                    }
                    return true;
                }
            }
        },
    },
    data() {
        return {
            newExtension: '',
            addable: false,
            deletable: false,

            validExtensions: [],

        }
    },
    methods: {
        commitNewExtensions() {
            var extensions = [];
            var invalidSyntax = "true";
            this.validExtensions = [];
            extensions = this.newExtension.split(',');

            if (this.newExtension.includes(',') || this.newExtension.includes('-')) {
                if (this.newExtension.includes(',')) {
                    for (let i = 0; i < extensions.length; i++) {
                        extensions[i] = extensions[i].trim();
                    }

                    extensions = extensions.filter((i) => i != '');
        
                    for (let i = 0; i < extensions.length; i++) {
                        if (!extensions[i].includes('-')) {
                            this.extensionValid(extensions[i]);
                        }
                    }
                }
                if (this.newExtension.includes('-')) {
                    for (let i = 0; i < extensions.length; i++) {
                        if (extensions[i].includes('-')) {
                            let beginRange = extensions[i].split('-')[0];
                            let endRange = extensions[i].split('-')[1];

                            if (extensions[i].split('-').length > 2) {
                                invalidSyntax = "multipleDashes"
                            } else if (!endRange || !beginRange) {
                                invalidSyntax = "missingRange"
                            } else if (endRange < beginRange) {
                                invalidSyntax = "invalidRange"
                            }

                            for (let j = beginRange; j < (parseInt(endRange) + 1); j++) {
                                this.extensionValid(j);
                            }
                        }
                    }
                }

                if (invalidSyntax == "invalidRange") {
                    return this.$swal({
                        title: "Invalid Range",
                        text: "The beginning of the extensions range is higher than the end of the range.",
                        type: 'warning',
                        confirmButtonText: 'Ok',
                    });    
                }
                if (invalidSyntax == "missingRange") {
                    return this.$swal({
                        title: "Invalid Range",
                        text: "Missing range parameter.",
                        type: 'warning',
                        confirmButtonText: 'Ok',
                    });
                }
                if (invalidSyntax == "multipleDashes") {
                    return this.$swal({
                        title: "Invalid Range",
                        text: "Only use a single dash when inserting a range.",
                        type: 'warning',
                        confirmButtonText: 'Ok',
                    });
                }

                if (this.validExtensions.length == 1) {
                    this.addExtension(this.validExtensions);
                } else if (this.validExtensions.length > 1) {
                    this.$emit('add-extensions', this.validExtensions);
                } else {
                    return this.$swal({
                        title: "Error",
                        text: "None of those extensions are valid",
                        type: 'warning',
                        confirmButtonText: 'Ok',
                    });
                }
            } else {
                this.addExtension(this.newExtension);
            }
        },
        extensionValid(data) {
            var unique = true;
            if (data != null && data != "" && !isNaN(data)) {
                for (let i = 0; i < this.extensionList.length; i++) {
                    if (this.extensionList[i].number == data) {
                        unique = false
                    }
                    if (this.pbx && this.pbx.maxExtensions) {
                        if (this.extensionList.length + this.validExtensions.length >= this.pbx.maxExtensions) {
                            unique = false
                        }
                    } else {
                        if (!this.pbx) {
                            if (this.extensionList.length + this.validExtensions.length >= 5) {
                                unique = false
                            }
                        }
                    }
                }
                if (unique) {
                    this.validExtensions.push(data);
                    this.validExtensions = [...new Set(this.validExtensions)];
                } 
            }
        },
        addExtension(data) {
            var unique = true;
            if (data == null || data == "" || isNaN(data)) {
                return this.$swal({
                        title: "Error",
                        text: "You need to insert a number",
                        type: 'warning',
                        confirmButtonText: 'Ok',
                    });
            } else {
                for (let i = 0; i < this.extensionList.length; i++) {
                    if (this.extensionList[i].number == data) {
                        unique = false
                    }
                }
                if (unique) {
                    this.$emit('add-new-extension', data);
                } else {
                    return this.$swal({
                        title: "Error",
                        text: "That extension number already exists",
                        type: 'warning',
                        confirmButtonText: 'Ok',
                    });
                }
            }
        },
        deleteExtension(data) {
            this.$emit('delete-extension', data);
        },
        editExtension(id) {
            var unique = true;
            return this.$swal({
                title: "Edit Extension Number",
                input: "text",
                showCancelButton: true,
                inputPlaceholder: this.extensionList[id].number
            }).then((result) => {
                if (result.dismiss) {
                    return false;
                }
                if (result.value == null || result.value == "") {
                    return this.$swal({
                        title: "Error",
                        text: "You need to insert a number",
                        type: 'warning',
                        confirmButtonText: 'Ok',
                    }).then((result) => {
                        this.editExtension(id)
                    });
                }
                if (result.value) {
                    for (let i = 0; i < this.extensionList.length; i++) {
                        if (this.extensionList[i].number == result.value) {
                            unique = false
                        }
                    }
                    if (unique) {
                        this.extensionList[id].number = result.value;
                        this.$emit('update-extensions', this.extensionList);
                    } else {
                        return this.$swal({
                            title: "Error",
                            text: "That extension number already exists",
                            type: 'warning',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.editExtension(id)
                        });
                    }
                }
            });
        }
    }
  }

</script>

<style>

</style>
