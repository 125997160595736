<template>
    <modal
        :title="'Add Website Alias'"
        ref="addWebsiteAliasModal"
        @save="createWebsiteAlias(newWebsiteAlias)"
    >
        <div class="row">
            <div class="form-group col-md-12">
                <label class="control-label">Alias:</label>
                <input type="text" class="form-control" v-model="newWebsiteAlias.name"/>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/GeneralComponents/Modal';
export default {
    components: {
        Modal
    },
    props: {
        newWebsiteAlias: {
            type: Object,
            required: true
        },
    },
    methods: {
        open: function() {
            this.$refs.addWebsiteAliasModal.open();            
        },
        createWebsiteAlias: function(data) {
            this.$emit("createWebsiteAlias", data);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>