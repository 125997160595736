<template>
    <div class="col-md-12">
        <div class="row row-spacing">
            <div class="col-md-auto px-0">
                <button class="btn btn-success" @click="addClassification()">
                    Add {{ productClassification }}
                </button>
            </div>
            <div class="col-md-5">
                <div class="input-group">
                    <input type="text" 
                        class="form-control" 
                        :placeholder="searchPlaceholder" 
                        aria-label="Search" 
                        aria-describedby="search-input"
                        v-model="searchTerm"
                    >
                    <div class="input-group-append">
                        <span class="input-group-text" id="search-input">
                            <i class="fa-solid fa-magnifying-glass"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-spacing" v-if="isProduct">
            <div class="table-responsive table-bordered">
                <table class="table">
                    <thead>
                        <tr>
                            <th @click="sortBy('name')">
                                Name
                                <i class="fa fa-sort-asc float-right" v-if="sortKey == 'name' && reverse"></i>
                                <i class="fa fa-sort-desc float-right" v-if="sortKey == 'name' && !reverse"></i>
                            </th>
                            <th @click="sortBy('sku')">
                                SKU
                                <i class="fa fa-sort-asc float-right" v-if="sortKey == 'sku' && reverse"></i>
                                <i class="fa fa-sort-desc float-right" v-if="sortKey == 'sku' && !reverse"></i>
                            </th>
                            <th v-if="productClassification === 'Segment'" @click="sortBy('accountNumber')">
                                Account Number
                                <i class="fa fa-sort-asc float-right" v-if="sortKey == 'accountNumber' && reverse"></i>
                                <i class="fa fa-sort-desc float-right" v-if="sortKey == 'accountNumber' && !reverse"></i>
                            </th>
                            <th v-if="productClassification === 'SubSegment'" @click="sortBy('segmentName')">
                                Segment
                                <i class="fa fa-sort-asc float-right" v-if="sortKey == 'segmentName' && reverse"></i>
                                <i class="fa fa-sort-desc float-right" v-if="sortKey == 'segmentName' && !reverse"></i>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="filteredClassifications.length > 0">
                        <tr v-for="data in filteredClassifications" :key="data.objKey">
                            <td>{{ data.name }}</td>
                            <td>{{ data.skuName }}</td>
                            <td v-if="productClassification === 'Segment'">{{ data.accountNumber }}</td>
                            <td v-if="productClassification === 'SubSegment'">
                                <span>{{ getSegmentForSubsegment(data.segmentNumber).name }}</span>
                            </td>
                            <td class="text-center">
                                <button class="btn btn-info" @click="editClassification(data)">
                                    <i class="fa fa-edit"></i>
                                </button>
                                <button class="btn btn-primary" @click="deleteClassification(data)">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="4">No {{ productClassification.toLowerCase() }} to display</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row row-spacing" v-if="isClient">
            <div class="table-responsive table-bordered">
                <table class="table">
                    <thead>
                        <tr>
                            <th @click="sortBy('description')">
                                Description
                                <i class="fa fa-sort-asc float-right" v-if="sortKey == 'description' && reverse"></i>
                                <i class="fa fa-sort-desc float-right" v-if="sortKey == 'description' && !reverse"></i>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="filteredClientClassifcations.length > 0">
                        <tr v-for="data in filteredClientClassifcations" :key="data.objKey">
                            <td>{{ data.description }}</td>
                            <td class="text-center">
                                <button class="btn btn-info" @click="editClassification(data)">
                                    <i class="fa fa-edit"></i>
                                </button>
                                <button class="btn btn-primary" @click="deleteClientClassification(data)">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="4">No {{ productClassification.toLowerCase() }} to display</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <ModifyClassificationModal 
            ref="classificationModal"
            :classificationObj="classificationObj"
            :segments="segments"
            :classification="classification"
            @saveClassification="saveClassification"
        />
        <ModifyClientClassificationModal 
            ref="clientClassificationModal"
            :classificationObj="classificationObj"
            @saveClassification="saveClassification"
        />
    </div>
</template>

<script>
import ModifyClassificationModal from './Modals/ModifyClassificationModal.vue';
import ModifyClientClassificationModal from './Modals/ModifyClientClassificationModal.vue';

export default {
    components: {
        ModifyClassificationModal,
        ModifyClientClassificationModal
    },
    props: {
        productClassification: {
            type: String,
            required: true
        },
        classificationData: {
            type: Array,
            required: false,
            default: () => {
                return [];
            }
        },
        clientClassificationData: {
            type: Array,
            required: false,
            default: () => {
                return [];
            }
        },
        isProduct: {
            type: Boolean,
            required: false,
            default: () => {
                return false;
            }
        },
        isClient: {
            type: Boolean,
            required: false,
            default: () => {
                return false;
            }
        },
        segments: {
            type: Array,
            required: false,
            default: () => {
                return [];
            }
        },
    },
    data() {
        return {
            classificationObj: {},
            searchTerm: "",
            reverse: false,
            sortKey: "",
            filteredClassificationsList: [],
            filteredClientClassificationsList: [],
        }
    },
    computed: {
        filteredClassifications() {
            this.filteredClassificationsList = this.classificationData;

            if (this.searchTerm && this.productClassification === 'Segment') {                
                this.filteredClassificationsList = this.filteredClassificationsList.filter((c) =>                       
                    c.name.toLowerCase().includes(this.searchTerm) ||
                    c.skuName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                    c.accountNumber.includes(this.searchTerm)
                ); 

                return this.filteredClassificationsList;
            }

            if (this.searchTerm) {                                    
                this.filteredClassificationsList = this.filteredClassificationsList.filter((c) =>                       
                    c.name.toLowerCase().includes(this.searchTerm) ||
                    c.skuName.toLowerCase().includes(this.searchTerm.toLowerCase())
                ); 

                return this.filteredClassificationsList;
            };

            return this.filteredClassificationsList;
        },
        filteredClientClassifcations() {
            this.filteredClientClassificationsList = this.clientClassificationData;

            if (this.searchTerm) {                                    
                this.filteredClientClassificationsList = this.filteredClientClassificationsList.filter((c) =>                    
                    c.description.toLowerCase().includes(this.searchTerm)
                ); 
            }

            return this.filteredClientClassificationsList;
        },
        classification() {
            return this.productClassification.toLowerCase();
        },
        searchPlaceholder() {
            if (this.isProduct && this.productClassification === 'Segment') {
                return `Search by Name, SKU or Account Number...`;
            }
             
            if (this.isProduct) {
                return `Search by Name or SKU...`;
            }

            return 'Search by Description...';
        }
    },
    methods: {
        addClassification: function() {
            if (this.isProduct) {
                this.classificationObj = {
                    //full classname is used here
                    class: `za.co.adept.aims.product.${this.productClassification}`
                };
                this.$refs.classificationModal.open();
            } else {
                this.classificationObj = {
                    class: 'za.co.adept.aims.segment.ClientSegment'
                };
                this.$refs.clientClassificationModal.open();
            }
        },
        editClassification: function(data) {
            this.classificationObj = this.deepCopyObject(data);

            if (this.isProduct) {
                this.$refs.classificationModal.open();
            } else {
                this.$refs.clientClassificationModal.open();
            }
        },
        saveClassification: function(data) {
            if (this.isProduct) {
                const hostUrl = this.$config.aimsAPIv2;
                let path = this.getClassification();
    
                if (data.objKey > 0) {                
                    this.updateClassification(data, hostUrl, path);
                } else {
                    this.createClassification(data, hostUrl, path)
                }
            } else {
                const hostUrl = this.$config.aimsAPI;

                if (data.objKey > 0) {                
                    this.updateClientClassification(data, hostUrl);
                } else {
                    this.createClientClassification(data, hostUrl)
                }
            }
        },
        createClassification: function(data, hostUrl, path) {
            this.$http.post(`${hostUrl}${path}`, data).then(
                (response) => {   
                    const classificationCapitalized = this.classification.charAt(0).toUpperCase() + this.classification.slice(1);
                    this.showSuccess(`${classificationCapitalized} created`);
                    this.$emit('reloadClassifications');
                    this.$refs.classificationModal.$refs.modifyClassificationModal.close();
                },
                (error) => {
                    console.error(error);
                    this.showError(`Error creating ${this.classification}`, error);
                }
            ).finally(() => {
                this.$refs.classificationModal.$refs.modifyClassificationModal.isLoading();
            });
        },
        updateClassification: function(data, hostUrl, path) {
            this.$http.put(`${hostUrl}${path}`, data).then(
                (response) => {      
                    this.showSuccess(`Save success`);
                    this.$emit('reloadClassifications');
                    this.$refs.classificationModal.$refs.modifyClassificationModal.close();
                },
                (error) => {
                    console.error(error);
                    this.showError(`Error saving ${this.classification}`, error);
                }
            ).finally(() => {
                this.$refs.classificationModal.$refs.modifyClassificationModal.isLoading();
            });
        },
        deleteClassification: function(data) {            
            const classificationCapitalized = this.classification.charAt(0).toUpperCase() + this.classification.slice(1);

            this.$swal({
                title: `Are you sure you want to delete this ${classificationCapitalized}?`,
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    const hostUrl = this.$config.aimsAPIv2;
                    let path = this.getClassification();                   

                    this.$http.delete(`${hostUrl}${path}`, { body: data }).then(
                        (response) => {
                            this.showSuccess(`${classificationCapitalized} deleted`);
                            this.$emit('reloadClassifications');
                        },
                        (error) => {
                            this.showError(`Error deleting ${this.classification}`, error);
                        }
                    );
                }
            });
        },
        createClientClassification: function(data, hostUrl) {
            this.$http.post(`${hostUrl}clients/segment/create`, data).then(
                (response) => {   
                    this.showSuccess('Client Segment created');
                    this.$emit('reloadClassifications');
                    this.$refs.clientClassificationModal.$refs.modifyClientClassificationModal.close();
                },
                (error) => {
                    console.error(error);
                    this.showError('Error creating client segment', error);
                }
            ).finally(() => {
                this.$refs.clientClassificationModal.$refs.modifyClientClassificationModal.isLoading();
            });
        },
        updateClientClassification: function(data, hostUrl) {
            this.$http.put(`${hostUrl}clients/segment/update`, data).then(
                (response) => {   
                    this.showSuccess('Save success');
                    this.$emit('reloadClassifications');
                    this.$refs.clientClassificationModal.$refs.modifyClientClassificationModal.close();
                },
                (error) => {
                    console.error(error);
                    this.showError('Error saving client segment', error);
                }
            ).finally(() => {
                this.$refs.clientClassificationModal.$refs.modifyClientClassificationModal.isLoading();
            });
        },
        deleteClientClassification: function(data) {
            this.$swal({
                title: 'Are you sure you want to delete this Segment?',
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    const hostUrl = this.$config.aimsAPI;                

                    this.$http.delete(`${hostUrl}clients/segment/delete`, { body: data }).then(
                        (response) => {
                            this.showSuccess('Segment deleted');
                            this.$emit('reloadClassifications');
                        },
                        (error) => {
                            this.showError('Error deleting client segment', error);
                        }
                    );
                }
            });
        },
        getClassification: function() {
            let value;

            switch (this.classification) {
                case 'supplier':
                    value = `${this.classification}s`;
                    break;
                case 'vendor':
                    value = `${this.classification}s`;
                    break;
                case 'segment':
                    value = `${this.classification}s`;
                    break;
                case 'subsegment':
                    value = `${this.classification}s`;
                    break;
                default:
                    break;
            }

            return value;
        },
        sortBy: function(column) {
            this.sortKey = column;                           

            if (column === this.sortKey) {
                this.reverse = !this.reverse;
                if (this.isProduct) {
                    this.filteredClassifications.reverse();
                } else {
                    this.filteredClientClassifcations.reverse();
                }
            } 
        },
        getSegmentForSubsegment: function(data) {
            let segment = {};

            segment = this.segments.find((c) => c.segmentNumber === data);

            return segment;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>