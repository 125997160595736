<template>
    <div class="card card-default">
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Liquid Subscription File [JSON]</label>
                        <input type="file" class="form-control" @change="fileSelected" />
                    </div>
                </div>
            </div>
            <div class="row" v-if="uploadedFile">
                <div class="col-md-10">
                    <label>File Name</label>
                    <input type="text" class="form-control" v-model="fileName">
                </div>
            </div>
            <div class="row row-spacing">
                <div class="col-md-12">
                    <button class="btn btn-primary" @click="uploadFile()">Upload File</button>                    
                </div>
            </div>
            <div class="row-spacing table-responsive table-bordered">
                <table class="table text-nowrap">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>File Name</th>
                            <th>Reprocess</th>
                        </tr>
                    </thead>
                        <tbody v-if="uploadedFiles.length > 0">
                            <tr v-for="upFile in uploadedFiles" :key="upFile.id">
                            <td>{{upFile.id}}</td>
                            <td>{{upFile.fileName}}</td>
                            <td>
                                <button class="btn btn-primary" @click="reprocessFile(upFile)">Reprocess</button>                    
                            </td>
                            </tr>
                        </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="5">No Liquid Subscription Files Uploaded</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            uploadedFile: null,
            uploadedFileId: null,
            fileName: '',
            uploadedFiles: []
        }
    },
    created() {
        this.$root.$emit('SET_PAGE_TITLE', this.$route.meta.title);
    },
    mounted() {
        this.loadFiles();
    },
    methods: {
      fileSelected(event) {
        this.uploadedFile = event.target.files[0];
        this.fileName = this.uploadedFile.name;
      },
      loadFiles() {
        this.$http.get(this.$config.aimsAPI + "liquid/data/uploadedfiles").then(response => {
            this.uploadedFiles = response.data;   
        },
        error => {
            console.error(error);
            this.showError("Error Loading Files", error);
        });
      },
      uploadFile() {
        if (!this.uploadedFile || this.uploadedFile.length == 0) {
          this.showWarning('Please select a file');
          return;
        }
        const formData = new FormData();
        formData.append('file', this.uploadedFile);
        this.$http.post(this.$config.aimsAPI + "liquid/import", formData, { 
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
            this.uploadedFileId = response.data.fileId;
            if (response.data.status == 'ERROR') {
                this.showWarning("File upload " + response.data.status, response.data.error);
            } else {
                this.showWarning("File upload " + response.data.status, "Check Alerts Notification for Processing issues");
            }
            
            this.resetFile();  
            
            this.loadFiles();          
          },
          error => {
            console.error(error);
            this.showError("Error Uploading File", error);
          });
      },
      reprocessFile(repFile) {
            this.$swal({
                title: "Are you sure you want to Re-process?",
                text: "("+ repFile.id + ") " + repFile.fileName,
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                        this.$http.put(this.$config.aimsAPI + 'liquid/data/' + repFile.id).then(response => {
                        this.showWarning("File Reprocessing Complete", "Check Liquid Import Alerts.");
                    }, error => {
                        console.error(error);
                        this.showError('Error Reprocessing File', error);
                    });
                }
            });
      },
      resetFile() {
        this.uploadedFile = null;
        $('input[type=file]').val(null);
      }
    }
}
</script>

<style scoped>

</style>