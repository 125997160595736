<template>
  <div>
    <div class="row" v-if="clientNumber">
      <div v-if="activeView === 'NEW_SERVICE'" class="col-md-12">
        <AddNewService
          :available-products="availableProducts"
          :client-number="clientNumber"
          @change-active-view="changeActiveView"
          @createSale="createSale"
        />
      </div>
      <div v-if="activeView === 'SERVICE_LIST'" class="col-md-12">
        <div class="row row-spacing">
          <div class="col-md-2" v-allowed:view="['STAFF']">
            <button 
              class="btn btn-primary"
              type="button"
              @click="changeActiveView('NEW_SERVICE')"

            >
              New Service
            </button>
          </div>
          <div class="col-md-6">
            <form class="form-inline">
              <div class="input-group w-100">
                <input class="form-control" v-model="saleSearchInput" type="text" :placeholder="'Search...'" aria-label="Search">
                <div class="input-group-append">
                  <span class="input-group-text"><i class="fa fa-search"></i></span>
                </div>
              </div>
              </form>
          </div>
        </div>
        <div class="row row-spacing">
          <div :class="this.userContainsPermission(['CLIENT']) ? 'col-md-12' : 'col-md-8'">
            <client-services-layout :parts="parts" v-if="this.userContainsPermission(['STAFF'])">
              <template v-slot:active-services>
                <div v-if="groupedSales.length > 0 && isSalesLoaded">
                  <ActiveSales 
                  :groupedSales="groupedSales"
                  :sales="sales"
                  :prices="prices"
                  :saleSearchInput="saleSearchInput"
                  :vendors="vendors"
                  @viewSale="viewSale"
                />
                </div>
                <div v-else-if="groupedSales.length < 1 && isSalesLoaded">
                  <h4 class="text-center font-weight-bolder mt-3">No active services available</h4>
                </div>
                <div v-else>
                  <AdeptCustomLoader />
                </div>
              </template>
              <template v-slot:archived-services>
                <div v-if="groupedArchives.length > 0 && isArchivedSalesLoaded">
                  <ArchivedSales 
                    :groupedArchives="groupedArchives"
                    :archivedSales="archivedSales"
                    :prices="prices"
                    :saleSearchInput="saleSearchInput"
                    @viewSale="viewSale"
                  />
                </div>
                <div v-else-if="groupedArchives.length < 1 && isArchivedSalesLoaded">
                  <h4 class="text-center font-weight-bolder mt-3">No archived services available</h4>
                </div>
                <div v-else>
                  <AdeptCustomLoader />
                </div>
              </template>
            </client-services-layout>
            <div v-else> 
              <div v-if="groupedSales.length > 0 && isSalesLoaded">
                <ActiveSales 
                  :groupedSales="groupedSales"
                  :sales="sales"
                  :prices="prices"
                  :saleSearchInput="saleSearchInput"
                  :vendors="vendors"
                @viewSale="viewSale"
              />
              </div>
              <div v-else-if="groupedSales.length < 1 && isSalesLoaded">
                <h4 class="text-center font-weight-bolder mt-3">No active services available</h4>
              </div>
              <div v-else>
                <AdeptCustomLoader />
              </div>
            </div>
          </div>
           <div class="d-none d-md-block col-md-4" v-allowed:view="['STAFF']">
            <div class="card b">
              <div class="card-body">
                <div class="card-head">
                  <strong class="text-dark text-uppercase"
                  >Classification Segment</strong
                >
                <p class="text-uppercase font-weight-bold text-success">Active Services [Only]</p>
                </div>
              </div>
              <div v-show="segmentsLoaded == false">
                <p class="text-center font-weight-bold mt-2">Loading active services Segments...</p>
              </div>
              <div class="list-group" v-if="segmentsArr.length > 0 && segmentsLoaded">
                <span
                  v-for="segment in Object.keys(segments)"
                  :key="segment"
                  @click="activeSegment = segment;
                  getClientActiveSales();
                  getPrices();
                  isSalesLoaded = false;"
                  :class="segment == activeSegment ? 'active rounded-0' : ''"
                  style="cursor: pointer"
                  class="list-group-item list-group-item-action flex-column classifications-type"
                >
                  <p class="d-flex justify-content-between align-items-end">
                    <span :class="getIconClass(segment)" class="mr-2"></span>
                    <span>{{
                      segment.includes("None") ? "Unspecified" : segment
                    }}</span>
                    <span class="ml-auto badge">{{ segments[segment] }}</span>
                  </p>
                  <p
                    v-if="segment == activeSegment"
                    class="d-flex justify-content-between"
                  >
                    <span>{{ currencyFormat(getSegmentPrice(segment)) }}</span>
                    <span>{{
                      currencyFormat(getSegmentPrice(segment, true))
                    }}</span>
                  </p>
                </span>
                <a class="list-group-item flex-column">
                  <p class="d-flex justify-content-between align-items-end">
                    <span class="fa fa-money mr-2"></span>
                    <span>Total Spend</span>
                    <span class="ml-auto badge">{{
                      breakdown.totalSales
                    }}</span>
                  </p>
                  <p class="d-flex justify-content-between">
                    <span>{{ currencyFormat(breakdown.totalExclVat) }}</span>
                    <span>{{ currencyFormat(breakdown.totalInclVat) }}</span>
                  </p>
                </a>
              </div>
              <div class="bg-primary" v-else-if="segmentsArr.length == 0 && segmentsLoaded">
                <h4 class="text-center font-weight-bolder my-2">No Segments available</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddNewService from "@/components/Services/AddNewService";
import ClientServicesLayout from '@/layouts/ClientServicesLayout';

import ActiveSales from '@/components/Clients/SaleStatusTabs/ActiveSales';
import ArchivedSales from '@/components/Clients/SaleStatusTabs/ArchivedSales';

import AdeptCustomLoader from '@/components/GeneralComponents/AdeptCustomLoader';


export default {
  components: {
   AddNewService,
   ClientServicesLayout,
   ActiveSales,
   ArchivedSales,
   AdeptCustomLoader
  },
  watch: {
    sales() {
      this.groupSalesByClassifications();
    },

    archivedSales() {
      this.groupArchiveSales();
    },

    $route (to, from){
      this.isSalesLoaded = false;  
      this.getClientActiveSales();
    }

  },
  data() {
    return {
      parts: [
        {
          name: "active-services",
          icon: "fa fa-circle",
          displayName: "Active Services",
          permissions: ["*"],
        },
        {
          name: "archived-services",
          icon: "fa fa-circle",
          displayName: "Archived Services",
          permissions: ["STAFF"],
        },
      ],
      sales: [],
      archivedSales: [],
      showArchivedSales: false,
      groupedSales: [],
      groupedArchives: [],
      availableProducts: [],
      activeView: "SERVICE_LIST",
      segments: {},
      prices: [],
      saleSearchInput: "",
      activeSegment: null,
      breakdown: {
        segmentPrices: [],
      },
      segmentsArr: [],
      isSalesLoaded: false,
      isArchivedSalesLoaded: false,
      segmentsLoaded: false,
      url: window.location.href,
      vendors: []
    };
  },
  created() {
    this.clientNumber = this.$route.params.clientNumber;
    this.getVendors();
  },
  mounted() {
    this.getClientPriceBreakdown();
    this.getSegments();
    this.getClientArchivedSales();
  },
  methods: {
    getClientPriceBreakdown: function() {
      const hostUrl = this.$config.aimsAPI;
      const clientNumber = this.clientNumber;

      this.$http.get(`${hostUrl}sales/client/${clientNumber}/price/breakdown`).then(
        (response) => {
          this.breakdown = response.data;
        },
        (error) => {
          this.showError("Error fetching Segment prices", error);
          console.error(error);
        }
      );
    },
    getVendors: function() {
      const hostUrl = this.$config.aimsAPIv2;

      this.$http.get(`${hostUrl}vendors`).then(
        (response) => {
            this.vendors = response.data;                             
        },
        (error) => {
            console.error(error);
            this.showError("Error fetching product vendors", error);
        }
      );
    },
    getSegments: function() {
      const hostUrl = this.$config.aimsAPI;
      const clientNumber = this.clientNumber;

      this.$http.get(`${hostUrl}sales/client/${clientNumber}/segments`).then(
        (response) => {
          this.segments = response.data; 
          this.segmentsArr = Object.keys(this.segments);
          this.activeSegment = this.$route.query.segment || this.segmentsArr[0];
          this.segmentsLoaded = true;

          if (this.activeSegment) {
            this.getClientActiveSales();
            this.getPrices();
          } else if (!this.activeSegment) {
            this.isSalesLoaded = true;
          }
        },
        (error) => {
          this.showError("Error fetching Classification Segments for Client", error);
          console.error(error);
        }
      )
    },
    getClientActiveSales: function() {
      this.sales = [];
      const hostUrl = this.$config.aimsAPI;
      const clientNumber = this.clientNumber;
      let segment = this.activeSegment;

      this.$http.get(`${hostUrl}sales/client/${clientNumber}/${segment}`).then(
        (response) => {
          this.sales = response.data;
          this.isSalesLoaded = true;  
        },
        (error) => {
          this.showError("Error fetching Active Sales for Client", error);
          console.error(error);
        }
      );
    },
    getClientArchivedSales: function() {
      this.archivedSales = [];
      const hostUrl = this.$config.aimsAPI;
      const clientNumber = this.clientNumber;

       this.$http.get(`${hostUrl}sales/client/${clientNumber}/archive`).then(
        (response) => {
          this.archivedSales = response.data;
          this.isArchivedSalesLoaded = true;            
        },
        (error) => {
          this.showError("Error fetching Active Sales for Client", error);
          console.error(error);
        }
      );

    },
    getPrices: function() {
      const hostUrl = this.$config.aimsAPI;
      const clientNumber = this.clientNumber;
      const segment = this.activeSegment;

      this.$http.get(`${hostUrl}sales/client/${clientNumber}/${segment}/prices`).then(
        (response) => {
          this.prices = response.data;
        },
        (error) => {
          this.showError("Error fetching prices", error);
          console.error(error);
        }
      );
    },
    getSegmentPrice: function(segment, inclVat) {
      const segmentPrice = this.breakdown.segmentPrices.find((p) => p.segment == segment);
      
      if (segmentPrice) {
        if (inclVat) {
          return segmentPrice.amountInclVat;
        }
        return segmentPrice.amountExclVat;
      }
    },
    getIconClass: function(segment) {
      switch (segment) {
        case "Connectivity":
          return "fa fa-wifi";
        case "Cloud Services":
          return "fa fa-globe";
        case "Managed Services":
          return "fa fa-laptop";
        case "Hardware":
          return "fa fa-television";
        case "Software Licences":
          return "fa fa-folder-o";
        case "Voice Services":
          return "fa fa-phone";
        case "Labour":
          return "fa fa-briefcase";
        default:
          return "fa fa-question-circle";
      }
    },
    getSegmentFromUrl: function () {
      const temp = window.location.href.split("/")[window.location.href.split("/").length-1];

      if (temp == "adhoc") {
        return "Ad Hoc";
      }
      if (temp == "connectivity") {
        return "Connectivity";
      }
      if (temp == "voice") {
        return "Voice Services";
      }
      if (temp == "cloud") {
        return "Cloud Services";
      }
      if (temp == "managed") {
        return "Managed Services";
      }
      if (temp == "hardware") {
        return "Hardware";
      }
      if (temp == "software") {
        return "Software Licenses";
      }
      if (temp == "labour") {
        return "Labour";
      }
      if (temp == "outsourced") {
        return "Outsourced Services";
      }

      return "n/a"
    },
    groupSalesByClassifications: function () {
      this.groupedSales = [];
      this.sales.map((sale) => {
        const groupIndex = this.groupedSales.findIndex(
          (group) => group.groupName === sale.product.description
        );

        if (groupIndex != -1) {
          this.groupedSales[groupIndex].sales.push(sale);
          this.groupedSales[groupIndex].filteredSaleCount++;
        } else {
          this.groupedSales.push({
            groupName: sale.product.description,
            sales: [sale],
            filteredSaleCount: 1,
          });
        }
      });

      this.groupedSales.sort(function (a, b) {
        if (a.groupName < b.groupName) {
          return -1;
        }
        if (a.groupName > b.groupName) {
          return 1;
        }
        return 0;
      });
    },
    groupArchiveSales: function () {
      this.groupedArchives = [];
      this.archivedSales.map((sale) => {
        const groupIndex = this.groupedArchives.findIndex(
          (group) => group.groupName === sale.status
        );
        
        if (groupIndex != -1) {
          this.groupedArchives[groupIndex].archivedSales.push(sale);
          this.groupedArchives[groupIndex].filteredSaleCount;
          [groupIndex].filteredSaleCount++;
        } else {
          this.groupedArchives.push({
            groupName: sale.status,
            archivedSales: [sale],
            filteredSaleCount: 1,
          });
        }
      });
      this.groupedArchives.sort(function (a, b) {
        if (a.groupName < b.groupName) {
          return -1;
        }
        if (a.groupName > b.groupName) {
          return 1;
        }
        return 0;
      });
    },
    loadProducts: function() {
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}products?forSale=true`).then(
        (response) => {
          this.availableProducts = response.data;
        },
        (error) => {
          this.showError("Error fetching Available Products", error);
          console.error(error);
        }
      )
    },
    changeActiveView: function (activeView) {
      this.activeView = activeView;
      if (this.activeView === "NEW_SERVICE" && this.availableProducts.length === 0) {
        this.loadProducts();
      }
    },
    createSale: function(saleDefRequirements) {
      const requiredObjList = [];
      const hostUrl = this.$config.aimsAPI;
      const clientNumber = this.clientNumber;
      const saleRequiredProductNumber = saleDefRequirements.product.productNumber;

      Object.keys(saleDefRequirements.requiredObjs).forEach(function (key) {
        requiredObjList.push(saleDefRequirements.requiredObjs[key]);
      });

      this.$http.post(`${hostUrl}sales/definition/client/${clientNumber}/product/${saleRequiredProductNumber}`, requiredObjList).then(
        (response) => {
          this.showSuccess("Sale successfully created");
          this.changeActiveView("SERVICE_LIST");
          response.data.isNew = true;
          this.sales.unshift(response.data);
          this.groupSalesByClassifications();
          this.productSelected = this.emptySaleDefinition = null;
        },
        (error) => {
          this.showError("Error creating Sale", error);
          console.error(error);
        }
      )
    },
    viewSale: function(sale) {
      const productRoute = this.getProductDefinition(sale.product.productDefinition);
      if (productRoute == -1 && this.userContainsPermission(["STAFF"])) {
        this.$swal({
          title: "No Page for Product",
          text: "Contact Dev",
          type: "info"
        });
      } else if (productRoute == -1 && this.userContainsPermission(["CLIENT"])) {
        this.$swal({
          title: "Service currently not accessible",
          text: "You'll soon be able to view this service. Sorry for the inconvenience.",
          type: "info",
          showCancelButton: true,
          cancelButtonText: 'Back',
          cancelButtonColor: "#d33",
          showConfirmButton: false,
        });
      } else {
        this.$router.push({
          name: productRoute.routeName,
          params: {
            saleNumber: sale.saleNumber,
          },
        });
      }
    },
    deactivateSale: function(saleNumber, endDate) {
      const hostUrl = this.$config.aimsAPI;

      this.$http.put(`${hostUrl}sales/${saleNumber}/endDate/${endDate}`).then(
        (response) => {},
        (error) => {
          this.showError("Error Deactivating Sale", error);
          console.error(error);
        }
      )
    },
  }
};
</script>

<style lang="scss">
.new-service--button {
  max-width: 120px;

  button {
    background-color: #E2231A;
    color: #ffffff;
  }
}

.text {
  font-size: 16px !important;
}

.list-group-item.active {
  background-color: #f05050;

  a.list-group-item.active:hover {
    color: #ffffff !important;
  }

  .classifications-type.active:hover {
    color: #FFFFFF;
  }
}

</style>
