var render = function render(){var _vm=this,_c=_vm._self._c;return _c('service-screen-layout',{attrs:{"parts":_vm.parts},scopedSlots:_vm._u([{key:"details",fn:function(){return [_c('div',{staticClass:"row row-spacing"},[_c('div',{staticClass:"col-md-8"},[(
            _vm.saleDefinition.definition.repaymentPlan.instalments.length > 0
          )?_c('div',{staticClass:"table-responsive table-bordered"},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Billing Cycle")]),_c('th',[_vm._v("Amount Invoiced")]),_c('th',[_vm._v("Amount Outstanding")]),_c('th',[_vm._v("Payments Remaining")])])]),_c('tbody',{staticClass:"text-nowrap"},_vm._l((_vm.saleDefinition.definition
                  .repaymentPlan.instalments),function(instalment,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(_vm.prettifyBillingPeriod(instalment.billingPeriod))+" ")]),_c('td',[_vm._v("R "+_vm._s(_vm.timesVATNoCurrency(instalment.amount)))]),_c('td',[_vm._v("R "+_vm._s(_vm.timesVATNoCurrency(instalment.remainingBalance)))]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(instalment.remainingInstalments)+" ")])])}),0)])]):_c('div',{staticClass:"alert alert-info"},[_vm._v(" No Repayment Instalments created. ")])])])]},proxy:true},{key:"summary",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',[_vm._v("Device")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","readonly":""},domProps:{"value":_vm.saleDefinition.definition.repaymentPlan.repaymentPlanPackage
                  .description}})])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Repayment Terms")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                _vm.saleDefinition.definition.repaymentPlan.repaymentPlanPackage
                  .terms
              ),expression:"\n                saleDefinition.definition.repaymentPlan.repaymentPlanPackage\n                  .terms\n              "}],staticClass:"form-control",attrs:{"type":"text","readonly":""},domProps:{"value":(
                _vm.saleDefinition.definition.repaymentPlan.repaymentPlanPackage
                  .terms
              )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.saleDefinition.definition.repaymentPlan.repaymentPlanPackage
                  , "terms", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Remaining Payments")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","readonly":""},domProps:{"value":_vm.latestInstalment.remainingInstalments}})])]),(_vm.remainingInstalments)?_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Next Invoice Amount (Inc. VAT)")]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._v("R")])]),_c('input',{staticClass:"form-control",attrs:{"type":"text","readonly":""},domProps:{"value":_vm.timesVATNoCurrency(_vm.saleDefinition.definition.repaymentPlan.monthlyAmount)}})])]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Next Invoice Date")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","readonly":""},domProps:{"value":_vm.prettifyBillingPeriod(_vm.period)}})])]):_vm._e(),(_vm.remainingInstalments)?_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Outstanding Amount (Inc. VAT)")]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._v("R")])]),_c('input',{staticClass:"form-control",attrs:{"type":"text","readonly":""},domProps:{"value":_vm.timesVATNoCurrency(_vm.latestInstalment.remainingBalance)}})])]),_c('div',{directives:[{name:"allowed",rawName:"v-allowed:view",value:(['STAFF']),expression:"['STAFF']",arg:"view"}],staticClass:"col-md-6 align-self-center pt-2"},[_c('button',{staticClass:"btn btn-info",attrs:{"type":"button"},on:{"click":function($event){return _vm.settleAmount()}}},[_vm._v(" Settle Amount ")])])]):_vm._e()])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }