<template>
  <modal :title="this.modalTitle" ref="addNewRecord" @save="saveDnsRecord()">
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label">IP Address</label>
        <input
          type="text"
          class="form-control"
          v-model="newDnsRecord.address"
          placeholder="Enter the IP Address and Host Name..."
        />
      </div>
      <div class="form-group col-md-10">
        <label class="control-label">Name</label>
        <input 
          type="text" 
          class="form-control" 
          v-model="newDnsRecord.name"
          placeholder="Enter Record Name..." 
        />
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
export default {
  props: {
    modalTitle: {
      type: String,
      required: true,
    },
    ipvType: {
      type: String,
      required: true,
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      newDnsRecord: {
        name: "",
        address: "",
      },
      ipvRecords: {},
    };
  },
  methods: {
    open: function() {
      this.$refs.addNewRecord.open();
    },
    saveDnsRecord: function() {
      const ipvType = this.ipvType.toLowerCase();
      const hostUrl = this.$config.aimsAPI;
      const recordAddress = this.newDnsRecord.address;
      const recordName = this.newDnsRecord.name;
      this.$http
        .post(`${hostUrl}reverse/zones/${ipvType}/${recordAddress}/${recordName}`)
        .then(
          (response) => {
            response.data.isNew = true;
            this.$refs.addNewRecord.close();
            this.showSuccess("Created new DNS record");
            this.$emit("recordAdded", response.data);
          },
          (error) => {
            console.error(error);
            this.showError("Error creating new record", error);
          });
    },
  },
};
</script>

