<template>
    <div class="card border-info">
      <div
        class="card-header bg-info"
        style="cursor: pointer"
        data-tool="card-collapse"
      >
        <a class="float-right" data-tool="card-collapse">
          <em class="fa fa-plus"></em>
        </a>
        <div class="card-title">Resellers Clients</div>
      </div>
      <div class="card-wrapper collapse show">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <tbody>
                <tr
                  v-for="client in clients"
                  :key="client.clientNumber"
                >
                  <!-- this causes issues with reloading, the clientmenus aren't reloaded accordingly -->
                  <!-- @click="$router.replace({
                      name: 'clientdetail',
                      params: { clientNumber: client.clientNumber },
                    })"  -->
                  <td>
                    <small>{{ client.toString }}</small>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    clients: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped>
.table-responsive {
  height: 255px !important;
}
</style>