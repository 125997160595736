<template>
    <div class="row">
        <div class="col-md-12 mt-4">
            <div class="form-group row">
                <div class="col-sm-12">
                    <button class="btn btn-info" type="button" @click="addLicense">Add Licence</button>
                </div>
            </div>
            <div class="table-responsive table-bordered">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Package</th>
                            <th>Quantity</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="licenses.length > 0">
                        <tr v-for="license in licenses" :key="license.virtualSplaLicenseNumber">
                            <td>{{license.splaPackage.description}}</td>
                            <td>{{license.quantity}}</td>
                            <td style="text-align:center">
                                <button type="button" class="btn btn-info" @click="editLicense(license)"><i class="fa fa-edit"></i></button>
                                <button type="button" class="btn btn-primary" @click="removeLicense(license)"><i class="fa fa-trash"></i></button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="4">No Licenses found</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            licenses: {
                type: Array,
                required: true
            },
            splaPackages: {
                type: Array,
                required: true
            },
        },
        methods: {
            addLicense: function(data) {                                
                this.$emit("addLicence", data);
            },
            editLicense: function(data) {                                
                this.$emit("editLicense", data);
            },
            removeLicense: function(data) {                                
                this.$emit("removeLicense", data);
            }
        }
    }
</script>