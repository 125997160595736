var render = function render(){var _vm=this,_c=_vm._self._c;return _c('service-screen-layout',{attrs:{"parts":_vm.parts},scopedSlots:_vm._u([{key:"mplsoverfibre",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9"},[_c('mpls-details',{attrs:{"lan":_vm.saleDefinition.definition.lan},on:{"saveSale":_vm.saveSale}},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.saleDefinition.sale.label),expression:"saleDefinition.sale.label"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.saleDefinition.sale.label)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.saleDefinition.sale, "label", $event.target.value)}}})])]),_c('div',{staticClass:"card card-default"},[_c('div',{staticClass:"card-header"},[_c('h4',[_vm._v("Point to Point Addesses")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9"},[_c('table',{staticClass:"table table-bordered"},[_c('caption',[_vm._v(" IPv4 Point to Point ")]),_c('thead',[_c('tr',[_c('th',[_vm._v("Address")]),_c('th',[_vm._v("Mask")])])]),_c('tbody',[_c('tr',[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                            _vm.saleDefinition.definition.lan
                              .pointToPointIpv4Network.address
                          ),expression:"\n                            saleDefinition.definition.lan\n                              .pointToPointIpv4Network.address\n                          "}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(
                            _vm.saleDefinition.definition.lan
                              .pointToPointIpv4Network.address
                          )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.saleDefinition.definition.lan
                              .pointToPointIpv4Network, "address", $event.target.value)}}})]),_c('td',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(
                            _vm.saleDefinition.definition.lan
                              .pointToPointIpv4Network.shortMask
                          ),expression:"\n                            saleDefinition.definition.lan\n                              .pointToPointIpv4Network.shortMask\n                          "}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.saleDefinition.definition.lan
                              .pointToPointIpv4Network, "shortMask", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.netmasksV4),function(mask){return _c('option',{key:mask,domProps:{"value":mask}},[_vm._v(" /"+_vm._s(mask)+" ")])}),0)])])])])])]),_c('div',{directives:[{name:"allowed",rawName:"v-allowed:view",value:(['STAFF']),expression:"['STAFF']",arg:"view"}],staticClass:"row row-spacing"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-success pull-right",attrs:{"type":"button"},on:{"click":function($event){return _vm.saveSale()}}},[_vm._v(" Save ")])])])])])]),_c('RoutedNetworks',{attrs:{"heading":"Routed Networks","routedNetworks":_vm.saleDefinition.definition.lan.routedIpv4Networks,"caption":"IPv4 Routes","netmasks":_vm.netmasksV4,"emptyRoutedNetwork":_vm.saleDefinition.supportingObjs.routedIpv4Networks},on:{"saveRoutedNetworks":_vm.saveRoutedNetworks,"removeAddress":_vm.removeAddress}})],1)])]},proxy:true},{key:"line",fn:function(){return [_c('div',{staticClass:"col-md-12"},[_c('ConnectivityLine',{attrs:{"line":_vm.saleDefinition.definition.line},on:{"saveSale":_vm.saveSale}})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }