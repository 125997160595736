<template>
  <div class="row">
    <div class="col-md-8">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template v-slot:smtp>
            <form novalidate data-vv-scope="smtp">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div 
                    class="form-group" 
                    :class="{'has-error': errors.has('smtp.username')}"
                  >
                    <label>Username</label>
                    <input 
                        name="username" 
                        v-validate="'required|email'" 
                        class="form-control"
                        v-model="filledInSaleDefinition.requiredObjs.authSmtp.username"
                    />
                    <span class="has-error" v-if="errors.has('smtp.username')">
                        {{errors.first('smtp.username')}}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div 
                    class="form-group" 
                    :class="{'has-error': errors.has('smtp.password')}"
                  >
                    <label>Password</label>
                    <input 
                        name="password" 
                        v-validate="'required'" 
                        class="form-control"
                        v-model="filledInSaleDefinition.requiredObjs.authSmtp.password"
                    />
                    <span class="has-error" v-if="errors.has('smtp.password')">
                        {{errors.first('smtp.password')}}</span>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
export default {
  components: {
    ServiceWizardLayout,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "smtp",
          displayName: "Authenticated SMTP Details",
        },
      ],
      filledInSaleDefinition: {
        requiredObjs: {
            authSmtp: {

            }
        },
        selectableOptions: {

        },
        supportingEnums: {

        },
      },
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
    
    if (this.filledInSaleDefinition.product.productDefinition.includes("Bulk")) {
        this.parts[0].displayName = "Bulk Authenticated SMTP Details"
    }
  },
  methods: {
    onComplete() {
      this.$emit("createSale", this.filledInSaleDefinition);
    },
    validateStep(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>
