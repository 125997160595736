<template>
  <div class="card card-default">
    <div class="card-header">
      <h4>Connectivity Status</h4>
      <span
        v-if="connectivityService.currentlyCapped"
        class="badge text-white badge-capped"
        >Capped</span
      >
    </div>
    <div class="card-body">
      <div class="form-group row mb-md-0">
        <label class="col-md-6 mb-md-0">Status:</label>
        <div class="col-md-6">
          <strong class="text-uppercase" :class="connected">{{
            connected
          }}</strong>
        </div>
      </div>
      <div class="form-group row mb-md-0">
        <label class="col-md-6 mb-md-0">Last Updated:</label>
        <div class="col-md-6">
          <strong>{{dateTimeFormat(session.lastUpdate)}}</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    connectivityService: {
      type: Object,
      required: true,
    },
    session: {
      type: Object,
      required: true,
    },
  },
  computed: {
    connected() {
      return this.dateFormat(this.session.stopTime) ==
        "1970-01-01"
        ? "connected"
        : "disconnected";
    },
  },
};
</script>

<style>
.connected {
  color: green;
}

.disconnected {
  color: orange;
}

.badge-capped {
  background-color: orange !important;
  font-size: 17;
}
</style>
