<template>
    <div class="row">
        <div class="col-md-6 mt-4">
            <div class="form-group row">
                <label class="col-md-3">Name:</label>
                <div class="col-md-9">
                    <v-select
                        :options="packages"
                        valueProp="virtualServerPackageNumber"
                        label="description"
                        v-model="vServerPackageNumber"
                    >
                    </v-select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3">CPU:</label>
                <div class="col-md-9">
                    <div class="form-control readonly">
                        {{ virtualServerPackage.virtualCPUs }}
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3">RAM:</label>
                <div class="col-md-9">
                    <div class="input-group">
                        <div class="form-control readonly">
                            {{ sizeBytesToGbWhole(virtualServerPackage.virtualRam) }}
                        </div>
                        <div class="input-group-append">
                            <span class="input-group-text">GB</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3">Disk space:</label>
                <div class="col-md-9">
                    <div class="input-group">
                        <div class="form-control readonly">
                            {{ sizeBytesToGbWhole(virtualServerPackage.diskSpace) }}
                        </div>
                        <div class="input-group-append">
                            <span class="input-group-text">GB</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row" v-allowed:view="['STAFF']">
                <div class="col-sm-12">
                    <button class="btn btn-success" type="button" @click="save">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            virtualServerPackage: {
                type: Object,
                required: true
            },
            virtualServerPackageNumber: {
                type: Number,
                required: false
            },
            packages: {
                type: Array,
                required: true
            },
        },
        computed: {
            vServerPackageNumber: {

                get: function () {
                    return this.virtualServerPackageNumber;
                },

                set: function (newValue) {
                    this.$emit('packageChange', newValue);
                }
            }
        },
        methods: {
            save: function() {                
                this.$emit("saveSale");
            }
        }
    }
</script>

<style lang="scss" scoped>
.readonly {
    background-color: #edf1f2;
}
</style>