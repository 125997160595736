<template>
  <div>
    <service-screen-layout :parts="parts">
      <template v-slot:details>
        <div class="row">
          <div class="col-md-6 mt-4">
              <div class="form-group row">
                  <label class="col-md-6">Name:</label>
                  <div class="col-md-9">
                      <input type="text" class="form-control" v-model="saleDefinition.definition.spladevice.name">
                  </div>
              </div>
          </div>
        </div>
        <div class="form-row row-spacing justify-content-between" v-allowed:view="['STAFF']">
          <div class="form-group row-spacing col-auto">
            <button type="button" @click="saveSale()" class="btn btn-success">Save</button>
          </div>
        </div>

      </template>
      <template v-slot:licenses>
        <SplaLicenses 
          :licenses="saleDefinition.definition.spladevice.licenses"
          :splaPackages="splaPackages"
          @addLicense="addLicense"
          @editLicense="editLicense"
          @removeLicense="removeLicense"
        />
      </template>
    </service-screen-layout>
    
    <SplaLicenceModal
      ref="splaModal" 
      :modalTitle="selectedSplaLicense.splaPackageNumber ? 'Edit License' : 'Add License'"
      :splaLicence="selectedSplaLicense"
      :splaPackages="splaPackages"
      @saveSplaLicence="saveSplaLicense"
    /> 
  </div>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SplaLicenceModal from '@/components/Admin/Modals/SplaLicenceModal';
import SplaLicenses from "@/components/ObjParts/MultipleCaptured/SplaLicenses";

export default {
  components: {
    ServiceScreenLayout,
    SplaLicenceModal,
    SplaLicenses
   
  },
  data() {
    return {
      parts: [
        {
          name: "details",
          icon: "fa-solid fa-globe",
          displayName: "SPLA",
          permissions: ["*"],
        },
        {
          name: "licenses",
          icon: "fa-solid fa-id-card",
          displayName: "SPLA Licenses",
          permissions: ["*"],
        }
      ],
      saleDefinition: {
        definition: {
          spladevice: {
            licenses: []
          }
        }
      },
      splaPackages: [],
      saleNumber: null,
      newLicense: {},
      selectedSplaLicense: {}
    };
  },
  mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.getSale(this.saleNumber);
  },
  methods: {
    getSale: function() {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleNumber;

      this.$http.get(`${hostUrl}sales/definition/sale/${saleNumber}`).then(
        (response) => {
          this.saleDefinition = response.data; 
          this.splaPackages = this.saleDefinition.selectableOptions.splaPackage; 
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching Sale Definition", error);
        }
      );
    },
    saveSale: function() {     
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleNumber;

      this.$http.put(`${hostUrl}sales/definition/${saleNumber}/update`, this.saleDefinition).then(
          (response) => {
            this.showSuccess("Save Success");
            this.saleDefinition = response.data;
            this.getSale();
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving Sale Definition", error);
          }
        );
    },
    saveSplaLicense: function(spla) {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleDefinition.sale.saleNumber;

      if (spla.splaPackageNumber) {
        this.$http.put(`${hostUrl}sales/definition/${saleNumber}/obj/update`, spla).then(
          (response) => {
            this.showSuccess("SPLA Updated successfully");
            this.getSale();
          },
          (error) => {
            console.error(error);
            this.showError("Error Updating SPLA", error);
          }
        );
      }
    },
    addLicense: function() {
      this.selectedSplaLicense = this.saleDefinition.supportingObjs.licenses;
      this.selectedSplaLicense.splaDeviceNumber = this.saleDefinition.definition.spladevice.splaDeviceNumber;
      this.$refs.splaModal.open();
    },
    editLicense: function(data) {
      this.selectedSplaLicense = data;
      this.$refs.splaModal.open();
    },
    removeLicense: function(data) {
      this.$swal({
          title: "Are you sure you want to delete this License?",
          text: data.name,
          type: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
      }).then((result) => {
          if (result.value) {
            const hostUrl = this.$config.aimsAPI;
            const saleNumber = this.saleDefinition.sale.saleNumber;

            this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/delete/`, data).then(
              (response) => {
                this.showSuccess("Delete Success");
                this.getSale();
              },(error) => {
                console.error(error);
                this.showError("Error Removing SPLA", error);
              });
          }
      });
    }
  },
};
</script>
