<template>
    <div class="row row-spacing">
        <div class="col-md-12">
            <div class="row row-spacing">
                <div class="col-md-12">
                    <h3>CPanel Configuration</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Username</label>
                        <div class="input-group">
                            <input 
                                type="text" 
                                class="form-control"
                                v-model="cPanelConfiguration.username"
                            >
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-6">
                    <h4>CPanel Package Info</h4>
                    <div class="form-group">
                        <label>Package</label>
                        <div class="input-group">
                            <v-select
                                :options="cPanelPackageOptions"
                                valueProp="cPanelPackageNumber"
                                label="description"
                                v-model="cPanelConfiguration.cPanelPackage.cPanelPackageNumber"
                            ></v-select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <h4>CPanel Server Info</h4>
                    <div class="form-group">
                        <label>Server Hostname</label>
                        <div class="input-group">
                            <v-select
                                :options="cPanelServerOptions"
                                valueProp="cPanelServerNumber"
                                label="serverHostname"
                                v-model="cPanelConfiguration.cPanelServer.cPanelServerNumber"
                                :disabled="!isSeniorTechnicalStaff"
                            ></v-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-spacing" v-allowed:view="['STAFF']">
                <div class="col-md-2">
                    <div class="form-group">
                        <button type="button" @click="$emit('updateConfiguration', cPanelConfiguration)" class="btn btn-success">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        cPanelConfiguration: {
            type: Object,
            required: true,
        },
        cPanelPackageOptions: {
            type: Array,
            required: true,
        },
        cPanelServerOptions: {
            type: Array,
            required: true,
        }
    },
    computed: {
        isSeniorTechnicalStaff() {
            return this.userContainsPermission(["SENIOR_TECHNICAL_STAFF"]);
        }
    },
}
</script>
