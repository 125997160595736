<template>
  <div>
    <!-- row for import segments -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Client Segments Import file</label>
                    <div class="row">
                      <div class="col-md-6">
                        <input type="file" class="form-control" @change="fileSelected" />
                      </div>
                    </div>
                  <div class="row row-spacing">
                    <div class="col-md-12">
                      <button class="btn btn-primary" @click="uploadFile()">Upload File</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <modal
            title="Client Liaison"
            ref="helpButtonModal"
            save-text="Download"
            @save="generateReport()"
          >
            <div class="form-group">
              <label>Staff User</label>
              <div>
                <v-select
                  :options="liaisonOptions"
                  placeholder="Select a User"
                  v-model="liaisonSelected"
                />
              </div>
            </div>
          </modal>
        </div>
      </div>
    </div>

  </div>  
</template>

<script>
  import Modal from "@/components/GeneralComponents/Modal";

  export default {
    components: {
      Modal,
    },
    data() {
      return {
        clientSegmentsFile: null,
        fileName: '',
        adhocReports: [],
        adhocReportSelected: { 
          params: [{
            value: {
              value: {},
            },
          }], 
        },
        liaisons: [],
        liaisonOptions: [],
        liaisonSelected: 'All',
      }
    },
    created() {
      this.$root.$emit('SET_PAGE_TITLE', this.$route.meta.title);

      //filter all adhoc reports down to Client Liaison
      this.getReportObject();
      
    },
    methods: {
      getReportObject() {
        this.$http.get(this.$config.aimsAPI + "reporting/adhoc").then(
          (response) => {
            this.adhocReports = response.data;
            this.adhocReports = this.adhocReports.filter(
              (r) => r.group === "Clients"
            );
            this.adhocReports = this.adhocReports.filter(
              (r) => r.class === "za.co.adept.aims.reports.adhoc.clients.ClientLiasonReport"
            );
            this.adhocReportSelected = this.adhocReports[0];
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Adhoc Reports", error);
          }
        );
        return Promise.resolve('Success');
      },
      fileSelected(event) {
        this.clientSegmentsFile = event.target.files[0];
        this.fileName = this.clientSegmentsFile.name;
      },
      generateReport() {

        //Insert parameters if Staff User was selected
        if (this.liaisonSelected != "All") {
          for (let i = 0; i < this.liaisons.length; i++) {
            if (this.liaisons[i].toString == this.liaisonSelected) {
              this.adhocReportSelected.params[0].value.value = this.liaisons[i];
            }
          }
        } else {
          this.adhocReportSelected.params[0].value = {};
        }
        
        //Download Report Call
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.$refs.helpButtonModal.isLoading();
            this.$http
              .post(
                this.$config.aimsAPI +
                "reporting/adhoc/download?reportClassName=za.co.adept.aims.reports.adhoc.clients.ClientLiasonReport",
                this.adhocReportSelected.params,
                {
                  responseType: "arraybuffer",
                }
              )
              .then(
                function (response) {
                  this.$refs.helpButtonModal.close();
                  const contentType = response.headers.get("Content-Type");
                  let blob = new Blob([response.data], {
                    type: contentType,
                  });
                  const fileName = response.headers
                    .get("Content-Disposition")
                    .split("filename = ")[1];
                  let link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  link.download = fileName;
                  link.dispatchEvent(
                    new MouseEvent("click", {
                      bubbles: true,
                      cancelable: true,
                      view: window,
                    })
                  );
                },
                function (error) {
                  console.error(error);
                  this.showError("Error Downloading Report", error.data);
                }
              )
              .finally(() => {
                this.$refs.helpButtonModal.isLoading();
              });
          }
        });
      },
      uploadFile() {
        if (!this.clientSegmentsFile || this.clientSegmentsFile.length == 0) {
          this.showWarning('Please select a file');
          return;
        }
        const formData = new FormData();
        formData.append('file', this.clientSegmentsFile);
        this.$http.post(this.$config.aimsAPI + "clients/update/segments", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
            this.resetFile();
            this.loadFiles();
          },
          error => {
            console.error(error);
            this.showError("Error Uploading Segment File", error);
          });
      },
      resetFile() {
        this.clientSegmentsFile = null;
        $('input[type=file]').val(null);
      },
    },
  }

</script>

<style>
  .customBtn {
        height: 25px;
        width: 25px;
        text-align: center;
        padding: 6px 0;
        font-size: 13.5px;
        line-height: 0.885;
        border-radius: 50%;
        margin-top: 3px;
    }
</style>
