<template>
  <div class="sale-loader--container p-4">
    <p class="text-center font-weight-bold loading-text">Services Loading...</p>
    <div class="loading">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.sale-loader--container {
  .loading-text {
      color: #e2231a;
      font-size: 1.3rem;
  }
  .loading {
    display: flex;
    justify-content: center;

    .dot {
      width: 1rem;
      height: 1rem;
      margin: 2rem 0.3rem;
      background: #e2231a;
      border-radius: 50%;
      animation: 0.9s bounce infinite alternate;

      &:nth-child(2) {
        animation-delay: 0.3s;
      }

      &:nth-child(3) {
        animation-delay: 0.6s;
      }
    }

    @keyframes bounce {
      to {
        opacity: 0.3;
        transform: translate3d(0, -1rem, 0);
      }
    }
  }
}
</style>