<template>
    <div class="row row-spacing">
        <div class="col-md-12">
            
        </div>
    </div>
</template>

<script>
import ClientSales from "@/pages/Clients/ClientSales";

export default {
    components: {
        ClientSales
    },
    data(){
        return {
            linkedClient: {
                
            }
        }
    },
    created(){
        this.$http.get(this.$config.aimsAPI + "clients/clientuser/linkedclient").then(
            response => {
                this.linkedClient = response.data;
                this.$router.push({
                    name: 'searchclientforclient',
                    params: {
                        clientNumber: this.linkedClient.clientNumber
                    }
                });
            },
            error => {
                console.error(error);
                this.showError("Error Fetching Linked Client", error);
            }
        );
    }
}
</script>

<style>

</style>
