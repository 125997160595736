var render = function render(){var _vm=this,_c=_vm._self._c;return _c('service-screen-layout',{attrs:{"parts":_vm.parts},scopedSlots:_vm._u([{key:"admin",fn:function(){return [_c('div',{staticClass:"row row-spacing"},[_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-4 col-form-label"},[_vm._v("Domain: ")]),_c('div',{staticClass:"col-md-7"},[_c('input',{staticClass:"form-control-plaintext",attrs:{"type":"text","readonly":""},domProps:{"value":_vm.saleDefinition.sale.domain.domainName}})])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-4 col-form-label"},[_vm._v("Admin Username: ")]),_c('div',{staticClass:"col-md-7"},[_c('input',{staticClass:"form-control-plaintext",attrs:{"type":"text","readonly":""},domProps:{"value":_vm.getUsername(_vm.saleDefinition.sale.domain)}})])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-4 col-form-label"},[_vm._v("Current Password: ")]),_c('div',{staticClass:"col-md-7"},[_c('PasswordInput',{attrs:{"password":_vm.saleDefinition.sale.domain.adminPassword,"userCanEdit":true,"validation":"zimbra"},on:{"changePassword":_vm.changePassword}})],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-success",attrs:{"type":"button"},on:{"click":_vm.saveSale}},[_vm._v(" Save ")])])])])]),(_vm.saleDefinition.details && 
        _vm.saleDefinition.details.portal && 
        _vm.saleDefinition.details.portal.url.length > 0 && 
        _vm.saleDefinition.details.portal.name.length > 0)?_c('div',{staticClass:"col-md-5"},[_c('PortalButton',{attrs:{"portalDetails":_vm.saleDefinition.details.portal}})],1):_vm._e()])]},proxy:true},{key:"domainalias",fn:function(){return [_c('div',{staticClass:"row row-spacing"},[_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10"},[(
                _vm.saleDefinition.sale.domain.domainAliases.length > 0
              )?_c('table',{staticClass:"table table-bordered"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Domain Alias")])])]),_c('tbody',_vm._l((_vm.saleDefinition.sale.domain
                    .domainAliases),function(domainAlias){return _c('tr',{key:domainAlias.zimbraDomainAliasNumber},[_c('td',[_vm._v(_vm._s(domainAlias.domainName))])])}),0)]):_c('div',{staticClass:"alert alert-info"},[_vm._v(" No Domain Aliases to display ")])])])])])]},proxy:true},{key:"mailboxes",fn:function(){return [_c('div',{staticClass:"row row-spacing"},[_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10"},[(_vm.saleDefinition.sale.domain.mailboxes.length > 0)?_c('table',{staticClass:"table table-bordered"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Username")]),_c('th',[_vm._v("Package")]),_c('th',[_vm._v("Usage")]),_c('th')])]),_c('tbody',_vm._l((_vm.saleDefinition.sale.domain
                    .mailboxes),function(mailbox){return _c('tr',{key:mailbox.zimbraMailboxNumber},[_c('td',[_vm._v(_vm._s(mailbox.username))]),_c('td',[_c('v-select',{attrs:{"options":_vm.saleDefinition.meta.domain.children.mailboxes.children.classOfService.partMeta.options,"label":"description"},model:{value:(mailbox.classOfService),callback:function ($$v) {_vm.$set(mailbox, "classOfService", $$v)},expression:"mailbox.classOfService"}})],1),_c('td',[_vm._v(_vm._s(_vm.sizeBytesToMb(mailbox.usage))+" MB")]),_c('td',{staticStyle:{"text-align":"center"}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Save Package'),expression:"'Save Package'"}],staticClass:"btn btn-info",attrs:{"type":"button"},on:{"click":_vm.saveSale}},[_c('em',{staticClass:"fa fa-save fa-fw"})])])])}),0)]):_c('div',{staticClass:"alert alert-info"},[_vm._v(" No Mailboxes to display ")])])])])])]},proxy:true},{key:"emailalias",fn:function(){return [_c('div',{staticClass:"row row-spacing"},[_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10"},[(
                _vm.saleDefinition.sale.domain.accountAliases.length > 0
              )?_c('table',{staticClass:"table table-bordered"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Email Alias Username")]),_c('th',[_vm._v("Destination")])])]),_c('tbody',_vm._l((_vm.saleDefinition.sale.domain
                    .accountAliases),function(accountAlias){return _c('tr',{key:accountAlias.zimbraMailboxAliasNumber},[_c('td',[_vm._v(_vm._s(accountAlias.username))]),_c('td',_vm._l((_vm.aliases(accountAlias.destination)),function(alias){return _c('p',{key:alias},[_vm._v(" "+_vm._s(alias)+" ")])}),0)])}),0)]):_c('div',{staticClass:"alert alert-info"},[_vm._v(" No Email Aliases to display ")])])])])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }