<template>
    <div class="col-md-12">
        <div class="row" v-allowed:view="['ACCOUNTS_STAFF', 'ADMIN']">
            <div class="form-group col-md-6 pl-0">
                <button class="btn btn-success" v-if="documentStatusIsOpen" @click="addRemark" type="button">Add Remark</button>
                <button class="btn btn-success" v-if="documentStatusIsOpen" @click="addNewLine" type="button">Add Line</button>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6 pl-0">
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Search document lines..." v-model="searchText">
                    <div class="input-group-append">
                        <span class="input-group-text"><i class="fa fa-search"></i></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 pl-0" ref="docuLines">
                <div class="table-responsive">
                    <table class="table table-bordered" :class="{ 'credit-mode': creditMode }">
                        <thead>
                            <tr class="dark-row">
                                <th class="">Description</th>
                                <th class="text-right">VAT exclusive</th>
                                <th class="text-right">VAT</th>
                                <th class="text-right">VAT inclusive</th>
                                <th class="text-center" v-if="hasBreakdowns" v-allowed:view="['STAFF']">View</th>
                                <th class="text-center" v-allowed:view="['ACCOUNTS_STAFF', 'ADMIN']">
                                    <span v-if="creditMode">
                                        <input type="checkbox" @change="selectAll($event)" v-model="selected"/>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="filteredLines.length > 0">
                            <tr v-for="docline in filteredLines" :key="docline.objKey" :class="{ 'credit-line': docline.credit }">
                                <td>
                                    <div v-if="!docline.editMode">
                                        <span :class="getDocumentLineClass(docline.type)">
                                            {{ docline.description }}
                                        </span>
                                    </div>
                                    <input v-else type="text" class="form-control" v-model="docline.description" />
                                </td>
                                <td v-if="!isRemark(docline.type) && !docline.editMode" class="text-right">
                                    {{ currencyFormat(showVatExclusive(docline)) }}
                                </td>
                                <td v-else-if="!isRemark(docline.type) && docline.editMode" class="text-center">
                                    <input type="text" class="form-control pull-right" v-model="docline.transactionAmount" v-if="addVatExcl"/>
                                    <button class="btn btn-info" v-if="!addVatExcl && !addVatIncl" @click="$emit('addVatExclusiveAmount', docline)" :disabled="addVatIncl">Add VAT Exclusive amount</button>
                                    <span v-if="addVatIncl">{{ currencyFormat(showVatExclusive(docline)) }}</span>
                                </td>
                                <td v-else>&nbsp;</td>
                                <td v-if="!isRemark(docline.type) && !docline.editMode" class="text-right text-nowrap">
                                    {{ currencyFormat(docline.vatAmount) }}
                                </td>
                                <td v-else>&nbsp;</td>
                                <td v-if="!isRemark(docline.type) && !docline.editMode" class="text-right">
                                    {{ currencyFormat(docline.transactionAmount) }}
                                </td>
                                <td v-else-if="!isRemark(docline.type) && docline.editMode" class="text-center">
                                    <input type="text" class="form-control pull-right" v-model="docline.transactionAmount" v-if="addVatIncl"/>
                                    <button class="btn btn-info" v-if="!addVatIncl && !addVatExcl" @click="$emit('addVatInclusiveAmount')" :disabled="addVatExcl">Add VAT Inclusive amount</button>
                                    <span v-if="addVatExcl">{{ currencyFormat(showVatInclusive(docline)) }}</span>
                                </td>
                                <td v-else>&nbsp;</td>
                                <td class="text-center" v-if="hasBreakdowns" v-allowed:view="['STAFF']">
                                    <a href="javascript:void(0)" style="font-size: 13px" @click="$emit('showBillingBreakdown', docline)" title="View Breakdown" v-if="docline.type === 'AIMS_INITIAL_SALE' || docline.type === 'AIMS_SALE'">
                                        Breakdown
                                    </a>
                                    <div v-allowed:view="['STAFF']">
                                    <label style="font-size: 13px" v-if=" docline.type === 'AIMS_INITIAL_SALE' || docline.type === 'AIMS_SALE'">
                                        /
                                    </label>
                                    <a href="javascript:void(0)" style="font-size: 13px" @click="$emit('showSale', docline.saleNumber)" v-if="docline.type === 'AIMS_INITIAL_SALE' || docline.type === 'AIMS_SALE'" :title="docline.saleNumber">
                                        Sale
                                    </a>
                                    </div>
                                </td>
                                <td class="text-center" v-allowed:view="['ACCOUNTS_STAFF', 'ADMIN']" v-if="documentBody.status === 'OPEN' || creditMode">
                                    <div v-if="creditMode === false">
                                    <button @click="$emit('toggleDocLineEdit', docline, true, addVatExcl)" type="button" class="btn btn-info" v-if="!docline.editMode && documentBody.status === 'OPEN'">
                                        <i class="fa fa-edit"></i>
                                    </button>
                                    <button @click="$emit('saveDocumentLine', docline)" type="button" class="btn btn-success" v-if="docline.editMode && documentBody.status === 'OPEN'">
                                        <i class="fa fa-save"></i>
                                    </button>
                                    <button @click="$emit('toggleDocLineEdit', docline, false, addVatExcl)" type="button" class="btn btn-warning" v-if="documentBody.status === 'OPEN' && docline.editMode">
                                        <i class="fa-solid fa-xmark"></i>
                                    </button>
                                    <button @click="$emit('deleteDocumentLine', docline.objKey)" type="button" class="btn btn-primary" v-if="!docline.editMode && documentBody.status === 'OPEN'">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                    </div>
                                    <div v-else-if="docline.type === 'AIMS_INITIAL_SALE' || docline.type === 'AIMS_SALE' || docline.type === 'REMARK' || docline.type === 'ADHOC_SALE'">
                                        <input type="checkbox" @change="selectSection(docline)" v-model="docline.credit" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="6">No document lines found</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="4" v-if="filteredLines.length > 0">
                                Total
                                <div class="pull-right">
                                    {{ currencyFormat(documentBody.totalAmount) }}
                                </div>
                            </td>
                            <td colspan="5" v-else>
                                Total
                                <div class="pull-right">
                                    {{ currencyFormat(documentBody.totalAmount) }}
                                </div>
                            </td>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            documentLines: {
                type: Array,
                required: true
            },
            documentBody: {
                type: Object,
                required: true
            },
            creditMode: {
                type: Boolean,
                required: true
            },
            hasBreakdowns: {
                type: Boolean,
                required: true
            },
            addVatExcl: {
                type: Boolean,
                required: true
            },
            addVatIncl: {
                type: Boolean,
                required: true
            },
        },
        data() {
            return {
                filteredLineList: [],
                searchText: '',
                isCredit: false,
                newDocumentLine: [],
                selected: false,
            }
        },
        computed: {
            filteredLines() {
                if (this.searchText === "") {
                    return this.documentLines;
                } else {
                    return this.documentLines.filter((d) =>
                        d.description.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1
                    );
                }
            },
            documentStatusIsOpen() {
                return this.documentBody.status === 'OPEN';
            }
        },
        methods: {
            selectAll() {
                this.$emit('selectAll', this.selected);
            },
            selectSection(docline) {
                if (docline.type == 'REMARK') {
                    this.$emit('selectSection', docline);
                } 
            },
            addRemark: function() {
                this.$emit('insertLineOtherWay','REMARK');
            },
            addNewLine: function() {
                this.$emit('insertLineOtherWay', 'ADHOC_SALE')
            },
            showVatExclusive: function(data) {
                let transactionAmount = Number(data.transactionAmount);
                return parseFloat(transactionAmount - data.vatAmount);
            },
            showVatInclusive: function(data) {
                let transactionAmount = Number(data.transactionAmount);
                return parseFloat(transactionAmount + data.vatAmount);
            },
            getDocumentLineClass: function(dltype) {
                switch (dltype) {
                case "REMARK":
                    return "text-bold";
                case "CLIENT_REMARK":
                    return "text-bold";
                default:
                    return "";
                }
            },
            isRemark: function(dltype) {
                switch (dltype) {
                case "REMARK":
                    return true;
                case "CLIENT_REMARK":
                    return true;
                default:
                    return false;
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.dark-row {
    width: 900px !important;
    background-color: #3a3f51;

    th {
        background-color: #3a3f51;
        color: #FFFFFF !important;

        position: sticky;
        top: 0;
        z-index: 2;
        font-size: .95em;
    }
}

.credit-line {
    color: green;
    font-size: 17px;
    border: 1px solid green;
 }
</style>