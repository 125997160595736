<template>

<div class="card card-default">
    <div class="card-body">
        <div class="table-responsive table-bordered text-nowrap">
          <table class="row-spacing table table-hover">
            <thead>
                <tr>
                    <th>#</th>
                    <th>CompanyName</th>
                    <th>Client Name</th>                    
                </tr>
            </thead>
            <tbody>
                <tr v-for="client in resellers" :key="client.clientNumber" @click="viewClient(client.clientNumber)">
                    <td>{{client.clientNumber}}</td>
                    <td>{{client.registeredCompanyName}}</td>
                    <td>{{client.firstnames}} {{client.lastname}}</td>
                </tr>
            </tbody>
        </table>
        </div>
    </div>
</div>

</template>

<script>

export default {
    data: function() {
        return {
          resellers: [],
        }
    },
    created() {
      this.$root.$emit('SET_PAGE_TITLE', this.$route.meta.title);
    },
    mounted: function() {
      this.getClients();        
    },
    methods: {
      getClients() {
        this.$http.get(this.$config.aimsAPI + 'clients/resellers')
          .then(response => {
            this.resellers = response.data;
          }, error => {
            console.error(error);
            this.showError('Error Fetching Resellers', error);
          });
      },
      viewClient: function(clientNumber) {
          this.$router.push({name: 'clientsales', params: {'clientNumber': clientNumber}});
      }
    }
}

</script>

