<template>
    <modal
      title="Credit Note Details"
      ref="creditDetailModal"
      size="modal-md"
      :saveText="creditDetail.creditDetailNumber ? 'Save' : 'Next'"
      @save="createCreditDetail()"
    >
        <div class="row">
            <div class="form-group col-md-6" :class="{ 'has-error': errors.has('ticketNumber') }">
                <label class="control-label">Ticket Number: </label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">#</span>
                  </div>
                  <input
                      type="text"
                      class="form-control"
                      v-model="creditDetail.ticketNumber"
                      name="ticketNumber"
                      :class="{ 'has-error': errors.has('ticketNumber') }"
                      v-validate="'required'"
                  />
                </div>
                <small class="has-error mt-1" v-if="errors.has('ticketNumber')">{{ errors.first("ticketNumber") }}</small>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12" :class="{ 'has-error': errors.has('reason') }">
                <label class="control-label">Reason: </label>
                <textarea 
                    class="form-control" 
                    rows="4" 
                    v-model="creditDetail.reason"
                    name="reason" 
                    :class="{ 'has-error': errors.has('reason') }"
                    v-validate="'required'"
                >
                </textarea>
                <small class="has-error mt-1" v-if="errors.has('reason')">{{ errors.first("reason") }}</small>
            </div>
        </div>
    </modal>
  </template>
  
  <script>
  import Modal from "@/components/GeneralComponents/Modal";
  import { Validator } from "vee-validate";

  export default {
    components: {
      Modal,
    },
    props: {
      creditDetail: {
        type: Object,
        required: true,
      },
    },
    methods: {
      createCreditDetail() {
        this.$validator.validateAll().then((valid) => {
          if (valid) { 
            this.$emit("createCreditDetail", this.creditDetail);
            this.$refs.creditDetailModal.close();
          }
        });
      },
      open() {
        this.$refs.creditDetailModal.open();
      },
    },
  };
  </script>
  