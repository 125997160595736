<template>
    <div class="row mt-3">
        <div class="col-md-7">
          <div class="form-row" v-allowed:view="['STAFF']">
            <div class="form-group col-md-12">
              <label>Service ID</label>
              <input
                type="text"
                class="form-control"
                :disabled="!isAdmin"
                v-model="saleDefinition.definition.service.axxessServiceId"
              />
            </div>
          </div>
          <div class="form-row" v-allowed:view="['STAFF']">
            <div class="form-group col-md-12">
              <label>Client ID</label>
              <input
                type="text"
                class="form-control"
                :disabled="!isAdmin"
                v-model="saleDefinition.definition.service.axxessClientId"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Sim Service ID</label>
              <input
                type="text"
                class="form-control"
                :disabled="!isAdmin"
                v-model="saleDefinition.definition.service.axxessSimServiceId"
              />
            </div>
          </div>
          <div class="row row-spacing">
            <div class="col-md-12">
                <button class="btn btn-success" type="button" @click="saveSale(saleDefinition)" :disabled="!isAdmin">Save</button>
            </div>
          </div>
        </div>
      </div>
</template>


<script>
    export default {
        props: {
            saleDefinition: {
                type: Object,
                required: true
            },
            isAdmin: {
              type: Boolean,
              required: true
            }
        },
        methods: {
         saveSale: function(saleDefinition) {             
           this.$emit("saveSale", saleDefinition);
         }
        },
    }
</script>
