<template>

  <div class="card card-default">
    <div class="card-body">
      <h4>Reseller's list of Clients</h4>
      <div class="table-responsive table-bordered">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>Client Name</th>
              <th>Company Name</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="client in resellerclients" :key="client.clientNumber"
              @click="openNewWindow('CLIENT', client.clientNumber)">
              <td>{{client.clientNumber}}</td>
              <td>{{client.firstnames}} {{client.lastname}}</td>
              <td>{{client.registeredCompanyName}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    data: function () {
      return {
        resellerclients: [],
      }
    },
    mounted: function () {
      this.getClients();
    },
    methods: {
      getClients() {
        this.$http.get(this.$config.aimsAPI + 'clients/' + this.$route.params.clientNumber + '/resellerclients')
          .then(response => {
            this.resellerclients = response.data;
          }, error => {
            console.error(error);
            this.showError('Error Fetching Reseller Clients', error);
          });
      }
    }
  }

</script>
