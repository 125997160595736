<template>
  <div class="row">
    <div class="col-xl- col-lg-12">
      <div class="card card-default">
        <form-wizard
          title=""
          subtitle=""
          @on-complete="onComplete"
          stepSize="sm"
        >
          <tab-content
            title="Zimbra Mail"
            :before-change="() => validateStep('zimbra-mail')"
          >
            <form novalidate data-vv-scope="domain-domain">
              <div class="row">
                <div class="col-xl-6">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('zimbra-mail.domain-name'),
                    }"
                  >
                    <label class="control-label">Domain Name *</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="domain-name"
                      v-model="
                        filledInSaleDefinition.requiredObjs.domain.domainName
                      "
                    />
                    <span
                      class="has-error"
                      v-if="errors.has('zimbra-mail.domain-name')"
                      >{{ errors.first("zimbra-mail.domain-name") }}</span
                    >
                  </div>
                </div>
              </div>
            </form>
          </tab-content>
        </form-wizard>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Address from "@/components/GeneralComponents/Address";

export default {
  components: {
    FormWizard,
    TabContent,
    Address,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filledInSaleDefinition: {
        requiredObjs: {
          domain: {},
        },
      },
    };
  },
  mounted: function () {
    this.filledInSaleDefinition = this.emptySaleDefinition;
  },
  methods: {
    onComplete() {
      this.$swal({
        title:
          "Add " + this.filledInSaleDefinition.requiredObjs.domain.domainName,
        text:
          "Are you sure you want to add " +
          this.filledInSaleDefinition.requiredObjs.domain.domainName +
          "?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Add Domain",
      }).then((result) => {
        if (result.value) {
          this.$emit("createSale", this.filledInSaleDefinition);
        }
      });
    },
    validateStep: function (stepName) {
      return this.$validator.validateAll(stepName).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>