<template>
  <div>
        <div class="card card-default">
            <div class="card-body">
                <div class="row row-spacing">
                    <div class="col-md-12">
                        <TaskHistory :days="10" :taskName="taskName" @tasksRunningChanged="tasksRunningChanged" ref="taskHistory" :includeInputParams="false"/>
                    </div>
                </div>
                <hr>
                <div v-if="!isRunningTasks">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Bankstatement csv file, csv containing Date,Description,Amount,Balance</label>
                            <input type="file" class="form-control" @change="fileSelected($event)"/>
                        </div>
                    </div>
                    <div class="row row-spacing">
                        <div class="col-md-12">
                            <button class="btn btn-primary" @click="uploadBankStatement()">Upload Bank Statement</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import TaskHistory from './TaskHistory'

export default {
    components: {
        TaskHistory
    },
    data(){
        return {
            taskName: 'bankstatement-upload',
            bankStatementFile: null,
            bankStatementFileParam: {
                name: 'bankStatementFile',
                type: 'CONTENT',
                kind: 'INPUT'
            },
            isRunningTasks: false
        }
    },
    methods: {
        uploadBankStatement(){
            if(!this.bankStatementFile || this.bankStatementFile.length == 0){
                this.showWarning('Please select a file');
                return;
            }
            this.$swal({
                title: 'Are you sure you want to upload ' + this.bankStatementFile.name + '?',
                type: 'warning',
                showCancelButton: true,
                cancelButtonColor: '',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.value) {
                    this.$http.post(this.$config.aimsAPI + 'tasks/definitions/run?taskName=' + this.taskName + "&runInForeground=true", [this.bankStatementFileParam]).then(
                        response => {
                            this.showSuccess('Bank Statement Upload Task Submitted');
                            this.$refs.taskHistory.getTaskHistory();
                        },
                        error => {
                            console.error(error);
                            this.showError("Error Uploading Bank Statement", error);
                        });
                }
            });
        },
        fileSelected(event){
            this.bankStatementFile = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(this.bankStatementFile);
            reader.onload = function () {
                let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                if ((encoded.length % 4) > 0) {
                    encoded += '='.repeat(4 - (encoded.length % 4));
                }
                this.bankStatementFileParam.value = encoded;
                this.bankStatementFileParam.meta = this.bankStatementFile.name;
            }.bind(this);
            reader.onerror = function (error) {
                console.error('Error selecting file: ', error);
                this.showError('Error selecting file')
            };
        },
        tasksRunningChanged(isTasksRunning){
            this.isRunningTasks = isTasksRunning;
        }
    },
    mounted(){
        this.$root.$emit('SET_PAGE_TITLE', 'Bank Statement Upload');
    }
}
</script>

<style>

</style>