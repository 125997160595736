<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-default">
        <div class="card-header">
          <h3>New Axxess Ftth Service</h3>
        </div>
        <div class="card-body">
          <form-wizard @on-complete="onComplete" title="" subtitle="" shape="tab" color="#d71921" stepSize="sm">
            <tab-content title="Package Selection" icon="fa fa-cube" :before-change="()=>validateStep('bundle-selection')">
              <form class="row" novalidate data-vv-scope="bundle-selection">
                <div class="col-lg-6">
                    <div class="form-group" :class="{'has-error': errors.has('bundle-selection.Package')}">
                        <label>Choose Package *</label>
                        <v-select :options="emptySaleDefinition.selectableOptions.bundle" valueProp="axxessFtthPackageNumber" name="Package" v-validate="'required'" class="mb-2" label="description" v-model="filledInSaleDefinition.requiredObjs.service.axxessFtthPackageNumber" placeholder="Select Package"></v-select>
                        <span class="has-error" v-if="errors.has('bundle-selection.Package')">{{errors.first('bundle-selection.Package')}}</span>
                    </div>
                </div>
              </form>
            </tab-content>
            <tab-content title="Delivery Info" icon="fa fa-truck" :before-change="()=>validateStep('delivery-info')">
              <div class="row">
                <Address class="col-xl-8" @addressChanged="addressChanged" @addressValid="deliveryAddressValidChanged" :addressStructure="filledInSaleDefinition.requiredObjs.orderAddress" :addressTypes="filledInSaleDefinition.supportingEnums.orderAddress.addressType" />
              </div>
            </tab-content>
          </form-wizard>
        </div>
      </div>
    </div>
    <WizardCheckout 
      ref="wizardCheckoutModal"
      :description="selectedPackage.description"
      :prices="selectedPackage.axxessftthpackageprices"
      @checkout="finalize"
    />
  </div>
</template>

<script>
  import {FormWizard, TabContent} from "vue-form-wizard"
  import "vue-form-wizard/dist/vue-form-wizard.min.css"
  import Address from '@/components/GeneralComponents/Address'
  import WizardCheckout from '@/components/ObjParts/MultipleLoaded/WizardCheckout'
  
  export default {
    components: {
      FormWizard,
      TabContent,
      WizardCheckout,
      Address
    },
    props: {
      emptySaleDefinition: {
        type: Object,
        required: true
      }
    },
    data(){
        return {
          filledInSaleDefinition: {
            selectableOptions: {
            bundle: []
            },
            supportingEnums: {
              service: {},
              bundle: {},
              order: {
              },
              orderAddress: {
                addressType: []
              },
            },
            requiredObjs: {
              service: {},
              order: {},
              orderAddress: {}
            }
          },
          selectedPackage: {
            description: '',
            axxessftthpackageprices: []
          }
        }
    },
    mounted(){
      this.filledInSaleDefinition = JSON.parse(JSON.stringify(this.emptySaleDefinition));
    },
    methods: {
      validateStep: function(stepName) {
        return this.$validator.validateAll(stepName).then(result => {  
          if (result) {
            return true;
          } else {
            return false;
          }
        });
      },
      addressChanged(address) {
        this.filledInSaleDefinition.requiredObjs.orderAddress = address;
      },
      deliveryAddressValidChanged(isValid) {
        this.deliveryAddressValid = isValid;
      },
      getPackage(p){
          return this.$http.get(this.$config.aimsAPI + 'axxess/ftth/package/' + p);
      },
      async onComplete() {
        await this.getPackage(this.filledInSaleDefinition.requiredObjs.service.axxessFtthPackageNumber).then(response => {
              this.selectedPackage = response.data;
        })

        this.$refs.wizardCheckoutModal.open();
      },
      finalize(){
        this.$emit('createSale', this.filledInSaleDefinition);
      }
    }
}
</script>

<style>

</style>

