<template>
  <modal
    :title="pricePackage.objKey ? 'Edit Package' : 'Add New Package'"
    ref="modifyPriceModal"
    @save="savePricePackage()"
  >
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> Description </label>
        <input
          type="text"
          class="form-control"
          v-model="pricePackage.label"
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10" v-if="pricePackage.cabinetPowerBillingPackageNumber">
        <label class="control-label"> Expired </label>
        <v-select :options="['true', 'false']" v-model="expired"></v-select>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
export default {
  props: {
    pricePackage: {
      type: Object,
      required: true,
    },
  },
  components: {
    Modal,
  },
  computed: {
    expired: {
      get() {
        return this.pricePackage.expired.toString();
      },
      set(expired) {
        this.pricePackage.expired = expired;
      },
    },
  },
  methods: {
    open() {
      this.$refs.modifyPriceModal.open();
    },
    savePricePackage() {
      this.$refs.modifyPriceModal.isLoading();
      this.$http
        .post(
          this.$config.aimsAPI + "pricing/pricepackages/save",
          this.pricePackage
        )
        .then(
          (response) => {
            this.$refs.modifyPriceModal.close();
            if (this.pricePackage.cabinetPowerBillingPackageNumber) {
              this.showSuccess("Successfully Edited Package");
              this.$emit("packageEdited");
            } else {
              this.showSuccess("Successfully Added Package");
              this.$emit("packageAdded", response.data);
            }
          },
          (error) => {
            this.showError("Error Saving Pricing", error);
            console.error(error);
          }
        )
        .finally(() => {
          this.$refs.modifyPriceModal.isLoading();
        });
    },
  },
};
</script>