<template>
  <div class="row">
    <div class="col-xl-8 col-lg-12">
      <div class="card card-default">
        <form-wizard
          title=""
          subtitle=""
          @on-complete="onComplete"
          stepSize="sm"
        >
          <tab-content
            title="Liquid Layer 2 Details"
            :before-change="() => validateStep('liquidlayer2')"
          >
            <form novalidate data-vv-scope="liquidlayer2">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('liquidlayer2.circuitNumber'),
                    }"
                  >
                    <label>Circuit Number *</label>
                    <input
                      type="text"
                      class="form-control"
                      name="circuitNumber"
                      data-vv-as="Circuit Number"
                      v-validate="'required'"
                      v-model="
                        filledInSaleDefinition.requiredObjs.liquidLayer2Line
                          .circuitNumber
                      "
                    />
                    <span
                      class="has-error"
                      v-if="errors.has('liquidlayer2.circuitNumber')"
                      >{{ errors.first("liquidlayer2.circuitNumber") }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('liquidlayer2.speed'),
                    }"
                  >
                    <label>Service Speed *</label>
                    <v-select
                      :options="[50000000, 100000000, 150000000, 200000000]"
                      name="speed"
                      data-vv-as="Service Speed"
                      v-validate="'required'"
                      :get-option-label="getLabel"
                      @input="setServiceSpeed"
                      v-model="serviceSpeed"
                      placeholder="Select Service Speed"
                    ></v-select>
                    <span
                      class="has-error"
                      v-if="errors.has('layer2link.speed')"
                      >{{ errors.first("layer2link.speed") }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('liquidlayer2.sideA'),
                    }"
                  >
                    <label>A-Side *</label>
                    <input
                      type="text"
                      class="form-control"
                      name="sideA"
                      data-vv-as="A-Side"
                      v-validate="'required'"
                      v-model="
                        filledInSaleDefinition.requiredObjs.liquidLayer2Line
                          .sideA
                      "
                    />
                    <span
                      class="has-error"
                      v-if="errors.has('liquidlayer2.sideA')"
                      >{{ errors.first("liquidlayer2.sideA") }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('liquidlayer2.sideB'),
                    }"
                  >
                    <label>B-side *</label>
                    <input
                      type="text"
                      class="form-control"
                      name="sideB"
                      data-vv-as="B-Side"
                      v-validate="'required'"
                      v-model="
                        filledInSaleDefinition.requiredObjs.liquidLayer2Line
                          .sideB
                      "
                    />
                    <span
                      class="has-error"
                      v-if="errors.has('liquidlayer2.sideB')"
                      >{{ errors.first("liquidlayer2.sideB") }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('liquidlayer2.vlanName'),
                    }"
                  >
                    <label>VLAN Name *</label>
                    <input
                      type="text"
                      class="form-control"
                      name="vlanName"
                      data-vv-as="VLAN Name"
                      v-validate="'required'"
                      v-model="
                        filledInSaleDefinition.requiredObjs.liquidLayer2Line
                          .vlanName
                      "
                    />
                    <span
                      class="has-error"
                      v-if="errors.has('liquidlayer2.vlanName')"
                      >{{ errors.first("liquidlayer2.vlanName") }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('liquidlayer2.vlanID'),
                    }"
                  >
                    <label>VLAN ID *</label>
                    <input
                      type="text"
                      class="form-control"
                      name="vlanID"
                      data-vv-as="VLAN ID"
                      v-validate="'required'"
                      v-model="
                        filledInSaleDefinition.requiredObjs.liquidLayer2Line
                          .vlanId
                      "
                    />
                    <span
                      class="has-error"
                      v-if="errors.has('liquidlayer2.vlanID')"
                      >{{ errors.first("liquidlayer2.vlanID") }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('liquidlayer2.cost'),
                    }"
                  >
                    <label>Cost (ex VAT) *</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">R</span>
                      </div>
                      <input
                        type="number"
                        class="form-control"
                        name="cost"
                        data-vv-as="Cost"
                        v-validate="'required'"
                        v-model="
                          filledInSaleDefinition.requiredObjs.liquidLayer2Line
                            .costExVat
                        "
                      />
                    </div>
                    <span
                      class="has-error"
                      v-if="errors.has('liquidlayer2.cost')"
                      >{{ errors.first("liquidlayer2.cost") }}</span
                    >
                  </div>
                </div>
              </div>
            </form>
          </tab-content>
        </form-wizard>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  components: {
    FormWizard,
    TabContent,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filledInSaleDefinition: {
        requiredObjs: {
          liquidLayer2Line: {},
        },
      },
      serviceSpeed: null,
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
  },
  methods: {
    validateStep(stepName) {
      return this.$validator.validateAll(stepName).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
    onComplete() {
      this.$swal({
        title:
          "Service Speed: " +
          this.speed_bitsToMbits(this.serviceSpeed) +
          " Mbit/s",
        text:
          "R " +
          this.filledInSaleDefinition.requiredObjs.liquidLayer2Line.costExVat,
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Create Service",
      }).then((result) => {
        if (result.value) {
          this.$emit("createSale", this.filledInSaleDefinition);
        } else {
        }
      });
    },
    getLabel(speed) {
      return this.speed_bitsToMbits(speed) + " Mbit/s";
    },
    setServiceSpeed(speed) {
      this.filledInSaleDefinition.requiredObjs.liquidLayer2Line.uploadSpeed =
        this.filledInSaleDefinition.requiredObjs.liquidLayer2Line.downloadSpeed =
          speed;
    },
  },
};
</script>

<style>
</style>