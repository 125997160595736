<template>
    <div class="row mt-3">
        <div class="col-md-12">
          <table class="table table-bordered">
            <thead>
              <tr class="dark-row">
                <th>Created</th>
                <th>Instruction</th>
                <th>Effective Date</th>
                <th>Status</th>
                <th>Info</th>
              </tr>
            </thead>
            <tbody v-if="saleDefinition.definition.service.instructions.length > 0">
              <tr v-for="instruction in saleDefinition.definition.service.instructions" :key="instruction.axxessInstructionNumber">
                <td>{{ dateTimeFormat(instruction.created) }}</td>
                <td>{{ instruction.instructionType }}</td>
                <td>{{ pendingDateTimeFormat(instruction.effectiveDate) }}</td>
                <td>{{ instruction.status }}</td>
                <td>{{ instruction.instructionInfo }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="3">No instruction history</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
</template>

<script>
    export default {
        props: {
            saleDefinition: {
                type: Object,
                required: true
            },
        },
        methods: {
          pendingDateTimeFormat: function (value) {
            if (value==="1970-01-01 02:00:00") {
              return "Pending";
            } else {
              if (value)  {
                return this.dateTimeFormat(value);
              }
            }
          },
        }
    }
</script>

<style lang="scss" scoped>
.dark-row {
    width: 900px !important;
    background-color: #3a3f51;

    th {
        background-color: #3a3f51;
        color: #FFFFFF !important;

        position: sticky;
        top: 0;
        z-index: 2;
        font-size: .95em;
        width: calc(100px + 100em);
    }

    .th-width {
        width: calc(100px + 30em);
    }
}

</style>
