<template>
    <div class="row">
        <div class="col-md-12">
            <div class="table-responsive table-bordered">
            <table class="table">
                <thead>
                <tr>
                    <th>Detail Type</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Quantity</th>
                    <th>Unit</th>
                    <th>Duration</th>
                    <th>Price (Excl. VAT)</th>
                    <th>Created</th>
                    <th>File ID</th>
                </tr>
                </thead>
                <tbody v-if="liquidSubscriptionDetail.length > 0" >
                <tr v-for="detail in liquidSubscriptionDetail" :key="detail.liquidSubscriptionDetailNumber">
                    <td>{{ detail.detailType }}</td>
                    <td>{{ dateFormat(detail.detailStartDate) }}</td>
                    <td>{{ dateFormat(detail.detailEndDate) }}</td>
                    <td>{{ detail.quantity }}</td>
                    <td>{{ detail.subscriptionUom }}</td>
                    <td>{{ detail.duration }}</td>
                    <td>{{ currencyFormat(detail.subscriptionPriceExclVat) }}</td>
                    <td>{{ detail.created }}</td>
                    <td>{{ detail.uploadedFileId }}</td>
                </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="6">No Details for this Subscription</td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        liquidSubscriptionDetail: {
            type: Array,
            required: true,
        }
    }
}
</script>

<style lang="scss" scoped>

</style>