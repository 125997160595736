<template>
    <ServiceScreenLayout :parts="parts">
        <template v-slot:network>
            <div>
                <div class="card-header">
                    <h3>Network Details</h3>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-10 row">
                          <div class="col-md-5">
                            <div class="form-group">
                              <label>VLAN ID</label>
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  disabled
                                  v-model="saleDefinition.definition.network.vlanId"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-5">
                            <div class="form-group">
                              <label>VLAN Name</label>
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="saleDefinition.definition.network.vlanName"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row-spacing col-md-12">
                          <h4> Assigned IP Network Addresses</h4>
                          <FttbRoutedNetworks
                            :ipv4Networks="saleDefinition.definition.network.ipv4networks"
                            :ipv6Networks="saleDefinition.definition.network.ipv6networks"
                            @routeAdded="addRoute"
                            @routeRemoved="removeRoute"
                          />
                          <div class="form-row row-spacing justify-content-between" v-allowed:view="['STAFF']">
                            <div class="form-group row-spacing col-auto">
                              <button type="button" @click="saveSaleDefinition()" class="btn btn-success">Save</button>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:internet>
            <div>
                <div class="card-header">
                    <h3>Internet Details</h3>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="form-group row">
                                <label class="col-md-4">Description:</label>
                                <div class="input-group col-md-7">
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="saleDefinition.definition.internet.description"
                                  />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4">Bandwidth package:
                                </label>
                                <div class="input-group col-md-7">
                                  <WarningBubble :poptext="'Package has expired'" v-if="expiredPackage"/>
                                  <v-select
                                    name="bandwidth"
                                    label="label"
                                    v-model="bandwidthSelected"
                                    :options="bandwidthOptions"
                                  >
                                  </v-select>
                                </div>
                            </div>
                            <div class="form-row row-spacing justify-content-between" v-allowed:view="['STAFF']">
                              <div class="form-group row-spacing col-auto">
                                <button type="button" @click="saveSaleDefinition()" class="btn btn-success">Save</button>
                              </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                         

                        </div>
                    </div>
                </div>
            </div>
        </template>
    </ServiceScreenLayout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";
import FttbRoutedNetworks from "@/components/ObjParts/MultipleCaptured/FttbRoutedNetworks"
import WarningBubble from "@/components/GeneralComponents/WarningBubble"

export default {
  extends: SaleFunctions,
  components: {
      ServiceScreenLayout,
      FttbRoutedNetworks,
      WarningBubble
  },
  data() {
    return {
      parts: [
        {
          name: "internet",
          icon: "fa fa-plug",
          displayName: "Internet",
          permissions: ["*"],
        },
        {
          name: "network",
          icon: "fa fa-cloud",
          displayName: "Network",
          permissions: ["*"],
        },
      ],
      saleNumber: null,
      saleDefinition: {
        definition: {
            network: {
              ipv4networks: [],
              ipv6networks: []
            },
            internet: {},
        },
      },
      bandwidthSelected: {},
      bandwidthOptions: [],
      expiredPackage: false,
    };
  },
  mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition() {
      this.getSaleDefinition(this.saleNumber).then(
        (success) => {
          this.saleDefinition = success.data;

          this.bandwidthOptions = [];
          for (let i = 0; i < this.saleDefinition.selectableOptions.internetPackage.length; i++) {
            if (this.saleDefinition.selectableOptions.internetPackage[i].expired == false) {
              this.bandwidthOptions.push({
                label: this.saleDefinition.selectableOptions.internetPackage[i].description,
                value: this.saleDefinition.selectableOptions.internetPackage[i].dataCentreInternetPackageNumber
              });
            }

            if (this.saleDefinition.selectableOptions.internetPackage[i].dataCentreInternetPackageNumber == this.saleDefinition.definition.internet.dataCentreInternetPackageNumber) {
              this.bandwidthSelected = {
                label: this.saleDefinition.selectableOptions.internetPackage[i].description,
                value: this.saleDefinition.selectableOptions.internetPackage[i].dataCentreInternetPackageNumber
              };
              if (this.saleDefinition.selectableOptions.internetPackage[i].expired) {
                this.expiredPackage = true;
              }
            }
          }

        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
    },
    saveSaleDefinition: function() {
      this.saleDefinition.definition.internet.dataCentreInternetPackageNumber = this.bandwidthSelected.value;

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
                this.loadSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Sale Definition", error);
              }
            );
        }
      });
    },
    addRoute(data) {
      let newRoute = null
      
      if (data.family.toLowerCase() == "ipv4") {
        newRoute = this.saleDefinition.supportingObjs.ipv4networks
      } else if (data.family.toLowerCase() == "ipv6") {
        newRoute = this.saleDefinition.supportingObjs.ipv6networks
      }

      if (newRoute) {
        newRoute.metric = data.metric
        newRoute.address = data.address
        newRoute.shortMask = data.shortMask
        newRoute.vlanNumber = this.saleDefinition.definition.network.vlanNumber
        
        if (data.family.toLowerCase() == "ipv4") {
          this.saleDefinition.definition.network.ipv4networks.push(newRoute);
          this.saveSaleDefinition();
        } else if (data.family.toLowerCase() == "ipv6") {
          this.saleDefinition.definition.network.ipv6networks.push(newRoute);
          this.saveSaleDefinition();
        }
      } else {
        this.$swal({
          title: 'Could not find route family',
          type: 'warning',
        })
      }
    },
    removeRoute(data) {
      if (data.family.toLowerCase() == "ipv4") {
        for (let i = 0; i < this.saleDefinition.definition.network.ipv4networks.length; i++) {
          if (this.saleDefinition.definition.network.ipv4networks[i] == data) {
            this.deleteObjPart(this.saleDefinition.definition.network.ipv4networks[i])
              .then(
                (response) => {
                  this.showSuccess("Network Removed Succesfully");
                  this.loadSaleDefinition()
                },
                (error) => {
                  console.error(error);
                  this.showError("Error Removing Network", error);
              });
          }
        }
      } else if (data.family.toLowerCase() == "ipv6") {
        for (let i = 0; i < this.saleDefinition.definition.network.ipv6networks.length; i++) {
          if (this.saleDefinition.definition.network.ipv6networks[i] == data) {
            this.deleteObjPart(this.saleDefinition.definition.network.ipv6networks[i])
              .then(
                (response) => {
                  this.showSuccess("Network Removed Succesfully");
                  this.loadSaleDefinition()
                },
                (error) => {
                  console.error(error);
                  this.showError("Error Removing Network", error);
              });
          }
        }
      }
    },
  },
};
</script>

<style>
</style>