<template>
    <div class="row">
        <div class="col-md-8">
            <div class="card card-default">
                <service-wizard-layout
                    title=""
                    subtitle=""
                    :parts="parts"
                    :onComplete="onComplete"
                    :validateStep="validateStep"
                >
                <template v-slot:certificate>
                    <form novalidate data-vv-scope="certificate">
                        <div class="row">
                            <div class="col-xl-8 col-lg-">
                                <div class="form-group" :class="{'has-error': errors.has('certificate.siteName')}">
                                    <label>Site Name</label>
                                    <input type="text" name="siteName" v-validate="'required'" v-model="filledInSaleDefinition.requiredObjs.certificate.siteName" class="form-control" >
                                    <span class="has-error" v-if="errors.has('certificate.siteName')">
                                            {{errors.first('certificate.siteName')}}
                                    </span>
                                </div>
                                <div class="form-group" :class="{'has-error': errors.has('certificate.type')}">
                                    <label>Type</label>
                                    <v-select
                                        :options="filledInSaleDefinition.supportingEnums.certificate.type"
                                        label="description"
                                        valueProp="name"
                                        name="type"
                                        v-validate="'required'"
                                        placeholder="Select a type"
                                        v-model="filledInSaleDefinition.requiredObjs.certificate.type"
                                    >
                                    </v-select>
                                    <span class="has-error" v-if="errors.has('certificate.type')">
                                            {{errors.first('certificate.type')}}
                                    </span>
                                </div>
                                <div class="form-group" :class="{'has-error': errors.has('certificate.csr')}">
                                    <label>CSR</label>
                                    <textarea class="form-control" name="csr" v-validate="'required'" v-model="filledInSaleDefinition.requiredObjs.certificate.csr" cols="15" rows="5"></textarea>
                                    <span class="has-error" v-if="errors.has('certificate.csr')">
                                            {{errors.first('certificate.csr')}}
                                    </span>
                                </div>
                                <div class="form-group" :class="{'has-error': errors.has('certificate.privateKey')}">
                                    <label>Private Key</label>
                                    <textarea class="form-control" name="privateKey" v-validate="'required'" v-model="filledInSaleDefinition.requiredObjs.certificate.privateKey" cols="15" rows="5"></textarea>
                                    <span class="has-error" v-if="errors.has('certificate.privateKey')">
                                            {{errors.first('certificate.privateKey')}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </form>
                </template>
                </service-wizard-layout>
            </div>
        </div>
    </div>
</template>

<script>
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";

export default {
    components: {
        ServiceWizardLayout
    },
    props: {
        emptySaleDefinition: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            parts: [
                {
                    name: "certificate",
                    displayName: "SSL Certificate",
                    icon: "fa fa-info",
                }
            ],
            filledInSaleDefinition: {
                requiredObjs: {
                    certificate: {},
                },
                supportingEnums: {
                    certificate: {
                        type: []
                    }
                }
            },
        }
    },
    mounted() {
        this.filledInSaleDefinition = this.emptySaleDefinition;        
    },
    methods: {
        onComplete() {
        this.$emit("createSale", this.filledInSaleDefinition);
        },
        validateStep(scope) {
        return this.$validator.validateAll(scope).then((result) => {
            if (result) {
            return true;
            } else {
            return false;
            }
        });
        },
    },
}
</script>
