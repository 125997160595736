<template>
  <div class="form-group">
    <input
      v-if="isClient"
      type="text"
      class="form-control border-right-0"
      placeholder="Generate password"
      v-model="password"
      readonly
      :disabled="!userCanEdit"
    />
    <div class="input-group with-focus" v-else>
      <input
        type="text"
        class="form-control border-right-0"
        placeholder="Generate password"
        v-model="password"
        :disabled="!userCanEdit"
      />
      <div class="input-group-append">
        <span
          @click="copyText(password)"
          class="input-group-text btn btn-info rounded text-white border-left-0"
        >
          <i class="fa fa-clipboard"></i>
        </span>
      </div>
      <button
        v-tooltip="'Generate Password'"
        v-allowed:view="['STAFF']"
        v-if="userCanEdit"
        class="btn btn-outline-warning"
        type="button"
        @click="generatePassword()"
      >
        <i class="fa fa-refresh"></i>
      </button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  props: {
    password: {
      type: String,
      required: false,
    },
    userCanEdit: {
      type: Boolean,
      required: true,
    },
    isClient: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: String,
      required: false
    }
  },
  watch: {
      password(newValue) {
        if (newValue) {
          this.$emit('changePassword', newValue);        
        }
      },
  },
  created() {
    Vue.config.silent = true;
  },
  methods: {
    generatePassword: function () {
      if (this.validation) {
        //use validation property for zimbra password .e.g 'zimbra'
        this.$http
        .get(this.$config.aimsAPI + "password/generate/10/"+this.validation)
        .then(
          (response) => {
            this.password = response.bodyText;
          },
          (error) => {
            console.error(error);
            this.showError("Error Generating Password", error);
          }
        );
      } else {
        //original password generation
        this.$http
        .get(this.$config.aimsAPI + "password/generate/8")
        .then(
          (response) => {
            this.password = response.bodyText;
          },
          (error) => {
            console.error(error);
            this.showError("Error Generating Password", error);
          }
        );
      }
    },
  },
};
</script>

<style></style>
