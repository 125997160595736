<template>
  <div>
    <ul class="nav nav-pills nav-fill" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link active"
          id="package-tab"
          data-toggle="tab"
          href="#package"
          role="tab"
          aria-controls="package"
          aria-selected="true"
          ><i class="fa fa-dropbox"></i> Packages</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="topup-tab"
          data-toggle="tab"
          href="#topup"
          role="tab"
          aria-controls="topup"
          aria-selected="true"
          ><i class="fa fa-database"></i> Topups</a
        >
      </li>
    </ul>
    <div class="tab-content">
      <div
        class="tab-pane active"
        id="package"
        role="tabpanel"
        aria-labelledby="package-tab"
      >
        <AxxessLtePackagePrices
          :productNumber="productNumber"
          :productSupplier="productSupplier"
          :userEditRight="userEditRight"
        />
      </div>
      <div
        class="tab-pane"
        id="topup"
        role="tabpanel"
        aria-labelledby="topup-tab"
      >
        <AxxessLteTopupPrices
          :productNumber="productNumber"
          :productSupplier="productSupplier"
          :userEditRight="userEditRight"
        />
      </div>
    </div>
  </div>
</template>
<script>
import AxxessLtePackagePrices from "@/components/Admin/AxxessPackages/AxxessLtePackagePrices";
import AxxessLteTopupPrices from "@/components/Admin/AxxessPackages/AxxessLteTopupPrices";
export default {
  props: {
    productNumber: {
      type: Number,
      required: true,
    },
    productSupplier: {
      type: String,
      required: true,
    },
    userEditRight: {
      type: Boolean,
      required: true
    },
  },
  components: {
    AxxessLtePackagePrices,
    AxxessLteTopupPrices,
  },
};
</script>

<style scoped>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #f05050 !important;
}
.nav-pills .nav-link {
  background-color: #f3f2ee;
}
a {
  color: rgba(0, 0, 0, 0.6);
}
</style>