<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-default">
        <ServiceScreenLayout :parts="parts" :setActiveTab="activeTab">
          <template v-slot:products>
            <div @mouseup="productListState.setShowLegacy(false)">
              <div class="card-heading"></div>
              <div class="card-body">
                <div class="row row-spacing">
                  <div class="col-md-12">
                    <ProductListTable 
                      :productVersion="'NEW'"
                      :products="products"
                      :types="types"
                      :suppliers="suppliers"
                      :vendors="vendors"
                      :segments="segments"
                      :subSegments="subSegments"
                      :isFetchingData="isFetchingData"
                      @productClicked="productClicked"
                      @createProduct="newProduct()"
                      @getSubsegments="getSubsegments"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:legacy >
            <div>
              <div class="card-heading"></div>
              <div class="card-body">
                <div class="row row-spacing">
                  <div class="col-lg-12">
                    <ProductListTable 
                      :productVersion="'OLD'"
                      :products="legacyProducts"
                      :types="types"
                      :suppliers="suppliers"
                      :vendors="vendors"
                      :segments="segments"
                      :subSegments="subSegments"
                      :isFetchingData="isFetchingData"
                      @productClicked="productClicked"
                      @getSubsegments="getSubsegments"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </ServiceScreenLayout>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import ProductListTable from "@/components/Admin/ProductListTable"

import { ProductListState } from '@/stores/pages/Admin/ProductList.ts';

export default {
  components: {
    ServiceScreenLayout,
    ProductListTable
  },
  data() {
    const productListState = ProductListState();    
    return {
      productListState,
      activeTab: "products",
      parts: [
        {
          name: "products",
          icon: "fa fa-product-hunt",
          displayName: "Products",
          permissions: ["STAFF"],
        },
        {
          name: "legacy",
          icon: "fa fa-circle",
          displayName: "Legacy",
          permissions: ["STAFF"],
        }
      ],
      products: [],
      legacyProducts: [],
      types: [],
      suppliers: [],
      vendors: [],
      segments: [],
      subSegments: [],
      isFetchingData: false
    };
  },
  async created() {
    document.title = this.$route.meta.title;
    const hostUrl = this.$config.aimsAPI;
    const hostUrlv2 = this.$config.aimsAPIv2;

    this.isFetchingData = true;

    await this.$http.get(`${hostUrl}products?defType=NEW`).then(
      (response) => {
        this.products = response.data;
        this.isFetchingData = false;
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching Products", error);
      }
    );

    await this.$http.get(`${hostUrl}products?defType=OLD`).then(
      (response) => {
        this.legacyProducts = response.data;
        this.isFetchingData = false;
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching Products", error);
      }
    );

    await this.$http.get(`${hostUrl}products/classifications/types`).then(
          (response) => {
            this.types = response.data;
          },
          (error) => {
              console.error(error);
              this.showError("Error fetching product types", error);
          }
      );

      await this.$http.get(`${hostUrlv2}suppliers`).then(
        (response) => {
          this.suppliers = response.data;            
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching product suppliers", error);
        }
      );

      await this.$http.get(`${hostUrlv2}vendors`).then(
        (response) => {
          this.vendors = response.data;  
        },
        (error) => {
            console.error(error);
            this.showError("Error fetching product vendors", error);
        }
      );

      await this.$http.get(`${hostUrlv2}segments`).then(
        (response) => {                  
          this.segments = response.data; 
        },
        (error) => {
            console.error(error);
            this.showError("Error fetching product vendors", error);
        }
      );
    this.getSubsegments();
  },
  mounted () {
    this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);

    if (this.productListState.showLegacy) {
      this.activeTab = "legacy";
    }   
  },
  methods: {
    getNewProducts: function() {
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}products?defType=NEW`).then(
        (response) => {
          this.products = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Products", error);
        }
      );
    },
    getOldProducts: function() {
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}products?defType=OLD`).then(
        (response) => {
          this.legacyProducts = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Products", error);
        }
      );
    },
    newProduct: function() {
      this.$router.push({
        name: "newproduct",
        params: {
          productNumber: 0,
        },
      });
    },
    productClicked: function(product) {
      if (product.definitionType == "Old") {
        this.productListState.setShowLegacy(true)
      } else {
        this.productListState.setShowLegacy(false)
      }

      this.$router.push({
        name: "productdetail",
        params: {
          productNumber: product.productNumber,
        },
      });
    },
    getSubsegments: function(data) {      
      const hostUrlv2 = this.$config.aimsAPIv2;
      let path = "";      
    
      if (data) {   
        let segment = this.segments.find((s) => s.name === data);   
        path = `segment/${segment.segmentNumber}`;
      }

      this.$http.get(`${hostUrlv2}subsegments/${path}`).then(
        (response) => {      
          this.subSegments = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching product subsegments", error);
        }
      );        
    },
  },
};
</script>