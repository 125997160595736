<template>
  <div class="card-body">
    <div class="row" v-if="this.productDetail">
      <div class="col-xl-8 col-lg-10 col-md-12">
        <div class="card card-default">
          <div class="card-header pb-0">
            <h4 class="m-0">Product Information</h4>
          </div>
          <div class="card-body">
            <div class="form-group mb-md-0 row">
              <label class="col-md-4">Product:</label>
              <div class="col-md-8">
                <strong
                  >{{ productDetail.description }} ({{
                    productDetail.productNumber
                  }})</strong
                >
              </div>
            </div>
            <div class="form-group mb-md-0 row">
              <label class="col-md-4">Product Definition:</label>
              <div class="col-md-8">
                <strong>{{ productDetail.productDefinition }}</strong>
              </div>
            </div>
            <div class="form-group mb-md-0 row">
              <label class="col-md-4">For Sale:</label>
              <div class="col-md-8">
                <strong>{{ productDetail.forSale ? "Yes" : "No" }}</strong>
              </div>
            </div>
            <div class="form-group mb-md-0 row">
              <label class="col-md-4">Definition Type:</label>
              <div class="col-md-8">
                <strong>{{ productDetail.definitionType }}</strong>
              </div>
            </div>
            <div class="form-group mb-md-0 row">
              <label class="col-md-4">Prorata Type:</label>
              <div class="col-md-8">
                <strong>{{ productDetail.prorataType }}</strong>
              </div>
            </div>
            <div class="form-group mb-md-0 row">
              <label class="col-md-4">Audit Type:</label>
              <div class="col-md-8">
                <strong>{{ productDetail.auditType }}</strong>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4">Documentation:</label>
              <div class="col-md-8">
                <strong>
                  <span
                    @click="documentationClick()"
                    class="btn-link"
                    style="cursor: pointer"
                    v-allowed:view="['STAFF']"
                  >
                    {{ productDetail.productDocumentationUrl }}
                  </span>
                </strong>
              </div>
            </div>
            <h4>Product Classification</h4>
            <div class="form-group mb-md-0 row">
              <label class="col-md-4">Supplier:</label>
              <div class="col-md-8">
                <strong>{{ supplier.name }}</strong>
              </div>
            </div>
            <div class="form-group mb-md-0 row">
              <label class="col-md-4">Vendor:</label>
              <div class="col-md-8">
                <strong>{{ vendor.name }}</strong>
              </div>
            </div>
            <div class="form-group mb-md-0 row">
              <label class="col-md-4">Segment:</label>
              <div class="col-md-8">
                <strong>{{ segment.name }}</strong>
              </div>
            </div>
            <div class="form-group mb-md-0 row">
              <label class="col-md-4">Subsegment:</label>
              <div class="col-md-8">
                <strong>{{ subSegment.name }}</strong>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4">Type:</label>
              <div class="col-md-8">
                <strong>{{ productDetail.classificationType }}</strong>
              </div>
            </div>
            <h4 v-if="productDetail.productvariables.length > 0">
              Product Variables
            </h4>
            <div
              class="table-responsive table-bordered"
              v-if="productDetail.productvariables.length > 0"
            >
              <table class="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      productVar, index
                    ) in productDetail.productvariables"
                    :key="index"
                  >
                    <td>
                      <span>{{ productVar.description }}</span>
                    </td>
                    <td>
                      <span
                        class="badge"
                        :class="getProductVarTypeClass(productVar.type)"
                        style="margin-bottom: 5px"
                        >{{ productVar.type }}</span
                      >
                    </td>
                    <td>
                      <span v-if="productVar.type == 'COST'"
                        >R {{ productVar.value }}</span
                      >
                      <span v-else>{{ productVar.value }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SaleFunctions from "@/pages/Services/SaleFunctions";

export default {
  extends: SaleFunctions,
  data() {
    return {
      saleDefinition: null,
      productDetail: null,
      supplier: {},
      vendor: {},
      segment: {},
      subSegment: {},
    };
  },
  created() {
    const saleNumber = this.$route.params.saleNumber;
    this.getSaleDefinition(saleNumber).then((success) => {
      this.saleDefinition = success.data;

      this.$http
        .get(
          this.$config.aimsAPI +
            "products/" +
            this.saleDefinition.product.productNumber
        )
        .then((success) => {
          this.productDetail = success.data;
          this.getSuppliers(this.productDetail.supplierNumber);
          this.getVendors(this.productDetail.vendorNumber);
          this.getSegments(this.productDetail.segmentNumber);
          this.getSubSegments(this.productDetail.subSegmentNumber);
        });
    });
  },
  methods: {
    getSuppliers(supplierNumber) {
      const hostUrlv2 = this.$config.aimsAPIv2;

      this.$http.get(`${hostUrlv2}suppliers`).then(
        (response) => {
          const suppliers = response.data;
          this.supplier = suppliers.find((sup) => sup.supplierNumber === supplierNumber);
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching product suppliers", error);
        }
      );
    },
    getVendors(vendorNumber) {
      const hostUrlv2 = this.$config.aimsAPIv2;

      this.$http.get(`${hostUrlv2}vendors`).then(
        (response) => {
          const vendors = response.data;  
          this.vendor = vendors.find((ven) => ven.vendorNumber === vendorNumber);      
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching product vendors", error);
        }
      );
    },
    getSegments(segmentNumber) {
      const hostUrlv2 = this.$config.aimsAPIv2;

      this.$http.get(`${hostUrlv2}segments`).then(
        (response) => {
          const segments = response.data;
          this.segment = segments.find((seg) => seg.segmentNumber === segmentNumber);  
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching product segments", error);
        }
      );
    },
    getSubSegments(subSegmentNumber) {
      const hostUrlv2 = this.$config.aimsAPIv2;

      this.$http.get(`${hostUrlv2}subsegments`).then(
        (response) => {
          const subSegments = response.data;
          this.subSegment = subSegments.find((sub) => sub.subSegmentNumber === subSegmentNumber);  
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching product subsegments", error);
        }
      );
    },
    saleSave() {
      this.saleDefinition.sale.startDate = this.dateTimeFormat(
        this.saleDefinition.sale.startDate
      );

      this.saleDefinition.sale.endDate = this.dateTimeFormat(
        this.saleDefinition.sale.endDate
      );
      if (
        this.saleDefinition.sale.endDate === null ||
        this.saleDefinition.sale.endDate === undefined
      ) {
        this.saleDefinition.sale.endDate = "1970-01-01 02:00:00";
      }

      this.$http
        .put(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/update",
          this.saleDefinition
        )
        .then(
          (response) => {
            this.showSuccess("Save Success");
            this.saleDefinition = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving Openserve Account", error);
          }
        );
    },
    documentationClick() {
      window.open(this.productDetail.productDocumentationUrl);
    },
    getProductVarTypeClass(type) {
      switch (type) {
        case "STRING":
          return "bg-green";
        case "DOUBLE":
          return "bg-warning";
        case "INTEGER":
          return "bg-primary";
      }
    },
  },
};
</script>

<style></style>
