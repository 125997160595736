<template>
  <div class="card card-default">
    <div class="card-header">
      <h4>Address</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-row">
            <div class="form-group col-md-3">
              <label>Type:</label>
              <v-select 
                :disabled="!isStaff"
                :options="typeOptions" 
                label="description" 
                v-model="address.addressType"
                valueProp="value">
              </v-select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-3">
              <label>Sub Premise:</label>
              <input
                :readonly="!isStaff"
                name="Subpremise"
                class="form-control"
                v-model="address.subpremise"
              />
            </div>
            <div class="form-group col-md-3">
              <label>Street Number:</label>
              <input
                :readonly="!isStaff"
                name="Street Number"
                class="form-control"
                v-model="address.streetNumber"
              />
            </div>
            <div class="form-group col-md-6">
              <label>Street Name:</label>
              <input
                :readonly="!isStaff"
                name="Street Name"
                class="form-control"
                v-model="address.streetName"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Suburb:</label>
              <input
                :readonly="!isStaff"
                name="Suburb"
                class="form-control"
                v-model="address.suburb"
              />
            </div>
            <div class="form-group col-md-6">
              <label>City:</label>
              <input
                :readonly="!isStaff"
                name="City"
                class="form-control"
                v-model="address.city"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-3">
              <label>Province:</label>
              <select
                :disabled="!isStaff"
                name="Province"
                class="form-control"
                v-model="address.province"
              >
                <option value="Eastern Cape">Eastern Cape</option>
                <option value="Western Cape">Western Cape</option>
                <option value="Northern Cape">Northern Cape</option>
                <option value="North West">North West</option>
                <option value="Freestate">Freestate</option>
                <option value="Gauteng">Gauteng</option>
                <option value="KwaZulu-Natal">KwaZulu-Natal</option>
                <option value="Limpopo">Limpopo</option>
                <option value="Mpumalanga">Mpumalanga</option>
              </select>
            </div>
            <div class="form-group col-md-3">
              <label>Postal Code:</label>
              <input
                :readonly="!isStaff"
                name="Postal Code"
                class="form-control"
                v-model="address.postalCode"
              />
            </div>
            <div class="form-group col-md-6">
              <label>Country:</label>
              <select
                name="Country"
                :disabled="!isStaff"
                class="form-control"
                v-model="address.country"
              >
                <option value="South Africa">South Africa</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    address: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      typeOptions: [
        {
          value: 'RESIDENTIAL',
          description: 'Residential'
        },
        {
          value: 'BUSINESS',
          description: 'Business'
        },
      ],
    }
  },
  computed: {
    isStaff() {
      return this.userContainsPermission(["STAFF"]);
    },
  },
};
</script>
