<template>
  <div class="card-body">
    <OpenserveLine
      v-allowed:view="['STAFF', 'CLIENT', 'RESELLER', 'SYSTEM']"
      :line="saleDefinition.definition.line"
    />
  </div>
</template>

<script>
import OpenserveLine from "@/components/ObjParts/OptionalConstructed/OpenserveLine";

export default {
  components: {
    OpenserveLine,
  },
  data() {
    return {
      saleDefinition: {
        definition: {
          line: {},
        },
      },
    };
  },
  methods: {
    saveSale() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Line Only sale", error);
              }
            );
        }
      });
    },
  },
  mounted() {
    const saleNumber = this.$route.params.saleNumber;
    this.$http
      .get(this.$config.aimsAPI + "sales/definition/sale/" + saleNumber)
      .then(
        (response) => {
          this.saleDefinition = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching ADSL Account", error);
        }
      );
  },
};
</script>
