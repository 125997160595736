<template>
  <ConnectivityPackagePrices
    :productNumber="productNumber"
    :connectivityPackages="uncappedPackages"
    :emptyPackage="emptyPackage"
    :userEditRight="userEditRight"
    @get-connectivity-packages="getUncappedConnectivityPackages"
  />
</template>

<script>
import ConnectivityPackagePrices from "@/components/Admin/ConnectivityPackages/ConnectivityPackagePrices";
export default {
  props: {
    productNumber: {
      type: Number,
      required: true,
    },
    userEditRight: {
      type: Boolean,
      required: true
    },
  },
  components: {
    ConnectivityPackagePrices,
  },
  data() {
    return {
      uncappedPackages: [],
      emptyPackage: {},
    };
  },
  mounted() {
    this.getUncappedConnectivityPackages();
  },
  methods: {
    getUncappedConnectivityPackages() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "pricing/connectivitypackages/product/" +
            this.productNumber
        )
        .then(
          (response) => {
            this.uncappedPackages = response.data.connectivitypackages;
            this.emptyPackage = this.deepCopyObject(response.data);
          },
          (error) => {
            this.showError("Error fetching Connectivity Packages", error);
            console.error(error);
          }
        );
    },
  },
};
</script>