<template>
  <div class="form-group">
    <div class="row align-items-center">
      <div class="col-md-4 p-3">
        <button
          class="btn btn-info offset-md-7 align-self-end"
          @click="addRouteModal()"
          type="button"
          v-allowed:view="['STAFF']"
        >
          Add IPv4 Ranges
        </button>
      </div>
    </div>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Address</th>
          <th>Mask</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="routedIpv4Network in ipv4Networks"
          :key="routedIpv4Network.apnIpv4RangeNumber"
        >
          <td>{{ routedIpv4Network.address }}</td>
          <td>{{ routedIpv4Network.mask }}</td>
          <td class="text-center" v-allowed:view="['STAFF']">
            <button
              type="button"
              v-tooltip="'Edit Routed Network'"
              @click="
                routedNetwork = deepCopyObject(routedIpv4Network);
                modalTitle = 'Edit Routed Network';
                editRouteModal(routedIpv4Network);
              "
              class="btn btn-sm btn-info m-1"
            >
              <em class="fa fa-edit fa-fw"></em>
            </button>
            <button
              type="button"
              v-tooltip="'Delete Routed Network'"
              @click="deleteRoutedNetwork(routedIpv4Network)"
              class="btn btn-sm btn-primary"
            >
              <em class="fa fa-trash fa-fw"></em>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    ipv4Networks: {
      type: Array,
      required: true,
    },
  },
  computed: {
    allNetworks() {
      let all = [];
      if (this.ipv4Networks) {
        all = all.concat(this.ipv4Networks);
      }
      return all;
    },
  },
  data() {
    return {
      networkRangePackages: [],
    };
  },
  mounted() {
    this.$http
      .get(
        this.$config.aimsAPI +
          "sales/" +
          this.$route.params.saleNumber +
          "/networkrangepackages"
      )
      .then(
        (response) => {
          this.networkRangePackages = response.data;
        },
        (error) => {
          this.showError("Could not fetch Network Range Packages", error);
          console.error(error);
        }
      );
  },
  methods: {
    confirmAddRoute(address, mask) {
      this.$swal({
        title: `Are you sure you want to add a ${address} /${mask} route?`,
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$emit("routeAdded", {
            address,
            mask,
          });
        }
      });
    },
    deleteRoutedNetwork(routedIpv4Network) {
      this.$swal({
        title: `Are you sure you want to remove network ${routedIpv4Network.address} /${routedIpv4Network.mask}?`,
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          
          this.$emit("routeRemoved", routedIpv4Network);
        }
      });
    },
    addRouteModal() {
      this.$emit("addRouteModal");
    },
    editRouteModal(data) {
      this.$emit("editRouteModal", data);
    },
  },
};
</script>

<style>
</style>
