<template>
  <ServiceScreenLayout :parts="parts">
        <template v-slot:vpn>
            <div class="form-group">
                <label>VRF Name</label>
                <input class="form-control" v-model="saleDefinition.definition.vpn.vrfName" />
            </div>
            <div class="form-group">
                <label>Loopback Name</label>
                <input class="form-control" v-model="saleDefinition.definition.vpn.loopbackName" />
            </div>
        </template>
  </ServiceScreenLayout>
</template>

<script>
import ServiceScreenLayout from '@/layouts/ServiceScreenLayout'
import SaleFunctions from '@/pages/Services/SaleFunctions'
export default {
    extends: SaleFunctions,
    components:{
        ServiceScreenLayout
    },
    data(){
        return {
            parts: [
                {
                    name: 'vpn',
                    icon: 'fa fa-link',
                    displayName: 'VPN',
                    permissions: ['*']

                },
            ],
            saleDefinition: {
                definition: {
                    vpn: {}
                }
            }
        }
    },
    mounted(){
        this.saleNumber = this.$route.params.saleNumber;
        this.loadSaleDefinition();
    },
    methods: {
        loadSaleDefinition(){
            this.getSaleDefinition(this.saleNumber).then(success => {
                this.saleDefinition = success.data;
            }, error => {
                console.error(error);
                this.showError("Error Fetching Sale Definition", error);
            });
        }
    }
}
</script>

<style>

</style>