<template>
  <div class="row">
    <div class="col-xl-8 col-lg-12">
      <div class="card card-default">
        <form-wizard
          title=""
          subtitle=""
          @on-complete="onComplete"
          stepSize="sm"
        >
          <tab-content
            title="Hosting"
            :before-change="() => validateStep('hosting')"
          >
            <form novalidate data-vv-scope="hosting">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('hosting.name'),
                    }"
                  >
                    <label class="control-label">Domain Name</label>
                    <input
                      type="text"
                      name="name"
                      v-validate="'required'"
                      class="form-control"
                      v-model="
                        filledInSaleDefinition.requiredObjs.hosting
                          .name
                      "
                    />
                    <span
                      class="has-error"
                      v-if="errors.has('hosting.name')"
                      >{{ errors.first("hosting.name") }}</span
                    >
                  </div>
                </div>
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{ 'has-error': errors.has('hosting.dnsViewNumber') }"
                  >
                    <label class="control-label">DNS View</label>
                    <v-select
                      label="name"
                      name="dnsViewNumber"
                      v-validate="'required'"
                      valueProp="dnsViewNumber"
                      placeholder="Select a DNS View"
                      :options="filledInSaleDefinition.selectableOptions.dnsView"
                      v-model="filledInSaleDefinition.requiredObjs.hosting.dnsViewNumber"
                    >
                    </v-select>
                    <span
                      class="has-error"
                      v-if="errors.has('hosting.dnsViewNumber')"
                      >{{ errors.first("hosting.dnsViewNumber") }}</span
                    >
                  </div>
                </div>
              </div>
            </form>
          </tab-content>
        </form-wizard>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  computed: {},
  components: {
    FormWizard,
    TabContent,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filledInSaleDefinition: {
        selectableOptions: {},
        requiredObjs: {
          hosting: {},
        },
      },
    };
  },
  mounted: function () {
    this.filledInSaleDefinition = this.emptySaleDefinition;
  },
  methods: {
    validateStep: function (stepName) {
      return this.$validator.validateAll(stepName).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
    onComplete() {
      this.$emit("createSale", this.filledInSaleDefinition);
    },
  },
};
</script>