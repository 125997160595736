<template>
  <date-picker
    :lang="lang"
    :type="type"
    :value-type="valueType"
    :format="format"
    v-model="date"
    :confirm="confirm"
    :name="name"
    :placeholder="placeholder"
    :clearable="clearable"
    :disabled="disabled"
    @change="$emit('change', date)"
  ></date-picker>
</template>

<script>
import Vue from "vue";
export default {
  computed: {
    date: {
      get() {
        if (this.valueType != "format") {
          return this.value;
        } else if (this.type == "datetime") {
          return this.dateTimeFormat(this.value);
        } else {
          return this.dateFormat(this.value);
        }
      },
      set(val) {
        if (this.valueType != "format") {
          this.$emit("input", val);
        } else {
          this.$emit("input", this.dateTimeFormat(val));
        }
      },
    },
  },
  props: {
    lang: {
      type: String,
      default: "en",
    },
    type: {
      type: String,
      default: "date",
    },
    valueType: {
      default: "format",
    },
    format: {
      type: [String, Object],
      default() {
        const map = {
          date: "YYYY-MM-DD",
          datetime: "YYYY-MM-DD HH:mm:ss",
          year: "YYYY",
          month: "YYYY-MM",
          time: "HH:mm:ss",
          week: "w",
        };
        return map[this.type] || map.date;
      },
    },
    confirm: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: [String, Object, Date],
    placeholder: {
      type: String,
      required: false,
    },
  },
  created() {
    Vue.config.silent = true;
  },
};
</script>