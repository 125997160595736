<template>
  <div class="row">
    <div class="col-md-7">
      <div class="card-header" style="padding-left: 0px">
        <h4>SIP Trunk</h4>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Registrar</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                v-model="sipTrunk.registrar"
                :disabled="disabledControls"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Username</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                disabled
                v-model="sipTrunk.username"
              />
            </div>
         </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Password</label>
            <PasswordInput :password="sipTrunk.password" :userCanEdit="!disabledControls" @changePassword="changePassword"/>
          </div>
        </div>
      </div>
      <div class="row" v-if="did">
        <div class="col-md-12">
          <div class="form-group">
            <label>DID Number</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                v-model="did.number"
                :disabled="disabledControls"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="form-row justify-content-between" v-allowed:view="['STAFF']">
        <div class="form-group col-auto">
          <button type="button" @click="saveBtn()" class="btn btn-success">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PasswordInput from '@/components/GeneralComponents/InputControls/PasswordInput';

  export default {
    components: {
      PasswordInput,
    },
    props: {
      sipTrunk: {
        type: Object,
        required: true
      },
      did: {
        type: Object,
        required: false
      },
      disabledControls: {
        type: Boolean,
        required: false
      }
    },
    methods: {
      changePassword: function(data) {
        if (data) {
          this.sipTrunk.password = data;
        }
      },
      saveBtn() {
        if (this.did) {
          if (this.did.number == null || this.did.number == "" || isNaN(this.did.number)) {
            return this.$swal({
              title: "Error",
              text: "You need to insert a number",
              type: 'warning',
              confirmButtonText: 'Ok',
            });
          } else { 
            this.$emit('save-sale');
          }
        } else {
          this.$emit('save-sale');
        }
      },
    },
  }

</script>

<style>

</style>
