

<template>
    <div class="above-overlay">
        <div class="pull-left">
            <div class="card card-default">
                <div class="card-body">
                    <div class="">
                        <button type="button" class="btn btn-info pull-left" @click="goToPortal()">{{portalDetails.name}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        portalDetails: {
            type: Object,
            required: true
        },
    },
    methods: {
        goToPortal() {
            window.open(this.portalDetails.url, "_blank");	
        }
  }
}
</script>

<style scoped>
h4 {
    margin-bottom:-0.5em;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.above-overlay {
    position: relative;
    z-index: 1000;
}
</style>