<template>
  <service-screen-layout :parts="parts">
    <template v-slot:cross-connect>
      <CrossConnectDetails
        :crossconnect="saleDefinition.definition.crossconnect"
        @saveSale="saveSale"
      />
    </template>
  </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";
import CrossConnectDetails from "@/components/ObjParts/SingleCaptured/CrossConnectDetails";

export default {
  extends: SaleFunctions,
  components: {
    CrossConnectDetails,
    ServiceScreenLayout
  },
  data() {
    return {
      parts: [
        {
          name: "cross-connect",
          icon: "fa-solid fa-link",
          displayName: "Cross Connect",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        definition: {
          crossconnect: {
            datacentre: {}
          },
        },
        product: {
           classificationSupplier: ""
        }
      },
    };
  },
  mounted() {
    const saleNumber = this.$route.params.saleNumber;
    this.getSaleDefinition(saleNumber).then(
      (success) => {
        this.saleDefinition = success.data;
        
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching Cross-Connect Sale Definition", error);
      }
    );
  },
  methods: {
    saveSale() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Cross-Connect", error);
              }
            );
        }
      });
    },
  },
};
</script>