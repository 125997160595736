<template>
  <div class="card-body">
    <h3>Web Hosting Regrade</h3>
  
    <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label>Select a Package</label>
              <select class="form-control" v-model="currentPackageId">  
                 <option v-for="option in saleDefinition.selectableOptions.webHostingPackage"
                  :disabled="option.webHostingPackageNumber == saleDefinition.definition.website.webHostingPackageNumber"
                  :value="option.webHostingPackageNumber"
                  :key="option.webHostingPackageNumber">
                  {{ option.description }}
                </option> 
              </select>
            
          </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
          <button :disabled="currentPackageId == saleDefinition.definition.website.webHostingPackageNumber" class="btn btn-success" @click="confirmRegrade()">
              Regrade
          </button>
        </div>
    </div>  
  </div>  
</template>

<script>
export default {
  computed: {
  },
  data() {
    return {
      saleDefinition: {
        selectableOptions: [],
        definition: {
          website: {}
        }
      },
      currentPackageId: {}
    };
  },
  mounted() {
    this.$http
      .get(this.$config.aimsAPI + "sales/definition/sale/" + this.$route.params.saleNumber)
      .then(
        (response) => {
          this.saleDefinition = response.data;
          this.currentPackageId = this.saleDefinition.definition.website.webHostingPackageNumber;
        },
        (error) => {
          console.error(error);
          this.showError("Error Loading Sale Definition", error);
        }
      );
  },
  methods: {
    confirmRegrade() {
      this.$swal({
        title: "Are you sure you want to Change Package?",
        text: "The billing will change for this client",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.changePackage();
        }
      });
    },
    changePackage() {
      
        this.saleDefinition.definition.website.webHostingPackageNumber = this.currentPackageId;

        this.$http
          .put(this.$config.aimsAPI + "sales/definition/" + this.$route.params.saleNumber + "/obj/update", this.saleDefinition.definition.website)
          .then(
            (response) => {
              this.showSuccess("Package Change Success");
              this.$router.push({
                name: "websitephpservice",
                params: {
                  saleNumber: this.$route.params.saleNumber,
                },
              });
            },
            (error) => {
              console.error(error);
              this.showError("Error Changing Package", error);
            }
          );
    }
  }
};
</script>

<style>

</style>
