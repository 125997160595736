<template>
  <service-screen-layout :parts="parts">
    <template v-slot:details>
      <DiginetLineDetails
        :diginetLine="
          saleDefinition.definition.leasedLineDiginet
            ? saleDefinition.definition.leasedLineDiginet
            : saleDefinition.definition.pointToPointDiginetLine
        "
        @saveSale="saveSale"
      />
    </template>
    <template v-slot:routednetworks>
      <RoutedNetworks
        heading="Routed Ipv4 Networks"
        :routedNetworks="
          saleDefinition.definition.leasedLineDiginet
            ? saleDefinition.definition.leasedLineDiginet.routedIpv4Networks
            : saleDefinition.definition.pointToPointDiginetLine
                .routedIpv4Networks
        "
        :netmasks="netmasksV4"
        :emptyRoutedNetwork="saleDefinition.supportingObjs.routedIpv4Networks"
        @saveRoutedNetworks="saveRoutedNetworks"
        @removeAddress="removeAddress"
      />
    </template>
  </service-screen-layout>
</template>

<script>
import SaleFunctions from "@/pages/Services/SaleFunctions";
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import DiginetLineDetails from "@/components/ObjParts/SingleCaptured/DiginetLineDetails";
import RoutedNetworks from "@/components/ObjParts/MultipleCaptured/RoutedNetworks";
export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    DiginetLineDetails,
    RoutedNetworks,
  },
  data() {
    return {
      parts: [
        {
          name: "details",
          icon: "fa fa-info-circle",
          displayName: "Details",
          permissions: ["*"],
        },
        {
          name: "routednetworks",
          icon: "fa fa-plus-square",
          displayName: "Routed Networks",
          permissions: ["STAFF"],
        },
      ],
      saleDefinition: {
        definition: {
          pointToPointDiginetLine: {
            routedIpv4Networks: [],
          },
        },
        supportingObjs: {
          routedIpv4Networks: {},
        },
      },
      netmasksV4: [32, 30, 29, 28, 27, 26, 25],
    };
  },
  async mounted() {
    await this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition() {
      const saleNumber = this.$route.params.saleNumber;
      this.getSaleDefinition(saleNumber).then(
        (success) => {
          this.saleDefinition = success.data;
          this.saleDefinition.supportingObjs.routedIpv4Networks.family = "IPV4";
          this.saleDefinition.supportingObjs.routedIpv4Networks.networkType =
            "ROUTED";
          this.saleDefinition.supportingObjs.routedIpv4Networks.diginetLineNumber = this.saleDefinition.definition.leasedLineDiginet.diginetLineNumber;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
    },
    saveSale() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Sale Definition", error);
              }
            );
        }
      });
    },
    saveRoutedNetworks(routedNetworks) {
      this.$http
        .put(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/objlist/update",
          routedNetworks
        )
        .then(
          () => {
            this.showSuccess("Save Success");
            this.loadSaleDefinition();
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving Sale Definition", error);
          }
        );
    },
    removeAddress(address) {
      this.$swal({
        title: "Are you sure you want to remove this address?",
        text: address.address,
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Remove",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/obj/delete",
              address
            )
            .then(
              () => {
                this.showSuccess("Delete Success");
                this.loadSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Sale Definition", error);
              }
            );
        }
      });
    },
  },
};
</script>