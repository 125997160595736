<template>
  <service-screen-layout :parts="parts">
    <template v-slot:details>
        <div class="row">
            <div class="col-md-7">
                <div class="card-header" >
                    <h4>Service Details</h4>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Description: </label>
                                <input 
                                  class="form-control col-md-12" 
                                  type="text" 
                                  disabled 
                                  v-model="saleDefinition.sale.management.description"
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="card card-default">
                    <div class="card-header">
                        <h4>Device Management Package</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4">
                                Name:
                            </div>
                            <div class="col-md-8">
                                <strong>{{saleDefinition.sale.management.devicePackage.description}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template v-slot:devices>
      <div class="form-group">
        <div class="row align-items-center">
          <div class="col-md-4">
            <button
              class="btn btn-info"
              @click="addDevice()"
              type="button"
              v-allowed:view="['STAFF']"
            >
              Add Device
            </button>
          </div>
        </div>
        <table class="table table-bordered row-spacing" v-if="saleDefinition.sale.management.devices.length > 0">
          <thead>
            <tr>
              <th>Device</th>
              <th v-allowed:view="['STAFF']" style="width:20%"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="device in saleDefinition.sale.management.devices"
              :key="device.objKey"
            >
              <td>{{ device.deviceName }}</td>
              <td class="text-center" v-allowed:view="['STAFF']">
                <button 
                  type="button" 
                  class="btn btn-info" 
                  v-tooltip="'Edit Device'"
                  @click="editDevice(device)"
                >
                  <i class="fa fa-edit"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-primary"
                  v-tooltip="'Delete Device'"
                  @click="deleteDevice(device)"
                >
                  <em class="fa fa-trash fa-fw"></em>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="alert alert-info row-spacing">
          No Devices Found
        </div>
      </div>

      <modal
        :title="modalTitle"
        ref="deviceModal"
        size="modal-md"
        @save="saveDevice()"
      >
        <form>
          <div class="row">
            <div
              class="
                form-group
                col-md-12
                d-flex
                align-items-baseline
                justify-content-center
              "
            >
              <label class="col-md-3">Device Name:</label>
              <div class="input-group col-md-9">
                <input
                  placeholder="Enter Name"
                  type="text"
                  v-model="device.deviceName"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </form>
      </modal>
    </template>
  </service-screen-layout>
</template>

<script>
import SaleFunctions from "@/pages/Services/SaleFunctions";
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import Modal from "@/components/GeneralComponents/Modal";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    Modal
  },
  data() {
    return {
      parts: [
        {
          name: "details",
          icon: "fa fa-info-circle",
          displayName: "Service",
          permissions: ["*"],
        },
        {
          name: "devices",
          icon: "fa-solid fa-microchip",
          displayName: "Devices",
          permissions: ["*"],
        },
      ],
      modalTitle: "Add Device",
      productRestriction: {},
      device: {
        deviceName: "",
        managedDeviceNumber: 0
      },
      newDevice: {},
      saleDefinition: {
        sale: {
            management: {
              description: "",
              devicePackage: {
                description: "",
              },
              devices: []
            },
        },
      },
    };
  },
  mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition() {
      const hostUrl = this.$config.aimsAPIv2;
      const saleNumber = this.saleNumber;

      this.$http.get(`${hostUrl}sales/definition/sale/${saleNumber}`).then(
        (success) => {
            this.saleDefinition = success.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
    },
    saveSale() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Sale Definition", error);
              }
            );
        }
      });
    },
    addDevice() {
      this.device = this.deepCopyObject(this.saleDefinition.meta.management.children.devices.objMeta.new);
      this.modalTitle = "Add Device";
      this.$refs.deviceModal.open();
    },
    editDevice(device) {
      this.device = this.deepCopyObject(device);
      this.modalTitle = "Edit Device";
      this.$refs.deviceModal.open();
    },
    saveDevice() {
        this.$refs.deviceModal.isLoading();
        if (this.device.managedDeviceNumber == 0) {
          this.$http
            .post(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/obj/create",
              this.device
            )
            .then(
              (response) => {
                this.loadSaleDefinition();
                this.$refs.deviceModal.close();
                this.showSuccess("Device Added Successfully");
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Device", error);
              }
            )
            .finally(() => {
              this.$refs.deviceModal.isLoading();
            });
        } else {
          this.$http
            .put(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/obj/update",
              this.device
            )
            .then(
              (response) => {
                this.loadSaleDefinition();
                this.$refs.deviceModal.close();
                this.showSuccess("Device Updated Successfully");
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Device", error);
              }
            )
            .finally(() => {
              this.$refs.deviceModal.isLoading();
            });
        }
    },
    deleteDevice(device) {
      this.$swal({
        title: "Are you sure you want to delete this device?",
        text: device.deviceName,
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/obj/delete/",
                device
            )
            .then(
              (response) => {
                this.showSuccess(response.data.message);
                this.loadSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error deleting device", error);
              }
            );
        }
      });
    },
  },
};
</script>