var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl- col-lg-12"},[_c('div',{staticClass:"card card-default"},[_c('form-wizard',{attrs:{"title":"","subtitle":"","stepSize":"sm"},on:{"on-complete":_vm.onComplete}},[_c('tab-content',{attrs:{"title":"Domain and Mailbox","before-change":() => _vm.validateStep('zimbra-mail')}},[_c('form',{attrs:{"novalidate":"","data-vv-scope":"domain-domain"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('zimbra-mail.domain'),
                  }},[_c('label',{staticClass:"control-label"},[_vm._v("Domain *")]),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"options":_vm.filledInSaleDefinition.selectableOptions.domain,"placeholder":"Select Domain","name":"domain","label":"domainName","valueProp":"zimbraDomainNumber"},model:{value:(
                      _vm.filledInSaleDefinition.requiredObjs.mailbox
                        .zimbraDomainNumber
                    ),callback:function ($$v) {_vm.$set(_vm.filledInSaleDefinition.requiredObjs.mailbox
                        , "zimbraDomainNumber", $$v)},expression:"\n                      filledInSaleDefinition.requiredObjs.mailbox\n                        .zimbraDomainNumber\n                    "}}),(_vm.errors.has('zimbra-mail.domain'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("zimbra-mail.domain")))]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('zimbra-mail.username'),
                  }},[_c('label',{staticClass:"control-label"},[_vm._v("Username *")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(
                      _vm.filledInSaleDefinition.requiredObjs.mailbox.username
                    ),expression:"\n                      filledInSaleDefinition.requiredObjs.mailbox.username\n                    "}],staticClass:"form-control",attrs:{"type":"text","name":"username"},domProps:{"value":(
                      _vm.filledInSaleDefinition.requiredObjs.mailbox.username
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filledInSaleDefinition.requiredObjs.mailbox, "username", $event.target.value)}}}),(_vm.errors.has('zimbra-mail.username'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("zimbra-mail.username")))]):_vm._e()])])])])])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }