<template>
    <modal
        :title="classificationObj.objKey ? 'Edit Classification' : 'Add Classification'"
        ref="modifyClassificationModal"
        @save="save()"
    >
        <div class="row">
            <div class="form-group col-md-12" :class="{'has-error': errors.has('Name')}">
                <label>Name</label>
                <input 
                    type="text" 
                    class="form-control"
                    name="Name"
                    v-model="classificationObj.name" 
                    v-validate="'required'" 
                    :class="{'has-error': errors.has('Name')}"
                >
                <span class="has-error" v-if="errors.has('Name')">{{ errors.first('Name') }}</span>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12" :class="{'has-error': errors.has('SKU')}">
                <label>SKU</label>
                <input 
                    type="text" 
                    class="form-control" 
                    name="SKU"
                    v-model="classificationObj.skuName"
                    v-validate="'required|min:2|max:4'" 
                    :class="{'has-error': errors.has('SKU')}"
                >
                <span class="has-error" v-if="errors.has('SKU')">{{ errors.first('SKU') }}</span>
            </div>
        </div>
        <div class="row" v-if="classification === 'subsegment'">
            <div class="form-group col-md-12" :class="{'has-error': errors.has('Segment')}">
                <label>Segment</label>
                <v-select
                    :options="segments"
                    v-model="classificationObj.segmentNumber"
                    label="name"
                    name="Segment"
                    valueProp="segmentNumber"
                    placeholder="Select Segment"
                    v-validate="'required'" 
                    :class="{'has-error': errors.has('Segment')}"
                >
                </v-select>
                <span class="has-error" v-if="errors.has('Segment')">{{ errors.first('Segment') }}</span>
            </div>
        </div>
        <div class="row" v-if="classification === 'segment'">
            <div class="form-group col-md-12" :class="{'has-error': errors.has('Account Number')}">
                <label>Account Number</label>
                <input 
                    type="text" 
                    class="form-control" 
                    name="Account Number"
                    v-model="classificationObj.accountNumber"
                    v-validate="'required|min:6|max:12'" 
                    :class="{'has-error': errors.has('Account Number')}"
                >
                <span class="has-error" v-if="errors.has('Account Number')">{{ errors.first('Account Number') }}</span>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";

export default {
    components: {
        Modal
    },
    props: {
        classificationObj: {
            type: Object,
            required: true
        },
        segments: {
            type: Array,
            required: false,
            default: () => {
                return [];
            }
        },
        classification: {
            type: String,
            required: true
        },
    },
    methods: {
        open: function() {
            this.$refs.modifyClassificationModal.open();
        },
        save: function() {    
            this.$validator.validateAll().then((valid) => {
                if (valid) {
                    this.$refs.modifyClassificationModal.isLoading();
                    this.$emit('saveClassification', this.classificationObj);
                }
            });
        }
    }
}
</script>
