<template>
  <service-screen-layout :parts="parts">
    <template v-slot:admin>
      <div class="row row-spacing">
        <div class="col-md-8">
          <h4>Customer Details</h4>
          <div class="form-group row">
            <label class="col-md-2 col-form-label">Full Name:</label>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                readonly
                placeholder="Full Name"
                v-model="saleDefinition.sale.customer.fullName"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-auto col-form-label">Tenant Domain:</label>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                readonly
                placeholder="Tenant Domain"
                v-model="saleDefinition.sale.customer.tenantDomain"
              />
            </div>
          </div>
          
        </div>
        
        <div 
          class="col-md-4" 
          v-if="saleDefinition.details && 
          saleDefinition.details.portal && 
          saleDefinition.details.portal.url.length > 0 && 
          saleDefinition.details.portal.name.length > 0"
        >
          <PortalButton :portalDetails="saleDefinition.details.portal"/>
        </div>

        <div class="col-md-8 row-spacing">
          <h4>Subscription Details</h4>
          <div class="form-group row">
            <label class="col-md-2 col-form-label">Offer Name:</label>
            <div class="col-md-4">
              <input
                type="text"
                class="form-control"
                readonly
                placeholder="Offer Name"
                v-model="saleDefinition.sale.subscription.offerName"
              />
            </div>

            <label class="col-md-2 col-form-label">Commitment:</label>
            <div class="col-md-4">
              <input
                type="text"
                class="form-control"
                readonly
                placeholder="Minimum Commitment Months"
                v-model="saleDefinition.sale.subscription.minimumCommitmentMonths"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-2 col-form-label">Status:</label>
            <div class="col-md-4">
              <input
                type="text"
                class="form-control"
                readonly
                placeholder="Status"
                v-model="saleDefinition.sale.subscription.status"
              />
            </div>

            <label class="col-md-2 col-form-label">Licences:</label>
            <div class="col-md-4">
              <input
                type="text"
                class="form-control"
                readonly
                placeholder="Quantity"
                v-model="saleDefinition.sale.subscription.quantity"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-2 col-form-label">Start Date:</label>
            <div class="col-md-4">
              <input
                type="text"
                class="form-control"
                readonly
                placeholder="Start Date"
                :value="dateFormat(saleDefinition.sale.subscription.startDate)"
              />
            </div>

            <label class="col-md-2 col-form-label">End Date:</label>
            <div class="col-md-4">
              <input
                type="text"
                class="form-control"
                readonly
                placeholder="End Date"
                :value="dateFormat(saleDefinition.sale.subscription.endDate)"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-2 col-form-label">Billing Cycle:</label>
            <div class="col-md-4">
              <input
                type="text"
                class="form-control"
                readonly
                placeholder="Billing Type"
                v-model="saleDefinition.sale.subscription.billingType"
              />
            </div>

            <label class="col-md-2 col-form-label">Auto-Renew:</label>
            <div class="col-md-4">
              <input
                type="text"
                class="form-control"
                readonly
                placeholder="Auto Renew Enabled?"
                :value="saleDefinition.sale.subscription.autoRenewEnabled == true ? 'Yes' : 'No'"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-2 col-form-label">Additional Info:</label>
            <div class="col-md-4">
              <input
                type="text"
                class="form-control"
                readonly
                placeholder="End Date"
                v-model="saleDefinition.sale.subscription.additionalInfo"
              />
            </div>
          </div>

        </div>
      </div>
    </template>

    <template v-slot:advanced>
      <div class="row row-spacing">
        <div class="col-md-10">
          <h4>Advanced Customer Details</h4>
          <div class="form-group row">
            <label class="col-md-3 col-form-label">Organization ID:</label>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                readonly
                placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxx"
                v-model="saleDefinition.sale.customer.organisationId"
              />
            </div>
          </div>
        </div>

        <div class="col-md-10 row-spacing">
          <h4>Advanced Subscription Details</h4>
          <div class="form-group row">
            <label class="col-md-3 col-form-label">Subscription ID:</label>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                readonly
                placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxx"
                v-model="saleDefinition.sale.subscription.subscriptionId"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-3 col-form-label">Subscription External ID:</label>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                readonly
                placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxx"
                v-model="saleDefinition.sale.subscription.subscriptionExternalId"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-3 col-form-label">Customer Organization ID:</label>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                readonly
                placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxx"
                v-model="saleDefinition.sale.subscription.customerOrganizationId"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-3 col-form-label">Customer Name:</label>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                readonly
                placeholder="Customer Name"
                v-model="saleDefinition.sale.subscription.customerName"
              />
            </div>
          </div>

        </div>
      </div>
    </template>

    <template v-slot:changes>
      <div class="row row-spacing">
        <div class="card-body">
          <div class="col-md-12">
            <div class="table-responsive table-bordered" v-if="saleDefinition.sale.subscription.subscriptionChanges.length > 0">
              <table class="table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Movement</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(change, index) in saleDefinition.sale.subscription.subscriptionChanges" :key="index">
                    <td>{{dateFormat(change.date)}}</td>
                    <td>{{change.movement}}</td>
                    <td>{{change.total}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="alert alert-info" v-else>
              No Changes Available
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:invoices>
      <div class="row row-spacing">
        <div class="card-body">
          <div class="col-md-12">
            <div class="table-responsive table-bordered" v-if="saleDefinition.sale.subscription.subscriptionInvoices.length > 0">
              <table class="table">
                <thead>
                  <tr>
                    <th>Billing Month</th>
                    <th>Billing Frequency</th>
                    <th>Charge Start Date</th>
                    <th>Charge End Date</th>
                    <th>Charge Type</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th>Total Price</th>
                    <th>Invoice Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(invoice, index) in saleDefinition.sale.subscription.subscriptionInvoices" :key="index">
                    <td>{{invoice.billingPeriod}}</td>
                    <td>{{invoice.billingFrequency}}</td>
                    <td>{{dateFormat(invoice.chargeStartDate)}}</td>
                    <td>{{dateFormat(invoice.chargeEndDate)}}</td>
                    <td>{{invoice.chargeType}}</td>
                    <td>{{invoice.quantity}}</td>
                    <td>{{currencyFormat(invoice.unitPrice)}}</td>
                    <td>{{currencyFormat(invoice.totalPrice)}}</td>
                    <td>{{dateFormat(invoice.invoiceDate)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="alert alert-info" v-else>
              No Invoices Available
            </div>
          </div>
        </div>
      </div>
    </template>

  </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";
import PortalButton from "@/components/ObjParts/SingleLoaded/PortalButton";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    PortalButton
  },
  watch: {
    saleDefinition: {
      deep: true,
      handler(newSaleDef, oldSaleDef) {
        if (oldSaleDef.sale.description != newSaleDef.sale.description && this.$parent.$refs.serviceTitle) {
          this.$parent.$refs.serviceTitle.reloadSale();
        }
      },
    },
  },
  computed: {
    isStaffUser() {
      return this.userContainsPermission(['STAFF']);
    }
  },
  data() {
    return {
      parts: [
        {
          name: "admin",
          icon: "fa fa-circle-user",
          displayName: "Details",
          permissions: ["*"],
        },
        {
          name: "advanced",
          icon: "fa-solid fa-gear",
          displayName: "Advanced",
          permissions: ["STAFF"],
        },
        {
          name: "changes",
          icon: "fa-solid fa-arrow-up-9-1",
          displayName: "Changes",
          permissions: ["*"],
        },
        {
          name: "invoices",
          icon: "fa-solid fa-receipt",
          displayName: "Invoices",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        sale: {
          customer: {},
          subscription: {
            startDate: '',
            subscriptionChanges: [],
            subscriptionInvoices: [],
          },
        },
      },
    };
  },
  mounted() {
    this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition() {
      const saleNumber = this.$route.params.saleNumber;
      this.fetchSaleDefinition(saleNumber).then(
        (response) => {
          this.saleDefinition = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Could not load Sale Definition", error);
        }
      );
    },
    dateFormat(date) {
      if (date) {
        return date.split(' ')[0];
      } else {
        return null;
      }
    },
  },
};
</script>
