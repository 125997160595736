<template>

  <div class="card card-default">
    <div class="card-body">
      <h4>Reseller's Commission Setup</h4>
      <div class="row form-group">
        <div class="col-md-4">
          <select name="Client" class="form-control" v-model="selectedClientNumber" @change="clientSelected()"
            placeholder="Select a client">
            <option :value="0">Show all Clients</option>
            <option :value="client.clientNumber" v-for="client in resellerClients" :key="client.clientNumber">
              {{client.toString}}</option>
          </select>
        </div>
        <div class="col-md-4" v-if="latestReport.storedCannedReportNumber">
          <a href="javascript:void(0)" @click="downloadCannedReport()">Download the latest Commission Report</a>
        </div>
      </div>
      <div v-for="client in resellerSetup" :key="client.clientNumber">
        <div class="card mb-3 border-primary">
          <div class="card-header" style="border-bottom:1px solid rgba(0, 0, 0, 0.125)">
            <h4>{{client.toString}}</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive table-bordered">
              <table class="table">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Sale</th>
                    <td>Commission rate</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="sale in client.sales" :key="sale.saleNumber">
                    <td>{{sale.product.toString}}</td>
                    <td>#{{sale.saleNumber}} {{sale.toString}}</td>

                    <td v-if="(sale.salecommissionrate.rate || sale.salecommissionrate.rate === 0)">
                      <div class="input-group">
                        <input type="text" v-model="sale.salecommissionrate.rate" class="form-control">
                        <div class="input-group-prepend">
                          <span class="input-group-text">%</span>
                        </div>
                      </div>
                    </td>
                    <td v-else><i class="badge badge-warning">N/A</i></td>

                    <td v-if="(sale.salecommissionrate.rate|| sale.salecommissionrate.rate === 0)">
                      <button class="btn btn-danger" @click="saveRate(sale)">Save</button>
                    </td>
                    <td v-else>
                      <button class="btn btn-info" @click="createRate(sale)">Create</button>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer">
            <div class="text-sm text-muted">{{client.firstnames}} {{client.lastname}} - {{client.resellerReference}}
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
  export default {
    components: {

    },
    data: function () {
      return {
        resellerClients: [],
        resellerSetup: {},
        selectedClientNumber: {},
        latestReport: {}
      }
    },
    async mounted() {
      this.getClients();
    },
    methods: {
      clientSelected() {
        this.getCommissionSetup();
      },
      getClients() {
        this.$http.get(this.$config.aimsAPI + 'clients/' + this.$route.params.clientNumber + '/resellerclients')
          .then(response => {
            this.resellerClients = response.data;
          }, error => {
            console.error(error);
            this.showError('Error Fetching Reseller Clients', error);
          });
      },
      getCommissionSetup() {
        //route param clientNumber is resellerNumber
        this.$http.get(this.$config.aimsAPI + 'clients/' + this.$route.params.clientNumber + '/commissionsetup/' + this
            .selectedClientNumber)
          .then(response => {
            this.resellerSetup = response.data;
            this.getLatestReport();
          }, error => {
            console.error(error);
            this.showError('Error Fetching Reseller Commission Setup', error);
          });
      },
      getLatestReport() {
        this.$http.get(this.$config.aimsAPI + 'clients/' + this.$route.params.clientNumber + '/commissionsetup/latest')
          .then(response => {
            this.latestReport = response.data;
          }, error => {
            console.error(error);
            this.showError('Error fetching latest Reseller Commission report ', error);
          });
      },
      saveRate(sale) {
        this.$http.put(this.$config.aimsAPI + 'clients/commissionsetup/update', sale.salecommissionrate)
          .then(response => {
              this.showSuccess(response.data.message);
            },
            error => {
              this.showError("Error updating Commission Rate", error);
              console.error(error);
            })
      },
      createRate(sale) {
        this.$http.put(this.$config.aimsAPI + 'clients/commissionsetup/create', sale)
          .then(response => {
              this.showSuccess(response.data.message);
              this.getCommissionSetup();
            },
            error => {
              this.showError("Error creating Commission Rate", error);
              console.error(error);
            })
      },
      downloadCannedReport() {
        this.$http.get(this.$config.aimsAPI + "reporting/canned/" + this.latestReport.storedCannedReportNumber +
            "/download", {
              responseType: 'arraybuffer'
            })
          .then(function (response) {
            this.latestReport.downloadCount++;
            const contentType = response.headers.get('Content-Type');
            let blob = new Blob([response.data], {
              type: contentType
            });
            const fileName = response.headers.get('Content-Disposition').split("filename = ")[1];
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.dispatchEvent(new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            }));
          }, function (error) {
            console.error(error);
            this.showError('Error Downloading Report', error.data);
          });
      }

    }
  }

</script>
