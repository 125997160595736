<template>
    <div class="col-md-12">
        <div class="row row-spacing justify-content-between bg-dark py-3 rounded">
            <div class="col-md-4 text-left">
                <button class="btn btn-primary" style="max-width: 150px;" @click="previousButtonClick" :disabled="disablePrevButton">
                    <i class="fa fa-arrow-left"></i>
                    Previous {{ timePeriodButtonText }}
                </button>
            </div>
            <div class="col-md-4 text-center">
                <h4 class="text-white my-2">
                    <span class="text-capitalize">{{ usageType }}</span> usage for {{ timePeriod }}
                </h4>
            </div>
            <div class="col-md-4 text-right">
                <button class="btn btn-primary" style="max-width: 150px;"  @click="nextButtonClick"  :disabled="disableNextButton">
                    Next {{ timePeriodButtonText }}
                    <i class="fa fa-arrow-right"></i>
                </button>
            </div>
        </div>
        <div class="row justify-content-center mt-3">
            <div class="col-auto text-center">
                <h4>Total Usage: {{ sizeBytesToGb(totalUsage) }} GB</h4>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-auto text-center">
                <button class="btn btn-info" @click="viewUsageForPeriod">
                    View {{ viewUsageForPeriodTypeText }} usage for {{ viewMonthOrYearText }}
                </button>
            </div>
        </div>
        <hr>
        <div class="row row-spacing">
            <div class="col-md-12">
                <ApexUsageGraph 
                    :title="graphTitle"
                    :usageData="currentDailyUsageData"
                    @hourly-usage="hourlyUsage"
                    @daily-usage="dailyUsage"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ApexUsageGraph from './ApexUsageGraph.vue';

    export default {
        components: {
            ApexUsageGraph
        },
        props: {
            currentDailyUsageData: {
                type: Array,
                required: true
            },
            graphTitle: {
                type: String,
                required: true
            },
            usageType: {
                type: String,
                required: true
            },
            timePeriod: {
                type: String,
                required: true
            },
            usageYear: {
                type: String,
                required: true
            },
            saleStartYear: {
                type: String,
                required: true
            },
            usagePeriod: {
                type: String,
                required: true
            },
            totalUsage: {
                type: Number,
                required: true
            },
            saleStartPeriod: {
                type: String,
                required: true
            },
            saleStartDate: {
                type: String,
                required: true
            },
        },
        computed: {
            timePeriodButtonText() {
                switch (this.usageType) {
                    case 'hourly':
                        return 'Day';
                    case 'monthly':
                        return 'Year';
                    default:
                        return 'Month';
                }
            },
            disablePrevButton() {  
                const saleStartingDate = this.dateFormat(this.saleStartDate);                    
                const currentPeriodDate = this.dateFormat(this.timePeriod);                
                const period = this.usagePeriod.toString();
                const year = this.usageYear.toString();

                switch (this.usageType) {
                    case 'hourly':
                        return saleStartingDate === currentPeriodDate;
                    case 'monthly':
                        return year === this.saleStartYear;
                    default:
                        return period === this.saleStartPeriod;
                }
            },
            disableNextButton() {
                const lastDayDate = this.dateFormat(new Date());
                const currentPeriodDate = this.dateFormat(this.timePeriod);
                const period = this.usagePeriod.toString();
                const year = this.usageYear.toString();
                const currentPeriodYear = this.getCurrentPeriod().substring(0, 4);

                switch (this.usageType) {
                    case 'hourly':
                        return lastDayDate === currentPeriodDate;
                    case 'monthly':
                        return year === currentPeriodYear;
                    default:
                        return period === this.getCurrentPeriod();
                }
            },
            viewUsageForPeriodTypeText() {
                switch (this.usageType) {
                    case 'hourly':
                        return 'Daily';
                    case 'monthly':
                        return 'Daily';
                    default:
                        return 'Monthly';
                }
            },
            viewMonthOrYearText() {
                if (this.usageType === 'hourly') {
                    let period = this.timePeriod.split(' ');
                    const month = period[1];
                    const year = period[2];                    
                    return `${month} ${year}`;
                }
                else if (this.usageType === 'monthly') {
                    return this.timePeriod;
                }
                else {
                    return this.usageYear;
                }
            }
        },
        methods: {
            previousButtonClick: function() {
                switch (this.usageType) {
                    case 'hourly':
                        this.$emit('hourlyUsage', 'previous');
                        break;
                    case 'monthly':
                        this.$emit('previousYearlyUsage');
                        break;
                    default:
                        this.$emit('previousMonthUsage');
                }
            },
            nextButtonClick: function() {
                switch (this.usageType) {
                    case 'hourly':
                        this.$emit('hourlyUsage', 'next');
                        break;
                    case 'monthly':
                        this.$emit('nextYearlyUsage');
                        break;
                    default:
                        this.$emit('nextMonthUsage');
                }
            },
            viewUsageForPeriod: function() {  
                const usageMonth = this.usagePeriod.toString().split('');
                const month = usageMonth.slice(-2).toString().replace(',', '');
                const currentPeriod =  this.getCurrentPeriod();

                switch (this.usageType) {
                    case 'hourly':
                    case 'monthly':
                        this.$emit('dailyUsage', month, this.usageYear);
                        break;
                    default:
                        this.$emit('yearlyUsage', currentPeriod);
                }                
            },
            hourlyUsage: function(day) {                
                this.$emit('hourlyUsage', day);
            },
            dailyUsage: function(month) {                
                if (month < 10) {
                    month = `0${month.toString()}`;
                } else {
                    month = month.toString();
                }                
                this.$emit('dailyUsage', month, this.usageYear);
            }
        }
    }
</script>