<template>
<div>
    <WidgetLayout 
      :heading="'Contacts'"
      :icon="'fa fa-users'"
      :showFooterButtons="false"
    >
      <div class="table-responsive">
          <table class="table table-hover">
            <tbody>
              <tr v-for="contact in contacts" :key="contact.objKey">
                <td class="text-muted">
                  <div class="mb-2">{{ contact.firstNames }} {{ contact.lastName }}</div>
                  <span
                    v-for="role in contact.roles"
                    :key="role.roleNumber"
                    class="role-text"
                    :class="getRoleBadgeClass(role.type)"
                  >
                    {{ role.type }}
                  </span>
                </td>
                <td class="text-center contact-btn">
                  <button type="button" class="btn btn-info" v-tooltip="'Edit Contact Details'" @click="editPerson(contact)"><i class="fa fa-edit"></i></button>
                  <button type="button" class="btn btn-green" v-tooltip="'Click to open communication channels'" @click="openContactModal(contact)"><i class="fa fa-comments"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <ContactModal
          ref="openContactModal"
          :modalTitle="modalTitle"
          :contactPerson="selectedContact"
        />
    </WidgetLayout>
    
  </div>
</template>

<script>
import ContactModal from '@/components/Admin/Modals/ContactModal'
import WidgetLayout from './WidgetLayout';
export default {
  computed: {},
  data() {
    return {
      contacts: [],
      modalTitle: "",
      selectedContact: {},
      clientNumber: null,
    };
  },
  components: {
    ContactModal,
    WidgetLayout
  },
  mounted() {
    this.clientNumber = this.$route.params.clientNumber;

    this.loadContacts();
  },
  methods: {
    loadContacts() {
      this.$http
        .get(this.$config.aimsAPI + "clients/" + this.clientNumber + "/users")
        .then(
          (response) => {
            this.contacts = response.data;
          },
          (error) => {
            this.showError("Error Fetching Client contacts", error);
            console.error(error);
          }
        );
    },
    getRoleBadgeClass: function(roleType) {
      switch (roleType) {
        case "BILLING":
          return "badge badge-warning";
        case "TECH":
          return "badge badge-success";
        case "DECISION_MAKER":
          return "badge badge-purple";
        default:
          return "badge badge-info";
      }
    },
    openContactModal: function(data) {
      this.$refs.openContactModal.open();
      this.modalTitle = `Contact ${data.firstNames} ${data.lastName}`;
      this.selectedContact = this.deepCopyObject(data);
    },
    editPerson: function(person) {        
        this.$router.push({ name: 'clientusers', params: { personNumber: person.personNumber } });
    },
  },
};
</script>

<style scoped>
.table-responsive {
  height: 255px !important;
}
.table-hover td {
  cursor: default
}
.text-muted small {
  display: block;
}
.role-text {
  font-size: 10px;
  margin: 0 .5em 0 0;
}
</style>