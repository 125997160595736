<template>
  <div class="card-body">
    <ul class="nav nav-tabs nav-fill responsive-tabs" role="tablist">
      <li class="nav-item" v-for="part in parts" v-allowed:view="part.permissions" :key="part.name" @click="activeTab = part.name">
        <a class="nav-link" :class="{'active' : activeTab === part.name}" :id="part.name + '-tab'" data-toggle="tab"
          :href="'#'+part.name" role="tab" :aria-controls="part.name" aria-selected="true">
          <i :class="part.icon"></i> {{part.displayName}}
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <div v-for="part in parts" :key="part.name" class="tab-pane" :class="{'active show' : activeTab === part.name}"
        :id="part.name" role="tabpanel" :aria-labelledby="part.name + '-tab'">
        <slot :name="part.name">
          {{part.name}} needs to be implemented
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
  import SaleFunctions from '@/pages/Services/SaleFunctions'

  export default {
    extends: SaleFunctions,
    components: {
    },
    props: {
      parts: {
        required: true,
        type: Array
      },
      setActiveTab: {
        required: false,
        type: String
      }
    },
    data() {
      return {
        activeTab: ''
      }
    },
    mounted() {
      $('.responsive-tabs').responsiveTabs({
        accordionOn: ['xs', 'sm']
      });
      this.activeTab = this.parts[0].name;
    },
    watch: {
      setActiveTab: {
        immediate: true,
        handler(val, oldVal) {
          this.activeTab = val;
        },
      },
    }
  }

</script>