<template>
  <div class="row">
    <div class="col-md-8">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template v-slot:repaymentterms>
            <form novalidate data-vv-scope="paymentterms">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group">
                    <label class="control-label">Terms *</label>
                    <v-select
                      name="Terms"
                      placeholder="Select Terms"
                      :options="['6 Months', '12 Months']"
                      @input="termSelected"
                    ></v-select>
                  </div>
                </div>
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group">
                    <label class="control-label">Device *</label>
                    <v-select
                      name="Device"
                      placeholder="Select Device"
                      @input="deviceSelected"
                      :options="termPackages"
                      label="description"
                      valueProp="repaymentPlanPackageNumber"
                      v-model="
                        filledInSaleDefinition.requiredObjs.repaymentPlan
                          .repaymentPlanPackageNumber
                      "
                    ></v-select>
                  </div>
                </div>
              </div>
            </form>
          </template>
          <template v-slot:summary>
            <form novalidate data-vv-scope="summary">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group">
                    <label class="control-label">Device</label>
                    <input
                      type="text"
                      class="form-control"
                      readonly
                      :value="device"
                    />
                  </div>
                  <div class="form-group">
                    <label class="control-label"
                      >Monthly Repayment Amount (inc VAT)</label
                    >
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">R</span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        readonly
                        :value="timesVAT(monthlyAmount)"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="control-label">Terms</label>
                    <input
                      type="text"
                      class="form-control"
                      readonly
                      :value="term"
                    />
                  </div>
                  <div class="form-group">
                    <label class="control-label">First Invoice</label>
                    <input
                      type="text"
                      class="form-control"
                      readonly
                      :value="prettifyBillingPeriod(period())"
                    />
                  </div>
                  <div class="form-group">
                    <label class="control-label"
                      >Total Repayment Amount (inc VAT)</label
                    >
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">R</span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        readonly
                        :value="timesVAT(deviceCost)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
export default {
  components: {
    ServiceWizardLayout,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "repaymentterms",
          displayName: "Repayment Terms",
        },
        {
          name: "summary",
          displayName: "Summary",
        },
      ],
      filledInSaleDefinition: {
        requiredObjs: {
          repaymentPlan: {},
        },
        selectableOptions: {
          repaymentPlanPackage: [],
        },
      },
      periods: [],
      device: "",
      term: "",
      deviceCost: 0,
      monthlyAmount: 0,
      termPackages: [],
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
  },
  methods: {
    termSelected(term) {
      this.termPackages =
        this.filledInSaleDefinition.selectableOptions.repaymentPlanPackage.filter(
          (rpp) => rpp.terms == term
        );
    },
    onComplete() {
      this.$swal({
        title: this.device,
        text: this.term,
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Create Service",
      }).then((result) => {
        if (result.value) {
          this.$emit("createSale", this.filledInSaleDefinition);
        } else {
        }
      });
    },
    period() {
      this.filledInSaleDefinition.requiredObjs.repaymentPlan.billingPeriod =
        this.increaseBillingPeriod(this.getCurrentPeriod());
      return this.filledInSaleDefinition.requiredObjs.repaymentPlan
        .billingPeriod;
    },
    deviceSelected(repaymentPlanPackageNumber) {
      this.$http
        .get(
          this.$config.aimsAPI +
            "pricing/repaymentplanpackages/product/" +
            this.filledInSaleDefinition.product.productNumber +
            "?onlyCurrentPricing=true"
        )
        .then(
          (response) => {
            const selectedPackage = response.data.repaymentplanpackages.find(
              (rpp) =>
                rpp.repaymentPlanPackageNumber == repaymentPlanPackageNumber
            );
            this.device = selectedPackage.description;
            this.term = selectedPackage.terms;
            this.deviceCost = selectedPackage.repaymentplanpackageprices.find(
              () => true
            ).priceExclVat;
            this.monthlyAmount =
              selectedPackage.terms == "6 Months"
                ? this.deviceCost / 6
                : this.deviceCost / 12;
          },
          (error) => {
            this.showError("Error fetching Repayment Plan Packages", error);
            console.error(error);
          }
        );
    },
    validateStep(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    }
  },
};
</script>