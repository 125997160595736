<template>
  <WidgetLayout 
    :heading="'Pipedrive Deals'"
    :icon="'fa fa-plus'"
    :showFooterButtons="false"
  >
    <div class="table-responsive">
          <table class="table" v-if="pipedriveDeals">
            <thead class="text-left">
              <tr>
                <th>Title</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="deal in pipedriveDeals" :key="deal.id">
                <td>
                  <small>
                    <a :href="$config.urlToPipedrive + deal.id"
                      target="_blank">{{ deal.title }}
                    </a>
                  </small>                  
                </td>
                <td>
                  <span
                    class="text-uppercase"
                    :class="getStatusBadge(deal.status)"
                    >{{ deal.status }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else><span>No pipedrive deals for client</span></div>
        </div>
  </WidgetLayout>
</template>

<script>
import WidgetLayout from './WidgetLayout';
export default {
  components: {
    WidgetLayout
  },
  data() {
    return {
      pipedriveDeals: [],
    };
  },
  mounted() {
    const clientNumber = this.$route.params.clientNumber;
    this.$http
      .get(
        this.$config.aimsAPI + "clients/" + clientNumber + "/pipedrive/deals"
      )
      .then(
        (response) => {
          this.pipedriveDeals = response.data.data;
        },
        (error) => {
          this.showError("Could not fetch Pipedrive Deals for client", error);
          console.error(error);
        }
      );
  },
  methods: {
    getStatusBadge(status) {
      switch (status) {
        case "open":
          return "badge bg-green";
        case "deleted":
          return "badge bg-red text-white";
        case "lost":
          return "badge bg-yellow";
        default:
          return "badge badge-success";
      }
    },
  },
};
</script>

<style scoped>
.table-responsive {
  height: 255px !important;
}
</style>