<template>
    <div>
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Domain Alias</th>
                </tr>
            </thead>
            <tbody v-if="domain.domainAliases.length > 0">
                <tr v-for="domainAlias in domain.domainAliases" :key="domainAlias.zimbraDomainAliasNumber">
                    <td>{{ domainAlias.domainName }}</td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="1">No Domain Aliases to display</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        domain: {
            type: Object,
            required: true
        },
    }
}
</script>

<style lang="scss" scoped>

</style>