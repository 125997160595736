<template>
    <ServiceScreenLayout :parts="parts" v-if="saleLoaded === true">
        <template v-slot:network-details>
            <LegacyHostedNetworkDetails
                :networkDetails="saleDefinition.sale.network"
                :contentionRatio="contentionRatio"
                @saveSale="saveSale"
            />
        </template>
        <template v-slot:network-ip-addresses>
            <LegacyAssignedIpNetworkAddresses
                :networkAddresses="saleDefinition.sale.network.networkAddress"
                :saleNumber="saleNumber"
                :isInternalNetworks="isInternalNetworks"
                @saveSale="saveSale"
                @deleteNetworkAddress="deleteNetworkAddress"
            />
        </template>
    </ServiceScreenLayout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import LegacyHostedNetworkDetails from '@/components/ObjParts/SingleCaptured/Legacy/LegacyHostedNetworkDetails';
import LegacyAssignedIpNetworkAddresses from '@/components/ObjParts/MultipleCaptured/Legacy/LegacyAssignedIpNetworkAddresses';

export default {
    components: {
        ServiceScreenLayout,
        LegacyHostedNetworkDetails,
        LegacyAssignedIpNetworkAddresses
    },
    data() {
        return {
            saleNumber: null,
            saleLoaded: false,
            saleDefinition: {
                sale: {
                    network: {
                        networkAddess: [],
                    },
                    product: {}
                },
            },
            contentionRatio: '',
            bandWidth: [],
            addressTypes: [],
            parts: [
                {
                    name: "network-details",
                    icon: "fa fa-info-circle",
                    displayName: "Network Details",
                    permissions: ["*"],
                },
                {
                    name: "network-ip-addresses",
                    icon: "fa fa-map-marker",
                    displayName: "Assigned IP Network Addresses",
                    permissions: ["*"],
                },
            ],
            isInternalNetworks: false
        }
    },
    async mounted() {
        await this.getSaleNumber();
        await this.getSaleDefinition();
    },
    methods: {
        getSaleNumber: function() {
            this.saleNumber = this.$route.params.saleNumber;
        },
        getSaleDefinition: function() {
            const hostUrl = this.$config.aimsAPIv2;
            const saleNumber = this.saleNumber;

            this.$http.get(`${hostUrl}sales/definition/sale/${saleNumber}`).then(
                (response) => {
                    this.saleDefinition = response.data;
                    if (this.saleDefinition.sale.product.productDefinition == "za.co.adept.aims.product.datacentre.ProductDefaultHostedVlanNetworkInternal") {
                        this.isInternalNetworks = true;
                    }
                    this.getContentionRatio();
                    this.saleLoaded = true;
                },
                (error) => {
                    console.error(error);
                    this.showError("Error fetching Sale Definition", error);
                },
            );
        },
        getContentionRatio: function() {
            const legacyHostUrl = this.$config.aimsAPIvLegacy;
            const shapedPoolNumber = this.saleDefinition.sale.network.shapedPoolNumber;

            this.$http.get(`${legacyHostUrl}fibre/contention/${shapedPoolNumber}`).then(
                (response) => {
                    this.contentionRatio = response.bodyText;
                },
                (error) => {
                    console.error(error);
                    this.showError("Error fetching contention ratio", error);
                },
            );
        },
        saveSale: function() {
            const hostUrl = this.$config.aimsAPIv2;
            const saleNumber = this.saleNumber;

            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.$http.put(`${hostUrl}sales/definition/${saleNumber}/update`, this.saleDefinition).then(
                        (response) => {
                             this.showSuccess("Save Success");
                             this.getSaleDefinition();
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error Saving Sale Definition", error);
                            this.getSaleDefinition();
                        },
                    );
                }
            });
        },
        deleteNetworkAddress: function(networkAddress) {
            this.$swal({
                title: "Are you sure you want to delete this IP Network Address",
                text: `${networkAddress.toString}`,
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Delete"
            }).then((result) => {
                if (result.value) {
                    const hostUrl = this.$config.aimsAPI;
                    const saleNumber = this.saleNumber;

                    this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/delete`, networkAddress).then(
                        (response) => {
                            this.showSuccess(`Successfully deleted ${networkAddress.toString}`);
                             this.getSaleDefinition();
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error deleting IP Network Address", error);
                        },
                    );
                }
            });
        },
    },
}
</script>
