<template>
    <div class="row">
       <div class="col-md-12">
            <div class="row row-spacing">
                <div class="col-md-12">
                    <button class="btn btn-success" @click="addCertificateIssue" v-allowed:view="['STAFF']">Add Certificate Issue</button>
                </div>
            </div>
            <div class="row row-spacing">
                <div class="col-md-12">
                    <div class="table-responsive table-bordered">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Issuer</th>
                                    <th>Product</th>
                                    <th>Issue date</th>
                                    <th>Expiration date</th>
                                    <th>Price Excl. VAT</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody v-if="saleDefinition.sale.certificate.certificateissue.length > 0">
                                <tr v-for="issue in saleDefinition.sale.certificate.certificateissue" :key="issue.sslCertificateIssueNumber">
                                    <td>{{ issue.issuer }}</td>
                                    <td>{{ issue.product }}</td>
                                    <td>{{ dateFormat(issue.issueDate) }}</td>
                                    <td>{{ dateFormat(issue.expirationDate) }}</td>
                                    <td>{{ currencyFormat(issue.priceExVat) }}</td>
                                    <td class="text-center" v-allowed:view="['CLIENT']">
                                        <button class="btn btn-green" @click="viewCertificateIssue(issue)" v-tooltip="'View'"><i class="fa-solid fa-scroll"></i></button>
                                    </td>
                                    <td class="text-center" v-allowed:view="['ADMIN', 'SENIOR_TECHNICAL_STAFF']">
                                        <button class="btn btn-info" @click="editCertificateIssue(issue)" v-tooltip="'Edit'"><i class="fa fa-edit"></i></button>
                                        <button class="btn btn-primary" @click="deleteCertificateIssue(issue)" v-tooltip="'Delete'"><i class="fa fa-trash"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="5">No Certificate issues</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
       </div>
       <AddSslCertificateIssueModal 
            ref="AddSslCertificateIssueModal"
            :modalTitle="modalTitle"
            :newIssue="newIssue"
            :products="saleDefinition.meta.certificate.children.certificateissue.objMeta.enums.product"
            :issuers="saleDefinition.meta.certificate.children.certificateissue.objMeta.enums.issuer"
            @createCertificateIssue="createCertificateIssue"
            @updateCertificateIssue="updateCertificateIssue"
       />
       <SslCertificateIssueModal
            ref="SslCertificateIssueModal"
            :selectedIssue="newIssue"
       />
    </div>
</template>

<script>
import AddSslCertificateIssueModal from "@/components/Admin/Modals/AddSslCertificateIssueModal";
import SslCertificateIssueModal from "@/components/Admin/Modals/SslCertificateIssueModal";

export default {
    props: {
        saleDefinition: {
            type: Object,
            required: true
        }
    },
    components: {
        AddSslCertificateIssueModal,
        SslCertificateIssueModal,
    },
    data() {
        return {
            modalTitle: "",
            newIssue: {},
        }
    },
    methods: {
        addCertificateIssue: function() {  
            this.newIssue = this.saleDefinition.meta.certificate.children.certificateissue.objMeta.new;
            this.newIssue.sslCertificateNumber = this.saleDefinition.sale.certificate.sslCertificateNumber;
            this.newIssue.billingMonth = this.getCurrentPeriod();
            this.$refs.AddSslCertificateIssueModal.open();
        },
        createCertificateIssue: function(newIssue) {    
            this.$refs.AddSslCertificateIssueModal.$refs.addCertificateIssue.isLoading();

            const hostUrl = this.$config.aimsAPI;
            const saleNumber = this.saleDefinition.sale.saleNumber;

            this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/create`, newIssue).then(
                (response) => {
                    this.showSuccess("Successfully create a new certificate issue");
                    this.$emit("reloadSale");
                    this.$refs.AddSslCertificateIssueModal.$refs.addCertificateIssue.isLoading();                    
                    this.$refs.AddSslCertificateIssueModal.$refs.issuerInput.selectedValue = null;
                    this.$refs.AddSslCertificateIssueModal.$refs.productInput.selectedValue = null;
                    this.$refs.AddSslCertificateIssueModal.$refs.addCertificateIssue.close();
                },
                (error) => {
                    this.showError("Error creating a new certificate issue", error);
                    this.$refs.AddSslCertificateIssueModal.$refs.addCertificateIssue.isLoading();
                }              
            )               
        },
        editCertificateIssue: function(issue) {
            this.modalTitle = "Edit"
            this.newIssue = this.deepCopyObject(issue)
            this.$refs.AddSslCertificateIssueModal.open();
        },
        updateCertificateIssue: function(selectedIssue) {
            this.$refs.AddSslCertificateIssueModal.$refs.addCertificateIssue.isLoading();

            const hostUrl = this.$config.aimsAPI;
            const saleNumber = this.saleDefinition.sale.saleNumber;

            this.$http.put(`${hostUrl}sales/definition/${saleNumber}/obj/update`, selectedIssue).then(
                (response) => {
                    this.showSuccess("Successfully Updated Certificate Issue");
                    this.$emit("reloadSale");
                    this.$refs.AddSslCertificateIssueModal.$refs.addCertificateIssue.isLoading();
                    this.$refs.AddSslCertificateIssueModal.$refs.addCertificateIssue.close();
                },
                (error) => {
                    console.error(error);
                    this.showError("Error Updating Certificate Issue", error);
                    this.$refs.AddSslCertificateIssueModal.$refs.addCertificateIssue.isLoading();
                }
            )
        },
        deleteCertificateIssue: function(selectedIssue) {
            const hostUrl = this.$config.aimsAPI;
            const saleNumber = this.saleDefinition.sale.saleNumber;

            this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/delete`, selectedIssue).then(
                (response) => {
                    this.showSuccess("Successfully Deleted Certificate Issue");
                    this.$emit("reloadSale");
                },
                (error) => {
                    console.error(error);
                    this.showError("Error Removing Certificate Issue", error);
                });
        },
        viewCertificateIssue: function(issue) {
            this.newIssue = this.deepCopyObject(issue)
            this.$refs.SslCertificateIssueModal.open();
        }
    }
}
</script>
