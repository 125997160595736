<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label">Username</label>
                <div class="input-group">
                  <input v-model="value.l2tpAccount.username" type="text" class="form-control" disabled="" />
                  <div class="input-group-append">
                    <span class="input-group-text">@{{value.l2tpAccount.l2tprealm.name}}</span>
                  </div>
                  <div class="input-group-append">
                    <button @click="copyText(value.l2tpAccount.username + '@' + value.l2tpAccount.l2tprealm.name)"
                      type="button" class="btn btn-info"><i class="fa fa-clipboard"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12" v-allowed:view="['STAFF']" v-if="value.l2tpAccount.password">
              <div class="form-group">
                <label class="control-label">Password</label>
                <PasswordInput :password="value.l2tpAccount.password" :userCanEdit="false" @changePassword="changePassword"/>
              </div>
            </div>
            <div class="col-md-12" v-allowed:view="['STAFF']">
              <button class="btn btn-primary" @click="savePart(value.l2tpAccount, 'L2tp Account')">Save L2tp
                Account</button>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>P2P Network</label>
              <div class="input-group">
                <input class="form-control" v-model="value.l2tpAccount.p2pIpv4Network.address" />
                <div class="input-group-append">
                  <div class="input-group-text">/{{ value.l2tpAccount.p2pIpv4Network.shortMask }}</div>
                </div>
              </div>
              <div class="row row-spacing" v-if="p2pSave">
                <div class="col-md-6"> 
                  <button type="button" @click="$emit('saveNetwork')" class="btn btn-primary">
                    Save Network
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <div class="row">
            <label class="col align-self-center mb-0">Routed Networks</label>
            <div class="col align-self-end" v-allowed:view="['STAFF']">
              <button class="btn btn-info offset-md-6 align-self-end" @click="addRoutedNetwork()" type="button">Add Routed Network</button>
            </div>
          </div>
          <table class="table table-bordered row-spacing">
            <thead>
              <tr>
                <th>Address</th>
                <th>Mask</th>
                <th>Metric</th>
                <th v-allowed:view="['STAFF']"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="routedIpv4Network in value.l2tpAccount.routedIpv4Networks"
                :key="routedIpv4Network.l2tpIpv4NetworkNumber">
                <td>{{routedIpv4Network.address}}</td>
                <td>{{routedIpv4Network.shortMask}}</td>
                <td>{{routedIpv4Network.metric}}</td>
                <td class="text-center" v-allowed:view="['STAFF']">
                  <button type="button" v-tooltip="'Edit Routed Network'" @click="routedNetwork = deepCopyObject(routedIpv4Network); modalTitle = 'Edit Routed Network'; $refs.addRoutedNetwork.open();" class="btn btn-sm btn-info m-1">
                    <em class="fa fa-edit fa-fw"></em>
                  </button>
                  <button type="button" v-tooltip="'Delete Routed Network'" @click="deleteRoutedNetwork(routedIpv4Network)" class="btn btn-sm btn-primary">
                    <em class="fa fa-trash fa-fw"></em>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <label>MPLS VPN</label>
          <select class="form-control" v-model="value.mplsVpnNumber" disabled>
            <option v-for="mplsvpn in vpns" :value="mplsvpn.mplsVpnNumber" :key="mplsvpn.mplsVpnNumber">
              {{mplsvpn.vrfName}}</option>
          </select>
        </div>
      </div>
    </div>
    <modal :title="modalTitle" ref="addRoutedNetwork"
      @save="saveRoutedNetwork(routedNetwork)">
      <div class="row">
        <div class="form-group col-md-10">
          <label class="control-label">
            Routed Network
          </label>
          <div class="input-group">
            <input class="form-control" v-model="routedNetwork.address" />
            <div class="input-group-append">
                <select class="form-control" v-model="routedNetwork.shortMask">
              <option value="24">/24</option>
              <option value="25">/25</option>
              <option value="26">/26</option>
              <option value="27">/27</option>
              <option value="28">/28</option>
              <option value="29">/29</option>
              <option value="30">/30</option>
              <option value="32">/32</option>
            </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
          <div class="form-group col-md-10">
            <label class="control-label">
              Metric
            </label>
            <input type="text" class="form-control" v-model="routedNetwork.metric">
          </div>
        </div>
    </modal>
  </div>
</template>

<script>
  import PasswordInput from '@/components/GeneralComponents/InputControls/PasswordInput'
  import Modal from '@/components/GeneralComponents/Modal'
  export default {
    components: {
      PasswordInput,
      Modal
    },
    props: {
      value: {
        type: Object,
        required: true
      },
      vpns: {
        type: Array,
        required: true
      },
      p2pSave: {
        type: Boolean,
        required: false
      }
    },
    data() {
        return {
            routedNetwork: {},
            modalTitle: ""
        }
    },
    methods: {
      changePassword: function(data) {
        if (data) {
          this.value.l2tpAccount.password = data;
        }
      },
      addRoutedNetwork() {
        this.modalTitle = "Add Routed Network";
        this.routedNetwork = {
          class: "za.co.adept.aims.protocol.l2tp.L2tpIpv4Network",
          l2tpAccountNumber: this.value.l2tpAccount.l2tpAccountNumber,
          family: "IPV4",
          type: "ROUTED"
        }
        this.$refs.addRoutedNetwork.open();
      },
      savePart(obj, name) {
        this.$http.put(this.$config.aimsAPI + "sales/definition/" + this.value.saleNumber + "/obj/update/", obj).then(
          success => {
            this.showSuccess("Saved " + name)
          }, error => {
            console.error(error);
            this.showError("Error Saving " + name, error);
          });
      },
      saveRoutedNetwork(obj) {
        this.$refs.addRoutedNetwork.isLoading();
        if(obj.objKey) {
            this.$http.put(this.$config.aimsAPI + "sales/definition/" + this.value.saleNumber + "/obj/update", obj)
                .then(success => {
                    this.showSuccess("Saved L2tp Routed IPv4 Network");
                    this.$refs.addRoutedNetwork.close();
                    this.$emit("loadSaleDefinition");
                }, error => {
                    console.error(error);
                    this.showError("Error Saving " + name, error);
                }).finally(() => {
                    this.$refs.addRoutedNetwork.isLoading();
                });
        } else {
            this.$http.post(this.$config.aimsAPI + "sales/definition/" + this.value.saleNumber + "/obj/create", obj)
                .then(success => {
                    this.showSuccess("Created L2tp Routed IPv4 Network");
                    this.$refs.addRoutedNetwork.close();
                    this.$emit("loadSaleDefinition");
                }, error => {
                    console.error(error);
                    this.showError("Error Saving " + name, error);
                }).finally(() => {
                    this.$refs.addRoutedNetwork.isLoading();
                });
        }
      },
      deleteRoutedNetwork(obj) {
        this.$refs.addRoutedNetwork.isLoading();
        this.$swal({
          title: 'Are you sure you want to delete this L2TP routed network?',
          text: obj.address + "/" + obj.shortMask,
          type: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then(result => {
          if (result.value) {
            this.$http.post(this.$config.aimsAPI + "sales/definition/" + this.value.saleNumber + "/obj/delete", obj)
              .then(success => {
                this.showSuccess("Deleted L2tp Routed IPv4 Network");
                this.$refs.addRoutedNetwork.close();
                this.$emit("loadSaleDefinition");
              }, error => {
                console.error(error);
                this.showError("Error Saving " + name, error);
              }).finally(() => {
                this.$refs.addRoutedNetwork.isLoading();
              });
          }
        });
      }
    }
  }

</script>

<style>

</style>
