<template>
<div class="card card-default">
    <div class="card-body">
        <div class="row">
            <div class="col-lg-12">
                <button class="btn btn-success" @click="addRealm()" type="button">
                    New Realm
                </button>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-md-7">
                <div class="mb-3">
                    <input type="text" class="form-control" placeholder="Search Realm Name" v-model="searchInput" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-7">
                <div class="table-responsive table-bordered">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Realm Name</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="realm in filteredRealms" :key="realm.realmNumber">
                                <td>
                                    <input type="text" class="form-control" v-model="realm.name" />
                                </td>
                                <td class="text-center">
                                    <button v-tooltip="'Save Realm'" @click="saveRealm(realm)" type="button" class="btn btn-sm btn-info m-1">
                                        <em class="fa fa-save fa-fw"></em>
                                    </button>
                                    <button v-tooltip="'Delete Realm'" @click="deleteRealm(realm.realmNumber)" type="button" class="btn btn-sm btn-primary">
                                        <em class="fa fa-trash fa-fw"></em>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-md-7">
                <button class="btn btn-success pull-right" @click="saveAll()" type="button">
                    Save All Realms
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    computed: {
        filteredRealms: function () {
            return this.realms.filter((realm) => {
                if (realm.name.toLowerCase().includes(this.searchInput.toLowerCase())) {
                    return true;
                }
                return false;
            });
        },
    },
    data() {
        return {
            realms: [],
            searchInput: "",
        };
    },
    created() {
        this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
    },
    mounted() {
        this.loadRealms();
    },
    methods: {
        loadRealms() {
            this.$http.get(this.$config.aimsAPI + "realms").then(
                (response) => {
                    this.realms = response.data;
                },
                (error) => {
                    this.showError("Error fetching realms", error);
                    console.error(error);
                }
            );
        },
        addRealm() {
            this.$http.get(this.$config.aimsAPI + "realms/new").then(
                (response) => {
                  this.realms.unshift(response.data);
                },
                (error) => {
                    this.showError("Error fetching new realm", error);
                    console.error(error);
                }
            );
        },
        saveRealm(realm) {
            this.$http.post(this.$config.aimsAPI + "realms/save", realm).then(
                (response) => {
                    this.showSuccess(response.data.message);
                    this.loadRealms();
                },
                (error) => {
                    this.showError("Error saving Realm", error);
                    console.error(error);
                }
            );
        },
        saveAll() {
            this.$http.post(this.$config.aimsAPI + "realms/save/all", this.realms).then(
                (response) => {
                    this.showSuccess(response.data.message);
                    this.loadRealms();
                },
                (error) => {
                    this.showError("Error saving realms", error);
                    console.error(error);
                }
            );
        },
        deleteRealm(objKey) {
            this.$swal({
                title: "Are you sure you want to delete Realm?",
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then(result => {
                if (result.value) {
                    this.$http.delete(this.$config.aimsAPI + "realms/realm/" + objKey).then(
                        (response) => {
                            this.showSuccess(response.data.message);
                            this.loadRealms();
                        },
                        (error) => {
                            this.showError("Error deleting realm", error);
                            console.error(error);
                        }
                    );
                }
            });
        },
    },
};
</script>

<style scoped>
td>input.form-control,
td>.input-group>input.form-control {
    width: 100%;
}
</style>
