<template>
    <div>
        <!-- Row for the current item -->
        <tr :class="{ 'parent-row': item.children, 'child-row': !item.children }" style="width: 100%" @click="toggleRow(item)">
            <template v-if="item.children">
                <td v-for="n in parentChild"></td>
                <td style="width: 80em">
                    <i class="fa-solid fa-chevron-down" v-if="expandedRows.includes(item)"></i> {{ item.name }} 
                </td>
            </template>
            <template v-else>
                <td v-for="field in fieldNames" :key="field.id" style="width: 22em">
                     {{ item[field.id] }}
                </td>
                <td>
                    <button v-tooltip="'Edit Setting'" @click="editSetting(item)" type="button" class="btn btn-sm btn-info m-1">
                        <em class="fa fa-edit fa-fw"></em>
                    </button>
                </td>
            </template>
        </tr>

        <!-- Render children rows if the parent row is expanded -->
        <template v-if="expandedRows.includes(item)">
            <SettingTreeTableRow
                v-for="child in item.children"
                :key="child.name"
                :item="child"
                :fieldNames="fieldNames"
                :expandedRows="expandedRows"
                :parentChild="1 + parentChild"
                @toggle="toggleRow"
                @edit="editSetting"
            />
        </template>
    </div>
</template>

<script>
export default {
    name: 'SettingTreeTableRow',
    props: {
        item: {
            type: Object,
            required: true
        },
        fieldNames: {
            type: Array,
            required: true
        },
        expandedRows: {
            type: Array,
            required: true
        },
        parentChild: {
            type: Number,
            required: false,
            default: 0,
        }
    },
    methods: {
        toggleRow(item) {
            this.$emit('toggle', item);
        },
        editSetting(item) {
            this.$emit('edit', item);
        }
    }
}
</script>

<style scoped>
.parent-row {
    cursor: pointer;
    font-weight: bold;
}

.parent-row:hover {
    background-color: rgb(250, 250, 250);
}

.child-row:hover {
    background-color: rgb(250, 250, 250);
}

.mlc {
    margin-left: 2em;
}

td {
    font-size: 17;
}
</style>
