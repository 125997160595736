<template>
    <div>
        <ul class="nav nav-pills nav-fill" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="package-tab" data-toggle="tab" href="#package" role="tab" aria-controls="package" aria-selected="true"><i class="fa fa-dropbox"></i> Packages</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="topup-tab" data-toggle="tab" href="#topup" role="tab" aria-controls="topup" aria-selected="true"><i class="fa fa-database"></i> Topups</a>
            </li>
        </ul>
        <div class="tab-content">
            <div class="tab-pane active" id="package" role="tabpanel" aria-labelledby="package-tab">
                <LtePackagePrices :productNumber="productNumber" :productSupplier="productSupplier" :userEditRight="userEditRight"/>
            </div>
            <div class="tab-pane" id="topup" role="tabpanel" aria-labelledby="topup-tab">
                <LteTopupPrices :productNumber="productNumber" :productSupplier="productSupplier" :userEditRight="userEditRight"/>
            </div>
        </div>
    </div>
</template>
<script>
import LtePackagePrices from '@/components/Admin/LtePackages/LtePackagePrices'
import LteTopupPrices from '@/components/Admin/LtePackages/LteTopupPrices'
export default {
    props: {
        productNumber: {
            type: Number,
            required: true
        },
        productSupplier: {
            type: String,
            required: true
        },
        userEditRight: {
        type: Boolean,
        required: true
        },
    },
    mounted() {
        
    },
    components: {
        LtePackagePrices,
        LteTopupPrices
    }
}
</script>

<style scoped>
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: #f05050 !important;
  }
  .nav-pills .nav-link {
    background-color: #f3f2ee;
  }
  a {
    color: rgba(0,0,0,.6);
  }
</style>