<template>
  <div class="row">
    <div class="col-xl-8 col-lg-12">
      <div class="card card-default">
        <form-wizard
          title=""
          subtitle=""
          @on-complete="onComplete"
          stepSize="sm"
        >
          <tab-content
            title="Account Options"
            :before-change="() => validateStep('account-selection')"
          >
            <form novalidate data-vv-scope="account-selection">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('account-selection.Customer'),
                    }"
                  >
                    <label class="control-label">nCentral Customer *</label>
                    <div class="input-group">
                      <v-select
                        v-validate="'required'"
                        v-model="selectedCustomer"
                        ref="speed"
                        name="Customer"
                        placeholder="Select nCentral Customer"
                        label="customerDescription"
                        :options="nCentralCustomers"
                      ></v-select>
                    </div>
                    <span
                      class="has-error"
                      v-if="errors.has('account-selection.Customer')"
                      >{{ errors.first("account-selection.Customer") }}</span
                    >
                  </div>
                </div>
              </div>
            </form>
          </tab-content>
        </form-wizard>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Address from "@/components/GeneralComponents/Address";

export default {
  components: {
    FormWizard,
    TabContent,
    Address,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filledInSaleDefinition: {},
      nCentralCustomers: [],
      selectedCustomer: null,
    };
  },
  mounted: function () {
    this.filledInSaleDefinition = JSON.parse(
      JSON.stringify(this.emptySaleDefinition)
    );
    this.$http
      .get(this.$config.aimsAPI + "managedservices/ncentral/customers/list")
      .then(
        (response) => {
          this.nCentralCustomers = response.body;
        },
        (error) => {
          this.showError("Error fetching NCentral Customers list", error);
          console.error(error);
        }
      );
  },
  methods: {
    validateStep: function (stepName) {
      return this.$validator.validateAll(stepName).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
    onComplete() {
      this.filledInSaleDefinition.requiredObjs.nCentralCustomer.customerAccessCode =
        this.selectedCustomer.customerAccessCode;
      this.filledInSaleDefinition.requiredObjs.nCentralCustomer.customerDescription =
        this.selectedCustomer.customerDescription;
      this.$swal({
        title: this.selectedCustomer.customerDescription,
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Create Service",
      }).then((result) => {
        if (result.value) {
          this.$emit("createSale", this.filledInSaleDefinition);
        }
      });
    },
  },
};
</script>