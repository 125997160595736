<template>
  <div class="row">
    <div class="col-md-8">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template v-slot:spladetails>
            <form novalidate data-vv-scope="spladetails">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group">
                    <label class="control-label">Name: </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="filledInSaleDefinition.requiredObjs.spladevice.name"
                    />
                  </div>
                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
export default {
  components: {
    ServiceWizardLayout,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "spladetails",
          displayName: "SPLA Details",
        }
      ],
      filledInSaleDefinition: {
        requiredObjs: {
          splaDevice: {}
        }
      }
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
  },
  methods: {
    onComplete() {
      this.$emit("createSale", this.filledInSaleDefinition);
    },
    validateStep(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>
