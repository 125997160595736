<template>
    <service-screen-layout :parts="parts">
        <template v-slot:sla-details>
            <div class="col-md-12">
                <div class="row">
                    <div class="form-group col-md-6">
                        <label>Description</label>
                        <input type="text" class="form-control" v-model="saleDefinition.definition.serviceLevelAgreement.description" disabled>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6">
                        <label>Price (excl. VAT)</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    R
                                </div>
                            </div>
                            <input type="text" class="form-control" v-model="saleDefinition.definition.serviceLevelAgreement.priceExclVat" disabled>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6">
                        <label>Service Supported</label>
                        <div class="input-group">
                            <input type="text" class="form-control" v-model="linkedSale.sale.description" disabled>
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <small class="btn-link" @click="viewSale()" style="cursor: pointer;">View sale</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";

export default {
    components: {
        ServiceScreenLayout
    },
    data() {
        return {
            parts: [
                {
                name: "sla-details",
                icon: "fa-solid fa-file-contract",
                displayName: "Support SLA Details",
                permissions: ["*"],
                },
            ],
            saleNumber: null,
            saleDefinition: {
                definition: {
                    serviceLevelAgreement: {}
                }
            },
            linkedSale: {
                product: {},
                sale: {}
            }
        }
    },
    mounted() {
        this.saleNumber = this.$route.params.saleNumber;
        this.getSale();
    },
    methods: {
        getSale: function(saleNumber) {
            const hostUrl = this.$config.aimsAPI;

            this.$http.get(`${hostUrl}sales/definition/sale/${this.saleNumber}`).then(
                (response) => {
                    this.saleDefinition = response.data;                      
                    this.getLinkedSale(this.saleDefinition.definition.serviceLevelAgreement.supportedSaleNumber);                  
                },
                (error) => {
                    console.error(error);
                    this.showError("Error fetching Sale Definition", error);
                }
            );
        },
        getLinkedSale: function(saleNumber) {
            const hostUrl = this.$config.aimsAPI;

            this.$http.get(`${hostUrl}sales/definition/sale/${saleNumber}`).then(
                (response) => {
                    this.linkedSale = response.data;                    
                },
                (error) => {
                    console.error(error);
                    this.showError("Error fetching Sale Definition", error);
                }
            );   
        },
        viewSale: function() {
            const productRoute = this.getProductDefinition(this.linkedSale.product.productDefinition);   
            const routeName = productRoute.routeName;
            const allRoutes = this.$router.getRoutes();
            const matchingRoute = allRoutes.find(route => route.name === routeName);
            const pathName = matchingRoute.path.split("/");
            
            this.$router.push({ path: `/main/client/${this.$route.params.clientNumber}/sales/${this.saleDefinition.definition.serviceLevelAgreement.supportedSaleNumber}/${pathName[pathName.length - 1]}` });         
        }
    }
}
</script>

<style scoped>
.sale-button {
    text-align: left;
    cursor: pointer; 
    background-color: #edf1f2;

    &:hover {
        color: #1b72e2;
        border: 1px solid #1b72e2;
    }
}
</style>