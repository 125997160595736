<template>
    <div class="col-md-12">
        <div class="row row-spacing justify-content-between">
            <div class="col-md-10 pl-0">
                <h3>CPanel Domains</h3>
            </div>
            <div class="col-md-2 pr-0">
                <button class="btn btn-info pull-right" :disabled="!isAdmin" @click="openAddDomainModal()">Add Domain</button>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="table-responsive table-bordered">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="saleDefinition.definition.configuration.cpaneldomains.length > 0">
                        <tr 
                            v-for="domain in saleDefinition.definition.configuration.cpaneldomains"
                            :key="domain.cPanelDomainNumber"
                        >
                            <td>{{ domain.name }}</td>
                            <td>
                                <div class="d-flex mt-3 justify-content-center">
                                    <button
                                        class="btn btn-primary"
                                        :disabled="!isAdmin"
                                        @click="deleteDomain(domain)"
                                        v-tooltip="'Delete Domain'"
                                    >
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="6">No Domains associated with this Sale</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <CPanelDomainModal
            ref="CPanelDomainModal"
            :modalTitle="modalTitle"
            :domain="selectedDomain"
            @saveDomain="saveDomain"
        />
    </div>
</template>

<script>
import CPanelDomainModal from '@/components/Admin/Modals/CPanelDomainModal';

export default {
    components: {
        CPanelDomainModal
    },
    props: {
        saleDefinition: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            modalTitle: "",
            selectedDomain: {}
        }
    },
    mounted() {
        
    },
    computed: {
        isAdmin() {
            return this.userContainsPermission(['ADMIN']);
        }
    },
    methods: {
        openAddDomainModal: function() {
            this.$refs.CPanelDomainModal.open();
            this.modalTitle = 'Add New CPanel Domain'
            this.selectedDomain = this.saleDefinition.supportingObjs.cpaneldomains;
        },
        saveDomain: function(data) {  
            this.saleDefinition.definition.configuration.cpaneldomains.push(data);
            this.$emit('saveSale', data);
        },
        deleteDomain: function(data) {
            this.$swal({
                title: `Delete record in Service Panel?`,
                text: "You need to remove ("+data.name+") using cPanel",
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    const hostUrl = this.$config.aimsAPI;
                    const saleNumber = this.saleDefinition.sale.saleNumber;
                    
                    this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/delete`, data).then(
                        (response) => {
                            this.showSuccess("CPanel Domain successfully removed");
                            this.$emit("reloadSale");
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error Removing CPanel Domain", error);
                        });
                }
            });
        }
    },
}
</script>

