<template>
  <ServiceScreenLayout :parts="parts">
    <template v-slot:service>
      <div class="row">
        <div class="col-md-7">
          <div class="card-header" >
              <h4>Domain</h4>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Domain Name</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="saleDefinition.definition.domain.name"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Origin</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="saleDefinition.definition.domain.origin"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Serial</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="saleDefinition.definition.domain.serial"
                      disabled
                    />
                  </div>
              </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Minimum TTL</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="saleDefinition.definition.domain.minimumTtl"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-row row-spacing justify-content-between" v-allowed:view="['STAFF']">
              <div class="form-group col-auto">
                <button type="button" @click="saveSaleDefinition()" class="btn btn-success">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:hosts>
      <div class="row">
        <div class="col-md-12">
          <div class="card-body">
            <button type="button" class="btn btn-info" @click="openHostModal()" v-allowed:view="['STAFF']">Add Host</button>
            <table class="table table-bordered row-spacing">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Destination</th>
                  <th>TTL</th>
                  <th>Priority</th>
                  <th v-allowed:view="['STAFF']"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(host, index) in hosts" :key="index" :class="{'new-table-row': host.isNew}">
                  <td>{{host.name}}</td>
                  <td>{{host.type}}</td>
                  <td style="word-break: break-word">{{host.destination}}</td>
                  <td>{{host.ttl}}</td>
                  <td>{{host.type == "MX" || host.type == "SRV" ? host.priority : ""}}</td>
                  <td style="text-align:center" v-allowed:view="['STAFF']">
                    <button type="button" class="btn btn-info" @click="openHostModal(host)"><i class="fa fa-edit"></i></button>
                    <button type="button" class="btn btn-red" @click="deleteHost(host)"><i class="fa fa-trash" style="color: white;"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <modal
        :title="newHost.objKey ? 'Edit Host' : 'Add Host'"
        ref="hostModal"
        size="modal-md"
        @save="saveHost()"
        @dismiss="dismissHostModal()"
      >
        <div class="row">
          <div class="col-md-10">
            <div class="form-group">
              <label>Name:</label>
              <input
                name="hostName"
                class="form-control"
                type="text"
                :disabled="newHost.objKey > 0"
                @keyup="forceLowercase()"
                v-model="newHost.name"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10">
            <div
              class="form-group"
              :class="{ 'has-error': errors.has('Type') }"
            >
              <label class="control-label">Type:</label>
              <v-select 
                name="Type"
                placeholder="Select a Type"
                :options="typeOptions" 
                label="description" 
                valueProp="name" 
                v-validate="'required'"
                v-model="newHost.type">
              </v-select>
              <span class="has-error" v-if="errors.has('Type')">{{ errors.first("Type") }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10">
            <div
              class="form-group"
              :class="{ 'has-error': errors.has('Destination') }"
            >
              <label>Destination:</label>
              <input
                name="Destination"
                class="form-control"
                type="text"
                v-validate="'required'"
                v-model="newHost.destination"
              />
              <span class="has-error" v-if="errors.has('Destination')">{{ errors.first("Destination") }}</span>
            </div>
          </div>
        </div>
        <div class="row" v-if="newHost.type == 'MX' || newHost.type == 'SRV'">
          <div class="col-md-10">
            <div
              class="form-group"
              :class="{ 'has-error': errors.has('Priority') }"
            >
              <label>Priority:</label>
              <input
                name="Priority"
                class="form-control"
                type="text"
                v-validate="'numeric|min_value:1'"
                v-model="newHost.priority"
              />
              <span class="has-error" v-if="errors.has('Priority')">{{ errors.first("Priority") }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10">
            <div
              class="form-group"
              :class="{ 'has-error': errors.has('TTL') }"
            >
              <label>TTL:</label>
              <input
                name="TTL"
                class="form-control"
                type="text"
                v-validate="'numeric|between:60,21600'"
                v-model="newHost.ttl"
              />
              <span class="has-error" v-if="errors.has('TTL')">{{ errors.first("TTL") }}</span>
            </div>
          </div>
        </div>
      </modal>
    </template>
  </ServiceScreenLayout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";
import Modal from "@/components/GeneralComponents/Modal";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    Modal,
  },
  data() {
    return {
      saleNumber: null,
      parts: [
        {
          name: "service",
          icon: "fa fa-user-circle",
          displayName: "Service",
          permissions: ["*"],
        },
        {
          name: "hosts",
          icon: "fa fa-server",
          displayName: "Hosts",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        definition: {
          domain: {

          }
        }
      },
      hosts: [],
      newHost: {},

      typeOptions: [],

    };
  },
  async mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition() {
      this.getSaleDefinition(this.saleNumber).then(
        (success) => {
          this.saleDefinition = success.data;

          this.hosts = this.saleDefinition.definition.domain.mxHosts;
          this.hosts = this.hosts.concat(this.saleDefinition.definition.domain.nsHosts);
          this.hosts = this.hosts.concat(this.saleDefinition.definition.domain.otherHosts);

          this.typeOptions = this.saleDefinition.supportingEnums.Host.type;

          this.dismissHostModal();
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
      return Promise.resolve('Success');
    },
    saveSaleDefinition: function() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http.put(this.$config.aimsAPI + "sales/definition/" + this.saleNumber + "/update", this.saleDefinition)
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.loadSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Sale Definition", error);
                this.loadSaleDefinition();
              }
            );
        }
      });
    },
    forceLowercase() {
      this.newHost.name = this.newHost.name.toLowerCase();
    },

    openHostModal(data) {
      if (data) {
        this.newHost = data;
      } else {
        this.newHost = this.saleDefinition.supportingObjs.otherHosts;
        this.newHost.domainNumber = this.saleDefinition.definition.domain.domainNumber;
        this.newHost.saleNumber = this.saleDefinition.definition.domain.saleNumber;
        this.newHost.ttl = this.saleDefinition.definition.domain.minimumTtl;
        this.newHost.type = "";
      }
      this.$refs.hostModal.open();
    },
    dismissHostModal() {
      this.newHost = [];
      this.$refs.hostModal.close();
    },
    saveHost() {
      this.$validator.validateAll().then((result) => {
      this.$refs.hostModal.isLoading();
        if (result) {
          if (this.newHost.objKey) {
            this.editHost();
          } else {
            this.addHost();
          }
        }
      });
    },
    addHost() {
      let temp = this.deepCopyObject(this.saleDefinition);
      temp.definition.domain.otherHosts.push(this.newHost);
      
      this.$http.put(this.$config.aimsAPI + "sales/definition/" + this.saleNumber + "/update", temp)
        .then(
          (response) => {
            this.showSuccess("Save Success");
            this.loadSaleDefinition();
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving Sale Definition", error);
            this.loadSaleDefinition();
          }
        )
    },
    editHost() {
      this.saveSaleDefinition();
    },
    deleteHost(data) {
      this.deleteObjPart(data)
        .then((response) => {
          this.showSuccess("Delete Success");
          this.loadSaleDefinition()
        },(error) => {
          console.error(error);
          this.showError("Error Deleting Extension", error);
        });
    },

    containsHost(host, list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i] === host) {
          return true;
        }
      }
    
      return false;
    }

  },
};
</script>