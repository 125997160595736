<template>
  <div class="card-body">
    <div class="row mb-3">
      <div class="col-md-3">
        <v-select :clearable="true" :options="types" v-model="typeSelected" placeholder="Select Object Type"></v-select>
      </div>
    </div>
    <div class="table-responsive table-bordered">
      <table class="table table-hover">
        <thead>
          <tr>
            <td>Date</td>
            <td>User</td>
            <td>Type</td>
            <td>Obj Type</td>
            <td>Version</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(history, index) in objHistory" :key="index" @click="viewObjHistory(history)">
            <td class="text-nowrap">{{dateTimeFormat(history.modified)}}</td>
            <td>{{history.username}}</td>
            <td>{{history.type}}</td>
            <td>{{history.name}}</td>
            <td>{{history.version}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import ServiceTitle from '@/components/Services/ServiceTitle'
  import SaleFunctions from '@/pages/Services/SaleFunctions'

  export default {
    extends: SaleFunctions,
    components: {
      ServiceTitle
    },
    data() {
      return {
        histories: [],
        typeSelected: null,
        types: [],
        saleDefinition: {}
      }
    },
    async mounted() {
      const saleNumber = this.$route.params.saleNumber;
      await this.getSaleDefinition(saleNumber).then(response => {
          this.saleDefinition = response.data;
        },
        error => {
          console.error(error);
          this.showError('Error fetching Sale Definition', error)
        })

      await this.$http.get(this.$config.aimsAPI + 'sales/definition/' + saleNumber + '/changehistory')
        .then(response => {
            this.histories = response.data;
          },
          error => {
            console.error(error);
            this.showError('Error fetching Sale History', error)
          })

      this.histories.forEach(history => {
        if (!this.types.includes(history.name)) {
          this.types.push(history.name)
        }
      })
    },
    methods: {
      viewObjHistory(history) {
        const objKey = history.objKey;
        const objClass = history.objClass
        this.$router.push({
          name: 'objhistory',
          params: {
            objKey,
            objClass
          }
        });
      }
    },
    computed: {
      objHistory() {
        if (this.typeSelected) {
          return this.histories.filter(history => {
            if (history.name == this.typeSelected) {
              return history
            }
          })
        } else {
          return this.histories;
        }
      }
    }
  }

</script>
