<template>
    <div class="card card-default">
        <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                    <h3>Reverse Zone IPv4</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Filter Zones..." v-model="searchParameter"  @keyup="filteredIpv4Zones = ipv4Zones">
                            <div class="input-group-append">
                                <span class="input-group-text"><i class="fa fa-search"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <!-- Select IPv Zone Type -->
                    <div class="form-group">
                        <button @click="viewIpv6Zones()" class="btn btn-toggle">View IPv6 Zones</button>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="form-group" style="text-align: right">
                        <button class="btn btn-primary" @click="addReverseRecord()">
                            Add Reverse Record
                        </button>
                        <button class="btn btn-primary" @click="addReverseZone()">
                            Add Reverse Zone
                        </button>
                    </div>
                </div>
            </div>
            <div class="row-spacing table-responsive table-bordered">
                <table class="table text-nowrap">
                    <thead>
                        <tr>
                            <th @click="sortBy('name')">Reverse Zone
                                <i class="fa fa-sort-asc float-right" v-if="sortKey == 'name' && reverse"></i>
                                <i class="fa fa-sort-desc float-right" v-if="sortKey == 'name' && !reverse"></i>
                            </th>
                            <th @click="sortBy('forwardName')">Address Range
                                <i class="fa fa-sort-asc float-right" v-if="sortKey == 'forwardName' && reverse"></i>
                                <i class="fa fa-sort-desc float-right" v-if="sortKey == 'forwardName' && !reverse"></i>
                            </th>
                            <th @click="sortBy('minimumTtl')">Minimum TTL
                                <i class="fa fa-sort-asc float-right" v-if="sortKey == 'minimumTtl' && reverse"></i>
                                <i class="fa fa-sort-desc float-right" v-if="sortKey == 'minimumTtl' && !reverse"></i>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="filteredIpv4Zones.length > 0">
                        <tr :class="{'new-table-row' : zone.isNew}" v-for="zone in filteredZones" :key="zone.publicIpv4ReverseZoneNumber" class="zone-table">
                            <td @click="goToReverseRecord(zone.publicIpv4ReverseZoneNumber)">{{ zone.name }}</td>
                            <td @click="goToReverseRecord(zone.publicIpv4ReverseZoneNumber)"> {{ zone.forwardName }}</td>
                            <td @click="goToReverseRecord(zone.publicIpv4ReverseZoneNumber)">{{ zone.minimumTtl }}</td>
                            <td class="text-center">
                                <button class="btn btn-info" @click="editReverseZone(zone)">
                                    <i class="fa fa-pencil-square-o"></i>
                                </button>
                                <button class="btn btn-primary" @click="deleteReverseZone(zone)">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="5" style="cursor: default">
                                No IPv4 Zones Found
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- Edit Zone Modal Component -->
            <ModifyRDNSZoneModal 
                ref="modifyZoneModal"
                :modalTitle="modalTitle"
                :rDNSZone="selectedZone"
                :ipvType="ipvType"
                @zoneEdited="getIpv4Zones()"
            />
            
            <!-- Add Zone Modal Component -->
            <AddNewDnsZoneModal 
                ref="AddZoneModal"
                :modalTitle="modalTitle"
                :ipvType="ipvType"
                @zoneAdded="addZoneToList"
            />

            <!-- Add Reverse Record Component -->
            <AddNewDnsRecordModal 
                ref="addRecordModal"
                :modalTitle="modalTitle"
                :ipvType="ipvType"
                @recordAdded="goToAddedRecord"
            />
        </div>
    </div>
</template>

<script>
import ModifyRDNSZoneModal from '@/components/Admin/Modals/ModifyRDNSZoneModal'
import AddNewDnsZoneModal from '@/components/Admin/Modals/AddNewDnsZoneModal'
import AddNewDnsRecordModal from "@/components/Admin/Modals/AddNewDnsRecordModal";

export default {
    data() {
        return {
            ipv4Zones: {
                forwardName: "",
            },
            filteredIpv4Zones: {},
            searchParameter: "",
            modalTitle: "",
            ipvType: "IPv4",
            selectedZone: {},
      
            sortKey: 'forwardName',
            reverse: true,
        }
    },
    components: {
        ModifyRDNSZoneModal,
        AddNewDnsZoneModal,
        AddNewDnsRecordModal,
    },
    created() {
        this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
    },
    mounted() {
        this.getIpv4Zones();
    },
    computed: {
        filteredZones: function() {
            this.filteredIpv4Zones = this.ipv4Zones;

            if (this.searchParameter.length > 0) {
                this.filteredIpv4Zones = this.filteredIpv4Zones.filter((ipv4Zone) => 
                ipv4Zone.name.toLowerCase().includes(this.searchParameter.toLowerCase()) ||
                ipv4Zone.forwardName.toLowerCase().includes(this.searchParameter.toLowerCase()) ||
                ipv4Zone.minimumTtl.toString().includes(this.searchParameter)
                );
            };

            if (this.reverse) {
                return _.orderBy(this.filteredIpv4Zones, this.sortKey, 'asc');
            } else {
                return _.orderBy(this.filteredIpv4Zones, this.sortKey, 'desc');
            }
        }
    },
    methods: {
        getIpv4Zones: function() {
            const hostUrl = this.$config.aimsAPI;
            const ipvType = this.ipvType.toLowerCase();
            this.$http.get(`${hostUrl}reverse/zones/${ipvType}`).then(
                (response) => {
                    this.ipv4Zones = response.data;
                    this.filteredIpv4Zones = this.ipv4Zones;
                },
                (error) => {
                    console.error(error);
                    this.showError("Error fetching IPv4 Zones")
                }
            )
        },
        addZoneToList(newZone) {
            this.ipv4Zones.push(newZone);
            this.filteredIpv4Zones = this.ipv4Zones;
        },
        addReverseZone: function() {
            this.modalTitle = `Add New Reverse Zone [${this.ipvType}]`
            this.$refs.AddZoneModal.open();
        },
        editReverseZone: function(data) {
            this.modalTitle = `Edit Reverse Zone [${this.ipvType}]`;
            this.selectedZone = this.deepCopyObject(data);
            this.$refs.modifyZoneModal.open();
        },
        deleteReverseZone: function(data) {
            this.$swal({
                title: "Are you sure you want to delete this Zone?",
                type: "warning",
                showCancelButton: true,
                cancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if(result.value) {
                    const hostUrl = this.$config.aimsAPI;
                    const ipvType = this.ipvType.toLowerCase();
                    const objectKey = data.objKey;
                    this.$http.delete(`${hostUrl}reverse/zones/${ipvType}/${objectKey}`).then(
                        () => {
                            this.showSuccess("Successfully deleted Zone");
                            this.getIpv4Zones();
                        },
                        (error) => {
                            this.showError("Error deleting Zone <br> There are Records associated with this Zone", error);
                            console.error(error);
                        }, 
                    );
                }
            });
        },
        viewIpv6Zones() {
            this.$router.push('reversednszoneipv6');
        },
        goToReverseRecord: function(data) {
            this.$router.push(`reversednsrecordipv4?ipv=${this.ipvType}&id=${data}`);
        },
        goToAddedRecord: function(data) {
            this.goToReverseRecord(data.publicIpv4ReverseZoneNumber);
        },
        addReverseRecord: function() {
            this.modalTitle = `Add New Reverse Record[${this.ipvType}]`;
            this.$refs.addRecordModal.open();
        },
        sortBy(sortKey) {
            this.reverse = (this.sortKey == sortKey) ? ! this.reverse : false;
            this.sortKey = sortKey;
        },
    },
}
</script>

<style scoped>
    .zone-table:hover{
        cursor: pointer;
        background-color: #fafafa;
    }

    .btn-toggle {
        background: #3f3f3f;
        border: 2px solid #3f3f3f;
        color: #ffffff;
    }

    .btn-toggle:hover {
        background: #444444;
        border: 2px solid #444444;
    }

    th:hover {
    cursor:pointer;
    }

    .table>thead>tr>th {
    padding: 1em;
    background-color: #3a3f51;
    color: #FFFFFF; 

    position: sticky;
    top: 0;
    z-index: 2;
    } 

    .pull-right {
    float: right;
    }
</style>