<template>
    <service-screen-layout :parts="parts">
        <template v-slot:server-details>
           <div class="row" v-if="saleLoaded == true">
                <div class="col-md-12">
                    <div class="row row-spacing">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Network</label>
                                <div class="input-group">
                                    <div class="form-control" style="background-color: #edf1f2">{{ networkString }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Name</label>
                                <div class="input-group">
                                    <input 
                                        type="text"
                                        class="form-control"
                                        v-model="saleDefinition.sale.servers.name"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row row-spacing">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>IP Address</label>
                                <div class="input-group">
                                    <input 
                                        type="text"
                                        class="form-control"
                                        v-model="saleDefinition.sale.servers.ipAddress"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Max Power Usage(PSU)</label>
                                <div class="input-group">
                                    <input 
                                        type="text"
                                        class="form-control"
                                        v-model="saleDefinition.sale.servers.psuSize"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row row-spacing">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Connected PSUs</label>
                                <div class="input-group">
                                    <input 
                                        type="text"
                                        class="form-control"
                                        v-model="saleDefinition.sale.servers.psuCount"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Connected LAN Ports</label>
                                <div class="input-group">
                                    <input 
                                        type="text"
                                        class="form-control"
                                        v-model="saleDefinition.sale.servers.lanPorts"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row row-spacing">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Cabinet Location</label>
                                <div class="input-group">
                                    <input 
                                        type="text"
                                        class="form-control"
                                        v-model="saleDefinition.sale.servers.cabinet"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Cabinet Space (in units)</label>
                                <div class="input-group">
                                    <input 
                                        type="text"
                                        class="form-control"
                                        v-model="saleDefinition.sale.servers.cabinetSpace"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row row-spacing">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Serial Number</label>
                                <div class="input-group">
                                    <input 
                                        type="text"
                                        class="form-control"
                                        v-model="saleDefinition.sale.servers.serial"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row row-spacing" v-allowed:view="['STAFF']">
                        <div class="col-md-2">
                            <div class="form-group">
                                 <button type="button" class="btn btn-success" @click="saveSaleDefinition()">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </template>
    </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";

export default {
   components: {
        ServiceScreenLayout
   },
   data() {
    return {
        saleNumber: null,
        hostedNetworkNumber: null,
        saleLoaded: false,
        parts: [
            {
                name: "server-details",
                icon: "fa fa-server",
                displayName: "Server Details",
                permissions: ["*"]
            }
        ],
        saleDefinition: {
            sale: {
                servers: {},
            },
        },
        networkName: '',
        networkAddress: '',
        networkShortMask: null,
        network: '',
    }
   },
   async mounted() {
    await this.getSaleNumber();
    await this.getSaleDefinition();
    
   },
   computed: {
    networkString() {
        return this.network = (`${this.networkName} - (${this.networkAddress}/${this.networkShortMask})`);
    }
   },
   methods: {
        getSaleNumber: function() {
            this.saleNumber = this.$route.params.saleNumber;
        },
        getSaleDefinition: function() {
            const hostUrl = this.$config.aimsAPIv2;
            const saleNumber = this.saleNumber;
            this.$http.get(`${hostUrl}sales/definition/sale/${saleNumber}`).then(
                (response) => {
                    this.saleLoaded = true;
                    this.saleDefinition = response.data;
                    this.hostedNetworkNumber = this.saleDefinition.sale.servers.hostedNetworkNumber;
                    if (this.hostedNetworkNumber) {
                        this.getNetworkName();
                        this.getNetworkAddressAndShortMask();
                    }
                },
                (error) => {
                    console.error(error);
                    this.showError("Error fetching Sale Definition", error);
                }
            );
        },
        reloadSale: function() {
            this.getSaleDefinition();
        },
        saveSaleDefinition: function() {
            const hostUrl = this.$config.aimsAPIv2;
            const saleNumber = this.saleNumber;
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.$http.put(`${hostUrl}sales/definition/${saleNumber}/update`, this.saleDefinition).then(
                        (response) => {
                            this.showSuccess("Save Success");
                            this.reloadSale();
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error saving Sale Definition", error);
                        }
                    );
                }
            });
        },
        getNetworkName: function() {
            const legacyHostUrl = this.$config.aimsAPIvLegacy;
            const hostedNetworkNumber = this.hostedNetworkNumber;
            this.$http.get(`${legacyHostUrl}networks/hosted/${hostedNetworkNumber}`).then(
                (response) => {
                    this.networkName = response.data.name;    
                },
                (error) => {
                    console.error(error);
                }
            );
        },

        getNetworkAddressAndShortMask: function() {
            const legacyHostUrl = this.$config.aimsAPIvLegacy;
            const hostedNetworkNumber = this.hostedNetworkNumber;
            this.$http.get(`${legacyHostUrl}networks/hosted/address/${hostedNetworkNumber}`).then(
                (response) => {
                    this.networkAddress = response.data[0].address;
                    this.networkShortMask = response.data[0].shortMask.toString();
                },
                (error) => {
                    console.error(error);
                }
            );
        },
    },
}
</script>
