<template>
    <div class="card card-default">
        <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                    <h3>Reverse Record IPv6</h3>
                    <p>Reverse Record of Reverse Zone <strong>{{ ipv6ZoneName }}</strong></p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Filter Records..." v-model="searchParameter" @keyup="filteredIpv6Records = ipv6Records">
                            <div class="input-group-append">
                                <span class="input-group-text"><i class="fa fa-search"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="form-group" style="text-align: right">
                        <button class="btn btn-primary" @click="addReverseRecord()">
                            Add Reverse Record
                        </button>
                        <button class="btn bg-info" @click="goToZones()" v-tooltip="'Back to IPv6 Zones'">
                            <i class="fa fa-arrow-circle-left mr-2"></i>
                            Back
                        </button>
                    </div>
                </div>
            </div>
            <div class="row-spacing table-responsive table-bordered">
                <table class="table text-nowrap">
                    <thead>
                        <tr>
                            <th @click="sortBy('address')">Address
                                <i class="fa fa-sort-asc float-right" v-if="sortKey == 'address' && reverse"></i>
                                <i class="fa fa-sort-desc float-right" v-if="sortKey == 'address' && !reverse"></i>
                            </th>
                            <th @click="sortBy('forwardAddress')">Forward Address
                                <i class="fa fa-sort-asc float-right" v-if="sortKey == 'forwardAddress' && reverse"></i>
                                <i class="fa fa-sort-desc float-right" v-if="sortKey == 'forwardAddress' && !reverse"></i>
                            </th>
                            <th @click="sortBy('name')">Name
                                <i class="fa fa-sort-asc float-right" v-if="sortKey == 'name' && reverse"></i>
                                <i class="fa fa-sort-desc float-right" v-if="sortKey == 'name' && !reverse"></i>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="filteredIpv6Records.length > 0">
                        <tr :class="{'new-table-row' : record.isNew}" v-for="record in filteredRecords" :key="record.publicIpv6ReverseRecordNumber" class="zone-table">
                            <td>{{ record.address }}</td>
                            <td>{{ record.forwardAddress }}</td>
                            <td>{{ record.name }}</td>
                            <td class="text-center">
                                <button class="btn btn-info" @click="editReverseRecord(record)">
                                    <i class="fa fa-pencil-square-o"></i>
                                </button>
                                <button type="button" @click="verifyRecord(record)" class="btn btn-sm btn-green m-1" v-tooltip="'Verify Record'">
                                    <em class="fa-solid fa-eye"></em>
                                </button>
                                <button class="btn btn-primary" @click="deleteReverseZone(record)">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="5" style="cursor: defualt">
                                No IPv6 Records Found for this Zone
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ModifyDnsRecordModal 
                ref="modifyRecordModal"
                :modalTitle="modalTitle"
                :dnsRecord="selectedRecord"
                :ipvType="ipvType"
                @recordsLoaded="getIpv6ReverseRecords()"
            />
            <AddNewDnsRecordModal 
                ref="addRecordModal"
                :modalTitle="modalTitle"
                :ipvType="ipvType"
                @recordAdded="addRecordToList"
            />
            <VerifyReverseRecordModal 
                ref="verifyReverseRecordModal"
                :modalTitle="modalTitle"
                :verifyData="verifyData"
                :dnsRecord="selectedRecord"
                :isIPv6="true"
            />
        </div>
    </div>
</template>

<script>
import ModifyDnsRecordModal from "@/components/Admin/Modals/ModifyDnsRecordModal";
import AddNewDnsRecordModal from "@/components/Admin/Modals/AddNewDnsRecordModal";
import VerifyReverseRecordModal from "@/components/Admin/Modals/VerifyReverseRecordModal";

export default {
    data() {
        return {
            ipvType: "",
            zoneId: "",
            ipv6Records: {},
            filteredIpv6Records: {},
            searchParameter: "",
            ipv6ZoneName: "",
            modalTitle: "",
            selectedRecord: {},
            sortKey: 'address',
            reverse: false,
            verifyData: [],
        }
    },
    components: {
        ModifyDnsRecordModal,
        AddNewDnsRecordModal,
        VerifyReverseRecordModal,
    },
    created() {
        this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
    },
    mounted() {
        let url = new URL(window.location.href);
        this.ipvType = url.href.split("?")[1].split("=")[1].split("&")[0];
        this.zoneId = url.href.split("?")[1].split("=")[2];
        this.getIpv6ReverseRecords();
    },
    computed: {
        filteredRecords: function() {
            this.filteredIpv6Records = this.ipv6Records;

            if (this.searchParameter.length > 0) {
                this.filteredIpv6Records = this.filteredIpv6Records.filter((ipv6Record) => 
                ipv6Record.address.toLowerCase().includes(this.searchParameter.toLowerCase()) ||
                ipv6Record.forwardAddress.toLowerCase().includes(this.searchParameter.toLowerCase()) ||
                ipv6Record.name.toLowerCase().includes(this.searchParameter.toLowerCase())
                );
            };

            if (this.reverse) {
                return _.orderBy(this.filteredIpv6Records, this.sortKey, 'asc');
            } else {
                return _.orderBy(this.filteredIpv6Records, this.sortKey, 'desc');
            }
        }
    },
    methods: {
        getIpv6ReverseRecords: function() {
            const hostUrl = this.$config.aimsAPI;
            const zoneId = this.zoneId;
            const ipvType = this.ipvType.toLowerCase();
            this.$http.get(`${hostUrl}reverse/zones/${ipvType}/records/${zoneId}`).then(
                (response) => {
                    this.ipv6Records = response.data;
                    this.filteredIpv6Records = this.ipv6Records;
                    this.ipv6ZoneName = this.filteredIpv6Records[0] && this.filteredIpv6Records[0].publicipv6reversezone ? this.filteredIpv6Records[0].publicipv6reversezone.name: null;
                }
            )
        },
        addRecordToList: function(newRecord) {
            this.ipv6Records.push(newRecord);
            this.filteredIpv6Records = this.ipv6Records;
        },
        addReverseRecord: function() {
            this.modalTitle = `Add New Reverse Record[${this.ipvType}]`;
            this.$refs.addRecordModal.open();
        },
        editReverseRecord: function(data) {
            this.modalTitle = `Edit Reverse Record[${this.ipvType}]`;
            this.selectedRecord = this.deepCopyObject(data);
            this.$refs.modifyRecordModal.open();
        },
        async verifyRecord(data) {           
            const hostUrl = this.$config.aimsAPI;
            const nameserverList = ['dns1.adept.za.net', 'dns2.adept.za.net', 'dns3.adept.za.net'];

            await this.$http.post(`${hostUrl}reverse/zones/ipv6/verify/${data.publicIpv6ReverseRecordNumber}`, nameserverList).then(
                (response) => {
                    this.verifyData = response.data;

                    this.modalTitle = `${data.name}`
                    this.selectedRecord = this.deepCopyObject(data);
                    this.$refs.verifyReverseRecordModal.open();
                },
                (error) => {
                    console.error(error);
                    this.showError("Error verifying record", error);
                }
            );           
        },
        deleteReverseZone: function(data) {
            this.$swal({
                title: "Are you sure you want to delete this Record?",
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    const hostUrl = this.$config.aimsAPI;
                    const ipvType = this.ipvType.toLowerCase();
                    const objectKey = data.objKey;
                    this.$http
                        .delete(`${hostUrl}reverse/zones/${ipvType}/records/${objectKey}`)
                        .then(
                        () => {
                            this.showSuccess("Successfully deleted Record");
                            this.getIpv6ReverseRecords();
                        },
                        (error) => {
                            this.showError("Error deleting Record", error);
                            console.error(error);
                        }
                    );
                }
            });
        },
        goToZones: function() {
            this.$router.push('reversednszoneipv6');
        },
        sortBy: function(sortKey) {
            this.reverse = (this.sortKey == sortKey) ? ! this.reverse : false;
            this.sortKey = sortKey;
        },
    },
}
</script>

<style lang="scss" scoped>
    th:hover {
        cursor:pointer;
    }

    .table>thead>tr>th {
        padding: 1em;
        background-color: #3a3f51;
        color: #FFFFFF; 

        position: sticky;
        top: 0;
        z-index: 2;
    } 

    .pull-right {
        float: right;
    }
</style>