<template>
  <div>
    <div class="table-responsive table-bordered" v-if="allNetworks.length > 0">
      <table class="table">
        <thead>
          <tr>
            <th>Family</th>
            <th>Address</th>
            <th>Short Mask</th>
            <th v-allowed:view="['TECHNICAL_STAFF']"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="network in allNetworks" :key="network.address">
            <td>{{ network.family }}</td>
            <td>{{ network.address }}</td>
            <td>{{ network.shortMask }}</td>
            <td v-allowed:view="['TECHNICAL_STAFF']" style="text-align:center">
              <button
                class="btn btn-red text-light"
                type="button"
                @click="removeNetwork(network)"
              >
              <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="form-row row-spacing" v-allowed:view="['STAFF']">
      <div class="form-group col-auto">
        <button
          type="button"
          class="btn btn-success dropdown-toggle"
          data-toggle="dropdown"
        >
          Add IPv4
        </button>
        <div class="dropdown-menu">
          <a
            href="javascript:void(0)"
            @click="confirmAddRoute('IPv4', networkRangePackage.shortMask)"
            class="dropdown-item"
            v-for="networkRangePackage in networkRangePackages"
            :key="networkRangePackage.networkRangePackageNumber"
          >
            {{ networkRangePackage.description }}
          </a>
        </div>
      </div>
      <div class="form-group col-auto">
        <button type="button" class="btn btn-success dropdown-toggle" data-toggle="dropdown">
          Add IPv6
        </button>
        <div class="dropdown-menu">
          <a href="javascript:void(0)" @click="confirmAddRoute('IPv6', 48)" class="dropdown-item">/48</a>
          <a href="javascript:void(0)" @click="confirmAddRoute('IPv6', 56)" class="dropdown-item">/56</a>
          <a href="javascript:void(0)" @click="confirmAddRoute('IPv6', 64)" class="dropdown-item">/64</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ipv4Networks: {
      type: Array,
      required: true,
    },
    ipv6Networks: {
      type: Array,
      required: true,
    },
  },
  computed: {
    allNetworks() {
      let all = [];
      if (this.ipv4Networks) {
        all = all.concat(this.ipv4Networks);
      }
      if (this.ipv6Networks) {
        all = all.concat(this.ipv6Networks);
      }
      return all;
    },
  },
  data() {
    return {
      networkRangePackages: [],
    };
  },
  mounted() {
    this.$http
      .get(
        this.$config.aimsAPI +
          "sales/" +
          this.$route.params.saleNumber +
          "/networkrangepackages"
      )
      .then(
        (response) => {
          this.networkRangePackages = response.data;          
        },
        (error) => {
          this.showError("Could not fetch Network Range Packages", error);teAdded
          console.error(error);
        }
      );
  },
  methods: {
    confirmAddRoute(family, shortMask) {
      this.$swal({
        title: `Add ${family} /${shortMask} route?`,
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$emit("routeAdded", {
            family,
            shortMask,
          });
        }
      });
    },
    removeNetwork(network) {
      this.$swal({
        title: `Remove network ${network.address} /${network.shortMask}?`,
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$emit("routeRemoved", network);
        }
      });
    },
  },
};
</script>

<style>
</style>
