<template>
    <div class="mt-4">
        <div class="row row-spacing" v-allowed:view="['STAFF']">
           <div class="col-md-12">
                <button type="button" class="btn btn-success" @click="addAddon()">Add Add-ons</button>
           </div> 
        </div>
        <div class="row row-spacing">
            <div class="col-md-12">
                <div class="table-bordered table-responsive">
                    <table class="table">
                        <thead>
                            <tr class="dark-row">
                                <th>Service ID</th>
                                <th>Name</th>
                                <th>Type</th>
                                <th>MRC Excl VAT</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody v-if="cabinetAddons.length > 0">
                            <tr v-for="addon in cabinetAddons" :key="addon.cabinetAddonNumber">
                                <td>{{ addon.serviceID }}</td>
                                <td>{{ addon.name }}</td>
                                <td>{{ addon.type }}</td>
                                <td>{{ currencyFormat(addon.mrcExclVat) }}</td>
                                <td class="text-center" v-allowed:view="['TECHNICAL_STAFF']">
                                    <button type="button" class="btn btn-info" @click="editAddon(addon)"><i class="fa fa-edit"></i></button>
                                    <button type="button" class="btn btn-primary" @click="deleteAddon(addon)"><i class="fa fa-trash"></i></button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="4">No Add-ons added</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <ModifyCabinetAddonsModal 
            ref="addonModal"
            :title="title"
            :cabinetAddon="cabinetAddon"
            :cabinetAddonTypes="cabinetAddonTypes"
            @createCabinetAddon="createCabinetAddon"
            @updateCabinetAddon="updateCabinetAddon"
        />
    </div>
</template>

<script>
import ModifyCabinetAddonsModal from '@/components/Admin/Modals/ModifyCabinetAddonsModal';

    export default {
        components: {
            ModifyCabinetAddonsModal
        },
        props: {
            cabinetAddons: {
                type: Array,
                required: true,
            },
            newCabinetAddon: {
                type: Object,
                required: true,
            },
            cabinetAddonTypes: {
                type: Array,
                required: true,
            },
            cabinetRental: {
                type: Object,
                required: true,
            }
        },
        data() {
            return {
                title: "",
                cabinetAddon: {}
            }
        },
        methods: {
            addAddon: function() {
                this.title = "Add Addon";
                this.cabinetAddon = Object.assign({}, this.newCabinetAddon);
                this.cabinetAddon.saleNumber = this.cabinetRental.saleNumber;
                this.cabinetAddon.serviceID = this.cabinetRental.serviceID;
                this.$refs.addonModal.open();                
            },
            editAddon: function(addon) {
                this.title = "Edit Addon";
                this.cabinetAddon = Object.assign({}, addon);
                this.$refs.addonModal.open();
            },
            createCabinetAddon: function(addon) {
                const hostUrl = this.$config.aimsAPIv2;
                const saleNumber = addon.saleNumber;

                this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/create?commit=true`, addon).then(
                    (response) => {
                        this.$refs.addonModal.$refs.addonModal.isLoading();
                        this.showSuccess("Cabinet Addon added");
                        this.$emit("getSaleDefinition");
                        this.$refs.addonModal.$refs.addonModal.close();
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error adding Cabinet Addon", error);
                        his.$refs.addonModal.$refs.addonModal.isLoading();
                    }
                )
            },
            updateCabinetAddon: function(addon) {
                const hostUrl = this.$config.aimsAPI;
                const saleNumber = addon.saleNumber;

                this.$http.put(`${hostUrl}sales/definition/${saleNumber}/obj/update`, addon).then(
                    (response) => {
                        this.$refs.addonModal.$refs.addonModal.isLoading();
                        this.showSuccess("Save success");
                        this.$emit("getSaleDefinition");
                        this.$refs.addonModal.$refs.addonModal.close();
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error updating Cabinet Addon", error);
                        his.$refs.addonModal.$refs.addonModal.isLoading();
                    }
                );
            },
            deleteAddon: function(addon) {
                const hostUrl = this.$config.aimsAPI;
                const saleNumber = addon.saleNumber;

                this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/delete`, addon).then(
                    (response) => {
                        this.showSuccess("Cabinet Addon successfully removed");
                        this.$emit("getSaleDefinition");
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error Removing Cabinet Addon", error);
                    });
            } 
        }
    }
</script>

<style lang="scss" scoped>
.dark-row {
    width: 900px !important;
    background-color: #3a3f51;

    th {
        background-color: #3a3f51;
        color: #FFFFFF !important;

        position: sticky;
        top: 0;
        z-index: 2;
        font-size: .95em;
        min-width: calc(100px + 1em);
    }

}
</style>