<template>
  <div class="card card-default">
    <div class="card-header">
      <h3>Line Summary</h3>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div
            class="form-group row"
            v-if="(!isStaff && line.circuitNumber != 'tbd') || isStaff"
          >
            <label class="col-md-6 col-form-label">Circuit Number:</label>
            <div class="col-md-6">
              <input
                type="text"
                :readonly="!isStaff"
                class="form-control"
                v-model="line.circuitNumber"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-6 col-form-label">Line Status:</label>
            <div class="col-md-6">
              <input type="text" class="form-control" readonly v-model="line.lineOrder.status" />
            </div>
          </div>
          <div class="row row-spacing">
            <div class="col-md-12">
              <ConnectivityAddress
                v-allowed:view="['STAFF', 'CLIENT', 'RESELLER']"
                :address="line.lineAddress"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row row-spacing" v-allowed:view="['STAFF']">
        <div class="col-md-12">
          <button type="submit" class="btn btn-success" @click="saveSale">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectivityAddress from "@/components/ObjParts/SingleConstructed/ConnectivityAddress";

export default {
  components: {
    ConnectivityAddress,
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isStaff() {
      return this.userContainsPermission(["STAFF"]);
    },
  },
  watch: {
    line: {
      deep: true,
      handler(newLine) {
        this.$emit("update:line", newLine);
      },
    },
  },
  methods: {
    saveSale: function() {
      this.$emit("saveSale");
    }
  }
};
</script>
