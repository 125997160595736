<template>
  <div class="row">
    <div class="col-md-8">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template v-slot:rental>
            <form novalidate data-vv-scope="rental">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                    'has-error': errors.has('rental.type'),
                    }"
                  >
                    <label>Type:</label>
                    <v-select
                    name="type"
                    placeholder="Select Type"
                    v-validate="'required'"
                    :value="filledInSaleDefinition.requiredObjs.equipmentrental.rentalType"
                    :options="rentalTypeOptions"
                    @input="typeSelected"
                    >
                    </v-select>
                    <span class="has-error" v-if="errors.has('rental.type')">{{
                    errors.first("rental.type")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group" :class="{'has-error': errors.has('rental.terms'),}">
                    <label class="control-label">Terms:</label>
                    <v-select
                      name="terms"
                      placeholder="Select Terms"
                      v-validate="'required'"
                      :value="filledInSaleDefinition.requiredObjs.equipmentrental.rentalTerms"
                      :options="rentalTermOptions"
                      @input="termSelected"
                    ></v-select>
                    <span class="has-error" v-if="errors.has('rental.terms')">{{
                      errors.first("rental.terms")
                    }}</span>
                  </div>
                </div>
              </div>
            </form>
          </template>
          <template v-slot:billing>
            <form novalidate data-vv-scope="billing">
              <label class="control-label"><strong>Bill From</strong></label>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group" :class="{'has-error': errors.has('billing.bill'),}">
                    <v-select
                      name="bill"
                      :options="periods"
                      placeholder="Select Billing From"
                      v-validate="'required'"
                      v-model="filledInSaleDefinition.requiredObjs.equipmentrental.billFrom"
                      :get-option-label="getLabel"
                    ></v-select>
                    <span class="has-error" v-if="errors.has('billing.bill')">{{
                      errors.first("billing.bill")
                    }}</span>
                  </div>
                </div>
              </div>
              <hr/>
              <label class="control-label"><strong>Billing Details</strong></label>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group" :class="{'has-error': errors.has('billing.monthly'),}">
                    <label class="control-label">Monthly Instalment (excl. VAT):</label>
                    <input
                      type="text"
                      class="form-control"
                      name="monthly"
                      placeholder="Monthly Instalment"
                      v-validate="'required'"
                      v-model="filledInSaleDefinition.requiredObjs.equipmentrental.monthlyInstalmentExVat"
                    />
                    <span class="has-error" v-if="errors.has('billing.monthly')">{{
                      errors.first("billing.monthly")
                    }}</span>
                  </div>
                </div>
              </div>
            </form>
          </template>
          <template v-slot:equipment>
            <form novalidate data-vv-scope="equipment">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group" :class="{'has-error': errors.has('equipment.name'),}">
                    <label class="control-label">Name:</label>
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      placeholder="Name"
                      v-validate="'required'"
                      v-model="filledInSaleDefinition.requiredObjs.equipmentrental.equipmentName"
                    />
                    <span class="has-error" v-if="errors.has('equipment.name')">{{
                      errors.first("equipment.name")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group" :class="{'has-error': errors.has('equipment.model'),}">
                    <label class="control-label">Model:</label>
                    <input
                      type="text"
                      class="form-control"
                      name="model"
                      placeholder="Model"
                      v-validate="'required'"
                      v-model="filledInSaleDefinition.requiredObjs.equipmentrental.equipmentModel"
                    />
                    <span class="has-error" v-if="errors.has('equipment.model')">{{
                      errors.first("equipment.model")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group" :class="{'has-error': errors.has('equipment.serial'),}">
                    <label class="control-label">Serial:</label>
                    <input
                      type="text"
                      class="form-control"
                      name="serial"
                      placeholder="Serial Number"
                      v-validate="'required'"
                      v-model="filledInSaleDefinition.requiredObjs.equipmentrental.equipmentSerialNumber"
                    />
                    <span class="has-error" v-if="errors.has('equipment.serial')">{{
                      errors.first("equipment.serial")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group" :class="{'has-error': errors.has('equipment.link'),}">
                    <label class="control-label">Link:</label>
                    <input
                      type="text"
                      class="form-control"
                      name="link"
                      placeholder=""
                      v-model="filledInSaleDefinition.requiredObjs.equipmentrental.equipmentLink"
                    />
                    <span class="has-error" v-if="errors.has('equipment.link')">{{
                      errors.first("equipment.link")
                    }}</span>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";

export default {
  extends: SaleFunctions,
  components: {
    ServiceWizardLayout,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "rental",
          displayName: "Rental Package",
          icon: "fa fa-cubes",
        },
        {
          name: "billing",
          displayName: "Billing Information",
          icon: "fa fa-dollar",
        },
        {
          name: "equipment",
          displayName: "Equipment Details",
          icon: "fa fa-archive",
        },
      ],
      filledInSaleDefinition: {
        requiredObjs: {
          equipmentrental: {},
        },
        supportingEnums: {
          equipmentrental: {
            rentalType: {},
            rentalTerms: {}
          },
        },
      },
      periods: [],
      rentalTermOptions: [],
      rentalTypeOptions: [],
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;

    //Initializing the Option arrays for the dropdowns
    for (let i = 0; i < this.filledInSaleDefinition.supportingEnums.equipmentrental.rentalTerms.length; i++) {
      this.rentalTermOptions[i] = this.filledInSaleDefinition.supportingEnums.equipmentrental.rentalTerms[i].description;
    }
    for (let i = 0; i < this.filledInSaleDefinition.supportingEnums.equipmentrental.rentalType.length; i++) {
      this.rentalTypeOptions[i] = this.filledInSaleDefinition.supportingEnums.equipmentrental.rentalType[i].description;
    }

    //Emptying the variables that the dropdowns are linked to, to make the validation checks work properly
    this.filledInSaleDefinition.requiredObjs.equipmentrental.rentalType = "";
    this.filledInSaleDefinition.requiredObjs.equipmentrental.rentalTerms = "";
    this.filledInSaleDefinition.requiredObjs.equipmentrental.billFrom = "";
    this.filledInSaleDefinition.requiredObjs.equipmentrental.monthlyInstalmentExVat = "";

    this.$http
      .get(this.$config.aimsAPI + "system/billingperiods?max=" + 7)
      .then(
        (response) => {
          this.periods = response.data;
        },
        (error) => {
          this.showError("Error fetching billing periods", error);
          console.error(error);
        }
      );
  },
  methods: {
    getLabel(period) {
      return this.prettifyBillingPeriod(period);
    },
    onComplete() {
        this.$emit("createSale", this.filledInSaleDefinition);
    },
    typeSelected(value) {
        for (let i = 0; i < this.filledInSaleDefinition.supportingEnums.equipmentrental.rentalType.length; i++) {
            if (value == this.filledInSaleDefinition.supportingEnums.equipmentrental.rentalType[i].description) {
                this.filledInSaleDefinition.requiredObjs.equipmentrental.rentalType = value;
            }
        }
    },
    termSelected(value) {
        for (let i = 0; i < this.filledInSaleDefinition.supportingEnums.equipmentrental.rentalTerms.length; i++) {
            if (value == this.filledInSaleDefinition.supportingEnums.equipmentrental.rentalTerms[i].description) {
                this.filledInSaleDefinition.requiredObjs.equipmentrental.rentalTerms = value;
            }
        }
    },
    validateStep(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>