<template>
    <button
        class="btn btn-green table-to-file"
        :class="small ? 'btn-sm' : ''"
        type="button"
        @click="downloadExcel()"
        :disabled="propDisable"
    >
        {{text}}  <em class="fa fa-download"></em>
    </button>
</template>

<script>
import XLSX from "xlsx-js-style";

export default {
    components: {
        XLSX
    },
    props: {
        small: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            required: false
        },
        fileName: {
            type: String,
            default: "tableData"
        },
        fieldProp: {
            type: Object,
            required: true
        },
        dataProp: {
            type: Array,
            required: true
        },
        propDisable: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            formattedName: '',
            formattedHeaders: [],
            formattedData: [],
            formattedObject: {},

            devErr: false
        }
    },
    mounted() {
        
    },
    methods: {   
        downloadExcel() {
            this.correctData()
            this.styleHeaders()
            this.formattedName = this.fileName + '.xlsx';

            if (!this.devErr) {
                const book = XLSX.utils.book_new()
                const sheet = XLSX.utils.aoa_to_sheet([this.formattedHeaders]);
    
                XLSX.utils.book_append_sheet(book, sheet, this.fileName);
                XLSX.utils.sheet_add_json(sheet, this.formattedData, {skipHeader:true, origin: "A2"});
                XLSX.writeFile(book, this.formattedName)
            }

            this.resetObjs();
        },
        correctData() {
            for (let i = 0; i < this.dataProp.length; i++) {
                this.formattedObject = {};
                for (let j = 0; j < Object.keys(this.fieldProp).length; j++) {
                    let fieldExists = false;
                    for (let k = 0; k < Object.keys(this.dataProp[0]).length; k++) {
                        if (Object.keys(this.dataProp[i])[k].toString() == Object.values(this.fieldProp)[j]) {
                            fieldExists = true;
                            this.formattedObject[Object.keys(this.fieldProp)[j]] = Object.values(this.dataProp[i])[k];
                        }
                    }
                    if (!fieldExists) {
                        this.devError();
                    }
                }
                this.formattedData.push(this.formattedObject);
            }
        },
        styleHeaders() {
            for (let i = 0; i < Object.values(this.formattedObject).length; i++) {
                this.formattedHeaders.push({ v: Object.keys(this.formattedObject)[i].toString(), t: "s", s: { font: { bold: true } } });
            }
        },
        resetObjs() {
            this.formattedHeaders = [];
            this.formattedData = [];
            this.formattedObject = {};
        },
        devError() {
            this.devErr = true;
            this.$swal({
                title: "Error",
                text: "Something went wrong generating the Excel file.. BUG!",
                type: 'warning',
                confirmButtonText: 'Ok'
            })
        }
    }
}

</script>

<style scoped>

</style>
