var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"card card-default"},[_c('form-wizard',{attrs:{"title":"","subtitle":"","stepSize":"sm"},on:{"on-complete":_vm.onComplete}},[_c('tab-content',{attrs:{"title":"Liquid Layer 2 Details","before-change":() => _vm.validateStep('liquidlayer2')}},[_c('form',{attrs:{"novalidate":"","data-vv-scope":"liquidlayer2"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('liquidlayer2.circuitNumber'),
                  }},[_c('label',[_vm._v("Circuit Number *")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(
                      _vm.filledInSaleDefinition.requiredObjs.liquidLayer2Line
                        .circuitNumber
                    ),expression:"\n                      filledInSaleDefinition.requiredObjs.liquidLayer2Line\n                        .circuitNumber\n                    "}],staticClass:"form-control",attrs:{"type":"text","name":"circuitNumber","data-vv-as":"Circuit Number"},domProps:{"value":(
                      _vm.filledInSaleDefinition.requiredObjs.liquidLayer2Line
                        .circuitNumber
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filledInSaleDefinition.requiredObjs.liquidLayer2Line
                        , "circuitNumber", $event.target.value)}}}),(_vm.errors.has('liquidlayer2.circuitNumber'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("liquidlayer2.circuitNumber")))]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('liquidlayer2.speed'),
                  }},[_c('label',[_vm._v("Service Speed *")]),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"options":[50000000, 100000000, 150000000, 200000000],"name":"speed","data-vv-as":"Service Speed","get-option-label":_vm.getLabel,"placeholder":"Select Service Speed"},on:{"input":_vm.setServiceSpeed},model:{value:(_vm.serviceSpeed),callback:function ($$v) {_vm.serviceSpeed=$$v},expression:"serviceSpeed"}}),(_vm.errors.has('layer2link.speed'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("layer2link.speed")))]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('liquidlayer2.sideA'),
                  }},[_c('label',[_vm._v("A-Side *")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(
                      _vm.filledInSaleDefinition.requiredObjs.liquidLayer2Line
                        .sideA
                    ),expression:"\n                      filledInSaleDefinition.requiredObjs.liquidLayer2Line\n                        .sideA\n                    "}],staticClass:"form-control",attrs:{"type":"text","name":"sideA","data-vv-as":"A-Side"},domProps:{"value":(
                      _vm.filledInSaleDefinition.requiredObjs.liquidLayer2Line
                        .sideA
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filledInSaleDefinition.requiredObjs.liquidLayer2Line
                        , "sideA", $event.target.value)}}}),(_vm.errors.has('liquidlayer2.sideA'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("liquidlayer2.sideA")))]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('liquidlayer2.sideB'),
                  }},[_c('label',[_vm._v("B-side *")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(
                      _vm.filledInSaleDefinition.requiredObjs.liquidLayer2Line
                        .sideB
                    ),expression:"\n                      filledInSaleDefinition.requiredObjs.liquidLayer2Line\n                        .sideB\n                    "}],staticClass:"form-control",attrs:{"type":"text","name":"sideB","data-vv-as":"B-Side"},domProps:{"value":(
                      _vm.filledInSaleDefinition.requiredObjs.liquidLayer2Line
                        .sideB
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filledInSaleDefinition.requiredObjs.liquidLayer2Line
                        , "sideB", $event.target.value)}}}),(_vm.errors.has('liquidlayer2.sideB'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("liquidlayer2.sideB")))]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('liquidlayer2.vlanName'),
                  }},[_c('label',[_vm._v("VLAN Name *")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(
                      _vm.filledInSaleDefinition.requiredObjs.liquidLayer2Line
                        .vlanName
                    ),expression:"\n                      filledInSaleDefinition.requiredObjs.liquidLayer2Line\n                        .vlanName\n                    "}],staticClass:"form-control",attrs:{"type":"text","name":"vlanName","data-vv-as":"VLAN Name"},domProps:{"value":(
                      _vm.filledInSaleDefinition.requiredObjs.liquidLayer2Line
                        .vlanName
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filledInSaleDefinition.requiredObjs.liquidLayer2Line
                        , "vlanName", $event.target.value)}}}),(_vm.errors.has('liquidlayer2.vlanName'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("liquidlayer2.vlanName")))]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('liquidlayer2.vlanID'),
                  }},[_c('label',[_vm._v("VLAN ID *")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(
                      _vm.filledInSaleDefinition.requiredObjs.liquidLayer2Line
                        .vlanId
                    ),expression:"\n                      filledInSaleDefinition.requiredObjs.liquidLayer2Line\n                        .vlanId\n                    "}],staticClass:"form-control",attrs:{"type":"text","name":"vlanID","data-vv-as":"VLAN ID"},domProps:{"value":(
                      _vm.filledInSaleDefinition.requiredObjs.liquidLayer2Line
                        .vlanId
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filledInSaleDefinition.requiredObjs.liquidLayer2Line
                        , "vlanId", $event.target.value)}}}),(_vm.errors.has('liquidlayer2.vlanID'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("liquidlayer2.vlanID")))]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('liquidlayer2.cost'),
                  }},[_c('label',[_vm._v("Cost (ex VAT) *")]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._v("R")])]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(
                        _vm.filledInSaleDefinition.requiredObjs.liquidLayer2Line
                          .costExVat
                      ),expression:"\n                        filledInSaleDefinition.requiredObjs.liquidLayer2Line\n                          .costExVat\n                      "}],staticClass:"form-control",attrs:{"type":"number","name":"cost","data-vv-as":"Cost"},domProps:{"value":(
                        _vm.filledInSaleDefinition.requiredObjs.liquidLayer2Line
                          .costExVat
                      )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filledInSaleDefinition.requiredObjs.liquidLayer2Line
                          , "costExVat", $event.target.value)}}})]),(_vm.errors.has('liquidlayer2.cost'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("liquidlayer2.cost")))]):_vm._e()])])])])])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }