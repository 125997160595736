<template>
    <div>
        <div class="form-group">
            <div class="input-group with-focus">
                <input
                    :type="fieldType"
                    class="form-control border-right-0"
                    placeholder="Generate password"
                    v-model="password"
                    :disabled="!userCanEdit"
                    id="passwordInput"
                    name="Password" 
                />
                <div class="input-group-append">
                    <span
                    @click="copyText(password)"
                    class="input-group-text btn btn-info rounded text-white border-left-0"
                    >
                    <i class="fa fa-clipboard"></i>
                    </span>
                </div>
                <button
                    v-tooltip="'Generate Password'"
                    v-allowed:view="['ADMIN', 'CLIENT_ADMIN', 'TECHNICAL', 'STAFF']"
                    v-if="userCanEdit"
                    class="btn btn-outline-warning"
                    type="button"
                    @click="generatePassword"
                >
                    <i class="fa fa-refresh"></i>
                </button>
            </div>
        </div>
        <div class="form-check form-check-inline" v-if="showPasswordToggle === true">
            <input class="form-check-input" type="checkbox" @click="showPassword()">
            <label class="form-check-label">Show Password</label>
        </div>
    </div>
</template>

<script>
import Vue from "vue";

export default {
    props: {
        password: {
            type: String,
            required: false,
        },
        userCanEdit: {
            type: Boolean,
            required: true,
        },
        isClient: {
            type: Boolean,
            default: false,
        },
        showPasswordToggle: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            fieldType: '',
        }
    },
    watch: {
      password(newValue) {
        if (newValue) {
          this.$emit('changePassword', newValue);        
        }
      },
    },
    created() {
        Vue.config.silent = true;        
    },
    mounted() {        
        this.fieldType = 'password';
    },
    methods: {
        generatePassword: function () {
            this.$http.get(this.$config.aimsAPI + "password/generate/8").then(
                (response) => {
                    this.password = response.bodyText;
                },
                (error) => {
                    console.error(error);
                    this.showError("Error Generating Password", error);
                }
            );
        },
        showPassword: function() {                        
            this.fieldType = this.fieldType === 'password' ? 'text': 'password';
        }
    },
}
</script>
