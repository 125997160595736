<template>
  <div class="row">
    <div class="col-md-7">
      <div class="form-group row">
        <label class="col-md-4 col-form-label">Circuit Number: </label>
        <div class="col-md-7">
          <input
            type="text"
            class="form-control"
            v-model="liquidLayer2Line.circuitNumber"
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 col-form-label">Service Speed: </label>
        <div class="col-md-7">
          <v-select
            :options="[50000000, 100000000, 150000000, 200000000]"
            :get-option-label="getLabel"
            v-model="linespeed"
          ></v-select>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 col-form-label">A-Side: </label>
        <div class="col-md-7">
          <input
            type="text"
            class="form-control"
            v-model="liquidLayer2Line.sideA"
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 col-form-label">B-side: </label>
        <div class="col-md-7">
          <input
            type="text"
            class="form-control"
            v-model="liquidLayer2Line.sideB"
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 col-form-label">VLAN Name: </label>
        <div class="col-md-7">
          <input
            type="text"
            class="form-control"
            v-model="liquidLayer2Line.vlanName"
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 col-form-label">VLAN ID: </label>
        <div class="col-md-7">
          <input
            type="text"
            class="form-control"
            v-model="liquidLayer2Line.vlanId"
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 col-form-label">Cost (ex VAT): </label>
        <div class="col-md-7">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">R</span>
            </div>
            <input
              type="number"
              class="form-control"
              v-model="liquidLayer2Line.costExVat"
            />
          </div>
        </div>
      </div>
      <div class="row" v-allowed:view="['STAFF']">
        <div class="col-md-12">
          <div class="form-group">
            <button
              class="btn btn-success"
              @click="$emit('saveSale')"
              type="button"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    linespeed: {
      get() {
        return this.liquidLayer2Line.downloadSpeed;
      },
      set(speed) {
        this.liquidLayer2Line.downloadSpeed =
          this.liquidLayer2Line.uploadSpeed = speed;
      },
    },
  },
  props: {
    liquidLayer2Line: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getLabel(speed) {
      return this.speed_bitsToMbits(speed) + " Mbit/s";
    },
  },
};
</script>