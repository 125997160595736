<template>
  <div class="row">
    <div class="col-md-11">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template v-slot:l2tpAccount>
            <form novalidate data-vv-scope="l2tpAccount">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group">
                    <div
                      class="form-group"
                      :class="{
                        'has-error': errors.has('l2tpAccount.profile'),
                      }"
                    >
                      <label class="control-label">Profile:</label>
                      <input
                        type="text"
                        class="form-control"
                        v-validate="'required'"
                        name="profile"
                        placeholder="Profile"
                        v-model="filledInSaleDefinition.requiredObjs.l2tpAccount.profile"
                      />
                      <span class="has-error" v-if="errors.has('l2tpAccount.profile')">{{
                        errors.first("l2tpAccount.profile")
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group">
                    <div
                      class="form-group"
                      :class="{
                        'has-error': errors.has('l2tpAccount.username'),
                      }"
                    >
                      <label class="control-label">Username:</label>
                      <input
                        type="text"
                        class="form-control"
                        v-validate="'required|email'"
                        name="username"
                        placeholder="Username@domain.com"
                        v-model="filledInSaleDefinition.requiredObjs.l2tpAccount.username"
                      />
                      <span class="has-error" v-if="errors.has('l2tpAccount.username')">{{
                        errors.first("l2tpAccount.username")
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group">
                    <div
                      class="form-group"
                      :class="{
                        'has-error': errors.has('l2tpAccount.password'),
                      }"
                    >
                      <label class="control-label">Password:</label>
                      <input
                        type="text"
                        class="form-control"
                        v-validate="'required'"
                        name="password"
                        placeholder="Password"
                        v-model="filledInSaleDefinition.requiredObjs.l2tpAccount.password"
                      />
                      <span class="has-error" v-if="errors.has('l2tpAccount.password')">{{
                        errors.first("l2tpAccount.password")
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
          <template v-slot:mplsL2tpLink>
            <form novalidate data-vv-scope="mplsL2tpLink">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group">
                    <div
                      class="form-group"
                      :class="{
                        'has-error': errors.has('mplsL2tpLink.vpn'),
                      }"
                    >
                      <label class="control-label">MPLS VPN:</label>
                      <v-select
                        name="vpn"
                        placeholder="Select Vpn"
                        v-validate="'required'"
                        v-model="vpnSelected"
                        :options="vpnOptions"
                        @input="vpnChanged"
                      >
                      </v-select>
                      <span class="has-error" v-if="errors.has('mplsL2tpLink.vpn')">{{
                        errors.first("mplsL2tpLink.vpn")
                      }}</span>
                    </div>
                    <div 
                      class="form-group"
                      :class="{
                        'has-error': errors.has('mplsL2tpLink.family'),
                      }"
                    >
                      <label class="control-label">Network Family:</label>
                      <v-select
                        name="family"
                        placeholder="Select Network Family"
                        :options="filledInSaleDefinition.supportingEnums.p2pIpv4Network.family"
                        label="description"
                        v-model="filledInSaleDefinition.requiredObjs.p2pIpv4Network.family"
                        valueProp="name"
                      ></v-select>
                      <span class="has-error" v-if="errors.has('mplsL2tpLink.family')">{{
                        errors.first("mplsL2tpLink.family")
                      }}</span>
                    </div>
                    <div 
                      class="form-group"
                      :class="{
                        'has-error': errors.has('mplsL2tpLink.address'),
                      }"
                    >
                      <label class="control-label">IP Address:</label>
                      <input
                        name="address"
                        type="text"
                        class="form-control"
                        v-validate="'required'"
                        v-model="filledInSaleDefinition.requiredObjs.p2pIpv4Network.address"
                      />
                      <span class="has-error" v-if="errors.has('mplsL2tpLink.address')">{{
                          errors.first("mplsL2tpLink.address")
                        }}</span>
                    </div>
                    <div
                      class="form-group"
                      :class="{
                        'has-error': errors.has('mplsL2tpLink.range'),
                      }"
                    >
                      <label class="control-label">Network Range:</label>
                        <v-select
                          name="range"
                          :options="netmasksV4"
                          placeholder="Select Network Range"
                          v-validate="'required'"
                          :get-option-label="getLabel"
                          v-model="filledInSaleDefinition.requiredObjs.p2pIpv4Network.shortMask"
                        ></v-select>
                        <span class="has-error" v-if="errors.has('mplsL2tpLink.range')">{{
                          errors.first("mplsL2tpLink.range")
                        }}</span>
                      </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
          <template v-slot:axxess>
            <form novalidate data-vv-scope="axxess">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div 
                    class="form-group" 
                    :class="{'has-error': errors.has('axxess.Sim')}"
                  >
                    <label>SIM card Serial Number</label>
                    <v-select 
                      name="Sim" 
                      :options="availableAxxessSims" 
                      valueProp="guidServiceId" 
                      v-validate="'required'" 
                      class="mb-2" 
                      label="strSerialNumber" 
                      v-model="selectedPackage.simGuid" 
                      placeholder="Choose a SIM">
                    </v-select>
                    <span class="has-error" v-if="errors.has('axxess.Sim')">
                      {{errors.first('axxess.Sim')}}</span>
                  </div>
                  <div 
                    class="form-group" 
                    :class="{'has-error': errors.has('axxess.Package')}"
                  >
                    <label>Choose Package</label>
                    <v-select 
                      name="Package" 
                      :options="emptySaleDefinition.selectableOptions.bundle" 
                      valueProp="axxessLtePackageNumber" 
                      v-validate="'required'" 
                      class="mb-2" 
                      label="description" 
                      v-model="selectedPackage.packageNumber" 
                      placeholder="Select a Package">
                    </v-select>
                    <span class="has-error" v-if="errors.has('axxess.Package')">
                      {{errors.first('axxess.Package')}}</span>
                  </div>
                </div>
              </div>
            </form>
          </template>
          <template v-slot:rica>
            <form novalidate data-vv-scope="rica" :before-change="()=>validateStep('rica')">
                <div class="col-lg-8">
                  <div 
                    class="form-group" 
                    :class="{'has-error': errors.has('rica.firstNames')}"
                  >
                    <label>First Names</label>
                    <input 
                      name="firstNames" 
                      v-validate="'required'" 
                      class="form-control" 
                      v-model="filledInSaleDefinition.requiredObjs.rica.firstNames" 
                    />
                    <span class="has-error" v-if="errors.has('rica.firstNames')">
                      {{errors.first('rica.firstNames')}}</span>
                  </div>
                  <div 
                    class="form-group" 
                    :class="{'has-error': errors.has('rica.lastName')}"
                  >
                    <label>Last Name</label>
                    <input 
                      name="lastName" 
                      v-validate="'required'" 
                      class="form-control" 
                      v-model="filledInSaleDefinition.requiredObjs.rica.lastName" 
                    />
                    <span class="has-error" v-if="errors.has('rica.lastName')">
                      {{errors.first('rica.lastName')}}</span>
                  </div>
                  <div 
                    class="form-group" 
                    :class="{'has-error': errors.has('rica.email')}"
                  >
                    <label>Email</label>
                    <input 
                      name="email" 
                      v-validate="'required|email'" 
                      class="form-control" 
                      v-model="filledInSaleDefinition.requiredObjs.rica.emailAddress" 
                    />
                    <span class="has-error" v-if="errors.has('rica.email')">
                      {{errors.first('rica.email')}}</span>
                  </div>
                  <div 
                    class="form-group" 
                    :class="{'has-error': errors.has('rica.contactNumber')}"
                  >
                    <label>Contact Number</label>
                    <input 
                      name="contactNumber" 
                      minlength="10" 
                      maxlength="10" 
                      v-validate="'required'" 
                      type="tel" 
                      class="form-control" 
                      v-model="filledInSaleDefinition.requiredObjs.rica.contactNumber" 
                    />
                    <span class="has-error" v-if="errors.has('rica.contactNumber')">
                      {{errors.first('rica.contactNumber')}}</span>
                  </div>
                  <div 
                    class="form-group" 
                    :class="{'has-error': errors.has('rica.id')}"
                  >
                    <label>ID / Passport Number</label>
                    <input 
                      name="id" 
                      minlength="8" 
                      maxlength="13" 
                      v-validate="'required'" 
                      class="form-control" 
                      v-model="filledInSaleDefinition.requiredObjs.rica.idNumber" 
                    />
                    <span class="has-error" v-if="errors.has('rica.id')">
                    {{errors.first('rica.id')}}</span>
                  </div>
                  <hr/>
                  <div class="form-group" :class="{'has-error': errors.has('rica.address')}">
                    <Address @addressChanged="ricaAddressChanged" @addressValid="ricaAddressValidChanged" :addressStructure="filledInSaleDefinition.requiredObjs.ricaAddress" :addressTypes="filledInSaleDefinition.supportingEnums.ricaAddress.addressType" />
                  </div>
                </div>
              </form>
          </template>
          <template v-slot:delivery>
            <div class="form-group">
              <div class="row" :before-change="()=>validateStep('rica-registration')">
                <Address class="col-xl-8" @addressChanged="addressChanged" @addressValid="deliveryAddressValidChanged" :addressStructure="filledInSaleDefinition.requiredObjs.orderAddress" :addressTypes="filledInSaleDefinition.supportingEnums.orderAddress.addressType" />
              </div>
            </div>
          </template>
        </service-wizard-layout>
      </div>
    </div>
    <WizardCheckout 
      ref="wizardCheckoutModal"
      :description="selectedPackage.package.description"
      :prices="selectedPackage.package.axxessltepackageprices"
      @checkout="finalize"
    />
  </div>
</template>

<script>
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
import Address from '@/components/GeneralComponents/Address'
import WizardCheckout from '@/components/ObjParts/MultipleLoaded/WizardCheckout'

export default {
  components: {
    ServiceWizardLayout,
    Address,
    WizardCheckout,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "axxess",
          displayName: "Axxess Service Association",
        },
        {
          name: "l2tpAccount",
          displayName: "L2TP Account",
        },
        {
          name: "mplsL2tpLink",
          displayName: "MPLS L2TP Link",
        },
        {
          name: "rica",
          displayName: "Rica Details",
        },
        {
          name: "delivery",
          displayName: "Delivery Info",
        },
      ],
      filledInSaleDefinition: {
        requiredObjs: {
            l2tpAccount: {},
            mplsL2tpLink: {},
            p2pIpv4Network: {},
            rica: {},
            ricaAddress: {},
            order: {},
            orderAddress: {},
        },
        selectableOptions: {
            
        },
        supportingEnums: {
          ricaAddress: {},
          orderAddress: {},
          p2pIpv4Network: {},

        },
      },
      selectedPackage: {
          package: {
            description: "",
            axxessltepackageprices: []
          },
          packageNumber: null,
          simGuid: null,
          simSerial: null
        },
      availableAxxessSims: [],
      vpnOptions: [],
      vpnSelected: '',
      netmasksV4: [32, 30, 29, 28, 27, 26, 25],
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
    this.filledInSaleDefinition.requiredObjs.p2pIpv4Network.shortMask = 32;

      this.$http.get(this.$config.aimsAPI + 'axxess/lte/telkom/availablesims')
      .then(
        response => {
          this.availableAxxessSims = response.body;
        },
        error => {
          this.showError("Error fetching Axxess SIMS", error);
          console.error(error);
        });

    for (let i = 0; i < this.filledInSaleDefinition.selectableOptions.vpn.length; i++) {
      this.vpnOptions.push(this.filledInSaleDefinition.selectableOptions.vpn[i].vrfName);
    }
  },
  methods: {
    getLabel(mask) {
      return "/" + mask;
    },
    vpnChanged() {
      for (let i = 0; i < this.filledInSaleDefinition.selectableOptions.vpn.length; i++) {
        if (this.filledInSaleDefinition.selectableOptions.vpn[i].vrfName === this.vpnSelected) {
          this.filledInSaleDefinition.requiredObjs.mplsL2tpLink.mplsVpnNumber = this.filledInSaleDefinition.selectableOptions.vpn[i].mplsVpnNumber;
        }
      }
    },
    ricaAddressChanged(raddress) {
      this.filledInSaleDefinition.requiredObjs.ricaAddress = raddress;
    },
    addressChanged(address) {
      this.filledInSaleDefinition.requiredObjs.orderAddress = address;
    },
    ricaAddressValidChanged(isValid) {
      this.ricaAddressValid = isValid;
    },
    deliveryAddressValidChanged(isValid) {
    this.deliveryAddressValid = isValid;
    },
    getSerialFromGuid(guid) {
      let result = this.availableAxxessSims.filter(p => p.guidServiceId === guid).strSerialNumber;
      return result;
    },
    async onComplete() {
      await this.$http.get(this.$config.aimsAPI + 'axxess/lte/package/' + this.selectedPackage.packageNumber)
        .then(
          response => {
            this.selectedPackage.package = response.data;
          },
          error => {
            this.showError("Error fetching Axxess Package", error);
            console.error(error);
          });

      this.filledInSaleDefinition.requiredObjs.service.axxessLtePackageNumber = this.selectedPackage.packageNumber;
      this.filledInSaleDefinition.requiredObjs.service.axxessSimServiceId = this.selectedPackage.simGuid;
      this.filledInSaleDefinition.requiredObjs.service.axxessSimSerialNumber = this.getSerialFromGuid(this.selectedPackage.simGuid);

      this.$refs.wizardCheckoutModal.open();
    },
    finalize(){
      this.$emit('createSale', this.filledInSaleDefinition);
    },
    validateStep(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>
