<template>
  <div v-dom-portal>
    <div class="modal" data-backdrop="static" data-keyboard="false" ref="modal">
      <div class="modal-dialog" :class="size ? size : ''">
        <div class="modal-content">
          <div class="modal-header" v-if="headerNeeded">
            <h4 class="modal-title">
              {{title}}
              <p v-if="subtitle"><small>{{subtitle}}</small></p>
            </h4>
            <button type="button" class="close" @click="close()">&times;</button>
          </div>
          <div class="mt-4" v-else></div>
          <div class="modal-body">
            <slot></slot>
          </div>
          <div class="modal-footer" v-if="footerNeeded">
            <slot name="footer">
              <button type="button" v-if="genButtonNeeded" :class="btnStyle ? btnStyle : 'btn'" @click="$emit('clickHandler')">{{ genButtonText }}</button>
              <button type="button" v-if="deleteNeeded" class="btn btn-link" @click="$emit('delete')">Delete</button>
              <button class="btn btn-danger" v-if="dismissNeeded" data-dismiss="modal" @click="$emit('dismiss')">{{dismissText}}</button>
              <button class="btn btn-success" :disabled="disableSave || loading" v-if="saveNeeded" @click="save()">
                <loading size="20px" v-if="loading"></loading>
                 <span v-else>{{saveText}}</span>
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Circle } from "vue-loading-spinner";
  export default {
    components: {
      'loading': Circle
    },
    props: {
      title: {
        type: String,
        default: ""
      },
      subtitle: {
        type: String,
        required: false
      },
      size: {
        type: String,
        default: 'modal-md'
      },
      dismissText: {
        type: String,
        default: 'Cancel'
      },
      saveText: {
        type: String,
        default: 'Save'
      },
      headerNeeded: {
        type: Boolean,
        default: true
      },
      footerNeeded: {
        type: Boolean,
        default: true
      },
      saveNeeded: {
        type: Boolean,
        default: true
      },
      dismissNeeded: {
        type: Boolean,
        default: true
      },
      deleteNeeded: {
        type: Boolean,
        default: false
      },
      genButtonNeeded: {
        type: Boolean,
        default: false
      },
      genButtonText: {
        type: String,
        default: 'Generic Button'
      },
      btnStyle: {
        type: String,
        default: 'btn'
      },
      disableSave: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        loading: false
      }
    },
    methods: {
      open() {
        this.loading = false;
        $(this.$refs.modal).modal('show');
      },
      isLoading() {
        this.loading = !this.loading;
      },
      save() {
        this.$emit('save');
      },
      close() {
        this.$emit('close');
        $(this.$refs.modal).modal('hide');
      }
    }
  }

</script>
