<template>
  <div>
       <div class="card card-default">
            <div class="card-body">
                <div class="row row-spacing">
                    <div class="col-md-12">
                        <TaskHistory :days="15" :taskName="taskName" @tasksRunningChanged="tasksRunningChanged" ref="taskHistory"/>
                    </div>
                </div>
                <hr>
                <div v-if="!isRunningTasks">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Which types of clients would you like to process invoices?</label>
                            <select class="form-control" v-model="clientPaymentType">
                                <option value="__NONE__">ALL</option>
                                <option value="DEBIT">DEBIT</option>
                                <option value="INVOICE">INVOICE</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label>Which billing period?</label>
                            <select class="form-control" v-model="billingPeriod">
                                <option v-for="billingPeriod in billingPeriods" :key="billingPeriod">{{billingPeriod}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row row-spacing">
                        <div class="col-md-12">
                            <button class="btn btn-primary" @click="processInvoices()">Process Invoices</button>
                        </div>
                    </div>
                </div>
            </div>
       </div>
  </div>
</template>

<script>
import TaskHistory from './TaskHistory'

export default {
    components: {
        TaskHistory
    },
    data(){
        return {
            taskName: 'process-invoices',
            clientPaymentType: null,
            billingPeriod: null,
            isRunningTasks: false,
            billingPeriods: []
        }
    },
    methods: {
        processInvoices(){
            if(!this.clientPaymentType || this.clientPaymentType.length == 0){
                this.showWarning('Please select client payment type');
                return;
            }
            if(!this.billingPeriod){
                this.showWarning('Please select billing period');
                return;
            }
            var messageParam = 'ALL';
            if (this.clientPaymentType != '__NONE__') {
                messageParam = this.clientPaymentType;
            }
            this.$swal({
                title: 'Are you sure you want to process invoices for ' + messageParam + ' clients for period ' + this.billingPeriod + '?',
                type: 'warning',
                showCancelButton: true,
                cancelButtonColor: '',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.value) {
                    const params = [
                        {
                            name: 'period',
                            value: this.billingPeriod,
                            type: 'INT'
                        }
                    ];
                    if (this.clientPaymentType != '__NONE__') {
                        params.push({
                                name: 'clientPaymentType',
                                value: this.clientPaymentType,
                                type: 'STRING'});
                    }
                    this.$http.post(this.$config.aimsAPI + 'tasks/definitions/run?taskName=' + this.taskName, params).then(
                        () => {
                                this.showSuccess('Process Invoices Task Submitted');
                                this.$refs.taskHistory.getTaskHistory();
                            },
                        error => {
                            console.error(error);
                            this.showError("Error Running Process Invoices", error);
                        });
                }
            });
        },
        tasksRunningChanged(isTasksRunning){
            this.isRunningTasks = isTasksRunning;
        }
    },
    mounted(){
        this.$root.$emit('SET_PAGE_TITLE', 'Process Invoices');

        this.$http.get(this.$config.aimsAPI + 'system/billingperiods?max=2')
        .then(response => {
                this.billingPeriods = response.data;
            },
            error => {
                console.error(error);
                this.showError("Error Getting Billing Periods", error);
            });
    }
}
</script>

<style>

</style>