<template>
  <span>
    <button class="btn btn-primary" type="button" @click="openFileSelection()"><i class="fa fa-2x fa-upload"></i>
      <div>Upload</div>
    </button>

    <!-- File Selection -->
    <modal title="Documentation - Select Files" ref="fileSelection" @save="openFileDetails()" save-text="Next" :dismiss-needed="false" :disable-save="fileListLength == 0">
      <div class="form-group upload-container">
        <input type="file" id="file_upload" class="form-control" multiple placeholder="" @change="fileSelected">
      </div>
      <label>Files</label>
      <div v-if="showFiles">
        <div class="row form-group" v-for="file in formData.values()" :key="file.name">
          <div class="col-md-7">
            <label>{{file.name}}</label>
          </div>
          <div class="col-md-5 row">
            <div class="col">
              <label>{{sizeBytesToKb(file.size)}} KB</label>
            </div>
            <div class="col" style="justify-content:right">
              <strong class="removeLabel" @click="removeFile(file.name)">Remove</strong>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <!-- File Details -->
    <modal :title="`Documentation - File Details (` + (fileIndex+1) + `/` + fileListLength + `)`" ref="fileDetails" @save="nextDetail()" save-text="Next" :dismiss-needed="false" :disable-save="paperUpload.description.length == 0 || paperUpload.type.length == 0">
      <div class="form-group">
        <label>File</label>
        <div v-for="(file, index) in formData.values()" :key="file.name">
          <input class="form-control" disabled v-if="index == fileIndex" v-model="file.name" />
        </div>
      </div>
      <div class="form-group">
        <label>Type</label> &nbsp; <strong style="color: red">*</strong>
        <select class="form-control" v-model="paperUpload.type">
          <option v-for="type in paperTypes" :key="type.name" :value="type.name">{{type.description}}</option>
        </select>
      </div>
      <div class="form-group">
        <label>Description</label> &nbsp; <strong style="color: red">*</strong>
        <input class="form-control" v-model="paperUpload.description" />
      </div>
      <div v-if="Array.isArray(clientSales) && selectedSales.length > 0" class="form-group">
        <label class="d-block">Selected Sales</label>
        <span v-for="saleNumber in selectedSales" :key="saleNumber">
          <span class="badge badge-warning" style="margin: 0.05rem">{{sale(saleNumber, clientSales)}}</span>
        </span>
      </div>
      <div class="form-group" v-if="Array.isArray(clientSales) && clientSales.length > 0">
        <popper trigger="clickToToggle" :options="{ placement: 'top', modifiers: {offset: {offset: '0,10px'}}}">
          <div class="popper">
            <div class="card mb-0">
              <div class="card-header">
                <div class="row row-spacing">
                  <div class="col-md-5">
                    <input type="text" class="form-control" v-model="searchText" placeholder="Search Sale">
                  </div>
                  <div class="col-md-4">
                    <v-select :options="options" v-model="show"></v-select>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="selectAll" v-model="selectAll">
                    <label class="form-check-label" for="selectAll">{{selectAll ? 'Deselect' : 'Select'}}
                      All</label>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="table-wrapper-scroll-y my-custom-scrollbar">
                  <table class="table table-bordered table-hover mb-0" v-if="filteredSales.length > 0">
                    <thead>
                      <tr>
                        <th scope="col">Sale Number</th>
                        <th scope="col">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr :class="selectedSales.includes(sale.saleNumber) ? 'new-table-row' : ''"
                        v-for="sale in filteredSales" :key="sale.saleNumber" @click="selectSale(sale)">
                        <td scope="row">{{sale.saleNumber}}</td>
                        <td>{{sale.description}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-else>
                    No {{ show.includes('Unselected') ? 'Unselected Sales' : 'Selected Sales' }} Found
                  </div>
                </div>
              </div>
            </div>
            <span class="text-danger">*Click on table row to select/deselect sale</span>
          </div>

          <button class="btn btn-secondary" slot="reference">
            Click to view list of sales
          </button>
        </popper>
      </div>
    </modal>

    <!-- Finalization -->
    <modal title="Documentation - Finalization" ref="finalization" @save="uploadPaper()" save-text="Upload" :dismiss-needed="false" :disable-save="false">
      <div class="row form-group" v-for="(file, index) in formData.values()" :key="file.name" style="padding-top:1em; padding-bottom:1em">
        <div class="col-md-10 row">
          <div class="col-md-3">
            <label>File:</label>
          </div>
          <div class="col-md-9">
            <label>{{file.name}}</label>
          </div>
        </div>
        
        <div class="col-md-10 row" v-if="fileListDetails[index]">
          <div class="col-md-3">
            <label>Type:</label>
          </div>
          <div class="col-md-9">
            <label v-if="fileListDetails[index].type.length > 0">{{fileListDetails[index].type}}</label>
            <strong style="color: red" v-else>Missing Type</strong>
          </div>
        </div>

        <div class="col-md-10 row" v-if="fileListDetails[index]">
          <div class="col-md-3">
            <label>Description:</label>
          </div>
          <div class="col-md-9">
            <label v-if="fileListDetails[index].description.length > 0">{{fileListDetails[index].description}}</label>
            <strong style="color: red" v-else>Missing Description</strong>
          </div>
        </div>
        <div class="col-md-2" style="text-align:center">
          <strong class="removeLabel" @click="editFile(index)">Edit</strong>
        </div>
        
        <div class="col-md-10 row">
          <div class="col-md-3">
            <label>Size</label>
          </div>
          <div class="col-md-9">
              <label>{{sizeBytesToKb(file.size)}} KB</label>
          </div>
        </div>

        <div class="col-md-10 row" v-if="fileListDetails[index]">
          <div v-if="Array.isArray(clientSales) && fileListDetails[index].selectedSales.length > 0" class="col-md-12">
            <span v-for="saleNumber in fileListDetails[index].selectedSales" :key="saleNumber">
              <span class="badge badge-warning" style="margin: 0.05rem">{{sale(saleNumber, clientSales)}}</span>
            </span>
          </div>
        </div>
      </div>
    </modal>

    <!-- Final Edit -->
    <modal :title="`Documentation - Edit Details (` + (fileIndex+1) + `/` + fileListLength + `)`" ref="finalEdit" @save="goToFinal()" save-text="Confirm" :dismiss-needed="false" :disable-save="paperUpload.description.length == 0 || paperUpload.type.length == 0">
      <div class="form-group">
        <label>File</label>
        <div v-for="(file, index) in formData.values()" :key="file.name">
          <input class="form-control" disabled v-if="index == fileIndex" v-model="file.name" />
        </div>
      </div>
      <div class="form-group">
        <label>Type</label> &nbsp; <strong style="color: red">*</strong>
        <select class="form-control" v-model="paperUpload.type">
          <option v-for="type in paperTypes" :key="type.name" :value="type.name">{{type.description}}</option>
        </select>
      </div>
      <div class="form-group">
        <label>Description</label> &nbsp; <strong style="color: red">*</strong>
        <input class="form-control" v-model="paperUpload.description" />
      </div>
      <div v-if="Array.isArray(clientSales) && selectedSales.length > 0" class="form-group">
        <label class="d-block">Selected Sales</label>
        <span v-for="saleNumber in selectedSales" :key="saleNumber">
          <span class="badge badge-warning" style="margin: 0.05rem">{{sale(saleNumber, clientSales)}}</span>
        </span>
      </div>
      <div class="form-group" v-if="Array.isArray(clientSales) && clientSales.length > 0">
        <popper trigger="clickToToggle" :options="{ placement: 'top', modifiers: {offset: {offset: '0,10px'}}}">
          <div class="popper">
            <div class="card mb-0">
              <div class="card-header">
                <div class="row row-spacing">
                  <div class="col-md-5">
                    <input type="text" class="form-control" v-model="searchText" placeholder="Search Sale">
                  </div>
                  <div class="col-md-4">
                    <v-select :options="options" v-model="show"></v-select>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="selectAll" v-model="selectAll">
                    <label class="form-check-label" for="selectAll">{{selectAll ? 'Deselect' : 'Select'}}
                      All</label>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="table-wrapper-scroll-y my-custom-scrollbar">
                  <table class="table table-bordered table-hover mb-0" v-if="filteredSales.length > 0">
                    <thead>
                      <tr>
                        <th scope="col">Sale Number</th>
                        <th scope="col">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr :class="selectedSales.includes(sale.saleNumber) ? 'new-table-row' : ''"
                        v-for="sale in filteredSales" :key="sale.saleNumber" @click="selectSale(sale)">
                        <td scope="row">{{sale.saleNumber}}</td>
                        <td>{{sale.description}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-else>
                    No {{ show.includes('Unselected') ? 'Unselected Sales' : 'Selected Sales' }} Found
                  </div>
                </div>
              </div>
            </div>
            <span class="text-danger">*Click on table row to select/deselect sale</span>
          </div>

          <button class="btn btn-secondary" slot="reference">
            Click to view list of sales
          </button>
        </popper>
      </div>
    </modal>
  </span>
</template>

<script>
  import Modal from '@/components/GeneralComponents/Modal';
  import Popper from 'vue-popperjs';
  import 'vue-popperjs/dist/vue-popper.css';
  export default {
    props: {
      paperTypes: {
        type: Array,
        required: true
      },
      clientSales: {
        type: [Array, Object],
        required: true
      },
      clientNumber: {
        type: [Number, String],
        required: true
      }
    },
    components: {
      Modal,
      'popper': Popper
    },
    data() {
      return {
        paperUpload: {
          type: '',
          description: '',
          file: {}
        },
        sales: [],
        selectedSales: [],
        options: ['All Sales', 'Selected Sales', 'Unselected Sales'],
        show: 'All Sales',
        selectAll: false,
        searchText: '',
        
        formData: new FormData(),

        selectedFiles: [],
        showFiles: true,

        fileListLength: 0,
        fileListDetails: [],
        fileIndex: 0,
      }
    },
    computed: {
      filteredSales() {
        if (this.show == 'Selected Sales') {
          return this.sales.filter(sale => this.selectedSales.includes(sale.saleNumber));
        }

        if (this.show == 'Unselected Sales') {
          return this.sales.filter(sale => !this.selectedSales.includes(sale.saleNumber));
        }

        return this.sales.filter(sale => {
          return sale.description.toLowerCase().includes(this.searchText.toLowerCase()) ||
            sale.saleNumber.toString().includes(this.searchText)
        });
      },
      sale: () => (saleNumber, clientSales) => {
        const sale = clientSales.find(sale => sale.saleNumber == saleNumber);
        let description;
        if (sale.toString.length > 30) {
          description = sale.toString.substring(0, 30) + '...';
        } else {
          description = sale.toString
        }
        return '#' + sale.saleNumber + ' - ' + description;
      },
      allDetailsExist() {
        if (this.fileListDetails) {
          let temp = false;
          for (let i = 0; i < this.fileListLength; i++) {
            if (this.fileListDetails[i] && this.fileListDetails[i].type.length == 0) {
              temp = true;
            }
            if (this.fileListDetails[i] && this.fileListDetails[i].description.length == 0) {
              temp = true;
            }
          }
          return temp
        } else {
          return false
        }
      }
    },
    watch: {
      clientSales(sales) {
        if (Array.isArray(sales)) {
          this.sales = sales;
        } else {
          this.selectedSales.push(sales.saleNumber);
        }
      },
      selectAll(selectAll) {
        if (selectAll) {
          this.selectedSales = this.sales.map(sale => sale.saleNumber);
        } else {
          this.selectedSales = []
        }
      },
      selectedSales(sales) {
        if (sales.length == this.sales.length) {
          this.selectAll = true;
        } else if (sales.length == 0) {
          this.selectAll = false;
        }
      }
    },
    methods: {
      openFileSelection() {
        this.selectedSales = [];
        this.$refs.fileSelection.open();
      },
      openFileDetails() {
        for (let i = 0; i < this.fileListLength; i++) {
          if (!this.fileListDetails[i]) {
            this.fileListDetails.push({type: '', description: '', selectedSales: []});
          }
        }

        this.fileIndex = 0;
        this.getDetails();

        this.$refs.fileSelection.close();
        this.$refs.fileDetails.open();
      },
      nextDetail() {
        this.setDetails();
        this.fileIndex++;

        if (this.fileIndex == this.fileListLength) {
          this.openFinalization();
        } else {
          this.getDetails();

          this.$refs.fileDetails.close();
          this.$refs.fileDetails.open();
        }
      },
      goToFinal() {
        this.setDetails();
        this.fileIndex == this.fileListLength-1;

        this.$refs.finalEdit.close();
        this.$refs.finalization.open();
      },
      openFinalization() {
        this.$refs.fileDetails.close();
        this.$refs.finalization.open();
      },
      getDetails() {
        this.paperUpload.type = this.fileListDetails[this.fileIndex].type;
        this.paperUpload.description = this.fileListDetails[this.fileIndex].description;
        this.selectedSales = this.fileListDetails[this.fileIndex].selectedSales;
      },
      setDetails() {
        this.fileListDetails[this.fileIndex].type = this.paperUpload.type;
        this.fileListDetails[this.fileIndex].description = this.paperUpload.description;
        this.fileListDetails[this.fileIndex].selectedSales = this.selectedSales;
      },
      fileSelected(event) {
        this.showFiles = false;
        this.showFiles = true;

        this.selectedFiles = event.target.files;
        let largeFiles = [];

        for (let i = 0; i < this.selectedFiles.length; i++) {
          if (!this.formData.has(this.selectedFiles[i].name)) {
            if (this.selectedFiles[i].size / 1024 / 1024 < 15) {
              this.formData.append(this.selectedFiles[i].name, this.selectedFiles[i]);
              this.fileListLength++;
            } else {
              largeFiles.push(this.selectedFiles[i].name);
            }
          }
        }

        if (largeFiles.length > 0) {
          let temp = "";
          for (let i = 0; i < largeFiles.length; i++) {
            if (i == largeFiles.length - 1) {
              temp += largeFiles[i]
            } else if (i == largeFiles.length - 2) {
              temp += largeFiles[i] + " and "
            } else {
              temp += largeFiles[i] + ", "
            }
          }
          if (largeFiles.length > 1) {
            temp += ' were too large.'
          } else {
            temp += ' was too large.'
          }

          this.$swal({
            title: 'Maximum file size is 15MB',
            text: temp,
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        }
      },
      editFile(data) {
        this.fileIndex = data;
        this.getDetails();

        this.$refs.finalization.close();
        this.$refs.finalEdit.open();
      },
      removeFile(data) {
        this.formData.delete(data);
        this.fileListLength--;
        this.showFiles = false;
        this.showFiles = true;
      },
      customLabel({
        saleNumber,
        toString
      }) {
        return 'Sale# ' + saleNumber + ' - ' + toString;
      },
      resetUpload: function () {
        this.formData = new FormData();
        this.fileListDetails = [];
        this.fileListLength = 0;
        this.fileIndex = 0;
      },
      selectSale({
        saleNumber
      }) {
        const i = this.selectedSales.indexOf(saleNumber);
        if (i == -1) {
          this.selectedSales.push(saleNumber);
        } else {
          this.selectedSales.splice(i, 1);
        }
      },
      uploadPaper() {
        const formData = new FormData();
        let filesSelected = [];
        let fileData = [];

        for (var value of this.formData.values()) {
          filesSelected.push(value);
        }

        for (let i = 0; i < this.fileListLength; i++) {
          fileData.push({
            fileName: filesSelected[i].name, 
            type: this.fileListDetails[i].type, 
            description: this.fileListDetails[i].description, 
            saleNumbers: this.fileListDetails[i].selectedSales
          });
        }
        
        for (var value of filesSelected.values()) {
          formData.append('files', value);
        }
        formData.append('data', JSON.stringify(fileData));

        this.$refs.fileSelection.isLoading();
        this.$http.post(this.$config.aimsAPI + 'correspondence/' + this.clientNumber + '/upload/multiple',
          formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(response => {
            this.showSuccess("File Uploaded Successfully");
            this.resetUpload();

            for (let i = 0; i < response.data.length; i++) {
              response.data[i].isNew = true;
            }

            this.$emit('updateCorrespondence', response.data);
            this.$refs.finalization.close();
          },
          error => {
            this.resetUpload();
            console.error(error);
            this.showError('Error Uploading Correspondence', error);
          }).finally(() => {
            this.$refs.finalization.isLoading();
          });
      },
      sizeBytesToKb(value) {
        if (value && value > 0) {
          return (value / 1024).toFixed(2)
        } else {
          return 0;
        }
      }
    }
  }

</script>

<style>
.removeLabel {
  color: red;
}

.removeLabel:hover {
  cursor: pointer;
}

.upload-container {
  position: relative;
  width: 100%;
}

.upload-container input, 
.upload-container input:focus{
  border: 2px dashed #23B7E5;
  padding: 100px 0px 200px ;
  color: transparent;
  width: 100%;
  background: #f1f1f1;
  background-image: url("/img/components/drag.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.upload-container input:hover {
  background: #ddd;
  background-image: url("/img/components/drag.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.upload-container input::file-selector-button {
  display: none;
}
</style>
