<template>
  <modal
    :title="modalTitle"
    ref="addNewDnsZone"
    @save="saveDNSZone()"
  >
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label">Reverse Zone</label>
        <input
          type="text"
          class="form-control"
          v-model="newDnsZone.name"
          placeholder="Enter Reverse Zone..."
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label">Address Range</label>
        <input
          type="text"
          class="form-control"
          v-model="newDnsZone.forwardName"
          placeholder="Enter Zone Address Range..."
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label">Minimum TTL</label>
        <input
          type="number"
          class="form-control"
          v-model="newDnsZone.minimumTtl"
          placeholder="Enter Zone Minimum TTL..."
        />
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
export default {
  props: {
    modalTitle: {
      type: String,
      required: true,
    },
    ipvType: {
      type: String,
      required: true,
    },
  },
  components: {
    Modal,
  },
  data() {
      return {
    newDnsZone: {
        name: "",
        forwardName: "",
        minimumTtl: "",
      },
      }
  },
  methods: {
    open() {
        this.$refs.addNewDnsZone.open();
    },
    saveDNSZone() {
      const ipvType = this.ipvType.toLowerCase();
      const hostUrl = this.$config.aimsAPI;
      const zoneName = this.newDnsZone.name;
      const zoneForwardName = this.newDnsZone.forwardName;
      const minimumTtl = this.newDnsZone.minimumTtl;
      this.$http
        .post(`${hostUrl}reverse/zones/${ipvType}/${zoneName}/${zoneForwardName}/${minimumTtl}`)
        .then(
        (response) => {
            response.data.isNew = true;
            this.$refs.addNewDnsZone.close();
            this.showSuccess("Successfully Added New Zone");
            this.$emit("zoneAdded", response.data);
        },
        (error) => {
            this.showError("Error Saving Zone", error);
            console.error(error);
        })
    },
  },
};
</script>