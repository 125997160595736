var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"card card-default"},[_c('service-wizard-layout',{attrs:{"title":"","subtitle":"","parts":_vm.parts,"onComplete":_vm.onComplete,"validateStep":_vm.validateStep}},[_c('template',{slot:"service"},[_c('form',{attrs:{"novalidate":"","data-vv-scope":"service"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('service.name'),
                  }},[_c('label',[_vm._v("Description:")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.filledInSaleDefinition.requiredObjs.service.description),expression:"filledInSaleDefinition.requiredObjs.service.description"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.filledInSaleDefinition.requiredObjs.service.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filledInSaleDefinition.requiredObjs.service, "description", $event.target.value)}}}),(_vm.errors.has('service.name'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("service.name")))]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('service.text'),
                  }},[_c('label',[_vm._v("Details:")]),_c('textarea',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.filledInSaleDefinition.requiredObjs.service.text),expression:"filledInSaleDefinition.requiredObjs.service.text"}],staticClass:"form-control",attrs:{"type":"text","name":"text","rows":"4","cols":"50"},domProps:{"value":(_vm.filledInSaleDefinition.requiredObjs.service.text)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filledInSaleDefinition.requiredObjs.service, "text", $event.target.value)}}}),(_vm.errors.has('service.text'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("service.text")))]):_vm._e()])])])])]),_c('template',{slot:"network"},[_c('form',{attrs:{"novalidate":"","data-vv-scope":"network"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('network.name'),
                  }},[_c('label',[_vm._v("VLAN Name:")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.filledInSaleDefinition.requiredObjs.network.name),expression:"filledInSaleDefinition.requiredObjs.network.name"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.filledInSaleDefinition.requiredObjs.network.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filledInSaleDefinition.requiredObjs.network, "name", $event.target.value)}}}),(_vm.errors.has('network.name'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("network.name")))]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('network.vlan'),
                  }},[_c('label',[_vm._v("VLAN ID:")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.filledInSaleDefinition.requiredObjs.network.vlan),expression:"filledInSaleDefinition.requiredObjs.network.vlan"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.filledInSaleDefinition.requiredObjs.network.vlan)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filledInSaleDefinition.requiredObjs.network, "vlan", $event.target.value)}}}),(_vm.errors.has('network.vlan'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("network.vlan")))]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('network.name'),
                  }},[_c('label',[_vm._v("Bandwidth:")]),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"hostNumber","options":_vm.rateLimits,"label":"description","valueProp":"rateLimit","placeholder":"Choose bandwidth"},model:{value:(_vm.selectedRateLimit),callback:function ($$v) {_vm.selectedRateLimit=$$v},expression:"selectedRateLimit"}}),(_vm.errors.has('network.name'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("network.name")))]):_vm._e()],1)])])])])],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }