<template>
    <service-screen-layout :parts="parts">
        <template v-slot:domain>
            <div class="row row-spacing">
                <div class="col-md-12">
                    <SynaqDomain 
                        :saleDefinition="saleDefinition"
                        @saveSale="saveSale"
                    />
                </div>
            </div>
        </template>
        <template  v-slot:licences>
            <div class="row row-spacing">
                <div class="col-md-12">
                    <SynaqLicences 
                        :saleDefinition="saleDefinition"
                    />
                </div>
            </div>
        </template>
    </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";

import SynaqDomain from "@/components/ObjParts/SingleCaptured/SynaqDomain";
import SynaqLicences from "@/components/ObjParts/MultipleLoaded/SynaqLicences";
    export default {
        components: {
            ServiceScreenLayout,
            SynaqDomain,
            SynaqLicences
        },
        data() {
            return {
                parts: [
                    {
                        name: "domain",
                        icon: "fa fa-cloud",
                        displayName: "Domain",
                        permissions: ["*"],
                    },
                    {
                        name: "licences",
                        icon: "fa fa-id-card",
                        displayName: "Licences",
                        permissions: ["*"],
                    },
                ],
                saleNumber: null,
                saleDefinition: {
                    sale: {
                        synaq: {
                            licences: []
                        }
                    }
                },
            }
        },
        mounted() {
            this.saleNumber = this.$route.params.saleNumber;

            this.getSaleDefinition();
        },
        methods: {
            getSaleDefinition: function() {
                const hostUrl = this.$config.aimsAPIv2;
                const saleNumber = this.saleNumber;

                this.$http.get(`${hostUrl}sales/definition/sale/${saleNumber}`).then(
                    (response) => {
                        this.saleDefinition = response.data;
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error fetching Sale Definition", error);
                    }
                );
            },
            saveSale: function() {
                const hostUrl = this.$config.aimsAPI;
                const saleNumber = this.saleNumber;

                this.$http.put(`${hostUrl}sales/definition/${saleNumber}/update`, this.saleDefinition).then(
                    (response) => {
                        this.showSuccess("Save Success");
                        this.getSaleDefinition();
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error Saving SaleDefinition", error);
                    }
                    );
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
