<template>
    <div>
        <div class="contact-numbers-wrapper my-3">
            <h4>Contact Numbers</h4>
            <label v-allowed:view="['CLIENT']"><em>Should any of the below information found to be incorrect, please contact <a href="mailto:support@adept.co.za" target="_blank">support@adept.co.za</a> to have it corrected.</em></label>
            <hr />
            <div class="form-wrapper">
                <div class="form-row">
                    <div class="form-group col-md-6" :class="{ 'has-error': errors.has('telwork') }">
                        <label>Telephone (Work):</label>
                        <input 
                            type="text" 
                            class="form-control" 
                            :disabled="!isAuthorizedUser && !isStaffUser" 
                            v-model="clientDetail.telephonework"
                            name="telwork"
                            :class="{ 'has-error': errors.has('telwork') }"
                            v-validate="'numeric|min:10|max:14'"
                        >
                        <small class="has-error mt-1" v-if="errors.has('telwork')">{{ errors.first("telwork") }}</small>
                    </div>
                    <div class="form-group col-md-6" :class="{ 'has-error': errors.has('cell') }">
                        <label>Cellphone:</label>
                        <input 
                            type="text" 
                            class="form-control" 
                            :disabled="!isAuthorizedUser && !isStaffUser" 
                            v-model="clientDetail.telephonecell"
                            name="cell"
                            :class="{ 'has-error': errors.has('cell') }"
                            v-validate="'numeric|min:10|max:14'"
                        >
                        <small class="has-error mt-1" v-if="errors.has('cell')">{{ errors.first("cell") }}</small>
                    </div>
                </div>
                <div class="form-row" v-if="showHomeTelephoneNumber" :class="{ 'has-error': errors.has('telhome') }">
                    <div class="form-group col-md-6">
                        <label>Telephone (Home):</label>
                        <input 
                            type="text" 
                            class="form-control" 
                            :disabled="!isAuthorizedUser && !isStaffUser" 
                            v-model="clientDetail.telephonehome"
                            name="telhome"
                            :class="{ 'has-error': errors.has('telhome') }"
                            v-validate="'numeric|min:10|max:14'"
                        >
                        <small class="has-error mt-1" v-if="errors.has('telhome')">{{ errors.first("telhome") }}</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="address-details-wrapper my-3">
            <h4>Postal and/or Street address</h4>
            <hr />
            <div class="form-group" v-allowed:view="['ADMIN', 'ACCOUNTS_STAFF', 'STAFF']">
                <label>Use same address:</label>
                <input class="ml-2" type="checkbox" ref="linkAddress" :checked="isSameAddress && addressLinked" @click="duplicateAddress">
            </div>
            <div class="form-wrapper">
                <div class="form-row">
                    <div class="form-group col-md-6" :class="{ 'has-error': errors.has('Postal Address') }">
                        <label>Postal Address:</label>
                        <textarea 
                            class="form-control" 
                            rows="4" 
                            :disabled="!isAuthorizedUser && !isStaffUser" 
                            v-model="clientDetail.postaladdress"
                            name="Postal Address" 
                            :class="{ 'has-error': errors.has('Postal Address') }"
                            v-validate="'required'"
                        >
                        </textarea>
                        <small class="has-error mt-1" v-if="errors.has('Postal Address')">{{ errors.first("Postal Address") }}</small>
                    </div>
                    <div class="form-group col-md-6" :class="{ 'has-error': errors.has('Street Address') }">
                        <label>Street Address:</label>
                        <textarea 
                            class="form-control" 
                            v-if="isSameAddress && addressLinked" 
                            rows="4" 
                            disabled 
                            v-model="clientDetail.postaladdress"
                            name="Street Address" 
                            :class="{ 'has-error': errors.has('Street Address') }"
                            v-validate="'required'"
                        >
                        </textarea>
                        <textarea 
                            class="form-control" 
                            v-else 
                            rows="4" 
                            :disabled="!isAuthorizedUser && !isStaffUser" 
                            v-model="clientDetail.streetaddress"
                            name="Street Address" 
                            :class="{ 'has-error': errors.has('Street Address') }"
                            v-validate="'required'"
                        >
                        </textarea>
                        <small class="has-error mt-1" v-if="errors.has('Street Address')">{{ errors.first("Street Address") }}</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="save-button-wrapper" v-allowed:view="['ADMIN', 'ACCOUNTS_STAFF', 'ADMIN_STAFF', 'STAFF']">
            <button class="btn btn-info m-0" @click="updateClientDetails(clientDetail)">Save</button>
        </div> 
    </div>
</template>

<script>
    export default {
        props: {
            userType: {
                type: String,
                required: true,
            },
            isAuthorizedUser: {
                type: Boolean,
                required: true,
            },
            isStaffUser: {
                type: Boolean,
                required: true,
            },
            clientDetail: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                addressLinked: true
            }
        },
        computed: {
           showHomeTelephoneNumber() {
                return this.userType == 'Private';
           },
           isSameAddress() {                
                return this.clientDetail.streetaddress === this.clientDetail.postaladdress;
            },
        },
        methods: {
            duplicateAddress() {
                if (this.$refs.linkAddress.checked === true) {
                    this.addressLinked = true;
                    this.clientDetail.streetaddress = this.clientDetail.postaladdress;
                } else {
                    this.addressLinked = false;
                    this.$emit("reloadDetails");
                    this.clientDetail.streetaddress = this.clientDetail.streetaddress;
                }
            },
            updateClientDetails: function(clientDetail) {   
                this.$validator.validateAll().then((valid) => {
                    if (valid) {
                        this.$emit('updateClientDetails', clientDetail)
                    }
                });                
            }
        },
    }
</script>

<style scoped>

</style>