<template>
  <div>
    <div class="row row-spacing">
      <div class="col-md-12 mb-2">
        <button
          v-if="userEditRight"
          type="button"
          @click="
            topupPrice = deepCopyObject(emptyTopup);
            $refs.modifyPackageModal.open();
          "
          class="btn btn-info"
        >
          Add New Topup
        </button>
      </div>
    </div>
    <div class="table-responsive table-bordered" v-if="packages.length > 0">
      <table class="table table-bordered">
        <thead>
          <tr class="text-nowrap">
            <th>Description</th>
            <th>Label</th>
            <th>Size</th>
            <th>Current Price (excl. VAT)</th>
            <th>Price (incl. VAT)</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(topupPackage, rowIndex) in packages" :key="rowIndex">
            <td>
              {{ topupPackage.description }}
            </td>
            <td>
              {{ topupPackage.label }}
            </td>
            <td>{{ topupPackage.size }} GB</td>
            <td>
              {{ getPrice(topupPackage.connectivitytopupprices, period) }}
              <span v-if="userEditRight"
                style="cursor: pointer"
                v-tooltip="'Edit Row Price'"
                @click="openPriceModal(topupPackage)"
                class="pull-right"
              >
                <em class="fa fa-edit fa-fw text-green"></em>
              </span> 
            </td>
            <td>
              {{ getPrice(topupPackage.connectivitytopupprices, period, true) }}
            </td>
            <td class="text-center">
              <button
                v-if="userEditRight"
                v-tooltip="'Edit Topup Package'"
                @click="editTopupPackage(topupPackage)"
                type="button"
                class="btn btn-sm btn-info m-1"
              >
                <em class="fa fa-edit fa-fw"></em>
              </button>
              <button
                v-if="userEditRight"
                v-tooltip="'Delete Topup Package'"
                @click="deleteTopupPackage(topupPackage)"
                type="button"
                class="btn btn-sm btn-primary"
              >
                <em class="fa fa-trash fa-fw"></em>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="alert alert-info col-sm-12" v-else>
      No topup packages to display
    </div>
    <ModifyTopupPackageModal
      ref="modifyPackageModal"
      :topupPrice="topupPrice"
      @packageAdded="packageAdded"
      @packageEdited="$emit('getConnectivityTopups')"
    />
    <AddPriceModal
      ref="addPriceModal"
      :row-price="rowPrice"
      :new-price="newPrice"
      @addPrice="addPrice"
      @pricesAdded="$emit('getConnectivityTopups')"
    />
  </div>
</template>

<script>
import AddPriceModal from "@/components/Admin/Modals/AddPriceModal";
import ModifyTopupPackageModal from "@/components/Admin/Modals/ModifyTopupPackageModal";
import PriceFunctions from "@/components/Admin/PriceFunctions";

export default {
  extends: PriceFunctions,
  props: {
    userEditRight: {
      type: Boolean,
      required: true
    },
    packages: {
      type: Array,
      required: true,
    },
    productNumber: {
      type: Number,
      required: true,
    },
    emptyTopup: {
      type: Object,
      required: true,
    },
  },
  components: {
    AddPriceModal,
    ModifyTopupPackageModal,
  },
  data() {
    return {
      period: this.getCurrentPeriod(),
      newPrice: {},
      topupPrice: {},
      rowPrice: [],
    };
  },
  methods: {
    editTopupPackage(topupPackage) {
      this.topupPrice = this.deepCopyObject(topupPackage);
      this.$refs.modifyPackageModal.open();
    },
    packageAdded(topupPackage) {
      topupPackage.connectivitytopupprices = [];
      this.packages.push(topupPackage);
      this.openPriceModal(topupPackage);
    },
    setNewPrice() {
      this.newPrice = this.deepCopyObject(
        this.emptyTopup.connectivitytopupprice
      );
    },
    openPriceModal(topup) {
      this.setNewPrice();
      this.rowPrice = this.deepCopyObject(topup.connectivitytopupprices);
      this.newPrice.connectivityTopupNumber = topup.connectivityTopupNumber;
      this.$refs.addPriceModal.open();
    },
    addPrice(newPrice) {
      this.rowPrice.forEach((price) => {
        if (price.validUntil == 0) {
          if (newPrice.validFrom % 100 > 1) {
            price.validUntil = newPrice.validFrom - 1;
          } else {
            price.validUntil = newPrice.validFrom - 100 + 11;
          }
        }
      });
      this.rowPrice.push(newPrice);
      this.setNewPrice();
      this.newPrice.connectivityTopupNumber = newPrice.connectivityTopupNumber;
    },
    deleteTopupPackage(topupPrice) {
      this.$swal({
        title: "Are you sure you want to delete topup price package?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$http
            .delete(
              this.$config.aimsAPI +
                "pricing/pricepackages/" +
                topupPrice.objKey +
                "?objClassName=" +
                topupPrice.class
            )
            .then(
              () => {
                this.showSuccess("Successfully deleted topup price");
                this.$emit("getConnectivityTopups");
              },
              (error) => {
                this.showError("Error Deleting Topup Pricing Package", error);
              }
            );
        }
      });
    },
  },
};
</script>
