<template>
    <modal title="View Certificate" ref="editCertificateIssue" :size="'modal-lg'" :footerNeeded="false">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group" :class="{ 'has-error': errors.has('Certificate') }">
                            <label>Certificate</label>
                            <textarea 
                                class="form-control" 
                                v-model="selectedIssue.certificate" 
                                cols="15" 
                                rows="10"
                                name="Certificate"
                                :class="{ 'has-error': errors.has('Certificate') }"
                                v-validate="'required'"
                                disabled
                            >
                            </textarea>
                            <small class="has-error mt-1" v-if="errors.has('Certificate')">{{ errors.first("Certificate") }}</small>
                            <div class="input-group mt-2">
                                <button @click="copyText(selectedIssue.certificate)" type="button" class="btn btn-info"  v-tooltip="'Copy to clipboard'">
                                    <i class="fa fa-clipboard"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";

export default {
    props: {
        selectedIssue: {
            type: Object,
            required: true
        },
    },
    components : {
        Modal
    },
    methods: {
        open: function() {
            this.$refs.editCertificateIssue.open();
        },
    }
}
</script>
