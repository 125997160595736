<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Username</label>
              <div class="input-group">
                <input v-model="l2tpAccount.username" type="text" class="form-control" disabled="" />
                <div class="input-group-append">
                  <span class="input-group-text">@{{l2tpAccount.l2tprealm.name}}</span>
                </div>
                <div class="input-group-append">
                  <button @click="copyText(l2tpAccount.username + '@' + l2tpAccount.l2tprealm.name)" type="button"
                    class="btn btn-info"><i class="fa fa-clipboard"></i></button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" v-allowed:view="['STAFF']" v-if="l2tpAccount.password">
            <div class="form-group">
              <label class="control-label">Password</label>
              <PasswordInput :password="l2tpAccount.password" :userCanEdit="false" @changePassword="changePassword"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- IPV4 -->
    <div class="row">
      <div class="col-md-12">
        <div class="card card-default">
          <div class="card-header">
            <h4>Allocated Ipv4 Network</h4>
          </div>
          <div class="card-body">
            <div class="row row-spacing">
              <div class="col-md-12">
                <div class="table-responsive table-bordered">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Address</th>
                        <th>Metric</th>
                        <th>Range</th>
                        <th v-allowed:view="['STAFF']"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{l2tpAccount.allocatedIpv4Network.address}}</td>
                        <td>
                          <div class="input-group">
                            <input class="form-control" name="Metric" v-model="l2tpAccount.allocatedIpv4Network.metric"
                              v-validate="'between:0,255'" onkeypress="return !(event.charCode == 46)" type="text"
                              required />
                            <div class="input-group-append">
                              <button class="btn btn-success" @click="saveAllocatedIpv4Network()" type="button"><i
                                  class="fa fa-check"></i></button>
                            </div>
                          </div>
                        </td>
                        <td>
                          {{l2tpAccount.allocatedIpv4Network.shortMask}}
                        </td>
                        <td v-allowed:view="['STAFF']">
                          <button class="btn btn-default" type="button" @click="changeIpv4Range(29)"
                            v-if="l2tpAccount.allocatedIpv4Network.shortMask == 32">Change to /29</button>
                          <button class="btn btn-default" type="button" @click="changeIpv4Range(32)"
                            v-if="l2tpAccount.allocatedIpv4Network.shortMask == 29">Change to /32</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END IPV4 -->
    </div>
  </div>
</template>

<script>
  import PasswordInput from '@/components/GeneralComponents/InputControls/PasswordInput';

  export default {
    components: {
      PasswordInput
    },
    props: {
      l2tpAccount: {
        type: Object,
        required: true
      }
    },
    methods: {
      changePassword: function(data) {
        if (data) {
          this.l2tpAccount.password = data;
        }
      },
      l2tpAccountChanged() {
        this.$emit('l2tpAccountChanged', this.l2tpAccount);
      },
      changeIpv4Range(range) {
        this.$swal({
          title: 'Are you sure you want to change the IPv4 range to /' + range + '?',
          type: 'warning',
          text: 'The current IP range will be unassigned',
          showCancelButton: true,
          cancelButtonColor: '',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.value) {
            this.l2tpAccount.allocatedIpv4Network.shortMask = range;
            this.$emit('l2tpIpv4NetworkSaved', this.l2tpAccount.allocatedIpv4Network);
          }
        });
      },
      changeIpv6Range(range) {
        this.$swal({
          title: 'Are you sure you want to change the IPv6 range to /' + range + '?',
          type: 'warning',
          text: 'The current IP range will be unassigned',
          showCancelButton: true,
          cancelButtonColor: '',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.value) {
            this.l2tpAccount.allocatedIpv6Network.shortMask = range;
          }
        });
      }
    }
  }

</script>
