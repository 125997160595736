<template>
  <div>
    <service-screen-layout :parts="parts">
      <template v-slot:detail>
        <div class="row">
          <div class="col-md-7">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label">Link name: </label>
                  <input type="text" class="form-control col-md-6" v-model="saleDefinition.definition.layer2link.name">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label">VLAN name: </label>
                  <input type="text" class="form-control col-md-4"
                    v-model="saleDefinition.definition.layer2link.vlanName">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label">VLAN Number: </label>
                  <input type="text" class="form-control col-md-4"
                    v-model="saleDefinition.definition.layer2link.vlanNumber">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="control-label">Live Date: </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <div class="input-group">
                    <DateInput 
                        type="date" 
                        :clearable="false" 
                        placeholder="Select Live Date"
                        v-model="saleDefinition.definition.layer2link.liveDate"
                        style="width: 100%"
                    />
                    <div class="input-group-append">
                        <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
            <div class="row" v-allowed:view="['STAFF']">
              <div class="col-md-12">
                <div class="form-group">
                  <button class="btn btn-success" @click="saveSale()" type="button">Save</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="card card-default">
              <div class="card-header">
                <h4>{{saleDefinition.definition.layer2link.layer2linkPackage.description}}</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    Details:
                  </div>
                  <div class="col-md-8">
                    <strong>{{saleDefinition.definition.layer2link.layer2linkPackage.toString}}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- <template v-slot:cross-connects>
        <div class="row">
          <div class="col-md-12 mb-3">
            <button type="button" @click="addCrossConnect()" class="btn btn-info">
              Add CrossConnect
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8">
            <table class="table table-bordered">
              <thead>
                <th>CrossConnect Name</th>
                <th>SO Number</th>
                <th>Type</th>
                <th>Speed</th>
                <th>Switch Name</th>
                <th>Switch Port</th>
                <th>Actions</th>
              </thead>
              <tbody v-if="saleDefinition.definition.layer2link.crossconnects.length > 0">
                <tr v-for="(cc,index) in saleDefinition.definition.layer2link.crossconnects" :key="index">
                  <td>{{cc.crossConnectName}}</td>
                  <td>{{cc.soNumber}}</td>
                  <td>{{cc.crossConnectType}}</td>
                  <td>{{cc.speed}} Mbit</td>
                  <td>{{cc.switchName}}</td>
                  <td>{{cc.switchPort}}</td>
                  <td class="text-center">
                    <button v-tooltip="'Edit Cross Connect'" type="button" @click="editCrossConnect(cc)"
                      class="btn btn-sm btn-info">
                      <em class="fa fa-edit fa-fw"></em>
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="6">No Cross Connects</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template> -->
      <template v-slot:usage>
        <div class="row">
          <div class="col-md-12 mb-3">
            <button type="button" @click="addUsageEntry()" class="btn btn-info" v-allowed:view="['STAFF']">
              Add Usage Entry
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <table class="table table-bordered">
              <thead>
                <th>Period</th>
                <th>Usage</th>
              </thead>
              <tbody v-if="saleDefinition.definition.layer2link.usage.length > 0">
                <tr v-for="(usageEntry,index) in saleDefinition.definition.layer2link.usage" :key="index">
                  <td>{{usageEntry.period}}</td>
                  <td>{{usageEntry.usage}} Mbit</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="4">No Usage Entries</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
    </service-screen-layout>
    <!-- <modal title="Add Cross Connect" ref="addCrossConnectModal" @save="saveCrossConnect()">
      <form>
        <div class="row">
          <div class="form-group col-md-12">
            <label>Name</label>
            <input type="text" placeholder="Enter Name" v-model="newCrossConnect.crossConnectName" class="form-control">
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <label>SO Number</label>
            <input type="text" placeholder="Enter SO Number" v-model="newCrossConnect.soNumber" class="form-control">
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <label>Type</label>
            <v-select :options="crossConnectTypes" v-model="newCrossConnect.crossConnectType" placeholder="Select Type">
            </v-select>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <label>Speed</label>
            <div class="input-group">
              <input type="text" placeholder="Enter Speed" v-model="newCrossConnect.speed" class="form-control">
              <div class="input-group-append">
                <span class="input-group-text">Mbit/s</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <label>Switch Name</label>
            <input type="text" placeholder="Enter Switch Name" v-model="newCrossConnect.switchName"
              class="form-control">
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <label>Switch Port</label>
            <input type="text" placeholder="Enter Switch Number" v-model="newCrossConnect.switchPort"
              class="form-control">
          </div>
        </div>
      </form>
    </modal> -->
    <modal title="Add Usage Entry" ref="addUsageEntryModal" @save="saveUsageEntry()">
      <form class="form-row">
        <div class="form-group col-md-5">
          <label>Period</label>
          <input type="text" placeholder="Enter Period" v-model="newUsagePeriod.period" class="form-control">
        </div>
        <div class="form-group col-md-7">
          <label>Usage</label>
          <div class="input-group">
            <input type="text" placeholder="Enter Usage" v-model="newUsagePeriod.usage" class="form-control">
            <div class="input-group-append">
              <span class="input-group-text">MB</span>
            </div>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
  import ServiceScreenLayout from '@/layouts/ServiceScreenLayout'
  import SaleFunctions from '@/pages/Services/SaleFunctions'
  import DateInput from '@/components/GeneralComponents/InputControls/DateInput.vue'
  import Modal from '@/components/GeneralComponents/Modal'

  export default {
    extends: SaleFunctions,
    components: {
      ServiceScreenLayout,
      DateInput,
      Modal
    },
    watch: {
      saleDefinition: {
        deep: true,
        handler(newSaleDef, oldSaleDef) {
          if(oldSaleDef.sale.description != newSaleDef.sale.description) {
            this.$parent.$refs.serviceTitle.reloadSale();
          }
        }
      }
    },
    data() {
      return {
        parts: [
          {
            name: 'detail',
            icon: 'fa fa-info-circle',
            displayName: 'Link Detail',
            permissions: ['*']
          },
          {
            name: 'cross-connects',
            icon: 'fa fa-link',
            displayName: 'Cross Connects',
            permissions: ['*']
          },
          {
            name: 'usage',
            icon: 'fa fa-database',
            displayName: 'Usage',
            permissions: ['*']
          },
        ],
        saleDefinition: {
          definition: {
            layer2link: {
              layer2linkPackage: {},
              // crossconnects: [],
              usage: []
            }
          },
          sale: {}
        },
        // newCrossConnect: {},
        newUsagePeriod: {}
        // crossConnectTypes: ['FIBRE', 'COPPER']
      }
    },
    async mounted() {
      await this.loadSale();
    },
    methods: {
      loadSale() {
        const saleNumber = this.$route.params.saleNumber;
        this.getSaleDefinition(saleNumber).then(response => {
            this.saleDefinition = response.data;
          },
          error => {
            this.showError("Error fetching Sale Definition", error);
            console.error(error);
          })
      },
      // addCrossConnect() {
      //   this.newCrossConnect = {
      //     class: 'za.co.adept.aims.datacentre.CrossConnect',
      //     userReadOnly: false
      //   };
      //   this.$refs.addCrossConnectModal.open();
      // },
      addUsageEntry() {
        this.newUsagePeriod = {
          class: 'za.co.adept.aims.layer2link.Layer2LinkUsage',
          userReadOnly: false
        };
        this.$refs.addUsageEntryModal.open();
      },
      // editCrossConnect(cc) {
      //   this.newCrossConnect = this.deepCopyObject(cc);
      //   this.$refs.addCrossConnectModal.open();
      // },
      // saveCrossConnect() {
      //   this.$refs.addCrossConnectModal.isLoading();
      //   this.newCrossConnect.layer2LinkNumber = this.saleDefinition.definition.layer2link.layer2LinkNumber;
      //   this.$http.put(this.$config.aimsAPI + "sales/definition/" + this.saleDefinition.sale.saleNumber + "/obj/update",
      //       this.newCrossConnect)
      //     .then(response => {
      //         this.$refs.addCrossConnectModal.close();
      //         this.saveSale();
      //       },
      //       error => {
      //         this.showError("Error adding Cross Connect", error);
      //         console.error(error);
      //       }).finally(() => {
      //       this.$refs.addCrossConnectModal.isLoading();
      //     });
      // },
      saveUsageEntry() {
        this.$refs.addUsageEntryModal.isLoading();
        this.newUsagePeriod.layer2LinkNumber = this.saleDefinition.definition.layer2link.layer2LinkNumber;
        this.$http.put(this.$config.aimsAPI + "sales/definition/" + this.saleDefinition.sale.saleNumber + "/obj/update",
            this.newUsagePeriod)
          .then(response => {
              this.$refs.addUsageEntryModal.close();
              this.saveSale();
            },
            error => {
              this.showError("Error adding Usage Entry", error);
              console.error(error);
            }).finally(() => {
            this.$refs.addUsageEntryModal.isLoading();
          });
      },
      saveSale() {
        this.saleDefinition.definition.layer2link.liveDate = this.dateTimeFormat(this.saleDefinition
          .definition.layer2link.liveDate);
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$http.put(this.$config.aimsAPI + "sales/definition/" + this.saleDefinition.sale.saleNumber +
                "/update", this.saleDefinition)
              .then(
                response => {
                  this.showSuccess("Save Success");
                  this.loadSale();
                },
                error => {
                  console.error(error);
                  this.showError("Error Saving Layer2 Link sale", error);
                }
              );
          }
        });
      }
    }
  }

</script>
