<template>
    <modal :title="modalTitle" ref="editDomainContact" :size="'modal-xl'" @dismiss="dismiss" @close="close" @save="saveDomainContact(selectedDomainContact)">
        <div class="row">
            <div class="col-md-12">
                <div>
                    <div class="row">
                        <div class="form-group col-md-6" :class="{ 'has-error': errors.has('Firstname') }">
                            <label>First name</label>
                            <input 
                                type="text" 
                                class="form-control"
                                v-model="selectedDomainContact.firstNames"
                                name="Firstname"
                                :class="{ 'has-error': errors.has('Firstname') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('Firstname')">{{ errors.first("Firstname") }}</small>
                        </div>
                        <div class="form-group col-md-6" :class="{ 'has-error': errors.has('Lastname') }">
                            <label>Last name</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="selectedDomainContact.lastName"
                                name="Lastname"
                                :class="{ 'has-error': errors.has('Lastname') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('Lastname')">{{ errors.first("Lastname") }}</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6" :class="{ 'has-error': errors.has('Company Name') }">
                            <label>Company Name</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="selectedDomainContact.companyName"
                                name="Company Name"
                                :class="{ 'has-error': errors.has('Company Name') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('Company Name')">{{ errors.first("Company Name") }}</small>
                        </div>
                        <div class="form-group col-md-6" :class="{ 'has-error': errors.has('Email Address') }">
                            <label>Email Address</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="selectedDomainContact.emailAddress"
                                name="Email Address"
                                :class="{ 'has-error': errors.has('Email Address') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('Email Address')">{{ errors.first("Email Address") }}</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6" :class="{ 'has-error': errors.has('Telephone Number') }">
                            <label>Telephone Number</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="selectedDomainContact.contactPhoneNumber"
                                name="Telephone Number"
                                :class="{ 'has-error': errors.has('Telephone Number') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('Telephone Number')">{{ errors.first("Telephone Number") }}</small>
                        </div>
                        <div class="form-group col-md-6" :class="{ 'has-error': errors.has('Cellphone Number') }">
                            <label>Cellphone Number</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="selectedDomainContact.contactCellNumber"
                                name="Cellphone Number"
                                :class="{ 'has-error': errors.has('Cellphone Number') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('Cellphone Number')">{{ errors.first("Cellphone Number") }}</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label>Client Number</label>
                            <input type="text" class="form-control" v-model="selectedDomainContact.clientNumber" disabled>
                        </div>
                        <div class="form-group col-md-6" :class="{ 'has-error': errors.has('Cellphone Number') }">
                            <label>Available as Shared Contact</label>
                            <div class="input-group">
                                        <label class="switch">
                                            <input type="checkbox" class="form-control" v-model="selectedDomainContact.shared">
                                            <span class="slider round"></span>
                                        </label>
                                        <span><strong>{{ selectedDomainContact.shared === true ? ' Public' : ' Private' }}</strong></span>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <h4>Address Details</h4>
                    <div class="row">
                        <div class="form-group col-md-2" :class="{ 'has-error': errors.has('Street Number') }">
                            <label>Street Number</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="selectedDomainContact.domaincontactaddress.streetNumber"
                                name="Street Number"
                                :class="{ 'has-error': errors.has('Street Number') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('Street Number')">{{ errors.first("Street Number") }}</small>
                        </div>
                        <div class="form-group col-md-4" :class="{ 'has-error': errors.has('Street Name') }">
                            <label>Street Name</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="selectedDomainContact.domaincontactaddress.streetName"
                                name="Street Name"
                                :class="{ 'has-error': errors.has('Street Name') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('Street Name')">{{ errors.first("Street Name") }}</small>
                        </div>
                        <div class="form-group col-md-4" :class="{ 'has-error': errors.has('Suburb') }">
                            <label>Suburb</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="selectedDomainContact.domaincontactaddress.suburb"
                                name="Suburb"
                                :class="{ 'has-error': errors.has('Suburb') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('Suburb')">{{ errors.first("Suburb") }}</small>
                        </div>
                        <div class="form-group col-md-2" :class="{ 'has-error': errors.has('Postal Code') }">
                            <label>Postal Code</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="selectedDomainContact.domaincontactaddress.postalCode"
                                name="Postal Code"
                                :class="{ 'has-error': errors.has('Postal Code') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('Postal Code')">{{ errors.first("Postal Code") }}</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col" :class="{ 'has-error': errors.has('Address Type') }">
                            <label>Address Type</label>
                            <v-select
                                :placeholder="'Select an address type'"
                                v-model="selectedDomainContact.domaincontactaddress.addressType"
                                :options="addressTypes"
                                label="description"
                                valueProp="name"
                                name="Address Type"
                                :class="{ 'has-error': errors.has('Address Type') }"
                                class="mb-1"
                                v-validate="'required'"
                            >
                            </v-select>
                            <small class="has-error mt-1" v-if="errors.has('Address Type')">{{ errors.first("Address Type") }}</small>
                        </div>
                        <div class="form-group col" :class="{ 'has-error': errors.has('City') }">
                            <label>City</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="selectedDomainContact.domaincontactaddress.city"
                                name="City"
                                :class="{ 'has-error': errors.has('City') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('City')">{{ errors.first("City") }}</small>
                        </div>
                        <div class="form-group col-md-4" :class="{ 'has-error': errors.has('Province') }">
                            <label>Province</label>
                            <v-select
                                :placeholder="'Select a Province'"
                                v-model="selectedDomainContact.domaincontactaddress.province"
                                :get-option-label="getLabel"
                                :options="provinces"
                                valueProp="name"
                                name="Province"
                                :class="{ 'has-error': errors.has('Province') }"
                                class="mb-1"
                                v-validate="'required'"
                            >
                            </v-select>
                            <small class="has-error mt-1" v-if="errors.has('Province')">{{ errors.first("Province") }}</small>
                        </div>
                        <div class="form-group col-md-2" :class="{ 'has-error': errors.has('Country') }">
                            <label>Country</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="selectedDomainContact.domaincontactaddress.country"
                                name="Country"
                                :class="{ 'has-error': errors.has('Country') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('Country')">{{ errors.first("Country") }}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
import { Validator } from "vee-validate";

export default {
    components: {
        Modal,
    },
    props: {
        modalTitle: {
            type: String,
            required: true
        },
        allClients: {
            type: Array,
            required: true
        },
        selectedDomainContact: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            addressTypes: [
                {
                    name: 'BUSINESS',
                    description: 'Business'
                },
                {
                    name: 'RESIDENTIAL',
                    description: 'Residential'
                }
            ],
            provinces: [
                {
                    name: 'Western Cape',
                    description: 'Western Cape',
                },
                {
                    name: 'Gauteng',
                    description: 'Gauteng',
                },
                {
                    name: 'KwaZulu-Natal',
                    description: 'KwaZulu-Natal',
                },
                {
                    name: 'Eastern Cape',
                    description: 'Eastern Cape',
                },
                {
                    name: 'Mpumalanga',
                    description: 'Mpumalanga',
                },
                {
                    name: 'Limpopo',
                    description: 'Limpopo',
                },
                {
                    name: 'Northern Cape',
                    description: 'Northern Cape',
                },
                {
                    name: 'Free State',
                    description: 'Free State',
                },
                {
                    name: 'North West',
                    description: 'North West',
                },
            ]
        }
    },
    methods: {
        open: function() {           
            this.$refs.editDomainContact.open();
        },
        getLabel: function({ description }) {           
            return description;
        },
        close: function() {
            this.clearInputs();
        },
        dismiss: function() {
            this.clearInputs();
        },
        saveDomainContact: function(selectedDomainContact) {
            this.$validator.validateAll().then((valid) => {
                if (valid) {
                    this.$emit('editDomainContacts', selectedDomainContact);
                    this.$refs.editDomainContact.close();
                }
            });
        },
        clearInputs: function() {
             this.errors.clear();
        }
    },
}
</script>
