<template>
    <div class="col-md-12">
        <div class="card border-light">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-hover">
                        <thead>
                           <tr>
                            <th>Description</th>
                            <th>Charges Excl VAT USD</th>
                            <th>Credits Excl VAT USD</th>
                            <th>Nett Charges Excl VAT USD</th>
                            <th>Price Excl VAT</th>
                           </tr> 
                        </thead>
                        <tbody v-if="awsInvoiceEntries.length > 0">
                            <tr v-for="(entry, i) in awsInvoiceEntries" :key="i">
                                <td style="width: 23rem;">
                                    <strong>{{ entry.description }}</strong>
                                </td>
                                <td>
                                    {{ currencyFormatUSD(entry.chargesExclVatUsd) }}
                                </td>
                                <td>
                                    {{ currencyFormatUSD(entry.creditsExclVatUsd) }}
                                </td>
                                <td>
                                    {{ currencyFormatUSD(entry.nettChargesExclVatUsd) }}
                                </td>
                                <td>
                                    <strong>{{ currencyFormat(entry.priceExclVat) }}</strong>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else-if="isUploading">
                        <tr>
                            <td colspan="9">
                                <DataLoader 
                                    :loadingText="loadingText"
                                />
                            </td>
                        </tr>
                    </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="7">No entries captured</td>
                            </tr>
                        </tbody>
                        <tfoot class="sticky-footer">
                            <tr>
                                <td colspan="7" class="text-right">Total retail: <strong>{{ currencyFormat(totalRetail) }}</strong></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataLoader from '@/components/GeneralComponents/DataLoader.vue';

export default {
    components: {
        DataLoader
    },
    props: {
        awsInvoiceEntries: {
            type: Array,
            required: true
        },
        totalRetail: {
            type: Number,
            required: false
        },
        isCurrentBillingMonth: {
            type: Boolean,
            required: false
        },
        isEditable: {
            type: Boolean,
            required: false
        },
        isUploading: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            loadingText: 'Uploading Entries...',
        }
    },
}
</script>

<style lang="scss" scoped>
.sticky-footer > tr > td {
    padding: 1em;
    background-color: #3a3f51;
    color: #FFFFFF;
    position: sticky;
    bottom: 0;
    z-index: 2;
}
</style>