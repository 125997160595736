<template>
    <div class="col-md-12">
        <div class="row ro-spacing justify-content-between">
                <h3>Firewall Rules</h3>
            <div class="mb-2" v-allowed:view="['STAFF']">
                <button class="btn btn-info pull-right" @click="openAddFirewallRuleModal()">
                    Add Firewall Rule
                </button>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="table-responsive table-bordered">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Access</th>
                            <th>Protocol</th>
                            <th>Src Address</th>
                            <th>Src Mask</th>
                            <th>Dst Address</th>
                            <th>Dst Mask</th>
                            <th>Port/Group Name</th>
                            <th v-allowed:view="['STAFF']"></th>
                        </tr>
                    </thead>
                    <tbody v-if="firewallRules.length > 0">
                        <tr v-for="firewallRule in firewallRules"
                            :key="firewallRule.hostedFirewallRuleNumber"
                        >
                            <td>{{ firewallRule.access }}</td>
                            <td>{{ firewallRule.protocol }}</td>
                            <td>{{ firewallRule.srcAddress }}</td>
                            <td>{{ firewallRule.srcNetmask }}</td>
                            <td>{{ firewallRule.dstAddress }}</td>
                            <td>{{ firewallRule.dstNetmask }}</td>
                            <td>{{ firewallRule.port }}</td>
                            <td v-allowed:view="['STAFF']">
                                <div class="text-center">
                                    <button 
                                        class="btn btn-info"
                                        @click="openFirewallRuleModal(firewallRule)"
                                        v-tooltip="'Edit Firewall Rule'"
                                    >
                                        <i class="fa fa-pencil-square-o"></i>
                                    </button>
                                    <button 
                                        class="btn btn-primary"
                                        @click="deleteFirewallRule(firewallRule)"
                                        v-tooltip="'Delete Firewall Rule'"
                                    >
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="9">No Firewall Rules associated with this sale</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <AddFirewallRule 
            ref="addFirewallRulesModal"
            :clientHostedNetworks="clientHostedNetworks"
            :accessArray="accessArray"
            :protocolArray="protocolArray"
            @addFirewallRule="addFirewallRule"
        />
        <ModifyFirewallRule 
            ref="openFirewallRuleModal"
            :clientHostedNetworks="clientHostedNetworks"
            :accessArray="accessArray"
            :protocolArray="protocolArray"
            :firewall="selectedFirewallRule"
            @editFirewallRule="editFirewallRule"
        />
    </div>
</template>

<script>
import AddFirewallRule from '@/components/Admin/Modals/Legacy/AddFirewallRule';
import ModifyFirewallRule from '@/components/Admin/Modals/Legacy/ModifyFirewallRule';

export default {
    props: {
        firewallRules: {
            type: Array,
            required: true
        },
        clientHostedNetworks: {
            type: Array,
            required: true
        },
        accessArray: {
            type: Array,
            required: true
        },
        protocolArray: {
            type: Array,
            required: true
        },
    },
    components: {
        AddFirewallRule,
        ModifyFirewallRule
    },
    data() {
        return {
            selectedFirewallRule: {},
        }
    },
    methods: {
        openAddFirewallRuleModal: function() {
            this.$refs.addFirewallRulesModal.open();
        },
        openFirewallRuleModal: function(data) {
            this.$refs.openFirewallRuleModal.open();
            this.selectedFirewallRule = this.deepCopyObject(data);
        },
        addFirewallRule: function(data) {
            this.firewallRules.push(data);
            this.$emit('saveFirewallRule', data);
        },
        editFirewallRule: function(data) {
            this.$emit('saveFirewallRule', data);
        },
        deleteFirewallRule: function(firewallRule) {
            this.$emit('deleteSelectedFirewallRule', firewallRule);
        }
    },
}
</script>
