<template>
  <div class="card-body">
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item">
        <a href="#apn" class="nav-link active" role="tab" data-toggle="tab" id="apn-tab" aria-controls="apn"
          aria-selected="true">
          <i class="fa fa-info-wifi"></i>&nbsp;&nbsp;APN
        </a>
      </li>
    </ul>
    <form class="tab-content">
      <div class="tab-pane fade show active" id="apn" role="tabpanel" aria-labelledby="apn-tab">
        <div class="form-group row">
          <label class="col-md-2 col-form-label">Description: </label>
          <div class="col-md-4">
            <input type="text" class="form-control" readonly disabled v-model="saleDefinition.definition.apn.name">
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import SaleFunctions from '@/pages/Services/SaleFunctions'
 

  export default {
    extends: SaleFunctions,
    components: {
      
    },
    computed: {
     
    },
    watch: {
      saleDefinition: {
        deep: true,
        handler(newSaleDef, oldSaleDef) {
          if(oldSaleDef.sale.description != newSaleDef.sale.description) {
            this.$parent.$refs.serviceTitle.reloadSale();
          }
        }
      }
    },
    data() {
      return {
        saleDefinition: {
          definition: {
            apn: {
            }
          },
          sale: {},
        },
        saleStartPeriod: '',
        availableBundles: []
      }
    },
    async mounted() {
      this.saleStartPeriod = this.dateToPeriod(this.saleDefinition.sale.startDate);
      this.loadSale();
    },
    methods: {
      loadSale() {
        const saleNumber = this.$route.params.saleNumber;
        this.getSaleDefinition(saleNumber).then(response => {
            this.saleDefinition = response.data;
          },
          error => {
            this.showError("Error fetching Sale Definition", error);
            console.error(error);
          })
      },
      saveSale() {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$http.put(this.$config.aimsAPI + "sales/definition/" + this.saleDefinition.sale.saleNumber +
                "/update", this.saleDefinition)
              .then(
                response => {
                  this.showSuccess("Save Success");
                  this.saleDefinition = response.data;
                },
                error => {
                  console.error(error);
                  this.showError("Error Saving APN Sale", error);
                }
              );
          }
        });
      }
    }
  }

</script>
