<template>
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Site Name</label>
                        <input type="text" class="form-control" v-model="saleDefinition.sale.certificate.siteName" disabled />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">                        
                        <label>Type</label>
                        <input type="text" class="form-control" v-model="saleDefinition.sale.certificate.type" disabled />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>CSR</label>
                        <textarea class="form-control" v-model="saleDefinition.sale.certificate.csr" cols="15" rows="5" :disabled="!this.userContainsPermission(['STAFF'])"></textarea>
                        <div class="input-group mt-2">
                            <button @click.stop="copyText(saleDefinition.sale.certificate.csr)" type="button" class="btn btn-info"  v-tooltip="'Copy to clipboard'">
                                <i class="fa fa-clipboard"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Private Key</label>
                        <textarea class="form-control" v-model="saleDefinition.sale.certificate.privateKey" cols="15" rows="5" :disabled="!this.userContainsPermission(['STAFF'])"></textarea>
                        <div class="input-group mt-2">
                            <button @click.stop="copyText(saleDefinition.sale.certificate.privateKey)" type="button" class="btn btn-info"  v-tooltip="'Copy to clipboard'">
                                <i class="fa fa-clipboard"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <button class="btn btn-success" @click="saveSale(saleDefinition)" v-allowed:view="['STAFF']">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        saleDefinition: {
            type: Object,
            required: true
        }
    },
    methods: {
        saveSale: function(saleDefinition) {
            this.$emit("saveSale", saleDefinition);
        }
    },
}
</script>
