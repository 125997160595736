<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h4>Internet Access</h4>
      </div>
      <div class="col-md-6">
        <div class="row row-spacing">
          <div class="form-group col-md-8">
            <label>VLAN Name</label>
            <input class="form-control" v-model="internetAccess.vlanName" />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-8">
            <label>VLAN ID</label>
            <input class="form-control" v-model="internetAccess.vlanId" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <FttbConnectivityPackage
          :fttbConnectivityPackage="internetAccess.internetConnectivityPackage"
        />
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-12">
        <h4>Point to Point Network</h4>
        <div class="table-responsive table-bordered">
          <table class="table">
            <thead>
              <tr>
                <th>Address</th>
                <th>Short Mask</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ internetAccess.pointToPointIpv4Network.address }}</td>
                <td>{{ internetAccess.pointToPointIpv4Network.shortMask }}</td>
              </tr>
              <tr>
                <td>{{ internetAccess.pointToPointIpv6Network.address }}</td>
                <td>{{ internetAccess.pointToPointIpv6Network.shortMask }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <hr />
    <div
      class="row"
      v-if="internetAccess.routedIpv4Networks && internetAccess.routedIpv6Networks"
    >
      <div class="col-md-12">
        <h4>Routed Networks</h4>
        <FttbRoutedNetworks
          :ipv4Networks="internetAccess.routedIpv4Networks"
          :ipv6Networks="internetAccess.routedIpv6Networks"
          @routeAdded="routeAdded"
          @routeRemoved="routeRemoved"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FttbConnectivityPackage from "@/components/ObjParts/Selected/FttbConnectivityPackage";
import FttbRoutedNetworks from "@/components/ObjParts/MultipleCaptured/FttbRoutedNetworks";

export default {
  components: {
    FttbConnectivityPackage,
    FttbRoutedNetworks,
  },
  props: {
    internetAccess: {
      type: Object,
      required: true,
    },
  },
  methods: {
    routeAdded(route) {
      route.fttbInternetAccessNumber = this.internetAccess.fttbInternetAccessNumber;
      route.networkType = 'ROUTED';
      this.$emit("routeAdded", route);
    },
    routeRemoved(route) {
      this.$emit("routeRemoved", route);
    },
  },
};
</script>

<style>
</style>
