<template>
  <service-screen-layout :parts="parts">
    <template v-slot:details>
      <div class="row">
        <div class="col-md-7">
          <div class="card card-default">
            <div class="card-header">
              <h4>MPLS VPN Details</h4>
            </div>
            <metro-connect-mpls-details
              :metroConnectionMpls="
                saleDefinition.definition.metroConnectionMpls
              "
              @saveSale="saveSale"
            >
              <div slot="label">
                <input
                  type="text"
                  class="form-control"
                  v-model="saleDefinition.sale.label"
                />
              </div>
            </metro-connect-mpls-details>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:ipaddresses>
      <div class="row">
        <div class="col-md-7">
          <div class="card card-default">
            <div class="card-header">
              <h4>IPv4 Address</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-10">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Address</th>
                        <th>Mask</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            v-model="
                              saleDefinition.definition.metroConnectionMpls
                                .ipAddress
                            "
                          />
                        </td>
                        <td>
                          <select
                            class="form-control"
                            v-model="
                              saleDefinition.definition.metroConnectionMpls
                                .shortMask
                            "
                          >
                            <option
                              :key="mask"
                              v-for="mask in netmasksV4"
                              :value="mask"
                            >
                              /{{ mask }}
                            </option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row row-spacing" v-allowed:view="['STAFF']">
                <div class="col-md-12">
                  <div class="form-group">
                    <button
                      class="btn btn-success pull-right"
                      @click="saveSale()"
                      type="button"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </service-screen-layout>
</template>

<script>
import SaleFunctions from "@/pages/Services/SaleFunctions";
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import MetroConnectMplsDetails from "@/components/ObjParts/SingleCaptured/MetroConnectMplsDetails";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    MetroConnectMplsDetails,
  },
  data() {
    return {
      parts: [
        {
          name: "details",
          icon: "fa fa-info-circle",
          displayName: "Details",
          permissions: ["*"],
        },
        {
          name: "ipaddresses",
          icon: "fa fa-plus-square",
          displayName: "IP Addresses",
          permissions: ["STAFF"],
        },
      ],
      saleDefinition: {
        definition: {
          metroConnectionMpls: {},
        },
        sale: {},
      },
      netmasksV4: [32, 30, 29, 28, 27, 26, 25],
    };
  },
  async mounted() {
    const saleNumber = this.$route.params.saleNumber;
    await this.getSaleDefinition(saleNumber).then(
      (success) => {
        this.saleDefinition = success.data;
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching Sale Definition", error);
      }
    );
  },
  methods: {
    saveSale() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Sale Definition", error);
              }
            );
        }
      });
    },
  },
};
</script>