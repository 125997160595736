<template>
    <div class="row">
        <div class="col-md-12 mt-4">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Internet Access:</label>
                        <input type="text" class="form-control" v-model="internetAccess.name" disabled>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>IPv4 Address:</label>
                        <div>
                            <input type="text" class="form-control" v-model="ipv4Network.address">
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>IPv4 Range:</label>
                        <v-select
                            :options="netmasksV4"
                            label="mask"
                            v-model="ipv4Network.shortMask"
                            clearable
                        >
                        </v-select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>IPv6 Address:</label>
                        <div>
                            <input type="text" class="form-control" v-model="ipv6Network.address">
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>IPv6 Range:</label>
                        <v-select
                            :options="netmasksV6"
                            label="mask"
                            v-model="ipv6Network.shortMask"
                            clearable
                        >
                        </v-select>
                    </div>
                </div>
            </div>
            <div class="form-group row" v-allowed:view="['STAFF']">
                <div class="col-sm-12">
                    <button class="btn btn-success" type="button" @click="save(ipv4Network, ipv6Network)">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            internetAccess: {
                type: Object,
                required: true
            },
            saleDefinition: {
                type: Object,
                required: true
            },
            ipv4Network: {
                type: Object,
                required: true
            },
            ipv6Network: {
                type: Object,
                required: true
            },
            netmasksV4: {
                type: Array,
                required: true
            },
            netmasksV6: {
                type: Array,
                required: true
            },
        },
        computed: {
        },
        methods: {
            save: function(ipv4Network, ipv6Network) {                 
                if (ipv4Network.virtualInternetIpv4NetworkNumber === 0 && ipv4Network.shortMask > 0) {
                    this.$emit("createObj", this.ipv4Network);
                } else if (ipv6Network.virtualInternetIpv6NetworkNumber === 0 && ipv6Network.shortMask > 0) {                    
                    this.$emit("createObj", this.ipv6Network);
                } else {
                    this.$emit("saveSale");
                }
            },
        }
    }
</script>
