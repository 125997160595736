<template>
  <div>
    <div class="row row-spacing my-3">
      <div class="col-md-6">
        <button v-if="userEditRight" @click="addNewTopupPrice()" type="button" class="btn btn-info">Add New Topup Package</button>
      </div>
      <!-- filter -->
      <div class="col-md-6 text-right">
        <button class="btn bg-green" @click="showFilters">
          <i class="fa-solid fa-chevron-left" :class="!clicked ? 'fa-chevron-left' : 'fa-chevron-right'"></i> 
          Filter
        </button>
        <div class="filter-container" v-show="clicked">
          <div class="filter-wrapper">
            <div class="filter-body">
              <div class="row">
                  <div class="col-md-12">
                    <label class="form-check-label" for="exampleRadios1">
                      Show expired packages
                    </label>
                    <input class="form-check-input ml-2 mt-1" type="checkbox" name="exampleRadios" id="exampleRadios1" value="option1" @change="toggleShowExpiredPackages" :checked="isChecked">
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- filter -->
    </div>
    <div class="table-responsive table-bordered" v-if="packages.length > 0">
      <table class="table">
        <thead>
          <tr class="text-nowrap">
            <th>Description</th>
            <th>Size</th>
            <th>Current Price (excl. VAT)</th>
            <th>Price (incl. VAT)</th>
            <th>Expired</th>
            <th>Commands</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(topupPackage, rowIndex) in packages" :key="rowIndex">
            <td>
              {{topupPackage.description}}
            </td>
            <td>
              {{topupPackage.size}} GB
            </td>
            <td>
              {{getPrice(topupPackage.ltetopupprices, period)}}
              <span v-if="userEditRight" style="cursor: pointer" v-tooltip="'Edit Row Price'" @click="openPriceModal(topupPackage)"
                class="pull-right">
                <em class="fa fa-edit fa-fw text-green"></em>
              </span>
            </td>
            <td>{{getPrice(topupPackage.ltetopupprices, period, true)}}</td>
            <td>
              <span
                class="badge"
                :class="topupPackage.expired ? 'badge-warning' : 'badge-green'"
                >{{ topupPackage.expired }}
              </span>
            </td>
            <td class="text-center">
              <button v-if="userEditRight" v-tooltip="'Edit Row'" @click="openTopupModal(topupPackage)" type="button"
                class="btn btn-sm btn-info m-1">
                <em class="fa fa-edit fa-fw"></em>
              </button>
              <button v-if="userEditRight" v-tooltip="'Delete Row'" @click="deleteTopupPrice(topupPackage)" type="button"
                class="btn btn-sm btn-primary">
                <em class="fa fa-trash fa-fw"></em>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="alert alert-info col-sm-12" v-else>
      No topup packages to display
    </div>
    <AddPriceModal 
      ref="addPriceModal" 
      :row-price="rowPrice" 
      :new-price="newPrice" 
      @addPrice="addPrice"
      @pricesAdded="getLteTopups()" 
    />
    <LtePriceModal 
      ref="newTopupModal" 
      :pricePackage="newTopupPrice" 
      :availableLteProducts="availableTopups"
      :supplier="productSupplier"
      @save-price="saveNewTopupPrice"
      @update-price="updateTopupPrice" 
    />
  </div>
</template>

<script>
  import AddPriceModal from '@/components/Admin/Modals/AddPriceModal'
  import LtePriceModal from '@/components/Admin/Modals/LtePriceModal'
  import PriceFunctions from '@/components/Admin/PriceFunctions'

  export default {
    extends: PriceFunctions,
    props: {
      productNumber: {
        type: Number,
        required: true
      },
      productSupplier: {
        type: String,
        required: true
      },
      userEditRight: {
        type: Boolean,
        required: true
      },
    },
    components: {
      AddPriceModal,
      LtePriceModal
    },
    data() {
      return {
        period: this.getCurrentPeriod(),
        newPrice: {},
        newTopupPrice: {},
        rowPrice: [],
        availableTopups: [],
        isChecked: false,
        topuppackages: [],
        filteredPackages: [],
        clicked: false,
      }
    },
    mounted() {
      this.getLteTopups();
    },
    computed: {
      packages() {  
        if (this.isChecked) {        
          this.filteredPackages = this.topuppackages;
        } else {
          this.filteredPackages = this.topuppackages.filter(p => p.expired === false); 
        }     
        return this.filteredPackages;
      },
    },
    methods: {
      getLteTopups() {
        this.$http.get(this.$config.aimsAPI + 'pricing/ltetopups/' + this.productNumber)
          .then(response => {
              this.topuppackages = response.data;

              //now also get available topup ids
              this.$http.get(this.$config.aimsAPI + 'mweb/mtn/availabletopups')
                .then(response => {
                    this.availableTopups = response.data;
                  },
                  error => {
                    this.showError('Error fetching Available LTE Topups', error)
                    console.error(error);
                  });

            },
            error => {
              this.showError('Error fetching LTE Topups', error)
              console.error(error);
            });
      },
      addNewTopupPrice() {
        this.newTopupPrice = this.deepCopyObject({
          class: 'za.co.adept.aims.lte.LteTopup',
          productNumber: this.productNumber
        });
        this.$refs.newTopupModal.open();
      },
      openTopupModal(topupPackage) {
        
        this.newTopupPrice = this.deepCopyObject(topupPackage);
        this.$refs.newTopupModal.open();
      },
      openPriceModal(topupPackage) {
        this.rowPrice = this.deepCopyObject(topupPackage.ltetopupprices);
        this.newPrice = this.deepCopyObject({
          lteTopupNumber: topupPackage.lteTopupNumber,
          class: topupPackage.class + 'Price',
          validFrom: 0,
          validUntil: 0
        });
        this.$refs.addPriceModal.open();
      },
      addPrice(newPrice) {
        this.rowPrice.forEach(price => {
          if (price.validUntil == 0) {
            if (newPrice.validFrom % 100 > 1) {
              price.validUntil = newPrice.validFrom - 1;
            } else {
              price.validUntil = (newPrice.validFrom - 100) + 11;
            }
          }
        });
        this.rowPrice.push(newPrice);
        this.newPrice = this.deepCopyObject({
          lteTopupNumber: newPrice.lteTopupNumber,
          class: newPrice.class,
          validFrom: 0,
          validUntil: 0
        });
      },
      updateTopupPrice(pricePackage) {
        this.saveData(pricePackage).then(response => {
            this.showSuccess('Updated Row Successfully');
            this.getLteTopups();
          },
          error => {
            this.showError("Error Saving LTE Topup Package", error);
            console.error(error);
          })
      },
      saveNewTopupPrice(newPackage) {
        this.$http.post(this.$config.aimsAPI + 'pricing/pricepackages/save', newPackage)
          .then(response => {
            this.showSuccess('Updated Row Successfully');
            response.data.ltetopupprices = [];
            this.openPriceModal(response.data);
          }, error => {
            this.showError("Error Saving Pricing", error);
            console.error(error);
          })
      },
      deleteTopupPrice(pricePackage) {
        this.$swal({
          title: 'Are you sure you want to delete topup price package?',
          type: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.value) {
            this.$http.delete(this.$config.aimsAPI + 'pricing/pricepackages/' + pricePackage.objKey +
                '?objClassName=' + pricePackage.class)
              .then(response => {
                this.showSuccess('Successfully deleted topup price');
                this.getLteTopups();
              }, error => {
                this.showError("Error Deleting Topup Pricing Package", error);
              })
          }
        });
      },
      toggleShowExpiredPackages: function() {
        this.isChecked = !this.isChecked;
      },
      showFilters: function() {
       this.clicked = !this.clicked;
      }
    }
  }

</script>
<style scoped>
.filter-container {
  position: relative;
  z-index: 999;
}

.filter-container .filter-wrapper {
  position: absolute;
  z-index: 999;
  margin-top: .5em;
  /* background-color: #f5f7fa; */
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 2px;
  width: 350px;
  right: 90;
  top: -42;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.filter-container .filter-wrapper .filter-body {
  max-height: 200px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 10px;
  text-align: left;
}

th.table-font-size {
  font-size: .95rem;
}
th.table-header-width-1 {
  width: 10px;
}
th.table-header-width-2 {
  width: 20px;
}
th.table-header-width-3 {
  width: 50px;
}
th.table-header-width-4 {
  width: 60px;
}
th.table-header-width-5 {
  width: 95px;
}
th.table-header-width-6 {
  width: 200px;
}

.font-size {
  font-size: .9rem;
}
</style>