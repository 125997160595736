import { defineStore } from "pinia"

export const WorkOrdersState = defineStore("WorkOrdersState", {
    state: () => ({
        selectedType: "",
        date: new Date().getFullYear() + "-01-01",
        status: "",
        searchTerm: null
    }),
    actions: {
    }
});