<template>
<div>
    <div class="row">
        <div class="col-md-4">
            <p style="color: green"><i class="fa fa-info-circle"></i> Notifications will be sent at <b>50%</b>, <b>80%</b> and <b>100%</b> of data consumption</p>
        </div>
    </div>
    <div class="row row-spacing" v-allowed:view="['STAFF']">
        <div class="col-md-offset-2 col-md-2">
            <input class="form-control" :disabled="usageRecipients.length == 2" minlength="10" maxlength="10" v-model="smsRecipientNumber" placeholder="Cell Phone Number"/>
        </div>
        <div class="col-md-2">
            <button :disabled="usageRecipients.length == 2" class="btn btn-success" type="button" @click="addRecipient()">Add</button>
        </div>
    </div>
    <div class="row row-spacing">
        <div class="col-md-4">
            <span class="badge badge-warning" v-if="usageRecipients.length == 2"><i class="fa fa-info-circle"></i> Limited to 2 recipients</span>
        </div>
    </div>
    <div class="row row-spacing">
        <div class="col-md-4">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Notification Recipient</th>
                        <th v-allowed:view="['STAFF']"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(recipient,index) in usageRecipients" :key="index" :class="{'new-table-row': recipient.isNew}">
                        <td><span class="badge bg-green" style="font-size:16px">{{recipient.recipient}}</span></td>
                        <td style="text-align:center" v-allowed:view="['STAFF']">
                            <button type="button" class="btn btn-danger" @click="removeRecipient(recipient)">Remove</button>
                            <button type="button" class="btn btn-default" @click="sendTest(recipient)">Send Test Message</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>  
</div>

</template>

<script>

export default {
    components: {
        
    },
    props: {
        usageRecipients: {
            type: Array,
            required: true
        }
    },
    data: function() {
        return {
            smsRecipientNumber: ""
        }
    },
    mounted: function() {

    },
    methods: {
        addRecipient(){
            if(this.smsRecipientNumber.length == 10){
                this.$emit('recipientAdded', this.smsRecipientNumber);
                this.smsRecipientNumber = '';
            }
        },
        removeRecipient(recipient){
            this.$emit('recipientRemoved', recipient);
        },
        sendTest(recipient){
            this.$http.put(this.$config.aimsAPI + 'notifications/test?recipient=' + recipient.recipient)
                .then(response => {
                    this.showSuccess(response.data.message);
                }, error => {
                    console.error(error);
                    this.showError("Error Sending Test Message", error);
                });
        }
    }
}

</script>
