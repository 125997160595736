<template>
    <div class="card widget-border">
        <div class="card-header text-white py-3">
            <div class="d-flex justify-content-between align-items-center">
                <h4 class="m-0">{{ heading }}</h4>
                <div class="">
                    <i :class="icon"></i>
                </div>
            </div>
        </div>
        <div class="card-body">
            <slot></slot>
        </div>
        <div v-if="showFooter" :class="!showFooterButtons ? 'addpadding': ''" class="card-footer">
            <div v-if="showFooterButtons" class="d-flex justify-content-end">
                <button class="btn btn-info" @click="$emit('infoButtonEvent')">{{ infoButtonText }}</button>
                <button v-if="showFooterButtons && showPrimaryButton" class="btn btn-primary" @click="$emit('primaryButtonEvent')">{{ primaryButtonText }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            heading: {
                type: String,
                required: true,
            },
            icon: {
                type: String,
                required: true
            },
            showFooterButtons: {
                type: Boolean,
                required: true
            },
            showPrimaryButton: {
                type: Boolean,
                required: false
            },
            showFooter: {
                type: Boolean,
                required: false
            },
            infoButtonText: {
                type: String,
                required: false
            },
            primaryButtonText: {
                type: String,
                required: false
            } 
        },
    }
</script>

<style scoped>
    .card-header {
        background-color: #3a3f51;
    }
    .card-footer {
        background-color: #ffffff;
    }
    .widget-border {
        border: 1px solid #3a3f51;
    }
    .addpadding {
        padding: 55px;
    }
</style>