<template>
    <modal
        ref="lineOrderModal"
        :title="modalTitle"
        size="modal-lg"
        save-text="Update"
        dismiss-text="Close"
        @save="updateOrder(selectedLineOrder)"
    >
        <div class="form-group" :class="{ 'has-error': errors.has('Ticket Number') }">
            <label>Ticket Number</label>
            <input 
                class="form-control" 
                type="text" 
                name="Ticket Number"
                v-model="selectedLineOrder.orderReference"
                :class="{ 'has-error': errors.has('Ticket Number') }"                       
                v-validate="'required'"
            />
            <small class="has-error mt-1" v-if="errors.has('Ticket Number')">{{ errors.first("Ticket Number") }}</small>
        </div>
        <div class="form-group">
            <label>Status</label>
            <select class="form-control" v-model="selectedLineOrder.status">
                <option>New</option>
                <option>Ordered</option>
                <option>Delivered</option>
                <option>Cancelled</option>
            </select>
        </div>
        <div class="form-group">
            <label class="control-label d-block my-3">
                Provisional Delivery Date:
            </label>
            <div class="input-group">
                <DateInput
                    type="datetime"
                    format="YYYY-MM-DD HH:mm:ss"
                    v-model="selectedLineOrder.provisionalDeliveryDate"
                    confirm
                    name="provisionalDeliveryDate"
                />
                <div class="input-group-append">
                    <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label d-block my-3">
                Actual Delivery Date:
            </label>
            <div class="input-group">
                <DateInput
                    type="datetime"
                    format="YYYY-MM-DD HH:mm:ss"
                    v-model="selectedLineOrder.deliveredDate"
                    confirm
                    name="actualDeliveryDate"
                />
                <div class="input-group-append">
                    <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label>Comments</label>
            <div v-if="selectedLineOrder.commentHistory">
                <p v-for="comment in selectedLineOrder.commentHistory" :key="comment.commentDate">
                    <b>{{ comment.comment }}</b>
                    <small>
                        ({{ comment.commentMadeBy }} - {{ comment.commentDate }})
                    </small>
                </p>
                <textarea class="form-control" v-model="selectedLineOrder.newComment"></textarea>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
import DateInput from "@/components/GeneralComponents/InputControls/DateInput";

export default {
    components: {
        Modal,
        DateInput
    },
    props: {
        modalTitle: {
            type: String,
            required: true
        },
        selectedLineOrder: {
            type: Object,
            required: true
        }
    },
    methods: {
        open: function() {
            this.$refs.lineOrderModal.open();
        },
        updateOrder: function(selectedLineOrder) {
            this.$validator.validateAll().then((valid) => {
                if (valid) { 
                    this.$emit("updateLineOrder", selectedLineOrder);
                    this.$refs.lineOrderModal.close();
                }
            });            
        }
    }, 
}
</script>

<style lang="scss" scoped>

</style>