<template>
  <div>
    <div class="row">
      <div class="form-group col-md-4">
        <label
          class="control-label"
          :class="{ 'has-error': errors.has('SSID') }"
        >
          SSID:
        </label>
        <input
          type="text"
          :readonly="isClient"
          v-validate="'required'"
          name="SSID"
          placeholder="Enter SSID"
          v-model="config.ssid"
          class="form-control"
        />
        <span class="has-error" v-if="errors.has('SSID')">{{
          errors.first("SSID")
        }}</span>
      </div>
      <div
        class="form-group col-md-4"
        :class="{ 'has-error': errors.has('SSID password') }"
      >
        <label class="control-label"> SSID Password: </label>
        <PasswordInput
          :isClient="isClient"
          :password="config.ssidPassword"
          :userCanEdit="true"
          @changePassword="changePassword"
        />
      </div>
    </div>
    <div class="row">
      <div
        class="form-group col-md-4"
        :class="{ 'has-error': errors.has('Router Admin Username') }"
      >
        <label class="control-label"> Router Admin Username: </label>
        <input
          type="text"
          :readonly="isClient"
          placeholder="Enter Username"
          v-validate="'required'"
          name="Router Admin Username"
          v-model="config.routerAdminUsername"
          class="form-control"
        />
        <span class="has-error" v-if="errors.has('Router Admin Username')">{{
          errors.first("Router Admin Username")
        }}</span>
      </div>
      <div
        class="form-group col-md-4"
        :class="{ 'has-error': errors.has('Router Admin Password') }"
      >
        <label class="control-label"> Router Admin Password: </label>
        <input
          type="text"
          :readonly="isClient"
          placeholder="Enter Password"
          v-validate="'required'"
          name="Router Admin Password"
          v-model="config.routerAdminPassword"
          class="form-control"
        />
        <span class="has-error" v-if="errors.has('Router Admin Password')">{{
          errors.first("Router Admin Password")
        }}</span>
      </div>
    </div>
    <div class="row">
      <div
        class="form-group col-md-4"
        :class="{ 'has-error': errors.has('Router Client Username') }"
      >
        <label class="control-label"> Router Client Username: </label>
        <input
          type="text"
          :readonly="isClient"
          placeholder="Enter Username"
          v-validate="'required'"
          name="Router Client Username"
          v-model="config.routerClientUsername"
          class="form-control"
        />
        <span class="has-error" v-if="errors.has('Router Client Username')">{{
          errors.first("Router Client Username")
        }}</span>
      </div>
      <div
        class="form-group col-md-4"
        :class="{ 'has-error': errors.has('Router Client Password') }"
      >
        <label class="control-label"> Router Client Password </label>
        <input
          type="text"
          :readonly="isClient"
          placeholder="Enter Password"
          v-validate="'required'"
          name="Router Client Password"
          v-model="config.routerClientPassword"
          class="form-control"
        />
        <span class="has-error" v-if="errors.has('Router Client Password')">{{
          errors.first("Router Client Password")
        }}</span>
      </div>
    </div>
    <div class="row">
      <div
        class="form-group col-md-8"
        :class="{ 'has-error': errors.has('LAN Network') }"
      >
        <label class="control-label"> Router LAN IP Address </label>
        <input
          type="text"
          :readonly="isClient"
          placeholder="Enter LAN Network"
          v-validate="'required'"
          name="LAN Network"
          v-model="config.lanNetwork"
          class="form-control"
        />
        <span class="has-error" v-if="errors.has('LAN Network')">{{
          errors.first("LAN Network")
        }}</span>
      </div>
    </div>
    <div class="row">
      <div
        class="form-group col-md-8"
        :class="{ 'has-error': errors.has('DHCP Network') }"
      >
        <label class="control-label"> DHCP Network </label>
        <input
          type="text"
          :readonly="isClient"
          placeholder="Start: [ _ . _ . _ . _ ] - End: [ _ . _ . _ . _ ]"
          v-validate="'required'"
          name="DHCP Network"
          v-model="config.dhcpNetwork"
          class="form-control"
        />
        <span class="has-error" v-if="errors.has('DHCP Network')">{{
          errors.first("DHCP Network")
        }}</span>
      </div>
    </div>
    <div v-if="!isClient">
      <button class="btn btn-success" @click="saveRouterConfig()" type="button">
        Save
      </button>
      <hr />
      <label>Generate Router Config</label>
      <div class="row">
        <div class="form-group col-md-6">
          <div class="input-group mb-3">
            <select
              class="form-control"
              v-model="templateSelected"
              :disabled="!config.version || config.version == 0"
            >
              <option disabled value="-1">Select Router Config Template</option>
              <option
                v-for="routerConfigTemplate in routerConfigTemplates"
                :key="routerConfigTemplate.templateNumber"
                :value="routerConfigTemplate.templateNumber"
              >
                {{ routerConfigTemplate.description }}
              </option>
            </select>
            <div class="input-group-append">
              <button
                class="btn btn-default"
                :disabled="
                  !config.version ||
                  config.version == 0 ||
                  templateSelected == -1
                "
                type="button"
                @click="generateConfig()"
              >
                Generate
              </button>
              <i
                class="fa fa-info-circle"
                style="
                  color: orange;
                  margin-top: 10px;
                  margin-left: 5px;
                  font-size: 16px;
                  cursor: pointer;
                "
                v-if="!config.version || config.version == 0"
                title="Router config needs to be saved first"
              ></i>
            </div>
          </div>
        </div>
      </div>
          <ServiceOnboarding
            :isClient="userContainsPermission(['CLIENT'])"
            :saleNumber="saleNumber"
            :canSave="routerConfig.objKey != 0"
            :routerConfig="routerConfig"
            :config="config"
            :cantSaveReason="'Router Config Needs to be captured'"
          />
    </div>
  </div>
</template>

<script>
import PasswordInput from "@/components/GeneralComponents/InputControls/PasswordInput";
import ServiceOnboarding from "@/components/Services/ServiceOnboarding";

export default {
  components: {
    PasswordInput,
    ServiceOnboarding
  },
  props: {
    routerConfig: {
      type: Object,
      required: false,
    },
    saleNumber: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isClient() {
      return this.userContainsPermission(["CLIENT"]);
    },
  },
  watch: {
    routerConfig: {
      handler(to, from) {
        this.config = this.deepCopyObject(to);
      },
      immediate: true,
    },
  },
  data() {
    return {
      config: {},
      routerConfigTemplates: [],
      templateSelected: -1,
      saleNumber: null
    };
  },
  mounted() {    
    this.$http
      .get(
        this.$config.aimsAPI +
          "sales/definition/" +
          this.saleNumber +
          "/documentation/templates?docType=CONFIGURATION"
      )
      .then(
        (response) => {
          this.routerConfigTemplates = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Router Config Templates", error);
        }
      );
  },
  methods: {
    changePassword: function(data) {
        if (data) {
          this.config.ssidPassword = data;
        }
    },
    saveRouterConfig() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (!this.config.hasOwnProperty("class") || !this.config.hasOwnProperty("routerConfigNumber")) {
            this.config.class = "za.co.adept.aims.docs.RouterConfig"

            this.$http.post(this.$config.aimsAPI + "sales/definition/" + this.saleNumber + "/obj/create", this.config).then(
              (response) => {
                this.showSuccess("Router config created");
                this.$emit("reloadSale");
              },
              (error) => {
                this.showError("Could not create router config", error);
                console.error(error);
              }
            );
          } else {
            this.$emit("routerConfigSaved", this.config);
          }
        }
      });
    },
    generateConfig() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleNumber +
            "/documentation/templates/" +
            this.templateSelected +
            "/download?docType=CONFIGURATION",
          {
            responseType: "arraybuffer",
          }
        )
        .then(
          function (response) {
            const contentType = response.headers.get("Content-Type");
            let blob = new Blob([response.data], {
              type: contentType,
            });
            const fileName = response.headers
              .get("Content-Disposition")
              .split("filename = ")[1];
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.dispatchEvent(
              new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
              })
            );
          },
          function (error) {
            console.error(error);
            this.showError("Error Downloading Router Config", error.data);
          }
        );
    },
  },
};
</script>
