<template>
    <modal
        :title="'Add Database'"
        ref="addDatabaseModal"
        @save="saveDatabase(database)"
        @close="close" @dismiss="dismiss"
    >
        <div class="row">
            <div class="form-group col-md-12">
                <label class="control-label">Database Name:</label>
                <input type="text" class="form-control" v-model="database.name"/>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label class="control-label">Sql Username:</label>
                <input type="text" class="form-control" v-model="database.sqlUser.username"/>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label class="control-label">Sql Password:</label>
                <PasswordInput
                    :password="database.sqlUser.password"
                    :userCanEdit="true"
                    @changePassword="changePassword"
                />
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/GeneralComponents/Modal';
import PasswordInput from "@/components/GeneralComponents/InputControls/PasswordInput";

export default {
    components: {
        Modal,
        PasswordInput
    },
    props: {
        // newDatabase: {
        //     type: Object,
        //     required: true
        // },
        // saleDefinition: {
        //     type: Object,
        //     required: true
        // },
    },
    data() {
        return {
            database: {
                name:"",
                sqlUser: {
                    class: "za.co.adept.aims.classes.service.SqlUser",
                    sqlDatabaseNumber:0,
                    saleNumber:0,
                    username: "",
                    password: ""
                }
            }
        }
    },
    methods: {
        open() {
            this.$refs.addDatabaseModal.open();
        },
        changePassword: function(data) {
            if (data) {
                this.database.sqlUser.password = data;
            }
        },
        saveDatabase: function(db) {
            this.$emit("saveDatabase", db);
            this.$refs.addDatabaseModal.close();
        },
        // addDatabase: function(database) {
        //     const hostUrl = this.$config.aimsAPIv2;
        //     const saleNumber = this.$route.params.saleNumber;

        //     this.saleDefinition.sale.database = database;

        //     this.$http.put(`${hostUrl}sales/definition/${saleNumber}/update`, this.saleDefinition).then(
        //         () => {
        //         },
        //         (error) => {
        //             console.error(error);
        //             this.showError("Error Saving SaleDefinition", error);
        //         }
        //     ).finally(() => {
        //         this.showSuccess("Save Success");
        //         this.$refs.addDatabaseModal.close();
        //     })
        // }
    }
}
</script>

<style lang="scss" scoped>

</style>
