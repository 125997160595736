var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"card card-default"},[_c('service-wizard-layout',{attrs:{"title":"","subtitle":"","parts":_vm.parts,"onComplete":_vm.onComplete,"validateStep":_vm.validateStep}},[_c('template',{slot:"device"},[_c('form',{attrs:{"novalidate":"","data-vv-scope":"device"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('device.name'),
                  }},[_c('label',[_vm._v("Device Name:")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.filledInSaleDefinition.requiredObjs.device.name),expression:"filledInSaleDefinition.requiredObjs.device.name"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.filledInSaleDefinition.requiredObjs.device.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filledInSaleDefinition.requiredObjs.device, "name", $event.target.value)}}}),(_vm.errors.has('device.name'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("device.name")))]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4 col-lg-6"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('device.adminUsername'),
                  }},[_c('label',[_vm._v("Admin Username:")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.filledInSaleDefinition.requiredObjs.device.adminUsername),expression:"filledInSaleDefinition.requiredObjs.device.adminUsername"}],staticClass:"form-control",attrs:{"type":"text","name":"adminUsername"},domProps:{"value":(_vm.filledInSaleDefinition.requiredObjs.device.adminUsername)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filledInSaleDefinition.requiredObjs.device, "adminUsername", $event.target.value)}}}),(_vm.errors.has('device.adminUsername'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("device.adminUsername")))]):_vm._e()])]),_c('div',{staticClass:"col-xl-4 col-lg-6"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('device.password'),
                  }},[_c('label',[_vm._v("Admin Password:")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.filledInSaleDefinition.requiredObjs.device.password),expression:"filledInSaleDefinition.requiredObjs.device.password"}],staticClass:"form-control",attrs:{"type":"text","name":"password"},domProps:{"value":(_vm.filledInSaleDefinition.requiredObjs.device.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filledInSaleDefinition.requiredObjs.device, "password", $event.target.value)}}}),(_vm.errors.has('device.password'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("device.password")))]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('device.operatingSystem'),
                  }},[_c('label',[_vm._v("Operating System:")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.filledInSaleDefinition.requiredObjs.device.operatingSystem),expression:"filledInSaleDefinition.requiredObjs.device.operatingSystem"}],staticClass:"form-control",attrs:{"type":"text","name":"operatingSystem"},domProps:{"value":(_vm.filledInSaleDefinition.requiredObjs.device.operatingSystem)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filledInSaleDefinition.requiredObjs.device, "operatingSystem", $event.target.value)}}}),(_vm.errors.has('device.operatingSystem'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("device.operatingSystem")))]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('device.serialNumber'),
                  }},[_c('label',[_vm._v("Serial Number:")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.filledInSaleDefinition.requiredObjs.device.serialNumber),expression:"filledInSaleDefinition.requiredObjs.device.serialNumber"}],staticClass:"form-control",attrs:{"type":"text","name":"serialNumber"},domProps:{"value":(_vm.filledInSaleDefinition.requiredObjs.device.serialNumber)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filledInSaleDefinition.requiredObjs.device, "serialNumber", $event.target.value)}}}),(_vm.errors.has('device.serialNumber'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("device.serialNumber")))]):_vm._e()])])])])])],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }