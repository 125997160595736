<template>
  <div class="row">
    <div class="col-md-8">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template v-slot:subscriptiondetails>
            <form novalidate data-vv-scope="subscriptiondetails">
              <div class="form-group" :class="{'has-error': errors.has('softwareSubscription.description')}">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group">
                    <label class="control-label">Description: </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="filledInSaleDefinition.requiredObjs.softwareSubscription.description"
                    />
                  </div>
                  <span class="has-error" v-if="errors.has('softwareSubscription.description')">
                      {{ errors.first("softwareSubscription.description") }}
                  </span>
                </div>
              </div>
              <div class="form-group" :class="{'has-error': errors.has('softwareSubscription.startDate')}">
                <div class="col-xl-8 col-lg-12">
                    <label class="col-form-label">Subscription Start Date</label>
                    <div class="input-group">
                      <DateInput
                        type="date"
                        v-model="filledInSaleDefinition.requiredObjs.softwareSubscription.startDate"
                        name="startDate"
                        v-validate="'required'"
                        style="width: 100%"
                      />
                      <div class="input-group-append">
                          <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                      </div>
                    </div>
                    <span class="has-error" v-if="errors.has('softwareSubscription.startDate')">
                      {{ errors.first("softwareSubscription.startDate") }}
                    </span>
                  </div>    
              </div>
              <div class="form-group" :class="{'has-error': errors.has('softwareSubscription.paymentTerm')}">
                <div class="col-xl-8 col-lg-12">
                  <label>Payment Term</label>
                  <v-select
                      :options="filledInSaleDefinition.supportingEnums.softwareSubscription.paymentTerm"
                      label="description"
                      valueProp="name"
                      name="type"
                      v-validate="'required'"
                      placeholder="Select a payment term"
                      v-model="filledInSaleDefinition.requiredObjs.softwareSubscription.paymentTerm"
                  >
                  </v-select>
                  <span class="has-error" v-if="errors.has('softwareSubscription.paymentTerm')">
                          {{errors.first('softwareSubscription.paymentTerm')}}
                  </span>
                </div>
              </div>
              
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
import DateInput from "@/components/GeneralComponents/InputControls/DateInput";
export default {
  components: {
    ServiceWizardLayout,
    DateInput,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "subscriptiondetails",
          displayName: "Recurring Software Subscription",
        }
      ],
      filledInSaleDefinition: {
        requiredObjs: {
          softwareSubscription: {

          }
        },
        supportingEnums: {
          softwareSubscription: {
            paymentTerm: []
          }
        }
      }
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
  },
  methods: {
    onComplete() {
      this.$emit("createSale", this.filledInSaleDefinition);
    },
    validateStep(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>
