<template>
  <div>
    <span :class="getStatusBadge">
        {{getOutputLabel}}
    </span>
  </div>
</template>

<script>
  export default {
    props: {
      badgeText: {
        required: true,
        type: String,
        default: "..."
      },
    },
    data() {
      return {
        
      }
    },
    computed: {
        getStatusBadge() {
            var badge = "badge badge-success";
            if (this.badgeText == "true") {
                badge = "badge bg-green";
            }
            if (this.badgeText == "false") {
                badge = "badge bg-red text-white";
            }
            if (this.badgeText == "...") {
                badge = "badge bg-yellow";
            }
            return badge;
        },
        getOutputLabel() {
            if (this.badgeText == "true") {
                return "Verified"
            } else if (this.badgeText == "false") {
                return "Unverified"
            } else {
                return "Verifying..."
            }
        },
    }
  }

</script>
