<template>
  <service-screen-layout :parts="parts" v-if="saleLoaded">
    <template v-slot:account>
      <div class="row row-spacing">
        <div class="col-md-8 py-4">
            <SolarWindsBackupDetails
              :solarwindsBackup="saleDefinition.sale.solarwindsBackup"
              @saveSale="saveSale"
            />
        </div>
        <div 
          class="col-md-4" 
          v-if="saleDefinition.details && 
          saleDefinition.details.portal && 
          saleDefinition.details.portal.url.length > 0 && 
          saleDefinition.details.portal.name.length > 0"
        >
          <PortalButton :portalDetails="saleDefinition.details.portal"/>
        </div>
      </div>
    </template>
    <template v-slot:usage>
      <div class="row">
        <div class="col-md-12 py-4">
            <SolarwindsBackupUsage
              ref="backupUsage"
              :usages="saleDefinition.sale.solarwindsBackup.usage"
              :backupPackage="saleDefinition.sale.solarwindsBackup.backupPackage"
            />
        </div>
      </div>
    </template>
    <template v-slot:packages>
      <div class="row">
        <div class="col-md-12 py-4">
            <CloudBackupPackage
              ref="backupPackages"
              :solarwindsBackup="saleDefinition.sale.solarwindsBackup"
              :packages="saleDefinition.meta.solarwindsBackup.children.backupPackage.partMeta.options"
              @saveSale="saveSale"
            />
        </div>
      </div>
    </template>
  </service-screen-layout>
</template>

<script>
import SaleFunctions from "@/pages/Services/SaleFunctions";
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout"
import SolarWindsBackupDetails from "@/components/ObjParts/SingleCaptured/SolarWindsBackupDetails"
import SolarwindsBackupUsage from "@/components/ObjParts/MultipleLoaded/SolarwindsBackupUsage"
import CloudBackupPackage from "@/components/ObjParts/Selected/CloudBackupPackage"
import PortalButton from "@/components/ObjParts/SingleLoaded/PortalButton";

export default {
  extends: 
    SaleFunctions,
  components: {
    ServiceScreenLayout,
    SolarWindsBackupDetails,
    SolarwindsBackupUsage,
    CloudBackupPackage,
    PortalButton
  },
  computed: {
    
  },
  data() {
    return {
      saleNumber: null,
      saleLoaded: false,
      parts: [
        {
          name: "account",
          icon: "fa fa-info-circle",
          displayName: "Account Details",
          permissions: ["*"],
        },
        {
          name: "usage",
          icon: "fa fa-area-chart",
          displayName: "Backup Usage",
          permissions: ["STAFF"],
        },
        {
          name: "packages",
          icon: "fa fa-dropbox",
          displayName: "Cloud Backup Packages",
          permissions: ["STAFF"],
        },
      ],
      saleDefinition: {
        sale: {
          solarwindsBackup: {
            backupPackage: {},
            usage: [],
          },
        },
        meta: {
          solarwindsBackup: {
            children: {
              backupPackage: {
                partMeta: {
                  options: []
                }
              }
            }
          }
        }
      },
    };
  },
  mounted() {
     this.saleNumber = this.$route.params.saleNumber;
     this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition: function() {
      this.fetchSaleDefinition(this.saleNumber).then(
        (response) => {
          this.saleDefinition = response.data;         
                    
          this.saleLoaded = true;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
    },
    saveSale: function() {      
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
          this.$validator.validateAll().then(result => {
            if (result) {
              const hostUrlV2 = this.$config.aimsAPIv2;
              const saleNumber = this.saleNumber;
              const solarwindsBackupSale = this.saleDefinition;
              this.$http.put(`${hostUrlV2}sales/definition/${saleNumber}/update`, solarwindsBackupSale).then(
                  (response) => {
                    this.showSuccess("Save Success");
                    this.loadSaleDefinition();
                  },
                  (error) => {
                    console.error(error);
                    this.showError("Error Saving sale", error);
                  }
                );
            }
          });
        }
      });
    },
  },
};
</script>