<template>
  <div>
    <div class="form-row">
      <div class="form-group col-md-2 mb-0">
        <label>Routed Address</label>
      </div>
      <div class="form-group col-md-2 mb-0">
        <label>Metric</label>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-2">
          <input class="form-control" v-model="addressToAdd"/>
      </div>
      <div class="form-group col-md-2">
          <input class="form-control" v-model="metricToAdd"/>
      </div>
      <div class="form-group col-auto" v-if="ipv4Networks">
          <button type="button" class="btn btn-success dropdown-toggle" data-toggle="dropdown">
            Add Ipv4 Route
          </button>
          <div class="dropdown-menu">
            <a href="javascript:void(0)" @click="confirmAddRoute('IPV4', addressToAdd, 32, metricToAdd)" class="dropdown-item">/32</a>
            <a href="javascript:void(0)" @click="confirmAddRoute('IPV4', addressToAdd, 30, metricToAdd)" class="dropdown-item">/30</a>
            <a href="javascript:void(0)" @click="confirmAddRoute('IPV4', addressToAdd, 29, metricToAdd)" class="dropdown-item">/29</a>
            <a href="javascript:void(0)" @click="confirmAddRoute('IPV4', addressToAdd, 28, metricToAdd)" class="dropdown-item">/28</a>
            <a href="javascript:void(0)" @click="confirmAddRoute('IPV4', addressToAdd, 27, metricToAdd)" class="dropdown-item">/27</a>
            <a href="javascript:void(0)" @click="confirmAddRoute('IPV4', addressToAdd, 26, metricToAdd)" class="dropdown-item">/26</a>
          </div>
      </div>
      <div class="form-group col-auto" v-if="ipv6Networks">
          <button type="button" class="btn btn-success dropdown-toggle" data-toggle="dropdown">
            Add Ipv6 Route
          </button>
          <div class="dropdown-menu">
            <a href="javascript:void(0)" @click="confirmAddRoute('IPV6', addressToAdd, 48, metricToAdd)" class="dropdown-item">/46</a>
            <a href="javascript:void(0)" @click="confirmAddRoute('IPV6', addressToAdd, 56, metricToAdd)" class="dropdown-item">/48</a>
            <a href="javascript:void(0)" @click="confirmAddRoute('IPV6', addressToAdd, 64, metricToAdd)" class="dropdown-item">/64</a>
          </div>
      </div>
    </div> 

    <div class="table-responsive table-bordered" v-if="allNetworks.length > 0">
      <table class="table">
        <thead>
          <tr>
            <th>Family</th>
            <th>Address</th>
            <th>Short Mask</th>
            <th>Metric</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="network in allNetworks" :key="network.address">
            <td>{{network.family}}</td>
            <td>{{network.address}}</td>
            <td>{{network.shortMask}}</td>
            <td>{{network.metric}}</td>
            <td><button class="btn btn-danger" type="button" @click="removeNetwork(network)">Remove</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
        return {
          addressToAdd : "0.0.0.0",
          metricToAdd : "0"
        }
    },
    props: {
      ipv4Networks: {
        type: Array,
        required: false
      },
      ipv6Networks: {
        type: Array,
        required: false
      }
    },
    computed: {
      allNetworks() {
        let all = []
        if (this.ipv4Networks) {
          all = all.concat(this.ipv4Networks);
        }
        if (this.ipv6Networks) {
          all = all.concat(this.ipv6Networks);
        }
        return all;
      }
    },
    methods: {
      confirmAddRoute(family, address, shortMask, metric) {
        this.$swal({
          title: `Are you sure you want to add a ${family} ${address}/${shortMask} route?`,
          type: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.value) {
            this.$emit('routeAdded', {
              address,
              family,
              shortMask,
              metric
            })
          }
        });
      },
      removeNetwork(network) {
        this.$swal({
          title: `Are you sure you want to remove network ${network.address} /${network.shortMask}?`,
          type: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.value) {
            this.$emit('routeRemoved', network)
          }
        });
      }
    }
  }

</script>

<style>

</style>
