<template>
  <div class="mt-4">
    <div class="row row-spacing">
      <div class="col-md-6">
        <div class="form-row align-items-center">
          <div class="col-md-6">
            <label>Data Centre:</label>
          </div>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="cabinetRental.dataCentre.description" disabled />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-row align-items-center">
          <div class="col-md-6">
            <label>Cabinet Label:</label>
          </div>
          <div class="col-md-6">            
            <input type="text" class="form-control"  v-model="cabinetRental.cabinetLabel" />
          </div>
        </div>
      </div>
    </div>
    <div class="row row-spacing">
      <div class="col-md-6">
        <div class="form-row align-items-center">
          <div class="col-md-6">
            <label>Service ID:</label>
          </div>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="cabinetRental.serviceID" />
          </div>
        </div>    
      </div>
      <div class="col-md-6">
        <div class="form-row align-items-center">
          <div class="col-md-6">
            <label>Power Service ID:</label>
          </div>
          <div class="col-md-6">
            <input type="text" class="form-control" v-model="cabinetRental.powerServiceID" />
          </div>
        </div>    
      </div>
    </div>
    <div class="row row-spacing">
      <div class="col-md-6">
        <div class="form-row align-items-center">
          <div class="col-md-6">
            <label>Non Recurring Cost (Excl. VAT):</label>
          </div>
          <div class="col-md-6">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">R</span>
              </div>
              <input type="text" class="form-control" v-model="cabinetRental.nrcExclVat" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-row align-items-center">
          <div class="col-md-6">
            <label>Monthly Recurring Cost (Excl. VAT):</label>
          </div>
          <div class="col-md-6">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">R</span>
              </div>
              <input type="text" class="form-control" v-model="cabinetRental.mrcExclVat" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-spacing" v-allowed:view="['STAFF']">
      <div class="col-md-12">
        <button class="btn btn-success" @click="$emit('saveSale')" type="button">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cabinetRental: {
      type: Object,
      required: true,
    },
  },
};
</script>
