<template>
  <ServiceScreenLayout :parts="parts">
    <template v-slot:service>
      <div class="row">
        <div class="col-md-7">
          <div class="card-header" >
              <h4>{{ saleDefinition.product.productDefinition.includes("Bulk") ? 'Bulk ' : '' }} Authenticated SMTP</h4>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <label>Username:</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="saleDefinition.definition.authSmtp.username"
                      :disabled="!staffOrClientAccess(productRestriction)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <label>Password:</label>
                    <MaskedPasswordInput
                      :password="saleDefinition.definition.authSmtp.password"
                      :userCanEdit="staffOrClientAccess(productRestriction)"
                      @changePassword="changePassword"
                      :showPasswordToggle="true"
                    />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-row row-spacing justify-content-between" v-if="staffOrClientAccess(productRestriction)">
              <div class="form-group col-auto">
                <button type="button" @click="saveSaleDefinition()" class="btn btn-success">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:senders>
      <div class="form-group">
        <div class="row align-items-center">
          <div class="col-md-4">
            <button
              class="btn btn-info"
              @click="openSenderModal()"
              type="button"
               v-if="staffOrClientAccess(productRestriction)"
            >
              Add Sender
            </button>
          </div>
        </div>
        <table class="table table-bordered row-spacing" v-if="saleDefinition.definition.authSmtp.authSmtpSenders.length > 0">
          <thead>
            <tr>
              <th>Sender Address</th>
              <th v-if="staffOrClientAccess(productRestriction)"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="sender in saleDefinition.definition.authSmtp.authSmtpSenders"
              :key="sender.objKey"
            >
              <td>{{ sender.senderAddress }}</td>
              <td class="text-center" v-if="staffOrClientAccess(productRestriction)">
                <button 
                type="button" 
                class="btn btn-info" 
                v-tooltip="'Edit Sender'"
                @click="openSenderModal(sender)"
                >
                  <i class="fa fa-edit"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-primary"
                  v-tooltip="'Delete Sender'"
                  @click="deleteSender(sender)"
                >
                  <em class="fa fa-trash fa-fw"></em>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="alert alert-info row-spacing">
          No Allowed Senders to display
        </div>
      </div>

      <modal
        :title="newSender.objKey ? 'Edit Address' : 'Add Address'"
        ref="addNewSender"
        size="modal-md"
        @save="saveSender(newSender)"
        @dismiss="dismissSenderModal()"
      >
        <div class="row">
          <div class="form-group col-md-10">
            <label class="control-label mt-2">Sender Address:</label>
            <div class="input-group">
              <input class="form-control" v-model="newSender.senderAddress" />
            </div>
          </div>
        </div>
      </modal>
    </template>
  </ServiceScreenLayout>
</template>

<script>
import MaskedPasswordInput from "@/components/GeneralComponents/InputControls/MaskedPasswordInput";
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";
import Modal from "@/components/GeneralComponents/Modal";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    MaskedPasswordInput,
    Modal,
  },
  data() {
    return {
      saleNumber: null,
      parts: [
        {
          name: "service",
          icon: "fa fa-user-circle",
          displayName: "Service",
          permissions: ["*"],
        },
        {
          name: "senders",
          icon: "fa-solid fa-envelope",
          displayName: "Senders",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        definition: {
            authSmtp: {
              authSmtpSenders: [],
            }
        },
        product: {
          productDefinition: '',
        },
        supportingObjs: {
          authSmtpSenders: {}
        }
      },
      newSender: {},
      productRestrictions: [],
      productRestriction: {},
    };
  },
  async mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition() {
      this.getSaleDefinition(this.saleNumber).then(
        (success) => {
          this.saleDefinition = success.data;
          this.newSender = this.saleDefinition.supportingObjs.authSmtpSenders;
          this.getAllProductRestrictions();
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
      return Promise.resolve('Success');
    },
    saveSaleDefinition: function() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http.put(this.$config.aimsAPI + "sales/definition/" + this.saleNumber + "/update", this.saleDefinition)
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.loadSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Sale Definition", error);
                this.loadSaleDefinition();
              }
            );
        }
      });
    },
    changePassword: function(data) {
        if (data) {
          this.saleDefinition.definition.authSmtp.password = data;
        }
    },
    openSenderModal(data) {
      if (data) {
        this.newSender = data;
      } else {
        this.newSender = this.saleDefinition.supportingObjs.authSmtpSenders;
        this.newSender.saleNumber = this.saleDefinition.definition.authSmtp.saleNumber;
        this.newSender.authSmtpNumber = this.saleDefinition.definition.authSmtp.authSmtpNumber;
      }
      this.$refs.addNewSender.open();
    },
    dismissSenderModal() {
      this.newSender = this.saleDefinition.supportingObjs.authSmtpSenders;
      this.$refs.addNewSender.close();
    },
    saveSender() {
      this.$refs.addNewSender.isLoading();
      this.saleDefinition.definition.authSmtp.authSmtpSenders.push(this.newSender);
      this.saveSaleDefinition();
      this.$refs.addNewSender.close();
    },
    deleteSender(data) {
      this.deleteObjPart(data)
        .then((response) => {
          this.showSuccess("Delete Success");
          this.loadSaleDefinition()
        },(error) => {
          console.error(error);
          this.showError("Error Deleting Sender", error);
          this.loadSaleDefinition()
        });
    },
    getAllProductRestrictions: function() {
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}products/restrictions`).then(
        (response) => {
          this.productRestrictions = response.data;

          this.productRestrictions.filter((restriction) => {
            if (restriction.description.toLowerCase() === this.saleDefinition.product.clientAccessRestriction.toLowerCase()) {
              this.productRestriction = restriction;
            }
          });
        },
        (error) => {
          console.error(error);
          this.showError("Error fetch all product restrictions", error);
        }
      );
    }
  },
};
</script>