<template>
    <div>
        <v-select
            :options="arrayOptions"
            @input="sendSelection"
        ></v-select>
    </div>
</template>

<script>
export default {
    props: {
        baseArray: {
            type: Array,
            required: true
        },
    },
    computed: {
        arrayOptions() {
            if (this.baseArray.length > 0) {
                if (this.baseArray[0].billingPeriod) {
                    var filteredArray = [];

                    for (let i = 0; i < this.baseArray.length; i++) {
                        filteredArray.push(this.getLabel(this.baseArray[i].billingPeriod));
                    }

                    return [...new Set(filteredArray)];
                }
                if (this.baseArray[0].period) {
                    var filteredArray = [];

                    for (let i = 0; i < this.baseArray.length; i++) {
                        filteredArray.push(this.getLabel(this.baseArray[i].period));
                    }

                    return [...new Set(filteredArray)];
                }
            } else {
                return [];
            }
        }
    },
    methods: {
        sendSelection(value) {
            if (this.baseArray.length > 0) {
                if (this.baseArray[0].billingPeriod) {
                    var filteredList = []

                    for (let i = 0; i < this.baseArray.length; i++) {
                        if (this.baseArray[i].billingPeriod == this.horrifyBillingPeriod(value)) {
                            filteredList.push(this.baseArray[i]);
                        }
                    }

                    this.$emit('selectedOption', filteredList);
                }
                if (this.baseArray[0].period) {
                    var filteredList = []

                    for (let i = 0; i < this.baseArray.length; i++) {
                        if (this.baseArray[i].period == this.horrifyBillingPeriod(value)) {
                            filteredList.push(this.baseArray[i]);
                        }
                    }

                    this.$emit('selectedOption', filteredList);
                }
            } else {
                return [];
            }
        },
        horrifyBillingPeriod(period) {
            var Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            var year = period.substring(period.length-4, period.length);

            for (let i = 0; i < Months.length; i++) {
                if (period.includes(Months[i])) {
                    if (i < 9) {
                        var month = '0' + (i+1);
                    } else {
                        var month = (i+1);
                    }
                }
            }

            var output = year + '' + month;
            return parseInt(output);
        },
        getLabel(period) {
            return this.prettifyBillingPeriod(period);
        },
    },
}
</script>