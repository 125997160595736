<template>
    <communicationModal :title="modalTitle" ref="contactPerson">
       <div class="row">
           <form>
                <div class="form-group col-md-12 align-items-center">
                    <label class="col-form-label">Email:</label>
                    <div class="contact-fields">
                    <a :href="'mailto:' + contactPerson.emailAddress" @click.stop target="_blank">{{ contactPerson.emailAddress }} <i class="fa fa-external-link"></i></a>
                    </div>
                </div>
                <div class="form-group col-md-12 align-items-center">
                    <label class="form-label">Mobile Number:</label>
                    <div v-if="contactPerson.telephonecell > 0" class="contact-fields">
                    <a :href="'tel:' + contactPerson.telephonecell" @click.stop target="_blank">{{ contactPerson.telephonecell }} <i class="fa fa-external-link"></i></a>
                    </div>
                    <div v-else>
                      <strong><p>No phone number available. To call or send SMS, add mobile number <span class="add-contact-text text-info" @click="addContactNumber(contactPerson)">here.</span></p></strong>
                    </div>
                </div>
            </form>
       </div>
       <hr>
       <div v-if="contactPerson.telephonecell > 0">
         <div class="row">
            <div class="form-group col-md-12">
            <label class="col-form-label">SMS:</label>
              <div class="input-group">
                <textarea :class="{'has-error': errors.has('message')}" name="message" v-validate="'required'"
                  v-model="smsMessage.message" maxlength="159" class="form-control"
                  rows="5" placeholder="Enter SMS Message"></textarea>
                <div class="word-counter text-muted">{{smsMessage.message.length}}/159</div>
              </div>
              <span class="has-error col-md-12" v-if="errors.has('message')">{{errors.first('message')}}</span>
            </div>
       </div>
       <div class="row">
              <div class="col-md-12">
                <button @click="sendSms()" type="button" class="btn btn-green">Send SMS</button>
              </div>
        </div>
       </div>
    </communicationModal>
</template>

<script>
import CommunicationModal from "@/components/GeneralComponents/CommunicationModal";
export default {
    props: {
        modalTitle: {
            type: String,
            required: true,
        },
        contactPerson: {
            type: Object,
            required: true,
        },
    },
    components: {
        CommunicationModal,
    },
    data() {
        return {
            smsMessage: {
                recipient: '',
                message: ''
            },
        }
    },
    mounted() {
        
    },
    methods: {
      open: function() {
        this.$refs.contactPerson.open();
      },
      sendSms: function() {
        const hostUrl = this.$config.aimsAPI;
        this.smsMessage.recipient = this.contactPerson.telephonecell;
        this.$http.put(`${hostUrl}notifications/client/contact`, this.smsMessage).then(
          (response) => {
            this.$refs.contactPerson.close();
            this.showSuccess(response.data.message);
          },
        (error) => {
            this.showError("Could not send SMS Message", error);
            console.error(error);
          });
      },
      addContactNumber: function(contactPerson) {        
        this.$router.push({ name: 'clientusers', params: { personNumber: contactPerson.personNumber } });
        this.$refs.contactPerson.close();
      }
    },
}
</script>

<style scoped>

.contact-fields {
    font-weight: 600;
}

.word-counter {
  position: absolute;
  right: 18;
  bottom: 0;
  z-index: 1000;
}

.add-contact-text {
  cursor: pointer;
}

</style>