import Vue from 'vue'
import dotenv from 'dotenv'
dotenv.config();
//------------------

//VUE ADDONS
import VueRouter from 'vue-router'
import ServicePanelRouter from './servicePanelRouter'
import VueResource from 'vue-resource'
import VueSweetalert2 from 'vue-sweetalert2'
import Toasted from 'vue-toasted'
import VueLocalStorage from 'vue-localstorage'
import GmapVue from 'gmap-vue';
import Multiselect from 'vue-multiselect'
import VueChart from 'vue-chart-js'
import VeeValidate from 'vee-validate'   
import Datepicker from 'vue2-datepicker'
import VueNumeric from 'vue-numeric'
import VTooltip from 'v-tooltip'
import VueClipboard from 'vue-clipboard2'
import ToggleButton from 'vue-js-toggle-button'
import VSelect from '@/components/GeneralComponents/InputControls/Select'
import VueApexCharts from 'vue-apexcharts'
import DomPortal from 'vue-dom-portal'
import NProgress from 'vue-nprogress'
import moment from 'moment'
import { createPinia, PiniaVuePlugin } from 'pinia'
import _ from 'lodash';

//CSS
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vue-select/dist/vue-select.css';

const appRoutes = require('./structures/appRoutes')
const VueInputMask = require('vue-inputmask').default

//CONFIGS
const VueConfig = require('vue-config')
import servicePanelConfig from '@/structures/servicePanelConfig'
import {
  isArray
} from 'util';

Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places',
  }
})
Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.use(NProgress)
const nprogress = new NProgress()
if (window.origin.indexOf('adept.servicepanel.co.za') != -1) {
  nprogress.configure({
    template: "<div class='bar' role='bar' style='background-color:#3a3f51'><div class='peg'></div></div>"
  });
} else {
  nprogress.configure({
    template: "<div class='bar' role='bar' style='background-color:red'><div class='peg'></div></div>"
  });
}

Vue.use(VueConfig, servicePanelConfig)
Vue.use(VueResource)
Vue.use(VueRouter)
Vue.use(VueSweetalert2)
Vue.use(Toasted, {
  iconPack: 'fontawesome'
})
Vue.use(VueLocalStorage)
Vue.component('multiselect', Multiselect)
Vue.use(VueChart)
Vue.use(VeeValidate)
Vue.use(Datepicker)
Vue.use(VueNumeric)
Vue.use(VueClipboard)
Vue.use(ToggleButton)
Vue.use(VueInputMask)
Vue.use(VTooltip)
Vue.component('v-select', VSelect)
Vue.component('apexchart', VueApexCharts)
Vue.use(DomPortal)
Vue.use(_)

//CONTROLLING VIEW AND EDIT RIGHTS ON ELEMENTS
Vue.directive('allowed', {
  inserted: function (element, binding, vNode) {

    const allowedPermissionNames = binding.value;
    if (!vNode.context.userContainsPermission(allowedPermissionNames)) {

      if (binding.arg == 'view') {
        element.remove();
      }

      if (binding.arg == 'edit') {
        element.disabled = true;
      }
    }
  }
});

new Vue({
  pinia,
  nprogress,
  router: ServicePanelRouter,
  data: function () {
    return {
      userGroups: [],
      activeFullNavStructure: [],
      previousRoute: null,
      productDefinitions: [],
      oldProducts: [],
      screenshotView: null,
    }
  },
  watch: {
    $route(to, from) {
      this.previousRoute = from;
      if (!from.fullPath.includes("/login") 
      && !from.fullPath.includes("/otp")
      && !from.fullPath.includes("/oauth2")
      && !from.fullPath.includes("/changepassword")) {
        this.$localStorage.set("OG_URL", from.fullPath);
      }
    }
  },
  created: async function () {
    document.title = this.$config.browserTitle;
    if (!this.$config.isDev) {
      Vue.config.silent = true
    }

    //GLOBAL MIXINS
    Vue.mixin({
      data: function () {
        return {
          versionUpdated: false,
          lastVersionChecked: {},
          appLoading: true
        }
      },
      created: function () {
        var link = document.createElement("link");
        link.href = "/servicepanel-themes/" + this.$config.servicePanelTheme;
        link.type = "text/css";
        link.rel = "stylesheet";
        document.getElementsByTagName("head")[0].appendChild(link);
      },
      methods: {
        currencyFormat: function (value) {
          if (typeof value === 'string') {
            value = parseFloat(value);
          }
          if (value) {
            return (value).toLocaleString('en-ZA', {
                      style: 'currency',
                      currency: 'ZAR',
                    });
          } else {
            return 'R 0.00';
          }
        },
        currencyFormatUSD: function (value) {
          if (typeof value === 'string') {
            value = parseFloat(value);
          }
          if (value) {
            return (value).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    });
          } else {
            return '$ 0.00';
          }
        },
        decimalFormat: function (value) {
          if (value) {
            return parseFloat(value).toFixed(2)
          } else {
            return 0.00;
          }
        },
        timesVAT(value) {
          if (value) {
            return (value * this.$root.vatRate).toLocaleString('en-ZA', {
                      style: 'currency',
                      currency: 'ZAR',
                    });
          } else {
            return 'R ' + 0.00;
          }
        },
        timesVATNoCurrency(value) {
          if (value && value > 0) {
            return (value * this.$root.vatRate).toFixed(2);
          } else {
            return 0;
          }
        },
        exVATNoCurrency(value) {
              if (value && value > 0) {
                  return (value / this.$root.vatRate).toFixed(2);
                } else {
                  return 0;
                }
        },
        dateTimeFormat: function (value) {
          if (value)  {
            return moment(String(value)).format('YYYY-MM-DD HH:mm:ss');
          }
        },
        dateFormat: function (value) {
          if (value) {
            return moment(String(value)).format('YYYY-MM-DD');
          }
        },
        sizeBytesToKb: function (value) {
          if (value && value > 0) {
            return (value / 1024 ).toFixed(2);
          } else {
            return 0;
          }
        },
        sizeBytesToMb: function (value) {
          if (value && value > 0) {
            return (value / 1024 / 1024).toFixed(2);
          } else {
            return 0;
          }
        },
        sizeBytesToGb: function (value) {
          if (value && value > 0) {
            return (value / 1024 / 1024 / 1024).toFixed(2);
          } else {
            return 0;
          }
        },
        sizeBytesToGbWhole: function (value) {
          if (value && value > 0) {
            return (value / 1024 / 1024 / 1024);
          } else {
            return 0;
          }
        },
        sizeBytesToTb: function (value) {
          if (value && value > 0) {
            return (value / 1024 / 1024 / 1024 / 1024).toFixed(2);
          } else {
            return 0;
          }
        },
        sizeGbToBytes: function (value) {
          if (value && value > 0) {
            return (value * 1024 * 1024 * 1024).toFixed(0);
          } else {
            return 0;
          }
        },
        sizeGbToBytesWhole: function (value) {
          if (value && value > 0) {
            return (value * 1024 * 1024 * 1024);
          } else {
            return 0;
          }
        },
        speed_bitsToMbits: function (value) {
          if (value && value > 0) {
            return value / 1000000;
          } else {
            return 0;
          }
        },
        speed_mbitsToBits: function (value) {
          if (value && value > 0) {
            return value * 1000000;
          } else {
            return 0;
          }
        },
        speed_bitsToKbits: function (value) {
          if (value && value > 0) {
            return value / 1000;
          } else {
            return 0;
          }
        },
        momentAgo: function (date) {
          if (date.includes('1970-01-01')) {
            return "0 days";
          }
          return moment().to(date)
        },
        getProductDefinition: function (product) {
          var index = this.$root.productDefinitions.findIndex(pd => pd.product == product);
          if (index != -1) {
            return this.$root.productDefinitions[index]
          }
          return -1;
        },
        userContainsPermission: function (groupNames) {
          for (var g = 0; g < groupNames.length; g++) {
            if (groupNames[g] === '*') {
              //anyone has access
              return true;
            }
            const indexOfGroup = this.$root.userGroups.findIndex(group => group.name === groupNames[g]);

            if (indexOfGroup != -1) {
              return true;
            }
          }

          return false;
        },
        staffOrClientAccess(clientAccessRestriction) {          
          if (this.userContainsPermission(["STAFF"]) || this.userContainsPermission(["TECHNICAL", "CLIENT_ADMIN"]) && clientAccessRestriction.name === "CLIENT_USAGE") {
            return true
          } else {
            return false
          }
        },
        initAuth: function () {
          var token = this.$localStorage.get('aims-token');
          if (!token) {
            //try for temp token, which is not a user login but a client logging in with service credentials
            token = this.$localStorage.get('temp-token');
          } else {
            //remove the temp token if there is one to reduce abiguity
            this.$localStorage.remove('temp-token');
          }

          var requestCount = 0;

          if (token) {
            Vue.http.interceptors.push((request, next) => {
              request.headers.set('Authorization', "Bearer " + token);

              if (request.url.indexOf('users/groups') == -1) {
                if (requestCount == 0) {
                  this.$root.$emit('START_PROGRESS');
                }
                requestCount++;
              }

              next((response) => {

                if (requestCount == 1) {
                  this.$root.$emit('STOP_PROGRESS');
                }

                if (requestCount > 0)
                  requestCount--;

                if (response.status == 401) {
                  this.logout("tokenIssue=" + requestCount);
                }
              });
            });

            Vue.http.get(this.$config.aimsAPI + "products/frontend-product-information").then(response => {
              this.$root.productDefinitions = response.data;
              
              Vue.http.get(this.$config.aimsAPI + "products/frontend-product-information/legacy").then(response => {
                this.$root.oldProducts = response.data;
                this.$root.productDefinitions.push(...this.$root.oldProducts);
              },
              error => {
                this.showError("Error fetching legacy frontend product information", error);
                console.error(error);
              })
            },
            error => {
              this.showError("Error fetching frontend product information", error);
              console.error(error);
            })
              
            Vue.http.get(this.$config.aimsAPI + "system/vatrate").then(response => {
                this.$root.vatRate = response.data;
              },
              error => {
                this.showError("Error fetching VAT rate", error);
                console.error(error);
              });
          } else {
            this.logout("tokenIssue=1");
          }
        },
        clearCustomInterceptors: function () {
          for (var i = Vue.http.interceptors.length - 1; i > 5; i--) {
            if (Vue.http.interceptors[i] instanceof Object)
            Vue.http.interceptors.splice(i, 1);
          }
        },
        userHasRouteAccess(routeName, routes) {

          if (!routes) {
            routes = appRoutes.default;
          }

          for (var p = 0; p < routes.length; p++) {
            if (routes[p].name === routeName) {
              if (routes[p].meta && Object.keys(routes[p].meta).length > 0 && this.userContainsPermission(routes[p].meta.permissions)) {
                return true;
              }
            } else {
              if (routes[p].children && routes[p].children.length > 0) {
                if (this.userHasRouteAccess(routeName, routes[p].children)) {
                  return true;
                }
              }
            }
          }
          return false;
        },
        //restrict the nav bar to permissions
        getRestrictedNavStructure: function (navBarStructure) {
          var userNavStructure = {
            heading: navBarStructure.heading,
            menu: []
          };

          if (navBarStructure.backRoute) {
            userNavStructure.backRoute = navBarStructure.backRoute;
          }
          for (var m = 0; m < navBarStructure.menu.length; m++) {

            var menuItem = navBarStructure.menu[m];

            var userMenuItem = JSON.parse(JSON.stringify(menuItem));
            //remove children menu items because we dont know if the user has permission to view it
            userMenuItem.children = [];

            var noChildren = false;
            //submenus
            if (menuItem.children) {
              for (var s = 0; s < menuItem.children.length; s++) {
                var subMenuItem = menuItem.children[s];
                
                if (this.userHasRouteAccess(subMenuItem.routeName)) {
                  userMenuItem.children.push(subMenuItem);

                //subchildren
                } else if (subMenuItem.children) {
                  var hasSubChildren = false;

                  for (var ss = 0; ss < subMenuItem.children.length; ss++) {
                    var subSubMenuItem = subMenuItem.children[ss];
    
                    if (!this.userHasRouteAccess(subSubMenuItem.routeName)) {
                      subMenuItem.children = subMenuItem.children.filter(child => child != subSubMenuItem)
                    } else {
                      hasSubChildren = true;
                    }
                  }

                  if (hasSubChildren) {
                    userMenuItem.children.push(subMenuItem);
                  }
                }
              }
              noChildren = (!userMenuItem.children || userMenuItem.children.length == 0);
            }

            if (noChildren == false) {
              if (this.userHasRouteAccess(menuItem.routeName))
                userNavStructure.menu.push(userMenuItem);

              if (menuItem.children)
                userNavStructure.menu.push(userMenuItem);
            }
          }
         return userNavStructure;
        },
        changePageTitle: function () {

        },
        openNewWindow: function (type, key) {
          if (type == 'SALE') {
            const link = this.$config.urlToAIMS + '#!sale.LIST/saleNumber=' + key;
            window.open(link);
          } else if (type == 'CLIENT') {
            const link = this.$config.urlToServicePanel + '/main/client/' + key + '/sales/all';
            window.open(link);
          }
        },
        getScreenshot: function (error) {
          const html2canvas = require('html2canvas');

          html2canvas(document.body).then(canvas => {
            canvas.toBlob((blob) => {
              const formData = new FormData();

              let eID = 'n/a';
              if (error.data && error.data.errorId) {
                eID = error.data.errorId;
              }

              let metaData = { url: window.location.href, message: '' }
              if (error.data && error.data.message) {
                metaData.message = error.data.message;
              }

              let file = new File([blob],  eID + ".png", { type: "image/png" })
              formData.append('file', file);
              formData.append('meta', JSON.stringify(metaData));
              
              this.$http.post(this.$config.aimsAPI + "error/submit/" + eID, 
              formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
            }, 'image/png');
          });
        },
        showSuccess: function (message) {
          this.$toasted.show(message, {
            theme: "outline",
            type: 'success',
            position: "top-center",
            icon: 'check',
            duration: 3000
          });
        },
        showAlerts: function(notification) {
          this.$toasted.show(notification, {
            theme: 'outline',
            type: 'info',
            position: 'top-center',
            icon: 'bell',
            duration: 4000,
          })
        },
        showWarningAlert: function(warning) {
          this.$toasted.show(warning, {
            theme: 'outline',
            type: 'error',
            position: 'top-center',
            duration: 4000,
          })
        },
        showError: function (title, error) {
          var delay = 0;
          if (!this.$swal.isVisible()) {
            delay = 500;
            //avoid overlapping
          }

          setTimeout(() => {
            var errorMessage = 'Unknown Error';
            let shouldntReport = false;
            if (error.data && error.data.message) {
              shouldntReport = error.data.message.toLowerCase().includes("contact person with email address");  
              errorMessage = error.data.message;
            } else {
              if (error.status == 0) {
                errorMessage = 'Could not contact server';
              } else if (error.toString() === '[object ArrayBuffer]') {
                errorMessage = JSON.parse(Buffer.from(error).toString('utf8')).message;
              } else if (error.toString() === '[object Object]') {
                errorMessage = JSON.parse(Buffer.from(error.body).toString('utf8')).message;
              } else {
                errorMessage = "Couldn't interpret error message."
              }
            }
            if (error.status != 401) {
              if (!this.$config.isDev) {
                this.getScreenshot(error);
              }
             
              if (this.userContainsPermission(['CLIENT']) && !shouldntReport) {
                this.$swal({
                  type: 'error',
                  title,
                  text: errorMessage,
                  showCancelButton: true,
                  cancelButtonColor: "#d33",
                  cancelButtonText: "Report error",
                }).then((result) => {
                  if (result.dismiss) {
                    this.showFeedback("Report", "Please describe your actions leading up to the error", error.data.errorId);
                  }
                });
              } else {
                this.$swal({
                  type: 'error',
                  title,
                  text: errorMessage
                });
              }
            }
          }, delay);
        },
        showFeedback: function(title, message, errorId = null) {
          return this.$swal({
            title,
            text: message,
            input: "textarea",
            showCancelButton: true,
            inputPlaceholder: "..."
          }).then((result) => {
            if (result.value) {
              if (title == "Report") {
                this.$http.post(this.$config.aimsAPI + "error/report/" + errorId, [title, result.value])
                .then(success => {
                  this.showSuccess("Report Email Success");
                }, error => {
                  this.showError("Error Emailing Report", error);
                });
              } else if (title == "Feedback") {
                this.$http.post(this.$config.aimsAPI + "users/feedback", [title, result.value])
                .then(success => {
                  this.showSuccess("Feedback Email Success");
                }, error => {
                  this.showError("Error Emailing Feedback", error);
                });
              }
            }
          });
        },
        showWarning: function (title, warningMessage) {
          if (!this.$swal.isVisible()) {
            this.$swal({
              type: 'warning',
              title,
              text: warningMessage
            });
          }
        },
        getCurrentPeriod: function () {
          return moment().format('YYYYMM');
        },
        getWeek(date) {
          return moment(date).format('w');
        },
        getDay(date) {
          if (date.includes('1970-01-01')) {
            return 0;
          }
          return moment(date).format('D');
        },
        getNumDays: function (date) {
          return moment().diff(date, 'hours')
        },
        backendLogout(reason) {
          this.$http.put(this.$config.aimsAPI + "users/logout").then(success => {
            this.logout(reason);
          }, error => {
            this.logout(reason);
            console.error(error);
          });
        },
        logout: function (reason) {
          const currentRoute = this.$route;

          this.$localStorage.remove('aims-token');
          this.clearCustomInterceptors();

          const loginRoute = {
            name: 'login',
            params: {
              fromRoute: {
                name: currentRoute.name,
                params: currentRoute.params,
                query: currentRoute.query,
              }
            }
          };

          if (reason) {
            const reasonSplit = reason.split('=');
            loginRoute.query = {};
            loginRoute.query[reasonSplit[0]] = reasonSplit[1];
          }

          this.$router.replace(loginRoute);
        },
        dateToPeriod: function (date) {
          return moment(date).format('YYYYMM');
        },
        getDate(date) {
          let utcDate = new Date(date).toUTCString();
          return utcDate;
        },
        getTime: function (dur) {
          var time = moment.duration(dur, "seconds").humanize();
          if (time.includes('minute') || time.includes('second')) {
            return 'text-danger text-bold'
          }
        },
        prettifyBillingPeriod: function (period) {
          if (!period) {
            return 0;
          }
          var Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
          var year = ('' + period).substring(0, 4);
          var month = Months[('' + period).substring(4, 6) - 1];
          return month + ' ' + year;
        },
        increaseBillingPeriod: function (period) {
          var intPeriod = parseInt(period);
          if (intPeriod % 100 >= 12) {
            return ((intPeriod + 100) - 12) + 1;
          } else {
            return intPeriod + 1;
          }
        },
        decreaseBillingPeriod: function (period) {
          var intPeriod = parseInt(period);
          if (intPeriod % 100 == 1) {
            return ((intPeriod - 100) + 12) - 1;
          } else {
            return intPeriod - 1;
          }
        },
        sameMonth: function (date1, date2) {
          return date1.getMonth() == date2.getMonth() && date1.getYear() == date2.getYear();
        },
        deepCopyObject: function (objectToCopy) {
          return JSON.parse(JSON.stringify(objectToCopy));
        },
        distinctList: function (list, key) {
          var distinct = [];

          list.map(l => {
            const found = distinct.find(d => d[key] == l[key]);
            if (!found) {
              distinct.push(l);
            }
          });
          return distinct;
        },
        orderList: function (list, key) {
          return list.sort((l1, l2) => l1[key] - l2[key]);
        },
        copyText: function (value) {
          this.$copyText(value).then(() => {
            this.showSuccess("Copy to clipboard successful");
          }, error => {
            this.showError(error);
          })
        },
        promptForRefresh() {
          this.versionUpdated = true;
        },
        versionCheck() {
          var lastVersionCheck = this.$localStorage.get('lastVersionCheck');
          this.lastVersionChecked = JSON.parse(this.$localStorage.get('lastVersionCheck'));
          var checkWithServer = false;
          if (lastVersionCheck) {
            //only check once a day
            checkWithServer = false;
            lastVersionCheck = JSON.parse(lastVersionCheck);

            const today = new Date();
            const lastVersionCheckDate = new Date(lastVersionCheck.date);
            checkWithServer = (lastVersionCheckDate.getFullYear() === today.getFullYear() &&
              lastVersionCheckDate.getMonth() === today.getMonth() &&
              lastVersionCheckDate.getDate() === today.getDate()) == false; //same day
          } else {
            checkWithServer = true;
          }

          if (checkWithServer) {
            this.$http.get(this.$config.aimsAPI + 'system/versions/latest')
              .then(response => {

                if (lastVersionCheck) {
                  if (lastVersionCheck.version != response.data) {
                    this.promptForRefresh();
                  }
                } else {
                  this.promptForRefresh();
                }
                lastVersionCheck = {
                  date: new Date(),
                  version: response.data
                };
                this.$localStorage.set('lastVersionCheck', JSON.stringify(lastVersionCheck))
              }, error => {
                console.error(error);
                this.showError("Error Getting App Version", error);
              });
          }
        },
        getClientName(client) {
          let clientName = "";
          switch (client.type) {
            case "Corporate":
            case "Reseller":
              clientName += client.companyname;
              break;
            case "Private":
              clientName += client.firstnames + " " + client.lastname;
              break;
            case "Client of Reseller":
              if (client.companyname && client.companyname.length > 0) {
                clientName += client.companyname;
              } else {
                clientName += client.firstnames + " " + client.lastname;
              }
              break;
            default:
              break;
          }
          return clientName + " [" + client.clientNumber + "]";
        },
        getStatusClass(status) {
          switch (status) {
            case "Cancelled":
              return "badge bg-danger";
            case "New Request":
              return "badge bg-info";
            case "Consigned":
            case "Waiting Consignment":
              return "badge bg-warning";
            case "Waiting on Delivery":
              return "badge bg-primary";
            case "Delivered":
            case "Active":
              return "badge bg-green";
            case "Ordered":
              return "badge bg-success";
            default:
              return "";
          }
        },
      }
    });
  },
  methods: {
    async getVatRate() {
      try {
        const response = await this.$http.get(this.$config.aimsAPI + "system/vatrate");
        return response.data;
      } catch(error) {
        console.error(error);
      }
    }
  },
}).$mount("#app");