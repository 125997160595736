<template>
    <service-screen-layout :parts="parts">
        <template v-slot:account>
            <CyberRiskAwareDetails 
                :saleDefinition="saleDefinition"
                @saveSale="saveSale"
            />
        </template>
        <template v-slot:licences>
            <CyberRiskAwareLicences 
                :saleDefinition="saleDefinition"
                @reloadSale="getSaleDefinition"
            />
        </template>
    </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout"

import CyberRiskAwareDetails from '@/components/ObjParts/SingleCaptured/CyberRiskAwareDetails';
import CyberRiskAwareLicences from '@/components/ObjParts/MultipleCaptured/CyberRiskAwareLicences';

export default {
    components: {
        ServiceScreenLayout,
        CyberRiskAwareDetails,
        CyberRiskAwareLicences
    },
    data() {
        return {
            saleNumber: null,
            parts: [
                {
                    name: "account",
                    icon: "fa fa-info-circle",
                    displayName: "Details",
                    permissions: ["*"],
                },
                {
                    name: "licences",
                    icon: "fa-regular fa-rectangle-list",
                    displayName: "Licences",
                    permissions: ["STAFF"],
                },
            ],
            saleDefinition: {
                sale: {
                    cyberRiskAware: {
                        cyberRiskAwarePackage: {

                        },
                        cyberRiskAwareLicences: []
                    },
                },
                meta: {
                    cyberRiskAware: {
                        children: {
                            cyberRiskAwareLicences: {
                                objMeta: {
                                    new: {
                                        
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    mounted() {
        this.saleNumber = this.$route.params.saleNumber;        
        this.getSaleDefinition();
    },
    methods: {
        getSaleDefinition: function() {
            const hostUrl = this.$config.aimsAPIv2;
            const saleNumber = this.saleNumber;

            this.$http.get(`${hostUrl}sales/definition/sale/${saleNumber}`).then(
                (response) => {
                    this.saleDefinition = response.data;                    
                },
                (error) => {
                    console.error(error);
                    this.showError('Error fetching Sale Definition', error);
                }
            )
        },
        saveSale: function(data) {            
            const hostUrl = this.$config.aimsAPIv2;
            const saleNumber = this.saleNumber;

            this.$http.put(`${hostUrl}sales/definition/${saleNumber}/update`, data).then(
                (response) => {
                    this.showSuccess("Save Success");
                    this.getSaleDefinition();
                },
                (error) => {
                    console.error(error);
                    this.showError("Error Saving Sale", error)
                }
            )
        }
    },
}
</script>
