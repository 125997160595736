<template>
  <div class="row">
    <div class="col-xl-8 col-lg-12">
      <div class="card card-default">
        <form-wizard
          title=""
          subtitle=""
          @on-complete="onComplete"
          stepSize="sm"
        >
          <tab-content
            title="Domain Hosting"
            :before-change="() => validateStep('domain-hosting')"
          >
            <form novalidate data-vv-scope="domain-hosting">
              <div class="row">
                <div class="col-xl-6">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('domain-hosting.domainavailable'),
                    }"
                  >
                    <label class="control-label">Name *</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="filledInSaleDefinition.requiredObjs.hosting.name"
                    />
                    <span
                      class="has-error"
                      v-if="errors.has('domain-hosting.domainavailable')"
                      >{{
                        errors.first("domain-hosting.domainavailable")
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </form>
          </tab-content>
        </form-wizard>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Address from "@/components/GeneralComponents/Address";

export default {
  computed: {},
  components: {
    FormWizard,
    TabContent,
    Address,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filledInSaleDefinition: {
        requiredObjs: {
          hosting: {},
        },
      },
    };
  },
  mounted: function () {
    this.filledInSaleDefinition = this.emptySaleDefinition;
  },
  methods: {
    onComplete() {
      this.$swal({
        title: "Add " + this.getFqdn(),
        text:
          "Are you sure you want to add Hosting for " + this.getFqdn() + "?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Add Hosting",
      }).then((result) => {
        if (result.value) {
          const saleDefCopy = this.deepCopyObject(this.filledInSaleDefinition);

          //fully qualified domain name
          saleDefCopy.requiredObjs.hosting.name =
            saleDefCopy.requiredObjs.hosting.name = this.getFqdn();

          this.$emit("createSale", saleDefCopy);
        } else {
        }
      });
    },
    getFqdn() {
      return this.filledInSaleDefinition.requiredObjs.hosting.name;
    },
    validateStep(stepName) {
      return true;
    },
  },
};
</script>