<template>
  <service-screen-layout :parts="parts">
    <template v-slot:faxtoemail>
      <div class="row">
        <div class="col-md-7">
          <div class="form-group row">
            <label class="col-md-4 col-form-label">Fax Number:</label>
            <div class="col-md-7">
              <input
                type="text"
                class="form-control"
                v-model="saleDefinition.definition.faxtoemail.faxnumber.number"
                readonly
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label">Email Address:</label>
            <div class="col-md-7">
              <input
                type="text"
                class="form-control"
                v-model="saleDefinition.definition.faxtoemail.emailAddress"
                readonly
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
  },
  data() {
    return {
      parts: [
        {
          name: "faxtoemail",
          icon: "fa fa-fax",
          displayName: "Fax to Email",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        definition: {
          faxtoemail: {
              faxnumber: {},
          },
        },
      },
    };
  },
  computed: {},
  mounted() {
    this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition: function() {
      const saleNumber = this.$route.params.saleNumber;
      this.getSaleDefinition(saleNumber).then(
        (response) => {
          this.saleDefinition = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Could not load Sale Definition", error);
        }
      );
    },
  },
};
</script>

<style scoped>
</style>