var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl- col-lg-12"},[_c('div',{staticClass:"card card-default"},[_c('form-wizard',{attrs:{"title":"","subtitle":"","stepSize":"sm"},on:{"on-complete":_vm.onComplete}},[_c('tab-content',{attrs:{"title":"Zimbra Mail","before-change":() => _vm.validateStep('zimbra-mail')}},[_c('form',{attrs:{"novalidate":"","data-vv-scope":"domain-domain"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('zimbra-mail.domain-name'),
                  }},[_c('label',{staticClass:"control-label"},[_vm._v("Domain Name *")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(
                      _vm.filledInSaleDefinition.requiredObjs.domain.domainName
                    ),expression:"\n                      filledInSaleDefinition.requiredObjs.domain.domainName\n                    "}],staticClass:"form-control",attrs:{"type":"text","name":"domain-name"},domProps:{"value":(
                      _vm.filledInSaleDefinition.requiredObjs.domain.domainName
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filledInSaleDefinition.requiredObjs.domain, "domainName", $event.target.value)}}}),(_vm.errors.has('zimbra-mail.domain-name'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("zimbra-mail.domain-name")))]):_vm._e()])])])])])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }