<template>
  <div class="card-body">
    <h3>Lte Regrade</h3>
    <div v-if="Object.keys(pendingChangeBundleInstruction).length == 0">
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label>Select Bundle</label>
            <select class="form-control" v-model="bundleSelected">
              <option :value="bundle.lteBundleNumber"
                :disabled="bundle.lteBundleNumber == saleDefinition.definition.account.lteBundleNumber"
                v-for="bundle in saleDefinition.selectableOptions.bundle" :key="bundle.lteBundleNumber">
                {{bundle.description}} - {{bundle.size}} GB
              </option>
            </select>
            <label><i class="fa fa-info-circle"></i> Change will be effective end of this month</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <button :disabled="bundleSelected == saleDefinition.definition.account.lteBundleNumber" type="button"
            class="btn bg-green" @click="confirmSendChangeBundleInstruction()">Save</button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-lg-12">
          <div class="alert bg-green">
            <p><i class="fa fa-info-circle"></i> There is an existing instruction to change this account's bundle.</p>
            <p>Created on <b>{{dateTimeFormat(pendingChangeBundleInstruction.created)}}</b></p>
            <button class="btn btn-danger" type="button" @click="confirmSendCancelBundleChangeInstruction()">Cancel
              Instruction</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data: function () {
      return {
        saleNumber: {},
        bundleSelected: {},
        saleDefinition: {
          selectableOptions: {
            bundle: []
          },
          definition: {
            account: {
              instructions: []
            }
          }
        },
        emptyInstruction: {
          class: "za.co.adept.aims.lte.LteInstruction"
        }
      }
    },
    computed: {
      pendingChangeBundleInstruction: function () {

        var pendingInstruction = {};

        const cancelChangeBundleInstructions = this.saleDefinition.definition.account.instructions.filter(ci => {
          return this.sameMonth(new Date(ci.created), new Date()) && ci.instructionType === 'CANCELCHANGEBUNDLE';
        });

        this.saleDefinition.definition.account.instructions.map(i => {
          if (this.sameMonth(new Date(i.created), new Date()) && i.instructionType === 'CHANGEBUNDLE') {

            const matchingCancelInstructionIndex = cancelChangeBundleInstructions.findIndex(ci => ci
              .lteBundleNumber === i.lteBundleNumber);
            if (matchingCancelInstructionIndex == -1) {
              pendingInstruction = i;
              return;
            }
          }
        });

        return pendingInstruction;
      }
    },
    mounted: function () {
      this.saleNumber = this.$route.params.saleNumber;
      this.fetchSaleDefinition();
    },
    methods: {
      fetchSaleDefinition: function () {
        this.$http.get(this.$config.aimsAPI + "sales/definition/sale/" + this.saleNumber).then(
          response => {
            this.saleDefinition = response.data;
            this.bundleSelected = this.saleDefinition.definition.account.lteBundleNumber;
          },
          error => {
            console.error(error);
            this.showError("Error Fetching Sale Definition", error);
          });
      },
      confirmSendCancelBundleChangeInstruction() {
        this.$swal({
          title: 'Are you sure you?',
          text: 'The billing will change for this client',
          type: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.value) {
            this.sendCancelBundleChangeInstruction();
          }
        });
      },
      sendCancelBundleChangeInstruction: function () {
        var cancelChangeBundleInstruction = this.emptyInstruction;
        //configure instruction
        cancelChangeBundleInstruction.instructionType = 'CANCELCHANGEBUNDLE';
        cancelChangeBundleInstruction.lteAccountNumber = this.saleDefinition.definition.account.lteAccountNumber;
        cancelChangeBundleInstruction.lteBundleNumber = this.pendingChangeBundleInstruction.lteBundleNumber;

        this.createLteInstruction(cancelChangeBundleInstruction);
      },
      confirmSendChangeBundleInstruction() {
        this.$swal({
          title: 'Are you sure you?',
          text: 'The billing will change for this client',
          type: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.value) {
            this.sendChangeBundleInstruction();
          }
        });
      },
      sendChangeBundleInstruction: function () {
        var changeBundleInstruction = this.emptyInstruction;
        //configure instruction
        changeBundleInstruction.instructionType = 'CHANGEBUNDLE';
        changeBundleInstruction.lteBundleNumber = this.bundleSelected;
        changeBundleInstruction.lteAccountNumber = this.saleDefinition.definition.account.lteAccountNumber;
        this.createLteInstruction(changeBundleInstruction);
      },
      createLteInstruction: function (instruction) {
        this.$http.post(this.$config.aimsAPI + "sales/definition/" + this.saleNumber + "/obj/create", instruction)
          .then(
            response => {
              this.showSuccess('Instruction Sent');
              this.saleDefinition.definition.account.instructions.push(response.data);
            },
            error => {
              console.error(error);
              this.showError("Error Changing Bundle", error);
            });
      }
    }
  }

</script>

<style>

</style>
