<template>
  <div>
    <service-screen-layout :parts="parts">
      <template v-slot:service>
        <div class="row">
          <div class="col-md-9">
            <div class="form-group row">
              <label class="col-md-3 col-form-label">Customer Name:</label>
              <div class="col-md-5">
                <input
                  type="text"
                  class="form-control"
                  v-model="saleDefinition.definition.mimecastmail.description"
                  :disabled="!isAuthorizedUser"
                  placeholder="Description"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label">Domain:</label>
              <div class="col-md-5">
                <input
                  type="text"
                  class="form-control"
                  v-model="saleDefinition.definition.mimecastmail.domain"
                  readonly
                  placeholder="Name"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label">Start Date:</label>
              <div class="col-md-5">
                <div class="input-group">
                  <DateInput 
                      type="date" 
                      :disabled="!isStaffUserType && productAccessRestricted"
                      v-model="saleDefinition.definition.mimecastmail.startDate"
                      style="width: 100%"
                  />
                  <div class="input-group-append">
                      <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label">End Date:</label>
              <div class="col-md-5">
                <div class="input-group">
                  <DateInput 
                      type="date" 
                      :disabled="!isStaffUserType && productAccessRestricted"
                      v-model="saleDefinition.definition.mimecastmail.endDate"
                      style="width: 100%"
                  />
                  <div class="input-group-append">
                      <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row" v-allowed:view="['STAFF']">
              <label class="col-md-3 col-form-label">Markup:</label>
              <div class="col-md-3">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="saleDefinition.definition.mimecastmail.markup"
                    placeholder="Name"
                    :disabled="!isAuthorizedUser && !isSeniorAccounts"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-allowed:view="['STAFF']">
              <div class="col-md-12">
                <div class="form-group">
                  <button
                    class="btn btn-success"
                    @click="saveSale()"
                    type="button"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:items>
        <div class="form-group row">
          <label class="col-md-2 col-form-label text-bold"
            >Service Month:</label
          >
          <div class="col-md-3">
            <v-select
              :options="periods"
              placeholder="Select Period"
              v-model="selectedPeriod"
            ></v-select>
          </div>
        </div>
        <div class="row row-spacing">
          <div class="col-md-12">
            <table
              class="table table-bordered"
              v-if="
                saleDefinition.definition.mimecastmail.mimecastitems.length > 0
              "
            >
              <thead>
                <tr>
                  <th>Period</th>
                  <th>Item</th>
                  <th>SKU</th>
                  <th>Quantity</th>
                  <th v-if="isStaffUserType">Cost</th>
                  <th v-if="isStaffUserType">Price (with markup)</th>
                  <th v-else>Price</th>
                  <th v-if="isStaffUserType">Total (with markup)</th>
                  <th v-else>Total</th>
                </tr>
              </thead>
              <tbody v-if="filteredItems.length > 0">
                <tr
                  v-for="item in filteredItems"
                  :key="item.mimecastItemNumber"
                >
                  <td>{{ item.period }}</td>
                  <td>{{ item.item }}</td>
                  <td>{{ item.sku }}</td>
                  <td>{{ item.quantity }}</td>
                  <td v-if="isStaffUserType">{{ currencyFormat(item.unitCostExVat) }}</td>
                  <td>{{ currencyFormat(item.unitPriceExVat) }}</td>
                  <td>{{ currencyFormat(item.totalPriceExVat) }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="text-center" colspan="6">No items to display</td>
                </tr>
              </tbody>
            </table>
            <div v-else class="alert alert-info">
              No Items loaded for
              {{ saleDefinition.definition.mimecastmail.domain }}
            </div>
          </div>
        </div>
      </template>
    </service-screen-layout>
  </div>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";
import DateInput from "@/components/GeneralComponents/InputControls/DateInput";
import Modal from "@/components/GeneralComponents/Modal";
export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    DateInput,
    Modal,
  },
  data() {
    return {
      parts: [
        {
          name: "service",
          icon: "fa fa-info-circle",
          displayName: "Mimecast Service",
          permissions: ["*"],
        },
        {
          name: "items",
          icon: "fa fa-calendar",
          displayName: "Service Items",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        definition: {
          mimecastmail: {
            mimecastitems: [],
          }
        },
      },
      periods: [],
      period: 0,
      selectedPeriod: 0,
      productAccessRestricted: null,
      readOnly: {},
      fullAccess: {},
    };
  },
  mounted() {
    this.loadSaleDefinition();
    this.getProductRestrictions();
  },
  computed: {
    filteredItems() {
      return this.saleDefinition.definition.mimecastmail.mimecastitems.filter(
        (i) => i.period == this.selectedPeriod
      );
    },
    isAuthorizedUser() {
      return this.userContainsPermission(['ADMIN','PRODUCT_MANAGER']);
    },
    isSeniorAccounts() {
      return this.userContainsPermission(['ACCOUNTS_ADMIN']);
    },
    isStaffUserType() {
      return this.userContainsPermission(['STAFF']);
    },
  },
  methods: {
    loadSaleDefinition() {
      const saleNumber = this.$route.params.saleNumber;
      this.getSaleDefinition(saleNumber).then(
        (response) => {
          this.saleDefinition = response.data;
          this.productAccessRestricted = this.saleDefinition.product.clientAccessRestriction === this.readOnly.description ? true : false;
          
          this.getItemPeriods();
          
        },
        (error) => {
          this.showError("Could not load Sale Definition", error);
          console.error(error);
        }
      );
    },
    getProductRestrictions: function() {
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}products/restrictions`).then(
        (response) => {
          const accessRestrictions = response.data;
          this.readOnly = accessRestrictions.find((access) => access.name === 'CLIENT_READONLY');
          this.fullAccess = accessRestrictions.find((access) => access.name === 'CLIENT_USAGE');          
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching product restrictions", error)
        }
      )
    },
    getItemPeriods() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "mimecastservice/" +
            this.saleDefinition.definition.mimecastmail.mimecastServiceNumber +
            "/periods/"
        )
        .then(
          (response) => {
            this.periods = response.data;
          },
          (error) => {
            this.showError("Could not fetch Mimecast Item Periods", error);
            console.error(error);
          }
        );
    },
    saveSale() {
      this.$http
        .put(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/update",
          this.saleDefinition
        )
        .then(
          () => {
            this.showSuccess("Save Success");
            this.loadSaleDefinition();
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving SaleDefinition", error);
          }
        );
    },
  },
};
</script>