<template>
  <div class="card card-default">
    <div class="card-header">
      <h4>MPLS Details</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-form-label">Label: </label>
            <div class="col-md-7">
              <slot name="label"></slot>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-form-label">VLAN Name: </label>
            <div class="col-md-7">
              <input type="text" class="form-control" v-model="lan.vlanName" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-form-label">VLAN ID: </label>
            <div class="col-md-7">
              <input type="text" class="form-control" v-model="lan.vlanId" />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-form-label">VRF: </label>
            <div class="col-md-7">
              <input type="text" class="form-control" v-model="lan.vrfName" />
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-allowed:view="['STAFF']">
        <div class="col-md-12">
          <div class="form-group">
            <button
              class="btn btn-success pull-right"
              @click="$emit('saveSale')"
              type="button"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lan: {
      type: Object,
      required: true,
    },
  },
};
</script>