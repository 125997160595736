<template>
<div class="card card-default">
    <div class="card-body">
        <div class="row">
            <div class="col-md-2 mb-3">
                <v-select :options="timePeriods" @input="getDomainsExpiring()" v-model="timePeriod" label="name" valueProp="days"></v-select>
            </div>
        </div>
        <table class="table table-bordered table-hover" v-if="domains.length > 0">
            <thead>
                <tr>
                    <th>Client</th>
                    <th>Sale Number</th>
                    <th>Domain</th>
                    <th>Expiry Date</th>
                    <th>Auto Renew</th>
                    <th>Locked</th>
                </tr>
            </thead>
            <tbody>
                <tr @click="viewSale(domain.saleNumber)" v-for="(domain, index) in domains" :key="index">
                    <td>{{getClient(domain)}}</td>
                    <td>{{domain.saleNumber}}</td>
                    <td>{{domain.name}}</td>
                    <td>
                        {{dateTimeFormat(domain.expiryDate)}}
                        ({{momentAgo(domain.expiryDate)}})
                    </td>
                    <td>{{domain.autoRenew}}</td>
                    <td>{{domain.isLocked}}</td>
                </tr>
            </tbody>
        </table>
        <div class="alert alert-info col-sm-12" v-else>
            <strong>No domains expiring in {{period}}</strong>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            domains: [],
            timePeriod: 7,
            timePeriods: [
                {
                    name: '1 week',
                    days: 7
                },
                {
                    name: '1 month',
                    days: 30
                },
                {
                    name: '2 months',
                    days: 60
                },
                {
                    name: '3 months',
                    days: 90
                }
            ]
        }
    },
    created() {
        document.title = this.$route.meta.title;
        this.$root.$emit('SET_PAGE_TITLE', this.$route.meta.title);
    },
    async mounted() {
        await this.getDomainsExpiring();
    },
    methods: {
        getDomainsExpiring() {
            this.$http.get(`${this.$config.aimsAPI}domains/registrations/renewal/upcoming/${this.timePeriod}`)
                .then(response => {
                    this.domains = response.data;
                },
                error => {
                    this.showError('Error fetching upcoming domain expiries', error);
                    console.error(error);
                })
        },
        viewSale: function (saleNumber) {
            this.$http.get(this.$config.aimsAPI + "sales/" + saleNumber)
                .then(response => {
                    const saleProduct = response.data.product;
                    const saleClient = response.data.client
                    const productRoute = this.getProductDefinition(saleProduct.productDefinition);

                    if (productRoute == -1) {
                        this.showWarning(
                            "Could not view sale #" + saleNumber,
                            "'" + saleProduct.description + "' is not supported yet."
                        );
                    } else {
                        this.$router.push({
                            name: productRoute.routeName,
                            params: {
                                clientNumber: saleClient.clientNumber,
                                saleNumber: saleNumber
                            }
                        });
                    }
                },
                error => {
                    console.error(error);
                    this.showError("Error Fetching Product for Sale", error);
                });
        },
        getClient(domain) {
            const client = domain.name.split('.');
            return client[0];
        }
    },
    computed: {
        period() {
            return this.timePeriods.filter(p => p.days == this.timePeriod)[0].name;
        }
    }
}
</script>
