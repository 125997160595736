<template>
  <div class="card card-default" style="margin-bottom:12em;">
    <div class="card-header">
      <div class="row justify-content-between">
        <div class="col-md-10">
          <div class="row mt-3">
            <div class="col-auto">
              <DateInput placeholder="Select start date" v-model="startDate" :disabled="loadingLines"/>
            </div>
            <div class="col-md-2">
              <v-select :clearable="true" placeholder="Select status" :options="statuses" v-model="status" :disabled="loadingLines"></v-select>
            </div>
            <div class="col-md-4 input-group">
              <input type="text" class="form-control" v-model="searchTerm" placeholder="Enter search term" v-on:keyup.enter="searchBankStatementLines()" :disabled="loadingLines">
              <div class="input-group-append">
                <button class="btn btn-danger" @click="searchBankStatementLines()" :disabled="loadingLines"><i class="fa-solid fa-magnifying-glass"></i></button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-auto mt-3">
          <button class="btn btn-info pull-right" @click="$router.push({name: 'bankstatementupload'})">Upload</button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <PageNavigator
        :disableControls="loadingLines"
        :currentPage="pageNumber"
        :totalPages="totalPages"

        @nextPage="nextPage"
        @previousPage="previousPage"
        @firstPage="firstPage"
        @lastPage="lastPage"
      ></PageNavigator>
      <div class="mt-2 row-spacing table-responsive table-bordered" v-if="bankstatementlines.length > 0 && !loadingLines" style="padding-top: 0; margin-top: 0;">
        <table class="table">
          <thead>
            <tr>
              <th class="round-left-top">#</th>
              <th>Date</th>
              <th>Status</th>
              <th>Comment</th>
              <th>Amount</th>
              <th>Client</th>
              <th>Invoice</th>
              <th class="round-right-top">Transaction</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="line in bankstatementlines" :key="line.bankStatementLineNumber">
              <td style="text-align:center">
                <span @click="viewStatement(line)" class="btn btn-link">{{line.bankStatementLineNumber}}</span>
              </td>
              <td>{{dateFormat(line.lineDate)}}</td>
              <td>{{line.status}}</td>
              <td>{{line.comment}}</td>
              <td>{{currencyFormat(line.amount)}}</td>
              <td style="text-align:center">
                <span @click="$router.push({name: 'clientsales', params:{clientNumber: line.clientNumber}})"
                  class="btn btn-link">
                  {{line.clientNumber ? line.clientNumber : ''}}
                </span>
              </td>
              <td>{{line.invoiceNumber ? line.invoiceNumber : ''}}</td>
              <td>{{line.transactionNumber ? line.transactionNumber : ''}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="loadingLines" style="padding: 10em;text-align: center;">
        <loading :active="true" :can-cancel="false"></loading>
      </div>
      <div class="mt-3 alert alert-info" v-else>
        <strong>No bank statement lines from {{dateFormat(startDate)}} to
          {{dateFormat(new Date().toISOString())}}</strong>
      </div>
      <PageNavigator
        class="mt-3"
        :disableControls="loadingLines"
        :currentPage="pageNumber"
        :totalPages="totalPages"

        @nextPage="nextPage"
        @previousPage="previousPage"
        @firstPage="firstPage"
        @lastPage="lastPage"
      ></PageNavigator>
    </div>
    <modal :title="'Bank Statement #' + statementLine.bankStatementLineNumber" ref="bankStatementModal" size="modal-lg">
      <div class="row">
        <div class="form-group col-md-12">
          <div class="row align-items-center">
            <label class="col-md-3 m-0">Statement Line Number</label>
            <input type="text" class="form-control-plaintext col-md-8" disabled
              v-model="statementLine.bankStatementLineNumber">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12">
          <div class="row align-items-center">
            <label class="col-md-3 m-0">Status</label>
            <input type="text" class="form-control-plaintext col-md-8" disabled v-model="statementLine.status">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12">
          <div class="row align-items-center">
            <label class="col-md-3 m-0">Client match</label>
            <input type="text" class="form-control-plaintext col-md-8" disabled v-if="statementLine.clientNumber"
              v-model="statementLine.client.toString">
            <!-- <v-select placeholder="Select Client to Match" :searchable="true"
              v-else-if="statementLine.clients && statementLine.clients.length > 0" class="col-md-7"
              :options="statementLine.clients" label="toString" @input="clientSelected"></v-select> -->
            <ObjAutocomplete class="col-md-8" v-else @objSelected="clientSelected" objClass="za.co.adept.aims.classes.Client"
              :startingObject="statementLine.client" />
            <!-- <div v-if="statementLine.clients && statementLine.clients.length > 0 && statementLine.clientNumber == 0">
              <span class="badge badge-green" style="cursor: pointer" @click="statementLine.clients = []">Clear</span>
            </div> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12">
          <div class="row align-items-center">
            <label class="col-md-3 m-0">Document match</label>
            <input type="text" class="form-control-plaintext col-md-8" disabled v-if="statementLine.invoiceNumber"
              v-model="statementLine.document.toString">
            <v-select placeholder="Select Document to Match" :searchable="true"
              v-else-if="statementLine.documents && statementLine.documents.length > 0" class="col-md-7"
              :options="statementLine.documents" label="toString" @input="documentSelected"></v-select>
            <ObjAutocomplete class="col-md-8" v-else @objSelected="documentSelected"
              objClass="za.co.adept.aims.classes.billing.Document" :startingObject="statementLine.document" />
            <div
              v-if="statementLine.documents && statementLine.documents.length > 0 && statementLine.invoiceNumber == 0">
              <span class="badge badge-green" style="cursor: pointer" @click="statementLine.documents = []">Clear</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12">
          <div class="row align-items-center">
            <label class="col-md-3 m-0">Statement Comment</label>
            <input type="text" class="form-control-plaintext col-md-8" disabled v-model="statementLine.comment">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12">
          <div class="row align-items-center">
            <label class="col-md-3 m-0">Amount</label>
            <input type="text" class="form-control-plaintext col-md-8" disabled v-model="statementLine.amount">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12">
          <div class="row align-items-center">
            <label class="col-md-3 m-0">Line Date</label>
            <input type="text" class="form-control-plaintext col-md-8" disabled v-model="statementLine.lineDate">
          </div>
        </div>
      </div>
      <div slot="footer">
        <div class="row">
          <div class="col-md-12">
            <button type="button" class="btn btn-primary ml-2" @click="processStatement()">
              Process
            </button>
            <button type="button" :disabled="statementLine.status != 'NEW'" class="btn btn-default ml-2"
              @click="unmatchStatement()">
              Unmatched
            </button>
            <button type="button" :disabled="statementLine.status == 'NEW'" class="btn btn-default ml-2"
              @click="statementLine.status == 'MATCHED'? getReference() : reverseStatement()">
              Reverse
            </button>
            <button @click="statementLine = {}" type="button" class="btn btn-default ml-2" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </modal>
    <modal :title="'Bank Statement #' + statementLine.bankStatementLineNumber" ref="referenceModal" save-text="Reverse"
      @save="reverseStatement()" dismiss-text="Close">
      <div class="row" v-if="statementLine.transaction">
        <div class="form-group col-md-12">
          <label>Reference</label>
          <input type="text" class="form-control" v-model="statementLine.transaction.extRefrence">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12">
          <label>Ticket Number</label>
          <input type="text" class="form-control">
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import ObjAutocomplete from '@/components/GeneralComponents/InputControls/ObjAutocomplete'
  import DateInput from '@/components/GeneralComponents/InputControls/DateInput.vue'
  import PageNavigator from '@/components/GeneralComponents/PageNavigator'
  import Modal from '@/components/GeneralComponents/Modal'
  import { Circle } from "vue-loading-spinner";

  export default {
    components: {
      loading: Circle,
      ObjAutocomplete,
      PageNavigator,
      DateInput,
      Modal
    },
    data() {
      return {
        bankstatementlines: [],
        startDate: '',
        status: null,
        searchTerm: '',
        statuses: [],
        statementLine: {},
        reference: 'Incorrectly allocated payment',
        loadingLines: true,

        pageNumber: 0,
        pageSize: 100,
        totalPages: 0,
      }
    },
    mounted() {
      this.getStatuses();
      this.getLatestBankStatementLines();
    },
    methods: {
      getLatestBankStatementLines() {
        this.firstPage();
        this.loadingLines = true;
        this.$http.get(this.$config.aimsAPI + 'bankstatement/lines/page/' + this.pageNumber + '/size/' + this.pageSize).then(
          response => {
            this.totalPages = response.data.totalPages;
            this.bankstatementlines = response.data.data;
            this.bankstatementlines.forEach(line => {
              if (!this.statuses.includes(line.status)) {
                this.statuses.push(line.status);
              }
              if (line.invoiceNumber) {
                this.$http.get(this.$config.aimsAPI + 'documents/' + line.invoiceNumber)
                  .then(response => {
                      line.clientNumber = response.data
                    },
                    error => {
                      console.error(error);
                      this.showError("Error fetching linked client", error);
                    });
              }
            })
            this.loadingLines = false;
          },
          error => {
            this.showError('Error fetching bank statement lines', error);
            console.error(error)
          })
      },
      getBankStatementLines() {
        this.loadingLines = true;
        let queryParams = this.getExtraParams();
        this.$http.get(this.$config.aimsAPI + 'bankstatement/lines/page/' + this.pageNumber + '/size/' + this.pageSize + queryParams).then(
          response => {
            this.totalPages = response.data.totalPages;
            this.bankstatementlines = response.data.data;
            this.bankstatementlines.forEach(line => {
              if (!this.statuses.includes(line.status)) {
                this.statuses.push(line.status);
              }
              if (line.invoiceNumber) {
                this.$http.get(this.$config.aimsAPI + 'documents/' + line.invoiceNumber)
                  .then(response => {
                      line.clientNumber = response.data
                    },
                    error => {
                      console.error(error);
                      this.showError("Error fetching linked client", error);
                    });
              }
            })
            this.loadingLines = false;
          },
          error => {
            this.showError('Error fetching bank statement lines', error);
            console.error(error)
          })
      },
      getStatuses() {
        this.$http.get(this.$config.aimsAPI + 'bankstatement/statuses').then(
          response => {
            this.statuses = response.data;
          },
          error => {
            this.showError('Error fetching bank statement statuses', error);
            console.error(error)
          })
      },
      viewStatement(line) {
        this.$http.get(this.$config.aimsAPI + 'bankstatement/lines/' + line.bankStatementLineNumber)
          .then(response => {
              this.statementLine = response.data;
            },
            error => {
              this.showError('Error fetching bank statement line', error)
              console.error(error);
            })
        this.$refs.bankStatementModal.open();
      },
      getReference() {
        this.statementLine.transaction.extRefrence = 'Incorrectly allocated payment';
        this.$refs.referenceModal.open();
      },
      reverseStatement() {
        this.$refs.bankStatementModal.isLoading();
        this.$refs.referenceModal.isLoading();
        this.$http.put(this.$config.aimsAPI + 'bankstatement/lines/' + this.statementLine.bankStatementLineNumber +
          '/reverse?reference=' + this.statementLine.transaction.extRefrence).then(() => {
            this.getBankStatementLines();
            this.showSuccess("Successfully reversed bank statement");
            this.$refs.bankStatementModal.close();
            this.$refs.referenceModal.close();
            this.statementLine = {};
          },
          error => {
            this.showError('Error reversing bank statement', error);
            console.error(error);
          }).finally(() => {
            this.$refs.bankStatementModal.isLoading();
            this.$refs.referenceModal.isLoading();
          });
      },
      processStatement() {
        this.$refs.bankStatementModal.isLoading();
        this.$http.put(this.$config.aimsAPI + 'bankstatement/lines/' + this.statementLine.bankStatementLineNumber +
            '/process?clientNumber=' + this.statementLine.clientNumber + '&documentNumber=' + this.statementLine
            .invoiceNumber)
          .then(() => {
              this.$refs.bankStatementModal.close();
              this.getBankStatementLines();
              this.showSuccess("Successfully processed bank statement");
              this.statementLine = {};
            },
            error => {
              this.showError('Error processing bank statement', error);
              console.error(error);
            }).finally(() => {
              this.$refs.bankStatementModal.isLoading();
            });
      },
      unmatchStatement() {
        this.$http.put(this.$config.aimsAPI + 'bankstatement/lines/' + this.statementLine.bankStatementLineNumber +
          '/unmatched').then(() => {
            this.getBankStatementLines();
            this.showSuccess("Successfully unmatched bank statement");
            this.statementLine = {};
          },
          error => {
            this.showError('Error unmatching bank statement', error);
            console.error(error);
          })
      },
      clientSelected(client) {
        this.statementLine.client = client;
        this.statementLine.clientNumber = client.clientNumber;
      },
      documentSelected(document) {
        this.statementLine.document = document;
        this.statementLine.invoiceNumber = document.documentNumber;
      },
      searchBankStatementLines() {
        this.firstPage();
        this.getBankStatementLines();
      },
      getExtraParams() {
        let extra = '';
        let exCount = 0;
        if (this.startDate) {
          if (exCount == 0) {
            extra = extra + '?startDate=' + this.startDate;
          } else {
            extra = extra + '&startDate=' + this.startDate;
          }
          exCount++;
        }
        if (this.searchTerm) {
          if (exCount == 0) {
            extra = extra + '?searchInput=' + this.searchTerm;
          } else {
            extra = extra + '&searchInput=' + this.searchTerm;
          }
          exCount++;
        }
        if (this.status) {
          if (exCount == 0) {
            extra = extra + '?status=' + this.status;
          } else {
            extra = extra + '&status=' + this.status;
          }
          exCount++;
        }
        return extra;
      },
      firstPage() {
        this.pageNumber = 0;
      },
      nextPage(num) {
        this.pageNumber = this.pageNumber + num;
      },
      previousPage(num) {
        this.pageNumber = this.pageNumber - num;
      },
      lastPage() {
        this.pageNumber = this.totalPages;
      },
    },
    created() {
      this.$root.$emit('SET_PAGE_TITLE', this.$route.meta.title);
    },
    watch: {
      startDate(date) {
        this.firstPage();
        this.getBankStatementLines();
      },
      pageNumber(num) {
        this.getBankStatementLines();
      },
      status(bla) {
        this.firstPage();
        this.getBankStatementLines();
      }
    }
  }

</script>

<style>
    .table>thead>tr>th {
        padding: 1em;
        background-color: #3a3f51;
        color: #FFFFFF;

        position: sticky;
        top: 0;
        z-index: 2;
    }

    .round-left-top {
      border-top-left-radius: 6px;
    }

    .round-right-top {
      border-top-right-radius: 6px;
    }
</style>
