<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template slot="liquidsubscription">
            <form novalidate data-vv-scope="liquidsubscription" class="col-md-6">
              <div 
               class="form-group"
               :class="{'has-error': errors.has('customer[0].customerAccountId')}"
              >
                <label>Liquid Customer Account Id</label>
                <v-select v-model="filledInSaleDefinition.requiredObjs.subscription.liquidCustomerNumber" :options="filledInSaleDefinition.selectableOptions.customer" valueProp="liquidCustomerNumber" label="customerAccountId" placeholder="Select Customer Account ID"></v-select>
                 <span
                  class="has-error"
                  v-if="errors.has('customer[0].customerAccountId')"
                >
                  {{ errors.first("customer[0].customerAccountId") }}
                </span>
              </div>
              
                <div
                    class="form-group"
                    :class="{'has-error': errors.has('subscription.subscriptionId')}"
                >
                <label>Liquid Subscription Id</label>
                <input
                  type="text"
                  class="form-control"
                  v-validate="'required'"
                  name="Id"
                  placeholder="Liquid Subscription Id"
                  v-model="filledInSaleDefinition.requiredObjs.subscription.subscriptionId"
                />
                <span
                  class="has-error"
                  v-if="errors.has('subscription.subscriptionId')"
                >
                  {{ errors.first("subscription.subscriptionId") }}
                </span>
                </div>                


              <div
                class="form-group"
                :class="{'has-error': errors.has('subscription.subscriptionName')}"
              >
                <label>Liquid Subscription Name</label>
                <input
                  type="text"
                  class="form-control"
                  v-validate="'required'"
                  name="Name"
                  placeholder="Liquid Subscription Name"
                  v-model="filledInSaleDefinition.requiredObjs.subscription.subscriptionName"
                />
                <span
                  class="has-error"
                  v-if="errors.has('subscription.subscriptionName')"
                >
                  {{ errors.first("subscription.subscriptionName") }}
                </span>
              </div>

              <div
                class="form-group"
                :class="{'has-error': errors.has('subscription.billingCycle')}"
              >
                <label>Billing Cycle</label>
                <v-select
                  :options="filledInSaleDefinition.supportingEnums.subscription.billingCycle"
                  v-validate="'required'"
                  name="billingCycle"
                  label="description" 
                  v-model="filledInSaleDefinition.requiredObjs.subscription.billingCycle"
                  valueProp="name"
                >
                </v-select>
                <span
                  class="has-error"
                  v-if="errors.has('subscription.billingCycle')"
                >
                  {{ errors.first("subscription.billingCycle") }}
                </span>
              </div>

            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";

export default {
  components: {
    ServiceWizardLayout,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "liquidsubscription",
          displayName: "Liquid Subscription",
          icon: "fa fa-info",
        },
      ],
      filledInSaleDefinition: {
        requiredObjs: {
          subscription: {

          },
        },
        selectableOptions: {
          customer: []
        },
        supportingEnums: {
          subscription: {
            billingCycle: []
          }
        }
      },
      liquidCustomers: {},
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;    
  },
  methods: {
    onComplete: function() {      
      this.$emit("createSale", this.filledInSaleDefinition);
    },
    validateStep: function(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
</style>