<template>
    <modal
      :title="
        pricePackage.layer3PackageNumber
          ? 'Edit Package'
          : 'Add New Package'
      "
      ref="modifyPriceModal"
      size="modal-lg"
      @save="savePricePackage()"
    >
      <div class="row">
        <div class="form-group col-md-10">
          <label class="control-label"> Description </label>
          <input
            disabled
            type="text"
            class="form-control"
            v-model="pricePackage.description"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-10">
          <label class="control-label"> Label </label>
          <input
            type="text"
            class="form-control"
            v-model="pricePackage.label"
          />
        </div>
      </div>
      <div class="row">
        <div
          class="form-group col-md-10"
          v-if="pricePackage.layer3PackageNumber"
        >
          <label class="control-label"> Expired </label>
          <v-select :options="[true, false]" v-model="expired"></v-select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-10">
          <label class="control-label"> Download Speed </label>
          <div class="input-group">
            <input type="text" v-model="downloadSpeed" class="form-control" />
            <div class="input-group-append">
              <span class="input-group-text">Mbit/s</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-10">
          <label class="control-label"> Upload Speed </label>
          <div class="input-group">
            <input type="text" v-model="uploadSpeed" class="form-control" />
            <div class="input-group-append">
              <span class="input-group-text">Mbit/s</span>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </template>
  
  <script>
  import Modal from "@/components/GeneralComponents/Modal";
  export default {
    props: {
      pricePackage: {
        type: Object,
        required: true,
      },
    },
    computed: {
      expired: {
        get() {
          return this.pricePackage.expired.toString();
        },
        set(expired) {
          this.pricePackage.expired = expired;
        },
      },
      downloadSpeed: {
        get() {
          return this.speed_bitsToMbits(
            this.pricePackage.downloadSpeed
          );
        },
        set(downloadSpeed) {
          this.pricePackage.downloadSpeed = this.speed_mbitsToBits(
            downloadSpeed
          );
        },
      },
      uploadSpeed: {
        get() {
          return this.speed_bitsToMbits(
            this.pricePackage.uploadSpeed
          );
        },
        set(uploadSpeed) {
          this.pricePackage.uploadSpeed = this.speed_mbitsToBits(
            uploadSpeed
          );
        },
      },
    },
    components: {
      Modal,
    },
    methods: {
      open() {
        this.$refs.modifyPriceModal.open();
      },
      getLabel({ name }) {
        return name;
      },
      savePricePackage() {
        this.$refs.modifyPriceModal.isLoading();
        if (this.pricePackage.layer3PackageNumber) {
            this.showSuccess("Successfully Edited Package");
            this.$emit("packageEdited", this.pricePackage);
        } else {
            this.showSuccess("Successfully Added Package");
            this.$emit("packageAdded", this.pricePackage);
        }
        this.$refs.modifyPriceModal.close();
        this.$refs.modifyPriceModal.isLoading();
      },
    },
  };
  </script>
  
  <style>
  </style>
  