<template>
    <div>
        <div class="row">
            <div class="col-md-12"> 
                <button v-if="userEditRight" type="button" @click="addPackage()" class="btn btn-info">Add Package</button>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-md-12">
                <div class="table-responsive table-bordered" v-if="packages.length > 0">
                    <table class="table col-md-8">
                        <thead>
                            <tr class="text-nowrap">
                                <th>Description</th>
                                <th>Label</th>
                                <th>Download / Upload</th>
                                <th>NRC (excl. VAT)</th>
                                <th>MRC (excl. VAT)</th>
                                <th>Expired</th>
                                <th v-if="userEditRight"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :class="{ 'bg-primary': p.expired }" v-for="p in packages" :key="p.layer3PackageNumber">
                                <td>
                                    {{p.description}}
                                </td>
                                <td>
                                    {{p.label}}
                                </td>
                                <td>
                                  <span class="pull-right"
                                    >{{ speed_bitsToMbits(p.downloadSpeed)}} /
                                    {{ speed_bitsToMbits(p.uploadSpeed) }}
                                    Mbit/s</span
                                  >
                                </td>
                                <td class="text-nowrap">
                                  {{
                                    getSetupFee(p.prices,period)
                                  }}
                                </td>
                                <td>
                                    {{getPrice(p.prices, period)}}
                                    <span v-if="userEditRight" style="cursor: pointer" v-tooltip="'Edit Row Price'" @click="openPriceModal(p)"
                                        class="pull-right">
                                        <em class="fa fa-edit fa-fw text-green"></em>
                                    </span>
                                </td>
                                <td class="text-center" style="width:1em">
                                    <span
                                        class="badge"
                                        :class="p.expired ? 'badge-warning' : 'badge-green'"
                                        >{{ p.expired }}
                                    </span>
                                </td>
                                <td class="text-center" v-if="userEditRight">
                                    <button v-tooltip="'Edit Package'" type="button" @click="editPackage(p)"
                                        class="btn btn-sm btn-info m-1">
                                        <em class="fa fa-edit fa-fw"></em>
                                    </button>
                                    <button v-tooltip="'Delete Package'" type="button" @click="deletePackage(p)"
                                        class="btn btn-sm btn-primary">
                                        <em class="fa fa-trash fa-fw"></em>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else class="alert alert-info col-md-12">No price packages to display</div>
            </div>
        </div>
        <ModifyLayer3PackageModal
          ref="addPackage"
          :pricePackage="newPricePackage"
          @packageAdded="createPackage"
          @packageEdited="savePackage"
        />
        <AddPriceModal ref="addPriceModal" :row-price="rowPrice" :new-price="newPriceObj"
            @addPrice="addPrice" @pricesAdded="getPackages()" />
        <!-- <BasicPriceModal ref="addPackage" :pricePackage="newPricePackage" @save-price="savePackage" /> -->
    </div>
</template>

<script>
  import AddPriceModal from '@/components/Admin/Modals/AddPriceModal'
  import BasicPriceModal from '@/components/Admin/Modals/BasicPriceModal'
  import ModifyLayer3PackageModal from '@/components/Admin/Modals/ModifyLayer3PackageModal'
  import Modal from '@/components/GeneralComponents/Modal'
  import PriceFunctions from './PriceFunctions'

  export default {
    extends: PriceFunctions,
    components: {
      Modal,
      AddPriceModal,
      BasicPriceModal,
      ModifyLayer3PackageModal
    },
    props: {
      productNumber: {
        type: Number,
        required: true
      },
      userEditRight: {
        type: Boolean,
        required: true
      },
    },
    data() {
      return {
        period: this.getCurrentPeriod(),
        newPriceObj: {},
        newPricePackage: {},
        rowPrice: [],
        packages: [],
      }
    },
    mounted() {
      this.getPackages();
    },
    methods: {
      getPackages() {
        this.$http.get(this.$config.aimsAPI + 'pricing/packages/product/' + this.productNumber + "/Layer3Package")
          .then(response => {
              this.packages = response.data;
            },
            error => {
              this.showError('Error fetching Packages', error)
              console.error(error);
            });
      },
      openPriceModal(p) {
        this.rowPrice = this.deepCopyObject(p.prices);
        //need an empty xxxPackagePrice object here
        this.newPriceObj = this.deepCopyObject({
          layer3PackageNumber: p.layer3PackageNumber,
          class: p.class + 'Price',
          validFrom: 0,
          validUntil: 0
        });
        // the setupfee is checked in modal to show/hide
        this.newPriceObj.setupfee = 0.00;    

        this.$refs.addPriceModal.open();
      },
      addPrice(newPriceParam) {
        this.rowPrice.forEach(price => {
          if (price.validUntil == 0) {
            if (newPriceParam.validFrom % 100 > 1) {
              price.validUntil = newPriceParam.validFrom - 1;
            } else {
              price.validUntil = (newPriceParam.validFrom - 100) + 11;
            }
          }
        });
        this.rowPrice.push(newPriceParam);
        this.newPriceObj = this.deepCopyObject({
          layer3PackageNumber: newPriceParam.layer3PackageNumber,
          class: newPriceParam.class,
          validFrom: 0,
          validUntil: 0
        });
        this.newPriceObj.setupfee = 0.00;    
      },
      addPackage() {
        this.$http.get(this.$config.aimsAPI + 'pricing/new/package/za.co.adept.aims.layer3.Layer3Package')
            .then(response => {
                this.newPricePackage = response.data;
                this.newPricePackage.productNumber = this.productNumber;
            },
            error => {
                this.showError('Error getting new package template', error)
                console.error(error);
            });
        this.$refs.addPackage.open();
      },
      editPackage(pricePackage) {
        this.newPricePackage = pricePackage;
        this.$refs.addPackage.open();
      },
      createPackage(pricePackage) {
        this.$http.post(this.$config.aimsAPI + 'pricing/packages/create', pricePackage)
            .then(response => {
              response.data.prices = [];
              this.openPriceModal(response.data);
              this.showSuccess('Created Package Successfully')
            },
            error => {
              this.showError('Error updating Packages', error)
              console.error(error);
            })
      },
      savePackage(pricePackage) {
        this.$http.put(this.$config.aimsAPI + 'pricing/packages/update', pricePackage)
            .then(response => {
              this.showSuccess('Updated Packages Successfully')
            },
            error => {
              this.showError('Error updating Packages', error)
              console.error(error);
            }).finally(() => {
              this.getPackages();
              //this.$refs.addPackage.close();
            });
      },
      deletePackage(pricePackage) {
        this.$swal({
          title: 'Are you sure you want to delete this package?',
          text: pricePackage.description,
          type: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then(result => {
          if (result.value) {
            this.$http.delete(
                this.$config.aimsAPI + 
                'pricing/pricepackages/' + 
                pricePackage.objKey +
                "?objClassName=" +
                pricePackage.class)
              .then(response => {
                  this.showSuccess('Successfully deleted Package');
                  this.getPackages();
                },
                error => {
                  this.showError('Error deleting Package', error)
                  console.error(error);
                });
          }
        })
      }
    }
  }

</script>
