<template>
    <modal 
        title="Changelog"
        ref="cchangelog"
        :size="'modal-md'"
        :saveNeeded="false"
    >
        <div v-html="markdownToHtml"></div>

    </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
import { marked } from 'marked';
export default {
    props: {
    },
    components: {
        Modal,
    },
    data(){
        return {
            markdown:  "",
        };
    },
    computed: {
        markdownToHtml(){
            return marked(this.markdown);
        }
    },
    mounted() {
        this.$http.get(this.$config.aimsAPI + "changelog/client/markdown").then(
            (response) => {
                this.markdown = response.data;
            },
            (error) => {
                this.showError("Error Fetching Client changelog", error);
                console.error(error);
            }
        );
    },
    methods: {
        open() {
            this.$refs.cchangelog.open();
        },
    },
}
</script>

<style scoped>

</style>