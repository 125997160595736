<template>
  <div>
    <div class="row row-spacing">
      <div class="col-md-12 mb-2" v-if="packages.length < 1">
        <button
          v-if="userEditRight"
          @click="addNewPackagePrice()"
          type="button"
          class="btn btn-info"
        >
          Add New Price Package
        </button>
      </div>
    </div>
    <div class="table-responsive table-bordered" v-if="packages.length > 0">
      <table class="table">
        <thead>
          <tr class="text-nowrap">
            <th>Description</th>
            <th>Price (excl. VAT)</th>
            <th>Price (incl. VAT)</th>
            <th>Expired</th>
            <th>#</th>
            <th>Commands</th>
          </tr>
        </thead>
        <tbody>
          <tr
            :class="{ 'bg-primary': pricePackage.expired }"
            v-for="(pricePackage, rowIndex) in packages"
            :key="rowIndex"
          >
            <td>{{ pricePackage.description }}</td>
            <td class="wd-md">
              {{ getPrice(pricePackage.basicpackageprices, period) }}
              <span
                v-if="userEditRight"
                style="cursor: pointer"
                v-tooltip="'Edit Row Price'"
                @click="openPriceModal(pricePackage)"
                class="pull-right"
              >
                <em class="fa fa-edit fa-fw text-green"></em>
              </span>
            </td>
            <td>
              {{ getPrice(pricePackage.basicpackageprices, period, true) }}
            </td>
            <td>
              <span
                class="badge"
                :class="pricePackage.expired ? 'badge-warning' : 'badge-green'"
                >{{ pricePackage.expired }}
              </span>
            </td>
            <td>{{ pricePackage.basicPackageNumber }}</td>
            <td class="text-center">
              <button
                v-if="userEditRight"
                v-tooltip="'Edit Package'"
                @click="editPricePackage(pricePackage)"
                type="button"
                class="btn btn-sm btn-info m-1"
              >
                <em class="fa fa-edit fa-fw"></em>
              </button>
              <button
                v-if="userEditRight"
                v-tooltip="'Delete Row'"
                @click="deletePricePackage(pricePackage)"
                type="button"
                class="btn btn-sm btn-primary"
              >
                <em class="fa fa-trash fa-fw"></em>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="alert alert-info col-sm-12" v-else>
      <strong>No price packages to display</strong>
    </div>
    <AddPriceModal
      ref="addPriceModal"
      :row-price="rowPrice"
      :new-price="newPrice"
      @addPrice="addPrice"
      @pricesAdded="getPackages()"
    />
    <BasicPriceModal
      ref="modifyPackageModal"
      :pricePackage="basicPricePackage"
      @save-price="savePricePackage"
    />
  </div>
</template>

<script>
import AddPriceModal from "@/components/Admin/Modals/AddPriceModal";
import BasicPriceModal from "@/components/Admin/Modals/BasicPriceModal";
import PriceFunctions from "@/components/Admin/PriceFunctions";

export default {
  extends: PriceFunctions,
  props: {
    productNumber: {
      type: Number,
      required: true,
    },
    userEditRight: {
      type: Boolean,
      required: true
    },
  },
  components: {
    AddPriceModal,
    BasicPriceModal,
  },
  data() {
    return {
      period: this.getCurrentPeriod(),
      newPrice: {},
      basicPricePackage: {},
      packages: [],
      rowPrice: [],
      availableProducts: [],
    };
  },
  mounted() {
    this.getPackages();
  },
  methods: {
    getPackages() {
      this.$http
        .get(
          this.$config.aimsAPI + "pricing/basicpackages/" + this.productNumber
        )
        .then(
          (response) => {
            this.packages = response.data;
          },
          (error) => {
            this.showError("Error fetching Packages", error);
            console.error(error);
          }
        );
    },
    addNewPackagePrice() {
      this.basicPricePackage = this.deepCopyObject({
        productNumber: this.productNumber,
        class: "za.co.adept.aims.prices.BasicPackage",
      });
      this.$refs.modifyPackageModal.open();
    },
    editPricePackage(pricePackage) {
      this.basicPricePackage = this.deepCopyObject(pricePackage);
      this.$refs.modifyPackageModal.open();
    },
    openPriceModal(pPackage) {
      this.rowPrice = this.deepCopyObject(pPackage.basicpackageprices);
      this.newPrice = this.deepCopyObject({
        basicPackageNumber: pPackage.basicPackageNumber,
        class: pPackage.class + "Price",
        validFrom: 0,
        validUntil: 0,
      });
      this.$refs.addPriceModal.open();
    },
    addPrice(newPrice) {
      this.rowPrice.forEach((price) => {
        if (price.validUntil == 0) {
          if (newPrice.validFrom % 100 > 1) {
            price.validUntil = newPrice.validFrom - 1;
          } else {
            price.validUntil = newPrice.validFrom - 100 + 11;
          }
        }
      });
      this.rowPrice.push(newPrice);
      this.newPrice = this.deepCopyObject({
        mplsPackageNumber: newPrice.mplsPackageNumber,
        class: newPrice.class,
        validFrom: 0,
        validUntil: 0,
      });
    },
    savePricePackage(pricePackage) {
      this.$http
        .post(this.$config.aimsAPI + "pricing/pricepackages/save", pricePackage)
        .then(
          (response) => {
            this.showSuccess("Updated Package Successfully");
            if (pricePackage.basicPackageNumber) {
              this.getPackages();
            } else {
              response.data.basicpackageprices = [];
              this.openPriceModal(response.data);
            }
          },
          (error) => {
            this.showError("Error Saving Pricing", error);
            console.error(error);
          }
        );
    },
    deletePricePackage(pricePackage) {
      this.$swal({
        title: "Are you sure you want to delete Price package?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$http
            .delete(
              this.$config.aimsAPI +
                "pricing/pricepackages/" +
                pricePackage.objKey +
                "?objClassName=" +
                pricePackage.class
            )
            .then(
              () => {
                this.showSuccess("Successfully deleted Price package");
                this.getPackages();
              },
              (error) => {
                this.showError("Error Deleting Pricing Package", error);
              }
            );
        }
      });
    },
  },
};
</script>
