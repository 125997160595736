<template>
    <div class="card card-default">
        <div class="card-body">
            <service-screen-layout :parts="parts">
                <template v-slot:client-classifications>
                    <service-screen-layout :parts="clientParts">
                        <template v-slot:segments>
                            <ClassificationManagementTable 
                                :productClassification="'Segment'"
                                :clientClassificationData="clientSegments"
                                :isClient="true"
                                @reloadClassifications="getClassifications"
                            />
                        </template>
                    </service-screen-layout>
                </template>
                <template v-slot:product-classifications>
                    <service-screen-layout :parts="productParts">
                        <template v-slot:suppliers>
                            <ClassificationManagementTable 
                                :productClassification="'Supplier'"
                                :classificationData="suppliers"
                                :isProduct="true"
                                @reloadClassifications="getClassifications"
                            />
                        </template>
                        <template v-slot:vendors>
                            <ClassificationManagementTable 
                                :productClassification="'Vendor'"
                                :classificationData="vendors"
                                :isProduct="true"
                                @reloadClassifications="getClassifications"
                            />
                        </template>
                        <template v-slot:segments>
                            <ClassificationManagementTable 
                                :productClassification="'Segment'"
                                :classificationData="segments"
                                :isProduct="true"
                                @reloadClassifications="getClassifications"
                            />
                        </template>
                        <template v-slot:subsegments>
                            <ClassificationManagementTable 
                                :productClassification="'SubSegment'"
                                :classificationData="subSegments"
                                :segments="segments"
                                :isProduct="true"
                                @reloadClassifications="getClassifications"
                            />
                        </template>
                    </service-screen-layout>
                </template>
            </service-screen-layout>
            <div class="col-md-12">
                <div class="row"></div>
            </div>
        </div>
    </div>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import ClassificationManagementTable from "@/components/Admin/ClassificationManagementTable.vue";

export default {
    components: {
        ServiceScreenLayout,
        ClassificationManagementTable
    },
    data() {
        return {
            parts: [
                {
                    name: "client-classifications",
                    icon: "fa-solid fa-handshake",
                    displayName: "Client",
                    permissions: ["STAFF"],
                },
                {
                    name: "product-classifications",
                    icon: "fa-solid fa-box-open",
                    displayName: "Product",
                    permissions: ["STAFF"],
                }
            ],
            clientParts: [
                {
                    name: "segments",
                    icon: "fa fa-gears",
                    displayName: "Segments",
                    permissions: ["STAFF"],
                }
            ],
            productParts: [
                {
                    name: "suppliers",
                    icon: "fa fa-gears",
                    displayName: "Suppliers",
                    permissions: ["STAFF"],
                },
                {
                    name: "vendors",
                    icon: "fa fa-gears",
                    displayName: "Vendors",
                    permissions: ["STAFF"],
                },
                {
                    name: "segments",
                    icon: "fa fa-gears",
                    displayName: "Segments",
                    permissions: ["STAFF"],
                },
                {
                    name: "subsegments",
                    icon: "fa fa-gears",
                    displayName: "Subsegments",
                    permissions: ["STAFF"],
                },
            ],
            suppliers: [],
            vendors: [],
            segments: [],
            subSegments: [],
            clientSegments: [], 
        }
    },
    created() {
        this.getClassifications();
    },
    mounted() {
        this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
    },
    methods: {
        getClassifications: async function() {
            const hostUrl = this.$config.aimsAPI;
            const hostUrlv2 = this.$config.aimsAPIv2;

            await this.$http.get(`${hostUrlv2}suppliers`).then(
                (response) => {      
                    this.suppliers = response.data;                    
                },
                (error) => {
                    console.error(error);
                    this.showError("Error fetching product suppliers", error);
                }
            );

            await this.$http.get(`${hostUrlv2}vendors`).then(
                (response) => {
                    this.vendors = response.data;                                             
                },
                (error) => {
                    console.error(error);
                    this.showError("Error fetching product vendors", error);
                }
            );

            await this.$http.get(`${hostUrlv2}segments`).then(
                (response) => {      
                    this.segments = response.data;                                             
                },
                (error) => {
                    console.error(error);
                    this.showError("Error fetching product segments", error);
                }
            );

            await this.$http.get(`${hostUrlv2}subsegments`).then(
                (response) => {      
                    this.subSegments = response.data;                                           
                },
                (error) => {
                    console.error(error);
                    this.showError("Error fetching product subsegments", error);
                }
            ); 

            await this.$http.get(`${hostUrl}clients/segments`).then(
                (response) => {      
                    this.clientSegments = response.data;      
                },
                (error) => {
                    console.error(error);
                    this.showError("Error fetching client segments", error);
                }
            ); 
        },
    }
}
</script>
