<template>
    <modal
      :title="'Client ' + this.workOrder.clientNumber + ' - Email Contacts'"
      ref="emailDialogModal"
      size="modal-xl"
      save-text="Send Email"
      @save="$emit('sendEmail')"
      dismiss-text="Close"
    >
      <div class="row">
        <div class="col-md-12">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="person in clientContacts"
                :key="person.personNumber"
                @click="$emit('contactClicked', person.user.emailAddress)"
              >
                <td>{{ person.user.firstNames }}</td>
                <td>{{ person.user.lastName }}</td>
                <td>{{ person.user.emailAddress }}</td>
                <td>
                  <input
                    type="checkbox"
                    :checked="$emit('isSelected', person.user.emailAddress)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <input
            type="text"
            class="form-control"
            v-validate="'email'"
            name="email"
            v-model="additionalContacts"
            placeholder="Comma separate additional email addresses"
          />
          <span class="has-error" v-if="errors.has('email')">{{
            errors.first("email")
          }}</span>
        </div>
      </div>
    </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";

export default {
    components: {
        Modal
    },
    props: {
        workOrder: {
            type: Object,
            required: true
        },
        clientContacts: {
            type: Array,
            required: true
        },
        additionalContacts: {
            type: String,
            required: true
        }
    },
    methods: {
        open() {
          this.$refs.emailDialogModal.open();
        },
        close() {
          this.$refs.emailDialogModal.close();
        },
        loading() {
          this.$refs.emailDialogModal.isLoading();
        }

    }
}
</script>
