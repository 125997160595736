<template>
  <div>
    <form-wizard :title="title" :subtitle="subtitle" @on-complete="onComplete" stepSize="sm">
        <tab-content v-for="part in parts" :key="part.name" :title="part.displayName" :icon="part.icon" :before-change="()=>validateStep(part.name)">
            <slot :name="part.name">
                <span class="text-danger">Part {{part.name}} is not implemented</span>
            </slot>
        </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
export default {
    props: {
        parts: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: true
        },
        onComplete:{
            type: Function,
            required: true
        },
        validateStep:{
            type: Function,
            required: true
        }
    },
    components: {
        FormWizard,
        TabContent
    },
    methods: {
    }
}
</script>

<style>

</style>