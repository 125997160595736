<template>
    <div class="card" v-if="!loadingServices">
        <feasibility-tool-layout :parts="parts">
            <template v-slot:feasibilitymap>
                <FeasibilityMap 
                    :allServices="allServices"
                />
            </template>
            <template v-slot:feasibilityupload>
                <FeasibilityUpload 
                    :allServices="allServices"
                />
            </template>
        </feasibility-tool-layout>
    </div>
</template>

<script>
import FeasibilityToolLayout from '@/layouts/FeasibilityToolLayout';

import FeasibilityMap from '@/components/Tools/FeasibilityMap'
import FeasibilityUpload from '@/components/Tools/FeasibilityUpload'

    export default {
        components: {
            FeasibilityToolLayout,
            FeasibilityMap,
            FeasibilityUpload
        },
        data() {
            return {
                parts: [
                    {
                        name: "feasibilitymap",
                        icon: "fa-solid fa-map-location-dot",
                        displayName: "Feasibility Map",
                        permissions: ["STAFF"],
                    },
                    {
                        name: "feasibilityupload",
                        icon: "fa-solid fa-upload",
                        displayName: "Data Upload",
                        permissions: ["STAFF"],
                    },
                ],
                allServices: [],
                loadingServices: false,
            }
        },
        created() {
            this.$root.$emit('SET_PAGE_TITLE', this.$route.meta.title);
            this.$root.$emit('SET_PAGE_TITLE_BADGES', []);
            this.getServices();
        },
        methods: {
            async getServices() {
                const hostUrl = this.$config.aimsAPI;
                this.loadingServices = true;

                await this.$http.get(`${hostUrl}feasibility/services/list`).then(
                    (response) => {
                        this.allServices = response.data;
                        this.loadingServices = false;                        
                    },
                    (error) => {
                        console.error(error);
                        this.showError('Error fetching Services', error);
                    }
                );
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>