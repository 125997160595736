<template>
    <modal :title="title" ref="addonModal" @save="save()">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group" :class="{ 'has-error': errors.has('Name') }">
                    <label>Name</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        v-model="cabinetAddon.name"
                        name="Name"
                        :class="{ 'has-error': errors.has('Name') }"
                        v-validate="'required'"
                    />
                    <small class="has-error mt-1" v-if="errors.has('Name')">{{ errors.first("Name") }}</small>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group" :class="{ 'has-error': errors.has('Type') }">
                    <label>Type</label>
                    <v-select
                        :options="cabinetAddonTypes"
                        label="description"
                        valueProp="name"
                        v-model="cabinetAddon.type"
                        clearable
                        name="Type"
                        :class="{ 'has-error': errors.has('Type') }"
                        v-validate="'required'"
                    >
                    </v-select>
                    <small class="has-error mt-1" v-if="errors.has('Type')">{{ errors.first("Type") }}</small>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group" :class="{ 'has-error': errors.has('Monthly Recurring Cost (Excl. VAT)') }">
                    <label>Monthly Recurring Cost (Excl. VAT)</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">R</span>
                        </div>
                        <input 
                            type="text" 
                            class="form-control" 
                            v-model="cabinetAddon.mrcExclVat"
                            name="Monthly Recurring Cost (Excl. VAT)"
                            :class="{ 'has-error': errors.has('Monthly Recurring Cost (Excl. VAT)') }"
                            v-validate="'required'"
                        />
                    </div>
                    <small class="has-error mt-1" v-if="errors.has('Monthly Recurring Cost (Excl. VAT)')">{{ errors.first("Monthly Recurring Cost (Excl. VAT)") }}</small>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/GeneralComponents/Modal';

    export default {
        components: {
            Modal
        },
        props: {
            title: {
                type: String,
                required: true,
            },
            cabinetAddon: {
                type: Object,
                required: true,
            },
            cabinetAddonTypes: {
                type: Array,
                required: true,
            },
        },
        methods: {
            open: function() {
                this.$refs.addonModal.open();
            },
            save: function() {
                this.$refs.addonModal.isLoading();
                this.$validator.validateAll().then((valid) => {
                    if (valid) {
                        if (this.cabinetAddon.cabinetAddonNumber !== 0) return this.$emit("updateCabinetAddon", this.cabinetAddon);
                        return this.$emit("createCabinetAddon", this.cabinetAddon); 
                    } else {
                        this.$refs.addonModal.isLoading();
                    }
                });
            }
        }
    }
</script>
