<template>
  <div>
    <div class="card card-default">
      <div class="card-body">
        <div class="row">
          <div class="form-group col-md-4">
            <input type="text"
              v-mask="{mask: '9{1,3}.9{1,3}.9{1,3}.9{1,3}', showMaskOnHover: false, showMaskOnFocus: false}"
              @keydown.enter="getIpUsage" v-model="address" placeholder="Search IP Address" class="form-control">
          </div>
          <div class="col-md-3">
            <button class="btn btn-info" @click="getIpUsage">
              <span><i class="fa fa-map-marker"></i></span>
              IP Lookup
            </button>
          </div>
        </div>
        <div class="form-check mb-2">
          <input type="checkbox" v-model="legacyMode" @change="getIpUsage()" class="form-check-input" id="legacyMode">
          <label class="form-check-label" for="legacyMode">Legacy Mode</label>
        </div>
        <div v-if="!legacyMode">
          <div class="table-responsive table-bordered" v-if="this.sessions.length > 0">
            <table class="table">
              <thead>
                <tr>
                  <th>Username</th>
                  <th>IPv4</th>
                  <th>Start Time</th>
                  <th>Stop Time</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="session in sessions" :key="session.objKey">
                  <td class="text-bold">{{session.username}}</td>
                  <td>{{session.ipAddressV4}}</td>
                  <td>{{session.startTime}}</td>
                  <td>
                    <span v-if="session.stopTime != '1970-01-01 02:00:00'">{{session.stopTime}}</span>
                    <span class="badge bg-green" v-else>Active</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else>
          <div class="table-responsive table-bordered" v-if="this.sessions.length > 0">
            <table class="table">
              <thead>
                <tr>
                  <th>Username</th>
                  <th>IP</th>
                  <th>Start Time</th>
                  <th>Stop Time</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="session in sessions" :key="session.objKey">
                  <td class="text-bold">{{session.username}}</td>
                  <td>{{session.ipAddress}}</td>
                  <td>{{session.startTime}}</td>
                  <td>
                    <span v-if="session.stopTime != '1970-01-01 02:00:00'">{{session.stopTime}}</span>
                    <span class="badge bg-green" v-else>Active</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="alert alert-info col-sm-12" v-if="empty">
          {{empty}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        address: '',
        empty: '',
        sessions: [],
        legacyMode: false
      }
    },
    methods: {
      getIpUsage() {

        let endpoint;

        if (this.legacyMode) {
          endpoint = 'connectivityaccounts/radius/ipv4/usagehistory/legacy';
        } else {
          endpoint = 'connectivityaccounts/radius/ipv4/usagehistory';
        }

        this.$http.get(this.$config.aimsAPI + endpoint + '?ip=' + this.address)
          .then(response => {
              this.sessions = response.data;
              if (this.sessions.length == 0) {
                this.empty = 'This IP address has no usage history'
              } else {
                this.empty = ''
              }
            },
            error => {
              console.error(error);
              this.showError("Error getting IP address usage history", error)
            })
      }
    },
    created() {
      this.$root.$emit('SET_PAGE_TITLE', this.$route.meta.title);
    }
  }

</script>
