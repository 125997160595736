<template>
    <div class="col-md-12">
        <div class="card border px-2">
            <div class="card-header text-center">
                <h4 class="my-1">Two-factor Authentication (2FA)</h4>
            </div>
            <hr class="mt-1">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>One-time Password</label>
                            <div class="input-group">
                                <div class="input-group-append w-100">
                                    <input type="text" class="form-control" v-model="otpKey" disabled>
                                    <span class="input-group-text bg-dark" id="basic-addon2">
                                        <span class="mr-2">{{ countDownStart }}</span>
                                        <i class="fa-solid fa-clock-rotate-left"></i>
                                    </span>
                                    <button class="btn btn-info ml-2" @click="copyText(otpKey.toString())" type="button" v-tooltip="'Copy to clipboard'">
                                        <i class="fa fa-clipboard"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            secretKey: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                otpKey: null,
                timer: null,
                countDownStart: 30
            }
        },
        watch: {
            secretKey(newVal, oldVal) {
                if (newVal) {
                    this.startTimer(newVal);
                }
            }
        },
        methods: {
            getOpt: function(secretKey) {  
                if (this.userContainsPermission(["ADMIN", "MANAGER", "TECHNICAL_STAFF"])) {              
                    const hostUrl = this.$config.aimsAPI;
                    this.showSuccess("Requesting OTP");

                    this.$http.post(`${hostUrl}otp/request`, secretKey).then(
                        (response) => {
                            this.otpKey = response.data;                        
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error fetching OTP", error);
                        }
                    )
                }
            },
            startTimer: function(secretKey) {                  
                if (this.timer) {
                    clearInterval(this.timer);
                }
                this.getOpt(secretKey);

                const start = () => {
                    if (this.countDownStart <= 0) {
                        this.countDownStart = 30;
                        this.getOpt(secretKey);
                    } else {
                        this.countDownStart--;
                    }
                }   

                this.timer = setInterval(start, 1000);
            },
            stopTimer: function() {
                if (this.timer) {
                    clearInterval(this.timer);
                }
            }
        },
        beforeRouteLeave(to, from, next) {
            this.stopTimer();
            next();
        },
        beforeDestroy() {
            this.stopTimer();
        },
    }
</script>

<style scoped>
.bg-dark {
    background-color: #3a3f51;
    color: #ffffff;
    font-size: 1em;
    font-weight: 600;
    width: 100px;
}
</style>