<template>
    <modal
        :title="classificationObj.objKey ? 'Edit Classification' : 'Add Classification'"
        ref="modifyClientClassificationModal"
        @save="save()"
    >
        <div class="row">
            <div class="form-group col-md-12" :class="{'has-error': errors.has('Description')}">
                <label>Description</label>
                <input 
                    type="text" 
                    class="form-control"
                    name="Description"
                    v-model="classificationObj.description" 
                    v-validate="'required'" 
                    :class="{'has-error': errors.has('Description')}"
                >
                <span class="has-error" v-if="errors.has('Description')">{{ errors.first('Description') }}</span>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";

export default {
    components: {
        Modal
    },
    props: {
        classificationObj: {
            type: Object,
            required: true
        },
    },
    methods: {
        open: function() {
            this.$refs.modifyClientClassificationModal.open();
        },
        save: function() {    
            this.$validator.validateAll().then((valid) => {
                if (valid) {
                    this.classificationObj.segment = this.classificationObj.description;
                    this.$refs.modifyClientClassificationModal.isLoading();
                    this.$emit('saveClassification', this.classificationObj);
                }
            });
        }
    } 
}
</script>
