<template>
    <service-screen-layout :parts="parts">
        <template v-slot:awsTenant>
            <div class="row">
                <div class="col-md-6">
                    <AwsTenant 
                        :saleDefinition="saleDefinition"
                    />
                </div>
            </div>
        </template>
        <template v-slot:awsInvoiceEntries>
            <div class="row">
                <div class="col-md-12">
                    <AwsInvoiceEntries 
                        :awsInvoiceEntries="filteredEntries"
                        @filterEntries="filterEntries"
                    />
                </div>
            </div>
        </template>
    </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";

import AwsTenant from "@/components/ObjParts/SingleCaptured/AwsTenant";
import AwsInvoiceEntries from "@/components/ObjParts/MultipleLoaded/AwsInvoiceEntries";

export default {
    components: {
        ServiceScreenLayout,
        AwsTenant,
        AwsInvoiceEntries
    },
    data() {
        return {
            parts: [
                {
                    name: "awsTenant",
                    icon: "fa fa-user-circle",
                    displayName: "AWS Tenant",
                    permissions: ["*"],
                },
                {
                    name: "awsInvoiceEntries",
                    icon: "fa fa-list",
                    displayName: "AWS Invoice Entries",
                    permissions: ["*"],
                },
            ],
            saleNumber: null,
            saleDefinition: {
                sale: {
                    awsTenant: {
                        awsInvoiceEntry: []
                    }
                }
            },
            filteredEntries: []
        }
    },
    mounted() {
        this.saleNumber = this.$route.params.saleNumber;
        this.getSaleDefinition();
    },
    methods: {        
        getSaleDefinition: function() {
            const hostUrl = this.$config.aimsAPIv2;

            this.$http.get(`${hostUrl}sales/definition/sale/${this.saleNumber}`).then(
                (response) => {
                    this.saleDefinition = response.data; 
                    this.filterEntries(parseInt(this.getCurrentPeriod()));                
                },
                (error) => {
                    console.error(error);
                    this.showError('Error fetching Sale Definition', error);
                } 
            )
        },
        filterEntries: function(period) {
            this.filteredEntries = this.saleDefinition.sale.awsTenant.awsInvoiceEntry.filter(e => e.billingMonth === period);
        }
    },
}
</script>

<style lang="scss" scoped>

</style>