<template>
  <modal :title="modalTitle" ref="modifyRDNS" @save="saveDNSZone()">
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label">Reverse Zone</label>
        <input
          type="text"
          class="form-control"
          v-model="rDNSZone.name"
          disabled
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label">Address Range</label>
        <input
          type="text"
          class="form-control"
          v-model="rDNSZone.forwardName"
          disabled
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> Minimum TTL </label>
        <input
          type="text"
          class="form-control"
          v-model="rDNSZone.minimumTtl"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
export default {
  props: {
    rDNSZone: {
      type: Object,
      required: true,
    },
    modalTitle: {
      type: String,
      required: true,
    },
    ipvType: {
      type: String,
      required: true,
    },
  },
  components: {
    Modal,
  },
  methods: {
    open: function() {
        this.$refs.modifyRDNS.open();
    },
    saveDNSZone: function() {
    this.$refs.modifyRDNS.isLoading();
    const ipvType = this.ipvType.toLowerCase();
    const hostUrl = this.$config.aimsAPI;
    const zoneObject = this.rDNSZone;
    this.$http
      .put(`${hostUrl}reverse/zones/${ipvType}`, zoneObject)
      .then(
      (response) => {
          this.$refs.modifyRDNS.close();
          this.showSuccess("Successfully Edited Zone");
          this.$emit("zoneEdited");
      },
      (error) => {
          this.showError("Error Saving Zone", error);
          console.error(error);
      })
    },
  },
};
</script>