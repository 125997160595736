<template>
    <div>
        <div class="card card-default">
            <div class="card-header">
                <div class="col-md-12">
                    <div class="row justify-content-between align-items-center">
                        <h4 class="mb-0">Routed IPv4 Address</h4>
                        <button type="button" class="btn btn-info" @click="openRoutedIPv4AddressModal" v-if="Object.keys(address).length === 0">Add Routed IPv4 Address</button>
                    </div>
                </div>
            </div>
            <div class="card-body pt-0">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Address</th>
                            <th>Netmask</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="Object.keys(address).length > 0">
                        <tr>
                            <td>{{ address.family }}</td>
                            <td>{{ address.address }}</td>
                            <td>{{ address.shortMask }}</td>
                            <td v-allowed:view="['TECHNICAL_STAFF']" style="text-align:center">
                                <button class="btn btn-red text-light" type="button" @click="removeRoutedIPv4Address(address)">
                                    <i class="fa fa-trash" aria-hidden="true"></i>  
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="3">No Routed IPv4 Address added</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <AddOptionalRoutedIPv4Address 
            ref="addRoutedIPv4AddressModal"
            @addRoutedIPv4Address="addRoutedIPv4Address"
        />
    </div>
</template>

<script>
import AddOptionalRoutedIPv4Address from '@/components/Admin/Modals/AddOptionalRoutedIPv4Address.vue';

export default {
    props: {
        address: {
            type: Object,
            required: true
        },
        newRoutedIPv4AddressObj: {
            type: Object,
            required: true
        },
        pppoeAccountNumber: {
            type: Number,
            required: true
        },
        saleNumber: {
            type: Number,
            required: true
        },
    },
    components: {
        AddOptionalRoutedIPv4Address
    },
    methods: {
        openRoutedIPv4AddressModal: function() {
            this.$refs.addRoutedIPv4AddressModal.open();
        },
        addRoutedIPv4Address: function(mask) {
            const hostUrl = this.$config.aimsAPI;
            this.newRoutedIPv4AddressObj.shortMask = mask;
            this.newRoutedIPv4AddressObj.pppoeAccountNumber = this.pppoeAccountNumber;

            this.$http.post(`${hostUrl}sales/definition/${this.saleNumber}/obj/create`, this.newRoutedIPv4AddressObj).then(
                (response) => {
                    this.showSuccess("Added Routed IPv4 address");
                    this.$emit('reload-sale');
                    this.$refs.addRoutedIPv4AddressModal.$refs.addRoutedIPv4AddressModal.close();
                },
                (error) => {
                    console.error(error);
                    this.showError("Error adding routed IPv4 address", error);
                }
            );
        },
        removeRoutedIPv4Address: function(address) {
            const hostUrl = this.$config.aimsAPI;
            const saleNumber = this.saleNumber;
            this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/delete/`, address).then(
            (response) => {
                this.showSuccess("Removed Routed IPv4 address");
                this.$emit('reload-sale');
            },
            (error) => {
                console.error(error);
                this.showError("Error Removing Routed Address", error);
            }
            );          
        }
    }
}
</script>
