<template>
  <div class="mt-2">
    <div
      class="card border-primary row-spacing"
      v-show="saleGroup.filteredSaleCount > 0"
      v-for="(saleGroup, index) in groupedSales"
      :key="index"
    >
      <div
        class="card-header bg-primary"
        style="cursor: pointer"
        data-tool="card-collapse" 
        @click="expandGroup(saleGroup)"
      >
        <a class="float-right item-icon" :class="{ rotate: isOpen ? selectedGroup.groupName === saleGroup.groupName : '' , default: !isOpen }" data-tool="card-collapse">
          <i class="fa fa-chevron-down"></i>
        </a>
        <span class="badge badge-success float-right">{{
          saleGroup.filteredSaleCount
        }}</span>
        <div class="card-title">{{ saleGroup.groupName }}</div>
      </div>
      <div
        class="card-wrapper collapse"
        :class="{ show: saleSearchInput.length > 0 || containsNewSale(saleGroup) }"
      >
        <div class="card-body">
          <div class="table-responsive table-bordered">
            <table
              class="table table-bordered table-hover"
              v-if="sales.length"
            >
              <thead>
                <tr class="text-nowrap">
                  <th>Service Name</th>
                  <th>Vendor</th>
                  <th v-if="showPrices">Price (excl. VAT)</th>
                  <th v-if="showPrices">Price (Incl. VAT)</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="sale in filteredSales(saleGroup)"
                  @click="viewSale(sale)"
                  :key="sale.saleNumber"
                  :class="{ 'new-table-row': sale.isNew, 'bg-suspended-grey' : sale.status == 'Suspended' }"
                >
                  <td>
                    <div class="d-flex flex-column">
                      <p>{{ sale.description }}</p>
                      <div class="badges-container d-flex justify-content-between">
                        <i
                          v-if="sale.status == 'Active'"
                          class="badge badge-active"
                          >Active
                        </i>
                        <i v-if="sale.isNew" class="badge bg-green badge-new">New </i>
                        <i
                          v-if="sale.status == 'Suspended'"
                          class="badge badge-warning"
                          >Suspended
                        </i>
                        <i
                          v-if="sale.status == 'Inactive'"
                          class="badge badge-deactivated"
                          >Deactivated
                        </i>
                      </div>
                    </div>
                  </td>
                  <td>
                    <ProviderLogo
                      :providerName="getVendorName(sale.product.vendorNumber)"
                      :logoContainerStyle="'margin: 0 auto'"
                    />
                  </td>
                  <td class="text-nowrap" v-if="showPrices">
                    {{ currencyFormat(getPrice(sale)) }}
                  </td>
                  <td class="text-nowrap" v-if="showPrices">
                    {{ currencyFormat(getPrice(sale, true)) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="alert alert-info" v-if="sales.length == 0">
        {{ noSalesMessage ? noSalesMessage : "No Services" }}
      </div>
    </div>
  </div>
</template>

<script>
import ProviderLogo from "@/components/GeneralComponents/ProviderLogo";

export default {
  components: {
    ProviderLogo,
  },
  props: {
      groupedSales: {
          type: Array,
          required: true
      },
      sales: {
          type: Array,
          required: true
      },
      prices: {
          type: Array,
          required: true
      },
      saleSearchInput: {
        type: String,
        required: true
      },
      vendors: {
        type: Array,
        required: true
      }
  },
  computed: {
  },
  data() {
      return {
          showPrices: true,
          isOpen: false,
          selectedGroup: {},
      }
  },
  methods: {
    expandGroup: function(data) {
      this.selectedGroup = data;
      if (data.groupName) {
            return this.isOpen = !this.isOpen;
      }
    },
     containsNewSale: function (groupedSales) {
      return groupedSales.sales.findIndex((s) => s.isNew) != -1;
    },
    filteredSales: function (saleGroup) {
      let filteredSales = saleGroup.sales.filter((sale) => {
        return (sale.description.toLowerCase().includes(this.saleSearchInput.toLowerCase()) || sale.product.description.toLowerCase().includes(this.saleSearchInput.toLowerCase()));
      });
      if (this.showUnauditedSales) {
        filteredSales = filteredSales.filter(
          (sale) => sale.audited != "Audited"
        );
      }
      saleGroup.filteredSaleCount = filteredSales.length;
      return filteredSales;
    },
     getPrice: function({ saleNumber }, inclVat) {
      if (inclVat) {
        const price = this.prices.find((p) => p.saleNumber == saleNumber);
        return price ? price.amountInclVat : 0;
      }
      const price = this.prices.find((p) => p.saleNumber == saleNumber);
      return price ? price.amountExclVat : 0;
    },
    viewSale: function(sale) {
      this.$emit("viewSale", sale);
    },
    getVendorName: function(vendorNumber) {
      let vendor = {};
      const vendors = [...this.vendors];

      vendor = vendors.find((v) => v.vendorNumber === vendorNumber);
      
      return vendor.name;
    }
  },
};
</script>

<style lang="scss" scoped>
.bg-dark-grey {
    background-color: #717171;

    .card-title {
        color: #ffffff;
    }

    .sale-group-counter {
        background-color: #ffffff;
        color: #2D2D2D;
        border-radius: 5%;
        padding: 4px;
        font-size: 14px;
        font-weight: 700;
        width: 30px;
        text-align: center;
    }
}

.bg-suspended-grey {
    background-image: linear-gradient(to left, #eeeeee, #ffffff);
}

.default {
  transform: rotate(0deg);
  transition: all 0.5s;
}

.rotate {
  transform: rotate(180deg);
  transition: all 0.5s;
}

.badges-container {
  width: 220px;
  .badge {
    &.badge-active, &.badge-new, &.badge-deactivated {
    width: 100px;
    color: #ffffff;
  }
}
}

</style>