<template>
  <service-screen-layout :parts="parts">
    <template v-slot:account>
      <div class="row">
        <div class="col-md-7">
          <AccountDetails
            :connectivity-package="
              saleDefinition.definition.uncappedInternetService
                .connectivityPackage
            "
            :account="saleDefinition.definition.pppoe"
            :sale="saleDefinition.sale"
            @save-sale="saveSale"
          />
        </div>
        <div class="col-md-5">
          <ConnectivityPackage
            v-allowed:view="['STAFF']"
            :bundle="
              saleDefinition.definition.uncappedInternetService
                .connectivityPackage
            "
          />
        </div>
      </div>
    </template>
    <template v-slot:ipaddresses>
      <div class="row">
        <div class="col-md-8">
          <div class="row row-spacing">
            <div class="col-md-12">
              <!-- IPv4 -->
              <div class="row">
                <div class="col-md-12">
                  <div class="card card-default">
                    <div class="card-header">
                      <h4>Allocated Ipv4 Network</h4>
                    </div>
                    <div class="card-body">
                      <div class="row row-spacing">
                        <div class="col-md-12">
                          <div class="table-responsive table-bordered">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>Address</th>
                                  <th>Metric</th>
                                  <th>Range</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {{
                                      saleDefinition.definition.pppoe
                                        .allocatedIPv4Network.address
                                    }}
                                  </td>
                                  <td>
                                    <div class="input-group">
                                      <input
                                        class="form-control"
                                        name="Metric"
                                        v-model="
                                          saleDefinition.definition.pppoe
                                            .allocatedIPv4Network.metric
                                        "
                                        v-validate="'between:0,255'"
                                        onkeypress="return !(event.charCode == 46)"
                                        type="text"
                                        required
                                      />
                                      <div class="input-group-append" v-allowed:view="['STAFF']">
                                        <button
                                          class="btn btn-success"
                                          @click="saveSale()"
                                          type="button"
                                        >
                                          <i class="fa fa-check"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="input-group">
                                      <select
                                        class="form-control"
                                        v-model="
                                          saleDefinition.definition.pppoe
                                            .allocatedIPv4Network.shortMask
                                        "
                                      >
                                        <option
                                          :key="mask"
                                          v-for="mask in netmasksV4"
                                          :value="mask"
                                        >
                                          /{{ mask }}
                                        </option>
                                      </select>
                                      <div class="input-group-append" v-allowed:view="['STAFF']">
                                        <button
                                          class="btn btn-success"
                                          @click="saveSale()"
                                          type="button"
                                        >
                                          <i class="fa fa-check"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- IPv4 -->
              <div class="row">
                <div class="col-md-12">
                  <div class="row row-spacing">
                    <div class="col-md-12">
                      <AllocatedIPAddresses
                        :editable="false"
                        :heading="'Assigned IPv6 addresses'"
                        :addresses="[
                          saleDefinition.definition.pppoe.allocatedIPv6Network,
                        ]"
                        :netmasksV6="netmasksV6"
                        @save-sale="saveSale()"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:usage>
      <ConnectivityUsageGraph
        :currentDailyUsageData="currentDailyUsageData"
        :graphTitle="saleDefinition.product.description"
        :usageType="usageType"
        :timePeriod="usageCaption"
        :usageYear="usageYear"
        :saleStartYear="saleStartYear"
        :usagePeriod="usagePeriod"
        :totalUsage="totalUsage"
        :saleStartPeriod="saleStartPeriod"
        :saleStartDate="saleDefinition.sale.startDate"
        @previousMonthUsage="previousMonthUsage"
        @nextMonthUsage="nextMonthUsage"
        @hourlyUsage="hourlyUsage"
        @dailyUsage="dailyUsage"
        @yearlyUsage="yearlyUsage"
        @previousYearlyUsage="previousYearlyUsage"
        @nextYearlyUsage="nextYearlyUsage"
      />
    </template>
    <template v-slot:sessions>
      <div class="row row-spacing">
        <div class="col-md-12 card-body card-scroll-100">
          <RadiusSessions
            :sessions="saleDefinition.definition.pppoe.sessions"
            cpeAddressName="CPE Address"
            cpeAddressField="cpeAddress"
          />
        </div>
      </div>
    </template>
    <template v-slot:line v-if="saleDefinition.definition.line">
      <div class="col-md-12">
        <ConnectivityLine
          v-allowed:view="['STAFF', 'CLIENT', 'RESELLER', 'SYSTEM']"
          :line="saleDefinition.definition.line"
          @saveSale="saveSale"
        />
      </div>
    </template>
    <template v-slot:router>
      <RouterConfig
        v-if="saleDefinition.sale.saleNumber"
        :routerConfig="saleDefinition.definition.routerConfig"
        :saleNumber="saleDefinition.sale.saleNumber"
        @routerConfigSaved="routerConfigSavedHandler"
        @reloadSale="loadSaleDefinition"
      />
    </template>
  </service-screen-layout>
</template>

<script>
import AccountDetails from "@/components/ObjParts/SingleConstructed/AccountDetails";
import AllocatedBundles from "@/components/ObjParts/MultipleLoaded/AllocatedBundles";
import AllocatedTopups from "@/components/ObjParts/MultipleCaptured/AllocatedTopups";
import RadiusSessions from "@/components/ObjParts/MultipleLoaded/RadiusSessions";
import ApexUsageGraph from "@/components/Graphs/ApexUsageGraph";
import ConnectivityPackage from "@/components/ObjParts/SingleConstructed/ConnectivityPackage";
import AllocatedIPAddresses from "@/components/ObjParts/MultipleCaptured/AllocatedIPAddresses";
import UsageRecipients from "@/components/ObjParts/MultipleCaptured/UsageRecipients";
import ConnectivityLine from "@/components/ObjParts/SingleCaptured/ConnectivityLine";
import RouterConfig from "@/components/ObjParts/OptionalCaptured/RouterConfig";
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import ConnectivityUsageGraph from "@/components/Graphs/ConnectivityUsageGraph.vue";

import SaleFunctions from "@/pages/Services/SaleFunctions";

export default {
  extends: SaleFunctions,
  components: {
    AccountDetails,
    AllocatedBundles,
    AllocatedTopups,
    RadiusSessions,
    ApexUsageGraph,
    ConnectivityPackage,
    AllocatedIPAddresses,
    UsageRecipients,
    ConnectivityLine,
    RouterConfig,
    ServiceScreenLayout,
    ConnectivityUsageGraph
  },
  data() {
    return {
      parts: [
        {
          name: "account",
          icon: "fa fa-info-circle",
          displayName: "Account",
          permissions: ["*"],
        },
        {
          name: "ipaddresses",
          icon: "fa fa-plus-square",
          displayName: "IP Addresses",
          permissions: ["STAFF"],
        },
        {
          name: "usage",
          icon: "fa fa-area-chart",
          displayName: "Usage",
          permissions: ["*"],
        },
        {
          name: "sessions",
          icon: "fa fa-clock-o",
          displayName: "Sessions",
          permissions: ["STAFF"],
        },
        {
          name: "line",
          icon: "fa fa-link",
          displayName: "Line",
          permissions: ["*"],
        },
        {
          name: "router",
          icon: "fa fa-cogs",
          displayName: "Router",
          permissions: ["STAFF"],
        },
      ],
      saleDefinition: {
        product: {
          classificationProvider: "",
        },
        sale: {},
        definition: {
          routerConfig: {},
          pppoe: {
            sessions: [],
            allocatedIPv4Network: {},
            allocatedIPv6Network: {},
          },
          uncappedInternetService: {
            connectivityPackage: {},
          },
        },
        selectableOptions: {
          bundle: [],
          topup: [],
        },
        supportingEnums: {
          account: {
            cappedType: [],
          },
          allocatedIPv4Addresses: {
            family: [],
          },
          allocatedIPv6Addresses: {
            family: [],
          },
        },
        supportingObjs: {
          dyndns: {},
          discount: {},
          dyndnsdomain: {},
        },
      },
      saleNumber: null,
      newUsageRecipient: "",
      usagePeriod: null,
      usageYear: null,
      usageCaption: "",
      totalUsage: 0,
      currentDailyUsageData: [],
      netmasksV4: [32, 30, 29, 28, 27, 26, 25],
      netmasksV6: [48, 56, 64],
      combinedIpAddresses: [],
      assignChoices: {
        assignIPFamily: null,
        assignIPNetmask: null,
      },
      filteredNetMaskList: [],
      listUsagePeriods: [],
      saleStartPeriod: null,
      usageType: "",
      saleStartYear: null,
    };
  },
  computed: {
    combinedAllocatedNetworks() {
      return [
        this.saleDefinition.definition.pppoe.allocatedIPv4Network,
        this.saleDefinition.definition.pppoe.allocatedIPv6Network,
      ];
    },
  },
  mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.usagePeriod = this.getCurrentPeriod();
    this.usageYear = ("" + this.getCurrentPeriod()).substring(0, 4);
    this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition() {
      this.getSaleDefinition(this.saleNumber).then(
        (success) => {
          this.saleDefinition = success.data;
          if (!this.saleDefinition.definition.line) {
            this.parts = this.parts.filter((p) => p.name != "line");
          }
          this.saleStartPeriod = this.dateToPeriod(
            this.saleDefinition.sale.startDate
          );
          this.saleStartYear = this.saleStartPeriod.substring(0, 4);   
          this.saleDefinition.definition.pppoe.fullUsername =
            this.saleDefinition.definition.pppoe.username +
            "@" +
            this.saleDefinition.definition.pppoe.pppoeRealm.name;
          this.translateCurrentDailyUsageData(
            this.saleDefinition.definition.pppoe.dailySummary
          );
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
    },
    saveSale: function () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
                this.saleDefinition.definition.pppoe.fullUsername =
                  this.saleDefinition.definition.pppoe.username +
                  "@" +
                  this.saleDefinition.definition.pppoe.pppoeRealm.name;
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Pppoe Account", error);
              }
            );
        }
      });
    },
    routerConfigSavedHandler(routerConfig) {
      this.saleDefinition.definition.routerConfig = routerConfig;
      this.saveSale();
    },
    dynDnsChanged: function (dyndns) {
      this.saleDefinition.definition.dyndns = dyndns;
    },
    topupAdded: function (topupAdded) {
      const accountTopup = {
        class: "za.co.adept.aims.connectivity.ConnectivityTopupAllocation",
        userReadOnly: false,
      };

      accountTopup.connectivityTopupNumber = topupAdded.objKey;
      accountTopup.cappedConnectivityServiceNumber = this.saleDefinition.definition.cappedService.cappedConnectivityServiceNumber;
      accountTopup.pppoeAccountNumber = this.saleDefinition.definition.pppoe.pppoeAccountNumber;
      this.$http
        .post(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/obj/create/",
          accountTopup
        )
        .then(
          (response) => {
            response.data.isNew = true;
            response.data.topup = this.deepCopyObject(topupAdded);
            if (this.saleDefinition.definition.cappedService) {
              this.saleDefinition.definition.cappedService.allocatedTopups.unshift(
                response.data
              );
            } else {
              this.saleDefinition.definition.uncappedInternetService.allocatedTopups.unshift(
                response.data
              );
            }
            this.showSuccess("Topup successfully added");
          },
          (error) => {
            console.error(error);
            this.showError("Error Adding Topup", error);
          }
        );
    },
    dynDnsDelete(dyndns) {
      this.$http
        .post(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/obj/delete/",
          dyndns
        )
        .then(
          (response) => {
            this.showSuccess("Dyn DNS successfully removed");
            this.saleDefinition.definition.dyndns = {};
          },
          (error) => {
            console.error(error);
            this.showError("Error Removing Dyn DNS", error);
          }
        );
    },
    recipientAdded(recipientNumber) {
      const newUsageRecipient = {};
      newUsageRecipient.class =
        "za.co.adept.aims.connectivity.CappedServiceUsageRecipient";
      newUsageRecipient.cappedConnectivityServiceNumber = this.saleDefinition.definition.cappedService.cappedConnectivityServiceNumber;
      newUsageRecipient.recipient = recipientNumber;

      this.$http
        .post(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/obj/create/",
          newUsageRecipient
        )
        .then(
          (response) => {
            if (this.saleDefinition.definition.cappedService) {
              this.saleDefinition.definition.cappedService.usageRecipients.push(
                response.data
              );
            } else {
              this.saleDefinition.definition.uncappedInternetService.usageRecipients.push(
                response.data
              );
            }
          },
          (error) => {
            console.error(error);
            this.showError("Error Adding Usage Recipient", error);
          }
        );
    },
    recipientRemoved(usageRecipient) {
      this.$http
        .post(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/obj/delete/",
          usageRecipient
        )
        .then(
          (response) => {
            if (this.saleDefinition.definition.cappedService) {
              const index = this.saleDefinition.definition.cappedService.usageRecipients.findIndex(
                (u) =>
                  u.usageRecipientNumber === usageRecipient.usageRecipientNumber
              );
              this.saleDefinition.definition.cappedService.usageRecipients.splice(
                index,
                1
              );
            } else {
              const index = this.saleDefinition.definition.uncappedInternetService.usageRecipients.findIndex(
                (u) =>
                  u.usageRecipientNumber === usageRecipient.usageRecipientNumber
              );
              this.saleDefinition.definition.uncappedInternetService.usageRecipients.splice(
                index,
                1
              );
            }
          },
          (error) => {
            console.error(error);
            this.showError("Error Removing Usage Recipient", error);
          }
        );
    },
    hourlyUsage(day) {
      const lastDay = new Date(
        new Date(this.usageCaption).getFullYear(),
        new Date(this.usageCaption).getMonth() + 1,
        0
      ).getDate();
      if (day == "previous") {
        day = parseInt(this.usageCaption.split(" ")[0]) - 1;
      } else if (day == "next") {
        day = parseInt(this.usageCaption.split(" ")[0]) + 1;
      }
      if (day < 1) {
        day = new Date(
          new Date(this.usageCaption).getFullYear(),
          new Date(this.usageCaption).getMonth(),
          0
        ).getDate();
        if (("" + this.usagePeriod).substring(4, 6) == "01") {
          this.usagePeriod = this.usagePeriod - 100 + 11; //dec last year
          this.usageYear--;
        } else {
          this.usagePeriod--;
        }
      } else if (day > lastDay) {
        day = 1;
        if (("" + this.usagePeriod).substring(4, 6) == "01") {
          this.usagePeriod = this.usagePeriod - 100 + 11; //dec last year
          this.usageYear++;
        } else {
          this.usagePeriod++;
        }
      }
      this.$http
        .get(
          this.$config.aimsAPI +
            "connectivityaccounts/pppoe/" +
            this.saleDefinition.definition.pppoe.pppoeAccountNumber +
            "/usage/" +
            this.usagePeriod +
            "/day/" +
            day +
            "/hourly"
        )
        .then((response) => {
          this.translateCurrentHourlyUsageData(response.data, day);
        });
    },
    translateCurrentHourlyUsageData: function (usageData, day) {
      this.currentDailyUsageData = [];
      this.totalUsage = 0;
      this.usageCaption =
        day + " " + this.prettifyBillingPeriod(this.usagePeriod);
      this.usageType = "hourly";
      usageData.map((c) => {
        this.totalUsage += c.inBytes + c.outBytes;
        const translatedDataUsageHour = {
          label: c.hour,
          downloaded: c.outBytes,
          uploaded: c.inBytes,
        };
        this.currentDailyUsageData.push(translatedDataUsageHour);
      });
    },
    dailyUsage(month, year) {
      this.usagePeriod = this.usagePeriod.toString();
      this.usagePeriod = `${year}${month}`;
      this.usagePeriod = parseInt(this.usagePeriod);
      const startPeriod =  parseInt(this.saleStartPeriod);    
      
      if (this.usagePeriod < startPeriod) {        
        this.usagePeriod = startPeriod;
      } 
      
      if (this.usagePeriod > parseInt(this.getCurrentPeriod())) {
        this.usagePeriod = parseInt(this.getCurrentPeriod());
      }

      this.$http
        .get(
          this.$config.aimsAPI +
            "connectivityaccounts/pppoe/" +
            this.saleDefinition.definition.pppoe.pppoeAccountNumber +
            "/usage/" +
            this.usagePeriod +
            "/daily"
        )
        .then(
          (response) => {
            this.translateCurrentDailyUsageData(response.data);
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Usage", error);
          }
        );
    },
    translateCurrentDailyUsageData: function (usageData) {
      this.currentDailyUsageData = {};
      this.totalUsage = 0;
      this.usageCaption = "" + this.prettifyBillingPeriod(this.usagePeriod);
      this.usageType = "daily";
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      usageData = usageData.map((c) => {
        this.totalUsage += c.inBytes + c.outBytes;
        return {
          label: c.day + " " + months[c.month - 1],
          downloaded: c.outBytes,
          uploaded: c.inBytes,
        };
      });
      this.currentDailyUsageData = {
        tooltip: "Click for hourly usage",
        data: usageData,
      };
    },
    previousMonthUsage() {
      if (("" + this.usagePeriod).substring(4, 6) == "01") {
        this.usagePeriod = this.usagePeriod - 100 + 11; //dec last year
        this.usageYear--;
      } else {
        this.usagePeriod--;
      }
      this.$http
        .get(
          this.$config.aimsAPI +
            "connectivityaccounts/pppoe/" +
            this.saleDefinition.definition.pppoe.pppoeAccountNumber +
            "/usage/" +
            this.usagePeriod +
            "/daily"
        )
        .then(
          (response) => {
            this.translateCurrentDailyUsageData(response.data);
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Usage", error);
          }
        );
    },
    nextMonthUsage() {
      if (this.usagePeriod < this.getCurrentPeriod()) {
        if (("" + this.usagePeriod).substring(4, 6) == "12") {
          this.usagePeriod = this.usagePeriod + 100 - 11; //jan next year
          this.usageYear++;
        } else {
          this.usagePeriod++;
        }
        this.$http
          .get(
            this.$config.aimsAPI +
              "connectivityaccounts/pppoe/" +
              this.saleDefinition.definition.pppoe.pppoeAccountNumber +
              "/usage/" +
              this.usagePeriod +
              "/daily"
          )
          .then(
            (response) => {
              this.translateCurrentDailyUsageData(response.data);
            },
            (error) => {
              console.error(error);
              this.showError("Error Fetching Usage", error);
            }
          );
      }
    },
    yearlyUsage() {
      var qryPeriod = "" + this.usageYear + "12";
      this.usageCaption = this.usageYear;
      this.$http
        .get(
          this.$config.aimsAPI +
            "connectivityaccounts/pppoe/" +
            this.saleDefinition.definition.pppoe.pppoeAccountNumber +
            "/usage/" +
            qryPeriod +
            "/monthly"
        )
        .then(
          (response) => {
            this.translateMonthlyUsageData(response.data);
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Usage", error);
          }
        );
    },
    previousYearlyUsage() {
      this.usageYear--;
      this.yearlyUsage();
    },
    nextYearlyUsage() {
      this.usageYear++;
      this.yearlyUsage();
    },
    translateMonthlyUsageData: function (usageData) {
      this.currentDailyUsageData = {};
      this.totalUsage = 0;
      this.usageType = "monthly";
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      usageData = usageData.map((c) => {
        this.totalUsage += c.inBytes + c.outBytes;
        return {
          label: months[c.month - 1],
          downloaded: c.outBytes,
          uploaded: c.inBytes,
        };
      });
      this.currentDailyUsageData = {
        tooltip: "Click for daily usage",
        data: usageData,
      };
    },
  },
};
</script>
