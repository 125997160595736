<template>
    <div>
        <div class="row row-spacing">
            <div class="col-md-12">
                <button class="btn btn-success" @click="openAddLicenceModal">Add Licence</button>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-md-12">
                <div class="table-responsive table-bordered">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Quantity</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody v-if="saleDefinition.sale.cyberRiskAware.cyberRiskAwareLicences.length > 0">
                            <tr v-for="licence in saleDefinition.sale.cyberRiskAware.cyberRiskAwareLicences" :key="licence.cyberRiskAwareLicencesNumber">
                                <td>{{ dateFormat(licence.startDate) }}</td>
                                <td>{{ dateFormat(licence.endDate) }}</td>
                                <td>{{ licence.quantity }}</td>
                                <td><span :class="statusBadge(licence.licencesStatus)">{{ licence.licencesStatus }}</span></td>
                                <td class="text-center" v-show="licence.licencesStatus === 'Active'">
                                    <button class="btn btn-info" v-tooltip="'Edit licence'" @click="openAddLicenceModal(licence)">
                                        <i class="fa fa-edit"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="5">No Licences available</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <CyberRiskAwareLicenceModal 
            ref="licenceModal"
            :modalTitle="modalTitle"
            :licence="licenceObj"
            :isEditing="isEditing"
            @saveLicence="saveLicence"
        />
    </div>
</template>

<script>
import CyberRiskAwareLicenceModal from '@/components/Admin/Modals/CyberRiskAwareLicenceModal';

    export default {
        components: {
            CyberRiskAwareLicenceModal
        },
        props: {
            saleDefinition: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                modalTitle: '',
                activeLicences: [],
                activeLicence: {},
                licenceObj: {},
                isEditing: false,
            }
        },
        watch: {
        saleDefinition: {
            handler(oldValue, newValue) {
                if (oldValue.meta.cyberRiskAware.children.cyberRiskAwareLicences.objMeta.new.endDate !== newValue.meta.cyberRiskAware.children.cyberRiskAwareLicences.objMeta.new.startDate) {
                    const endDate = new Date(newValue.meta.cyberRiskAware.children.cyberRiskAwareLicences.objMeta.new.startDate).setFullYear(new Date(newValue.meta.cyberRiskAware.children.cyberRiskAwareLicences.objMeta.new.startDate).getFullYear() + 1);                                               
                    oldValue.meta.cyberRiskAware.children.cyberRiskAwareLicences.objMeta.new.endDate = this.dateTimeFormat(new Date(endDate));
                }
            },
            deep: true
        }
    },
        methods: {
            openAddLicenceModal: function(licence) {    
                if (licence.cyberRiskAwareLicencesNumber > 0) {
                    this.modalTitle = 'Edit Licence';
                    this.licenceObj = licence;
                    this.isEditing = true,
                    this.$refs.licenceModal.open();
                } else {
                    this.modalTitle = 'Add Licence';
                    this.saleDefinition.meta.cyberRiskAware.children.cyberRiskAwareLicences.objMeta.new.startDate = this.dateTimeFormat(new Date());
                    this.saleDefinition.meta.cyberRiskAware.children.cyberRiskAwareLicences.objMeta.new.endDate = new Date(this.saleDefinition.meta.cyberRiskAware.children.cyberRiskAwareLicences.objMeta.new.startDate).setFullYear(new Date(this.saleDefinition.meta.cyberRiskAware.children.cyberRiskAwareLicences.objMeta.new.startDate).getFullYear() + 1);                  
                    this.licenceObj = this.saleDefinition.meta.cyberRiskAware.children.cyberRiskAwareLicences.objMeta.new;
                    this.$refs.licenceModal.open();
                }
            },
            saveLicence: function(data) {
                const hostUrl = this.$config.aimsAPI;
                const saleNumber = this.$route.params.saleNumber;

                if (data.cyberRiskAwareLicencesNumber > 0) {
                    this.$http.put(`${hostUrl}sales/definition/${saleNumber}/obj/update`, data).then(
                        (response) => {
                            this.showSuccess('Successsfully updated a licence');
                            this.$emit("reloadSale");
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error updating licence", error);
                        }
                    );
                } else {
                    data.cyberRiskAwareNumber = this.saleDefinition.sale.cyberRiskAware.cyberRiskAwareNumber;

                    this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/create`, data).then(
                        (response) => {
                            this.showSuccess('Successsfully created a licence');
                            this.$emit("reloadSale");
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error creating licence", error);
                        }
                    );
                }

                this.isEditing = false;
            },
            statusBadge(status) {
                let badge = "badge badge-success";
                if (status === "Active") {
                    badge = "badge bg-green";
                }
                if (status === "Expired") {
                    badge = "badge bg-red text-white";
                }
                return badge;
            },
        },
    }
</script>
