<template>
    <div>
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Email Alias Username</th>
                    <th>Destination</th>
                </tr>
            </thead>
            <tbody v-if="domain.accountAliases.length > 0">
                <tr v-for="accountAlias in domain.accountAliases" :key="accountAlias.zimbraMailboxAliasNumber">
                    <td>{{ accountAlias.username }}</td>
                    <td>
                      <p v-for="alias in aliases(accountAlias.destination)" :key="alias">
                        {{ alias }}
                      </p>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="2">No Email Aliases to display</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        domain: {
            type: Object,
            required: true
        },
    }
}
</script>

<style lang="scss" scoped>

</style>