<template>
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-6">
                <div class="row row-spacing">
                    <div class="col-md-12">
                        <label>Domain</label>
                        <input type="text" class="form-control" v-model="domain.domainName">
                    </div>
                </div>
                <div class="row row-spacing">
                    <div class="col-md-12">
                        <label>Admin Username</label>
                        <input type="text" class="form-control" v-model="domain.adminUsername">
                    </div>
                </div>
                <div class="row row-spacing">
                    <div class="col-md-12">
                        <label>Admin Password</label>
                        <PasswordInput
                            :password="domain.adminPassword"
                            :userCanEdit="true"
                            @changePassword="changePassword"
                        />
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <PortalButton :portalDetails="portal"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-md-4">
                <button class="btn btn-success" @click="$emit('saveSale')" v-allowed:view="['STAFF']">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
import PortalButton from "@/components/ObjParts/SingleLoaded/PortalButton";
import PasswordInput from "@/components/GeneralComponents/InputControls/PasswordInput";

export default {
    components: {
        PortalButton,
        PasswordInput
    },
    props: {
        domain: {
            type: Object,
            required: true
        },
        portal: {
            type: Object,
            required: true
        }
    },
    methods: {
        changePassword: function(data) {
            if (data) {
                this.domain.adminPassword = data;
            }
        },
    }
}
</script>

<style lang="scss" scoped>

</style>