<template>
    <Modal
        ref="addPersonModal"
        :size="'modal-xl'"
        :title="modalTitle"
        :saveNeeded="false"
        :dismissNeeded="false"
        :footerNeeded="false"
        @close="close"
    >
        <div class="row">
            <div class="col-md-12">
                <form-wizard
                    title=""
                    subtitle=""
                    stepSize="md"
                    color="#3a3f51"
                    errorColor="none"
                    ref="personModal"
                    @on-complete="checkIfRightIsSelected()"
                    @on-validate="createPersonDetails(personSelected.user)"  
                >
                    <tab-content 
                        title="Person Details"
                        icon="fa fa-user"
                        :before-change="()=>validateStep('person-details')"
                    >
                    <form data-vv-scope="person-details">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group mb-0">
                                    <label>Allow User Portal Access</label>
                                    <div class="input-group">
                                        <label class="switch">
                                            <input type="checkbox" class="form-control" v-model="personSelected.user.allowPortalAccess">
                                            <span class="slider round"></span>
                                        </label>
                                        <span><strong>{{ personSelected.allowPortalAccess === true ? 'Enabled' : 'Disabled' }}</strong></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" :class="{'has-error': errors.has('person-details.Email')}">
                                <label>Email</label>
                                <input type="email" class="form-control" v-model="personSelected.user.emailAddress" name="Email" v-validate="'required'" :class="{'has-error': errors.has('person-details.Email')}" placeholder="Enter an Email Address">
                                <span class="has-error" v-if="errors.has('person-details.Email')">{{ errors.first('person-details.Email') }}</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group" :class="{'has-error': errors.has('password')}">
                                <label>Password</label>
                                <MaskedPasswordInput 
                                    :password="password"
                                    :userCanEdit="true"
                                    @changePassword="changePassword"
                                />
                                <span class="has-error" v-if="errors.has('password')">{{ errors.first('password') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" :class="{'has-error': errors.has('person-details.Firstnames')}">
                                <label>Firstnames</label>
                                <input type="text" class="form-control" v-model="personSelected.user.firstNames" name="Firstnames" v-validate="'required'" :class="{'has-error': errors.has('person-details.Firstnames')}" placeholder="Enter a Firstname(s)">
                                <span class="has-error" v-if="errors.has('person-details.Firstnames')">{{ errors.first('person-details.Firstnames') }}</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group" :class="{'has-error': errors.has('person-details.Lastname')}">
                                <label>Lastname</label>
                                <input type="text" class="form-control" v-model="personSelected.user.lastName" name="Lastname" v-validate="'required'" :class="{'has-error': errors.has('person-details.Lastname')}" placeholder="Enter a Lastname">
                                <span class="has-error" v-if="errors.has('person-details.Lastname')">{{ errors.first('person-details.Lastname') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Job Title</label>
                                <input type="text" class="form-control" v-model="personSelected.user.jobTitle" placeholder="Enter a Job Title">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group" :class="{'has-error': errors.has('person-details.Mobile Number')}">
                                <label>Mobile Number <i class="fa fa-info-circle mx-2" v-tooltip="'This number may be used for SMS notifications'" style="cursor: pointer;"></i></label>
                                <input type="text" class="form-control" v-model="personSelected.user.telephonecell" name="Mobile Number"  v-validate="{ regex: /^([0-9 ]+)*$/ }" :class="{ 'has-error': errors.has('person-details.Mobile Number') }" placeholder="Enter a Mobile number">
                                <span class="has-error" v-if="errors.has('person-details.Mobile Number')">{{ errors.first('person-details.Mobile Number') }}, it should only contain numeric values [0-9]</span>
                            </div>
                        </div>
                    </div>
                    <hr>
                    </form>
                    </tab-content>
                    <tab-content 
                        title="Access Rights Management"
                        icon="fa fa-key"
                    >
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <h4>User Access Rights</h4>
                                    <div class="form-group">
                                        <label>Assign Rights</label>
                                        <div class="row" v-if="showRightMessage === true">
                                            <div class="col-md-12">
                                                <p class="badge bg-danger" style="font-size: .95em; padding: 8px 16px"><i class="fa fa-warning mr-2"></i><strong>{{ rightMessage }}</strong></p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-10" :class="{ 'col-md-12' :  rightObj === ''}">
                                                <v-select
                                                    ref="rightsOptions"
                                                    :options="availablePersonRights"
                                                    v-model="rightObj"
                                                    label="description"
                                                    v-if="availablePersonRights.length > 0"
                                                    :placeholder="'Assign right'"
                                                >
                                                </v-select>
                                            </div>
                                            <div class="col-md-2" v-if="availablePersonRights.length > 0 && rightObj != ''">
                                                <button class="btn btn-success float-right" @click="assignRightToPerson(rightObj)">Add Right</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group" style="max-height: 250px; overflow-y: scroll">
                                        <ul class="list-group" v-if="personSelected.rights.length > 0">
                                            <li class="list-group-item d-flex justify-content-between align-items-center" v-tooltip="right.groupName"
                                                    v-for="(right, name) in personSelected.rights" :key="name">
                                                    <span><i class="fa fa-user-plus mr-2"></i>{{ right.description }}</span> 
                                                <div class="d-flex ustify-content-end">
                                                    <button class="btn btn-primary" @click="unassignPersonRight(right)"><i class="fa fa-trash"></i></button>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul class="list-group" v-else-if="availablePersonRights.length > 0 && personSelected.rights.length <= 0">
                                            <li class="list-group-item">
                                                <span><i class="fa fa-user-plus mr-2"></i>Assign rights to person</span>
                                            </li>
                                        </ul>
                                        <ul class="list-group" v-else>
                                            <li class="list-group-item">
                                                <span><i class="fa fa-user-times mr-2"></i>No rights available</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab-content>
                </form-wizard>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
import MaskedPasswordInput from "@/components/GeneralComponents/InputControls/MaskedPasswordInput";
import {FormWizard, TabContent} from 'vue-form-wizard'
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
    components: {
        Modal,
        MaskedPasswordInput,
        FormWizard,
        TabContent
    },
    props: {
        modalTitle: {
            type: String,
            required: true
        },
        personSelected: {
            type: Object,
            required: true,
        },
        availablePersonRights: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            rightObj: '',
            addRights: [],
            rightMessage: 'Assign right in dropdown in order to finish',
            showRightMessage: false,
            password: '',
            cleanNumber: ''
        }
    },
    methods: {
        open: function() {
            this.$refs.addPersonModal.open();

            this.$http.get(this.$config.aimsAPI + "password/generate/8").then(
                (response) => {
                    this.password = response.bodyText;                       
                },
                (error) => {
                    console.error(error);
                    this.showError("Error Generating Password", error);
                }
            );
        },
        changePassword: function(data) {
            if (data) {
                this.personSelected.user.password = data;
            }
        },
        validateStep: function(stepName) {
            return this.$validator.validateAll(stepName).then(result => {  
                if (result) {
                    return true;
                } else {
                    return false;
                }
            });
        },
        checkIfRightIsSelected: function() {
            if (Object.keys(this.rightObj).length === 3) {                
                this.showRightMessage = true
            } else {
                this.closeModal();
            } 
        },
        createPersonDetails: function(personSelected) {                       
            if (this.errors.items.length < 1 && !personSelected.personNumber) {
                this.$emit('createPerson', personSelected);
            }
        },
        assignRightToPerson: function(rightObj) {
            this.showRightMessage = false
            this.$emit('assignRightToPerson', rightObj); 
            this.$refs.rightsOptions._data.selectedValue = null;       
            this.rightObj = "";          
        },
        unassignPersonRight: function(rightObj) {
            this.$emit('removeAssignedPersonRight', rightObj);
        },
        close() {   
            this.errors.clear()
        },
        closeModal() {
            this.$emit('reloadPersons');
            this.showSuccess("Person successfully created");
            this.$refs.personModal.reset();
            this.$refs.addPersonModal.close();
        }
    },
}
</script>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #37bc9b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}
</style>
