<template>
  <service-screen-layout v-if="saleDefinition.definition.service" :parts="parts">
    <template v-slot:account>
      <AxxessLteServiceDetails
        :saleDefinition="saleDefinition"
        :pendingSuspendAccountInstruction="pendingSuspendAccountInstruction"
        :pendingDeleteAccountInstruction="pendingDeleteAccountInstruction" 
        :clientReadOnly="clientReadOnly"
        :isSaving="isSaving"
        :isAdmin="isAdminUser"
        @sendRestoreAccountInstruction="sendRestoreAccountInstruction"
        @sendSuspendAccountInstruction="sendSuspendAccountInstruction"
        @sendDeleteAccountInstruction="sendDeleteAccountInstruction"
        @saveAxxessLteService="saveAxxessLteService"
      />
    </template>
    <template v-slot:advanced>
      <AxxessLteServiceAdvanced
       :saleDefinition="saleDefinition"
       :isAdmin="isAdminUser"
       @saveSale="saveAxxessLteService"
      />
    </template>
    <template v-slot:topups v-if="saleDefinition.definition.service.bundle.size > 0">
      <div class="row">
        <div class="col-md-12">
          <AllocatedTopups
            :heading="'Topups'"
            :showStatus="true"
            :hideUsage="true"
            :hideDates="true"
            @topupAdded="topupAdded"
            :availableTopups="saleDefinition.selectableOptions.topup"
            :allocatedTopups="saleDefinition.definition.service.allocatedTopups"
          />
        </div>
      </div>
    </template>
    <template v-slot:usage> 
      <TelkomLteUsageBreakdown :usageBreakdown="usageBreakdown" />
    </template>
    <template v-slot:mplsL2tpLink v-if="saleDefinition.definition.mplsL2tpLink">
      <MplsL2tpLink
        v-model="saleDefinition.definition.mplsL2tpLink"
        :vpns="saleDefinition.selectableOptions.vpn"
        :p2pSave="true"
        @saveNetwork="saveAxxessLteService()"
      />
    </template>
    <template v-slot:order>
      <AxxessLteServiceOrder 
        :saleDefinition="saleDefinition"
        :simswapReasons="simswapReasons"
        :simswapAvailableSims="simswapAvailableSims"
        :regradeOptions="regradeOptions"
        @changePackageInstruction="changePackageInstruction"
        @simswapInstruction="simswapInstruction"
      />
    </template>
    <template v-slot:instructions>
      <AxxessLteServiceInstructions 
        :saleDefinition="saleDefinition"
      />
    </template>
  </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import DateInput from "@/components/GeneralComponents/InputControls/DateInput";

import AllocatedTopups from "@/components/ObjParts/MultipleCaptured/AllocatedTopups";
import TelkomLteUsageBreakdown from "@/components/ObjParts/SingleConstructed/TelkomLteUsageBreakdown";
import MplsL2tpLink from "@/components/ObjParts/SingleCaptured/MplsL2tpLink";
import AxxessLteServiceDetails from "@/components/ObjParts/SingleCaptured/AxxessLteServiceDetails";
import AxxessLteServiceAdvanced from "@/components/ObjParts/SingleCaptured/AxxessLteServiceAdvanced";
import AxxessLteServiceOrder from "@/components/ObjParts/SingleConstructed/AxxessLteServiceOrder";
import AxxessLteServiceInstructions from "@/components/ObjParts/MultipleLoaded/AxxessLteServiceInstructions";

export default {
  components: {
    ServiceScreenLayout,
    DateInput,
    AllocatedTopups,
    TelkomLteUsageBreakdown,
    MplsL2tpLink,
    AxxessLteServiceDetails,
    AxxessLteServiceAdvanced,
    AxxessLteServiceOrder,
    AxxessLteServiceInstructions
  },
  data() {
    return {
      saleNumber: null,
      parts: [
        {
          name: "account",
          icon: "fa fa-user-circle",
          displayName: "Service",
          permissions: ["*"],
        },
        {
          name: "advanced",
          icon: "fa-solid fa-gears",
          displayName: "Advanced",
          permissions: ["*"],
        },
        {
          name: "topups",
          icon: "fa fa-plus-square",
          displayName: "Topups",
          permissions: ["*"],
        },
        {
          name: "usage",
          icon: "fa fa-area-chart",
          displayName: "Usage",
          permissions: ["*"],
        },
        {
          name: "order",
          icon: "fa fa-address-book",
          displayName: "Order",
          permissions: ["*"],
        },
        {
          name: "instructions",
          icon: "fa fa-arrows-v",
          displayName: "Instructions History",
          permissions: ["*"],
        },
        {
          name: "mplsL2tpLink",
          icon: "fa fa-link",
          displayName: "MPLS L2TP",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        sale: {},
        definition: {
          service: {
            serviceId: {},
            bundle: {},
            instructions: [],
            order: {
              orderAddress: {
                addressType: ""
              },
              created: ""
            },
            rica: {
              ricaAddress: {
                addressType: ""
              }
            }
          }
        },
      },
      usageBreakdown: {
        strUsername: {},
        updated: {},
        usages: []
      },
      isSaving: false,
      regradeOptions: [],
      simswapAvailableSims: [],
      simswapReasons: [],
      simswapSelectedReason: {
        intReasonId: "1",
        strReason: ""
      },
      simswapSelectedSim: {
        strSerialNumber: ""
      },         
      clientReadOnly: false,
      clientUsageEnabled: false
    };
  },
  computed: {
    isAdminUser() {
        return this.userContainsPermission(['ADMIN']);
    },
    pendingDeleteAccountInstruction: function() {
      let deleteAccountInstruction = {};
  
        this.saleDefinition.definition.service.instructions.map((ci) => {
          if (
            this.sameMonth(new Date(ci.created), new Date()) &&
            ci.instructionType === "Delete Account"
          ) {
            deleteAccountInstruction = ci;
          } else if (
            this.sameMonth(new Date(ci.created), new Date()) &&
            ci.instructionType === "Restore Account"
          ) {
            deleteAccountInstruction = {};
          }
         });
      
      return deleteAccountInstruction;
    },
    pendingSuspendAccountInstruction: function () {
      let suspendAccountInstruction = {};
      
        this.saleDefinition.definition.service.instructions.map((ci) => {
          if (this.sameMonth(new Date(ci.created), new Date()) && ci.instructionType === "Suspend Account") {
            suspendAccountInstruction = ci;
          } else if (this.sameMonth(new Date(ci.created), new Date()) && ci.instructionType === "Restore Account") {
            suspendAccountInstruction = {};
          }
        });
      
      return suspendAccountInstruction;
    },
  },
   mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.getSaleDefinition();

    this.getSimSwapReasons();
    this.getAvailableSims();
  },  
  methods: {
    getSaleDefinition() {
        const hostUrl = this.$config.aimsAPI;
        const saleNumber = this.saleNumber;
        this.$http.get(`${hostUrl}sales/definition/sale/${saleNumber}`).then(
          (response) => {
            this.saleDefinition = response.data;
            if (this.saleDefinition.definition.service.bundle.size == 0) {
              this.parts = this.parts.filter(data => data.name != 'topups');
            }
            if (!this.saleDefinition.definition.mplsL2tpLink) {
              this.parts = this.parts.filter((p) => !p.name.includes("mplsL2tpLink"));
            }
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Sale Definition", error);
          }
      ).finally(() => {this.getUsageBreakdown()});
    },
    getUsageBreakdown() {
      const hostUrl = this.$config.aimsAPI;
      const serviceId = this.saleDefinition.definition.service.axxessServiceId;
      this.$http.get(`${hostUrl}axxess/${serviceId}/lte/telkom/usage/breakdown`).then(
          (response) => {
            this.usageBreakdown = response.data;   
          },
          (error) => {
            this.parts = this.parts.filter((p) => p.name != "usage");
            console.error(error);
            this.showError("Failed to load Usage",error);
          }
      ).finally(() => {this.getServiceChangeProducts()});
    },
    getServiceChangeProducts() {
      const hostUrl = this.$config.aimsAPI;
      const serviceId = this.saleDefinition.definition.service.axxessServiceId;
      this.$http.get(`${hostUrl}axxess/lte/telkom/${serviceId}/availableservicechangeproducts`).then(
        (response) => {
          this.regradeOptions = response.data;
        },
        (error) => {
          //Don't throw error, there might be a pending servicechange already.
          //Is there a way of pre-checking this?
          //It would be easier if they returned nothing instead of throw an error 
          //{"intCode":400,"strStatus":"Bad Request","strMessage":"There is a pending service change already."}
          //console.error(error);
          // this.showError("Failed to load Service Change options",error);
        }
      );
    },

    getSimSwapReasons() {
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}axxess/lte/telkom/simswapreasons`).then(
        (response) => {
          this.simswapReasons = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Failed to load SIM Swap reasons",error);
        }
      );
    },
    getAvailableSims() {
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}axxess/lte/telkom/availablesims`).then(
        (response) => {
          this.simswapAvailableSims = response.data;          
        },
        (error) => {
          console.error(error);
          this.showError("Failed to SIM Swap options",error);
        }
      );    
    },  

    toggleSaving: function() {
      this.isSaving = !this.isSaving;
    },
    topupAdded: function(selectedTopup) {
      const hostUrl = this.$config.aimsAPI;
      const serviceTopup = {};
      serviceTopup.class = "za.co.adept.aims.axxess.lte.AxxessLteAllocatedTopup";
      serviceTopup.axxessLteTopupNumber = selectedTopup.axxessLteTopupNumber;
      serviceTopup.axxessLteServiceNumber = this.saleDefinition.definition.service.axxessLteServiceNumber;

      this.$http.post(`${hostUrl}sales/definition/${this.saleDefinition.sale.saleNumber}/obj/create/`, serviceTopup).then(
        (response) => {
          response.data.isNew = true;
          response.data.topup = this.deepCopyObject(selectedTopup);
          this.saleDefinition.definition.service.allocatedTopups.unshift(response.data);
          this.showSuccess("Topup successfully added");
          this.getSaleDefinition();
        },
        (error) => {
          console.error(error);
          this.showError("Error Adding Topup", error);
        }
      );
    },
    saveAxxessLteService: function() {
      this.toggleSaving();
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleDefinition.sale.saleNumber;

      this.$http.put(`${hostUrl}sales/definition/${saleNumber}/update`, this.saleDefinition).then(
        (response) => {
          this.showSuccess("Save Success");
          this.saleDefinition = response.data;
          this.toggleSaving();
        },
        (error) => {
          console.error(error);
          this.showError("Error Saving Axxess LTE Service", error);
          this.toggleSaving();
        }
      );
    },
    l2tpAccountChanged: function(l2tpAccount) {
      this.saleDefinition.definition.l2tpAccount = l2tpAccount;
    },
    saveL2tpIpv4Network: function(ipv4Network) {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleDefinition.sale.saleNumber;

      this.$http.put(`${hostUrl}sales/definition/${saleNumber}/obj/update/`, ipv4Network).then(
        (response) => {
          this.saleDefinition.definition.l2tpAccount.allocatedIpv4Network = response.data;
          this.showSuccess("Ipv4 Network updated successfully");
        },
        (error) => {
          console.error(error);
          this.showError("Error Updating Ipv4 Network", error);
        }
      );
    },
    sendDeleteAccountInstruction: function () {
      this.$swal({
        title: "Are you sure you want to Expire this account's service?",
        text: "This will be effective end of the month",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Expire it",
      }).then((result) => {
        if (result.value) {
          const deleteAccountInstruction = {};
          deleteAccountInstruction.class = "za.co.adept.aims.axxess.AxxessInstruction";
          deleteAccountInstruction.instructionType = "DELETEACC";
          deleteAccountInstruction.axxessLteServiceNumber = this.saleDefinition.definition.service.axxessLteServiceNumber;
          deleteAccountInstruction.axxessLtePackageNumber = this.saleDefinition.definition.service.axxessLtePackageNumber;
          this.createLteInstruction(deleteAccountInstruction);
        }
      });
    },
    sendSuspendAccountInstruction: function () {
      this.$swal({
        title: "Are you sure you want to suspend this account?",
        text: "There is a 2 minute delay on instructions",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Suspend it",
      }).then((result) => {
        if (result.value) {
          const suspendAccountInstruction = {};
          suspendAccountInstruction.class = "za.co.adept.aims.axxess.AxxessInstruction";
          suspendAccountInstruction.instructionType = "SUSPEND";
          suspendAccountInstruction.axxessLteServiceNumber = this.saleDefinition.definition.service.axxessLteServiceNumber;
          suspendAccountInstruction.axxessLtePackageNumber = this.saleDefinition.definition.service.axxessLtePackageNumber;
          this.createLteInstruction(suspendAccountInstruction);
        }
      });
    },
    sendRestoreAccountInstruction: function () {
      this.$swal({
        title: "Are you sure you want to restore this account?",
        text: "There is a 2 minute delay on instructions",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Restore it",
      }).then((result) => {
        if (result.value) {
          const restoreAccountInstruction = {};
          restoreAccountInstruction.class = "za.co.adept.aims.axxess.AxxessInstruction";
          restoreAccountInstruction.instructionType = "RESTORE";
          restoreAccountInstruction.axxessLteServiceNumber = this.saleDefinition.definition.service.axxessLteServiceNumber;
          restoreAccountInstruction.axxessLtePackageNumber = this.saleDefinition.definition.service.axxessLtePackageNumber;
          this.createLteInstruction(restoreAccountInstruction);
        }
      });
    },
    changePackageInstruction: function (regradeSelected) {
      if (regradeSelected) {          
        if (regradeSelected.ltePackageNumber != "0") {
                this.$swal({
                  title: "Regrade to [" + regradeSelected.strName + "]? ",
                  text: "Regrade effective on 1st of next month",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Regrade",
                }).then((result) => {
                  if (result.value) {
                    const regradeAccountInstruction = {};
                    regradeAccountInstruction.class = "za.co.adept.aims.axxess.AxxessInstruction";
                    regradeAccountInstruction.instructionType = "SERVICECHANGES";
                    regradeAccountInstruction.axxessLteServiceNumber = this.saleDefinition.definition.service.axxessLteServiceNumber;
                    regradeAccountInstruction.instructionInfo = regradeSelected.strName;  
                    regradeAccountInstruction.instructionCode = regradeSelected.guidProductId; 
                    regradeAccountInstruction.axxessLtePackageNumber = regradeSelected.ltePackageNumber;
                    this.createLteInstruction(regradeAccountInstruction);
                  } 
                });
        } else {
          this.showWarning("Regrade Service", "Could not find a matching Product Package. Contact DEV"); 
        }
      }
    },
    simswapInstruction: function (sim, reason) {
      this.$swal({
        title: "Are you sure you want to request a SIM Swap?",
        text: "This can not be undone",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, request SIM Swap",
      }).then((result) => {
        if (result.value) {
          const simswapInstruction = {};
          simswapInstruction.class = "za.co.adept.aims.axxess.AxxessInstruction";
          simswapInstruction.instructionType = "SIMSWAP";
          simswapInstruction.axxessLteServiceNumber = this.saleDefinition.definition.service.axxessLteServiceNumber;
          simswapInstruction.axxessLtePackageNumber = this.saleDefinition.definition.service.axxessLtePackageNumber;
          simswapInstruction.instructionInfo = sim.guidServiceId;
          simswapInstruction.instructionCode = reason.intReasonId;
          this.createLteInstruction(simswapInstruction);
        } 
      });
    },
    createLteInstruction: function (instruction) {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleDefinition.sale.saleNumber;

      this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/create`, instruction).then(
        (response) => {
          this.showSuccess("Instruction Sent");
          this.saleDefinition.definition.service.instructions.push(response.data);
          this.getSaleDefinition();
        },
        (error) => {
          console.error(error);
          this.showError("Error Creating Instruction", error);
        }
      );
    }
  },
};
</script>
