<template>
    <div>
        <autocomplete
            v-if="searchObj"
            ref="autocomplete"
            input-class="form-control"
            :source="searchEndpoint"
            placeholder="Search"
            results-property="data"
            :request-headers="authHeaders"
            results-display="toString"
            @selected="objSelected"
            >
        </autocomplete>
        <div v-else>
            <div>
                <strong>{{selectedObj.toString}}</strong>
                <span class="badge badge-primary" style="cursor:pointer; margin-left:2px" @click="changeObj()">Change</span>
            </div>
        </div>
    </div>
</template>

<script>

import Autocomplete from 'vuejs-auto-complete'

export default {
    computed: {
        authHeaders () {
            const token = this.$localStorage.get('aims-token');
            return {
                'Authorization': 'Bearer ' + token
            }
        }
    },
    components: {
        Autocomplete
    },
    props: {
        objClass: {
            type: String,
            required: true
        },
        startingObject: {
            type: Object,
            required: false
        }
    },
    data(){
        return {
            searchEndpoint: null,
            data: [],
            searchObj: true,
            selectedObj: {}
        }
    },
    watch: {

    },
    created: function(){
        this.searchEndpoint = this.$config.aimsAPI + "search/obj?objClass=" + this.objClass + "&term=";
    },
    methods: {
        changeObj(){
            this.searchObj = true;
        },
        objSelected: function(event){
            this.selectedObj =  event.selectedObject;
            this.$emit('objSelected', this.selectedObj);
            this.searchObj = false;
        }
    }
}
</script>

<style>

</style>
