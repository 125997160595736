<template>
  <div>
    <div class="row mb-2">
      <button v-if="userEditRight" type="button" @click="addPackage()" class="btn btn-info">
        Add new Package
      </button>
    </div>
    <div class="row">
      <div class="table-responsive table-bordered" v-if="packages.length > 0">
        <table class="table col-md-12">
          <thead>
            <tr class="text-nowrap">
              <th>Description</th>
              <th>Label</th>
              <th>Profile</th>
              <th>Current Price (excl. VAT)</th>
              <th>Incl. VAT</th>
              <th>Expired</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="supportPackage in packages"
              :key="supportPackage.cPanelPackageNumber"
            >
              <td>{{ supportPackage.description }}</td>
              <td>{{ supportPackage.label }}</td>
              <td>{{ supportPackage.deviceProfile }}</td>
              <td>
                {{ getPrice(supportPackage.supportpackageprices, period) }}
                <span
                  v-if="userEditRight"
                  style="cursor: pointer"
                  v-tooltip="'Edit Row Price'"
                  @click="openPriceModal(supportPackage)"
                  class="pull-right"
                >
                  <em class="fa fa-edit fa-fw text-green"></em>
                </span>
              </td>
              <td>
                {{
                  getPrice(supportPackage.supportpackageprices, period, true)
                }}
              </td>
              <td>
                <span
                class="badge"
                :class="
                  supportPackage.expired ? 'badge-warning' : 'badge-green'
                "
                >{{ supportPackage.expired }}
              </span>
              </td>
              <td class="text-center">
                <button
                  v-if="userEditRight"
                  v-tooltip="'Edit Package'"
                  type="button"
                  @click="editPackage(supportPackage)"
                  class="btn btn-sm btn-info m-1"
                >
                  <em class="fa fa-edit fa-fw"></em>
                </button>
                <button
                  v-if="userEditRight"
                  v-tooltip="'Delete Package'"
                  type="button"
                  @click="deletePackage(supportPackage)"
                  class="btn btn-sm btn-primary"
                >
                  <em class="fa fa-trash fa-fw"></em>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="alert alert-info col-md-12">
        No price packages to display
      </div>
    </div>
    <AddPriceModal
      ref="addPriceModal"
      :row-price="rowPrice"
      :new-price="newPrice"
      @addPrice="addPrice"
      @pricesAdded="getPackages()"
    />
    <modal
      :title="
        pricePackage.supportPackageNumber ? 'Edit Package' : 'Add New Package'
      "
      ref="modifyPackage"
      size="modal-lg"
      @save="savePackage()"
    >
      <div class="row">
        <div class="form-group col-md-10">
          <label class="control-label"> Description </label>
          <input
            disabled
            type="text"
            class="form-control"
            v-model="pricePackage.description"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-10">
          <label class="control-label"> Label </label>
          <input
            type="text"
            class="form-control"
            v-model="pricePackage.label"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-10">
          <label class="control-label"> Profile </label>
          <input
            type="text"
            class="form-control"
            v-model="pricePackage.deviceProfile"
          />
        </div>
      </div>
      <div class="row"
           v-if="pricePackage.supportPackageNumber"
      >
        <div class="form-group col-md-10">
          <label class="control-label"> Expired </label>
          <v-select :options="['true', 'false']" placeholder="false" v-model="pricePackage.expired"></v-select>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import PriceFunctions from "./PriceFunctions";
import AddPriceModal from "@/components/Admin/Modals/AddPriceModal";
import Modal from "@/components/GeneralComponents/Modal";

export default {
  extends: PriceFunctions,
  components: {
    AddPriceModal,
    Modal,
  },
  props: {
    productNumber: {
      type: Number,
      required: true,
    },
    userEditRight: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      period: this.getCurrentPeriod(),
      newPrice: {},
      pricePackage: {},
      rowPrice: [],
      packages: [],
    };
  },
  mounted() {
    this.getPackages();
  },
  methods: {
    getPackages() {
      this.$http
        .get(
          this.$config.aimsAPI + "pricing/supportpackages/" + this.productNumber
        )
        .then(
          (response) => {
            this.packages = response.data;
            
          },
          (error) => {
            this.showError("Error fetching Support Packages", error);
            console.error(error);
          }
        );
    },
    addPackage() {
      this.pricePackage = this.deepCopyObject({
        class: "za.co.adept.aims.ncentral.SupportPackage",
        productNumber: this.productNumber,
      });
      this.$refs.modifyPackage.open();
    },
    editPackage(supportPackage) {
      this.pricePackage = this.deepCopyObject(supportPackage);
      this.$refs.modifyPackage.open();
    },
    openPriceModal(supportPackage) {
      this.rowPrice = this.deepCopyObject(supportPackage.supportpackageprices);
      this.newPrice = this.deepCopyObject({
        supportPackageNumber: supportPackage.supportPackageNumber,
        class: supportPackage.class + "Price",
        validFrom: 0,
        validUntil: 0,
      });
      this.$refs.addPriceModal.open();
    },
    addPrice(newPrice) {
      this.rowPrice.forEach((price) => {
        if (price.validUntil == 0) {
          if (newPrice.validFrom % 100 > 1) {
            price.validUntil = newPrice.validFrom - 1;
          } else {
            price.validUntil = newPrice.validFrom - 100 + 11;
          }
        }
      });
      this.rowPrice.push(newPrice);
      this.newPrice = this.deepCopyObject({
        supportPackageNumber: newPrice.supportPackageNumber,
        class: newPrice.class,
        validFrom: 0,
        validUntil: 0,
      });
    },
    savePackage() {
      this.$refs.modifyPackage.isLoading();
      this.$http
        .post(
          this.$config.aimsAPI + "pricing/supportpackages/save",
          this.pricePackage
        )
        .then(
          (response) => {
            this.showSuccess("Saved Package Successfully");
            this.$refs.modifyPackage.close();
            if (this.pricePackage.supportPackageNumber) {
              this.getPackages();
            } else {
              response.data.supportpackageprices = [];
              this.openPriceModal(response.data);
            }
          },
          (error) => {
            this.showError("Error saving Support Packages", error);
            console.error(error);
          }
        )
        .finally(() => {
          this.$refs.modifyPackage.isLoading();
        });
    },
    deletePackage(supportPackage) {
      this.$swal({
        title: "Are you sure you want to delete this package?",
        text: supportPackage.description,
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$http
            .delete(
              this.$config.aimsAPI +
                "pricing/supportpackages/" +
                supportPackage.supportPackageNumber
            )
            .then(
              () => {
                this.showSuccess("Successfully deleted Support Package");
                this.getPackages();
              },
              (error) => {
                this.showError("Error deleting Support Package", error);
                console.error(error);
              }
            );
        }
      });
    },
  },
};
</script>
