<template>
    <div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
              <label>Subscription Name</label>
              <input type="text" class="form-control" v-model="liquidSubscriptionInformation.subscriptionName" disabled/>
            </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
              <label>Subscription ID</label>
              <input type="text" class="form-control" v-model="liquidSubscriptionInformation.subscriptionId" disabled/>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
              <label>Start Date</label>
              <input type="text" class="form-control" :value="dateFormat(liquidSubscriptionInformation.subscriptionStartDate)" disabled/>
            </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
              <label>End Date</label>
              <input type="text" class="form-control" :value="dateFormat(liquidSubscriptionInformation.subscriptionEndDate)" disabled/>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Term Duration</label>
            <input type="text" class="form-control" v-model="termDuration" disabled>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Billing Cycle</label>
            <input type="text" class="form-control" v-model="liquidSubscriptionInformation.billingCycle" disabled>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
              <label>Status</label>
              <input type="text" class="form-control" v-model="liquidSubscriptionInformation.subscriptionStatus" disabled/>
            </div>
        </div>
        <div class="col-md-6"></div>
      </div>
    </div>
</template>

<script>
export default {
    props: {
        liquidSubscriptionInformation: {
            type: Object,
            required: true
        }
    },
    computed: {
      termDuration() {
        return this.liquidSubscriptionInformation.termDuration === 1 ? `1 Month` : `${this.liquidSubscriptionInformation.termDuration} Months`;
      }
    }
}
</script>