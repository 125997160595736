<template>
  <div class="row">
    <div class="col-md-8">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template v-slot:mplsl2tplink>
            <form novalidate data-vv-scope="mplsl2tplink">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group">
                    <label class="control-label">MPLS VPN *</label>
                    <v-select
                      name="vpn"
                      placeholder="Select VPN"
                      :options="filledInSaleDefinition.selectableOptions.vpn"
                      label="vrfName"
                      v-model="
                        filledInSaleDefinition.requiredObjs.mplsL2tpLink
                          .mplsVpnNumber
                      "
                      valueProp="mplsVpnNumber"
                    ></v-select>
                  </div>
                </div>
              </div>
            </form>
          </template>
          <template v-slot:p2pipv4network>
            <form novalidate data-vv-scope="p2pipv4network">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group">
                    <label class="control-label">Network Family *</label>
                    <v-select
                      name="family"
                      placeholder="Select Network Family"
                      :options="
                        filledInSaleDefinition.supportingEnums.p2pIpv4Network
                          .family
                      "
                      label="description"
                      v-model="
                        filledInSaleDefinition.requiredObjs.p2pIpv4Network
                          .family
                      "
                      valueProp="name"
                    ></v-select>
                  </div>
                  <div class="form-group">
                    <label class="control-label">IP Address</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        filledInSaleDefinition.requiredObjs.p2pIpv4Network
                          .address
                      "
                    />
                  </div>
                  <div class="form-group">
                    <label class="control-label">Network Range *</label>
                    <v-select
                      :options="netmasksV4"
                      placeholder="Select Network Range"
                      :get-option-label="getLabel"
                      v-model="
                        filledInSaleDefinition.requiredObjs.p2pIpv4Network
                          .shortMask
                      "
                    ></v-select>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
export default {
  components: {
    ServiceWizardLayout,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "mplsl2tplink",
          displayName: "MPLS L2TP Link",
        },
        {
          name: "p2pipv4network",
          displayName: "P2P IPv4 Network",
        },
      ],
      filledInSaleDefinition: {
        requiredObjs: {
          mplsL2tpLink: {},
          p2pIpv4Network: {},
        },
        selectableOptions: {
          vpn: [],
        },
        supportingEnums: {
          p2pIpv4Network: {
            family: [],
          },
        },
      },
      netmasksV4: [32, 30, 29, 28, 27, 26, 25],
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
  },
  methods: {
    getLabel(mask) {
      return "/" + mask;
    },
    onComplete() {
      this.$emit("createSale", this.filledInSaleDefinition);
    },
    validateStep(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>
