<template>
    <modal
        :title="description"
        ref="wizardCheckoutModal"
        size="modal-md"
        saveText="Create Service"
        @save="checkout()"
    >
        <div class="row text-center mt-3" v-if="setupFee > 0">
            <div class="form-group col-md-12">
                <label class="red-label"> Setup Fee </label>
                <label> (excl. VAT): </label>
                <label class="price-label">  {{currencyFormat(setupFee)}} </label>
            </div>
        </div>
        <div class="row text-center mt-3">
            <div class="form-group col-md-12">
                <label class="red-label"> Current </label>
                <label> price (excl. VAT): </label>
                <label class="price-label">  {{currencyFormat(currentPrice)}} </label>
            </div>
        </div>
        <div class="row text-center" v-if="filteredPrices.length > 1">
            <div class="form-group col-md-12" v-for="(price, index) in filteredPrices" :key="index">
                <label class="red-label"> From </label>
                <label> {{prettifyBillingPeriod(price.validFrom)}}: </label>
                <label  class="price-label"> {{currencyFormat(price.priceExclVat)}} </label>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";

export default {
    components: {
        Modal,
    },
    props: {
        prices: {
            type: Array,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
    },
    computed: {
        hasSetupFee() {
            return Object.keys(this.prices).includes("setupfee");
        },
        hasSetupFeeExclVat() {
            return Object.keys(this.prices).includes("setupFeeExclVat");
        },
    },
    data(){
        return {
            period: this.getCurrentPeriod(),
            currentPrice: 0.00,
            setupFee: 0.00,
            filteredPrices: []
        }
    },
    methods: {
        checkout() {
            this.$emit("checkout");
            this.$refs.wizardCheckoutModal.close();
        },
        open() {
            this.filteredPrices = [];
            this.$refs.wizardCheckoutModal.open();

            for (let i = 0; i < this.prices.length; i++) {
                if (this.prices[i].validUntil > this.period || this.prices[i].validUntil == 0) {
                    this.filteredPrices.push(this.prices[i]);
                }
            }

            this.filteredPrices = this.filteredPrices.sort((a,b) => a.validFrom - b.validFrom);
            this.currentPrice = this.filteredPrices[0].priceExclVat;
            if (this.filteredPrices[0].setupfee) {
                this.setupFee = this.filteredPrices[0].setupfee;
            } else if (this.filteredPrices[0].setupFeeExclVat) {
                this.setupFee = this.filteredPrices[0].setupFeeExclVat;
            }
            this.filteredPrices.shift();
        },
    },
};
</script>

<style scoped>

label {
    font-size: 16.5px;
    padding-left: 0.2em;
    padding-right: 0.2em;
}

.red-label {
    color: red;
}

.price-label {
    color: rgb(39, 115, 208);
}
</style>
