var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"card card-default"},[_c('form-wizard',{attrs:{"title":"","subtitle":"","stepSize":"sm"},on:{"on-complete":_vm.onComplete}},[_c('tab-content',{attrs:{"title":"Hosting","before-change":() => _vm.validateStep('hosting')}},[_c('form',{attrs:{"novalidate":"","data-vv-scope":"hosting"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"form-group",class:{
                    'has-error': _vm.errors.has('hosting.name'),
                  }},[_c('label',{staticClass:"control-label"},[_vm._v("Domain Name")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(
                      _vm.filledInSaleDefinition.requiredObjs.hosting
                        .name
                    ),expression:"\n                      filledInSaleDefinition.requiredObjs.hosting\n                        .name\n                    "}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(
                      _vm.filledInSaleDefinition.requiredObjs.hosting
                        .name
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filledInSaleDefinition.requiredObjs.hosting
                        , "name", $event.target.value)}}}),(_vm.errors.has('hosting.name'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("hosting.name")))]):_vm._e()])]),_c('div',{staticClass:"col-xl-8 col-lg-12"},[_c('div',{staticClass:"form-group",class:{ 'has-error': _vm.errors.has('hosting.dnsViewNumber') }},[_c('label',{staticClass:"control-label"},[_vm._v("DNS View")]),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"label":"name","name":"dnsViewNumber","valueProp":"dnsViewNumber","placeholder":"Select a DNS View","options":_vm.filledInSaleDefinition.selectableOptions.dnsView},model:{value:(_vm.filledInSaleDefinition.requiredObjs.hosting.dnsViewNumber),callback:function ($$v) {_vm.$set(_vm.filledInSaleDefinition.requiredObjs.hosting, "dnsViewNumber", $$v)},expression:"filledInSaleDefinition.requiredObjs.hosting.dnsViewNumber"}}),(_vm.errors.has('hosting.dnsViewNumber'))?_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.errors.first("hosting.dnsViewNumber")))]):_vm._e()],1)])])])])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }