<template>
    <div class="d-flex flex-column justify-content-center align-items-center p-5">
        <Circle2/>
        <h4 class="text-dark mt-2" :style="textStyle">{{ loadingText }}</h4>
    </div>
</template>

<script>
import { Circle2 } from "vue-loading-spinner";

export default {
    components: {
        Circle2
    },
    props: {
        loadingText: {
            type: String,
            default: 'Loading...'
        },
        textStyle: {
            type: String,
            required: false
        }
    }
}
</script>

<style scoped>
    .spinner.spinner--circle-2 {
        border-color:  #ffffff #3a3f51 #3a3f51  !important;
    }
</style>