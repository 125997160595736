import { defineStore } from "pinia"

export const InvoicesState = defineStore("InvoicesState", {
    state: () => ({
        searchTerm: '',
        date: null,
    }),
    actions: {
        setSearch(data) {
            this.searchTerm = data;
        },
        setDate(data) {
            this.date = data;
        }
    }
});