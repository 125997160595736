<template>
  <service-screen-layout :parts="parts">
    <template v-slot:mplsoverfibre>
      <div class="row">
        <div class="col-md-9">
          <mpls-details
            :lan="saleDefinition.definition.lan"
            @saveSale="saveSale"
          >
            <div slot="label">
              <input
                type="text"
                class="form-control"
                v-model="saleDefinition.sale.label"
              />
            </div>
          </mpls-details>
          <div class="card card-default">
            <div class="card-header">
              <h4>Point to Point Addesses</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-9">
                  <table class="table table-bordered">
                    <caption>
                      IPv4 Point to Point
                    </caption>
                    <thead>
                      <tr>
                        <th>Address</th>
                        <th>Mask</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            v-model="
                              saleDefinition.definition.lan
                                .pointToPointIpv4Network.address
                            "
                          />
                        </td>
                        <td>
                          <select
                            class="form-control"
                            v-model="
                              saleDefinition.definition.lan
                                .pointToPointIpv4Network.shortMask
                            "
                          >
                            <option
                              :key="mask"
                              v-for="mask in netmasksV4"
                              :value="mask"
                            >
                              /{{ mask }}
                            </option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row row-spacing" v-allowed:view="['STAFF']">
                <div class="col-md-12">
                  <div class="form-group">
                    <button
                      class="btn btn-success pull-right"
                      @click="saveSale()"
                      type="button"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <RoutedNetworks
            heading="Routed Networks"
            :routedNetworks="saleDefinition.definition.lan.routedIpv4Networks"
            caption="IPv4 Routes"
            :netmasks="netmasksV4"
            :emptyRoutedNetwork="
              saleDefinition.supportingObjs.routedIpv4Networks
            "
            @saveRoutedNetworks="saveRoutedNetworks"
            @removeAddress="removeAddress"
          />
        </div>
      </div>
    </template>
    <template v-slot:line>
      <div class="col-md-12">
        <ConnectivityLine 
          :line="saleDefinition.definition.line" 
          @saveSale="saveSale"
        />
      </div>
    </template>
  </service-screen-layout>
</template>

<script>
import SaleFunctions from "@/pages/Services/SaleFunctions";
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import MplsDetails from "@/components/ObjParts/SingleCaptured/MplsDetails";
import RoutedNetworks from "@/components/ObjParts/MultipleCaptured/RoutedNetworks";
import ConnectivityLine from "@/components/ObjParts/SingleCaptured/ConnectivityLine";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    MplsDetails,
    RoutedNetworks,
    ConnectivityLine,
  },
  data() {
    return {
      parts: [
        {
          name: "mplsoverfibre",
          icon: "fa fa-info-circle",
          displayName: "MPLS Over Fibre",
          permissions: ["*"],
        },
        {
          name: "line",
          icon: "fa fa-link",
          displayName: "Line",
          permissions: ["STAFF"],
        },
      ],
      saleDefinition: {
        definition: {
          lan: {
            pointToPointIpv4Network: {},
            routedIpv4Networks: [],
          },
          line: {},
        },
        sale: {},
        supportingObjs: {
          routedIpv4Networks: {},
        },
      },
      netmasksV4: [32, 30, 29, 28, 27, 26, 25],
    };
  },
  async mounted() {
    await this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition() {
      const saleNumber = this.$route.params.saleNumber;
      this.getSaleDefinition(saleNumber).then(
        (success) => {
          this.saleDefinition = success.data;
          this.saleDefinition.supportingObjs.routedIpv4Networks.family = "IPV4";
          this.saleDefinition.supportingObjs.routedIpv4Networks.networkType =
            "ROUTED";
          this.saleDefinition.supportingObjs.routedIpv4Networks.mplsLanNumber = this.saleDefinition.definition.lan.mplsLanNumber;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
    },
    saveSale() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Sale Definition", error);
              }
            );
        }
      });
    },
    saveRoutedNetworks(routedNetworks) {
      this.$http
        .put(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/objlist/update",
          routedNetworks
        )
        .then(
          () => {
            this.showSuccess("Save Success");
            this.loadSaleDefinition();
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving Sale Definition", error);
          }
        );
    },
    removeAddress(address) {
      this.$swal({
        title: "Are you sure you want to remove this address?",
        text: address.address,
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Remove",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/obj/delete",
              address
            )
            .then(
              () => {
                this.showSuccess("Delete Success");
                this.loadSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Sale Definition", error);
              }
            );
        }
      });
    },
  },
};
</script>